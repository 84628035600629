import { EventId } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../core/domain/CBOEventListReadModel';
import { CBOLandingPageData } from '../../core/domain/CBOLandingPageData';
import { QueryStatus } from '../../core/store/state/utils';
import EventListHeader from '../components/molecules/EventListHeader';
import FixedContentHeader from '../components/molecules/FixedContentHeader';
import EventListGrid from '../components/organisms/event/EventListGrid';
import CreateEventFormDialog from '../components/organisms/event/create-event-form-dialog/CreateEventFormDialog';
import StyledDialog from './dialog/StyledDialog';

const EVENTS_LIST_CONTAINER_OFFSET = 10;

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const EventListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  // be sure to maintain marginTop === paddingBottom for virtual scroll works properly
  marginTop: theme.spacing(EVENTS_LIST_CONTAINER_OFFSET),
  paddingBottom: theme.spacing(EVENTS_LIST_CONTAINER_OFFSET),
}));

export type LiveEventsTemplateProps = {
  canShareEvents: boolean;
  isDrawerOpen: boolean;
  landingPageData: CBOLandingPageData;
  liveEvents: CBOEventListReadModel[];
  liveEventsFetchingStatus: QueryStatus;
  onGoToLiveEventDetails: (id: EventId) => void;
  onGoToLiveEvents: () => void;
  urlParams: URLSearchParams;
};

const LiveEventsTemplate: FC<LiveEventsTemplateProps> = ({
  canShareEvents,
  isDrawerOpen,
  liveEvents,
  liveEventsFetchingStatus,
  onGoToLiveEventDetails,
  onGoToLiveEvents,
  landingPageData,
  urlParams,
}) => {
  const { t } = useTranslation('onboarding');

  const [isCreateLiveEventModalOpen, setIsCreateLiveEventModalOpen] = useState(false);
  const [isOnboardingCompletedModalOpen, setIsOnboardingCompletedModalOpen] = useState(false);

  const handleCloseOnboardingCompletedModal = () => {
    setIsOnboardingCompletedModalOpen(false);
    onGoToLiveEvents();
  };

  useEffect(() => {
    if (urlParams.get('show-onboarding-completed-modal') === '1') setIsOnboardingCompletedModalOpen(true);
  }, [urlParams]);

  return (
    <>
      <CreateEventFormDialog onClose={() => setIsCreateLiveEventModalOpen(false)} open={isCreateLiveEventModalOpen} />

      <StyledDialog
        ButtonsProps={[
          {
            children: t('EndModal.Action'),
            color: 'secondary',
            onClick: handleCloseOnboardingCompletedModal,
            sx: (theme) => ({
              backgroundColor: theme.palette.secondary.dark,
            }),
            variant: 'contained',
          },
        ]}
        content={t('EndModal.Description')}
        open={isOnboardingCompletedModalOpen}
        title={t('EndModal.Title')}
      />

      <Container>
        <FixedContentHeader isDrawerOpen={isDrawerOpen} isShadowHidden>
          <EventListHeader
            loading={liveEventsFetchingStatus === 'pending'}
            onCreateEvent={() => setIsCreateLiveEventModalOpen(true)}
          />
        </FixedContentHeader>
        <EventListContainer>
          <EventListGrid
            canShareEvents={canShareEvents}
            events={liveEvents}
            eventsType="upcoming"
            landingPageData={landingPageData}
            onCreateEvent={() => setIsCreateLiveEventModalOpen(true)}
            onShowEvent={onGoToLiveEventDetails}
          />
        </EventListContainer>
      </Container>
    </>
  );
};

export default LiveEventsTemplate;
