import { Box, Button, styled, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import liveShowImage from '../../../assets/live-show.png';
import publisherImage from '../../../assets/publisher-hand-transparent.png';
import { ImageActionCard, ImageActionCardProps } from '../../atoms/home/ImageActionCard';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

type ActionCardsProps = {
  onCreateEvent: () => void;
  onCreateShoppableVideo: () => void;
  onShowEventTutorial: () => void;
  onShowShoppableVideoTutorial: () => void;
};

export default function ActionCards({
  onCreateEvent,
  onCreateShoppableVideo,
  onShowEventTutorial,
  onShowShoppableVideoTutorial,
}: ActionCardsProps) {
  const { t } = useTranslation('home');
  const theme = useTheme();

  const eventActions: ImageActionCardProps['actions'] = useMemo(
    () => [
      <Button color="secondary" key="create-event-action" onClick={onCreateEvent} variant="outlined">
        {t('CreateLiveAction')}
      </Button>,
      <Button color="primary" key="create-event-tutorial" onClick={onShowEventTutorial} variant="contained">
        {t('Tutorial')}
      </Button>,
    ],
    [onCreateEvent, onShowEventTutorial, t],
  );

  const shoppableActions: ImageActionCardProps['actions'] = useMemo(
    () => [
      <Button color="secondary" key="create-shoppable-action" onClick={onCreateShoppableVideo} variant="outlined">
        {t('CreateShoppableAction')}
      </Button>,
      <Button
        color="secondary"
        key="create-shoppable-tutorial"
        onClick={onShowShoppableVideoTutorial}
        variant="contained"
      >
        {t('Tutorial')}
      </Button>,
    ],
    [onCreateShoppableVideo, onShowShoppableVideoTutorial, t],
  );

  return (
    <Container>
      <ImageActionCard
        actions={eventActions}
        backgroundImage={liveShowImage}
        gradient={`linear-gradient(90deg, ${theme.palette.primary.main}, transparent)`}
        title={t('CreateLive.Title')}
      />
      <ImageActionCard
        actions={shoppableActions}
        gradient={`linear-gradient(118deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`}
        image={publisherImage}
        title={t('ShoppableVideos')}
      />
    </Container>
  );
}
