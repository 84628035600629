import { V1 } from '@bellepoque/api-contracts';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/Dehaze';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, Typography, darken, styled } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOMediaCollectionMedia } from '../../../../../core/domain/CBOMediaCollectionMedia';
import { secondsToFormatedTime } from '../../../../../utils/date-format';

const maxMediasCount = V1.api.constraints.mediaCollections.maxMediasCount;

// TODO: Use theme spacings and colors
const MediaCollectionMediasOrganizerRoot = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  color: theme.palette.common.white,
  display: 'flex',
}));

const MediaCollectionMediasOrganizerTextColumn = styled(Box)<{ arrow?: 'right' | 'top' }>(({ arrow, theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: '25px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(3),
  position: 'relative',
  textAlign: 'left',
  width: '25%',

  ...(arrow && {
    '&::after': {
      backgroundColor: theme.palette.info.main,
      borderRadius: '50%',
      content: `''`,
      height: '18px',
      position: 'absolute',
      width: '18px',

      ...(arrow === 'right' && {
        right: -6,
        top: '50%',
        transform: 'translate(0, -50%)',
      }),
      ...(arrow === 'top' && {
        right: '24px',
        top: -6,
      }),
    },
  }),
}));

const MediaCollectionMediasOrganizerMediasColumn = styled(Box)(({ theme }) => ({
  backgroundColor: '#0D2E39',
  borderRadius: '25px',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(0.5),
  justifyContent: 'center',
  margin: `${theme.spacing(-2)} ${theme.spacing(1)}`,
  minWidth: 'fit-content',
  overflow: 'auto',
  padding: `0 ${theme.spacing(2)}`,
  placeItems: 'center',
  textAlign: 'left',
}));

const MediaCollectionMediasOrganizerMedia = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.info.main,
  borderRadius: '50%',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  color: theme.palette.primary.light,
  display: 'flex',
  fontSize: '20px',
  height: '100px',
  justifyContent: 'center',
  minWidth: '100px',
  position: 'relative',
  textShadow: 'rgb(0 0 0 / 37%) 0px 3px 6px',
  width: '100px',
}));

const MediaCollectionMediasOrganizerMediaThumbnail = styled('img')({
  borderRadius: '50%',
  height: '100%',
  left: 0,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  width: '100%',
});

const MediaCollectionMediasOrganizerMediaText = styled(Typography)({
  /* stylelint-disable-next-line property-no-vendor-prefix */
  '-webkit-box-orient': 'vertical',

  /* stylelint-disable-next-line property-no-vendor-prefix */
  '-webkit-line-clamp': '2',

  display: '-webkit-box',

  fontSize: '10px',

  inlineSize: '84px',

  lineClamp: 2,

  overflow: 'hidden',

  overflowWrap: 'break-word',

  textOverflow: 'ellipsis',

  textShadow: '1px 1px 2px #000',
});

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    height: 12,
    width: 12,
  },
  '&:hover': {
    background: darken(theme.palette.common.white, 0.1),
  },
  background: theme.palette.common.white,
  color: '#0D2E39',
  height: '16px',
  position: 'absolute',
  right: 4,

  top: 4,

  width: '16px',
}));

const PlayIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.35)',
  },
  background: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  height: '28px',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',

  width: '28px',
}));

const DragIconContainer = styled(Box)({
  '& svg': {
    height: 12,
    width: 12,
  },
  left: '8px',
  position: 'absolute',
  top: -26,

  transform: 'rotate(90deg)',
});

const CloseIconContainer = styled(Box)({
  position: 'absolute',
  right: '-16px',
  top: '-4px',
  zIndex: 100,
});

const CollectionTextContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'alignBottom' })<{
  alignBottom?: boolean;
}>(({ alignBottom }) => ({
  bottom: '-42px',
  height: '42px',
  left: 16,
  position: 'absolute',

  ...(alignBottom && {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  }),
}));

const Card = styled(Box, { shouldForwardProp: (prop) => prop !== 'order' })<{ order: number }>(({ order }) => ({
  height: '100px',
  position: 'relative',
  width: '80px',
  zIndex: order,
}));

export type MediaCollectionMediasOrganizerProps = {
  hasSelectedPublishedPages: boolean;
  isSaveButtonDisplayed: boolean;
  medias: CBOMediaCollectionMedia[];
  onChangeOrder: (items: CBOMediaCollectionMedia[]) => void;
  onMediaPreviewClick: (media: CBOMediaCollectionMedia) => void;
  onRemove: (media: CBOMediaCollectionMedia) => void;
};

const MediaCollectionMediasOrganizer: FC<MediaCollectionMediasOrganizerProps> = ({
  hasSelectedPublishedPages,
  isSaveButtonDisplayed,
  medias,
  onChangeOrder,
  onMediaPreviewClick,
  onRemove,
}) => {
  const { t } = useTranslation('mediaCollections');
  const [orderedMedias, setOrderedMedias] = useState(medias);

  const cards = useMemo(() => Array.from({ length: maxMediasCount }), [maxMediasCount]);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const media = medias.find((media) => media.id === result.draggableId);
      if (!media) return;

      const reorderedItems = [...medias];
      reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, media);
      setOrderedMedias(reorderedItems);
      onChangeOrder(reorderedItems);
    },
    [medias, onChangeOrder],
  );

  const handleMediaPreviewClick = useCallback(
    (media: CBOMediaCollectionMedia) => () => {
      onMediaPreviewClick(media);
    },
    [onMediaPreviewClick],
  );

  useEffect(() => {
    setOrderedMedias(medias);
  }, [medias]);

  const renderCard = useCallback(
    (_, index: number) => {
      const media = orderedMedias[index];

      if (!media) {
        return (
          <Card key={index.toString()} order={maxMediasCount - index}>
            <MediaCollectionMediasOrganizerMedia>{index + 1}</MediaCollectionMediasOrganizerMedia>
          </Card>
        );
      }

      const duration = media.type === 'shoppable' || media.type === 'replay-chapter' ? media.duration : 0;

      return (
        <Draggable draggableId={media.id} index={index} isDragDisabled={medias.length < 2} key={media.id}>
          {(provided) => (
            <Card order={maxMediasCount - index} ref={provided.innerRef} {...provided.draggableProps}>
              <DragIconContainer {...provided.dragHandleProps}>
                <DragIndicatorIcon />
              </DragIconContainer>
              <CloseIconContainer>
                <CloseIconButton onClick={() => onRemove(media)}>
                  <CloseIcon fontSize="small" />
                </CloseIconButton>
              </CloseIconContainer>
              <MediaCollectionMediasOrganizerMedia
                sx={{ border: (theme) => `1px solid ${theme.palette.primary.light}` }}
              >
                <Fragment>
                  {media.image && <MediaCollectionMediasOrganizerMediaThumbnail src={media.image} />}
                  <PlayIconButton onClick={handleMediaPreviewClick(media)}>
                    <PlayIcon />
                  </PlayIconButton>
                </Fragment>
              </MediaCollectionMediasOrganizerMedia>
              <CollectionTextContainer alignBottom={!duration}>
                {!!duration && (
                  <MediaCollectionMediasOrganizerMediaText>
                    {secondsToFormatedTime(duration / 1000)}
                  </MediaCollectionMediasOrganizerMediaText>
                )}
                <MediaCollectionMediasOrganizerMediaText fontWeight="bold">
                  {media.title}
                </MediaCollectionMediasOrganizerMediaText>
              </CollectionTextContainer>
            </Card>
          )}
        </Draggable>
      );
    },
    [handleMediaPreviewClick, onRemove, orderedMedias],
  );

  return (
    <MediaCollectionMediasOrganizerRoot>
      <MediaCollectionMediasOrganizerTextColumn arrow="right">
        <Box display="flex" gap="8px">
          <Typography fontWeight="bold">{t('SelectedMedias')}</Typography>
          <Typography>{`${medias.length}/${maxMediasCount}`}</Typography>
        </Box>
        <Typography>{t('AddMediasFromSources')}</Typography>
        <Typography>{t('OrganizeMedias')}</Typography>
      </MediaCollectionMediasOrganizerTextColumn>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable direction="horizontal" droppableId="collection-list" isDropDisabled={medias.length < 2}>
          {(provided) => (
            <MediaCollectionMediasOrganizerMediasColumn ref={provided.innerRef} {...provided.droppableProps}>
              {cards.map(renderCard)}
              {provided.placeholder}
            </MediaCollectionMediasOrganizerMediasColumn>
          )}
        </Droppable>
      </DragDropContext>

      {isSaveButtonDisplayed && (
        <MediaCollectionMediasOrganizerTextColumn arrow="top">
          <Typography fontWeight="bold">{t('SaveToDisplay')}</Typography>
          <Typography>{t('SaveToDisplayDescription')}</Typography>
        </MediaCollectionMediasOrganizerTextColumn>
      )}

      {!hasSelectedPublishedPages && !isSaveButtonDisplayed && (
        <MediaCollectionMediasOrganizerTextColumn arrow="top">
          <Typography fontWeight="bold">{t('InstantDisplay')}</Typography>
          <Typography>{t('SelectPagesToDisplay')}</Typography>
          <Typography>{t('ClickOnDisplay')}</Typography>
        </MediaCollectionMediasOrganizerTextColumn>
      )}

      {hasSelectedPublishedPages && !isSaveButtonDisplayed && (
        <MediaCollectionMediasOrganizerTextColumn arrow="top">
          <Typography fontWeight="bold">{t('ChangeDestination')}</Typography>
          <Typography>{t('ChangeDestinationDescription')}</Typography>
        </MediaCollectionMediasOrganizerTextColumn>
      )}
    </MediaCollectionMediasOrganizerRoot>
  );
};

export default MediaCollectionMediasOrganizer;
