import { Autocomplete, Box, FilterOptionsState, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { FC, HTMLAttributes, useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputTooltip from '../../atoms/inputs/InputTooltip';
import { ShoppableSettingsFormInputs } from '../../organisms/shoppables/ShoppableSettingsFormDialog';

export type FontSelectorProps = {
  control: Control<ShoppableSettingsFormInputs, any>;
  fontOptions: string[];
  readonly: boolean;
  size?: 'medium' | 'small';
  submissionInProgress: boolean;
  variant?: 'filled' | 'outlined';
};

const FontSelector: FC<FontSelectorProps> = ({
  control,
  fontOptions,
  readonly,
  size = 'medium',
  submissionInProgress,
  variant = 'filled',
}) => {
  const { t } = useTranslation(['shoppables', 'events', 'common']);
  const disabled = readonly || submissionInProgress;
  const filter = createFilterOptions<string>();

  const getFilterOptions = useCallback((options: string[], params: FilterOptionsState<string>) => {
    const filteredOptions = filter(options, params);
    const { inputValue } = params;
    const isExistingOption = options.some((option) => inputValue === option);

    if (inputValue !== '' && !isExistingOption) filteredOptions.push(inputValue);

    return filteredOptions;
  }, []);

  const getRenderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: string) => {
      const isExisting = fontOptions.some((fontOption) => fontOption === option);

      if (isExisting)
        return (
          <Typography {...props} fontFamily={option}>
            {option}
          </Typography>
        );

      return (
        <Typography {...props}>
          {option} ({t('events:fonts.NewLabel')})
        </Typography>
      );
    },
    [fontOptions, t],
  );

  return (
    <Box position="relative">
      <Controller
        control={control}
        defaultValue="Arial"
        name="mainFontName"
        render={({ field }) => (
          <Autocomplete
            {...field}
            autoHighlight
            blurOnSelect
            disableClearable={true}
            disabled={disabled}
            filterOptions={getFilterOptions}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(_event, newValue) => field.onChange(newValue)}
            options={fontOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                label={t('events:fonts.MainFont')}
                variant={variant}
              />
            )}
            renderOption={getRenderOption}
            size={size}
            value={field.value}
          />
        )}
        rules={{
          maxLength: { message: t('common:MaxCharsWithCount', { count: 100 }), value: 100 },
          minLength: { message: t('common:MinCharsWithCount', { count: 3 }), value: 3 },
          required: { message: t('MainFontNameIsRequired'), value: true },
        }}
      />
      <InputTooltip content={t('Helpers.Font')} />
    </Box>
  );
};

export default FontSelector;
