import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ENDPOINTS } from '../../../../../../endpoints';
import LinkYoutubeAccountButton from '../../../../atoms/event/LinkYoutubeAccountButton';

interface ConnectYoutubeAccountProps {
  areButtonsDisabled: boolean;
  isNoChannelsErrorShown: boolean;
  onConnect: () => void;
}

export default function ConnectYoutubeAccount({
  areButtonsDisabled,
  onConnect,
  isNoChannelsErrorShown,
}: ConnectYoutubeAccountProps) {
  const { t } = useTranslation('events', { keyPrefix: 'YoutubeRestreaming' });

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Box marginBottom={1}>
        {isNoChannelsErrorShown && (
          <Typography fontSize=".9em" sx={{ color: (theme) => theme.palette.error.main }}>
            {t('NoChannels')}
          </Typography>
        )}
      </Box>
      <LinkYoutubeAccountButton disabled={areButtonsDisabled} onClick={onConnect} />
      <Typography fontSize=".8em" mt={1}>
        {t('FeatureMustBeEnabled')}{' '}
        <Link href={ENDPOINTS.youtubeAccountFeatures} target="_blank">
          {t('EnableLiveStreaming')}
        </Link>
      </Typography>
      <Typography fontSize=".8em" mt={1}>
        <Trans
          components={{
            userDataPolicyLink: <Link href={ENDPOINTS.googleApiServicesUserDataPolicy} target="_blank" />,
          }}
          i18nKey="Disclosure"
          ns="events"
          t={t}
        />
      </Typography>
    </Box>
  );
}
