import { Box } from '@mui/material';
import React, { FC } from 'react';

import UploadFile, { FileTypes, UploadProps } from './UploadFile';

export type UploadVideoSquareBoxProps = Pick<
  UploadProps,
  | 'buttonDescription'
  | 'buttonLabel'
  | 'disabled'
  | 'errorMessage'
  | 'helperText'
  | 'loading'
  | 'onSelectFile'
  | 'uploadStatus'
>;

const UploadVideoSquareBox: FC<UploadVideoSquareBoxProps> = (props) => (
  <Box bgcolor="white" borderRadius="15px" boxShadow={2} height="340px" p={2}>
    <UploadFile acceptedFiles={FileTypes.Video} variant="square" {...props} />
  </Box>
);

export default UploadVideoSquareBox;
