import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function EventMessagesSettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 38 30.001" {...props}>
      <g transform="translate(-5 -9)">
        <path d="M42,9H6a1,1,0,0,0-1,1V34a1,1,0,0,0,1,1H20.5l2.7,3.6a1,1,0,0,0,1.6,0L27.5,35H42a1,1,0,0,0,1-1V10a1,1,0,0,0-1-1ZM41,33H27a1,1,0,0,0-.8.4L24,36.333,21.8,33.4A1,1,0,0,0,21,33H7V11H41ZM16,20V17a1,1,0,0,1,1-1H31a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1Zm0,7V24a1,1,0,0,1,1-1H31a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1Z" />
      </g>
    </SvgIcon>
  );
}
