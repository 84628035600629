import { EventStatus } from '@bellepoque/api-contracts';
import CopyIcon from '@mui/icons-material/ContentCopy';
import PublicIcon from '@mui/icons-material/Public';
import { Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '../../../../../core/store';
import { CommandStatus } from '../../../../../core/store/state/utils';
import HeaderEventState from '../../../atoms/event/HeaderEventState';
import SwitchButton from '../../../atoms/inputs/SwitchButtons';

interface HeaderPreviewActionsProps {
  eventStatus: EventStatus;
  onChangeMode: (isPrivatePreview: boolean) => void;
  onOpenReplay: () => void;
  replayOpeningStatus: CommandStatus;
  urlToUse: string;
}

export default function HeaderPreviewActions(props: HeaderPreviewActionsProps) {
  const { t } = useTranslation(['events', 'common']);
  const { eventStatus, onChangeMode, onOpenReplay, replayOpeningStatus, urlToUse } = props;

  const isPrivatePreview = useSelector((state: State) => state.events.isPrivatePreview);

  if (
    eventStatus !== EventStatus.PLANNED &&
    eventStatus !== EventStatus.PRIVATE_PREVIEW &&
    eventStatus !== EventStatus.PRIVATE_REPLAY &&
    eventStatus !== EventStatus.FINISHED
  ) {
    return null;
  }

  const showPreviewSwitchButton =
    eventStatus === EventStatus.PRIVATE_PREVIEW || eventStatus === EventStatus.PRIVATE_REPLAY;

  const showOpenInNewWindowButtons = eventStatus === EventStatus.PRIVATE_PREVIEW;

  return (
    <Box
      id="header-actions"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'space-evenly',
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          gap: {
            md: 3,
            xs: 1,
          },
        }}
      >
        {showPreviewSwitchButton && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <SwitchButton
              label={t('common:PreviewAs')}
              onChange={onChangeMode}
              option1={t('common:Admin')}
              option2={t('common:Public')}
              value={isPrivatePreview}
            />
          </Box>
        )}

        <Box sx={{ display: 'flex' }}>
          {showOpenInNewWindowButtons && (
            <Link href={urlToUse} target="_blank" underline="none">
              <Button
                color="primary"
                endIcon={<CopyIcon sx={{ transform: 'rotateZ(180deg) scaleX(-1)' }} />}
                fullWidth
                size="large"
                sx={{ textTransform: 'none' }}
                variant="contained"
              >
                {t('common:OpenInNewWindow')}
              </Button>
            </Link>
          )}
          {eventStatus === EventStatus.PRIVATE_REPLAY && (
            <Button
              color="error"
              disabled={replayOpeningStatus === 'pending'}
              onClick={onOpenReplay}
              startIcon={<PublicIcon />}
              variant="contained"
            >
              {t('OpenReplay')}
            </Button>
          )}
          {eventStatus === EventStatus.FINISHED && <HeaderEventState eventStatus={eventStatus} />}
        </Box>
      </Box>
    </Box>
  );
}
