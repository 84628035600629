import { Box, Tooltip, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../../../../core/store';
import { fetchTrafficReports } from '../../../../core/usecases/reports/fetch-traffic-reports';
import YearSelector from '../../atoms/inputs/YearSelector';
import DetailsContainer from '../../molecules/administration/DetailsContainer';
import TrafficMonthDetails from '../../molecules/reports/TrafficMonthDetails';

interface MonthNames {
  [key: number]: string;
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  padding: theme.spacing(4),
}));

// TODO: View model
export default function TrafficReports() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['administration', 'analytics', 'common']);
  const { trafficReports, trafficReportsFetching } = useSelector((state: State) => state.reports);
  const tenantId = useSelector((state: State) => state.tenants.currentTenantId);
  const tenants = useSelector((state: State) => state.tenants.tenants);
  const currentTenant = tenants.find((tenant) => tenant.id === tenantId);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const monthNamesByIndex: MonthNames = useMemo(
    () => ({
      1: t('common:January'),
      10: t('common:October'),
      11: t('common:November'),
      12: t('common:December'),
      2: t('common:February'),
      3: t('common:March'),
      4: t('common:April'),
      5: t('common:May'),
      6: t('common:June'),
      7: t('common:July'),
      8: t('common:August'),
      9: t('common:September'),
    }),
    [t],
  );

  useEffect(() => {
    dispatch(fetchTrafficReports({ tenantId, year: selectedYear }));
  }, [tenantId, selectedYear]);

  const isReportDefaultOpened = (monthNumberStr: string): boolean => {
    if (Object.keys(trafficReports).length === 1) {
      return true;
    }

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    if (selectedYear === currentYear && monthNumberStr === currentMonth.toString()) {
      return true;
    }

    return false;
  };
  const hasDataToDisplay = Object.keys(trafficReports).length > 0;
  const loading = trafficReportsFetching.status === 'pending';

  const renderReports = () => {
    return (
      <Container boxShadow={2}>
        <Box sx={{ display: 'flex', mb: 3, pr: 5 }}>
          <Typography sx={{ flexShrink: 0, fontWeight: 'bold', width: '40%' }} variant="h6">
            {t('common:Period')}
          </Typography>
          <Box sx={{ width: '20%' }}>
            <Tooltip arrow title={`${t('analytics:Reports.LiveViewers.helperText')}`}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                {t('analytics:Reports.LiveViewers.text')}
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Tooltip arrow title={`${t('analytics:Reports.ReplayViewers.helperText')}`}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                {t('analytics:Reports.ReplayViewers.text')}
              </Typography>
            </Tooltip>
          </Box>
          <Box sx={{ width: '20%' }}>
            <Tooltip arrow title={`${t('analytics:Reports.ShoppableVideoViewers.helperText')}`}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                {t('analytics:Reports.ShoppableVideoViewers.text')}
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        {Object.keys(trafficReports).map((monthNumberStr) => {
          const monthNumber = +monthNumberStr;
          const monthName: string = monthNamesByIndex[monthNumber] || 'January';
          return (
            <TrafficMonthDetails
              defaultOpened={isReportDefaultOpened(monthNumberStr)}
              key={monthName}
              monthName={monthName}
              reports={trafficReports[monthNumber]}
            />
          );
        })}
      </Container>
    );
  };

  return (
    <DetailsContainer
      actionsLoading={false}
      filterChildren={
        currentTenant && (
          <YearSelector onSelectedYearChanged={setSelectedYear} selectedYear={selectedYear} tenant={currentTenant} />
        )
      }
      headerLoading={loading}
      title={t('common:TrafficReports')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          mb: 6,
          mx: {
            lg: 8,
            md: 2,
            xs: 0,
          },
        }}
      >
        {loading === false && <>{hasDataToDisplay ? renderReports() : <span>{t('common:NoDataYet')}</span>}</>}
      </Box>
    </DetailsContainer>
  );
}
