import { Box, styled } from '@mui/material';
import React from 'react';

import Tabs, { TabProps, tabWidthInPx } from '../components/molecules/Tabs';
import TabPanel from './TabPanel';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
});

const TabsContainer = styled(Box)({
  height: '100%',
  position: 'fixed',
  top: '64px',
});

type AdministrationTemplateProps = {
  activeTab: number;
  onChangeTab: (index: number) => void;
  tabs: TabProps[];
};

export default function AdministrationTemplate({ activeTab, onChangeTab, tabs }: AdministrationTemplateProps) {
  return (
    <Container>
      <Box height="100%" minWidth={tabWidthInPx}>
        <TabsContainer>
          <Tabs activeTab={activeTab} onChangeTab={onChangeTab} tabs={tabs} />
        </TabsContainer>
      </Box>
      <Box flex={1}>
        {tabs.map(({ component, index, label }) => (
          <TabPanel index={index} key={label} value={activeTab}>
            {component}
          </TabPanel>
        ))}
      </Box>
    </Container>
  );
}
