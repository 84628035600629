import { Auth0Client, User } from '@auth0/auth0-spa-js';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthenticationGateway } from '../../core/gateways/authentication-gateway';
import { RealtimeDataGateway } from '../../core/gateways/realtime-data-gateway';

export default class Auth0Gateway implements AuthenticationGateway {
  private auth0: Auth0Client;

  constructor(private realtimeDataGateway: RealtimeDataGateway) {
    this.auth0 = new Auth0Client({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
      domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
      useFormData: false,
      useRefreshTokens: true, // TODO https://github.com/auth0/auth0-spa-js/blob/master/MIGRATION_GUIDE.md#applicationx-www-form-urlencoded-is-used-by-default-instead-of-applicationjson
    });
  }

  public signFirebaseInWithCustomToken(user: User, customToken?: string): Observable<User> {
    return this.realtimeDataGateway.signInWithCustomToken(user, customToken);
  }

  public loginWithRedirect(): Observable<void> {
    const origin = window.location.origin;

    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const path = encodeURI(`${window.location.pathname}?${searchParams.toString()}`);

    return from(
      this.auth0.loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${origin}/auth/redirect?redirect_url=${path}`,
        },
      }),
    );
  }

  public handleRedirectCallback(): Observable<void> {
    return from(this.auth0.handleRedirectCallback()).pipe(map(() => void 0));
  }

  public getAccessToken(): Observable<string> {
    return from(this.auth0.getTokenSilently());
  }

  public getUser(): Observable<User | undefined> {
    return from(this.auth0.getUser());
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap(() => from(this.auth0.isAuthenticated())),
      catchError((error) => {
        if (error.message !== 'Login required') {
          console.log(error);
        }
        return of(false);
      }),
    );
  }

  public logout(): void {
    this.realtimeDataGateway.signOut();
    this.auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
}
