import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { CBOShoppableVideo } from '../../../domain/CBOShoppableVideo';
import { CBOShoppableVideoListItemReadModel } from '../../../domain/CBOShoppableVideoReadModel';
import { updateShoppableVideo } from '../../../usecases/shoppables/update-shoppable-video';
import { uploadShoppableThumbnailFile } from '../../../usecases/shoppables/upload-shoppable-thumbnail-file';
import { ShoppablesState } from '../../state/shoppables';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetUpdateShoppableVideo = createAction<void>('shoppables/reset-update-shoppable-video');

const updateShoppableVideoInState = (state: ShoppablesState, updatedDto: CBOShoppableVideo) => {
  let currentShoppable = state.currentShoppable;

  const updatedFields: Partial<CBOShoppableVideoListItemReadModel> = {
    thumbnailUrl: updatedDto.thumbnailUrl,
    title: updatedDto.title,
  };

  if (currentShoppable) {
    currentShoppable = {
      ...currentShoppable,
      ...updatedFields,
    };
  }

  const updatedShoppables = [...state.shoppables].map((shoppable) => {
    if (shoppable.id !== updatedDto.id) return shoppable;
    return {
      ...shoppable,
      ...updatedFields,
    };
  });

  return {
    currentShoppable,
    shoppables: updatedShoppables,
  };
};

export const buildVideoUpdateReducer = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(updateShoppableVideo.fulfilled, (state, action) => {
    return {
      ...state,
      ...successStatus('shoppableVideoUpdate'),
      ...updateShoppableVideoInState(state, action.payload),
    };
  });
  builder.addCase(updateShoppableVideo.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppableVideoUpdate'),
  }));
  builder.addCase(updateShoppableVideo.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableVideoUpdate', [action.error]),
  }));

  builder.addCase(uploadShoppableThumbnailFile.fulfilled, (state) => ({
    ...state,
    ...successStatus('shoppableThumbnailFileUploading'),
  }));
  builder.addCase(uploadShoppableThumbnailFile.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppableThumbnailFileUploading'),
  }));
  builder.addCase(uploadShoppableThumbnailFile.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableThumbnailFileUploading', [action.error]),
  }));
  builder.addCase(resetUpdateShoppableVideo, (state) => ({
    ...state,
    ...notRequestedStatus('shoppableVideoUpdate'),
  }));
};
