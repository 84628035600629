import { Cms, TCms } from '@bellepoque/api-contracts';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, MenuItem, SelectChangeEvent, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { COUNTRY_CODES, CountryCode } from '../../../../utils/countryCodes';
import { emailRegEx } from '../../../../utils/email-regex';
import CurrencySelector from '../../atoms/inputs/CurrencySelector';
import DetailsContainer from '../../molecules/administration/DetailsContainer';
import { createCreateTenantViewModel } from './CreateTenant.viewmodel';

type Inputs = {
  cms: TCms;
  countryCode: CountryCode;
  currencyId: string;
  masterEmail: string;
  tenantName: string;
};

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

export default function CreateTenant() {
  const { t } = useTranslation(['administration', 'common']);
  const dispatch = useDispatch();

  const viewModel = useSelector(createCreateTenantViewModel({ dispatch }));

  const { currencies, fetchCurrencies, onCreateTenant, createTenantStatus, currentTenant } = viewModel;

  const defaultValues: Inputs = {
    cms: Cms.Unknown,
    countryCode: 'US',
    currencyId: currentTenant.currency.id,
    masterEmail: '',
    tenantName: '',
  };

  const { formState, handleSubmit, register, reset, setValue } = useForm<Inputs>({ defaultValues });
  const { errors } = formState;

  const loadingInProgress = createTenantStatus === 'pending';

  const [cms, setCms] = useState<TCms>(Cms.Unknown);
  const [currency, setCurrency] = useState<CBOCurrency>(currentTenant.currency);
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultValues.countryCode);

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const handleChangeCurrency = (id: string) => {
    const foundCurrency = currencies.find((currency) => currency.id === id);
    if (foundCurrency) {
      setValue('currencyId', id);
      setCurrency(foundCurrency);
    }
  };

  const renderCountryCodesOptions = () => {
    return Object.values(COUNTRY_CODES).map((countryCode) => (
      <MenuItem key={countryCode} value={countryCode}>
        {countryCode}
      </MenuItem>
    ));
  };

  const renderCmsOptions = () => {
    const notShownCms: TCms[] = [Cms.Shopify];

    const cmsOptionsList: { label: string; value: TCms }[] = Object.values(Cms)
      .filter((value) => !notShownCms.includes(value))
      .map((value) => ({
        label: t(`common:Cms.${value}`),
        value,
      }));

    return cmsOptionsList.map(({ label, value }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  };

  const onSubmit = (data: Inputs) => {
    const payload = {
      cms: data.cms,
      countryCode: data.countryCode,
      currencyId: data.currencyId,
      masterEmail: data.masterEmail,
      tenantName: data.tenantName,
    };

    onCreateTenant(payload);
  };

  const onCancel = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    if (createTenantStatus === 'success') {
      reset(defaultValues);
    }
  }, [reset, createTenantStatus]);

  const { ref: masterEmailInputRef, ...masterEmailInputProps } = register('masterEmail', {
    pattern: {
      message: t('EmailValidationError'),
      value: emailRegEx,
    },
    required: { message: t('MasterEmailRequired'), value: true },
  });

  const { ref: tenantNameInputRef, ...tenantNameInputProps } = register('tenantName', {
    required: { message: t('TenantNameRequired'), value: true },
  });

  const { ref: cmsInputRef, ...cmsInputProps } = register('cms', {
    onChange: (event: SelectChangeEvent) => setCms(event.target.value as TCms),
    required: { message: t('CmsRequired'), value: true },
  });

  const { ref: countryCodeInputRef, ...countryCodeInputProps } = register('countryCode', {
    onChange: (event: SelectChangeEvent) => setCountryCode(event.target.value as CountryCode),
    required: { message: t('CountryCodeRequired'), value: true },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
      }}
    >
      <DetailsContainer actionsLoading={loadingInProgress} headerLoading={false} title={t('common:CreateTenant')}>
        <Container boxShadow={2}>
          <Box
            id="master-invitation-page-content"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '80%',
            }}
          >
            <TextField
              disabled={loadingInProgress}
              error={!!errors.masterEmail}
              fullWidth
              helperText={!!errors.masterEmail ? errors?.masterEmail?.message : ''}
              id="masterEmail"
              inputRef={masterEmailInputRef}
              label={t('MasterEmail')}
              required
              variant="outlined"
              {...masterEmailInputProps}
            />
            <TextField
              disabled={loadingInProgress}
              error={!!errors.tenantName}
              fullWidth
              helperText={!!errors.tenantName ? errors?.tenantName?.message : ''}
              id="masterEmail"
              inputRef={tenantNameInputRef}
              label={t('TenantName')}
              required
              variant="outlined"
              {...tenantNameInputProps}
            />
            <TextField
              disabled={loadingInProgress}
              fullWidth
              id="cms"
              inputRef={cmsInputRef}
              label={t('common:Cms.Cms')}
              required
              select
              sx={{ textAlign: 'initial' }}
              value={cms}
              variant="outlined"
              {...cmsInputProps}
            >
              {renderCmsOptions()}
            </TextField>
            <TextField
              disabled={loadingInProgress}
              fullWidth
              id="countryCode"
              inputRef={countryCodeInputRef}
              label={t('common:CountryCode')}
              required
              select
              sx={{ textAlign: 'initial' }}
              value={countryCode}
              variant="outlined"
              {...countryCodeInputProps}
            >
              {renderCountryCodesOptions()}
            </TextField>
            <CurrencySelector
              currencies={currencies}
              currentCurrency={currency}
              disabled={loadingInProgress}
              onChange={handleChangeCurrency}
              required
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              color="primary"
              disabled={!formState.isDirty || loadingInProgress}
              onClick={onCancel}
              startIcon={<ClearIcon />}
              variant="contained"
            >
              <span>{t('common:Cancel')}</span>
            </Button>
            <Button
              color="primary"
              disabled={!formState.isDirty || loadingInProgress}
              startIcon={<SendIcon />}
              type="submit"
              variant="contained"
            >
              <span>{t('CreateTenant')}</span>
            </Button>
          </Box>
        </Container>
      </DetailsContainer>
    </form>
  );
}
