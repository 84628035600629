import { Box, Button, Typography, alpha, lighten, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(4)}`,
}));

const BetaFeatureContainer = styled(Box)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.secondary.main, 0.9),
  border: `solid ${alpha(theme.palette.secondary.main, 0.7)} 1px`,
  borderRadius: '15px',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

type MagicContentHeaderProps = {
  hasMagicContentPending: boolean;
  hasMagicContents: boolean;
  isTranscriptAvailable: boolean;
  onGenerateMagicContent: () => void;
};

export default function MagicContentHeader({
  hasMagicContentPending,
  hasMagicContents,
  isTranscriptAvailable,
  onGenerateMagicContent,
}: MagicContentHeaderProps) {
  const { t } = useTranslation('events', { keyPrefix: 'MagicContent' });
  return (
    <Container boxShadow={2} mb={2}>
      <BetaFeatureContainer mb={1}>
        <Typography whiteSpace="pre-line">{t('BetaFeature')}</Typography>
      </BetaFeatureContainer>

      <Typography textAlign="initial" whiteSpace="pre-line">
        {t('Intro')}
      </Typography>
      {!hasMagicContentPending && (
        <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" mt={2}>
          <Button disabled={!isTranscriptAvailable} onClick={onGenerateMagicContent} variant="contained">
            {t(hasMagicContents ? 'GenerateAgain' : 'Generate')}
          </Button>
          <Typography fontSize=".8em" mt={0.5}>
            {t('MistakesDisclaimer')}
          </Typography>
          {!isTranscriptAvailable && (
            <Typography fontSize=".8em" fontWeight="bold" sx={{ color: (theme) => theme.palette.secondary.main }}>
              {t('AudioProcessing')}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
}
