import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type UnpinMessageParams = {
  eventId: EventId;
  messageId: string;
};

export const unpinMessage = createAppAsyncThunk(
  'events/unpin-message',
  async ({ eventId, messageId }: UnpinMessageParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.unpinMessage(eventId, messageId);
  },
);
