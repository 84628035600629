import { CBOEventListReadModel } from './CBOEventListReadModel';
import { CBOReplayChapter } from './CBOReplaysChapters';
import { CBOShoppableVideoListItemReadModel } from './CBOShoppableVideoReadModel';

type CBOMediaCollectionMediaBase = {
  image: string | null;
  title: string;
};

export type CBOMediaCollectionMediaReplay = CBOMediaCollectionMediaBase & {
  id: string;
  type: 'replay';
};

export type CBOMediaCollectionMediaReplayChapter = CBOMediaCollectionMediaBase & {
  duration: number;
  endTimeStamp: number;
  id: string;
  replayId: string;
  startTimeStamp: number;
  type: 'replay-chapter';
};

export type CBOMediaCollectionMediaShoppable = CBOMediaCollectionMediaBase & {
  duration: number | null;
  id: string;
  productId: string;
  productImage: string | null;
  productOriginalId: string;
  productTitle: string;
  type: 'shoppable';
};

export type CBOMediaCollectionMedia =
  | CBOMediaCollectionMediaReplay
  | CBOMediaCollectionMediaReplayChapter
  | CBOMediaCollectionMediaShoppable;

export const isCBOMediaCollectionMediaShoppable = (
  media: CBOMediaCollectionMedia,
): media is CBOMediaCollectionMediaShoppable => media.type === 'shoppable';

export const shoppableVideoListItemReadModelToMediaCollectionMedia = (
  shoppableVideo: CBOShoppableVideoListItemReadModel,
): CBOMediaCollectionMediaShoppable => ({
  duration: shoppableVideo.videoDuration,
  id: shoppableVideo.id,
  image: shoppableVideo.thumbnailUrl,
  productId: shoppableVideo.productId,
  productImage: shoppableVideo.productImage,
  productOriginalId: shoppableVideo.productOriginalId,
  productTitle: shoppableVideo.productTitle,
  title: shoppableVideo.title,
  type: 'shoppable',
});

export const eventListReadModelToMediaCollectionMedia = (
  replay: CBOEventListReadModel,
): CBOMediaCollectionMediaReplay => ({
  id: replay.id,
  image: replay.image,
  title: replay.title,
  type: 'replay',
});

export const replayChapterToMediaCollectionMedia = (
  chapter: CBOReplayChapter,
): CBOMediaCollectionMediaReplayChapter => ({
  duration: chapter.duration,
  endTimeStamp: chapter.endAt,
  id: `${chapter.replayId}-${chapter.startAt}-${chapter.endAt}`,
  image: chapter.image,
  replayId: chapter.replayId,
  startTimeStamp: chapter.startAt,
  title: chapter.title,
  type: 'replay-chapter',
});
