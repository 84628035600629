import { EventId } from '@bellepoque/api-contracts';
import { Box, Button, Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SingleSelect from '../../../atoms/inputs/SingleSelect';

interface CopyLastEventSectionProps {
  onCopy: () => void;
  onSetCopiedEventId: (eventId: EventId) => void;
  options: { label: string; value: EventId }[];
  submissionInProgress: boolean;
  value: EventId;
}

const SelectContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  width: '370px',
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: `20px 20px 0 0`,
  flex: 1,
  padding: theme.spacing(3),
}));

export default function CopyLastEventSection(props: CopyLastEventSectionProps) {
  const { onCopy, options, onSetCopiedEventId, submissionInProgress, value } = props;
  const { t } = useTranslation(['events', 'common']);

  return (
    <Container boxShadow={2}>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Grid alignItems="center" container display="flex" gap={3}>
            <SelectContainer>
              <SingleSelect
                disabled={submissionInProgress}
                label={t('CopyEvent')}
                noOptionsMessage={t('NoEventsFound')}
                onChange={onSetCopiedEventId}
                options={options}
                value={value}
              />
            </SelectContainer>
            <Box marginLeft="10px" marginTop="6px">
              <Button
                color="primary"
                disabled={submissionInProgress}
                onClick={onCopy}
                sx={{ textTransform: 'initial' }}
                variant="text"
              >
                <span>{t('common:Copy')}</span>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
