import { Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import ValueCard from '../../atoms/home/ValueCard';

type WithSales = {
  currency: CBOCurrency;
  hasSales: true;
  purchasedAmounts: number;
  purchasedCarts: number;
};

type WithoutSales = {
  hasSales: false;
};

type ShoppableVideoIntroSectionProps = {
  views: number;
} & (WithoutSales | WithSales);

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));

export default function ShoppableVideoIntroSection(props: ShoppableVideoIntroSectionProps) {
  const { t } = useTranslation('analytics');

  const { hasSales, views } = props;

  return (
    <Container container>
      <ValueCard
        sx={hasSales ? {} : { flex: 1 / 3 }}
        title={t('Traffic.Views')}
        totalValue={views}
        valueType="number"
      />
      {hasSales && (
        <>
          <ValueCard
            title={t('ECommerce.Orders')}
            tooltip={t('analytics:ConversionTrackerDisclaimer')}
            totalValue={props.purchasedCarts}
            valueType="number"
          />
          <ValueCard
            currency={props.currency}
            title={t('ECommerce.Sales')}
            tooltip={t('analytics:ConversionTrackerDisclaimer')}
            totalValue={props.purchasedAmounts}
            valueType="currency"
          />
        </>
      )}
    </Container>
  );
}
