import { EventId } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { FC } from 'react';

import { CBOEventListReadModel } from '../../core/domain/CBOEventListReadModel';
import { CBOLandingPageData } from '../../core/domain/CBOLandingPageData';
import { QueryStatus } from '../../core/store/state/utils';
import FixedContentHeader from '../components/molecules/FixedContentHeader';
import { ReplaysHeader } from '../components/molecules/ReplaysHeader';
import EventList from '../components/organisms/event/EventListGrid';

const EVENTS_LIST_CONTAINER_OFFSET = 10;

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const EventListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  // be sure to maintain marginTop === paddingBottom for virtual scroll works properly
  marginTop: theme.spacing(EVENTS_LIST_CONTAINER_OFFSET),
  paddingBottom: theme.spacing(EVENTS_LIST_CONTAINER_OFFSET),
}));

type ReplaysTemplateProps = {
  canShareEvents: boolean;
  isDrawerOpen: boolean;
  landingPageData: CBOLandingPageData;
  loading: boolean;
  onGoToReplayDetails: (id: EventId) => void;
  replays: CBOEventListReadModel[];
  replaysFetchingStatus: QueryStatus;
};

const ReplaysTemplate: FC<ReplaysTemplateProps> = ({
  canShareEvents,
  isDrawerOpen,
  loading,
  onGoToReplayDetails,
  landingPageData,
  replays,
}) => (
  <Container>
    <FixedContentHeader isDrawerOpen={isDrawerOpen} isShadowHidden>
      <ReplaysHeader loading={loading} />
    </FixedContentHeader>
    <EventListContainer>
      <EventList
        canShareEvents={canShareEvents}
        events={replays}
        eventsType="finished"
        landingPageData={landingPageData}
        onShowEvent={onGoToReplayDetails}
      />
    </EventListContainer>
  </Container>
);

export default ReplaysTemplate;
