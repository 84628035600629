import { SecuredReplayFileUploadUrls } from '../../../domain/Theme';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type GetSecuredReplayFileUploadUrlsParams = {
  replayId: string;
};

export const getSecuredReplayFileUploadUrls = createAppAsyncThunk(
  'events/get-secured-replay-file-upload-urls',
  async (
    { replayId }: GetSecuredReplayFileUploadUrlsParams,
    { extra: { eventGateway } },
  ): Promise<SecuredReplayFileUploadUrls> => {
    return eventGateway.getSecuredReplayFileUploadUrls(replayId);
  },
);
