import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function TapcartIcon(props: SvgIconProps) {
  return (
    <SvgIcon fill="none" height="364" viewBox="0 0 419 364" width="419" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M398.644 133.781L282.884 20.2428C276.415 13.8687 268.725 8.81086 260.257 5.35961C251.79 1.90835 242.71 0.131714 233.54 0.131714C224.37 0.131714 215.291 1.90835 206.823 5.35961C198.355 8.81086 190.666 13.8687 184.197 20.2428L132.682 71.0106C159.613 75.2238 184.129 88.7163 201.829 109.065C219.529 129.413 229.252 155.284 229.252 182.032C229.252 208.779 219.529 234.651 201.829 254.999C184.129 275.347 159.613 288.84 132.682 293.053L184.443 343.869C190.913 350.243 198.602 355.301 207.07 358.753C215.538 362.204 224.617 363.98 233.787 363.98C242.957 363.98 252.036 362.204 260.504 358.753C268.972 355.301 276.661 350.243 283.13 343.869L398.644 230.283C411.679 217.48 419 200.126 419 182.032C419 163.938 411.679 146.584 398.644 133.781Z"
        fill={props.fill}
      />
      <path
        d="M131.101 72.5588L132.68 71.0101C116.308 68.4486 99.563 69.4013 83.6019 73.8025C67.6408 78.2036 52.8432 85.9485 40.2298 96.5028C27.6165 107.057 17.4877 120.17 10.5423 134.936C3.5968 149.702 0 165.77 0 182.031C0 198.293 3.5968 214.361 10.5423 229.127C17.4877 243.893 27.6165 257.006 40.2298 267.56C52.8432 278.114 67.6408 285.859 83.6019 290.26C99.563 294.661 116.308 295.614 132.68 293.053L131.101 291.504C101.512 262.463 84.8904 223.087 84.8904 182.031C84.8904 140.975 101.512 101.599 131.101 72.5588Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}
