import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ColorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32.117 32.105">
      <g transform="translate(0.032 0.041)">
        <g>
          <path d="M25.62,12.69a10,10,0,1,0-19.24,0A10,10,0,1,0,16,30a10,10,0,1,0,9.62-17.29ZM16,2a8,8,0,0,1,7.7,10.16A9.8,9.8,0,0,0,16,14a9.8,9.8,0,0,0-7.7-1.86A8,8,0,0,1,16,2Zm6.89,12.05a8,8,0,0,1-4,3.4,10,10,0,0,0-1.4-2.05,7.91,7.91,0,0,1,5.4-1.35Zm-6,3.9a7.58,7.58,0,0,1-1.77,0A8,8,0,0,1,16,16.73,8,8,0,0,1,16.89,17.95ZM14.51,15.4a10,10,0,0,0-1.4,2.05,8,8,0,0,1-4-3.4,7.91,7.91,0,0,1,5.4,1.35ZM10,30A8,8,0,0,1,7.11,14.55a10,10,0,0,0,5.27,4.76,9.89,9.89,0,0,0,2.13,9.29A8,8,0,0,1,10,30Zm4-8a8,8,0,0,1,.3-2.16,9.36,9.36,0,0,0,3.39,0A7.91,7.91,0,0,1,16,27.27,8,8,0,0,1,14,22Zm8,8a8,8,0,0,1-4.51-1.4,9.89,9.89,0,0,0,2.13-9.29,10,10,0,0,0,5.27-4.76A8,8,0,0,1,22,30Z" />
        </g>
      </g>
    </SvgIcon>
  );
}
