import { Box, ButtonProps, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormDialog from '../../../templates/dialog/FormDialog';
import UploadImageSquareBox from '../../atoms/inputs/UploadImageSquareBox';

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  width: '100%',
}));

const ImagePreviewContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',
  flex: 1,
  height: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: theme.spacing(2),
}));

const ImagePreview = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
});

const HelperText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isError' })<{ isError?: boolean }>(
  ({ isError, theme }) => ({
    fontSize: '10px',
    ...(isError && {
      color: theme.palette.error.main,
      fontSize: '12px',
      fontWeight: 'bold',
    }),
  }),
);

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  paddingTop: theme.spacing(2),
}));

export type UpdateReplayCoverDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
  submissionInProgress: boolean;
};

export default function UpdateReplayCoverDialog({
  isOpen,
  onSubmit,
  submissionInProgress,
  onClose,
}: UpdateReplayCoverDialogProps) {
  const { t } = useTranslation('events');

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hasCheckedImageOrientation, setHasCheckedImageOrientation] = useState<boolean>(false);
  const [isVertical, setIsVertical] = useState<boolean>();

  useEffect(() => {
    setIsVertical(false);
    setSelectedFile(null);
    setHasCheckedImageOrientation(false);
  }, [isOpen]);

  useEffect(() => {
    if (selectedFile) {
      setHasCheckedImageOrientation(false);
    }
  }, [selectedFile]);

  const checkImageOrientation = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      if (!selectedFile) return;

      const { naturalHeight, naturalWidth } = event.currentTarget;
      const isVertical = naturalHeight >= naturalWidth;

      setIsVertical(isVertical);
      setHasCheckedImageOrientation(true);

      if (!isVertical) {
        setSelectedFile(null);
      }
    },
    [selectedFile],
  );

  const handleClose = () => {
    onClose();
  };

  const handleSelectedFile = (file: File) => {
    setSelectedFile(file);
  };

  const buttonsProps: ButtonProps[] = [
    {
      children: <span>{t('common:Cancel')}</span>,
      color: 'primary',
      disabled: submissionInProgress,
      onClick: onClose,
      variant: 'text',
    },
    {
      children: <span>{t('common:SaveAndClose')}</span>,
      color: 'primary',
      disabled: submissionInProgress || !hasCheckedImageOrientation || !isVertical || !selectedFile,
      onClick: selectedFile ? () => onSubmit(selectedFile) : undefined,
      variant: 'contained',
    },
  ];

  return (
    <FormDialog
      DialogContentProps={{
        sx: {
          display: 'flex',
          flex: 1,
          paddingTop: 0,
        },
      }}
      buttonsProps={buttonsProps}
      loading={false}
      onClose={handleClose}
      open={isOpen}
      sx={{
        display: 'flex',
        height: '500px',
        width: '350px',
      }}
    >
      <ContentContainer>
        <Title>{t('ChangeCover')}</Title>
        {selectedFile ? (
          <ImagePreviewContainer>
            <ImagePreview onLoad={checkImageOrientation} src={URL.createObjectURL(selectedFile)} />
          </ImagePreviewContainer>
        ) : (
          <UploadImageSquareBox
            buttonDescription={t('common:DragNDropImage') + '*'}
            buttonLabel={t('common:Select')}
            errorMessage={t('common:ErrorPleaseRetry')}
            helperText={
              <HelperText isError={hasCheckedImageOrientation && !isVertical}>{t('VerticalImageOnly')}</HelperText>
            }
            onSelectFile={handleSelectedFile}
          />
        )}
      </ContentContainer>
    </FormDialog>
  );
}
