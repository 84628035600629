import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ReplaysIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 256 256" {...props}>
      <g>
        <path d="M136.6,29.4c-30,3.7-56,19.5-72.3,44.3c-9.1,13.9-14.4,29.1-15.9,46.6l-0.7,7.1H28.9c-10.4,0-18.9,0.2-18.9,0.4c0,0.2,11.5,11.9,25.6,26l25.6,25.6l25.6-25.6c14.1-14.1,25.6-25.8,25.6-26c0-0.3-8.6-0.4-19-0.4H74.2l0.4-4.6c1.1-13.3,5-24.4,12.1-35c20.1-30,58.2-40.8,91-25.6c13.8,6.3,27.2,19.4,34.2,33.2c2.3,4.5,5.3,13.6,6.4,19.2c1.2,5.9,1.3,19.5,0.2,25.4c-4.7,26.5-23.5,48.3-49.2,56.9c-7.6,2.6-13,3.4-22.7,3.4c-6.9,0-10-0.3-14.4-1.2c-12.6-2.7-23.4-8.1-33-16.4c-1.8-1.7-3.6-3-3.9-3c-0.2,0-4.5,4.1-9.5,9c-10.5,10.5-10.1,8.8-2.5,15.1c20.5,17.1,47.5,25.4,73.4,22.6c39.2-4.2,71.7-30.3,84-67.2c11-33,4.1-68.5-18.7-95.6c-14.1-16.8-35.9-29.3-58-33.1C156.8,29.2,142.9,28.6,136.6,29.4z" />
        <path d="M127.8,127.7c0,36.2,0.1,43.2,0.8,42.8c0.4-0.2,12.2-10,26.1-21.6c24.2-20.1,25.4-21.2,24.4-22.1c-1.9-1.8-50.8-42.3-51.1-42.3C127.9,84.4,127.8,103.9,127.8,127.7z" />
      </g>
    </SvgIcon>
  );
}
