import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function FontIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 34 30" {...props}>
      <g transform="translate(-18 -22)">
        <path
          d="M42.564,25.026A4.232,4.232,0,0,0,38.507,22H29.018l-8.1,25.39H18v4.232H28.05V47.39H25.358l1.857-5.818h8.463l1.735,5.818H34.4v4.232H51.853V47.39H49.208ZM28.579,37.339,31.6,27.866,34.4,37.339Zm6.94-11.108h2.989l6.3,21.158h-3Z"
          transform="translate(0)"
        />
      </g>
    </SvgIcon>
  );
}
