import { Box, styled, useTheme } from '@mui/material';
import i18next from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { formatCurrency } from '../../../../utils/currency-formatter';
import StatsTitle from '../../atoms/analytics/StatsTitle';

const MIN_SIZE = 10;
const MAX_SIZE = 20;

const Circle = styled('div')<{ size: number }>(({ size }) => ({
  backgroundColor: 'black',
  borderRadius: '100%',
  height: `${size}px`,
  maxHeight: `${MAX_SIZE}px`,
  maxWidth: `${MAX_SIZE}px`,
  minHeight: `${MIN_SIZE}px`,
  minWidth: `${MIN_SIZE}px`,
  width: `${size}px`,
}));

const CircleContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${MAX_SIZE}px;
`;

const CircleWithValueContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 2px;
`;

const Container = styled(Box)`
  flex-grow: 0;
  width: 200px;
`;
const MainValue = styled('span')`
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 2px;
`;

const LargeValue = styled(MainValue)`
  font-size: 2.5em;
`;

const MainPercentage = styled(MainValue)`
  font-weight: initial;
  margin-left: 5px;
`;

const TotalContainer = styled(Box)`
  margin-bottom: 10px;
`;

const Value = styled('span')`
  font-size: 0.9em;
  font-weight: bold;
`;

const Percentage = styled(Value)`
  font-weight: normal;
  margin-left: 5px;
`;

const ValueContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
});

const ValueTitle = styled('span')`
  display: block;
  font-size: 0.7em;
  text-transform: uppercase;
`;

const ValueType = styled('span')`
  display: inline-block;
  font-size: 0.7em;
  text-transform: uppercase;
`;

const getCircleSize = (currentValue: number, allValuesSum: number): number => {
  if (currentValue === 0 && allValuesSum === 0) return MAX_SIZE / 2;

  return (currentValue / allValuesSum) * MAX_SIZE;
};

const getLiveCircleSize = (liveValue?: number, replayValue?: number): number => {
  if (liveValue === undefined || replayValue === undefined) return MAX_SIZE / 2;
  return getCircleSize(liveValue, liveValue + replayValue);
};

const getReplayCircleSize = (liveValue?: number, replayValue?: number): number => {
  if (replayValue === undefined) return MIN_SIZE;
  if (liveValue === undefined) return MAX_SIZE;
  return getCircleSize(replayValue, liveValue + replayValue);
};

type StatsItemProps = {
  hideLiveLabel?: boolean;
  livePercentage?: number;
  liveValue?: number;
  replayPercentage?: number;
  replayValue?: number;
  subtext?: string;
  title: string;
  totalPercentage?: number;
  totalValue?: number;
  valueSize?: 'large' | 'medium';
} & (
  | {
      currency: CBOCurrency;
      format: 'currency';
    }
  | {
      format: 'number' | 'time';
    }
);

export default function StatsItem(props: StatsItemProps) {
  const {
    format,
    livePercentage,
    liveValue,
    replayPercentage,
    replayValue,
    hideLiveLabel = false,
    subtext,
    title,
    totalPercentage,
    totalValue,
    valueSize = 'medium',
  } = props;
  const { t } = useTranslation('analytics');
  const theme = useTheme();

  const formatValue = (value: number, round = false): string => {
    switch (format) {
      case 'currency':
        return formatCurrency(value, props.currency, { round });
      case 'number':
        return `${value.toLocaleString(i18next.language)}`;
      case 'time':
        const timeInSeconds = value / 1000;
        const seconds = Math.floor(timeInSeconds % 60);
        const minutes = Math.floor(timeInSeconds / 60) % 60;
        const hours = Math.floor(timeInSeconds / 3600);

        const secondsString = `${seconds}${t('common:Time.s')}`;
        const minutesString = `${minutes.toLocaleString(i18next.language)}${t('common:Time.m')}`;
        const hoursString = `${hours.toLocaleString(i18next.language)}${t('common:Time.h')}`;

        if (hours > 100) return hoursString;
        if (hours > 0) return `${hoursString} ${minutesString.padStart(2, '0')} ${secondsString.padStart(2, '0')}`;
        if (minutes > 0) return `${minutesString} ${secondsString.padStart(2, '0')}`;
        return secondsString;

      default:
        return `${value}`;
    }
  };

  const hasLivePercentage = livePercentage !== undefined;
  const hasLiveValue = liveValue !== undefined;
  const hasReplayPercentage = replayPercentage !== undefined;
  const hasReplayValue = replayValue !== undefined;
  const hasTotalPercentage = totalPercentage !== undefined;

  const liveColor = theme.palette.primary.main;

  return (
    <Container>
      <StatsTitle content={title} />
      <TotalContainer>
        <ValueTitle>{t('Total')}</ValueTitle>
        {valueSize === 'large' && (
          <LargeValue>
            {totalValue === undefined ? t('common:NotApplicable') : formatValue(totalValue, true)}
          </LargeValue>
        )}
        {valueSize === 'medium' && (
          <MainValue>{totalValue === undefined ? t('common:NotApplicable') : formatValue(totalValue, true)}</MainValue>
        )}
        {hasTotalPercentage && <MainPercentage>{parseFloat(totalPercentage.toFixed(2))}%</MainPercentage>}
        {!!subtext && <Value sx={{ display: 'block', fontSize: '.8em' }}>{subtext}</Value>}
      </TotalContainer>

      {!hideLiveLabel && (
        <CircleWithValueContainer>
          <CircleContainer>
            <Circle size={getLiveCircleSize(liveValue, replayValue)} sx={{ backgroundColor: liveColor }} />
          </CircleContainer>

          <ValueContainer>
            <ValueType sx={{ color: liveColor }}>{t('Live')}</ValueType>
            {hasLiveValue && (
              <Box>
                <Value sx={{ color: liveColor }}>{formatValue(liveValue)}</Value>
                {hasLivePercentage && hasReplayPercentage && (
                  <Percentage sx={{ color: liveColor }}>
                    {(livePercentage || 0).toLocaleString(i18next.language, { style: 'percent' })}
                  </Percentage>
                )}
              </Box>
            )}
          </ValueContainer>
        </CircleWithValueContainer>
      )}

      {hasReplayValue && (
        <CircleWithValueContainer>
          <CircleContainer>
            <Circle size={getReplayCircleSize()} />
          </CircleContainer>
          <ValueContainer>
            <ValueType>{t('Replay')}</ValueType>
            <Box>
              <Value>{formatValue(replayValue)}</Value>
              {hasLivePercentage && hasReplayPercentage && (
                <Percentage>
                  {(replayPercentage || 0).toLocaleString(i18next.language, { style: 'percent' })}
                </Percentage>
              )}
            </Box>
          </ValueContainer>
        </CircleWithValueContainer>
      )}
    </Container>
  );
}

type CustomStatsItemValue = {
  text: string;
  value: number;
};

interface CustomStatsItemProps {
  format: 'number' | 'percentage';
  isNotApplicable?: boolean;
  isTotalHidden?: boolean;
  title: string;
  values: CustomStatsItemValue[];
}

export function CustomStatsItem({
  format,
  isTotalHidden = false,
  isNotApplicable = false,
  title,
  values,
}: CustomStatsItemProps) {
  const { t } = useTranslation(['analytics', 'common']);

  const formatValue = (value: number): string => {
    switch (format) {
      case 'number':
        return `${value.toLocaleString(i18next.language)}`;
      case 'percentage':
        return value.toLocaleString(i18next.language, { style: 'percent' });
    }
  };

  const valuesSum = useMemo(() => values.reduce((total, current) => total + current.value, 0), [values]);

  const renderValue = useCallback(
    ({ text, value }: CustomStatsItemValue) => {
      return (
        <CircleWithValueContainer>
          <CircleContainer>
            <Circle size={getCircleSize(value, valuesSum)} />
          </CircleContainer>
          <ValueContainer>
            <ValueType>{text}</ValueType>
            <Value>{isNotApplicable ? t('common:NotApplicable') : formatValue(value)}</Value>
          </ValueContainer>
        </CircleWithValueContainer>
      );
    },
    [valuesSum],
  );

  return (
    <Container>
      <StatsTitle content={title} />
      <TotalContainer sx={{ ...(isTotalHidden && { display: 'none' }) }}>
        <ValueTitle>{t('Total')}</ValueTitle>
        <MainValue>{isNotApplicable ? t('common:NotApplicable') : formatValue(valuesSum)}</MainValue>
      </TotalContainer>

      {values.map(renderValue)}
    </Container>
  );
}
