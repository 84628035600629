import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export default function HelpAndSupportIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 35 35" {...props}>
      <path
        d="M87.5,2.8A14.7,14.7,0,1,1,77.1,7.105,14.7,14.7,0,0,1,87.5,2.8m0-2.8A17.5,17.5,0,1,0,99.872,5.126,17.5,17.5,0,0,0,87.5,0Z"
        fill={theme.palette.common.white}
        transform="translate(-69.998)"
      />
      <path
        d="M263.362,131.5l.378.035h0a7.352,7.352,0,0,1,2.7.7,4.5,4.5,0,0,1,1.862,1.592,4.113,4.113,0,0,1,.633,1.813,4.012,4.012,0,0,1-.133,1.666,4.328,4.328,0,0,1-1.015,1.719,11.6,11.6,0,0,1-1.75,1.438c-.35.241-.662.493-.991.746a2.1,2.1,0,0,0-.648.934c-.07.189-.15.378-.224.567h0a1.306,1.306,0,0,1-1.218.864,1.7,1.7,0,0,1-.885-.14,1.445,1.445,0,0,1-.794-1.081,2.485,2.485,0,0,1,.42-1.855,5.093,5.093,0,0,1,1.1-1.162c.458-.374.931-.731,1.4-1.106a3.689,3.689,0,0,0,.959-1.1,2.054,2.054,0,0,0-.245-2.275,1.891,1.891,0,0,0-1.05-.581,3.343,3.343,0,0,0-1.631.028,1.984,1.984,0,0,0-1.264,1.05c-.165.308-.308.623-.462.935a2.276,2.276,0,0,1-.451.633,1.564,1.564,0,0,1-1.186.382,1.642,1.642,0,0,1-.749-.2,1.3,1.3,0,0,1-.7-1.155,3.387,3.387,0,0,1,.7-2.142,5.4,5.4,0,0,1,2.1-1.7,6.4,6.4,0,0,1,1.992-.574l.392-.039Z"
        fill={theme.palette.common.white}
        transform="translate(-245.702 -123.27)"
      />
      <path
        d="M315.034,370.181c-.105-.021-.21-.039-.311-.066a1.939,1.939,0,1,1,.84.031l-.171.035Z"
        fill={theme.palette.common.white}
        transform="translate(-298.069 -343.407)"
      />
    </SvgIcon>
  );
}
