import Box from '@mui/material/Box';
import React from 'react';

interface PageCountItemProps {
  filled: boolean;
}

export default function PageCountItem({ filled }: PageCountItemProps) {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        height: '3px',
        opacity: filled ? 1 : 0.4,
        width: '30px',
      }}
    />
  );
}
