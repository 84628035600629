import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, IconButton, styled } from '@mui/material';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { UploadProps } from '../../atoms/inputs/UploadFile';
import UploadImageCircleBox from '../../atoms/inputs/UploadImageCircleBox';

const ThumbnailSelectorContainer = styled(Box)({
  display: 'flex',
  width: '100%',
});

const ThumbnailChoicesContainer = styled(Box)({
  '&:after': {
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%)',
    content: `''`,
    height: '100%',
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    width: '50px',
  },
  alignItems: 'center',
  display: 'flex',
  gap: '16px',
  height: 100,
  overflowY: 'auto',

  padding: '0 32px',
});

const ThumbnailUploadContainer = styled(Box)(({ theme }) => ({
  '&:after': {
    backgroundColor: theme.palette.grey['400'],
    content: `''`,
    height: '80px',
    position: 'absolute',
    right: 0,
    top: 10,
    width: '1px',
  },
  background: theme.palette.common.white,
  left: 0,
  padding: `${theme.spacing(1)} 0`,
  paddingRight: theme.spacing(2),
  position: 'sticky',
  zIndex: 100,
}));

const ThumbnailChoice = styled(Box, { shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'isSelected' })<{
  disabled?: boolean;
  isSelected?: boolean;
}>(({ disabled, isSelected, theme }) => ({
  position: 'relative',
  ...(isSelected && {
    '&:before': {
      border: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      content: `''`,
      height: `calc(100% + ${theme.spacing(2)})`,
      left: theme.spacing(-1),
      position: 'absolute',
      top: theme.spacing(-1),
      width: `calc(100% + ${theme.spacing(2)})`,
      zIndex: 10,
    },
  }),
  ...(disabled && {
    pointerEvents: 'none',
  }),
}));

const ImageContainer = styled(Box)({
  alignItems: 'center',
  borderRadius: '50%',
  display: 'flex',
  height: '80px',
  justifyContent: 'start',
  lineHeight: 'initial',
  maxHeight: '80px',
  maxWidth: '80px',
  overflow: 'hidden',
  position: 'relative',
  width: '80px',
});

const PlayIconButton = styled(IconButton)(({ theme }) => ({
  '&:disabled': {
    background: 'rgba(0, 0, 0, 0.5)',
    color: '#a6a6a6',
  },
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.35)',
  },
  background: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  height: '100%',
  left: 0,
  padding: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const ThumbnailImage = styled('img')({
  height: '100%',
  margin: '0 auto',
  objectFit: 'cover',
  width: '100%',
});

export type ThumbnailProps = {
  disabled?: boolean;
  src: string;
};

export const Thumbnail: FC<ThumbnailProps> = ({ disabled, src }) => (
  <ImageContainer>
    <ThumbnailImage src={src} />
    <PlayIconButton disabled={disabled}>
      <PlayIcon />
    </PlayIconButton>
  </ImageContainer>
);

export type ThumbnailSelectorProps = Pick<UploadProps, 'buttonLabel' | 'disabled' | 'onSelectFile'> & {
  defaultSelectedThumbnail?: string;
  imageUrls: string[];
  onSelectThumbnail?: (thumbnailUrl: string) => void;
};

const ThumbnailSelector: FC<ThumbnailSelectorProps> = ({
  buttonLabel,
  defaultSelectedThumbnail,
  disabled,
  imageUrls,
  onSelectFile,
  onSelectThumbnail,
}) => {
  const [selectedThumbnailChoiceUrl, setSelectedThumbnailChoiceUrl] = useState(defaultSelectedThumbnail);
  const [thumbnailPreviewFile, setThumbnailPreviewFile] = useState<File | null>(null);

  const thumbnailPreviewUrl = useRef<string | null>(null);

  const handleSelectThumbnailFile = useCallback((thumbnailFile: File) => {
    setThumbnailPreviewFile(thumbnailFile);
    const thumbnailUrl = URL.createObjectURL(thumbnailFile);
    thumbnailPreviewUrl.current = thumbnailUrl;
    setSelectedThumbnailChoiceUrl(thumbnailUrl);
    onSelectFile?.(thumbnailFile);
  }, []);

  const handleSelectThumbnailUrl = useCallback(
    (url: string, file?: File) => () => {
      setSelectedThumbnailChoiceUrl(url);

      if (file) {
        onSelectFile?.(file);
        return;
      }

      onSelectThumbnail?.(url);
    },
    [onSelectFile, onSelectThumbnail, thumbnailPreviewFile],
  );

  useEffect(() => {
    setSelectedThumbnailChoiceUrl(defaultSelectedThumbnail);
  }, [defaultSelectedThumbnail]);

  return (
    <ThumbnailSelectorContainer>
      <ThumbnailUploadContainer>
        <UploadImageCircleBox buttonLabel={buttonLabel} disabled={disabled} onSelectFile={handleSelectThumbnailFile} />
      </ThumbnailUploadContainer>
      <ThumbnailChoicesContainer>
        {thumbnailPreviewFile && thumbnailPreviewUrl.current && (
          <ThumbnailChoice
            disabled={disabled}
            isSelected={selectedThumbnailChoiceUrl === thumbnailPreviewUrl.current}
            onClick={disabled ? undefined : handleSelectThumbnailUrl(thumbnailPreviewUrl.current, thumbnailPreviewFile)}
          >
            <Thumbnail disabled={disabled} src={thumbnailPreviewUrl.current} />
          </ThumbnailChoice>
        )}
        {imageUrls.map((url, index) => (
          <ThumbnailChoice
            disabled={disabled}
            isSelected={selectedThumbnailChoiceUrl === url}
            key={index}
            onClick={disabled ? undefined : handleSelectThumbnailUrl(url)}
          >
            <Thumbnail disabled={disabled} src={url} />
          </ThumbnailChoice>
        ))}
      </ThumbnailChoicesContainer>
    </ThumbnailSelectorContainer>
  );
};

export default ThumbnailSelector;
