import { Box, styled } from '@mui/material';
import React from 'react';

import { MobileAppsPlatforms } from '../../../../core/domain/MobileAppPlatforms';
import StoreLink from '../tutorial/StoreLinks';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(6),
  justifyContent: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

export default function StoreLinks(): JSX.Element {
  return (
    <Container>
      <StoreLink variant={MobileAppsPlatforms.ios} />
      <StoreLink variant={MobileAppsPlatforms.android} />
    </Container>
  );
}
