import { Button, Typography, styled } from '@mui/material';
import { Box, Skeleton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileAppsPlatforms } from '../../../core/domain/MobileAppPlatforms';
import publisherImg from '../../assets/tutorial/publisher.jpg';
import StoreLink from './tutorial/StoreLinks';

interface PublisherModalProps {
  isModalFaded?: boolean;
  onClose: () => void;
}

const heightPercentage = 58;
const widthPercentage = 55;
const modalBorderRadius = 20;

const ContentContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

const Description = styled('p')`
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 0;
`;

const Image = styled('img', { shouldForwardProp: (prop) => prop !== 'loaded' })<{ loaded: boolean }>(({ loaded }) => ({
  borderRadius: `${modalBorderRadius}px 0 0 ${modalBorderRadius}px`,
  display: loaded ? 'block' : 'none',
  minHeight: '100%',
  objectFit: 'cover',
  width: '100%',
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: `${modalBorderRadius}px`,
  display: 'flex',
  flex: '1',
  flexDirection: 'row',
  height: `${heightPercentage}%`,
  left: `calc(50% - ${widthPercentage / 2}%)`,
  maxWidth: `${widthPercentage}%`,
  minHeight: '500px',
  outline: 'none',
  position: 'sticky',
  top: `calc(50% - ${heightPercentage / 2}%)`,
  transition: 'opacity 0.5s',
}));

const Title = styled(Typography)({
  fontSize: '2.5em',
  fontWeight: '500',
  margin: '0',
  textAlign: 'center',
});

const StoreLinksContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(5),
  justifyContent: 'center',
  marginTop: theme.spacing(4),
}));

const ButtonContainer = styled(Box)({
  margin: '20px',
  width: '120px',
});

export default function PublisherModal(props: PublisherModalProps) {
  const { isModalFaded, onClose } = props;
  const { t } = useTranslation('common');

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleLoadImage = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const iconSkeleton = useMemo(
    () => !imageLoaded && <Skeleton animation="wave" height="100%" variant="rectangular" width={300} />,
    [imageLoaded],
  );

  return (
    <ModalContainer sx={{ boxShadow: 3, opacity: isModalFaded ? 0 : 1 }}>
      <Box>
        <Image loaded={imageLoaded} onLoad={handleLoadImage} src={publisherImg} />
        {iconSkeleton}
      </Box>
      <ContentContainer sx={{ opacity: isModalFaded ? 0 : 1, position: 'relative' }}>
        <Title variant="h3">{t('PublisherModal.TutorialTitle')}</Title>
        <Description>{t('PublisherModal.Description')}</Description>
        <StoreLinksContainer>
          <StoreLink variant={MobileAppsPlatforms.android} />
          <StoreLink variant={MobileAppsPlatforms.ios} />
        </StoreLinksContainer>

        <ButtonContainer>
          <Button color="primary" fullWidth onClick={onClose} variant="contained">
            {t('Done')}
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </ModalContainer>
  );
}
