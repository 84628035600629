import { V1 } from '@bellepoque/api-contracts';
import { Chip, CircularProgress, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ShoppableStatusChipProps = {
  status: V1.api.ShoppableVideoStatus;
};

type ChipStyle = {
  color: string;
  icon?: React.ReactElement;
  label: string;
};

export default function ShoppableStatusChip({ status }: ShoppableStatusChipProps) {
  const { t } = useTranslation('shoppables', { keyPrefix: 'Status' });
  const theme = useTheme();

  const chipStyle: ChipStyle = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return {
          color: theme.palette.secondary.main,
          label: t('Draft'),
        };
      case 'OPTIMIZING':
        return {
          color: theme.palette.secondary.main,
          icon: <CircularProgress size="16px" />,
          label: t('Optimizing'),
        };
      case 'OPTIMIZATION_FAILED':
        return {
          color: theme.palette.error.main,
          label: t('OptimizationFailed'),
        };
      case 'PUBLISHED':
        return {
          color: theme.palette.success.main,
          label: t('Ready'),
        };
      case 'PUBLISHING':
        return {
          color: theme.palette.secondary.main,
          icon: <CircularProgress size="16px" />,
          label: t('Publishing'),
        };
      case 'READY':
        return {
          color: theme.palette.success.main,
          label: t('Ready'),
        };
      default:
        return {
          color: theme.palette.secondary.main,
          label: t('Draft'),
        };
    }
  }, [status]);

  return <Chip icon={chipStyle.icon} label={chipStyle.label} sx={{ backgroundColor: chipStyle.color }} />;
}
