import { EventStatus, V1 } from '@bellepoque/api-contracts';
import { ArrowBack, SystemUpdateAlt } from '@mui/icons-material';
import { Box, Button, FormHelperText, TextField, styled } from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../../core/domain/CBOEventListReadModel';
import { CBOMediaCollectionMedia } from '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOShoppableVideoListReadModel } from '../../../../../core/domain/CBOShoppableVideoReadModel';
import { isShoppableReady } from '../../../../../utils/is-shoppable-ready';
import { MediaCollectionsEditorFormType } from '../../../../pages/media-collections/media-collections-editor/MediaCollectionsEditor';
import ShoppablesCounterUpgradePlanButton from '../../../molecules/shoppables/ShoppablesCounterUpgradePlanButton';
import { MediaCollectionSettingsFormInputs } from './MediaCollectionFormLayout';
import MediaCollectionMediasOrganizer from './MediaCollectionMediasOrganizer';
import MediaCollectionMediasSelector from './MediaCollectionMediasSelector';

const constraints = V1.api.constraints.mediaCollections;

const MediaCollectionFormRoot = styled(Box)(({ theme }) => ({ padding: theme.spacing(2), width: '100%' }));

// TODO: Use theme.spacing
const MediaCollectionFormHeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  marginTop: theme.spacing(-3),
  padding: `${theme.spacing(3)} 0`,
  position: 'sticky',
  top: 64,
  zIndex: 100,
}));

const MediaCollectionFormHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}));

const MediaCollectionFormTitleTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  minWidth: '400px',
}));

const BackToMediaCollectionListButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const MediaCollectionFormButtonsContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  justifyContent: 'end',
  width: '100%',
});

export type MediaCollectionFormProps = {
  areReplayChaptersFetched: boolean;
  areReplaysFetched: boolean;
  areShoppablesFetched: boolean;
  form: UseFormReturn<MediaCollectionSettingsFormInputs>;
  formType: MediaCollectionsEditorFormType;
  maxPublishedShoppablesCount: number | null;
  onFetchReplayChapters: (replaysIds: string[]) => void;
  onGoToEventCreationPage: () => void;
  onGoToMediaCollectionList: () => void;
  onGoToShoppableCreationPage: () => void;
  onMediaPreviewClick: (media: CBOMediaCollectionMedia) => void;
  onOpenPublishedPagesEditor: () => void;
  onSubmit: (values: MediaCollectionSettingsFormInputs) => void;
  publishedPagesCount: number;
  publishedShoppablesCount: number;
  replays: CBOEventListReadModel[];
  resetForm: () => void;
  shoppables: CBOShoppableVideoListReadModel;
  submissionInProgress: boolean;
  tenantName: string;
};

const MediaCollectionForm = ({
  areReplayChaptersFetched,
  areReplaysFetched,
  areShoppablesFetched,
  form,
  formType,
  maxPublishedShoppablesCount,
  onFetchReplayChapters,
  onGoToEventCreationPage,
  onGoToShoppableCreationPage,
  onGoToMediaCollectionList,
  onMediaPreviewClick,
  onOpenPublishedPagesEditor,
  onSubmit,
  publishedPagesCount,
  publishedShoppablesCount,
  replays,
  resetForm,
  shoppables,
  submissionInProgress,
  tenantName,
}: MediaCollectionFormProps) => {
  const { t } = useTranslation(['common', 'mediaCollections']);

  const { watch, formState, register, handleSubmit: handleFormSubmit, setValue } = form;
  const { errors, isValid, isDirty } = formState;

  const medias = watch('medias');
  const title = watch('title');

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: V1.api.constraints.mediaCollections.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: V1.api.constraints.mediaCollections.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });

  const handleRemoveMedia = useCallback(
    (removedMedia: CBOMediaCollectionMedia) => {
      const newMedias = medias.filter(({ id }) => id !== removedMedia.id);
      setValue('medias', newMedias, { shouldDirty: true });
    },
    [medias],
  );

  const handleChangeMedias = (medias: CBOMediaCollectionMedia[]) => {
    setValue('medias', medias, { shouldDirty: true });
  };

  const hasExceededMaxPublishedShoppablesCount = maxPublishedShoppablesCount
    ? publishedShoppablesCount > maxPublishedShoppablesCount
    : false;

  const isFormValid = isValid && medias.length > 0 && !submissionInProgress && !hasExceededMaxPublishedShoppablesCount;

  const isSaveButtonDisplayed = formType === 'creation' || isDirty;
  const hasSelectedPublishedPages = publishedPagesCount > 0;

  return (
    <MediaCollectionFormRoot>
      <form noValidate onSubmit={handleFormSubmit(onSubmit)}>
        <MediaCollectionFormHeaderContainer>
          <MediaCollectionFormHeader>
            <BackToMediaCollectionListButton onClick={onGoToMediaCollectionList} startIcon={<ArrowBack />}>
              {t('mediaCollections:MediaCollections')}
            </BackToMediaCollectionListButton>
          </MediaCollectionFormHeader>

          <Box position="relative">
            <MediaCollectionFormTitleTextField
              error={!!errors.title}
              focused={!!title}
              label={t('mediaCollections:Name')}
              ref={titleInputRef}
              required
              variant="outlined"
              {...titleInputProps}
            />
            <FormHelperText error={!!errors.title}>{`${title.length} / ${constraints.title.maxLength}`}</FormHelperText>
          </Box>
          <MediaCollectionFormButtonsContainer>
            {maxPublishedShoppablesCount && (
              <ShoppablesCounterUpgradePlanButton
                maxPublishedShoppablesCount={maxPublishedShoppablesCount}
                publishedShoppablesCount={publishedShoppablesCount}
                tenantName={tenantName}
              />
            )}
            {isSaveButtonDisplayed ? (
              <Fragment>
                {formType === 'edition' && (
                  <Button disabled={!isDirty} onClick={resetForm} variant="contained">
                    {t('common:Cancel')}
                  </Button>
                )}
                <Button
                  disabled={!isFormValid}
                  onClick={handleFormSubmit(onSubmit)}
                  startIcon={<SystemUpdateAlt />}
                  variant="contained"
                >
                  {t('common:Save')}
                </Button>
              </Fragment>
            ) : (
              <Button
                onClick={onOpenPublishedPagesEditor}
                startIcon={<SystemUpdateAlt style={{ transform: 'rotate(180deg)' }} />}
                variant="contained"
              >
                {hasSelectedPublishedPages
                  ? t('mediaCollections:DisplayedCount', { publishedPagesCount })
                  : t('mediaCollections:Display')}
              </Button>
            )}
          </MediaCollectionFormButtonsContainer>
        </MediaCollectionFormHeaderContainer>

        {/* TODO: theme.spacing */}
        <Box style={{ marginBottom: 16, marginTop: '40px' }}>
          <MediaCollectionMediasOrganizer
            hasSelectedPublishedPages={hasSelectedPublishedPages}
            isSaveButtonDisplayed={isSaveButtonDisplayed}
            medias={medias}
            onChangeOrder={handleChangeMedias}
            onMediaPreviewClick={onMediaPreviewClick}
            onRemove={handleRemoveMedia}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <MediaCollectionMediasSelector
            areReplayChaptersFetched={areReplayChaptersFetched}
            areReplaysFetched={areReplaysFetched}
            areShoppablesFetched={areShoppablesFetched}
            maxItems={constraints.maxMediasCount}
            onChangeMedias={handleChangeMedias}
            onFetchReplayChapters={onFetchReplayChapters}
            onGoToEventCreationPage={onGoToEventCreationPage}
            onGoToShoppableCreationPage={onGoToShoppableCreationPage}
            onMediaPreviewClick={onMediaPreviewClick}
            replays={replays.filter(({ status }) => status === EventStatus.REPLAY)}
            selectedMedias={medias}
            shoppables={shoppables.filter(({ status }) => isShoppableReady(status))}
          />
        </Box>
      </form>
    </MediaCollectionFormRoot>
  );
};

export default MediaCollectionForm;
