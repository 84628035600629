import { styled } from '@mui/material';
import React from 'react';

const Container = styled('div')({
  backdropFilter: 'blur(8px)',
  display: 'flex',
  flex: '1',
  height: '100%',
  justifyContent: 'center',
  left: '0',
  position: 'absolute',
  top: '0',
  width: '100%',
  zIndex: '100',
});

interface BlurOverlayProps {
  children?: React.ReactNode;
  shown: boolean;
}

export default function BlurOverlay({ shown, children }: BlurOverlayProps) {
  if (!shown) return null;

  return <Container>{children}</Container>;
}
