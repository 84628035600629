import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import {
  CBOShoppablesSettings,
  CBOShoppablesSettingsFileUploadUrls,
} from '../../../../core/domain/CBOShoppablesSettings';
import { ShoppablesSettingsFilesUploads } from '../../../../core/gateways/shoppables-gateway';
import { CommandStatus, QueryStatus } from '../../../../core/store/state/utils';
import { UpdateShoppablesSettingsPayload } from '../../../../core/usecases/shoppables/update-shoppables-settings';
import SettingsForm from './ShoppableSettingsForm';

const fontOptions = [
  'Arial Narrow',
  'Arial',
  'Bookman Old Style',
  'Brush Script',
  'Calibri',
  'Cambria',
  'Candara',
  'Charcoal',
  'Copperplate',
  'Courier New',
  'Courier',
  'Didot',
  'Garamond',
  'Geneva',
  'Georgia',
  'Helvetica',
  'Impact',
  'Lucida Bright',
  'Lucida',
  'Monaco Monospace',
  'Monaco',
  'Optima',
  'Palatino Linotype',
  'Perpetua',
  'Roboto',
  'Sans-serif',
  'Times New Roman',
  'Times',
  'Trebuchet MS',
  'Ubuntu',
  'Verdana',
];

export type CreateShoppableFormInputs = {
  productId: string;
  title: string;
};

export type ShoppableSettingsFormDialogProps = {
  onClose: () => void;
  onGetSecuredFileUploadUrls: () => void;
  onSubmit: (payload: UpdateShoppablesSettingsPayload) => void;
  onUploadShoppablesSettingsFiles: (files: ShoppablesSettingsFilesUploads) => void;
  open: boolean;
  shoppablesSettings: CBOShoppablesSettings;
  shoppablesSettingsFileUploadUrls: CBOShoppablesSettingsFileUploadUrls | null;
  shoppablesSettingsFileUploadUrlsFetchingStatus: QueryStatus;
  shoppablesSettingsFileUploadingStatus: CommandStatus;
  submissionInProgress: boolean;
  tenantCurrency: CBOCurrency;
};

export type ShoppableSettingsFormInputs = {
  addToCartBackgroundColor: string;
  // TODO
  brandLogo?: any;
  brandLogoUploaded: boolean;
  mainFontName: string;
};

const ShoppableSettingsFormDialog: FC<ShoppableSettingsFormDialogProps> = ({
  onClose,
  onGetSecuredFileUploadUrls,
  onSubmit,
  onUploadShoppablesSettingsFiles,
  open,
  shoppablesSettings,
  shoppablesSettingsFileUploadingStatus,
  shoppablesSettingsFileUploadUrls,
  shoppablesSettingsFileUploadUrlsFetchingStatus,
  submissionInProgress,
  tenantCurrency,
}) => {
  const defaultValues = useMemo(
    () => ({
      addToCartBackgroundColor: shoppablesSettings.theme.addToCartBackgroundColor,
      brandLogo: undefined,
      brandLogoUploaded: false,
      mainFontName: shoppablesSettings.theme.mainFontName,
    }),
    [shoppablesSettings],
  );

  const form = useForm<ShoppableSettingsFormInputs>({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    resetForm();
  }, [open]);

  const resetForm = () => {
    form.reset(defaultValues);
  };

  return (
    <SettingsForm
      fontOptions={fontOptions}
      form={form}
      onClose={onClose}
      onGetSecuredFileUploadUrls={onGetSecuredFileUploadUrls}
      onSubmit={onSubmit}
      onUploadShoppablesSettingsFiles={onUploadShoppablesSettingsFiles}
      open={open}
      readonly={false}
      shoppablesSettings={shoppablesSettings}
      shoppablesSettingsFileUploadUrls={shoppablesSettingsFileUploadUrls}
      shoppablesSettingsFileUploadUrlsFetchingStatus={shoppablesSettingsFileUploadUrlsFetchingStatus}
      shoppablesSettingsFileUploadingStatus={shoppablesSettingsFileUploadingStatus}
      submissionInProgress={submissionInProgress}
      tenantCurrency={tenantCurrency}
    />
  );
};

export default ShoppableSettingsFormDialog;
