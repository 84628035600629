import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { AuthenticatedUser } from '../../domain/User';
import { AuthenticationState, INITIAL_AUTHENTICATION_STATE } from '../state/authentication';

export const loginPending = createAction('authentication/login-pending');
export const loginConfirmFulfilled = createAction<{ user: AuthenticatedUser }>(
  'authentication/login-confirm-fulfilled',
);
export const loginConfirmPending = createAction('authentication/login-confirm-pending');
export const loginFailed = createAction<{ error: string }>('authentication/login-failed');

export const logoutPending = createAction('authentication/logout-pending');
export const logoutSucceeded = createAction('authentication/logout-succeeded');
export const logoutFailed = createAction<{ error: string }>('authentication/logout-failed');

export type AuthenticationSliceActions =
  | ReturnType<typeof loginConfirmFulfilled>
  | ReturnType<typeof loginConfirmPending>
  | ReturnType<typeof loginFailed>
  | ReturnType<typeof loginPending>
  | ReturnType<typeof logoutFailed>
  | ReturnType<typeof logoutPending>
  | ReturnType<typeof logoutSucceeded>;

export const buildAuthenticationReducers = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
  builder.addCase(loginConfirmFulfilled, (state, action) => ({
    ...state,
    currentUser: action.payload.user,
    isAuthenticated: true,
  }));
  builder.addCase(loginFailed, (state) => ({
    ...state,
    isAuthenticated: false,
  }));
};

export const authenticationSlice = createSlice({
  extraReducers: (builder) => {
    buildAuthenticationReducers(builder);
  },
  initialState: INITIAL_AUTHENTICATION_STATE,
  name: 'authentication',
  reducers: {},
});
