import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateMediaCollectionPages as resetUpdateMediaCollectionPagesAction } from '../../store/slices/media-collections.slice';

export type UpdateMediaCollectionPagesRequest = {
  mediaCollectionId: string;
  payload: Pick<CBOMediaCollection, 'publishedPages'>;
  tenantId: string;
};

export const updateMediaCollectionPages = createAppAsyncThunk(
  'media-collections/update-media-collection-pages',
  async (
    { mediaCollectionId, payload, tenantId }: UpdateMediaCollectionPagesRequest,
    { extra: { mediaCollectionsGateway } },
  ): Promise<void> => {
    await mediaCollectionsGateway.updateMediaCollectionPages({ dto: payload, mediaCollectionId, tenantId });
  },
);

export const resetUpdateMediaCollectionPages = () => {
  return resetUpdateMediaCollectionPagesAction();
};
