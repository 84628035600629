import { BillingPlanId, EventId } from '@bellepoque/api-contracts';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  Box,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import UpgradePlanButton from '../../atoms/UpgradePlanButton';
import InputTooltip from '../../atoms/inputs/InputTooltip';
import SettingsSection from '../SettingsSection';
import EventRow from './EventRow';
import SkeletonEventRow from './SkeletonEventRow';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  height: '8px',
  width: '100%',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Count = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '.9em',
}));

type ReplaySectionProps = {
  currentPlanId: BillingPlanId;
  formErrors: any;
  maxPublishedReplayDurationInSeconds?: number;
  maxPublishedReplays?: number;
  onChangePublishedReplays: (publishedReplayEventsIds: EventId[]) => void;
  publishedReplayEventsIds: EventId[];
  replaySectionTitleProps: any;
  replaySectionTitleRef: RefCallBack;
  replaySectionTitleValue: string;
  replays: CBOEventListReadModel[];
  submissionInProgress: boolean;
  tenantName: string;
};

export default function ReplaySection({
  currentPlanId,
  replays: unsortedReplays,
  formErrors,
  maxPublishedReplayDurationInSeconds,
  maxPublishedReplays,
  onChangePublishedReplays,
  publishedReplayEventsIds,
  replaySectionTitleProps,
  replaySectionTitleRef,
  replaySectionTitleValue,
  submissionInProgress,
  tenantName,
}: ReplaySectionProps) {
  const { t } = useTranslation('liveShoppingPage');
  const theme = useTheme();
  const [replays, setReplays] = useState<CBOEventListReadModel[]>(unsortedReplays);

  useEffect(() => {
    const publishedReplays: CBOEventListReadModel[] = [];
    const unpublishedReplays: CBOEventListReadModel[] = [];

    unsortedReplays.forEach((replay) => {
      const isPublished = publishedReplayEventsIds.includes(replay.id);
      isPublished ? publishedReplays.push(replay) : unpublishedReplays.push(replay);
    });

    const publishedReplayEventsSortedByReplayOrder = publishedReplays.sort(
      (a, b) => publishedReplayEventsIds.indexOf(a.id) - publishedReplayEventsIds.indexOf(b.id),
    );

    const unpublishedReplayEventsSortedByShowtime = unpublishedReplays.sort((a, b) =>
      a.showTime! < b.showTime! ? 1 : -1,
    );

    const sortedReplays = publishedReplayEventsSortedByReplayOrder.concat(unpublishedReplayEventsSortedByShowtime);
    setReplays(sortedReplays);
  }, [unsortedReplays, publishedReplayEventsIds]);

  const hasExceededCount = !!maxPublishedReplays && publishedReplayEventsIds.length >= maxPublishedReplays;

  const handlePublishReplay = (eventId: EventId) => {
    const newPublishedReplays = [...publishedReplayEventsIds, eventId];
    const reorderedNewPublishedReplays = reorderDisplayedReplays(newPublishedReplays);
    onChangePublishedReplays(reorderedNewPublishedReplays);
  };

  const handleReorderDropEvent = (result: DropResult) => {
    if (!result.destination) return;

    const replay = replays.find((replay) => replay.id === result.draggableId);
    if (!replay) return;

    const reorderedReplays = replays;
    reorderedReplays.splice(result.source.index, 1);
    reorderedReplays.splice(result.destination.index, 0, replay);
    setReplays(reorderedReplays);

    const reorderedDisplayedReplays = reorderDisplayedReplays(publishedReplayEventsIds);
    onChangePublishedReplays(reorderedDisplayedReplays);
  };

  const handleUnpublishReplay = (eventId: string) => {
    onChangePublishedReplays(publishedReplayEventsIds.filter((id) => id !== eventId));
  };

  const renderRow = useCallback(
    (replay: CBOEventListReadModel, index: number) => {
      const publishedReplayIndex = publishedReplayEventsIds.findIndex((eventId) => eventId === replay.id);
      const isPublished = publishedReplayIndex !== -1;

      return (
        <Draggable draggableId={replay.id} index={index} isDragDisabled={!isPublished} key={replay.id}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <EventRow
                currentPlanId={currentPlanId}
                event={replay}
                maxPublishedReplayDurationInSeconds={maxPublishedReplayDurationInSeconds}
                position={isPublished ? publishedReplayIndex + 1 : undefined}
                reduceOpacity={!isPublished}
                section="replays"
                switchProps={{
                  checked: isPublished,
                  disabled: submissionInProgress || (hasExceededCount && !isPublished),
                  onChange: (e) => {
                    e.target.checked ? handlePublishReplay(replay.id) : handleUnpublishReplay(replay.id);
                  },
                }}
                tenantName={tenantName}
              />
            </Box>
          )}
        </Draggable>
      );
    },
    [replays, publishedReplayEventsIds, submissionInProgress],
  );

  const renderSkeletonEventRow = useCallback(
    (position: number) => <SkeletonEventRow position={position} section="replays" />,
    [],
  );

  const reorderDisplayedReplays = useCallback(
    (publishedReplayIds: EventId[]): EventId[] =>
      replays.map((replay) => replay.id).filter((eventId) => publishedReplayIds.includes(eventId)),
    [replays],
  );

  const renderPublishedReplayCount = (maxPublishedReplays: number) => (
    <Box alignItems="flex-end" display="flex" flex={1} flexDirection="column" gap={1} justifyContent="flex-end" mr={2}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box alignItems="center" display="flex" flexDirection="column" mt={1}>
          {!hasExceededCount && (
            <>
              <StyledLinearProgress
                color="primary"
                value={hasExceededCount ? 100 : (publishedReplayEventsIds.length / maxPublishedReplays) * 100}
                variant="determinate"
              />
              <Count>
                {t('Replays.ReplaysCount', {
                  count: publishedReplayEventsIds.length,
                  maxCount: maxPublishedReplays,
                })}
              </Count>
            </>
          )}
        </Box>

        {hasExceededCount && (
          <Box alignItems="center" maxWidth="100%">
            <UpgradePlanButton tenantName={tenantName} />
            <Count>{t('Replays.CountExceeded', { maxCount: maxPublishedReplays })}</Count>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <SettingsSection
      additionalTitleContent={!!maxPublishedReplays ? renderPublishedReplayCount(maxPublishedReplays) : undefined}
      contentWidth={12}
      icon={<YouTubeIcon sx={{ color: theme.palette.common.white }} />}
      roundBottom
      title={t('Replays.Title')}
      tooltip={t('Replays.SectionTooltip')}
    >
      <Grid item position="relative" xs={10}>
        <TextField
          disabled={submissionInProgress}
          error={!!formErrors.replaySectionTitle}
          fullWidth
          helperText={formErrors.replaySectionTitle?.message ?? ''}
          id="replaySectionTitle"
          inputRef={replaySectionTitleRef}
          label={t('Replays.SectionTitle')}
          required
          value={replaySectionTitleValue}
          variant="filled"
          {...replaySectionTitleProps}
        />
        <InputTooltip content={t('Replays.SectionTitleTooltip')} />
      </Grid>
      <Grid item xs={12}>
        {replays.length === 0 ? (
          <>
            {renderSkeletonEventRow(1)}
            {renderSkeletonEventRow(2)}
          </>
        ) : (
          <DragDropContext onDragEnd={handleReorderDropEvent}>
            <Droppable droppableId="replays-list">
              {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                  {replays.map(renderRow)}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Grid>
    </SettingsSection>
  );
}
