import { combineReducers } from '@reduxjs/toolkit';

import { administrationSlice } from './administration.slice';
import { AuthenticationSliceActions, authenticationSlice } from './authentication.slice';
import { catalogSlice } from './catalog.slice';
import { EventsSliceActions, eventsSlice } from './event';
import { facebookSlice } from './facebook.slice';
import { homeSlice } from './home.slice';
import { klaviyoSlice } from './klaviyo.slice';
import { landingPageSlice } from './landing-page.slice';
import { mediaCollectionsSlice } from './media-collections.slice';
import { onboardingSlice } from './onboarding.slice';
import { reportsSlice } from './reports.slice';
import { settingsSlice } from './settings.slice';
import { shopifySlice } from './shopify.slice';
import { ShoppablesSliceActions, shoppablesSlice } from './shoppables';
import { tenantStatisticsSlice } from './tenant-statistics';
import { tenantsSlice } from './tenants.slice';
import { TutorialSliceActions, tutorialSlice } from './tutorial.slice';
import { youtubeSlice } from './youtube.slice';

export const rootReducer = combineReducers({
  administration: administrationSlice.reducer,
  authentication: authenticationSlice.reducer,
  catalog: catalogSlice.reducer,
  events: eventsSlice.reducer,
  facebook: facebookSlice.reducer,
  home: homeSlice.reducer,
  klaviyo: klaviyoSlice.reducer,
  landingPage: landingPageSlice.reducer,
  mediaCollections: mediaCollectionsSlice.reducer,
  onboarding: onboardingSlice.reducer,
  reports: reportsSlice.reducer,
  settings: settingsSlice.reducer,
  shopify: shopifySlice.reducer,
  shoppables: shoppablesSlice.reducer,
  tenantStatistics: tenantStatisticsSlice.reducer,
  tenants: tenantsSlice.reducer,
  tutorial: tutorialSlice.reducer,
  youtube: youtubeSlice.reducer,
});

export type Action = AuthenticationSliceActions | EventsSliceActions | ShoppablesSliceActions | TutorialSliceActions;
