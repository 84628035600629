import { EventStatus, ProductId } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { CBOEventReadModel } from '../../../domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { closeEvent } from '../../../usecases/event/live/close-event';
import { displayProduct } from '../../../usecases/event/live/display-product';
import { fetchEventViewerToken } from '../../../usecases/event/live/fetch-event-viewer-token';
import { openEvent } from '../../../usecases/event/live/open-event';
import { openReplay } from '../../../usecases/event/live/open-replay';
import { publishEvent } from '../../../usecases/event/live/publish-event';
import { publishReplay } from '../../../usecases/event/live/publish-replay';
import { reorderProductsLineup } from '../../../usecases/event/live/reorder-products-lineup';
import { toggleLiveViewersCountDisplay } from '../../../usecases/event/live/toggle-live-viewers-count-display';
import { unpublishEvent } from '../../../usecases/event/live/unpublish-event';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetCloseEvent = createAction<void>('events/reset-close-event');

export const changePreviewModeAction = createAction<{ isPrivatePreview: boolean }>('events/change-preview-mode');

const getSortedProducts = (products: CBOEventReadModelProduct[], sortedProductsIds: ProductId[]) => {
  return sortedProductsIds.reduce((acc: CBOEventReadModelProduct[], id: ProductId) => {
    const productIndex = products.findIndex((product) => product.id === id);
    if (productIndex !== -1) {
      acc.push(products[productIndex]);
    }
    return acc;
  }, []);
};

const buildChangePreviewModeReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(changePreviewModeAction, (state, action) => ({
    ...state,
    isPrivatePreview: action.payload.isPrivatePreview,
  }));
};

const buildDisplayProductReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(displayProduct.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('productsDisplay'),
    displayedProducts: [...state.displayedProducts, action.payload],
  }));
  builder.addCase(displayProduct.pending, (state) => ({
    ...state,
    ...pendingStatus('productsDisplay'),
  }));
  builder.addCase(displayProduct.rejected, (state, action) => ({
    ...state,
    ...errorStatus('productsDisplay', [action.error]),
  }));
};

const buildLifecycleReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(closeEvent.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.FINISHED,
        }
      : null;

    return {
      ...state,
      ...successStatus('eventClosing'),
      currentEvent: updatedCurrentEvent,
    };
  });
  builder.addCase(closeEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventClosing'),
  }));
  builder.addCase(closeEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventClosing', [action.error]),
  }));
  builder.addCase(resetCloseEvent, (state) => ({
    ...state,
    ...notRequestedStatus('eventClosing'),
  }));
  builder.addCase(openEvent.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.ON_AIR,
        }
      : null;

    return {
      ...state,
      ...successStatus('eventOpening'),
      currentEvent: updatedCurrentEvent,
    };
  });
  builder.addCase(openEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventOpening'),
  }));
  builder.addCase(openEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventOpening', [action.error]),
  }));
  builder.addCase(openReplay.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.REPLAY,
        }
      : null;

    return {
      ...state,
      ...successStatus('replayOpening'),
      currentEvent: updatedCurrentEvent,
    };
  });
  builder.addCase(openReplay.pending, (state) => ({
    ...state,
    ...pendingStatus('replayOpening'),
  }));
  builder.addCase(openReplay.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replayOpening', [action.error]),
  }));
  builder.addCase(publishEvent.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.PRIVATE_PREVIEW,
        }
      : null;

    return {
      ...state,
      ...successStatus('eventPublish'),
      currentEvent: updatedCurrentEvent,
      isPrivatePreview: true,
    };
  });
  builder.addCase(publishEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventPublish'),
  }));
  builder.addCase(publishEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventPublish', [action.error]),
  }));
  builder.addCase(publishReplay.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.PRIVATE_REPLAY,
        }
      : null;

    return {
      ...state,
      ...successStatus('replayPublish'),
      currentEvent: updatedCurrentEvent,
      isPrivatePreview: true,
    };
  });
  builder.addCase(publishReplay.pending, (state) => ({
    ...state,
    ...pendingStatus('replayPublish'),
  }));
  builder.addCase(publishReplay.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replayPublish', [action.error]),
  }));
  builder.addCase(unpublishEvent.fulfilled, (state) => {
    const updatedCurrentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
          status: EventStatus.PLANNED,
        }
      : null;

    return {
      ...state,
      ...successStatus('eventUnPublish'),
      currentEvent: updatedCurrentEvent,
      displayedProducts: [],
    };
  });
  builder.addCase(unpublishEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventUnPublish'),
  }));
  builder.addCase(unpublishEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventUnPublish', [action.error]),
  }));
};

const buildReorderProductsReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(reorderProductsLineup.fulfilled, (state) => ({
    ...state,
    ...successStatus('productsLineupReordering'),
  }));
  builder.addCase(reorderProductsLineup.pending, (state, action) => {
    if (!state.currentEvent || state.currentEvent.id !== action.meta.arg.eventId) return state;

    const sortedProducts: CBOEventReadModelProduct[] = getSortedProducts(
      state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
      action.meta.arg.newProductsOrder,
    );

    return {
      ...state,
      ...pendingStatus('productsLineupReordering'),
      currentEvent: {
        ...state.currentEvent,
        products: sortedProducts,
      },
    };
  });
  builder.addCase(reorderProductsLineup.rejected, (state, action) => {
    if (!state.currentEvent || state.currentEvent.id !== action.meta.arg.eventId) return state;

    const sortedProducts: CBOEventReadModelProduct[] = getSortedProducts(
      state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
      action.meta.arg.previousProductsOrder,
    );

    return {
      ...state,
      ...errorStatus('productsLineupReordering', [action.error]),
      currentEvent: {
        ...state.currentEvent,
        products: sortedProducts,
      },
    };
  });
};

const buildViewerTokenReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchEventViewerToken.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('eventViewerTokenFetching'),
    currentEventViewerToken: action.payload,
  }));
  builder.addCase(fetchEventViewerToken.pending, (state) => ({
    ...state,
    ...pendingStatus('eventViewerTokenFetching'),
  }));
  builder.addCase(fetchEventViewerToken.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventViewerTokenFetching', [action.error]),
  }));
};

const buildToggleLiveViewersCountDisplayReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(toggleLiveViewersCountDisplay.fulfilled, (state) => ({
    ...state,
    ...successStatus('toggleLiveViewersCountDisplayUpdating'),
  }));
  builder.addCase(toggleLiveViewersCountDisplay.pending, (state, action) => {
    if (!state.currentEvent || state.currentEvent.id !== action.meta.arg.eventId) return state;

    return {
      ...state,
      ...pendingStatus('toggleLiveViewersCountDisplayUpdating'),
      currentEvent: {
        ...state.currentEvent,
        isLiveViewersCountDisplayed: action.meta.arg.isDisplayed,
      },
    };
  });
  builder.addCase(toggleLiveViewersCountDisplay.rejected, (state, action) => {
    if (!state.currentEvent || state.currentEvent.id !== action.meta.arg.eventId) return state;

    return {
      ...state,
      ...errorStatus('toggleLiveViewersCountDisplayUpdating', [action.error]),
      currentEvent: {
        ...state.currentEvent,
        isLiveViewersCountDisplayed: !state.currentEvent.isLiveViewersCountDisplayed,
      },
    };
  });
};

export const buildLiveReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  buildChangePreviewModeReducers(builder);
  buildDisplayProductReducers(builder);
  buildLifecycleReducers(builder);
  buildReorderProductsReducers(builder);
  buildToggleLiveViewersCountDisplayReducers(builder);
  buildViewerTokenReducers(builder);
};
