import { V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { CBOKlaviyoList } from '../../core/domain/CBOKlaviyoList';
import { KlaviyoGateway } from '../../core/gateways/klaviyo-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiKlaviyoGateway extends ApiGateway implements KlaviyoGateway {
  async fetchLists(tenantId: string, apiKey: string): Promise<CBOKlaviyoList[]> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.KlaviyoListDTO[]>({
        params: { apiKey },
        url: `${this.apiEndpoint}/tenants/${tenantId}/klaviyo-lists`,
      }),
    );
  }
}
