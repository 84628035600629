import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AdministrationHeader from '../../atoms/administration/AdministrationHeader';
import { createAdminToolsViewModel } from './AdminTools.viewmodel';
import EventFinderSection from './tools/EventFinderSection';
import TenantAliasSection from './tools/TenantAliasSection';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

export default function AdminTools() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['administration', 'common']);

  const viewModel = useSelector(createAdminToolsViewModel({ dispatch }));

  const {
    currentTenant,
    findEventStatus,
    foundEvent,
    onFindEventSubmit,
    onUpdateTenantAliasSubmit,
    tenants,
    updateTenantAliasStatus,
  } = viewModel;

  const handleFindEventSubmit = (eventId: string) => {
    onFindEventSubmit(eventId);
  };

  const handleUpdateTenantAliasSubmit = (alias: string) => {
    onUpdateTenantAliasSubmit(alias);
  };

  return (
    <Container>
      <AdministrationHeader title={t('Tools')} />
      <ContentContainer mt={1}>
        <EventFinderSection
          findEventStatus={findEventStatus}
          foundEvent={foundEvent}
          onSubmit={handleFindEventSubmit}
          tenants={tenants}
        />
        <TenantAliasSection
          defaultAlias={currentTenant.alias}
          onSubmit={handleUpdateTenantAliasSubmit}
          submissionInProgress={updateTenantAliasStatus === 'pending'}
        />
      </ContentContainer>
    </Container>
  );
}
