import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { CBOHomePageGuards } from '../../domain/Home';
import { fetchHomePage } from '../../usecases/home/fetch-home-page';
import { HomeState, INITIAL_HOME_STATE } from '../state/home';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildReducers = (builder: ActionReducerMapBuilder<HomeState>) => {
  builder.addCase(fetchHomePage.fulfilled, (state, action) => {
    if (CBOHomePageGuards.isCBOHomePageWithSales(action.payload)) {
      return {
        ...state,
        ...loadedStatus('homePageFetching'),
        productPagesWithMediaCollectionsCount: action.payload.productPagesWithMediaCollectionsCount,
        purchasedAmounts: action.payload.purchasedAmounts,
        purchasedCarts: action.payload.purchasedCarts,
        views: action.payload.views,
      };
    }

    if (CBOHomePageGuards.isCBOHomePageWithoutSales(action.payload)) {
      return {
        ...state,
        ...loadedStatus('homePageFetching'),
        productPagesWithMediaCollectionsCount: action.payload.productPagesWithMediaCollectionsCount,
        validatedCarts: action.payload.validatedCarts,
        validatedCartsAmounts: action.payload.validatedCartsAmounts,
        views: action.payload.views,
      };
    }

    return state;
  });
  builder.addCase(fetchHomePage.pending, (state) => ({
    ...state,
    ...pendingStatus('homePageFetching'),
  }));
  builder.addCase(fetchHomePage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('homePageFetching', [action.error]),
  }));
};

export const homeSlice = createSlice({
  extraReducers: (builder) => {
    buildReducers(builder);
  },
  initialState: INITIAL_HOME_STATE,
  name: 'home',
  reducers: {},
});
