export default {
  EndModal: {
    Action: 'Explore Live Me Up',
    Description:
      'A replay clip and an event dashboard will soon be available.\n\nFor more informations, check our Help & Support section.',
    Title: 'Tutorial completed!',
  },
  Leave: 'Leave',
  ResumeModal: {
    Action: 'Continue',
    Description: 'We highly recommend you finish this tutorial.',
    Title: 'You had to go?',
  },
  Skip: 'Skip',
  StartModal: {
    Action: 'Run tutorial',
    ActionSecondary: 'Go to shoppables',
    Description:
      "Follow this quick tutorial to discover how to stream Live Shopping events on your Shopify. (recommended)\n\nHere to create shoppable videos first? We'll take you there too.",
    Title: 'Let’s stream live on your Shopify',
  },
  Steps: {
    AddPhoneNumber: {
      Form: {
        Description: 'Welcome and thank you for supporting Live me up!',
        Title: 'My first live',
      },
      Label: 'Add your phone number',
    },
    AddProducts: {
      Label: 'Add products',
      PresentationModal: {
        Content: 'Your catalog is in sync with our platform.',
        Title: 'Select those products you want to demo and sell',
      },
    },
    CameraApp: {
      GiveYourPhoneNumberPart1: 'Open the app, a code will be sent to ',
      GiveYourPhoneNumberPart2: 'You will be able to stream after you have completed the next step.',
      Label: 'Camera app',
      MobileAppPresentation: 'Your phone will serve as a camera for Live Shopping shows. Download the Live Me Up app.',
      Title: 'Download camera app',
    },
    LiveManager: {
      BeforePrivateTestModal: {
        Content: 'Just hit Private Test to rehearse and test your setup.',
        Title: 'Live Manager',
      },
      InPrivateTestModal: {
        Content:
          'Hit Publish on your mobile phone now to open your camera.\nTo allow your audience to watch, hit Open to Public here.',
        Title: 'Live Manager / Private Test',
      },
      OnAirModal: {
        Content:
          'Click on Watch on my Shopify and come back here to control and moderate your event:\n• Chat with your audience\n• Showcase your products\n\nWhen you are done with your Live Shopping show, hit Close Event.',
        Title: 'You’re now streaming on your Shopify',
      },
      WatchOnMyShopify: 'Watch on my Shopify',
    },
    OpenToPublic: 'Go live',
    PrivatePreview: 'Private preview',
  },
};
