import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  zIndex: 1000,
}));

export interface CannotPublishShoppableModalProps {
  onClose: () => void;
  open: boolean;
}

export default function CannotPublishShoppableModal({ onClose, open }: CannotPublishShoppableModalProps) {
  const { t } = useTranslation('shoppables');
  return (
    <Dialog onClose={onClose} open={open}>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
      <DialogTitle>{t('CannotPublishModal.Title')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{t('CannotPublishModal.Text')}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
