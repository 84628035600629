import { EventId } from '@bellepoque/api-contracts';

import { CBOPinnedMessage } from '../../../domain/Chat';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type PinMessageParams = {
  content: string;
  eventId: EventId;
  messageId: string;
};

export const pinMessage = createAppAsyncThunk(
  'events/pin-message',
  async ({ eventId, content, messageId }: PinMessageParams, { extra: { eventGateway } }): Promise<CBOPinnedMessage> => {
    return eventGateway.pinMessage(eventId, { content, messageId });
  },
);
