import { CatalogProductDTO, PaginationWrapper } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import {
  CatalogGateway,
  DeleteProductsParams,
  FetchProductFilters,
  FetchProductsFilters,
} from '../../core/gateways/catalog-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiCatalogGateway extends ApiGateway implements CatalogGateway {
  deleteProducts({ productsIds, tenantId }: DeleteProductsParams): Promise<void> {
    const url = `${this.apiEndpoint}/tenants/${tenantId}/products/delete-multiple`;
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body: { productsIds: productsIds },
        url,
        verb: 'post',
      }),
    );
  }

  fetchProduct({ productId, tenantId }: FetchProductFilters): Promise<CatalogProductDTO> {
    const url = `${this.apiEndpoint}/tenants/${tenantId}/products/${productId}`;
    return firstValueFrom(this.authenticatedJsonQuery<CatalogProductDTO>({ url }));
  }

  fetchProducts({
    eventId,
    limit,
    offset,
    search,
    sort,
    tenantId,
  }: FetchProductsFilters): Promise<PaginationWrapper<CatalogProductDTO>> {
    const params: Record<string, string> = {
      limit: limit.toString(),
      offset: offset.toString(),
      sort,
    };

    if (search) params.search = search;
    if (eventId) params.eventId = eventId;

    const url = `${this.apiEndpoint}/tenants/${tenantId}/products`;
    return firstValueFrom(this.authenticatedJsonQuery<PaginationWrapper<CatalogProductDTO>>({ params, url }));
  }
}
