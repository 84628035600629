import { Box, Typography, styled } from '@mui/material';
import React from 'react';

const BrandLogo = styled('img')({
  margin: '4px',
  maxHeight: '20px',
  maxWidth: '50px',
});

const Header = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 4px;
  position: relative;
  z-index: 52;
`;

const TitleContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: 1.4,
  margin: '4px',
  textAlign: 'initial',
  textShadow: 'rgb(0 0 0 / 37%) 0px 3px 6px',
  whiteSpace: 'nowrap',
}));

interface ThemePreviewHeaderProps {
  brandLogo: string | null;
  title: string;
}

const ThemePreviewHeader = ({ brandLogo, title }: ThemePreviewHeaderProps) => (
  <Header>
    <TitleContainer>
      <BrandLogo src={brandLogo ?? ''} />
      <Title variant="h2">{title}</Title>
    </TitleContainer>
  </Header>
);

export default ThemePreviewHeader;
