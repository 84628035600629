import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type FetchEventViewerTokenParams = {
  eventId: EventId;
};

export const fetchEventViewerToken = createAppAsyncThunk(
  'events/fetch-event-viewer-token',
  async ({ eventId }: FetchEventViewerTokenParams, { extra: { eventGateway } }): Promise<string> => {
    const { viewerToken } = await eventGateway.fetchOneEventViewerToken(eventId);
    return viewerToken;
  },
);
