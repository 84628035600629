import { Edit } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SettingsSection from '../../../molecules/SettingsSection';
import TenantAliasForm from '../../../molecules/administration/TenantAliasForm';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

type TenantAliasSectionProps = {
  defaultAlias: string;
  onSubmit: (alias: string) => void;
  submissionInProgress: boolean;
};

export default function TenantAliasSection({ defaultAlias, onSubmit, submissionInProgress }: TenantAliasSectionProps) {
  const { t } = useTranslation('administration');

  return (
    <SettingsSection
      icon={<Edit sx={{ color: (theme) => theme.palette.common.white }} />}
      roundBottom
      title={t('RenameTenant')}
    >
      <SectionContainer>
        <FormContainer>
          <TenantAliasForm defaultAlias={defaultAlias} loading={submissionInProgress} onSubmit={onSubmit} />
        </FormContainer>
      </SectionContainer>
    </SettingsSection>
  );
}
