import { YoutubeAccessToken } from '@bellepoque/api-contracts';
import { WarningRounded, YouTube } from '@mui/icons-material';
import { Avatar, Box, Grid, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOConnectedYoutubeUser } from '../../../../../../core/domain/CBOYoutubeChannel';
import { CBOYoutubeRestreamingConfiguration } from '../../../../../../core/domain/Restreaming';
import { QueryStatus } from '../../../../../../core/store/state/utils';
import { localStoragePrefix } from '../../../../../../utils/local-storage';
import ConnectYoutubeAccount from './ConnectYoutubeAccount';
import SelectYoutubeChannel from './SelectYoutubeChannel';
import SelectedYoutubeChannel from './SelectedYoutubeChannel';

interface YoutubeRestreamingSectionProps {
  configuredAccount: CBOYoutubeRestreamingConfiguration | null;
  fetchLoginUrlStatus: QueryStatus;
  loginUrl: string | null;
  onChange: (value: CBOYoutubeRestreamingConfiguration | null) => void;
  onDisconnect: () => void;
  onFetchChannels: (token: YoutubeAccessToken) => void;
  onFetchLoginUrl: () => void;
  onResetFetchYoutubeLoginUrl: () => void;
  readonly: boolean;
  submissionInProgress: boolean;
  user: CBOConnectedYoutubeUser | null;
}

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: `${theme.spacing(2)} 0`,
  padding: `0 ${theme.spacing(3)}`,
  width: '100%',
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'initial',
});

export default function YoutubeRestreamingSection({
  configuredAccount,
  fetchLoginUrlStatus,
  loginUrl,
  onChange,
  onDisconnect,
  onFetchChannels,
  onFetchLoginUrl,
  onResetFetchYoutubeLoginUrl,
  readonly,
  submissionInProgress,
  user,
}: YoutubeRestreamingSectionProps) {
  const { t } = useTranslation('events');
  const [token, setToken] = useState<YoutubeAccessToken | null>(null);
  const [isNoChannelsErrorShown, setIsNoChannelsErrorShown] = useState(false);
  const areButtonsDisabled = readonly || submissionInProgress;

  const hasExpired = configuredAccount && configuredAccount.expiresAt < new Date();

  useEffect(() => {
    if (!user && loginUrl && fetchLoginUrlStatus === 'loaded') {
      openInNewTab(loginUrl);
      onResetFetchYoutubeLoginUrl();
    }
  }, [fetchLoginUrlStatus, loginUrl, user]);

  useEffect(() => {
    if (token && !user) {
      onFetchChannels(token);
    }
  }, [token, user]);

  useEffect(() => {
    if (user) {
      if (user.channels.length === 0) {
        setIsNoChannelsErrorShown(true);
        disconnect();
      } else if (user.channels.length === 1) {
        handleSelectChannel(user.channels[0].id);
      } else {
        setIsNoChannelsErrorShown(false);
      }
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  const handleLocalStorageChange = (e: StorageEvent) => {
    if (e.key === `${localStoragePrefix}YoutubeAccessToken` && e.newValue) {
      parseAndSetToken(e.newValue);
    }
  };

  const handleConnect = async () => {
    onFetchLoginUrl();
  };

  const handleDisconnect = async () => {
    disconnect();
  };

  const disconnect = () => {
    setToken(null);
    onDisconnect();
  };

  const handleSelectChannel = useCallback(
    (channelId: string) => {
      if (!user) return;
      const selectedChannel = user.channels.find(({ id }) => channelId === id);
      if (selectedChannel) {
        onChange({
          accessToken: user.token.accessToken,
          expiresAt: user.token.expiresAt,
          id: channelId,
          picture: selectedChannel.picture,
          refreshToken: user.token.refreshToken,
          title: selectedChannel.title,
        });
      }
    },
    [user, onChange],
  );

  const handleRemoveChannel = () => {
    onChange(null);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
  };

  const parseAndSetToken = (tokenString: string) => {
    const parsedToken = JSON.parse(tokenString);
    const hasChanged = parsedToken.accessToken !== token?.accessToken;
    if (hasChanged) {
      setToken({
        accessToken: parsedToken.accessToken,
        expiresAt: new Date(parsedToken.expiresAt),
        refreshToken: parsedToken.refreshToken,
      });
    }
  };

  return (
    <Container item xs={12}>
      <Box alignItems="center" display="flex" flex={1} justifyContent="space-between" mb={1}>
        <Box alignItems="center" display="flex" gap={2}>
          <Avatar sx={{ bgcolor: (theme) => theme.palette.socials.youtube }}>
            <YouTube />
          </Avatar>
          <TitleContainer>
            <Typography fontSize="1em" fontWeight="bold" variant="titleFont">
              {t('common:Socials.Youtube')}
            </Typography>
          </TitleContainer>
          <Box>{hasExpired && <WarningRounded color="error" />}</Box>
        </Box>
      </Box>

      <Box>
        {configuredAccount ? (
          <SelectedYoutubeChannel
            areButtonsDisabled={areButtonsDisabled}
            channel={configuredAccount}
            expiresAt={configuredAccount.expiresAt}
            onRemove={handleRemoveChannel}
          />
        ) : (
          <>
            {user && user.channels.length > 0 ? (
              <SelectYoutubeChannel
                areButtonsDisabled={areButtonsDisabled}
                channels={user.channels}
                onDisconnect={handleDisconnect}
                onSelect={handleSelectChannel}
              />
            ) : (
              <ConnectYoutubeAccount
                areButtonsDisabled={areButtonsDisabled}
                isNoChannelsErrorShown={isNoChannelsErrorShown}
                onConnect={handleConnect}
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
