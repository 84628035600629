import { ProductOption as EventProductOption, ProductOptionValue } from '@bellepoque/api-contracts';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import MultiGenericSelector from '../../atoms/inputs/MultiGenericSelector';

interface ProductOptionSelectorProps {
  onChange: (option: EventProductOption) => void;
  onDeleteValue: (value: ProductOptionValue) => void;
  onRemoveOptionClicked?: () => void;
  option: EventProductOption;
  readonly: boolean;
  title: string;
}

const optionWithNewValue = (option: EventProductOption, selectedValues: ProductOptionValue[]): EventProductOption => {
  const allValues = option.allValues;
  selectedValues.forEach((selectedValue) => {
    if (!option.allValues.find(({ id }) => id === selectedValue.id)) {
      allValues.push(selectedValue);
    }
  });
  return {
    ...option,
    allValues,
    selectedValues,
  };
};

export default function ProductOptionSelector(props: ProductOptionSelectorProps) {
  const { onChange, onDeleteValue, onRemoveOptionClicked, option, readonly, title } = props;
  const { t } = useTranslation(['products', 'common']);
  let debounceTimer: NodeJS.Timeout | null = null;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const callback = () => {
      onChange({
        ...option,
        name: e.target.value,
      });
    };

    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = setTimeout(callback, 500);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start" textAlign="initial">
        <Box flexGrow={1}>
          <Typography fontWeight="bold" variant="subtitle2">
            {title}
          </Typography>
        </Box>
        {onRemoveOptionClicked && !readonly && (
          <Box>
            <Button color="secondary" onClick={onRemoveOptionClicked} size="small" sx={{ textTransform: 'initial' }}>
              {t('DeleteOption')}
            </Button>
          </Box>
        )}
      </Box>
      <Grid container mb={2} spacing={2}>
        <Grid alignItems="flex-end" display="flex" item xs={3}>
          <TextField
            defaultValue={option.name}
            disabled={readonly}
            id="name"
            label={t('Option')}
            onChange={handleNameChange}
            required
            size="small"
            sx={{ marginBottom: readonly ? 0 : '22px' }}
            variant="filled"
          />
        </Grid>
        <Grid alignItems="flex-end" display="flex" item xs={9}>
          <MultiGenericSelector
            onChange={(selectedValues: ProductOptionValue[]) => onChange(optionWithNewValue(option, selectedValues))}
            onDeleteValue={onDeleteValue}
            option={option}
            readonly={readonly}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
