import { CBOShoppablesSettingsFileUploadUrls } from '../../domain/CBOShoppablesSettings';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateShoppablesSettings as resetUpdateShoppablesSettingsAction } from '../../store/slices/shoppables/shoppables-settings.slice';

type GetShoppablesSettingsFileUploadUrlsParams = {
  tenantId: string;
};

export const getShoppablesSettingsFileUploadUrls = createAppAsyncThunk(
  'shoppables/settings/get-file-upload-urls',
  async (
    { tenantId }: GetShoppablesSettingsFileUploadUrlsParams,
    { extra: { shoppablesGateway } },
  ): Promise<CBOShoppablesSettingsFileUploadUrls> => {
    return shoppablesGateway.getShoppablesSettingsFileUploadUrls(tenantId);
  },
);

export const resetUpdateShoppablesSettings = () => {
  return resetUpdateShoppablesSettingsAction();
};
