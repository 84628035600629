import { TenantId } from '@bellepoque/api-contracts';

import { CBOCurrency } from '../../domain/CBOCurrency';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateCurrency as resetUpdateCurrencyAction } from '../../store/slices/tenants.slice';

export type RenewApiKeyRequest = {
  tenantId: string;
};

type UpdateCurrencyParams = {
  currency: CBOCurrency;
  tenantId: TenantId;
};

export const updateCurrency = createAppAsyncThunk(
  'tenants/update-currency',
  async (params: UpdateCurrencyParams, { extra: { tenantsGateway } }): Promise<void> => {
    return tenantsGateway.updateCurrency(params.tenantId, { currencyId: params.currency.id });
  },
);

export const resetUpdateCurrency = () => {
  return resetUpdateCurrencyAction();
};
