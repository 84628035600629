import { Box, Button, Link, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadVideoIcon from '../../../../icons/DownloadVideoIcon';

interface DownloadReplayButtonProps {
  link: string;
}

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  lineHeight: 'initial',
});

export default function DownloadReplayButton({ link }: DownloadReplayButtonProps) {
  const { t } = useTranslation('events');

  return (
    <Container>
      <Link href={link} target="_blank" underline="none">
        <Button
          color="primary"
          fullWidth
          onClick={(e) => e.stopPropagation()}
          startIcon={<DownloadVideoIcon />}
          sx={{ textTransform: 'none' }}
          variant="contained"
        >
          {t('DownloadMP4')}
        </Button>
      </Link>
    </Container>
  );
}
