import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type DeleteProductsRequest = {
  productsIds: string[];
  tenantId: string;
};

export const deleteProducts = createAppAsyncThunk(
  'catalog/delete-products',
  async (request: DeleteProductsRequest, { extra: { catalogGateway } }): Promise<string[]> => {
    await catalogGateway.deleteProducts(request);
    return request.productsIds;
  },
);
