import { UserJourneyEvent } from '../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../core/store';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createIntegrationsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    return {
      currentTenant: state.tenants.currentTenant,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
