import { Cms } from '@bellepoque/api-contracts';
import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlanIcon from '../../../icons/PlanIcon';
import { getUrlToTenantShopifyApp } from '../../../utils/get-url-to-tenant-shopify-app';

type UpgradePlanButtonProps = {
  ButtonProps?: ButtonProps;
  cms?: typeof Cms.Shopify;
  sx?: SxProps<Theme>;
  tenantName: string;
  text?: string;
};

const UpgradePlanButton = ({ ButtonProps, cms = Cms.Shopify, tenantName, sx = {}, text }: UpgradePlanButtonProps) => {
  const { t } = useTranslation('common');

  const handleClick = () => {
    if (cms === Cms.Shopify) {
      window.open(`${getUrlToTenantShopifyApp(tenantName)}/billing`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Button
      {...ButtonProps}
      color="primary"
      onClick={handleClick}
      startIcon={<PlanIcon sx={{ height: ButtonProps?.size === 'small' ? '.55em' : undefined }} />}
      sx={sx}
      variant="outlined"
    >
      {text ?? t('UpgradeForMore')}
    </Button>
  );
};

export default UpgradePlanButton;
