import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CopyPageUrlButtonProps {
  onCopyUrlButtonClick: () => void;
}

export default function CopyLandingPageUrlButton({ onCopyUrlButtonClick }: CopyPageUrlButtonProps) {
  const { t } = useTranslation('liveShoppingPage');
  const theme = useTheme();

  return (
    <Tooltip title={t('CopyUrl') as string}>
      <IconButton onClick={onCopyUrlButtonClick} sx={{ color: theme.palette.primary.dark, padding: 0 }}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
}
