import { CBOEventReadModelDTO, CBOEventReadModelDTOPinnedChatMessage } from '@bellepoque/api-contracts';

import { CBOEventReadModelProduct, toCBOProduct } from './CBOEventReadModelProduct';
import {
  CBOFacebookRestreamingConfiguration,
  CBORestreamingConfiguration,
  CBOYoutubeRestreamingConfiguration,
} from './Restreaming';
import { SecuredFileUploadUrls } from './Theme';

export type CBOEventFileUploaded = {
  adminMessageIconUploaded: boolean;
  brandLogoUploaded: boolean;
  cartIconUploaded: boolean;
  fileUploadUrls: SecuredFileUploadUrls | null;
  postEventCoverUploaded: boolean;
  preEventCoverUploaded: boolean;
  storeIconUploaded: boolean;
};

export type CBOEventReadModel = CBOEventFileUploaded &
  Omit<
    CBOEventReadModelDTO,
    | 'createdAt'
    | 'deletedAt'
    | 'facebookRestreamingConfiguration'
    | 'finishTime'
    | 'pinnedChatMessage'
    | 'products'
    | 'restreamingConfigurations'
    | 'showTime'
    | 'startTime'
    | 'youtubeRestreamingConfiguration'
  > & {
    createdAt: Date;
    deletedAt: Date | null;
    facebookRestreamingConfiguration: CBOFacebookRestreamingConfiguration | null;
    finishTime: Date | null;
    pinnedChatMessage: CBOEventReadModelPinnedMessage | null;
    products: CBOEventReadModelProduct[];
    restreamingConfigurations: CBORestreamingConfiguration[];
    showTime: Date | null;
    startTime: Date | null;
    youtubeRestreamingConfiguration: CBOYoutubeRestreamingConfiguration | null;
  };

export type CBOEventReadModelPinnedMessage = CBOEventReadModelDTOPinnedChatMessage;

export function toCBOEventReadModel(dto: CBOEventReadModelDTO): CBOEventReadModel {
  const products: CBOEventReadModelProduct[] = dto.products.map(toCBOProduct);

  const cboEventReadModel: CBOEventReadModel = {
    ...dto,
    adminMessageIconUploaded: false,
    brandLogoUploaded: false,
    cartIconUploaded: false,
    createdAt: new Date(dto.createdAt),
    deletedAt: dto.deletedAt ? new Date(dto.deletedAt) : null,
    facebookRestreamingConfiguration: dto.facebookRestreamingConfiguration
      ? {
          ...dto.facebookRestreamingConfiguration,
          expiresAt: new Date(dto.facebookRestreamingConfiguration.expiresAt),
        }
      : null,
    fileUploadUrls: null,
    finishTime: dto.finishTime ? new Date(dto.finishTime) : null,
    postEventCoverUploaded: false,
    preEventCoverUploaded: false,
    products,
    restreamingConfigurations: dto.restreamingConfigurations.map((restreamingConfiguration) => {
      if (restreamingConfiguration.enabled) {
        return {
          ...restreamingConfiguration,
          expiresAt: restreamingConfiguration.expiresAt ? new Date(restreamingConfiguration.expiresAt) : null,
        };
      }
      return restreamingConfiguration;
    }),
    showTime: dto.showTime ? new Date(dto.showTime) : null,
    startTime: dto.startTime ? new Date(dto.startTime) : null,
    storeIconUploaded: false,
    youtubeRestreamingConfiguration: dto.youtubeRestreamingConfiguration
      ? {
          ...dto.youtubeRestreamingConfiguration,
          expiresAt: new Date(dto.youtubeRestreamingConfiguration.expiresAt),
        }
      : null,
  };

  return cboEventReadModel;
}

export const EVENT_PRODUCTS_COUNT_LIMIT = 200;
