import { Box, LinearProgress, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UpgradePlanButton from '../../atoms/UpgradePlanButton';

const ShoppablesCounterWithUpgradePlanButtonRoot = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}));

const ShoppablesCounterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const ShoppablesCounterProgress = styled(LinearProgress)({
  borderRadius: '25px',
  height: '8px',
});

const ShoppablesCounterText = styled(Typography)(({ color, theme }) => ({
  color: color === 'error' ? theme.palette.error.main : theme.palette.grey[600],
}));

export type ShoppablesUpgradePlanButtonProps = {
  maxPublishedShoppablesCount: number;
  publishedShoppablesCount: number;
  tenantName: string;
};

const ShoppablesCounterUpgradePlanButton: FC<ShoppablesUpgradePlanButtonProps> = ({
  maxPublishedShoppablesCount,
  publishedShoppablesCount,
  tenantName,
}) => {
  const { t } = useTranslation('shoppables');
  const hasExceededPlanConstraints = publishedShoppablesCount > maxPublishedShoppablesCount;

  let value = (publishedShoppablesCount / maxPublishedShoppablesCount) * 100;
  if (value > 100) value = 100;

  return (
    <ShoppablesCounterWithUpgradePlanButtonRoot>
      <ShoppablesCounterContainer>
        <ShoppablesCounterProgress
          color={hasExceededPlanConstraints ? 'error' : 'primary'}
          value={value}
          variant="determinate"
        />
        <ShoppablesCounterText color={hasExceededPlanConstraints ? 'error' : 'primary'}>
          {t('PublishedShoppablesCount', { count: publishedShoppablesCount, maxCount: maxPublishedShoppablesCount })}
        </ShoppablesCounterText>
      </ShoppablesCounterContainer>
      <UpgradePlanButton tenantName={tenantName} />
    </ShoppablesCounterWithUpgradePlanButtonRoot>
  );
};

export default ShoppablesCounterUpgradePlanButton;
