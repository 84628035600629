import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { NavigationTabs } from '../../../ui/navigation-tabs';
import { INITIAL_TUTORIAL_STATE, TutorialState } from '../state/tutorial';

export const toggleTutorial = createAction<{ isShown: boolean }>('tutorial/show');
export const nextTutorialStep = createAction<{ highlightedTab: NavigationTabs | null }>('tutorial/next-step');

export type TutorialSliceActions = ReturnType<typeof nextTutorialStep> | ReturnType<typeof toggleTutorial>;

const buildReducers = (builder: ActionReducerMapBuilder<TutorialState>) => {
  builder.addCase(toggleTutorial, (state, action) => ({
    ...state,
    currentStep: 0,
    highlightedTab: null,
    isShown: action.payload.isShown,
  }));

  builder.addCase(nextTutorialStep, (state, action) => ({
    ...state,
    currentStep: state.currentStep + 1,
    highlightedTab: action.payload.highlightedTab,
  }));
};

export const tutorialSlice = createSlice({
  extraReducers: (builder) => {
    buildReducers(builder);
  },
  initialState: INITIAL_TUTORIAL_STATE,
  name: 'tutorial',
  reducers: {},
});
