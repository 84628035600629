import { EventId } from '@bellepoque/api-contracts';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { routes } from '../../routes';
import LiveEventsTemplate from '../../templates/LiveEventsTemplate';
import { createLiveEventsViewModel } from './LiveEvents.viewmodel';

type LiveEventProps = {
  isDrawerOpen: boolean;
};

const LiveEvents = ({ isDrawerOpen }: LiveEventProps) => {
  const viewModel = useSelector(createLiveEventsViewModel({ dispatch: useDispatch() }));

  const {
    canShareEvents,
    fetchEvents,
    fetchLandingPage,
    landingPageData,
    landingPageTenantId,
    liveEvents,
    liveEventsFetchingStatus,
    tenantId,
    trackUserJourneyLiveEvent,
  } = viewModel;

  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location]);

  const handleGoToLiveEventDetails = (id: EventId) => {
    navigate(routes.events.settings({ eventId: id }));
  };

  const handleGoToLiveEvents = () => {
    navigate(routes.events.root, { replace: true });
  };

  useEffect(() => {
    trackUserJourneyLiveEvent();
  }, []);

  useEffect(() => {
    fetchEvents();
    if (canShareEvents && landingPageTenantId !== tenantId) {
      fetchLandingPage();
    }
  }, [tenantId]);

  return (
    <LiveEventsTemplate
      canShareEvents={canShareEvents}
      isDrawerOpen={isDrawerOpen}
      landingPageData={landingPageData}
      liveEvents={liveEvents}
      liveEventsFetchingStatus={liveEventsFetchingStatus}
      onGoToLiveEventDetails={handleGoToLiveEventDetails}
      onGoToLiveEvents={handleGoToLiveEvents}
      urlParams={urlParams}
    />
  );
};

export default LiveEvents;
