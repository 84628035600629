import { Box, LinearProgress, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, { PropsWithChildren } from 'react';

type DetailsContainerTemplateProps = PropsWithChildren<{
  actionsChildren?: React.ReactNode;
  actionsLoading: boolean;
  filterChildren?: React.ReactNode;
  headerLoading: boolean;
  title: string;
}>;

export default function DetailsContainer(props: DetailsContainerTemplateProps) {
  const renderActionBar = () => {
    if (!props.actionsChildren) {
      return null;
    }

    return (
      <>
        <Box sx={{ width: '100%' }}>{props.actionsLoading ? <LinearProgress /> : <Divider variant="fullWidth" />}</Box>
        <Box
          sx={{
            width: {
              md: '50%',
              xs: '95%',
            },
          }}
        >
          {props.actionsChildren}
        </Box>
      </>
    );
  };
  return (
    <Box
      id="details-template"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        id="details-header"
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          pb: 1,
          pl: 3,
          pr: 2,
          textAlign: 'left',
          width: '100%',
        }}
      >
        <Typography
          style={{
            fontSize: '1.7vw',
            fontWeight: 'bold',
            lineHeight: '1.235',
          }}
          variant="h4"
        >
          {props.title}
        </Typography>
        {props.filterChildren}
      </Box>

      <Box sx={{ width: '100%' }}>{props.headerLoading ? <LinearProgress /> : <Divider variant="fullWidth" />}</Box>

      <Box id="details-template-content" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {props.children}
      </Box>

      {renderActionBar()}
    </Box>
  );
}
