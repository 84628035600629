import { Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 0,
  paddingLeft: '16px',
  paddingRight: '16px',
  textAlign: 'initial',
  width: '100%',
});

export default function VariantsHeader() {
  const { t } = useTranslation(['common', 'products']);

  return (
    <Container container spacing={1}>
      <Grid item xs={2.5}>
        <Typography fontWeight="bold" variant="subtitle2">
          {t('products:Variants')}
        </Typography>
      </Grid>
      <Grid item xs={1.5}></Grid>
      <Grid item xl={1.5} xs={2}>
        <Typography fontWeight="bold" variant="subtitle2">
          {t('Price')}
        </Typography>
      </Grid>
      <Grid item xl={1.5} xs={2}>
        <Typography fontWeight="bold" variant="subtitle2">
          {t('CrossedPrice')}
        </Typography>
      </Grid>
      <Grid item xl={2.5} xs={3}>
        <Typography fontWeight="bold" variant="subtitle2">
          {t('products:VariantId')}
        </Typography>
      </Grid>
      <Grid item xs={1}></Grid>
    </Container>
  );
}
