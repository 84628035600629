import { EventId } from '@bellepoque/api-contracts';

import { CBOLiveEventStatistics } from '../../../domain/Statistics';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type FetchLiveEventStatisticsRequest = {
  eventId: EventId;
};

type FetchLiveEventStatisticsParams = {
  eventId: EventId;
};

export const fetchLiveEventStatistics = createAppAsyncThunk(
  'events/fetch-live-event-statistics',
  async ({ eventId }: FetchLiveEventStatisticsParams, { extra: { eventGateway } }): Promise<CBOLiveEventStatistics> => {
    return eventGateway.fetchOneLiveEventStatistics(eventId);
  },
);
