import { TenantId, V2 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { LandingPageGateway } from '../../core/gateways/landing-page-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiLandingPageGateway extends ApiGateway implements LandingPageGateway {
  async createCmsLandingPage(tenantId: string): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: {},
        url: `${this.apiEndpoint}/tenants/${tenantId}/create-cms-landing-page`,
        verb: 'post',
      }),
    );
  }

  async fetchOne(tenantId: TenantId): Promise<V2.api.LandingPageDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V2.api.LandingPageDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/landing-page`,
      }),
    );
  }

  async hasCmsLandingPage(tenantId: string): Promise<V2.api.HasCmsLandingPageDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V2.api.HasCmsLandingPageDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/has-cms-landing-page`,
      }),
    );
  }

  async updateOne(tenantId: TenantId, dto: V2.api.UpdateLandingPageDTO): Promise<V2.api.LandingPageDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V2.api.LandingPageDTO>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/landing-page`,
        verb: 'put',
      }),
    );
  }
}
