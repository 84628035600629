import { Backdrop, Box, CircularProgress, styled } from '@mui/material';
import React, { useMemo } from 'react';

import Tabs, { TabProps, tabWidthInPx } from '../../components/molecules/Tabs';
import TabPanel from '../TabPanel';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
});

const TabsContainer = styled(Box)({
  height: '100%',
  position: 'fixed',
  top: '64px',
});

export type EventDetailsTemplateProps = {
  activeTab: number;
  loading: boolean;
  onChangeTab: (index: number) => void;
  tabs: TabProps[];
};

export default function EventDetailsTemplate({ activeTab, loading, onChangeTab, tabs }: EventDetailsTemplateProps) {
  const loader = useMemo(
    () => (
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    ),
    [loading],
  );

  return (
    <Container>
      {loader}
      <Box height="100%" minWidth={tabWidthInPx}>
        <TabsContainer>
          <Tabs activeTab={activeTab} onChangeTab={onChangeTab} tabs={tabs} />
        </TabsContainer>
      </Box>
      <Box flex={1}>
        {tabs.map(({ component, index, label }) => (
          <TabPanel index={index} key={label} value={activeTab}>
            {component}
          </TabPanel>
        ))}
      </Box>
    </Container>
  );
}
