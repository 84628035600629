import { HomeNotificationId } from '../ui/pages/homepage/Home';

export const localStoragePrefix = 'LMU@';

export function getLocalStorageItem(key: string): string | null {
  return localStorage.getItem(`${localStoragePrefix}${key}`);
}

export function setLocalStorageItem(key: string, value: string): void {
  localStorage.setItem(`${localStoragePrefix}${key}`, value);
}

export function clearLocalStorageItem(key: string): void {
  localStorage.removeItem(`${localStoragePrefix}${key}`);
}

export function getLocalStorageNotificationSeen(tenantId: string, notificationId: HomeNotificationId): boolean {
  return getLocalStorageItem(`${tenantId}-${notificationId}-notification`) === 'seen';
}

export function setLocalStorageNotificationSeen(tenantId: string, notificationId: HomeNotificationId) {
  setLocalStorageItem(`${tenantId}-${notificationId}-notification`, 'seen');
}

export function resetLocalStorageNotificationSeen(tenantId: string, notificationId: HomeNotificationId) {
  clearLocalStorageItem(`${tenantId}-${notificationId}-notification`);
}
