import { Box, Paper, Typography, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { eventToUserJourneyData } from '../../../../utils/event-to-user-journey-data';
import OnboardingTitle from '../../../components/atoms/onboarding/OnboardingTitle';
import PhoneNumber from '../../../components/atoms/onboarding/PhoneNumber';
import StoreLinks from '../../../components/molecules/onboarding/StoreLinks';
import { createOnboardingCameraViewModel } from './OnboardingCamera.viewmodel';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  height: '100%',
  justifyContent: 'center',
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledPaper = styled(Paper)({
  borderRadius: '21px',
  maxWidth: '800px',
});

const Footer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderTop: `solid ${theme.palette.grey[400]} 1px`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

const PhoneNumberWithTextContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'center',
}));

const OnboardingCamera = () => {
  const { t } = useTranslation('onboarding', { keyPrefix: 'Steps.CameraApp' });

  const viewModel = useSelector(createOnboardingCameraViewModel({ dispatch: useDispatch() }));

  const { event, publisherPhone, trackUserJourneyEvent, tenantId, tenantName } = viewModel;

  useEffect(() => {
    if (event)
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: 'Onboarding - Camera',
      });
  }, []);

  return (
    <Container>
      <StyledPaper elevation={1}>
        <OnboardingTitle>{t('Title')}</OnboardingTitle>
        <ContentContainer display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="center">{t('MobileAppPresentation')}</Typography>
          <StoreLinks />
        </ContentContainer>
        <Footer flexDirection="column">
          <PhoneNumberWithTextContainer>
            <Typography>{t('GiveYourPhoneNumberPart1')}</Typography>
            {!!publisherPhone && <PhoneNumber>{publisherPhone}</PhoneNumber>}
          </PhoneNumberWithTextContainer>
          <Box flex={1}>
            <Typography>{t('GiveYourPhoneNumberPart2')}</Typography>
          </Box>
        </Footer>
      </StyledPaper>
    </Container>
  );
};

export default OnboardingCamera;
