import { InputAdornment, TextField, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { CBOCurrency } from '../../../core/domain/CBOCurrency';
import { formatCurrency } from '../../../utils/currency-formatter';

interface ProductPriceProps {
  currency: CBOCurrency;
  defaultValue: number | null;
  disabled?: boolean;
  field: any;
  hideLabel?: boolean;
  id: string;
  isRequired?: boolean;
  isVariantPrice?: boolean;
  label: string;
  onChange: (value: number | null) => void;
}

export default function ProductPriceInput(props: ProductPriceProps) {
  const {
    currency,
    defaultValue,
    disabled,
    field,
    hideLabel,
    id,
    isRequired,
    isVariantPrice = false,
    label,
    onChange,
  } = props;
  const theme = useTheme();

  const getDefaultDisplayedValue = () =>
    defaultValue ? formatCurrency(defaultValue, currency, { withoutSymbol: true }) : isRequired ? '0' : '';

  const [displayedValue, setDisplayedValue] = useState(getDefaultDisplayedValue());

  useEffect(() => {
    setDisplayedValue(getDefaultDisplayedValue());
  }, [defaultValue]);

  const getFormattedAndFieldValue = useCallback(
    (value: string) => {
      const numberValue = parseFloat(value.replace(',', '.'));
      const formattedValue = numberValue
        ? formatCurrency(numberValue, currency, { withoutSymbol: true })
        : isRequired
        ? '0'
        : '';
      const fieldValue = numberValue ? numberValue : isRequired ? 0 : null;
      return { fieldValue, formattedValue };
    },
    [isRequired, currency],
  );

  return (
    <TextField
      {...field}
      InputProps={{
        startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
        style: isVariantPrice
          ? { padding: '4px' }
          : {
              paddingTop: '8px',
            },
      }}
      disabled={disabled}
      hiddenLabel
      id={id}
      label={hideLabel ? undefined : label}
      onBlur={(e) => {
        const { formattedValue, fieldValue } = getFormattedAndFieldValue(e.target.value);
        setDisplayedValue(formattedValue);
        onChange(fieldValue);
      }}
      onChange={(e) => setDisplayedValue(e.target.value)}
      size={isVariantPrice ? 'small' : 'medium'}
      style={{ paddingBottom: 0 }}
      sx={isVariantPrice ? { backgroundColor: theme.palette.common.white, borderRadius: '4px' } : {}}
      value={displayedValue}
      variant="filled"
    />
  );
}
