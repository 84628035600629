import Image from '@jy95/material-ui-image';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UrlDialog from '../../../templates/dialog/UrlDialog';

const StyledImage = styled(Image)({
  width: '100%',
});

const AddIconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
}));

const AddIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '100%',
  height: '50px',
  position: 'relative',
  width: '50px',
}));
interface ImageSelectorByUrlProps {
  disabled: boolean;
  id: string;
  imgUrl?: string;
  onChange: (newImageUrl: string) => void;
  required: boolean;
  title: string;
}

const StyledButton = styled(Button)({
  aspectRatio: '1 / 1',
  height: '100%',
});

export default function ImageSelectorByUrl(props: ImageSelectorByUrlProps) {
  const { disabled, id, onChange, required, title, imgUrl } = props;
  const { t } = useTranslation(['events']);

  const [changeImageUrlOpen, setChangeImageUrlOpen] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(1);

  return (
    <Box width="100%">
      <StyledButton disableFocusRipple disabled={disabled} fullWidth onClick={() => setChangeImageUrlOpen(true)}>
        {imgUrl ? (
          <Box height="100%" width="100%">
            <StyledImage
              alt={title}
              animationDuration={1000}
              imageStyle={{ objectFit: 'contain', opacity: imageOpacity }}
              onMouseEnter={() => setImageOpacity(0.7)}
              onMouseOut={() => setImageOpacity(1)}
              src={imgUrl}
            />
          </Box>
        ) : (
          <AddIconContainer>
            <AddIconButton>
              <AddIcon />
            </AddIconButton>
          </AddIconContainer>
        )}
      </StyledButton>
      {changeImageUrlOpen && (
        <UrlDialog
          defaultUrl={imgUrl}
          id={id}
          label={t('ImageURL')}
          onClose={(params) => {
            setChangeImageUrlOpen(false);
            onChange(params.url);
          }}
          open={changeImageUrlOpen}
          required={required}
        />
      )}
    </Box>
  );
}
