import { EventId } from '@bellepoque/api-contracts';
import { ProductId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type DeleteEventProductParams = {
  eventId: EventId;
  productId: ProductId;
};

export const deleteEventProduct = createAppAsyncThunk(
  'events/delete-product',
  async ({ eventId, productId }: DeleteEventProductParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.deleteEventProduct(eventId, productId);
  },
);
