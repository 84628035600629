import { AppBar, Box, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../../atoms/CreateButton';

const Container = styled(AppBar)(({ theme }) => ({
  alignItems: 'flex-end',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'row',
  left: 0,
  padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
  position: 'absolute',
  right: 'auto',
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
  marginRight: theme.spacing(7),
  paddingBottom: theme.spacing(1),
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}));

interface AnalyticsHeaderProps {
  isCreateEventButtonShown: boolean;
  isCreateShoppableVideoButtonShown: boolean;
  onCreateEvent: () => void;
  onCreateShoppableVideo: () => void;
}

export default function AnalyticsHeader({
  isCreateEventButtonShown,
  isCreateShoppableVideoButtonShown,
  onCreateEvent,
  onCreateShoppableVideo,
}: AnalyticsHeaderProps) {
  const { t } = useTranslation(['events', 'shoppables']);
  return (
    <Container elevation={0}>
      <Title variant="h4">{t('Analytics')}</Title>
      <ActionsContainer>
        {isCreateEventButtonShown && <CreateButton onClick={onCreateEvent} text={t('events:CreateAnEvent')} />}
        {isCreateShoppableVideoButtonShown && (
          <CreateButton onClick={onCreateShoppableVideo} text={t('shoppables:CreateShoppable')} />
        )}
      </ActionsContainer>
    </Container>
  );
}
