import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import { loginConfirmFulfilled } from '../../slices/authentication.slice';
import { toggleTutorial } from '../../slices/tutorial.slice';
import { ofType } from '../utils';

export const toggleTutorialEpic: AppEpic = (action$) => {
  return action$.pipe(
    ofType<ReturnType<typeof loginConfirmFulfilled>>(loginConfirmFulfilled.type),
    switchMap((action) => of(toggleTutorial({ isShown: action.payload.user.previousLogin === null }))),
  );
};
