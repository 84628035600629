import { EventStatus } from '@bellepoque/api-contracts';

const UPCOMING_EVENT_STATUSES = [
  EventStatus.DRAFT,
  EventStatus.ON_AIR,
  EventStatus.PLANNED,
  EventStatus.PRIVATE_PREVIEW,
];

export const isLiveEvent = (eventStatus: EventStatus): boolean => {
  const values: EventStatus[] = Object.values(UPCOMING_EVENT_STATUSES);
  return values.includes(eventStatus);
};
