import { TenantId } from '@bellepoque/api-contracts';

import { synchronizeOneShoppablePending } from '../../store/slices/shoppables/shoppables-synchronize.slice';

export type FetchOneShoppableRequest = {
  shoppableId: string;
  tenantId: TenantId;
};

export const fetchOneShoppable = (request: FetchOneShoppableRequest) => {
  return synchronizeOneShoppablePending(request);
};
