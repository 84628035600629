import { EventId, V1 } from '@bellepoque/api-contracts';
import { ProductId } from '@bellepoque/api-contracts';

import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type UpdateEventProductParams = {
  eventId: EventId;
  updatedProduct: CBOEventReadModelProduct;
};

export type DeleteEventProductParams = {
  eventId: EventId;
  productId: ProductId;
};

export const updateEventProduct = createAppAsyncThunk(
  'events/update-product',
  async ({ eventId, updatedProduct }: UpdateEventProductParams, { extra: { eventGateway } }): Promise<void> => {
    const updatedProductState = updatedProduct.toState();
    if (updatedProductState.isSynced) {
      const dto: V1.api.UpdateProductOverrideDTO = {
        description: updatedProductState.description,
        imageUrls: updatedProduct.imageUrls,
        title: updatedProduct.title,
      };

      return eventGateway.updateOneSyncedProduct(eventId, updatedProduct.id, dto);
    }

    const dto: V1.api.UpdateProductDTO = {
      crossedOutPrice: updatedProductState.crossedOutPrice,
      customerInternalId: updatedProductState.customerInternalId,
      description: updatedProductState.description,
      imageUrls: updatedProduct.imageUrls,
      options: updatedProductState.options,
      price: updatedProductState.price,
      title: updatedProduct.title,
      url: updatedProductState.url,
    };

    return eventGateway.updateOneUnsyncedProduct(eventId, updatedProduct.id, dto);
  },
);
