import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function NappsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      clipRule="evenodd"
      fillRule="evenodd"
      height="100px"
      shapeRendering="geometricPrecision"
      version="1.1"
      viewBox="0 0 100 100"
      width="100px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 55.5,-0.5 C 70.1667,-0.5 84.8333,-0.5 99.5,-0.5C 99.5,32.8333 99.5,66.1667 99.5,99.5C 88.1667,99.5 76.8333,99.5 65.5,99.5C 65.5,77.5 65.5,55.5 65.5,33.5C 54.8333,33.5 44.1667,33.5 33.5,33.5C 33.5,55.5 33.5,77.5 33.5,99.5C 22.1667,99.5 10.8333,99.5 -0.5,99.5C -0.5,77.1667 -0.5,54.8333 -0.5,32.5C 10.1667,32.5 20.8333,32.5 31.5,32.5C 32.6626,16.3618 40.6626,5.36183 55.5,-0.5 Z"
        fill="#253137"
      />
    </SvgIcon>
  );
}
