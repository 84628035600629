import { EventId } from '@bellepoque/api-contracts';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ReplaysTemplate from '../../templates/ReplaysTemplate';
import { createReplaysViewModel } from './Replays.viewmodel';

type ReplayProps = {
  isDrawerOpen: boolean;
};

const Replays = ({ isDrawerOpen }: ReplayProps) => {
  const viewModel = useSelector(createReplaysViewModel({ dispatch: useDispatch(), navigate: useNavigate() }));

  const {
    canShareEvents,
    fetchEvents,
    fetchLandingPage,
    landingPageData,
    goToReplayDetails,
    replays,
    replaysFetchingStatus,
    tenantId,
    landingPageTenantId,
    tenantName,
    trackUserJourneyEvent,
  } = viewModel;

  const handleGoToReplayDetails = (id: EventId) => {
    goToReplayDetails(id);
  };

  useEffect(() => {
    fetchEvents();

    if (canShareEvents && landingPageTenantId !== tenantId) {
      fetchLandingPage();
    }
  }, [tenantId]);

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Replays',
    });
  }, []);

  return (
    <ReplaysTemplate
      canShareEvents={canShareEvents}
      isDrawerOpen={isDrawerOpen}
      landingPageData={landingPageData}
      loading={replaysFetchingStatus === 'pending'}
      onGoToReplayDetails={handleGoToReplayDetails}
      replays={replays}
      replaysFetchingStatus={replaysFetchingStatus}
    />
  );
};

export default Replays;
