import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type ToggleLiveViewersCountDisplayParams = {
  eventId: EventId;
  isDisplayed: boolean;
};

export const toggleLiveViewersCountDisplay = createAppAsyncThunk(
  'events/toggle-live-viewers-count-display',
  async ({ eventId, isDisplayed }: ToggleLiveViewersCountDisplayParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.toggleLiveViewersCountDisplay(eventId, { isDisplayed });
  },
);
