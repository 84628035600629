export default {
  AddProduct: {
    SelectProduct: 'Select your product',
  },
  AddToMediaCollection: 'Add to playlist',
  BrandLogo: 'Brand Logo',
  CannotPublishModal: {
    Text: 'You already have a shoppable video published for this product. Please unpublish it first and come back here to display this new one instead. Publishing multiple videos per product page will be released soon!',
    Title: `You can't publish this shoppable video`,
  },
  CopyVideoId: 'Copy video ID',
  CreateShoppable: 'Create shoppable',
  Delete: 'Delete',
  DeleteShoppable: 'Delete Shoppable Video?',
  DeleteShoppableDescription: 'This video will be deleted. Its analytics will be saved.',
  Duplicate: 'Duplicate',
  Duration: 'Duration',
  Edit: 'Edit',
  EditShoppable: 'Edit shoppable',
  Helpers: {
    Font: 'Select your brand’s font that will be loaded in our video player',
    ShoppableHeaderLogo: 'Upload your logo that will show up in the top left corner of the Shoppable Videos',
  },
  NewMediaCollection: 'New playlist',
  NewShoppable: 'New shoppable',
  OptimizingDescription: 'Optimization may take over a minute.',
  PlayerPlaylistsBlockModal: {
    Line1: '1. Go to your',
    Line2: '2. Select the page where to add our bloc/widget.',
    Line3: `3. Activate and position the <Underlined>widget stories (bubbles) or widget Carousel (Posters)</Underlined> block.`,
    Line4: '4. Save and come back here.',
    ShopifyThemeEditor: 'Theme Editor',
    Title: 'Enable block on your Store',
    Tutorial: `If needed, you can find a tutorial <Link> here.</Link>`,
  },
  Preview: 'Preview',
  PreviewTitle: 'Video title',
  Product: 'Product',
  PublishedShoppablesCount: '{{count}}/{{maxCount}} Shoppables on-site',
  Settings: 'Shoppable Video Settings',
  Shoppable: 'Shoppable',
  ShoppablePublishedCannotRemove: 'Please undisplay this video from your Shopify first.',
  ShoppableVideoManager: 'Shoppable Video Manager',
  Status: {
    Draft: 'Draft',
    NotPublished: 'Not displayed',
    OptimizationFailed: 'Optimization failed',
    Optimizing: 'Optimizing',
    Published: 'Displayed',
    Ready: 'Ready',
    Status: 'Status',
  },
  Thumbnail: 'Thumbnail',
  Title: 'Title',
  TitleIsRequired: 'Title is required',
  TitleLabel: 'Name your shoppable',
  UploadImage: {
    Action: 'Upload image',
  },
  UploadVideo: {
    Action: 'Select',
    Description: 'Drag and drop your video here*',
    HelperText: '*Vertical format video only',
    ImportDescription: 'Import from your mobile media library.',
    ImportVideo: 'Import video',
    InvalidURL: 'The URL is incorrect. Please check and try again.',
    OpenQRCode: 'Open QR code',
    SocialMediaNotSupported: 'This social media is not supported.',
    SocialMediaURL: 'Social media URL',
    UploadFromMobile: 'Upload from your mobile',
    UploadFromSocialMedia: 'Import from Social Media',
    UploadFromSocialMediaTooltip: 'Paste a URL from Facebook, Instagram reels, TikTok or YouTube to use the video.',
  },
  Video: 'Video',
  notifications: {
    ShoppableFailedCreation: 'An error occurred while creating the shoppable. Please retry.',
    ShoppableFailedDeleting: 'An error occurred while deleting the shoppable. Please retry.',
    ShoppableFailedPublishing: 'An error occurred while publishing the shoppable. Please retry.',
    ShoppableFailedUpdate: 'An error occurred while updating the shoppable. Please retry.',
    ShoppableIdSuccessfullyCopied: 'Shoppable video ID successfully copied to your clipboard!',
    ShoppableSuccessfullyCreated: 'Shoppable successfully created!',
    ShoppableSuccessfullyDeleted: 'Shoppable successfully deleted!',
    ShoppableSuccessfullyPublished: 'Shoppable successfully published!',
    ShoppableSuccessfullyUpdated: 'Shoppable successfully updated!',
    ShoppableVideoSuccessfullyImported: 'Video successfully imported!',
    ThumbnailConfigurationFailed: 'An error occurred while configuring the thumbnail. Please retry.',
  },
};
