import { BILLING_PLANS, Cms } from '@bellepoque/api-contracts';

import { CBOTenantTapcartIntegration } from '../../../../../core/domain/CBOTenant';
import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { updateIntegrations } from '../../../../../core/usecases/tenants/update-integrations';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { ENDPOINTS } from '../../../../../endpoints';

export const createTapcartIntegrationViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentTenant = state.tenants.currentTenant;

    const isEnabled =
      currentTenant.cms !== Cms.Shopify ||
      BILLING_PLANS[currentTenant.activeSubscriptionId].integrationsEnabled.tapcart;

    return {
      currentTenant,
      integrations: state.tenants.currentTenant.integrations,
      isEnabled,
      liveShoppingPageUrlToCopy: ENDPOINTS.tapcart.liveShoppingPageUrl(currentTenant.name),
      playlistsBlocUrlToCopy: ENDPOINTS.tapcart.playlistsBlocUrl,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateIntegration: (tapcart: CBOTenantTapcartIntegration) => {
        dispatch(
          updateIntegrations({
            integrations: { ...currentTenant.integrations, tapcart },
            tenantId: currentTenant.id,
          }),
        );
      },
      updateStatus: state.tenants.updateIntegrations.status,
    };
  };
