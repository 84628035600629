import { Box, FormControlLabel, FormGroup, Grid, Switch, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DraggableIcon from '../../../../icons/DraggableIcon';

const Title = styled(Typography)({
  fontWeight: 800,
});

const SwitchLabel = styled(Typography)(({ theme }) => ({
  marginRight: `${theme.spacing(3)}`,
  opacity: 0.5,
}));

const Container = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  alignItems: 'center',
  borderBottom: `solid ${theme.palette.grey[500]} 1px`,
  display: 'flex',
  justifyContent: 'center',
  marginLeft: `-${theme.spacing(1.5)}`,
  padding: `20px ${theme.spacing(3)} 20px ${theme.spacing(2)}`,
  textAlign: 'initial',
  width: `calc(100% + ${theme.spacing(3)})`,
}));

const SkeletonBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
}));

const SkeletonImage = styled(SkeletonBox)({
  borderRadius: '10px',
  height: '40px',
  width: '40px',
});

interface SkeletonEventRowProps {
  position: number | null;
  section: 'highlight' | 'replays';
}

export default function SkeletonEventRow({ position, section }: SkeletonEventRowProps) {
  const { t } = useTranslation('liveShoppingPage');
  return (
    <Container>
      <Box display="flex" flex={1}>
        <Grid alignItems="center" columnGap={2} container>
          <Grid item xs={1}>
            {section === 'replays' && (
              <Box alignItems="center" display="flex" flex={1} justifyContent="space-evenly">
                <Box width="15px">
                  {position !== null && <Typography sx={{ fontWeight: 'bold' }}>{position}</Typography>}
                </Box>
                <Box>
                  <DraggableIcon />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid alignItems="center" display="flex" item width="40px">
            <SkeletonImage />
          </Grid>
          <Grid alignItems="center" display="flex" item xs={3}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap="2px" height="30px" px={3} width="100%">
              <SkeletonBox height="50%" width="100%" />
              <SkeletonBox height="50%" width="33%" />
            </Box>
          </Grid>
          <Grid alignItems="center" display="flex" item xs={3}>
            <Title>{t(section === 'highlight' ? 'Highlights.NextLive' : 'Replays.NextReplay')}</Title>
          </Grid>
          <Grid alignItems="center" display="flex" flex={1} item justifyContent="flex-end">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={false} />}
                label={<SwitchLabel>{t('Display')}</SwitchLabel>}
                labelPlacement={'start'}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
