import { TCms } from '@bellepoque/api-contracts';
import AddIcon from '@mui/icons-material/Add';
import { Button, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BlurOverlay from '../../../templates/BlurOverlay';
import ActionDialog from '../../../templates/dialog/ActionDialog';

interface LiveShoppingPageCreationProps {
  isShown: boolean;
  loading: boolean;
  onCreate: () => void;
  submissionInProgress: boolean;
  tenantCms: TCms;
}

export default function LiveShoppingPageCreation({
  isShown,
  onCreate,
  submissionInProgress,
  tenantCms,
}: LiveShoppingPageCreationProps) {
  const { t: tCms } = useTranslation('common', { keyPrefix: 'Cms' });
  const { t } = useTranslation('liveShoppingPage', { keyPrefix: 'NoCmsPage' });
  const theme = useTheme();

  const actionButton = useMemo(
    () => (
      <Button
        color="secondary"
        disabled={submissionInProgress}
        onClick={onCreate}
        startIcon={<AddIcon fontSize="large" />}
        sx={{
          backgroundColor: theme.palette.secondary.dark,
        }}
        variant="contained"
      >
        {t('Action')}
      </Button>
    ),
    [onCreate, t, submissionInProgress],
  );

  return (
    <BlurOverlay shown={isShown}>
      <ActionDialog
        actionButton={actionButton}
        content={t('Description')}
        open
        title={t('Title', { cms: tCms(tenantCms) })}
      />
    </BlurOverlay>
  );
}
