import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ProductsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 19 17" {...props}>
      <g transform="translate(-3 -4)">
        <path d="M21.98,7.38c0-.02-.01-.03-.01-.04V7.33a1.34,1.34,0,0,0-.06-.12.01.01,0,0,0-.01-.01.2.2,0,0,0-.09-.09c0-.01,0-.01-.01-.01a.161.161,0,0,0-.07-.04c-.01-.01-.02-.02-.03-.02a.076.076,0,0,0-.04-.01l-.05-.02H21.6L12.72,4.05l-.06-.02A.4.4,0,0,0,12.51,4h-.02a.4.4,0,0,0-.15.03l-.06.02L7.88,5.52l-.07.02L3.39,7.01l-.05.02c-.02.01-.05.01-.07.03l-.06.03a.01.01,0,0,0-.01.01.179.179,0,0,0-.09.1.354.354,0,0,0-.09.16A.59.59,0,0,0,3,7.5v10a.486.486,0,0,0,.34.47l8.97,2.99a.037.037,0,0,0,.03.01.442.442,0,0,0,.32,0l.03-.01,8.97-2.99A.486.486,0,0,0,22,17.5V7.5a.5.5,0,0,0-.02-.12ZM12.5,5.02,19.93,7.5,17,8.48,9.57,6ZM12,19.81,4,17.14V8.19l8,2.67Zm.5-9.83L5.07,7.5,8,6.52,15.43,9ZM21,17.14l-8,2.67V10.86l4.12-1.38a.145.145,0,0,0,.07-.02L21,8.19Z" />
      </g>
    </SvgIcon>
  );
}
