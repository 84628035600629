import { EventId, TenantId } from '@bellepoque/api-contracts';

import { synchronizeOneEventPending } from '../../../store/slices/event/event-synchronize.slice';

export type FetchEventRequest = {
  eventId: EventId;
  tenantId: TenantId;
};

export const fetchOneEvent = (request: FetchEventRequest) => {
  return synchronizeOneEventPending(request);
};
