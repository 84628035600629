import { UserJourneyEvent } from '../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../core/store';
import { fetchLandingPage } from '../../../../core/usecases/landing-page/fetch-landing-page';
import { trackUserJourneyEvent } from '../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createOnboardingLiveManagerViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const landingPageUrl = state.landingPage.landingPageData.landingPageUrl;
    const currentEvent = state.events.currentEvent;
    const { id: tenantId, name: tenantName } = state.tenants.currentTenant;

    return {
      event: currentEvent,
      fetchLandingPage: () => {
        dispatch(fetchLandingPage({ tenantId: tenantId }));
      },
      landingPageUrl,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
