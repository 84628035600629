import { QuestionAnswerOutlined } from '@mui/icons-material';
import { Box, LinearProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  borderRight: `solid 1px ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '50%',
  display: 'grid',
  height: '30px',
  justifyContent: 'center',
  width: '30px',
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(6),
  height: '40px',
  marginLeft: theme.spacing(1),
}));

export default function ChatLoadingHeader() {
  const { t } = useTranslation('events');

  return (
    <>
      <Container>
        <ContentContainer>
          <IconContainer>
            <QuestionAnswerOutlined fontSize="small" htmlColor="white" />
          </IconContainer>
          <TitleContainer>
            <Typography fontSize="1.1em" fontWeight={600} variant="h6">
              {t('Chat')}
            </Typography>
          </TitleContainer>
        </ContentContainer>
        <LinearProgress />
      </Container>
    </>
  );
}
