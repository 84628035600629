import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { countPublishedShoppablesFromMediaCollections } from '../../../utils/count-published-shoppables-from-media-collections';
import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { fetchHomePage } from '../../usecases/home/fetch-home-page';
import { createMediaCollection } from '../../usecases/media-collections/create-media-collection';
import { deleteMediaCollection } from '../../usecases/media-collections/delete-media-collection';
import { fetchMediaCollections } from '../../usecases/media-collections/fetch-media-collections';
import { fetchOneMediaCollection } from '../../usecases/media-collections/fetch-one-media-collection';
import {
  UpdateMediaCollectionPagesRequest,
  updateMediaCollectionPages,
} from '../../usecases/media-collections/update-media-collection-pages';
import {
  UpdateMediaCollectionSettingsRequest,
  updateMediaCollectionSettings,
} from '../../usecases/media-collections/update-media-collection-settings';
import { INITIAL_MEDIA_COLLECTIONS_STATE, MediaCollectionsState } from '../state/mediaCollections';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../utils';

export const resetCreateMediaCollection = createAction<void>('media-collections/reset-create-media-collection');

export const resetUpdateMediaCollectionSettings = createAction<void>(
  'media-collections/reset-update-media-collection-settings',
);
export const resetUpdateMediaCollectionPages = createAction<void>(
  'media-collections/reset-update-media-collection-pages',
);

const updateMediaCollectionSettingsInState = (
  state: MediaCollectionsState,
  request: UpdateMediaCollectionSettingsRequest,
) => {
  const { payload, mediaCollectionId } = request;
  let currentMediaCollection = state.currentMediaCollection;

  if (currentMediaCollection) {
    currentMediaCollection = {
      ...currentMediaCollection,
      ...payload,
    };
  }

  const updatedMediaCollections = [...state.mediaCollections].map((mediaCollection) => {
    if (mediaCollection.id === mediaCollectionId && currentMediaCollection) return currentMediaCollection;
    return mediaCollection;
  });

  return {
    currentMediaCollection,
    mediaCollections: updatedMediaCollections,
  };
};

const updateMediaCollectionPagesInState = (
  state: MediaCollectionsState,
  request: UpdateMediaCollectionPagesRequest,
) => {
  const { payload, mediaCollectionId } = request;
  let currentMediaCollection = state.currentMediaCollection;

  if (currentMediaCollection) {
    currentMediaCollection = {
      ...currentMediaCollection,
      ...payload,
    };
  }

  const updatedMediaCollections = [...state.mediaCollections].map((mediaCollection) => {
    if (mediaCollection.id === mediaCollectionId && currentMediaCollection) return currentMediaCollection;
    return mediaCollection;
  });

  return {
    currentMediaCollection,
    mediaCollections: updatedMediaCollections,
  };
};

const buildMediaCollectionsReducers = (builder: ActionReducerMapBuilder<MediaCollectionsState>) => {
  builder.addCase(createMediaCollection.fulfilled, (state, action) => {
    const createPayload = action.meta.arg.payload;

    const newMediaCollection: CBOMediaCollection = {
      id: createPayload.id,
      medias: createPayload.medias,
      publishedPages: [],
      tenantId: action.meta.arg.tenantId,
      title: createPayload.title,
    };
    const mediaCollections = [...state.mediaCollections];
    mediaCollections.unshift(newMediaCollection);

    return {
      ...state,
      ...successStatus('mediaCollectionCreation'),
      currentMediaCollection: newMediaCollection,
      mediaCollections: [newMediaCollection, ...state.mediaCollections],
    };
  });
  builder.addCase(createMediaCollection.pending, (state) => ({
    ...state,
    ...pendingStatus('mediaCollectionCreation'),
  }));
  builder.addCase(createMediaCollection.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionCreation', [action.error]),
  }));
  builder.addCase(deleteMediaCollection.pending, (state) => ({
    ...state,
    ...pendingStatus('mediaCollectionDeletion'),
  }));
  builder.addCase(deleteMediaCollection.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionDeletion', [action.error]),
  }));
  builder.addCase(deleteMediaCollection.fulfilled, (state, action) => {
    const mediaCollections = state.mediaCollections.filter(
      (mediaCollection) => mediaCollection.id !== action.meta.arg.id,
    );

    return {
      ...state,
      ...successStatus('mediaCollectionDeletion'),
      mediaCollections,
    };
  });
  builder.addCase(resetCreateMediaCollection, (state) => ({
    ...state,
    ...notRequestedStatus('mediaCollectionCreation'),
  }));
  builder.addCase(resetUpdateMediaCollectionPages, (state) => ({
    ...state,
    ...notRequestedStatus('mediaCollectionPagesUpdate'),
  }));
  builder.addCase(resetUpdateMediaCollectionSettings, (state) => ({
    ...state,
    ...notRequestedStatus('mediaCollectionSettingsUpdate'),
  }));

  builder.addCase(fetchMediaCollections.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('mediaCollectionsFetching'),
    mediaCollections: action.payload,
    mediaCollectionsTenantId: action.meta.arg.tenantId,
    publishedShoppablesCount: countPublishedShoppablesFromMediaCollections(action.payload),
  }));
  builder.addCase(fetchMediaCollections.pending, (state, action) => ({
    ...state,
    ...pendingStatus('mediaCollectionsFetching'),
    mediaCollections: [],
    mediaCollectionsTenantId: action.meta.arg.tenantId,
  }));
  builder.addCase(fetchMediaCollections.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionsFetching', [action.error]),
  }));

  builder.addCase(fetchOneMediaCollection.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('mediaCollectionFetching'),
    currentMediaCollection: action.payload,
  }));
  builder.addCase(fetchOneMediaCollection.pending, (state) => ({
    ...state,
    ...pendingStatus('mediaCollectionFetching'),
  }));
  builder.addCase(fetchOneMediaCollection.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionFetching', [action.error]),
  }));

  builder.addCase(updateMediaCollectionSettings.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('mediaCollectionSettingsUpdate'),
    ...updateMediaCollectionSettingsInState(state, action.meta.arg),
  }));
  builder.addCase(updateMediaCollectionSettings.pending, (state) => ({
    ...state,
    ...pendingStatus('mediaCollectionSettingsUpdate'),
  }));
  builder.addCase(updateMediaCollectionSettings.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionSettingsUpdate', [action.error]),
  }));
  builder.addCase(updateMediaCollectionPages.fulfilled, (state, action) => {
    const { currentMediaCollection, mediaCollections } = updateMediaCollectionPagesInState(state, action.meta.arg);
    return {
      ...state,
      ...successStatus('mediaCollectionPagesUpdate'),
      currentMediaCollection,
      mediaCollections,
      publishedShoppablesCount: countPublishedShoppablesFromMediaCollections(mediaCollections),
    };
  });
  builder.addCase(updateMediaCollectionPages.pending, (state) => ({
    ...state,
    ...pendingStatus('mediaCollectionPagesUpdate'),
  }));
  builder.addCase(updateMediaCollectionPages.rejected, (state, action) => ({
    ...state,
    ...errorStatus('mediaCollectionPagesUpdate', [action.error]),
  }));
};

const buildMediaCollectionsHomeReducers = (builder: ActionReducerMapBuilder<MediaCollectionsState>) => {
  builder.addCase(fetchHomePage.fulfilled, (state, action) => ({
    ...state,
    publishedShoppablesCount: action.payload.publishedShoppablesCount,
  }));
};

export const mediaCollectionsSlice = createSlice({
  extraReducers: (builder) => {
    buildMediaCollectionsReducers(builder);
    buildMediaCollectionsHomeReducers(builder);
  },
  initialState: INITIAL_MEDIA_COLLECTIONS_STATE,
  name: 'mediaCollections',
  reducers: {},
});
