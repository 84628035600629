import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function PlanIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16.323 12.64" {...props}>
      <g transform="translate(-1.604 -4.705)">
        <path d="M3.575,15.077H15.957l-.306,1.049a1.709,1.709,0,0,1-1.633,1.219h-8.5a1.709,1.709,0,0,1-1.633-1.219ZM17.522,7.361a1.123,1.123,0,0,0-1.236-.147L13.338,8.688,10.673,5.139a1.165,1.165,0,0,0-1.814,0L6.194,8.688,3.246,7.214A1.134,1.134,0,0,0,1.653,8.552L3.24,13.944H16.292l1.587-5.392a1.117,1.117,0,0,0-.357-1.191Z" />
      </g>
    </SvgIcon>
  );
}
