import { ProductId } from '@bellepoque/api-contracts';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CatalogSortType } from '../../../../../core/store/state/catalog';
import ProductListHeader from '../../../molecules/administration/catalog/ProductListHeader';
import ProductRow from '../../../molecules/administration/catalog/ProductRow';

type ProductListProps = {
  currency: CBOCurrency;
  onAddToEvent: (products: CBOCatalogProduct[]) => void;
  onChangeSort: (sort: CatalogSortType) => void;
  onClearSelection: () => void;
  onDelete: (productsIds: ProductId[]) => void;
  onRefresh: () => void;
  onSelect: (product: CBOCatalogProduct) => void;
  onUnselect: (product: CBOCatalogProduct) => void;
  products: CBOCatalogProduct[];
  selectedProducts: CBOCatalogProduct[];
  sort: CatalogSortType;
};

const TableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  marginTop: theme.spacing(2),
  width: '100%',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
}));

export default function ProductList({
  currency,
  onAddToEvent,
  onChangeSort,
  onClearSelection,
  onDelete,
  onRefresh,
  onSelect,
  onUnselect,
  products,
  selectedProducts,
  sort,
}: ProductListProps) {
  const { t } = useTranslation(['products', 'common']);

  const isProductSelected = (product: CBOCatalogProduct): boolean => {
    return selectedProducts.findIndex(({ id }) => product.id === id) !== -1;
  };

  const renderProductsList = () => {
    if (products.length === 0) {
      return (
        <Box style={{ marginTop: 20 }}>
          <Typography>{t('administration:Catalog.NoProducts')}</Typography>
        </Box>
      );
    }

    return (
      <Grid container justifyContent="center">
        <Grid gap={2} item lg={10} xs={12}>
          <Grid container flex={1}>
            <ProductListHeader
              onAddToEvent={onAddToEvent}
              onChangeSort={onChangeSort}
              onClearSelection={onClearSelection}
              onDelete={onDelete}
              onRefresh={onRefresh}
              onSelectProduct={onSelect}
              onUnselectProduct={onUnselect}
              products={products}
              selectedProducts={selectedProducts}
              sort={sort}
            />
            <TableContainer boxShadow={2} id="product-list-table-container">
              <Table
                id="product-list-table"
                sx={{
                  mx: 3,
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" key="checkbox"></StyledTableCell>
                    <StyledTableCell align="left" key="image">
                      {t('Preview')}
                    </StyledTableCell>
                    <StyledTableCell align="left" key="title">
                      {t('title_capitalized')}
                    </StyledTableCell>
                    <StyledTableCell align="right" key="price">
                      {t('common:Price')}
                    </StyledTableCell>
                    <StyledTableCell align="right" key="actions"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product, index) => (
                    <ProductRow
                      currency={currency}
                      index={index}
                      isSelected={isProductSelected(product)}
                      key={product.id}
                      onAddToEvent={() => onAddToEvent([product])}
                      onSelect={() => onSelect(product)}
                      onUnselect={() => onUnselect(product)}
                      product={product}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <Box id="product-list-root">{renderProductsList()}</Box>;
}
