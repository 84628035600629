import { TrafficReports } from '../../domain/Reports';
import { FetchTrafficReportsFilters } from '../../gateways/reports-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const fetchTrafficReports = createAppAsyncThunk(
  'reports/fetch-traffic-reports',
  async (request: FetchTrafficReportsFilters, { extra: { reportsGateway } }): Promise<TrafficReports> => {
    return reportsGateway.fetchTrafficReports(request);
  },
);
