import { SxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import { TMobileAppsPlatforms } from '../../../../core/domain/MobileAppPlatforms';
import StoreImage from '../../atoms/StoreImage';
import StoreQRCode from '../../atoms/StoreQRCode';

interface StoreLinkProps {
  sx?: SxProps;
  variant: TMobileAppsPlatforms;
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: '100%',
}));

export default function StoreLink({ sx = {}, variant }: StoreLinkProps) {
  return (
    <Container sx={sx}>
      <StoreQRCode variant={variant} />
      <StoreImage variant={variant} />
    </Container>
  );
}
