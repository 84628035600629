import { Box, Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { EventDisplayedTime, EventDuration } from '../../../atoms/event/DisplayedValue';
import EditableImage from '../../../atoms/inputs/EditableImage';
import ReplayInfos from './ReplayInfos';

interface ReplayLinksHeaderProps {
  event: CBOEventReadModel;
  onShowUpdateImageModal: () => void;
  onShowUpdateInfoModal: () => void;
}

const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(2)} 0`,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'center',
}));

const TimeContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(4),
  justifyContent: 'center',
}));

export default function ReplayLinksHeader({
  event,
  onShowUpdateImageModal,
  onShowUpdateInfoModal,
}: ReplayLinksHeaderProps) {
  const { t } = useTranslation('events');

  return (
    <Container container>
      <Grid item md={10} width="100%">
        <ContentContainer>
          <EditableImage
            ImageProps={{ alt: t('ChangeCover'), src: event.theme.preEventCoverUrl }}
            onEdit={onShowUpdateImageModal}
          />
          <ReplayInfos eventDescription={event.description} eventTitle={event.title} onEdit={onShowUpdateInfoModal} />
          <TimeContainer>
            <Box display="flex" gap={4} justifyContent="space-evenly">
              <EventDisplayedTime event={event} />
              <EventDuration event={event} />
            </Box>
          </TimeContainer>
        </ContentContainer>
      </Grid>
    </Container>
  );
}
