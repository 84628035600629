import { OnboardingProgress } from '@bellepoque/api-contracts';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OnboardingGateway } from '../../core/gateways/onboarding-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiOnboardingGateway extends ApiGateway implements OnboardingGateway {
  async getProgress(): Promise<OnboardingProgress | null> {
    return firstValueFrom(
      this.authenticatedJsonQuery<OnboardingProgress>({
        url: `${this.apiEndpoint}/onboarding-progress`,
      }).pipe(
        catchError((e) => {
          if (e.status === 404) return of(null);
          throw e;
        }),
      ),
    );
  }

  async updateProgress(progress: OnboardingProgress): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body: progress,
        url: `${this.apiEndpoint}/onboarding-progress`,
        verb: 'put',
      }),
    );
  }
}
