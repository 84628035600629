import { Cms } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchEvents } from '../../../core/usecases/event/crud/fetch-events';
import { fetchLandingPage } from '../../../core/usecases/landing-page/fetch-landing-page';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { isLiveEvent } from '../../../utils/is-live-event';

export const createLiveEventsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { events, eventsFetching } = state.events;
    const { cms: tenantCms, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const liveEventsFetchingStatus = eventsFetching.status;

    return {
      canShareEvents: tenantCms === Cms.Shopify,
      fetchEvents: () => {
        dispatch(fetchEvents({ eventsFetchingStatus: liveEventsFetchingStatus, tenantId }));
      },
      fetchLandingPage: () => {
        dispatch(fetchLandingPage({ tenantId }));
      },
      landingPageData: state.landingPage.landingPageData,
      landingPageTenantId: state.landingPage.landingPageTenantId,
      liveEvents: events.filter(({ status }) => isLiveEvent(status)),
      liveEventsFetchingStatus,
      tenantId,
      trackUserJourneyLiveEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
            name: 'Live events list',
          }),
        );
      },
    };
  };
