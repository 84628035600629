import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from '../../../endpoints';
import { getShopNameFromTenantName } from '../../../utils/get-shop-name-from-tenant-name';
import { getLocalStorageNotificationSeen, setLocalStorageNotificationSeen } from '../../../utils/local-storage';
import { makeAutoplayUrl } from '../../../utils/replay-links';
import CreateEventFormDialog from '../../components/organisms/event/create-event-form-dialog/CreateEventFormDialog';
import ConfigureLandingPageMenuModal from '../../components/organisms/shoppables/ConfigureLandingPageMenuModal';
import PlayerPlaylistsBlockModal from '../../components/organisms/shoppables/PlayerPlaylistsBlockModal';
import ShoppableFormDialog from '../../components/organisms/shoppables/shoppable-form-dialog/ShoppableFormDialog';
import { routes } from '../../routes';
import HomeTemplate from '../../templates/HomeTemplate';
import { SHOULD_SHOW_ADD_VIDEO_MODAL_QUERY_PARAM } from '../../templates/ShoppablesTemplate';
import { createHomeViewModel } from './Home.viewmodel';

export type HomeNotificationId = 'landing-page-created' | 'landing-page-not-found' | 'no-player-playlists-block';

export default function Home() {
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const [isCreateShoppableModalOpen, setIsCreateShoppableModalOpen] = useState(false);
  const [isPlayerPlaylistsBlockHelpModalOpen, setIsPlayerPlaylistsBlockHelpModalOpen] = useState(false);
  const [isConfigureLiveShoppingPageMenuModalOpen, setIsConfigureLiveShoppingPageMenuModalOpen] = useState(false);
  const [shownNotificationsIds, setShownNotificationsIds] = useState<HomeNotificationId[]>([]);

  const navigate = useNavigate();

  const viewModel = useSelector(createHomeViewModel({ dispatch: useDispatch() }));

  const {
    canShareEvents,
    checkHasCmsLandingPage,
    checkHasPlayerPlaylistsBlock,
    cmsLandingPageCreationStatus,
    createCmsLandingPage,
    currency,
    currentPlanId,
    fetchHomePage,
    fetchHomePageStatus,
    fetchLandingPage,
    hasCmsLandingPageFetchingStatus,
    hasShopify,
    isLandingPageCreatedNotificationShown,
    isProductPagesWithMediaCollectionsCountShown,
    isNoLandingPageFoundNotificationShown,
    isNoPlayerPlaylistsBlockNotificationShown,
    isPublishedShoppablesCountShown,
    landingPageUrl,
    maxPublishedShoppablesCount,
    playerPlaylistsBlockIsAddedFetchingStatus,
    productPagesWithMediaCollectionsCount,
    productsCount,
    publishedShoppablesCount,
    purchasedAmounts,
    purchasedCarts,
    resetIsLandingPageCreatedNotification,
    shoppableCreationStatus,
    tenantId,
    tenantName,
    trackUserJourneyLiveEvent,
    upcomingEvent,
    usesSales,
    validatedCartsAmounts,
    validatedCarts,
    views,
  } = viewModel;

  useEffect(() => {
    if (shoppableCreationStatus === 'success') {
      showShoppablesListWithAddVideoModal();
    }
  }, [shoppableCreationStatus]);

  useEffect(() => {
    trackUserJourneyLiveEvent();
  }, []);

  useEffect(() => {
    setShownNotificationsIds([]);
    checkHasCmsLandingPage();
    checkHasPlayerPlaylistsBlock();
    fetchHomePage();

    if (canShareEvents) {
      fetchLandingPage();
    }
  }, [tenantId]);

  useEffect(() => {
    setShownNotificationsIds(getShownNotificationsIds());
  }, [hasCmsLandingPageFetchingStatus, playerPlaylistsBlockIsAddedFetchingStatus]);

  useEffect(() => {
    if (cmsLandingPageCreationStatus === 'success') {
      resetIsLandingPageCreatedNotification();
      setShownNotificationsIds(getShownNotificationsIds());
    }
  }, [cmsLandingPageCreationStatus]);

  const getShownNotificationsIds = (): HomeNotificationId[] => {
    const shownNotificationsIds: HomeNotificationId[] = [];
    if (
      hasCmsLandingPageFetchingStatus === 'loaded' &&
      isLandingPageCreatedNotificationShown &&
      !getLocalStorageNotificationSeen(tenantId, 'landing-page-created')
    ) {
      shownNotificationsIds.push('landing-page-created');
    }

    if (
      hasCmsLandingPageFetchingStatus === 'loaded' &&
      isNoLandingPageFoundNotificationShown &&
      !getLocalStorageNotificationSeen(tenantId, 'landing-page-not-found')
    ) {
      shownNotificationsIds.push('landing-page-not-found');
    }

    if (playerPlaylistsBlockIsAddedFetchingStatus === 'loaded' && isNoPlayerPlaylistsBlockNotificationShown) {
      shownNotificationsIds.push('no-player-playlists-block');
    }

    return shownNotificationsIds;
  };

  const handleCloseNotification = (notificationId: HomeNotificationId) => {
    setLocalStorageNotificationSeen(tenantId, notificationId);
    setShownNotificationsIds(shownNotificationsIds.filter((val) => val !== notificationId));
  };

  const handleCopyEventLink = (eventId: string) => {
    if (!landingPageUrl) {
      return;
    }
    navigator.clipboard.writeText(makeAutoplayUrl({ baseUrl: landingPageUrl, eventId }));
  };

  const handleCreateCmsLandingPage = () => {
    createCmsLandingPage();
  };

  const handleGoToEventSettings = (eventId: string) => {
    navigate(routes.events.settings({ eventId }));
  };

  const handleGoToShopifyMenu = () => {
    window.open(ENDPOINTS.shopifyMenu(getShopNameFromTenantName(tenantName)), '_blank', 'noreferrer');
  };

  const handleShowEventTutorial = () => {
    navigate(`${routes.helpAndSupport({ slug: encodeURIComponent(ENDPOINTS.support.createEvent) })}`);
  };

  const handleShowShoppableVideoTutorial = () => {
    navigate(`${routes.helpAndSupport({ slug: encodeURIComponent(ENDPOINTS.support.createShoppableVideo) })}`);
  };

  const handleSeeAllPlans = () => {
    const url = hasShopify ? ENDPOINTS.shopifyPricing(getShopNameFromTenantName(tenantName)) : ENDPOINTS.pricing;
    window.open(url, '_blank', 'noreferrer');
  };

  const handleCreateMediaCollection = () => {
    navigate(routes.playlists.new({}));
  };

  const showShoppablesListWithAddVideoModal = () => {
    navigate(`${routes.shoppables}?${SHOULD_SHOW_ADD_VIDEO_MODAL_QUERY_PARAM}=1`);
  };

  return (
    <>
      <CreateEventFormDialog onClose={() => setIsCreateEventModalOpen(false)} open={isCreateEventModalOpen} />

      <ShoppableFormDialog
        formType="create"
        onClose={() => setIsCreateShoppableModalOpen(false)}
        open={isCreateShoppableModalOpen}
      />

      <PlayerPlaylistsBlockModal
        isOpen={isPlayerPlaylistsBlockHelpModalOpen}
        onClose={() => setIsPlayerPlaylistsBlockHelpModalOpen(false)}
        tenantName={tenantName}
      />

      <ConfigureLandingPageMenuModal
        isOpen={isConfigureLiveShoppingPageMenuModalOpen}
        onClose={() => setIsConfigureLiveShoppingPageMenuModalOpen(false)}
        onGoToShopifyMenu={handleGoToShopifyMenu}
      />

      <HomeTemplate
        cmsLandingPageCreationStatus={cmsLandingPageCreationStatus}
        currency={currency}
        currentPlanId={currentPlanId}
        hasShopify={hasShopify}
        isProductPagesWithMediaCollectionsCountShown={isProductPagesWithMediaCollectionsCountShown}
        isPublishedShoppablesCountShown={isPublishedShoppablesCountShown}
        loading={fetchHomePageStatus === 'pending'}
        maxPublishedShoppablesCount={maxPublishedShoppablesCount}
        onCloseNotification={handleCloseNotification}
        onCopyEventLink={handleCopyEventLink}
        onCreateCmsLandingPage={handleCreateCmsLandingPage}
        onCreateEvent={() => setIsCreateEventModalOpen(true)}
        onCreateMediaCollection={handleCreateMediaCollection}
        onCreateShoppableVideo={() => setIsCreateShoppableModalOpen(true)}
        onGoToEventSettings={handleGoToEventSettings}
        onGoToShopifyMenu={handleGoToShopifyMenu}
        onSeeAllPlans={handleSeeAllPlans}
        onShowConfigureLiveShoppingPageMenuModal={() => setIsConfigureLiveShoppingPageMenuModalOpen(true)}
        onShowEventTutorial={handleShowEventTutorial}
        onShowPlayerPlaylistsBlockHelpModal={() => setIsPlayerPlaylistsBlockHelpModalOpen(true)}
        onShowShoppableVideoTutorial={handleShowShoppableVideoTutorial}
        productPagesWithMediaCollectionsCount={productPagesWithMediaCollectionsCount}
        productsCount={productsCount}
        publishedShoppablesCount={publishedShoppablesCount}
        purchasedAmounts={purchasedAmounts}
        purchasedCarts={purchasedCarts}
        shownNotificationsIds={shownNotificationsIds}
        upcomingEvent={upcomingEvent}
        usesSales={usesSales}
        validatedCarts={validatedCarts}
        validatedCartsAmounts={validatedCartsAmounts}
        views={views}
      />
    </>
  );
}
