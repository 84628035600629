import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface SettingsSectionTitleProps {
  sx?: TypographyProps;
  title: string;
}

export default function SettingsSectionTitle({ title, sx }: SettingsSectionTitleProps) {
  return (
    <Typography color="primary.dark" style={{ fontWeight: 'bolder' }} sx={sx} variant="h6">
      {title}
    </Typography>
  );
}
