import { TenantId, V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { TenantStatisticsGateway } from '../../core/gateways/tenant-statistics-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiTenantStatisticsGateway extends ApiGateway implements TenantStatisticsGateway {
  fetchTenantStatistics(tenantId: TenantId): Promise<V1.api.TenantStatisticsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.TenantStatisticsDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/statistics`,
      }),
    );
  }
}
