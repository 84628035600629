import { BillingPlanId, EventId } from '@bellepoque/api-contracts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Box, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import CreateButton from '../../atoms/CreateButton';
import SettingsSection from '../SettingsSection';
import EventRow from './EventRow';
import SkeletonEventRow from './SkeletonEventRow';

interface HighlightSectionProps {
  currentPlanId: BillingPlanId;
  events: CBOEventListReadModel[];
  highlightedEventsIds: EventId[];
  onChangeHighlightedEvents: (highlightedEventsIds: EventId[]) => void;
  onOpenEventCreationModal: () => void;
  submissionInProgress: boolean;
  tenantName: string;
}

export default function HighlightSection({
  currentPlanId,
  events,
  highlightedEventsIds,
  onChangeHighlightedEvents,
  onOpenEventCreationModal,
  submissionInProgress,
  tenantName,
}: HighlightSectionProps) {
  const { t: eventsT } = useTranslation('events');
  const { t } = useTranslation('liveShoppingPage');
  const theme = useTheme();

  const handleHighlightingEvent = (eventId: EventId) => {
    const newHighlightedEvents = [...highlightedEventsIds, eventId];
    onChangeHighlightedEvents(newHighlightedEvents);
  };

  const handleUnhighlightingEvent = (eventId: string) => {
    onChangeHighlightedEvents(highlightedEventsIds.filter((id) => id !== eventId));
  };

  const renderEventRow = useCallback(
    (event: CBOEventListReadModel) => {
      const isHighlighted = highlightedEventsIds.includes(event.id);
      return (
        <EventRow
          currentPlanId={currentPlanId}
          event={event}
          key={event.id}
          reduceOpacity={highlightedEventsIds.length !== 0 && !isHighlighted}
          section="highlight"
          switchProps={{
            checked: isHighlighted,
            disabled: submissionInProgress,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.checked ? handleHighlightingEvent(event.id) : handleUnhighlightingEvent(event.id);
            },
          }}
          tenantName={tenantName}
        />
      );
    },
    [events, highlightedEventsIds],
  );

  return (
    <SettingsSection
      contentWidth={12}
      icon={<DashboardIcon sx={{ color: theme.palette.common.white }} />}
      roundTop
      title={t('Highlights.Title')}
      tooltip={t('Highlights.SectionTooltip')}
    >
      <Box flex={1} flexDirection="column">
        {events.length === 0 ? (
          <>
            <SkeletonEventRow position={null} section="highlight" />
            <Box mt={4}>
              <CreateButton onClick={onOpenEventCreationModal} text={eventsT('CreateAnEvent')} />
            </Box>
          </>
        ) : (
          events.map(renderEventRow)
        )}
      </Box>
    </SettingsSection>
  );
}
