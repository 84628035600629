import { AppBar, Box, LinearProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../atoms/CreateButton';

const Container = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'absolute',
  right: 'auto',
  width: '100%',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
  marginRight: theme.spacing(7),
  paddingBottom: theme.spacing(1),
}));

interface EventListHeaderProps {
  loading: boolean;
  onCreateEvent: () => void;
}

export default function EventListHeader({ loading, onCreateEvent }: EventListHeaderProps) {
  const { t } = useTranslation('events');

  return (
    <Container elevation={0}>
      <ContentContainer>
        <Title variant="h4">{t('Events')}</Title>
        <Box alignItems="center" alignSelf="center" display="flex" flex={1} justifyContent="flex-end">
          <CreateButton onClick={onCreateEvent} text={t('events:CreateAnEvent')} />
        </Box>
      </ContentContainer>
      {loading && <LinearProgress />}
    </Container>
  );
}
