import { V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetFetchThumbnailFileUploadUrlAction } from '../../store/slices/shoppables/shoppables-file-upload.slice';

type GetShoppableThumbnailFileUploadUrlParams = {
  shoppableId: string;
  tenantId: string;
};

export const getShoppableThumbnailFileUploadUrl = createAppAsyncThunk(
  'shoppables/get-thumbnail-file-upload-url',
  async (
    params: GetShoppableThumbnailFileUploadUrlParams,
    { extra: { shoppablesGateway } },
  ): Promise<V1.api.ShoppableVideoThumbnailFileUploadUrlDTO> => {
    return shoppablesGateway.getShoppableThumbnailFileUploadUrl(params);
  },
);

export const resetFetchThumbnailFileUploadUrl = () => {
  return resetFetchThumbnailFileUploadUrlAction();
};
