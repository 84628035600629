import { ErrorsByCode } from '@bellepoque/api-contracts';
import { TFunction } from 'i18next';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

import { CommandQueryResult } from '../core/store/state/utils';

export type Enqueuer = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

export interface SnackbarMessageMap {
  error: string;
  success: string;
}

type WithCode = {
  code: string;
};
const hasCode = (obj: unknown): obj is WithCode =>
  (obj as WithCode).code !== undefined && typeof (obj as WithCode).code === 'string';

const getCustomError = (error: unknown): string | null => {
  if (hasCode(error)) {
    return ErrorsByCode[error.code] ?? null;
  }
  return null;
};

export const getCustomErrors = (errors?: unknown[] | unknown): string[] => {
  if (!errors) {
    return [];
  }

  if (Array.isArray(errors)) {
    return errors.reduce((acc, error) => {
      const customError = getCustomError(error);
      if (customError) acc.push(customError);
      return acc;
    }, []);
  }

  const customError = getCustomError(errors);
  return customError ? [customError] : [];
};

const getDisplaySnackbar =
  (enqueueSnackbar: Enqueuer, t: TFunction) =>
  (map: SnackbarMessageMap, data: CommandQueryResult): void => {
    const customErrors = getCustomErrors(data.errors);
    if (customErrors.length > 0) {
      enqueueSnackbar(t(`notifications.${customErrors[0]}`), { variant: 'error' });
    } else if (data.status === 'success' || data.status === 'error') {
      const translationKey = map[data.status];
      if (translationKey) {
        enqueueSnackbar(t(translationKey), { variant: data.status });
      }
    }
  };
export default getDisplaySnackbar;
