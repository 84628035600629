import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type AddCatalogProductsToEventRequest = {
  eventId: string;
  productsIds: string[];
};

export const addCatalogProductsToEvent = createAppAsyncThunk(
  'catalog/add-products-to-event',
  async ({ eventId, productsIds }: AddCatalogProductsToEventRequest, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.addCatalogProductsToEvent(eventId, { productsIds });
  },
);
