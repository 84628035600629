import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';

import { Container } from '../di/container';
import { State } from './state';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  dispatch: Dispatch;
  extra: Container;
  state: State;
}>();
