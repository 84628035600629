import { Box, useTheme } from '@mui/material';
import QRCode from 'qrcode.react';
import React, { useMemo } from 'react';

import { MobileAppsPlatforms, TMobileAppsPlatforms } from '../../../core/domain/MobileAppPlatforms';
import { ENDPOINTS } from '../../../endpoints';

interface StoreQRCodeProps {
  variant: TMobileAppsPlatforms;
}

export default function StoreQRCode({ variant }: StoreQRCodeProps) {
  const theme = useTheme();
  const publisherStoreUrls = ENDPOINTS.publisherStoreUrls;
  const size = 175;

  const storeUrl = useMemo(() => {
    switch (variant) {
      case MobileAppsPlatforms.android:
        return publisherStoreUrls.playStore;
      case MobileAppsPlatforms.ios:
        return publisherStoreUrls.appStore;
    }
  }, [variant]);

  return (
    <Box maxWidth={size} minWidth={size}>
      <QRCode fgColor={theme.palette.primary.dark} level="L" renderAs="canvas" size={size} value={storeUrl} />
    </Box>
  );
}
