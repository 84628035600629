export type CommandStatus = 'error' | 'notRequested' | 'pending' | 'success';
export type CommandResult = {
  errors: any[];
  status: CommandStatus;
};
export type QueryStatus = 'error' | 'loaded' | 'notLoaded' | 'pending';
export type QueryResult = {
  errors: any[];
  status: QueryStatus;
};
export type CommandQueryResult = CommandResult | QueryResult;

export const INITIAL_COMMAND_RESULT: CommandResult = {
  errors: [],
  status: 'notRequested',
};

export const INITIAL_QUERY_RESULT: QueryResult = {
  errors: [],
  status: 'notLoaded',
};
