import { SettingsInputAntenna } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RTMPRestreamingSection, { RestreamingProps, RestreamingSectionProps } from './RTMPRestreamingSection';

const OtherRTMPSourceRestreamingSection: FC<RestreamingSectionProps> = ({ keyError, urlError, ...props }) => {
  const { t } = useTranslation(['events', 'common']);

  const otherRTMPSourceRestreamingProps: RestreamingProps = useMemo(
    () => ({
      id: 'other-rtmp-source',
      introductionText: t('ShortRTMPKeyValidityWarning'),
      keyError,
      logo: (
        <Avatar color="primary">
          <SettingsInputAntenna />
        </Avatar>
      ),
      title: t('common:Socials.OtherRTMPSource'),
      urlError,
    }),
    [keyError, t, urlError],
  );

  return <RTMPRestreamingSection {...props} restreamingProps={otherRTMPSourceRestreamingProps} />;
};

export default OtherRTMPSourceRestreamingSection;
