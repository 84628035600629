import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchEvents } from '../../../core/usecases/event/crud/fetch-events';
import { createCmsLandingPage } from '../../../core/usecases/landing-page/create-cms-landing-page';
import { fetchLandingPage } from '../../../core/usecases/landing-page/fetch-landing-page';
import { hasCmsLandingPage as checkHasCmsLandingPage } from '../../../core/usecases/landing-page/has-cms-landing-page';
import { UpdateLandingPageParams, updateLandingPage } from '../../../core/usecases/landing-page/update-landing-page';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createLiveShoppablePageViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentTenant = state.tenants.currentTenant;
    const { activeSubscriptionId, id: tenantId, cms: tenantCms, name: tenantName } = currentTenant;
    const { events, eventsFetching } = state.events;
    const {
      hasCmsLandingPageFetching,
      cmsLandingPageCreate,
      landingPageDataFetching,
      landingPageDataUpdate,
      landingPageData,
      hasCmsLandingPage,
    } = state.landingPage;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    const maxPublishedReplays = BILLING_PLANS[currentPlanId].maxPublishedReplays;
    const maxPublishedReplayDurationInSeconds = BILLING_PLANS[currentPlanId].maxPublishedReplayDurationInSeconds;

    return {
      checkHasCmsLandingPage: () => {
        dispatch(checkHasCmsLandingPage({ tenantId }));
      },
      cmsLandingPageCreateStatus: cmsLandingPageCreate.status,
      createCmsLandingPage: () => {
        dispatch(createCmsLandingPage({ tenantId }));
      },
      currentPlanId,
      events,
      eventsFetchingStatus: eventsFetching.status,
      fetchEvents: () => {
        dispatch(fetchEvents({ eventsFetchingStatus: eventsFetching.status, tenantId }));
      },
      fetchLandingPage: () => {
        dispatch(fetchLandingPage({ tenantId }));
      },
      hasCmsLandingPage,
      hasCmsLandingPageFetchingStatus: hasCmsLandingPageFetching.status,
      landingPageData,
      landingPageDataFetchingStatus: landingPageDataFetching.status,
      landingPageDataUpdateStatus: landingPageDataUpdate.status,
      maxPublishedReplayDurationInSeconds,
      maxPublishedReplays,
      tenantCms,
      tenantId,
      tenantName,
      trackUserJourneyEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
            name: 'Landing page',
          }),
        );
      },
      updateLandingPage: (data: UpdateLandingPageParams['payload']) => {
        dispatch(updateLandingPage({ payload: data, tenantId }));
      },
    };
  };
