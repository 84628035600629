import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductChapterRow from '../../../molecules/event/replay-links/ProductChapterRow';
import { CBOEventReadModelProductWithTimeCode } from './ReplayLinks';

const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  justifyContent: 'center',
  padding: `${theme.spacing(4)} 0`,
}));

const SectionTitle = styled(Typography)({
  color: 'black',
  fontSize: '1.4em',
  fontWeight: 'bold',
  margin: 0,
  textAlign: 'left',
});

interface ReplayLinksProductsChaptersProps {
  areLinksShown: boolean;
  arePlayButtonsShown: boolean;
  chapters: CBOEventReadModelProductWithTimeCode[];
  eventId: string;
  landingPageUrl: string | null;
  onCopyUrl: (url: string) => void;
}

export default function ReplayLinksProductsChapters({
  areLinksShown,
  arePlayButtonsShown,
  eventId,
  landingPageUrl,
  onCopyUrl,
  chapters,
}: ReplayLinksProductsChaptersProps) {
  const { t } = useTranslation('events');

  return (
    <Container container>
      <Grid item md={10} xs={12}>
        <SectionTitle variant="h2">{t('ProductChapters')}</SectionTitle>
        <Box mt={2}>
          {chapters.map((chapter) => (
            <ProductChapterRow
              areLinksShown={areLinksShown}
              arePlayButtonsShown={arePlayButtonsShown}
              eventId={eventId}
              key={chapter.product.id}
              landingPageUrl={landingPageUrl}
              onCopyUrl={onCopyUrl}
              product={chapter.product}
              timeCode={chapter.timeCode}
            />
          ))}
        </Box>
      </Grid>
    </Container>
  );
}
