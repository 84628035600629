import { Typography } from '@mui/material';
import React from 'react';

import { CBOEventReadModel } from '../../../../core/domain/CBOEventReadModel';

interface HeaderTitleProps {
  event: CBOEventReadModel;
}

export default function HeaderTitle({ event }: HeaderTitleProps) {
  return (
    <Typography
      sx={{
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        alignSelf: 'center',
        display: '-webkit-box',
        fontSize: '1.4em',
        fontWeight: 'bold',
        height: '100%',
        lineHeight: '1.235',
        maxHeight: 'calc(1.4em  * 2)',
        maxWidth: '100%',
        overflow: 'hidden',
        textAlign: 'initial',
        textOverflow: 'ellipsis',
      }}
      variant="h4"
    >
      {event.title}
    </Typography>
  );
}
