import i18n from 'i18next';

import en from './translations/en';

const resources = {
  en,
};

i18n
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources,
  });

export default i18n;
