import { Box, Grid, LinearProgress, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import {
  CBOEventEcommerceStatisticsGuards,
  CBOProductsStatisticsWithSales,
} from '../../../../../core/domain/CBOTenantStatistics';
import { State } from '../../../../../core/store';
import { fetchEventStatistics } from '../../../../../core/usecases/event/statistics/fetch-event-statistics';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import { useRequiredParams } from '../../../../../utils/useRequiredParams';
import EventStatisticsHeader from '../../../molecules/EventStatisticsHeader';
import EventECommerceSection from '../../analytics/EventECommerceSection';
import EventEngagementSection from '../../analytics/EventEngagementSection';
import EventTrafficSection from '../../analytics/EventTrafficSection';

const Root = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  flexGrow: 1,
  position: 'relative',
  textAlign: 'initial',
}));

type AnalyticsProps = {
  currency: CBOCurrency;
  event: CBOEventReadModel;
};

// TODO: Add view model
export default function EventAnalytics({ currency, event }: AnalyticsProps) {
  const { eventId } = useRequiredParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const eventFetchingStatus = useSelector((state: State) => state.events.eventFetching.status);
  const eventStatisticsFetchingStatus = useSelector((state: State) => state.events.eventStatisticsFetching.status);
  const statistics = useSelector((state: State) => state.events.currentEventStatistics);
  const { eCommerce, engagement, productsStatistics, traffic } = statistics;

  useEffect(() => {
    dispatch(fetchEventStatistics({ eventId }));
  }, [dispatch, eventId, currentTenant.id]);

  const loading = eventStatisticsFetchingStatus === 'pending' || eventFetchingStatus === 'pending' || !statistics;

  const hasSales = CBOEventEcommerceStatisticsGuards.isCBOEventECommerceStatisticsWithSales(eCommerce);

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Event id': eventId,
          'Event name': event?.title ?? '',
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'Event analytics',
      }),
    );
  }, []);

  return (
    <Root id="event-statistics">
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container justifyContent="center" pb={4}>
          <Grid gap={2} item md={10} mt={2} xs={12}>
            <Box display="flex" flex={1} flexDirection="column" gap={2}>
              <EventStatisticsHeader event={event} />
              <EventTrafficSection statistics={traffic} />
              <EventEngagementSection statistics={engagement} />
              {hasSales ? (
                <EventECommerceSection
                  currency={currency}
                  event={event}
                  hasSales
                  products={event.products}
                  productsStatistics={productsStatistics as CBOProductsStatisticsWithSales}
                  statistics={eCommerce}
                />
              ) : (
                <EventECommerceSection
                  currency={currency}
                  event={event}
                  hasSales={false}
                  products={event.products}
                  productsStatistics={productsStatistics}
                  statistics={eCommerce}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Root>
  );
}
