import { V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import {
  FetchBillingReportsFilters,
  FetchTrafficReportsFilters,
  ReportsGateway,
} from '../../core/gateways/reports-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiReportsGateway extends ApiGateway implements ReportsGateway {
  fetchBillingReports({ tenantId, year }: FetchBillingReportsFilters): Promise<V1.api.BillingReportsDTO> {
    const params: Record<string, string> = { year: year.toString() };
    const url = `${this.apiEndpoint}/tenants/${tenantId}/billing-reports`;
    return firstValueFrom(this.authenticatedJsonQuery<V1.api.BillingReportsDTO>({ params, url }));
  }

  fetchTrafficReports({ tenantId, year }: FetchTrafficReportsFilters): Promise<V1.api.TrafficReportsDTO> {
    const params: Record<string, string> = { year: year.toString() };
    const url = `${this.apiEndpoint}/tenants/${tenantId}/traffic-reports`;
    return firstValueFrom(this.authenticatedJsonQuery<V1.api.TrafficReportsDTO>({ params, url }));
  }
}
