import { Box, Button, Typography, alpha, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOYoutubeChannel } from '../../../../../../core/domain/CBOYoutubeChannel';
import YoutubeChannelCard from '../../../../atoms/event/YoutubeChannelCard';

interface SelectedYoutubeChannelProps {
  areButtonsDisabled: boolean;
  channel: CBOYoutubeChannel;
  expiresAt: Date;
  onRemove: () => void;
}

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const RemoveChannelButton = styled(Button)(({ theme }) => ({
  borderColor: alpha(theme.palette.socials.youtube, 0.8),
  color: theme.palette.socials.youtube,
  marginTop: theme.spacing(1),
  textTransform: 'initial',
}));

const TokenNotExpiredText = styled(Typography)(({ theme }) => ({
  fontSize: '.8em',
  marginTop: theme.spacing(1),
}));

const TokenExpiredText = styled(TokenNotExpiredText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function SelectedYoutubeChannel({
  channel,
  areButtonsDisabled,
  expiresAt,
  onRemove,
}: SelectedYoutubeChannelProps) {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('events', { keyPrefix: 'YoutubeRestreaming' });
  const now = new Date();
  return (
    <Container>
      <Typography fontSize=".9em" mb={2}>
        {t('RestreamingConfiguredForThisChannel')}
      </Typography>
      <YoutubeChannelCard channel={channel} selected />
      {now < expiresAt ? (
        <TokenNotExpiredText>{t('TokenExpiresOn', { date: expiresAt.toLocaleDateString() })}</TokenNotExpiredText>
      ) : (
        <TokenExpiredText>{t('ExpiredTokenPleaseReconnect')}</TokenExpiredText>
      )}
      <RemoveChannelButton disabled={areButtonsDisabled} onClick={onRemove} variant="outlined">
        {tCommon('Remove')}
      </RemoveChannelButton>
    </Container>
  );
}
