import { PlayArrow } from '@mui/icons-material';
import { Box, Button, Link, TableRow, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { makeAutoplayUrl } from '../../../../../utils/replay-links';
import ButtonWithTextChangeTimeout from '../../../atoms/ButtonWithTextChangeTimeout';
import ReplayLink from '../../../atoms/event/ReplayLink';

interface ProductChapterRowProps {
  areLinksShown: boolean;
  arePlayButtonsShown: boolean;
  eventId: string;
  landingPageUrl: string | null;
  onCopyUrl: (url: string) => void;
  product: CBOEventReadModelProduct;
  timeCode: number;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  height: `86px`,
  minHeight: 0,
}));

const Image = styled('img')(({ theme }) => ({
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '5px',
  flex: 1,
  objectFit: 'contain',
}));

export default function ProductChapterRow({
  areLinksShown,
  arePlayButtonsShown,
  eventId,
  landingPageUrl,
  onCopyUrl,
  product,
  timeCode,
}: ProductChapterRowProps) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const productReplayUrl = makeAutoplayUrl({ baseUrl: landingPageUrl, eventId, timeCode });

  const handleCopyUrl = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCopyUrl(productReplayUrl);
  };

  return (
    <StyledTableRow>
      <Box alignItems="center" display="flex" flex={1}>
        <Box display="flex" height="70px" margin="auto" width="70px">
          <Image alt={product.title} src={product.imageUrls[0]} />
        </Box>
        <Box flex={1} ml={2}>
          <Typography fontSize="1em" fontWeight="bold">
            {product.title}
          </Typography>
        </Box>
      </Box>

      <Box alignItems="center" display="flex" flex={1.5} gap={3} justifyContent="flex-end" justifySelf="flex-end">
        {areLinksShown && (
          <Box flex={1}>
            <ReplayLink onClick={handleCopyUrl}>{productReplayUrl}</ReplayLink>
          </Box>
        )}
        <Box display="flex" gap={2} justifyContent="flex-end">
          <ButtonWithTextChangeTimeout
            ButtonProps={{
              color: 'inherit',
              onClick: handleCopyUrl,
              sx: {
                borderColor: theme.palette.grey[400],
                color: theme.palette.common.black,
                px: 4,
                textTransform: 'initial',
                width: '140px',
              },
              variant: 'outlined',
            }}
            initialText={t(areLinksShown ? 'Copy' : 'CopyLink')}
            secondText={t(areLinksShown ? 'Copied' : 'LinkCopied')}
          />
          {arePlayButtonsShown && (
            <Link href={productReplayUrl} target="_blank" underline="none">
              <Button
                color="secondary"
                startIcon={<PlayArrow />}
                sx={{
                  backgroundColor: theme.palette.secondary.dark,
                  px: 4,
                  textTransform: 'initial',
                }}
                variant="contained"
              >
                {t('Play')}
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </StyledTableRow>
  );
}
