import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { stepConnectorClasses } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider, createTheme, darken } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppStore } from '../core/store';
import i18n from '../i18n/i18n';
import { TracingErrorBoundary } from './TracingErrorBoundary';
import Auth from './auth/Auth';
import './index.css';

declare module '@mui/material/styles' {
  interface Palette {
    shopify: {
      background: string;
      main: string;
    };
    socials: {
      facebook: string;
      instagram: string;
      youtube: string;
    };
  }
  interface PaletteOptions {
    shopify: {
      background: string;
      main: string;
    };
    socials: {
      facebook: string;
      instagram: string;
      youtube: string;
    };
  }
  interface TypographyVariants {
    titleFont: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    titleFont?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    titleFont: true;
  }
}

/**
 * Grey colors reference :
 * 50   : #fafafa
 * 100  : #f5f5f5
 * 200  : #eeeeee
 * 300  : #e0e0e0
 *        #bebebe
 * 400  : #bdbdbd
 * 500  : #9e9e9e
 * 600  : #757575
 * 700  : #616161
 * 800  : #424242
 * 900  : #212121
 * A100 : #f5f5f5
 * A200 : #eeeeee
 * A400 : #bdbdbd
 * A700 : #616161
 **/

export const theme = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#625df4',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          fontSize: '16px',
          fontWeight: 'normal',
          textTransform: 'none',
        },
        text: {
          color: '#0E2B36',
        },
      },
      variants: [
        {
          props: { color: 'primary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: darken('#625df4', 0.1),
            },

            backgroundColor: '#625df4',
          },
        },
        {
          props: { color: 'secondary', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: darken('#c8739e', 0.1),
            },

            backgroundColor: '#c8739e',
          },
        },
        {
          props: { color: 'info', variant: 'contained' },
          style: {
            '&:hover': {
              backgroundColor: darken('#6e7290', 0.1),
            },
            backgroundColor: '#6e7290',

            borderColor: '#0000001f',
          },
        },

        {
          props: { color: 'primary', variant: 'outlined' },
          style: {
            '&:hover': {
              backgroundColor: darken('#dad9fe', 0.1),
            },
            backgroundColor: '#dad9fe',
            borderColor: '#625df4',

            color: '#625df4',
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            '&:hover': {
              backgroundColor: darken('#fff', 0.1),
            },
            backgroundColor: '#fff',
            borderColor: '#0000001f',

            color: '#625df4',
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: '#fff',
          fontSize: '11px',
          height: '24px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#F4F4F4',
          },
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          right: 0,
          textAlign: 'right',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0', // grey[300]
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          [`&.${stepConnectorClasses.alternativeLabel}`]: {
            margin: '0 15px',
            top: '22px',
          },
          [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              borderColor: '#c8739e',
            },
          },
          [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              borderColor: '#c8739e',
            },
          },
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#eaeaf0',
            borderRadius: '3px',
            borderTopWidth: '5px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // backgroundColor: palette.primary.main,
          left: '0px',
          width: '4px',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        color: 'primary',
      },
    },
  },
  palette: {
    background: { default: '#f7f7ff' },
    error: {
      main: '#FF3200',
    },
    info: { light: '#f2f1fe', main: '#6e7290' },
    primary: {
      contrastText: '#fff',
      dark: '#0E2B36',
      light: '#dad9fe',
      main: '#625df4',
    },
    secondary: {
      contrastText: '#fff',
      main: '#c8739e',
    },
    shopify: {
      background: '#0d2e39',
      main: '#81bf36',
    },
    socials: {
      facebook: '#4267B2',
      instagram: '#E56969',
      youtube: '#FF0000', // https://www.youtube.com/howyoutubeworks/resources/brand-resources/
    },
    success: {
      main: '#96BE4F',
    },
  },
  typography: {
    fontFamily: 'Ubuntu, Roboto, sans-serif',
    h1: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    h2: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    h3: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    h4: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    h5: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    h6: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
    titleFont: {
      fontFamily: 'Ubuntu, Roboto, sans-serif',
    },
  },
});

export function startReact(store: AppStore) {
  ReactDOM.render(
    <React.StrictMode>
      <TracingErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Auth />
                  </LocalizationProvider>
                </BrowserRouter>
              </I18nextProvider>
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </TracingErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
