import { Button, ButtonProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface AddProductButtonProps {
  ButtonProps?: ButtonProps;
  initialText: string;
  secondText: string;
  timeoutDuration?: number;
}

export default function ButtonWithTextChangeTimeout({
  ButtonProps = {},
  initialText,
  secondText,
  timeoutDuration = 1000,
}: AddProductButtonProps) {
  const [text, setText] = useState(initialText);
  const [isDisabled, setIsDisabled] = useState(false);

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    ButtonProps?.onClick?.(e);
    setText(secondText);
    setIsDisabled(true);
    setTimeout(() => {
      setText(initialText);
      setIsDisabled(false);
    }, timeoutDuration);
  };

  return (
    <Button
      {...ButtonProps}
      onClick={handleClick}
      sx={{
        ...ButtonProps.sx,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
    >
      {text}
    </Button>
  );
}
