import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AddProductButton from '../../../atoms/AddProductButton';
import ProductCount from '../../../atoms/event/ProductCount';

interface HeaderProductsActionsProps {
  isAddProductsButtonHidden: boolean;
  onAddProduct: () => void;
  productsCount: number;
  readonly: boolean;
}

export default function HeaderProductsActions(props: HeaderProductsActionsProps) {
  const { isAddProductsButtonHidden, onAddProduct, productsCount, readonly } = props;
  const { t } = useTranslation('events');

  return (
    <Box
      id="header-actions"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'flex-end',
      }}
    >
      <ProductCount count={productsCount} countPosition="right" text={t('Products')} />
      {!readonly && !isAddProductsButtonHidden && <AddProductButton onAddProduct={onAddProduct} />}
    </Box>
  );
}
