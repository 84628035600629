import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export default function CoversOverlayIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 27.663 27.663">
      <g transform="translate(3.1 -1.676)">
        <g transform="translate(-3.1 1.676)">
          <path
            d="M378.458,381.916A3.458,3.458,0,1,0,375,378.458,3.459,3.459,0,0,0,378.458,381.916Zm0-5.174a1.715,1.715,0,1,1-1.715,1.715A1.715,1.715,0,0,1,378.458,376.741Z"
            fill={theme.palette.common.white}
            transform="translate(-369.812 -369.812)"
          />
          <path
            d="M24.97-.1H2.493A2.594,2.594,0,0,0-.1,2.493V24.97a2.592,2.592,0,0,0,2.593,2.593H24.97a2.592,2.592,0,0,0,2.593-2.593V2.493A2.593,2.593,0,0,0,24.97-.1ZM3.358,1.629H24.1a1.73,1.73,0,0,1,1.729,1.729V18.349L20.8,13.313c-.014-.017-.029-.032-.044-.047a.863.863,0,0,0-1.217,0h0l-8.047,8.047L6.482,16.3c-.014-.017-.029-.032-.044-.047a.863.863,0,0,0-1.217,0h0L1.629,19.843V3.358A1.73,1.73,0,0,1,3.358,1.629ZM1.629,24.106V22.287l4.2-4.2,4.441,4.441-3.3,3.3H3.358A1.73,1.73,0,0,1,1.629,24.106Zm22.477,1.729H9.409L20.142,15.1l5.693,5.693v3.311A1.73,1.73,0,0,1,24.106,25.835Z"
            fill={theme.palette.common.white}
            transform="translate(0.1 0.1)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
