import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, IconButton, styled } from '@mui/material';
import React from 'react';

const StyledDialog = styled(Dialog)({
  borderRadius: '15px',
  padding: 0,
});

const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
});

const StyledDialogContent = styled(DialogContent)({});

const ClosePlayerButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  right: -6,
  top: -24,
}));

export type PlayerPreviewProps = {
  height?: number;
  ratio?: number;
};

const PlayerPreview = styled('iframe')<PlayerPreviewProps>(({ height = 600, ratio = 9 / 16 }) => ({
  border: 'none',
  height,
  marginRight: '-25px',
  width: height * ratio + 100,
}));

export type PlayerMediaCollectionPreviewParams = {
  mediaCollectionId: string;
  startIndex: number;
};

export type PlayerMediaCollectionPreviewDialogProps = PlayerMediaCollectionPreviewParams & {
  onClose: () => void;
  open: boolean;
};

export default function PlayerMediaCollectionPreviewDialog({
  onClose,
  open,
  mediaCollectionId,
  startIndex,
}: PlayerMediaCollectionPreviewDialogProps) {
  const urlToUse = new URL(
    `${process.env.REACT_APP_PLAYER_ENDPOINT}/media-collection/${mediaCollectionId}?startIndex=${startIndex}&isPreview=true`,
  );

  return (
    <StyledDialog PaperProps={{ elevation: 0, sx: { background: 'transparent' } }} onClose={onClose} open={open}>
      <StyledDialogActions>
        <ClosePlayerButton disableRipple onClick={onClose} size="large">
          <CloseIcon />
        </ClosePlayerButton>
      </StyledDialogActions>
      <StyledDialogContent>
        {/* TODO: trads */}
        <PlayerPreview scrolling="no" src={urlToUse.href} title="Player Media Collection Preview" />
      </StyledDialogContent>
    </StyledDialog>
  );
}
