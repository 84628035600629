import { ButtonProps } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import beLiveImg from '../../assets/tutorial/be-live.webp';
import liveShoppingPageImg from '../../assets/tutorial/liveshoppingpage.jpg';
import performanceImg from '../../assets/tutorial/performance.png';
import shoppablesImg from '../../assets/tutorial/shoppables.png';
import welcomeImg from '../../assets/tutorial/welcome.webp';
import { NavigationTabs } from '../../navigation-tabs';
import { TutorialStep } from '../molecules/ImageModal';

export interface TutorialStepContent {
  ButtonsProps: ButtonProps[];
  description: string;
  enabled: boolean;
  highlightedTab: NavigationTabs | null;
  id: string;
  imageUrl: string;
  title: string;
}

interface TutorialProps {
  canAccessLiveShoppingPage: boolean;
  currentStepIndex: number;
  onClose: () => void;
  onNextStep: (params: { highlightedTab: NavigationTabs | null }) => void;
}
export default function Tutorial(props: TutorialProps) {
  const { canAccessLiveShoppingPage, currentStepIndex, onClose, onNextStep } = props;
  const { t } = useTranslation(['tutorial', 'common']);

  const getStepOneDescription = useMemo(() => {
    return canAccessLiveShoppingPage ? t('Welcome.LiveShoppingPageContent') : t('Welcome.Content');
  }, [canAccessLiveShoppingPage, t]);

  const [isModalFaded, setFadeModal] = useState<boolean>(false);
  const [isContentFaded, setFadeContent] = useState<boolean>(false);

  const handleNextStep = () => {
    if (currentStepIndex === enabledSteps.length - 1) {
      closeTutorial();
      return;
    }

    setFadeContent(true);
    setTimeout(() => {
      onNextStep({ highlightedTab: nextStep?.highlightedTab ?? null });
      setFadeContent(false);
    }, 150);
  };

  const closeTutorial = () => {
    setFadeModal(true);
    setTimeout(() => {
      onClose();
      setFadeModal(false);
    }, 500);
  };

  const nextButtonProps: ButtonProps = {
    children: t('common:Next'),
    color: 'secondary',
    fullWidth: true,
    onClick: handleNextStep,
  };

  const STEPS: TutorialStepContent[] = [
    {
      ButtonsProps: [nextButtonProps],
      description: getStepOneDescription,
      enabled: true,
      highlightedTab: null,
      id: 'welcome',
      imageUrl: welcomeImg,
      title: t('Welcome.Title'),
    },
    {
      ButtonsProps: [nextButtonProps],
      description: t('BeLive.Content'),
      enabled: true,
      highlightedTab: NavigationTabs.Events,
      id: 'be-live',
      imageUrl: beLiveImg,
      title: t('BeLive.Title'),
    },
    {
      ButtonsProps: [nextButtonProps],
      description: t('LiveShoppingPage.Content'),
      enabled: canAccessLiveShoppingPage,
      highlightedTab: NavigationTabs.LiveShoppingPage,
      id: 'live-shopping-page',
      imageUrl: liveShoppingPageImg,
      title: t('LiveShoppingPage.Title'),
    },
    {
      ButtonsProps: [nextButtonProps],
      description: t('Shoppables.Content'),
      enabled: true,
      highlightedTab: NavigationTabs.Shoppables,
      id: 'shoppables',
      imageUrl: shoppablesImg,
      title: t('Shoppables.Title'),
    },
    {
      ButtonsProps: [nextButtonProps],
      description: t('TrackPerformance.Content'),
      enabled: true,
      highlightedTab: NavigationTabs.Analytics,
      id: 'track-performance',
      imageUrl: performanceImg,
      title: t('TrackPerformance.Title'),
    },
  ];

  const enabledSteps = STEPS.filter(({ enabled }) => enabled);

  const currentStep = enabledSteps[currentStepIndex];
  const nextStep = enabledSteps[currentStepIndex + 1];

  return (
    <TutorialStep
      ImageModalProps={{
        ButtonsProps: currentStep.ButtonsProps,
        content: currentStep.description,
        imageUrl: currentStep.imageUrl,
        isContentFaded,
        isModalFaded,
        isOpen: true,
        title: currentStep.title,
      }}
      currentStepIndex={currentStepIndex}
      stepsCount={enabledSteps.length}
    />
  );
}
