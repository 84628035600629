import { V1 } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { CBOEventListReadModel } from '../../../domain/CBOEventListReadModel';
import { CBOEventReadModel } from '../../../domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { createEvent } from '../../../usecases/event/crud/create-event';
import { deleteEvent } from '../../../usecases/event/crud/delete-event';
import { updateEvent } from '../../../usecases/event/crud/update-event';
import { updateReplay } from '../../../usecases/event/crud/update-replay';
import { EventsState } from '../../state/events';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetCreateEvent = createAction<void>('events/reset-create-event');
export const resetUpdateEvent = createAction<void>('events/reset-update-event');
export const resetUpdateReplay = createAction<void>('events/reset-update-replay');

export const updateCBOEventListReadModelWithUpdateDto = (
  eventListReadModel: CBOEventListReadModel,
  updateDto: V1.api.UpdateEventDTO,
): CBOEventListReadModel => {
  return {
    ...eventListReadModel,
    ...updateDto,
    showTime: updateDto.showTime ? new Date(updateDto.showTime) : null,
  };
};

export const updateCBOEventReadModelWithUpdateReplayDto = (
  eventReadModel: CBOEventReadModel,
  updateDto: V1.api.UpdateReplayDTO,
): CBOEventReadModel => {
  return {
    ...eventReadModel,
    description: updateDto.description,
    title: updateDto.title,
  };
};

export const updateCBOEventReadModelWithUpdateDto = (
  eventReadModel: CBOEventReadModel,
  updateDto: V1.api.UpdateEventDTO,
): CBOEventReadModel => {
  return {
    ...eventReadModel,
    ...updateDto,
    facebookRestreamingConfiguration: updateDto.facebookRestreamingConfiguration
      ? {
          ...updateDto.facebookRestreamingConfiguration,
          expiresAt: new Date(updateDto.facebookRestreamingConfiguration.expiresAt),
        }
      : null,
    restreamingConfigurations: updateDto.restreamingConfigurations.map((restreamingConfiguration) => {
      if (restreamingConfiguration.enabled) {
        return {
          ...restreamingConfiguration,
          expiresAt: restreamingConfiguration.expiresAt ? new Date(restreamingConfiguration.expiresAt) : null,
        };
      }
      return restreamingConfiguration;
    }),
    showTime: updateDto.showTime ? new Date(updateDto.showTime) : null,
    youtubeRestreamingConfiguration: updateDto.youtubeRestreamingConfiguration
      ? {
          ...updateDto.youtubeRestreamingConfiguration,
          expiresAt: new Date(updateDto.youtubeRestreamingConfiguration.expiresAt),
        }
      : null,
  };
};

export const buildCrudReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(createEvent.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('eventCreation'),
    events: state.events.concat(action.payload),
  }));
  builder.addCase(createEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventCreation'),
  }));
  builder.addCase(createEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventCreation', [action.error]),
  }));
  builder.addCase(resetCreateEvent, (state) => ({
    ...state,
    ...notRequestedStatus('eventCreation'),
  }));
  builder.addCase(deleteEvent.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('eventDeletion'),
    events: state.events.filter(({ id }) => id !== action.meta.arg.eventId),
  }));
  builder.addCase(deleteEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventDeletion'),
  }));
  builder.addCase(deleteEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventDeletion', [action.error]),
  }));

  builder.addCase(updateEvent.fulfilled, (state, action) => {
    const events = [...state.events];
    const eventInListIndex = events.findIndex(({ id }) => id === action.meta.arg.id);
    if (eventInListIndex !== -1) {
      const updatedEventInList = updateCBOEventListReadModelWithUpdateDto(
        events[eventInListIndex],
        action.meta.arg.payload,
      );
      events.splice(eventInListIndex, 1, updatedEventInList);
    }

    let currentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
        }
      : null;

    if (currentEvent?.id === action.meta.arg.id) {
      currentEvent = updateCBOEventReadModelWithUpdateDto(currentEvent, action.meta.arg.payload);
    }

    return {
      ...state,
      ...successStatus('eventUpdate'),
      currentEvent,
      events,
    };
  });
  builder.addCase(updateEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('eventUpdate'),
  }));
  builder.addCase(updateEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventUpdate', [action.error]),
  }));
  builder.addCase(updateReplay.pending, (state) => ({
    ...state,
    ...pendingStatus('replayUpdate'),
  }));
  builder.addCase(updateReplay.fulfilled, (state, action) => {
    let currentEvent: CBOEventReadModel | null = state.currentEvent
      ? {
          ...state.currentEvent,
          products: state.currentEvent.products.map((product) => CBOEventReadModelProduct.fromState(product.toState())),
        }
      : null;

    if (currentEvent?.id === action.meta.arg.id) {
      currentEvent = updateCBOEventReadModelWithUpdateReplayDto(currentEvent, action.meta.arg.payload);
    }

    return {
      ...state,
      ...successStatus('replayUpdate'),
      currentEvent,
    };
  });
  builder.addCase(updateReplay.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replayUpdate', [action.error]),
  }));
  builder.addCase(resetUpdateEvent, (state) => ({
    ...state,
    ...notRequestedStatus('eventUpdate'),
  }));
  builder.addCase(resetUpdateReplay, (state) => ({
    ...state,
    ...notRequestedStatus('replayUpdate'),
  }));
};
