import { SxProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../core/domain/CBOCurrency';
import { formatCurrency } from '../../../utils/currency-formatter';

interface ProductPriceProps {
  currency: CBOCurrency;
  price: number | null;
  sx?: SxProps;
  variant?: 'range' | 'simple';
}

export default function ProductPrice({ currency, price, sx = {}, variant }: ProductPriceProps) {
  const { t } = useTranslation('common');

  if (!price) {
    return <>- {currency.symbol}</>;
  }
  let prefix = '';
  if (variant === 'range') {
    prefix = `${t('from')} `;
  }
  return (
    <>
      <Typography
        component="span"
        sx={{
          fontSize: 'small',
          fontWeight: 'lighter',
          ...sx,
        }}
      >
        {prefix} {formatCurrency(price, currency)}
      </Typography>
    </>
  );
}
