import { V1 } from '@bellepoque/api-contracts';
import { FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EventSettingsIcon from '../../../../icons/EventSettingsIcon';
import InputTooltip from '../../atoms/inputs/InputTooltip';
import SettingsSection from '../../molecules/SettingsSection';
import { UpdateReplayInfosPayload } from './event-details/ReplayLinks';

const constraints = V1.api.constraints.events;

export type UpdateReplayInfosFormProps = {
  disabled?: boolean;
  form: UseFormReturn<UpdateReplayInfosPayload, object>;
  hasDialogContainer?: boolean;
  onSubmit: () => void;
};

const UpdateReplayInfosForm = ({
  disabled = false,
  form,
  onSubmit,
  hasDialogContainer = false,
}: UpdateReplayInfosFormProps) => {
  const { handleSubmit: handleFormSubmit, register, formState, watch } = form;
  const { errors } = formState;
  const theme = useTheme();
  const { t } = useTranslation(['events', 'common']);

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: constraints.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: constraints.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });
  const { ref: descriptionInputRef, ...descriptionInputProps } = register('description', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.description.maxLength }),
      value: constraints.description.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.description.minLength }),
      value: constraints.description.minLength,
    },
    required: { message: t('DescriptionIsRequired'), value: true },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFormSubmit(onSubmit)(e);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <SettingsSection
        disableElevation
        fixedIcon={hasDialogContainer}
        icon={<EventSettingsIcon sx={{ color: theme.palette.common.white }} />}
        sx={{ ...(hasDialogContainer && { marginLeft: '-25px', paddingRight: 0 }) }}
        title={t('ReplayInfos')}
      >
        <Grid container justifyContent="center" marginTop={2}>
          <Grid item xs={12}>
            <Grid container gap={3} justifyContent="center">
              <Grid item position="relative" xs={12}>
                <TextField
                  disabled={disabled}
                  error={!!errors.title}
                  fullWidth
                  helperText={!!errors.title ? errors?.title?.message : ''}
                  id="title"
                  inputRef={titleInputRef}
                  label={t('Title')}
                  required
                  variant="filled"
                  {...titleInputProps}
                />
                <InputTooltip content={t('helpers.ReplayTitle')} />
                <FormHelperText>{`${watch('title').length} / ${constraints.title.maxLength}`}</FormHelperText>
              </Grid>
              <Grid item position="relative" xs={12}>
                <TextField
                  disabled={disabled}
                  error={!!errors.description}
                  fullWidth
                  helperText={!!errors.description ? errors?.description?.message : ''}
                  id="description"
                  inputRef={descriptionInputRef}
                  label={t('Description')}
                  multiline
                  required
                  rows={4}
                  variant="filled"
                  {...descriptionInputProps}
                />
                <InputTooltip content={t('helpers.Description')} />
                <FormHelperText>{`${watch('description').length} / ${
                  constraints.description.maxLength
                }`}</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SettingsSection>
    </form>
  );
};

export default UpdateReplayInfosForm;
