import { CatalogProductDTO, ProductOptions as ProductOptionsDTO } from '@bellepoque/api-contracts';

import { CBOCatalogProduct } from '../../core/domain/CBOCatalogProduct';
import { CBOEventReadModelProductOptions } from '../../core/domain/CBOEventReadModelProductOptions';
import { getSmallestVariantPrice } from '../../utils/variants';

function buildDomainOptions(options: ProductOptionsDTO): CBOEventReadModelProductOptions | null {
  return {
    ...options,
    samePricesChecked: options.variantsHaveSamePrice,
  };
}

export function toDomain(catalogProductDTO: CatalogProductDTO): CBOCatalogProduct {
  const domainOptions = catalogProductDTO.options ? buildDomainOptions(catalogProductDTO.options) : null;
  const hasVariants = !!domainOptions && domainOptions.variants?.length > 0;
  const price =
    domainOptions && hasVariants ? getSmallestVariantPrice(domainOptions.variants) : catalogProductDTO.price;

  return {
    ...catalogProductDTO,
    hasVariants,
    price,
  };
}

export function manyToDomain(catalogProductDTOs: CatalogProductDTO[]): CBOCatalogProduct[] {
  return catalogProductDTOs.map(toDomain);
}
