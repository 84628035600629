import { OnboardingProgress } from '@bellepoque/api-contracts';

import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface OnboardingCommandsQueriesState {
  progressFetching: QueryResult;
  progressUpdate: CommandResult;
}

export interface OnboardingState extends OnboardingCommandsQueriesState {
  progress: OnboardingProgress | null;
}
export const INITIAL_ONBOARDING_STATE: OnboardingState = {
  progress: null,
  progressFetching: INITIAL_QUERY_RESULT,
  progressUpdate: INITIAL_COMMAND_RESULT,
};
