import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type UploadVideoFromSocialMediaParams = {
  shoppableVideoId: string;
  tenantId: string;
  url: string;
};

// TODO: Test
export const uploadVideoFromSocialMedia = createAppAsyncThunk(
  'shoppables/upload-video-from-social-media',
  async (
    { shoppableVideoId, tenantId, url }: UploadVideoFromSocialMediaParams,
    { extra: { shoppablesGateway, tracingGateway } },
  ): Promise<{ videoUrl: string }> => {
    let videoUrl: string;

    try {
      const res = await shoppablesGateway.getVideoFromSocialMedia(url);
      videoUrl = res.videoUrl;
    } catch (e: any) {
      console.error('Error while fetching video from social media url', e);
      throw e;
    }

    try {
      await shoppablesGateway.notifyFileUploaded({
        fileUrl: videoUrl,
        shoppableId: shoppableVideoId,
        tenantId,
      });
    } catch (e: any) {
      tracingGateway.captureException(e);
      console.error('Error while notifying file uploaded', e);
      throw e;
    }

    return { videoUrl };
  },
);
