import { V1 } from '@bellepoque/api-contracts';
import { combineLatest, firstValueFrom, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CBOFacebookPage, CBOFacebookUser } from '../../core/domain/CBOFacebookAccount';
import { AuthenticationGateway } from '../../core/gateways/authentication-gateway';
import { FacebookGateway, ManagedFacebookPage } from '../../core/gateways/facebook-gateway';
import { ApiGateway } from '../ApiGateway';

type FacebookResponse<T> = T | { error: unknown };

export type FacebookImageResponse = {
  data: {
    height: number;
    url: string;
    width: number;
  };
};

export type FacebookUserResponse = {
  id: string;
  name: string;
  picture: {
    data: {
      height: number;
      url: string;
      width: number;
    };
  };
};

const RESTREAMING_SCOPES =
  'business_management, publish_video, pages_read_engagement, pages_manage_posts, pages_show_list';

export class RealFacebookGateway extends ApiGateway implements FacebookGateway {
  isInitialized = false;

  constructor(private appId: string, authenticationGateway: AuthenticationGateway) {
    super(authenticationGateway);
  }

  apiRequest = <T extends Record<string, unknown>>(path: string, params?: Record<string, string>): Promise<T> => {
    const url = `/${path}?${params && new URLSearchParams(params).toString()}`;
    return new Promise(function (resolve, reject) {
      FB.api(url, (response: FacebookResponse<T>) =>
        'error' in response ? reject(response.error) : resolve(response),
      );
    });
  };

  login(): Promise<fb.StatusResponse> {
    return new Promise(function (resolve) {
      FB.login((response: any) => resolve(response), { scope: RESTREAMING_SCOPES });
    });
  }

  logout(): Promise<void> {
    return new Promise(function (resolve) {
      FB.logout(() => resolve());
    });
  }

  async fetchLongLivedAccessToken(accessToken: string): Promise<string> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.FacebookLongLivedAccessTokenDTO>({
        params: { token: accessToken },
        url: `${this.apiEndpoint}/facebook-long-lived-access-token`,
      }).pipe(map(({ accessToken }) => accessToken)),
    );
  }

  async fetchUser(): Promise<CBOFacebookUser> {
    return firstValueFrom(
      from(this.apiRequest<FacebookUserResponse>('me', { fields: 'id,picture,name' })).pipe(
        map((user) => ({
          id: user.id,
          name: user.name,
          picture: user.picture.data.url,
          type: V1.api.FacebookRestreamingAccountTypeSchema.enum.USER,
        })),
      ),
    );
  }

  fetchPageImage(page: ManagedFacebookPage): Promise<FacebookImageResponse> {
    return this.apiRequest<FacebookImageResponse>(`${page.id}/picture`, {
      access_token: page.access_token,
      redirect: 'false',
    });
  }

  async fetchPages(longLivedUserToken: string): Promise<CBOFacebookPage[]> {
    return firstValueFrom(
      from(this.apiRequest<{ data: ManagedFacebookPage[] }>('me/accounts', { access_token: longLivedUserToken })).pipe(
        switchMap((pagesResponse) => {
          if (pagesResponse.data.length === 0) return of([]);
          return combineLatest(
            pagesResponse.data.map((pageWithoutImage) =>
              from(this.fetchPageImage(pageWithoutImage)).pipe(
                map((imageResponse) => ({ ...pageWithoutImage, picture: imageResponse.data.url })),
                map((pageWithImage) => ({
                  ...pageWithImage,
                  accessToken: pageWithImage.access_token,
                  type: V1.api.FacebookRestreamingAccountTypeSchema.enum.PAGE,
                })),
              ),
            ),
          );
        }),
      ),
    );
  }

  init(): void {
    if (this.isInitialized) return;

    if (!FB) throw new Error('Facebook SDK was not found. It could be blocked by an ad blocker.');

    FB.init({
      appId: this.appId,
      autoLogAppEvents: true,
      version: 'v17.0',
      xfbml: true,
    });

    this.isInitialized = true;
  }
}
