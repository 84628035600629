import { EventStatus, OnboardingStep, TOnboardingStep } from '@bellepoque/api-contracts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../routes';
import OnboardingTemplate from '../../templates/onboarding/OnboardingTemplate';
import { createOnboardingViewModel } from './Onboarding.viewmodel';

export const ONBOARDING_SEQUENCE: Map<number, TOnboardingStep> = new Map([
  [0, OnboardingStep.CreateEvent],
  [1, OnboardingStep.AddProducts],
  [2, OnboardingStep.CameraApp],
  [3, OnboardingStep.PrivateTest],
  [4, OnboardingStep.OpenToPublic],
]);

export default function Onboarding() {
  const viewModel = useSelector(createOnboardingViewModel({ dispatch: useDispatch() }));

  const {
    currentEventHasProducts,
    currentEventStatus,
    currentStep,
    fetchOnboardingEvent,
    hasCreatedOrUpdatedEventSuccessfully,
    isCreatingOrUpdatingEvent,
    isCurrentEventTheOnboardingEvent,
    isFetchingProgress,
    isUpdatingProgress,
    resetEventCreationAndUpdate,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateStep,
  } = viewModel;

  const navigate = useNavigate();

  const [currentSequenceIndex, setCurrentSequenceIndex] = useState<number>(0);

  const loading = isFetchingProgress || isUpdatingProgress;

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Onboarding',
    });
  }, []);

  useEffect(() => {
    if (!isCurrentEventTheOnboardingEvent) {
      fetchOnboardingEvent();
    }
  }, [currentStep, isCurrentEventTheOnboardingEvent]);

  useEffect(() => {
    handleProgressStepChange();
  }, [currentStep]);

  useEffect(() => {
    if (hasCreatedOrUpdatedEventSuccessfully && currentStep === OnboardingStep.CreateEvent) {
      resetEventCreationAndUpdate();
      handleGoToNextStep();
    }
  }, [hasCreatedOrUpdatedEventSuccessfully, currentStep]);

  useEffect(() => {
    if (currentEventStatus) {
      handleEventStatusChange(currentEventStatus);
    }
  }, [currentEventStatus]);

  const getPreviousStep = (): TOnboardingStep | null => {
    return ONBOARDING_SEQUENCE.get(currentSequenceIndex - 1) ?? null;
  };

  const getNextStep = (): TOnboardingStep | null => {
    return ONBOARDING_SEQUENCE.get(currentSequenceIndex + 1) ?? null;
  };

  const handleEventStatusChange = (eventStatus: EventStatus) => {
    if (eventStatus === EventStatus.FINISHED) {
      updateStep(OnboardingStep.Completed);
      return;
    }

    const shouldGoToOpenToPublicStep =
      currentStep === OnboardingStep.PrivateTest &&
      (eventStatus === EventStatus.PRIVATE_PREVIEW || eventStatus === EventStatus.ON_AIR);

    if (shouldGoToOpenToPublicStep) {
      handleGoToNextStep();
      return;
    }

    const shouldReturnToPrivateTestStep =
      currentStep === OnboardingStep.OpenToPublic && eventStatus === EventStatus.PLANNED;

    if (shouldReturnToPrivateTestStep) {
      handleGoToPreviousStep();
    }
  };

  const handleGoToNextStep = async () => {
    const nextStep = getNextStep();
    if (!nextStep) return;

    updateStep(nextStep);
  };

  const handleGoToPreviousStep = async () => {
    const previousStep = getPreviousStep();
    if (!previousStep) return;

    updateStep(previousStep);
  };

  const handleProgressStepChange = () => {
    if (currentStep === OnboardingStep.Completed) {
      navigate(`${routes.events}?show-onboarding-completed-modal=1`, { replace: true });
      return;
    }

    setSequenceForCurrentStep();
  };

  const setSequenceForCurrentStep = () => {
    const sequenceForCurrentStep = Array.from(ONBOARDING_SEQUENCE.entries()).find((value) => value[1] === currentStep);
    if (sequenceForCurrentStep) {
      setCurrentSequenceIndex(sequenceForCurrentStep[0]);
    }
  };

  return (
    <OnboardingTemplate
      currentEventHasProducts={currentEventHasProducts}
      currentSequenceIndex={currentSequenceIndex}
      isCreatingOrUpdatingEvent={isCreatingOrUpdatingEvent}
      loading={loading}
      onGoToNextStep={handleGoToNextStep}
      onGoToPreviousStep={handleGoToPreviousStep}
    />
  );
}
