import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export default function DownloadVideoIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 23.692 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g data-name="Groupe 18198" id="Groupe_18198" transform="translate(-4111.308 -3651)">
        <path
          d="M22,12v8a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V12a2.006,2.006,0,0,1,2-2H20A2.006,2.006,0,0,1,22,12Zm-6,4-6-3.27v6.53Z"
          fill={theme.palette.common.white}
          id="ic_subscriptions_24px"
          transform="translate(4113 3641)"
        />
        <g
          fill={theme.palette.common.white}
          id="ic_file_download_24px"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(4107.308 3657)"
        >
          <path
            d="M 7.692307949066162 8.707110404968262 L 7.338757991790771 8.353549957275391 L 4.646448135375977 5.661240100860596 L 3.792887926101685 4.807690143585205 L 4.999998092651367 4.807690143585205 L 6.038457870483398 4.807690143585205 L 6.038457870483398 3 L 6.038457870483398 2.5 L 6.538457870483398 2.5 L 8.846158027648926 2.5 L 9.346158027648926 2.5 L 9.346158027648926 3 L 9.346158027648926 4.807690143585205 L 10.38461780548096 4.807690143585205 L 11.59172821044922 4.807690143585205 L 10.73816776275635 5.661240100860596 L 8.045858383178711 8.353549957275391 L 7.692307949066162 8.707110404968262 Z"
            stroke="none"
          />
          <path
            d="M 6.538457870483398 3 L 6.538457870483398 5.307690143585205 L 4.999998092651367 5.307690143585205 L 7.692307949066162 8 L 10.38461780548096 5.307690143585205 L 8.846158027648926 5.307690143585205 L 8.846158027648926 3 L 6.538457870483398 3 M 6.538457870483398 2 L 8.846158027648926 2 C 9.3984375 2 9.846158027648926 2.447719573974609 9.846158027648926 3 L 9.846158027648926 4.307690143585205 L 10.38461780548096 4.307690143585205 C 10.78907775878906 4.307690143585205 11.15371799468994 4.551330089569092 11.30849838256836 4.925010204315186 C 11.46327781677246 5.298679828643799 11.37771797180176 5.728799819946289 11.09172821044922 6.014800071716309 L 8.399417877197266 8.707109451293945 C 8.008888244628906 9.097629547119141 7.375727653503418 9.097629547119141 6.985198020935059 8.707109451293945 L 4.292887687683105 6.014800071716309 C 4.006897926330566 5.728799819946289 3.921338081359863 5.298679828643799 4.076117515563965 4.925010204315186 C 4.230897903442383 4.551330089569092 4.595538139343262 4.307690143585205 4.999998092651367 4.307690143585205 L 5.538457870483398 4.307690143585205 L 5.538457870483398 3 C 5.538457870483398 2.447719573974609 5.986178398132324 2 6.538457870483398 2 Z"
            fill={theme.palette.primary.main}
            stroke="none"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
