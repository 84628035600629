import { EventId, V1 } from '@bellepoque/api-contracts';

import {
  CBOEventReadModelProduct,
  CreateCBOEventReadModelProductParams,
} from '../../../domain/CBOEventReadModelProduct';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type CreateProductPayload = V1.api.CreateProductDTO;

type CreateProductParams = {
  eventId: EventId;
  payload: CreateProductPayload;
};

export const createEventProduct = createAppAsyncThunk(
  'events/create-product',
  async ({ eventId, payload }: CreateProductParams, { extra: { eventGateway } }): Promise<CBOEventReadModelProduct> => {
    const createdDto = await eventGateway.createEventProduct(eventId, payload);

    const createParams: CreateCBOEventReadModelProductParams = {
      ...createdDto,
      isSynced: false,
      options: createdDto.options
        ? {
            ...createdDto.options,
            samePricesChecked: createdDto.options?.variantsHaveSamePrice || false,
          }
        : null,
    };

    return CBOEventReadModelProduct.create(createParams);
  },
);
