import { manyToCBOShopifyPage } from '../../../gateways/shopify/shopify-pages-mapper';
import { CBOShopifyPage } from '../../domain/CBOShopifyPage';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

// TODO: Add tests
export const getPagesForMediaCollections = createAppAsyncThunk(
  'shopify/get-pages-for-media-collections',
  async (tenantId: string, { extra: { shopifyGateway } }): Promise<CBOShopifyPage[]> => {
    const dto = await shopifyGateway.getPagesForMediaCollections(tenantId);

    return manyToCBOShopifyPage(dto.pages);
  },
);
