import { CBOCurrency } from '../../domain/CBOCurrency';
import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface SettingsState extends SettingsCommandsQueriesState {
  currencies: CBOCurrency[];
}

export interface SettingsCommandsQueriesState {
  currenciesFetching: QueryResult;
}

export const INITIAL_SETTINGS_STATE: SettingsState = {
  currencies: [],
  currenciesFetching: INITIAL_QUERY_RESULT,
};
