import { Box, styled } from '@mui/material';
import React from 'react';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { ThemePreviewProducts } from '../../molecules/event/ThemePreviewProducts';
import ThemePreviewActions from '../../molecules/shoppables/ThemePreviewActions';
import ThemePreviewHeader from '../../molecules/shoppables/ThemePreviewHeader';

const ThemePreviewWrapper = styled(Box)({
  alignItems: 'stretch',
  backgroundColor: '#505050',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  inset: '0px',
  overflow: 'hidden',
  pointerEvents: 'none',
  textAlign: 'left',
  top: '16px',

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  // iPhone SE dimensions
  height: '444.6px',
  width: '250px',
  /* eslint-enable sort-keys-fix/sort-keys-fix */
});

const ContentWrapper = styled('div')({
  flex: '1 1 0%',
  position: 'relative',
});

const PreviewBorder = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderWidth: 1,
  height: '26px',
  width: '100%',
}));

interface ThemePreviewProps {
  addToCartBackgroundColor: string;
  brandLogo: string | null;
  currency: CBOCurrency;
  mainFontName: string;
  title: string;
}

export default function ThemePreview({
  brandLogo,
  currency,
  title,
  addToCartBackgroundColor,
  mainFontName,
}: ThemePreviewProps) {
  return (
    <Box display="flex" flex={1} py={2}>
      <ThemePreviewWrapper sx={{ boxShadow: 3, fontFamily: mainFontName }}>
        <PreviewBorder />
        <ContentWrapper>
          <ThemePreviewHeader brandLogo={brandLogo} title={title} />
          <ThemePreviewActions addToCartBackgroundColor={addToCartBackgroundColor} />
        </ContentWrapper>
        <ThemePreviewProducts addToCartBackgroundColor={addToCartBackgroundColor} currency={currency} />
        <PreviewBorder />
      </ThemePreviewWrapper>
    </Box>
  );
}
