import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';

import { MonthTrafficReports, TrafficReport } from '../../../../core/domain/Reports';
import { TrafficMonthReporting, TrafficMonthReportingReport } from '../../atoms/reports/MonthReporting';

type Totals = {
  nbUniqueLiveViewers: number;
  nbUniqueReplayViewers: number;
  nbUniqueShoppableVideoViewers: number;
};

interface MonthDetailsProps {
  defaultOpened: boolean;
  monthName: string;
  reports: MonthTrafficReports;
}

const compareByShowTime = (a: TrafficMonthReportingReport, b: TrafficMonthReportingReport) => {
  if ('showTime' in a.report && 'showTime' in b.report) {
    return a.report.showTime > b.report.showTime ? -1 : 1;
  }
  return 1;
};

export default function TrafficMonthDetails({ defaultOpened, monthName, reports }: MonthDetailsProps) {
  const totals = Object.values<TrafficReport>(reports).reduce<Totals>(
    (acc, value) => {
      if ('nbUniqueLiveViewers' in value) {
        acc.nbUniqueLiveViewers += value.nbUniqueLiveViewers;
        acc.nbUniqueReplayViewers += value.nbUniqueReplayViewers;
      }

      if ('nbUniqueShoppableVideoViewers' in value) {
        acc.nbUniqueShoppableVideoViewers += value.nbUniqueShoppableVideoViewers;
      }

      return acc;
    },
    {
      nbUniqueLiveViewers: 0,
      nbUniqueReplayViewers: 0,
      nbUniqueShoppableVideoViewers: 0,
    },
  );

  const builtReports: TrafficMonthReportingReport[] = Object.entries<TrafficReport>(reports)
    .map<TrafficMonthReportingReport>(([eventId, report]) => ({
      eventId,
      report,
    }))
    .sort(compareByShowTime);

  return (
    <Accordion defaultExpanded={defaultOpened}>
      <AccordionSummary aria-controls="month-details" expandIcon={<ExpandMoreIcon />} id={`month-details-${monthName}`}>
        <Typography sx={{ flexShrink: 0, textAlign: 'left', width: '40%' }}>{monthName}</Typography>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{totals.nbUniqueLiveViewers}</Typography>
        </Box>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{totals.nbUniqueReplayViewers}</Typography>
        </Box>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{totals.nbUniqueShoppableVideoViewers}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pr: 5 }}>
        <TrafficMonthReporting reports={builtReports} />
      </AccordionDetails>
    </Accordion>
  );
}
