import { OnboardingProgress } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const fetchOnboardingProgress = createAppAsyncThunk(
  'onboarding/fetch-progress',
  async (_, { extra: { onboardingGateway } }): Promise<OnboardingProgress | null> => {
    return onboardingGateway.getProgress();
  },
);
