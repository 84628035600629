import { Box, Typography, styled } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel, GridValueFormatterParams, gridClasses } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import {
  CBOShoppableVideoStatisticsPreviewWithSales,
  CBOShoppableVideoStatisticsPreviewWithoutSales,
} from '../../../../core/domain/CBOTenantStatistics';
import { formatCurrency } from '../../../../utils/currency-formatter';

const PREFIX = 'ShoppableVideoStatisticsPreviewSection';

const classes = {
  boldCell: `${PREFIX}-bold-cell`,
  numberValueCell: `${PREFIX}-number-value-cell`,
};
const IMAGE_SIZE = '80px';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  flex: 1,
  padding: theme.spacing(3),
}));

const ImageContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const Image = styled('img')({
  borderRadius: '10px',
  height: IMAGE_SIZE,
  objectFit: 'cover',
  width: IMAGE_SIZE,
});

const EmptyImage = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `solid ${theme.palette.grey[500]} 1px`,
  borderRadius: '10px',
  display: 'block',
  height: IMAGE_SIZE,
  width: IMAGE_SIZE,
}));

const Title = styled(Typography)({
  fontSize: '1.5em',
  fontWeight: 'bold',
});

const StyledDataGrid = styled(DataGrid)({
  border: 'none',

  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    paddingLeft: 0,
  },

  [`& .${gridClasses.columnSeparator}`]: {
    visibility: 'hidden',
  },

  [`& .${gridClasses.row}`]: {
    maxHeight: 'initial !important',
    minHeight: '100px !important',
  },

  [`& .${gridClasses.row}:hover`]: {
    background: 'transparent',
  },

  [`& .${gridClasses.cell}`]: {
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    fontSize: '1.2em',
    lineHeight: '1.5 !important',
    minHeight: '100px !important',
    outline: 'none',
    overflow: 'visible',
    whiteSpace: 'pre-wrap',
  },

  [`& .${classes.boldCell}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.numberValueCell} `]: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
});

type ShoppableVideoStatisticsPreviewRowProps =
  | {
      currency: CBOCurrency;
      hasSales: true;
      previews: CBOShoppableVideoStatisticsPreviewWithSales[];
    }
  | {
      hasSales: false;
      previews: CBOShoppableVideoStatisticsPreviewWithoutSales[];
    };

export default function ShoppableVideoStatisticsPreviewSection(props: ShoppableVideoStatisticsPreviewRowProps) {
  const { hasSales, previews } = props;
  const { t } = useTranslation('analytics', { keyPrefix: 'ShoppableVideoStatisticsPreview' });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: hasSales ? 'purchasedAmounts' : 'views',
      sort: 'desc',
    },
  ]);

  const defaultHeaderProps: Partial<GridColDef> = {
    flex: 1,
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      ...defaultHeaderProps,
      field: 'imageUrl',
      headerName: '',
      renderCell: (params) => (
        <ImageContainer>
          {params.row.imageUrl ? <Image alt={params.row.title} src={params.row.imageUrl} /> : <EmptyImage />}
        </ImageContainer>
      ),
      sortable: false,
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'title',
      flex: 2,
      headerName: t('Title'),
    },
    {
      ...defaultHeaderProps,
      field: 'productTitle',
      flex: 1.5,
      headerName: t('Product'),
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.numberValueCell,
      field: 'views',
      headerName: t('Views'),
    },
  ];

  if (hasSales) {
    columns.push({
      ...defaultHeaderProps,
      cellClassName: classes.numberValueCell,
      field: 'purchasedCarts',
      headerName: t('PurchasedCarts'),
    });
    columns.push({
      ...defaultHeaderProps,
      cellClassName: classes.numberValueCell,
      field: 'purchasedAmounts',
      headerName: t('PurchasedAmounts'),
      valueFormatter: (params: GridValueFormatterParams) =>
        formatCurrency(params.value as number, props.currency, { round: true }),
    });
  }

  return (
    <Container boxShadow={3}>
      <Title>{t('SectionTitle')}</Title>
      <StyledDataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        hideFooterPagination
        onSortModelChange={setSortModel}
        rowHeight={100}
        rows={previews}
        sortModel={sortModel}
      />
    </Container>
  );
}
