import { AppDispatch, State } from '../../../../core/store';
import { adminFindEvent } from '../../../../core/usecases/administration/admin-find-event';
import { updateAlias } from '../../../../core/usecases/tenants/update-alias';

export const createAdminToolsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const findEventStatus = state.administration.findEvent.status;
    const foundEvent = state.administration.foundEvent;
    const { currentTenant, tenants } = state.tenants;
    const updateTenantAliasStatus = state.tenants.updateAlias.status;

    return {
      currentTenant,
      findEventStatus,
      foundEvent,
      onFindEventSubmit: (eventId: string) => {
        dispatch(adminFindEvent({ eventId }));
      },
      onUpdateTenantAliasSubmit: (tenantAlias: string) => {
        dispatch(updateAlias({ alias: tenantAlias, tenantId: state.tenants.currentTenantId }));
      },
      tenants,
      updateTenantAliasStatus,
    };
  };
