import { ProductOption as EventProductOption, ProductOptionValue } from '@bellepoque/api-contracts';
import { Checkbox, Grid, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import EventSettingsIcon from '../../../../../icons/EventSettingsIcon';
import SettingsSection from '../../SettingsSection';
import ProductOptions from './ProductOptions';

interface DetailsSectionProps {
  hasOptionsChecked: boolean;
  isPending: boolean;
  onAddOption2: () => void;
  onAddOption3: () => void;
  onChangeOption1: (newOption: EventProductOption) => void;
  onChangeOption2: (newOption: EventProductOption) => void;
  onChangeOption3: (newOption: EventProductOption) => void;
  onChangeOptionsChecked: (value: boolean) => void;
  onDeleteOption1: (value: ProductOptionValue) => void;
  onDeleteOption2: (value: ProductOptionValue) => void;
  onDeleteOption3: (value: ProductOptionValue) => void;
  onRemoveOption2: () => void;
  onRemoveOption3: () => void;
  option1: EventProductOption;
  option2: EventProductOption | null;
  option3: EventProductOption | null;
  product?: CBOEventReadModelProduct;
  readonly: boolean;
  roundBottom?: boolean;
}

export default function DetailsSection(props: DetailsSectionProps) {
  const { t } = useTranslation('events');
  const theme = useTheme();

  const {
    hasOptionsChecked,
    isPending,
    onAddOption2,
    onAddOption3,
    onRemoveOption2,
    onRemoveOption3,
    onChangeOption1,
    onChangeOption2,
    onChangeOption3,
    onDeleteOption1,
    onDeleteOption2,
    onDeleteOption3,
    onChangeOptionsChecked,
    option1,
    option2,
    option3,
    product,
    readonly,
    roundBottom,
  } = props;

  // https://github.com/react-hook-form/react-hook-form/discussions/4413
  // Due to react-hook-form, we can't simply disable a radio button and keep its value when we are in read-only
  // When we're editing, we use the form value, and when we're reading, we use the product one
  const hasOptionsCheckedValue = readonly ? product?.hasOptionsChecked : hasOptionsChecked;

  if (readonly && !hasOptionsChecked) {
    return null;
  }

  return (
    <SettingsSection
      icon={<EventSettingsIcon sx={{ color: theme.palette.common.white }} />}
      roundBottom={roundBottom}
      title={t('productDetails.Details')}
      width={11}
    >
      <Grid container spacing={2} textAlign="initial">
        <Grid item xs={12}>
          <label htmlFor="hasOptionsChecked" style={readonly ? {} : { cursor: 'pointer' }}>
            <Checkbox
              checked={hasOptionsCheckedValue}
              color="primary"
              disabled={isPending || readonly}
              id="hasOptionsChecked"
              onChange={(e, value) => {
                onChangeOptionsChecked(value);
              }}
              sx={{ paddingLeft: 0 }}
            />

            <span>{t('ProductHasManyVersions')}</span>
          </label>
        </Grid>
        {hasOptionsCheckedValue && option1 && (
          <Grid item xs={12}>
            <ProductOptions
              isPending={isPending}
              onAddOption2={onAddOption2}
              onAddOption3={onAddOption3}
              onChangeOption1={onChangeOption1}
              onChangeOption2={onChangeOption2}
              onChangeOption3={onChangeOption3}
              onDeleteOption1={onDeleteOption1}
              onDeleteOption2={onDeleteOption2}
              onDeleteOption3={onDeleteOption3}
              onRemoveOption2={onRemoveOption2}
              onRemoveOption3={onRemoveOption3}
              option1={option1}
              option2={option2}
              option3={option3}
              readonly={readonly}
            />
          </Grid>
        )}
      </Grid>
    </SettingsSection>
  );
}
