import { Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import ValueCard from '../../atoms/home/ValueCard';

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));

type WithSales = {
  hasSales: true;
  purchasedAmounts: number;
  purchasedCarts: number;
};

type WithoutSales = {
  hasSales: false;
  validatedCarts: number;
  validatedCartsAmounts: number;
};

type EventIntroSectionProps = {
  currency: CBOCurrency;
  views: number;
} & (WithoutSales | WithSales);

export default function EventIntroSection(props: EventIntroSectionProps) {
  const { t } = useTranslation('analytics');

  const { currency, hasSales, views } = props;

  return (
    <Container container>
      <ValueCard title={t('Traffic.Views')} totalValue={views} valueType="number" />
      {hasSales ? (
        <>
          <ValueCard
            title={t('ECommerce.Orders')}
            tooltip={t('analytics:ConversionTrackerDisclaimer')}
            totalValue={props.purchasedCarts}
            valueType="number"
          />
          <ValueCard
            currency={props.currency}
            title={t('ECommerce.Sales')}
            tooltip={t('analytics:ConversionTrackerDisclaimer')}
            totalValue={props.purchasedAmounts}
            valueType="currency"
          />
        </>
      ) : (
        <>
          <ValueCard title={t('ECommerce.CartsInCheckOut')} totalValue={props.validatedCarts} valueType="number" />
          <ValueCard
            currency={currency}
            title={t('ECommerce.AmountInCheckOut')}
            totalValue={props.validatedCartsAmounts}
            valueType="currency"
          />
        </>
      )}
    </Container>
  );
}
