import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { fetchTenantStatistics } from '../../usecases/tenant-statistics/fetch-tenant-statistics';
import { INITIAL_TENANT_STATISTICS_STATE, TenantStatisticsState } from '../state/tenantStatistics';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildCrudReducers = (builder: ActionReducerMapBuilder<TenantStatisticsState>) => {
  builder.addCase(fetchTenantStatistics.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('tenantStatisticsFetching'),
    tenantStatistics: action.payload,
  }));
  builder.addCase(fetchTenantStatistics.pending, (state) => ({
    ...state,
    ...pendingStatus('tenantStatisticsFetching'),
  }));
  builder.addCase(fetchTenantStatistics.rejected, (state, action) => ({
    ...state,
    ...errorStatus('tenantStatisticsFetching', [action.error]),
  }));
};

export const tenantStatisticsSlice = createSlice({
  extraReducers: (builder) => {
    buildCrudReducers(builder);
  },
  initialState: INITIAL_TENANT_STATISTICS_STATE,
  name: 'tenant-statistics',
  reducers: {},
});
