import { CBOHomePage, homePageToDomain } from '../../domain/Home';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchHomePageParams = {
  tenantId: string;
};

export const fetchHomePage = createAppAsyncThunk(
  'home/fetch-home-page',
  async ({ tenantId }: FetchHomePageParams, { extra: { homeGateway } }): Promise<CBOHomePage> => {
    const dto = await homeGateway.fetchHomepage(tenantId);
    return homePageToDomain(dto);
  },
);
