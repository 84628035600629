import { BillingPlanId } from '@bellepoque/api-contracts';
import { Box, FormControlLabel, FormGroup, Grid, Switch, SwitchProps, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import DraggableIcon from '../../../../icons/DraggableIcon';
import { secondsToFormatedTime } from '../../../../utils/date-format';
import EventDateValue from '../../atoms/EventDateValue';
import UpgradePlanButton from '../../atoms/UpgradePlanButton';

const VideoDuration = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 500,
}));

const Title = styled(Typography)({
  display: 'block',
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const SwitchLabel = styled(Typography)(({ theme }) => ({
  marginRight: `${theme.spacing(3)}`,
  opacity: 0.5,
}));

const Container = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  alignItems: 'center',
  borderBottom: `solid ${theme.palette.grey[500]} 1px`,
  display: 'flex',
  justifyContent: 'center',
  marginLeft: `-${theme.spacing(3)}`,
  padding: `20px ${theme.spacing(3)} 20px ${theme.spacing(2)}`,
  textAlign: 'initial',
  width: `calc(100% + ${theme.spacing(6)})`,
}));

const Image = styled('img')({
  borderRadius: '10px',
  height: '40px',
  objectFit: 'cover',
  width: '40px',
});

const ReplayDurationExceededMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '.8em',
}));

interface EventRowProps {
  currentPlanId: BillingPlanId;
  event: CBOEventListReadModel;
  maxPublishedReplayDurationInSeconds?: number;
  position?: number;
  reduceOpacity?: boolean;
  section: 'highlight' | 'replays';
  switchProps: SwitchProps;
  tenantName: string;
}

export default function EventRow({
  currentPlanId,
  event,
  maxPublishedReplayDurationInSeconds,
  position,
  reduceOpacity,
  section,
  switchProps,
  tenantName,
}: EventRowProps) {
  const { t } = useTranslation('liveShoppingPage');

  const hasExceededVideoDuration =
    maxPublishedReplayDurationInSeconds &&
    event.videoDuration &&
    event.videoDuration > maxPublishedReplayDurationInSeconds * 1000;

  const isPublished = switchProps.checked;

  return (
    <Container>
      <Box display="flex" flex={1}>
        <Grid alignItems="center" columnGap={2} container>
          <Grid item sx={{ opacity: reduceOpacity ? 0.5 : 1 }} xs={1}>
            {section === 'replays' && (
              <Box alignItems="center" display="flex" flex={1} justifyContent="space-evenly">
                <Box width="15px">{!!position && <Typography sx={{ fontWeight: 'bold' }}>{position}</Typography>}</Box>
                <Box>
                  <DraggableIcon />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid alignItems="center" display="flex" item sx={{ opacity: reduceOpacity ? 0.5 : 1 }} width="40px">
            <Image src={event.image ?? ''} />
          </Grid>
          <Grid
            alignItems="flex-end"
            display="flex"
            item
            justifyContent="flex-start"
            sx={{ opacity: reduceOpacity ? 0.5 : 1 }}
            xs={3}
          >
            <EventDateValue date={event.showTime} />
          </Grid>
          <Grid item sx={{ opacity: reduceOpacity ? 0.5 : 1 }} xs={3.5}>
            <Box display="flex" flexDirection="column" width="100%">
              <Title>{event.title}</Title>
              {!!event.videoDuration && (
                <VideoDuration>{secondsToFormatedTime(event.videoDuration / 1000)}</VideoDuration>
              )}
            </Box>
          </Grid>
          <Grid alignItems="center" display="flex" flex={1} item justifyContent="flex-end">
            {!isPublished && hasExceededVideoDuration ? (
              <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
                <UpgradePlanButton
                  ButtonProps={{ size: 'small' }}
                  sx={{ fontSize: '.8em' }}
                  tenantName={tenantName}
                  text={t('UpgradeToDislay')}
                />
                <ReplayDurationExceededMessage>
                  {t('ClipLengthExceeded.' + currentPlanId)}
                </ReplayDurationExceededMessage>
              </Box>
            ) : (
              <FormGroup>
                <FormControlLabel
                  control={<Switch {...switchProps} />}
                  label={<SwitchLabel>{t('Display')}</SwitchLabel>}
                  labelPlacement={'start'}
                />
              </FormGroup>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
