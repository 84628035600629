import { IdentifyUserParams } from '../../gateways/user-journey-tracing-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const initUserJourneyTracing = createAppAsyncThunk(
  'user-journey-tracing/init',
  async (params: IdentifyUserParams, { extra: { tracingGateway, userJourneyTracingGateway } }): Promise<void> => {
    try {
      userJourneyTracingGateway.identifyUser(params);
    } catch (e: any) {
      tracingGateway.captureException(e);
      console.error('Error while initializing user journey tracing', e);
    }
  },
);
