import { Box, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CopyLandingPageUrlButton from './CopyLandingPageUrlButton';

interface LandingPageURLProps {
  url: string;
}

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'row',
  gap: `${theme.spacing(1)}`,
  height: '48px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
}));

const ColumnContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
  justifyContent: 'center',
}));

const Label = styled(Typography)({
  fontSize: '.65em',
  fontWeight: 'bold',
  lineHeight: 1,
});

const URL = styled(Typography)({
  fontSize: '.85em',
  lineHeight: 'initial',
});

export default function LandingPageURL({ url }: LandingPageURLProps) {
  const { t } = useTranslation('liveShoppingPage');

  const handleCopyPageUrl = () => {
    navigator.clipboard.writeText(url);
  };

  const trimmedUrl = useMemo(() => {
    if (!url) return '';

    const splittedLiveShopUrl = url.split('://');
    return splittedLiveShopUrl.length > 1 ? splittedLiveShopUrl[1] : url;
  }, [url]);

  return (
    <MainContainer>
      <ColumnContainer>
        <Label>{t('LiveShopUrl')}</Label>
        <URL>{trimmedUrl}</URL>
      </ColumnContainer>
      <CopyLandingPageUrlButton onCopyUrlButtonClick={handleCopyPageUrl} />
    </MainContainer>
  );
}
