import { EventId } from '@bellepoque/api-contracts';

import { CBOChatCredentials } from '../../../domain/Chat';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type FetchEventChatCredentialsParams = {
  eventId: EventId;
};

export const fetchOneEventChatCredentials = createAppAsyncThunk(
  'events/fetch-chat-credentials',
  async ({ eventId }: FetchEventChatCredentialsParams, { extra: { eventGateway } }): Promise<CBOChatCredentials> => {
    const { authenticationToken, userId } = await eventGateway.fetchOneEventChatCredentials(eventId);
    return {
      authToken: authenticationToken,
      userId,
    };
  },
);
