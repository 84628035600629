import PaletteIcon from '@mui/icons-material/PaletteOutlined';
import { Box, ButtonProps, Grid, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import {
  CBOShoppablesSettings,
  CBOShoppablesSettingsFileUploadUrls,
} from '../../../../core/domain/CBOShoppablesSettings';
import { ShoppablesSettingsFilesUploads } from '../../../../core/gateways/shoppables-gateway';
import { CommandStatus, QueryStatus } from '../../../../core/store/state/utils';
import { UpdateShoppablesSettingsPayload } from '../../../../core/usecases/shoppables/update-shoppables-settings';
import FormDialog from '../../../templates/dialog/FormDialog';
import SettingsSection from '../../molecules/SettingsSection';
import BrandLogoSelector from '../../molecules/shoppables/BrandLogoSelector';
import ButtonsColorPicker from '../../molecules/shoppables/ButtonsColorPicker';
import FontSelector from '../../molecules/shoppables/FontSelector';
import { ShoppableSettingsFormInputs } from './ShoppableSettingsFormDialog';
import ThemePreview from './ShoppableThemePreview';

export type PreviewImages = {
  brandLogo: string | null;
};

type FilesList = {
  brandLogo?: File;
};

type UpdateShoppablesSettingsPayloadWithFiles = FilesList & UpdateShoppablesSettingsPayload;

type ShoppableSettingsFormProps = {
  fontOptions: string[];
  form: UseFormReturn<ShoppableSettingsFormInputs, object>;
  onClose: () => void;
  onGetSecuredFileUploadUrls: () => void;
  onSubmit: (values: UpdateShoppablesSettingsPayload) => void;
  onUploadShoppablesSettingsFiles: (files: ShoppablesSettingsFilesUploads) => void;
  open: boolean;
  readonly: boolean;
  shoppablesSettings: CBOShoppablesSettings;
  shoppablesSettingsFileUploadUrls: CBOShoppablesSettingsFileUploadUrls | null;
  shoppablesSettingsFileUploadUrlsFetchingStatus: QueryStatus;
  shoppablesSettingsFileUploadingStatus: CommandStatus;
  submissionInProgress: boolean;
  tenantCurrency: CBOCurrency;
};

const ShoppableSettingsForm: FC<ShoppableSettingsFormProps> = ({
  fontOptions,
  form,
  onClose,
  onGetSecuredFileUploadUrls,
  onSubmit,
  onUploadShoppablesSettingsFiles,
  open,
  readonly,
  shoppablesSettings,
  shoppablesSettingsFileUploadingStatus,
  shoppablesSettingsFileUploadUrls,
  shoppablesSettingsFileUploadUrlsFetchingStatus,
  submissionInProgress,
  tenantCurrency,
}) => {
  const { t } = useTranslation(['shoppables', 'common']);
  const theme = useTheme();

  const [brandLogoPreview, setBrandLogoPreview] = useState<string | null>(null);
  const [filesList, setFilesList] = useState<FilesList>({});
  const [submittedData, setSubmittedData] = useState<UpdateShoppablesSettingsPayloadWithFiles | null>(null);

  const { control, register, formState, watch, reset, getValues, handleSubmit: formHandleSubmit } = form;
  const { errors, isDirty, isSubmitted, isValid } = formState;

  const { addToCartBackgroundColor, mainFontName } = watch();

  useEffect(() => {
    if (isSubmitted) {
      handleShoppablesSettingsFileUrlsFetchingChanged();
    }
  }, [shoppablesSettingsFileUploadUrlsFetchingStatus]);

  useEffect(() => {
    if (shoppablesSettingsFileUploadingStatus === 'success' && isSubmitted) {
      handleShoppablesSettingsFilesUploaded();
    }
  }, [shoppablesSettingsFileUploadingStatus]);

  const handleShoppablesSettingsFilesUploaded = () => {
    if (!submittedData) {
      return;
    }

    onSubmit(submittedData);
  };

  const handleShoppablesSettingsFileUrlsFetchingChanged = () => {
    if (
      shoppablesSettingsFileUploadUrlsFetchingStatus !== 'loaded' ||
      Object.keys(filesList).length === 0 ||
      !shoppablesSettingsFileUploadUrls
    ) {
      return;
    }

    const files: ShoppablesSettingsFilesUploads = {};

    if (filesList.brandLogo) {
      files.brandLogo = {
        file: filesList.brandLogo,
        url: shoppablesSettingsFileUploadUrls.brandLogoUrl,
      };
    }

    if (Object.keys(files).length) {
      onUploadShoppablesSettingsFiles(files);
    }
  };

  const handleSubmit = ({ addToCartBackgroundColor, mainFontName }: ShoppableSettingsFormInputs) => {
    const hasChangedFiles = !!filesList.brandLogo;
    if (hasChangedFiles) {
      setSubmittedData({
        brandLogo: filesList.brandLogo,
        theme: {
          addToCartBackgroundColor,
          brandLogoUploaded: !!filesList.brandLogo,
          mainFontName,
        },
      });

      onGetSecuredFileUploadUrls();
      return;
    }

    onSubmit({
      theme: {
        addToCartBackgroundColor,
        brandLogoUploaded: false,
        mainFontName,
      },
    });
  };

  const handleUpdateFilesList = (files: Partial<FilesList>) => {
    setFilesList({ ...filesList, ...files });
  };

  const buttonsProps: ButtonProps[] = [
    {
      children: <span>{t('common:Cancel')}</span>,
      onClick: onClose,
      variant: 'text',
    },
    {
      children: <span>{t('common:SaveAndClose')}</span>,
      color: 'primary',
      disabled: !isValid || !isDirty || submissionInProgress || readonly,
      onClick: formHandleSubmit(handleSubmit),
      sx: {
        backgroundColor: theme.palette.primary.main,
      },
      variant: 'contained',
    },
  ];

  return (
    <FormDialog
      buttonsProps={buttonsProps}
      loading={false}
      onClose={onClose}
      open={open}
      sx={{
        minHeight: '600px',
        minWidth: '750px',
        overflowY: 'visible',
        width: '750px',
      }}
      title={t('shoppables:Settings')}
    >
      <form
        noValidate
        onSubmit={formHandleSubmit(handleSubmit)}
        style={{
          width: '100%',
        }}
      >
        <Box alignItems="flex-start" display="flex">
          <Box display="flex" flexDirection="column">
            <SettingsSection
              disableElevation
              fixedIcon
              icon={<PaletteIcon sx={{ color: (theme) => theme.palette.common.white, height: 30 }} />}
              roundBottom
              sx={{
                bgcolor: 'transparent',
                marginLeft: (theme) => `-${theme.spacing(4)}`,
                padding: theme.spacing(3),
              }}
              title={t('common:Theme')}
            >
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={10}>
                  <FontSelector
                    control={control}
                    fontOptions={fontOptions}
                    readonly={readonly}
                    submissionInProgress={submissionInProgress}
                  />
                </Grid>

                <Grid item xs={10}>
                  <BrandLogoSelector
                    defaultBrandLogoUrl={shoppablesSettings.theme.brandLogoUrl}
                    errors={errors}
                    onChange={(fileList) => handleUpdateFilesList({ brandLogo: fileList?.[0] })}
                    onResetBrandLogo={() =>
                      reset({
                        ...getValues(),
                        brandLogoUploaded: false,
                      })
                    }
                    onSetBrandLogoPreview={setBrandLogoPreview}
                    readonly={readonly}
                    register={register}
                    submissionInProgress={submissionInProgress}
                    watch={watch}
                  />
                </Grid>

                <Grid item xs={10}>
                  <ButtonsColorPicker
                    control={control}
                    errors={errors}
                    readonly={readonly}
                    submissionInProgress={submissionInProgress}
                    watch={watch}
                  />
                </Grid>
              </Grid>
            </SettingsSection>
          </Box>

          <Box>
            <ThemePreview
              addToCartBackgroundColor={addToCartBackgroundColor}
              brandLogo={brandLogoPreview}
              currency={tenantCurrency}
              mainFontName={mainFontName}
              title={t('shoppables:PreviewTitle')}
            />
          </Box>
        </Box>
      </form>
    </FormDialog>
  );
};

export default ShoppableSettingsForm;
