import { ProductOption as EventProductOption, ProductOptionValue } from '@bellepoque/api-contracts';
import { Box, Button, Divider, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductOptionSelector from '../ProductOptionSelector';

interface ProductOptionsProps {
  isPending: boolean;

  onAddOption2: () => void;
  onAddOption3: () => void;

  onChangeOption1: (newOption: EventProductOption) => void;
  onChangeOption2: (newOption: EventProductOption) => void;
  onChangeOption3: (newOption: EventProductOption) => void;

  onDeleteOption1: (value: ProductOptionValue) => void;
  onDeleteOption2: (value: ProductOptionValue) => void;
  onDeleteOption3: (value: ProductOptionValue) => void;

  onRemoveOption2: () => void;
  onRemoveOption3: () => void;

  option1: EventProductOption;
  option2: EventProductOption | null;
  option3: EventProductOption | null;

  readonly: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function ProductOptions(props: ProductOptionsProps) {
  const { t } = useTranslation(['events', 'common', 'products']);
  const {
    onAddOption2,
    onAddOption3,
    onRemoveOption2,
    onRemoveOption3,
    option1,
    option2,
    option3,
    readonly,
    onChangeOption1,
    onChangeOption2,
    onChangeOption3,
    onDeleteOption1,
    onDeleteOption2,
    onDeleteOption3,
  } = props;

  return (
    <Container>
      <Divider variant="fullWidth" />
      <Typography fontWeight="bold">{t('products:Versions')}</Typography>
      {option1 && (
        <Box mt={-1}>
          <ProductOptionSelector
            onChange={onChangeOption1}
            onDeleteValue={onDeleteOption1}
            option={option1}
            readonly={readonly}
            title={t('products:Option_number', { number: 1 })}
          />
          <Divider variant="fullWidth" />
        </Box>
      )}
      {option2 ? (
        <Box>
          <ProductOptionSelector
            onChange={onChangeOption2}
            onDeleteValue={onDeleteOption2}
            onRemoveOptionClicked={onRemoveOption2}
            option={option2}
            readonly={readonly}
            title={t('products:Option_number', { number: 2 })}
          />
          <Divider variant="fullWidth" />
        </Box>
      ) : (
        !readonly && (
          <Box>
            <Button color="primary" onClick={onAddOption2} sx={{ textTransform: 'initial' }} variant="contained">
              <span>{t('products:AddAnOption')}</span>
            </Button>
          </Box>
        )
      )}
      {option2 &&
        (option3 ? (
          <Box>
            <ProductOptionSelector
              onChange={onChangeOption3}
              onDeleteValue={onDeleteOption3}
              onRemoveOptionClicked={onRemoveOption3}
              option={option3}
              readonly={readonly}
              title={t('products:Option_number', { number: 3 })}
            />
            <Divider variant="fullWidth" />
          </Box>
        ) : (
          !readonly && (
            <Box>
              <Button color="primary" onClick={onAddOption3} sx={{ textTransform: 'initial' }} variant="contained">
                <span>{t('products:AddAnOption')}</span>
              </Button>
            </Box>
          )
        ))}
    </Container>
  );
}
