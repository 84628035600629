import { Button } from '@mui/material';
import React, { FC } from 'react';

import ShoppableSettingsIcon from '../../../icons/ShoppableSettingsIcon';

export type SettingsButtonProps = {
  onClick: () => void;
};

const SettingsButton: FC<SettingsButtonProps> = (props) => (
  <Button color="secondary" startIcon={<ShoppableSettingsIcon />} {...props} variant="contained" />
);

export default SettingsButton;
