import { V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { ShopifyGateway } from '../../core/gateways/shopify-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiShopifyGateway extends ApiGateway implements ShopifyGateway {
  checkShopifyScopesAreUpToDate(tenantId: string): Promise<boolean> {
    return firstValueFrom(
      this.authenticatedJsonQuery<boolean>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/shopify-scopes/status`,
      }),
    );
  }

  getPagesForMediaCollections(tenantId: string): Promise<V1.api.ShopifyPagesForMediaCollectionsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ShopifyPagesForMediaCollectionsDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/media-collections-pages`,
      }),
    );
  }
}
