import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type GetShoppableVideoFileUploadUrlParams = {
  shoppableId: string;
  tenantId: string;
};

export const getShoppableVideoFileUploadUrl = createAppAsyncThunk(
  'shoppables/get-video-file-upload-url',
  async (params: GetShoppableVideoFileUploadUrlParams, { extra: { shoppablesGateway } }): Promise<string> => {
    return shoppablesGateway.getShoppablesVideoFileUploadUrl(params);
  },
);
