import { Box, Button, DialogActions, Pagination, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActionsFooterWithPaginationProps {
  currentPage: number;
  hasSelectedTooManyProducts: boolean;
  isPaginationHidden: boolean;
  isSubmitButtonDisabled: boolean;
  onChangePage: (page: number) => void;
  onClose: () => void;
  onSubmit: () => void;
  pageCount: number;
}

const ButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}));

const Container = styled(DialogActions)(({ theme }) => ({
  alignItems: 'center',
  borderTop: `solid 1px ${theme.palette.grey[400]}`,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '.7em',
  marginTop: '2px',
}));

const PaginationContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
});

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& button': {
    fontWeight: 'lighter',
  },
  '& button.Mui-selected': {
    backgroundColor: 'transparent',
    fontWeight: 'bolder',
  },
  '& li .MuiPaginationItem-page': {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '1em',
  },
}));

export default function ActionsFooterWithPagination(props: ActionsFooterWithPaginationProps) {
  const { t } = useTranslation(['common', 'events']);
  const {
    currentPage,
    hasSelectedTooManyProducts,
    isPaginationHidden,
    isSubmitButtonDisabled,
    onChangePage,
    onClose,
    onSubmit,
    pageCount,
  } = props;

  return (
    <Container>
      <PaginationContainer>
        <StyledPagination
          boundaryCount={0}
          count={pageCount}
          hidden={isPaginationHidden}
          onChange={(_e, page) => onChangePage(page)}
          page={currentPage}
          siblingCount={2}
        />
      </PaginationContainer>
      <ButtonsContainer>
        <Button color="primary" onClick={onClose} sx={{ width: '120px' }} variant="text">
          <span>{t('common:Cancel')}</span>
        </Button>
        <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Button
            color="primary"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmit}
            sx={{ width: '120px' }}
            type="submit"
            variant="contained"
          >
            <span>{t('common:Add')}</span>
          </Button>
          <ErrorText sx={{ visibility: hasSelectedTooManyProducts ? 'visible' : 'hidden' }}>
            {t('events:ProductsLimitExceeded')}
          </ErrorText>
        </Box>
      </ButtonsContainer>
    </Container>
  );
}
