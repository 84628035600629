import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(3),
  width: '100%',
}));

interface ActionBarProps {
  areButtonsDisabled: boolean;
  isFormDirty: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function ActionBar({ areButtonsDisabled, isFormDirty, onCancel, onSubmit }: ActionBarProps) {
  const { t } = useTranslation('common');
  return (
    <Container>
      <Box display="flex" gap={3}>
        <Button disabled={!isFormDirty || areButtonsDisabled} onClick={onCancel}>
          <span>{t('Cancel')}</span>
        </Button>
        <Button
          color="primary"
          disableElevation
          disabled={!isFormDirty || areButtonsDisabled}
          onClick={onSubmit}
          variant="contained"
        >
          <span>{t('Update')}</span>
        </Button>
      </Box>
    </Container>
  );
}
