import { Box, SxProps, styled } from '@mui/material';
import React from 'react';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import ProductPrice from '../../atoms/ProductPrice';

const CrossedOutContainer = styled(Box)(({ theme }) => ({
  background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='%2370645C' /></svg>")`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%, auto',
  color: theme.palette.grey[500],
}));

interface PriceWithCrossedOutPriceProps {
  crossedOutPrice: number;
  crossedOutPriceSx?: SxProps;
  currency: CBOCurrency;
  price: number | null;
  priceSx?: SxProps;
}

export function PriceWithCrossedOutPrice({
  crossedOutPrice,
  currency,
  price,
  crossedOutPriceSx,
  priceSx,
}: PriceWithCrossedOutPriceProps) {
  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <CrossedOutContainer>
        <ProductPrice currency={currency} price={crossedOutPrice} sx={crossedOutPriceSx} />
      </CrossedOutContainer>
      <Box>
        <ProductPrice currency={currency} price={price} sx={priceSx} />
      </Box>
    </Box>
  );
}
