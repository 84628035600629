import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import mediaCollectionImg from '../../../assets/media-collection.jpg';
import { HomeNotificationId } from '../../../pages/homepage/Home';
import ShopifyButton from '../../atoms/ShopifyButton';
import GetStartedActionCard from '../../atoms/home/GetStartedActionCard';

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'initial',
}));

const ItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

type GetStartedWithShoppables = {
  onCloseNotification: (notificationId: HomeNotificationId) => void;
  onShowPlayerPlaylistsBlockHelpModal: () => void;
  shownNotificationsIds: HomeNotificationId[];
};

export default function GetStartedWithShoppables({
  onCloseNotification,
  onShowPlayerPlaylistsBlockHelpModal,
  shownNotificationsIds,
}: GetStartedWithShoppables) {
  const { t } = useTranslation('home');

  return (
    <Container>
      <Title variant="h2">{t('GetStarted.Shoppables.Title')}</Title>

      <ItemsContainer>
        {shownNotificationsIds.includes('no-player-playlists-block') && (
          <GetStartedActionCard
            actionButton={
              <ShopifyButton onClick={onShowPlayerPlaylistsBlockHelpModal}>
                {t('GetStarted.Shoppables.Cards.AddCarousel.ActionButton')}
              </ShopifyButton>
            }
            description={t('GetStarted.Shoppables.Cards.AddCarousel.Description')}
            duration={t('GetStarted.Shoppables.Cards.AddCarousel.Duration')}
            imageSrc={mediaCollectionImg}
            onClose={() => onCloseNotification('landing-page-created')}
            title={t('GetStarted.Shoppables.Cards.AddCarousel.Title')}
          />
        )}
      </ItemsContainer>
    </Container>
  );
}
