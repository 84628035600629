import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { copyEventTheme } from '../../../usecases/event/theme/copy-event-theme';
import { customiseEventTheme } from '../../../usecases/event/theme/customise-event-theme';
import { EventsState } from '../../state/events';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetCopyEventTheme = createAction<void>('events/reset-copy-theme');

export const buildThemeReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(copyEventTheme.fulfilled, (state) => ({
    ...state,
    ...successStatus('eventThemeCopy'),
  }));
  builder.addCase(copyEventTheme.pending, (state) => ({
    ...state,
    ...pendingStatus('eventThemeCopy'),
  }));
  builder.addCase(copyEventTheme.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventThemeCopy', [action.error]),
  }));
  builder.addCase(customiseEventTheme.fulfilled, (state) => ({
    ...state,
    ...successStatus('eventThemeCustomisation'),
  }));
  builder.addCase(customiseEventTheme.pending, (state) => ({
    ...state,
    ...pendingStatus('eventThemeCustomisation'),
  }));
  builder.addCase(customiseEventTheme.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventThemeCustomisation', [action.error]),
  }));
  builder.addCase(resetCopyEventTheme, (state) => ({
    ...state,
    ...notRequestedStatus('eventThemeCopy'),
  }));
};
