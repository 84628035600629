import { Cms, ProductId } from '@bellepoque/api-contracts';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { clearSelectedProducts, resetDeleteProducts } from '../../../../../core/store/slices/catalog.slice';
import { CATALOG_PAGINATION_LIMIT, CatalogSortType } from '../../../../../core/store/state/catalog';
import { deleteProducts } from '../../../../../core/usecases/catalog/delete-products';
import { fetchProducts } from '../../../../../core/usecases/catalog/fetch-products';
import { selectProduct } from '../../../../../core/usecases/catalog/select-product';
import { renewApiKey, resetRenewApiKey } from '../../../../../core/usecases/tenants/renew-api-key';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createCatalogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { apiKey, cms, currency, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const currentUser = state.authentication.currentUser;

    const { products, selectedProducts, search, sort } = state.catalog;
    const { offset, totalCount } = state.catalog.pagination;

    const currentPage = Math.floor(offset / CATALOG_PAGINATION_LIMIT) + 1;
    const pageCount = Math.ceil(totalCount / CATALOG_PAGINATION_LIMIT);

    const defaultFetchProductsFilters = {
      limit: CATALOG_PAGINATION_LIMIT,
      offset,
      search,
      sort,
      tenantId,
    };

    return {
      apiKey,
      canRenewApiKey: !!currentUser.isAdmin,
      changePage: (page: number) => {
        const newOffset = (page - 1) * CATALOG_PAGINATION_LIMIT;
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, offset: newOffset }));
      },
      clearSelectedProducts: () => {
        dispatch(clearSelectedProducts());
      },
      currency,
      currentPage,
      deleteProducts: (productsIds: ProductId[]) => {
        dispatch(deleteProducts({ productsIds, tenantId }));
      },
      deleteStatus: state.catalog.productsDelete.status,
      fetchProductsWithoutFilters: () => {
        dispatch(fetchProducts(defaultFetchProductsFilters));
      },
      hasShopify: state.tenants.currentTenant.cms === Cms.Shopify,
      pageCount,
      products,
      productsFetchingStatus: state.catalog.productsFetching.status,
      renewApiKey: () => {
        dispatch(renewApiKey({ tenantId }));
      },
      renewApiKeyStatus: state.tenants.renewApiKey.status,
      resetDeleteProducts: () => {
        dispatch(resetDeleteProducts());
      },
      resetRenewApiKey: () => {
        dispatch(resetRenewApiKey());
      },
      searchProducts: (search: string) => {
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, offset: 0, search }));
      },
      selectProduct: (product: CBOCatalogProduct, isSelected: boolean) => {
        dispatch(selectProduct({ isSelected, product }));
      },
      selectedProducts,
      sort,
      sortProducts: (sort: CatalogSortType) => {
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, sort }));
      },
      tenantCms: cms,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
