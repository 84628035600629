import { miniSerializeError } from '@reduxjs/toolkit';
import { of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, retry, switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import {
  synchronizeOneShoppableFulfilled,
  synchronizeOneShoppablePending,
  synchronizeOneShoppableRejected,
} from '../../slices/shoppables/shoppables-synchronize.slice';
import { ofType } from '../utils';

export const synchronizeShoppableEpic: AppEpic = (action$, state$, { container: { realtimeDataGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeOneShoppablePending>>(synchronizeOneShoppablePending.type),
    distinctUntilChanged((prev, cur) => prev.payload.shoppableId === cur.payload.shoppableId),
    switchMap((action) => {
      return realtimeDataGateway
        .getShoppableVideoListItemReadModel({
          shoppableId: action.payload.shoppableId,
          tenantId: action.payload.tenantId,
        })
        .pipe(
          retry({ delay: () => timer(2000) }), // reconnect to firebase if it disconnects
          switchMap((readModel) => {
            if (!readModel) {
              throw new Error('Read model not found');
            }
            return of(readModel);
          }),
        );
    }),
    map((shoppableReadModel) => synchronizeOneShoppableFulfilled(shoppableReadModel)),
    catchError((error) => of(synchronizeOneShoppableRejected([miniSerializeError(error)]))),
  );
};
