import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { fetchHomePage } from '../../../usecases/home/fetch-home-page';
import { EventsState } from '../../state/events';

export const buildUpcomingEventReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchHomePage.fulfilled, (state, action) => ({
    ...state,
    upcomingEvent: action.payload.upcomingEvent,
  }));
};
