import { Info } from '@mui/icons-material';
import { Box, Skeleton, SxProps, Tooltip, Typography, styled } from '@mui/material';
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { formatCurrency } from '../../../../utils/currency-formatter';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  position: 'relative',
}));

const TooltipContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
}));

type ValueCardProps = {
  comingSoon?: boolean;
  liveValue?: number;
  loading?: boolean;
  replayValue?: number;
  shoppableVideoValue?: number;
  sx?: SxProps;
  title: string;
  tooltip?: string;
  totalValue: number;
} & (
  | {
      currency: CBOCurrency;
      valueType: 'currency';
    }
  | {
      valueType: 'number';
    }
);

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
  lineHeight: 1,
});

const totalValueFontSize = '2.9em';
const totalValueLineHeight = 1;
const TotalValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: totalValueFontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: totalValueLineHeight,
  marginBottom: theme.spacing(1),
}));

const SecondaryValueContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(0.5),
  justifyContent: 'space-between',
  position: 'relative',
}));

const dotWidthInPx = 10;
const SecondaryValueDot = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  height: `${dotWidthInPx}px`,
  left: `calc(${-dotWidthInPx}px - ${theme.spacing(0.5)})`,
  position: 'absolute',
  top: 5,
  width: `${dotWidthInPx}px`,
}));

const SecondaryValue = styled(Typography)({
  fontSize: '.95em',
  fontWeight: 500,
  lineHeight: 1.3,
});

const SecondaryValueLabel = styled(Typography)({
  fontSize: '.95em',
  fontWeight: 500,
  lineHeight: 1.3,
});

export default function ValueCard(props: ValueCardProps) {
  const { loading = false, sx = {}, title, totalValue, liveValue, replayValue, shoppableVideoValue, valueType } = props;
  const { t } = useTranslation('home');

  const getFormattedValue = (value: number) => {
    switch (valueType) {
      case 'number': {
        return value.toLocaleString(i18next.language);
      }
      case 'currency': {
        return formatCurrency(value, props.currency, { locale: i18next.language, round: true });
      }
    }
  };

  const renderContent = useCallback(() => {
    if (loading) {
      return (
        <>
          <Skeleton animation="wave" sx={{ fontSize: '3.5em', lineHeight: 1 }} variant="text" />
          <Skeleton animation="wave" sx={{ fontSize: '1.2em', lineHeight: 1.3 }} variant="text" />
          <Skeleton animation="wave" sx={{ fontSize: '.95em', lineHeight: 1.3 }} variant="text" />
        </>
      );
    }

    return (
      <>
        <TotalValue>{getFormattedValue(totalValue)}</TotalValue>
        {!!liveValue && (
          <SecondaryValueContainer sx={{ color: (theme) => theme.palette.primary.main }}>
            {liveValue > 0 && liveValue >= (replayValue || 0) && liveValue >= (shoppableVideoValue || 0) && (
              <SecondaryValueDot sx={{ backgroundColor: (theme) => theme.palette.primary.main }} />
            )}
            <SecondaryValueLabel>{t('LiveEvents')}</SecondaryValueLabel>
            <SecondaryValue>{getFormattedValue(liveValue)}</SecondaryValue>
          </SecondaryValueContainer>
        )}
        {!!replayValue && (
          <SecondaryValueContainer>
            {replayValue > 0 && replayValue >= (liveValue || 0) && replayValue >= (shoppableVideoValue || 0) && (
              <SecondaryValueDot sx={{ backgroundColor: (theme) => theme.palette.text.primary }} />
            )}
            <SecondaryValueLabel>{t('Replays')}</SecondaryValueLabel>
            <SecondaryValue>{getFormattedValue(replayValue)}</SecondaryValue>
          </SecondaryValueContainer>
        )}
        {!!shoppableVideoValue && (
          <SecondaryValueContainer sx={{ color: (theme) => theme.palette.grey[600] }}>
            {shoppableVideoValue > 0 &&
              shoppableVideoValue >= (liveValue || 0) &&
              shoppableVideoValue >= (replayValue || 0) && (
                <SecondaryValueDot sx={{ backgroundColor: (theme) => theme.palette.grey[600] }} />
              )}
            <SecondaryValueLabel>{t('Shoppables')}</SecondaryValueLabel>
            <SecondaryValue>{getFormattedValue(shoppableVideoValue)}</SecondaryValue>
          </SecondaryValueContainer>
        )}
      </>
    );
  }, [loading, props, liveValue, replayValue, shoppableVideoValue, totalValue]);

  return (
    <Container boxShadow={3} sx={sx}>
      {!!props.tooltip && (
        <TooltipContainer>
          <Tooltip title={props.tooltip}>
            <Info sx={{ fontSize: '20px' }} />
          </Tooltip>
        </TooltipContainer>
      )}
      <Title>{title}</Title>
      <Box>{renderContent()}</Box>
    </Container>
  );
}
