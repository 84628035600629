import { NEVER, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import { loginFailed, loginPending } from '../../slices/authentication.slice';
import { ofType } from '../utils';

export const loginEpic: AppEpic = (action$, state$, { container: { authenticationGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof loginPending>>(loginPending.type),
    switchMap(() => {
      return authenticationGateway.loginWithRedirect().pipe(
        switchMap(() => NEVER),
        catchError((error) => of(loginFailed(error))),
      );
    }),
  );
};
