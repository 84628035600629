import { EventId } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventListReadModel } from '../../../../../core/domain/CBOEventListReadModel';
import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { EventTheme } from '../../../../../core/domain/Event';
import { CommandStatus, QueryStatus } from '../../../../../core/store/state/utils';
import StickyActionBarWrapper from '../../../../templates/StickyActionBarWrapper';
import ThemeActionBar from '../../../molecules/event/ThemeActionBar';
import ColorsSettingsSection from '../../../molecules/event/theme/ColorsSettingsSection';
import CopyLastEventSection from '../../../molecules/event/theme/CopyLastEventSection';
import CoversSettingsSection from '../../../molecules/event/theme/CoversSettingsSection';
import FontSettingsSection from '../../../molecules/event/theme/FontSettingsSection';
import LogoSettingsSection from '../../../molecules/event/theme/LogoSettingsSection';
import { SubmittedInputs } from './Theme';
import ThemePreview from './ThemePreview';

const PREFIX = 'ThemeForm';

const classes = {
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: `-${theme.spacing(2)}`,
  paddingTop: '24px',
  width: '100%',

  [`& .${classes.errorMessage}`]: {
    color: 'red',
  },
}));

export type ThemeInputs = {
  addToCartBackgroundColor: string;
  adminMessageBackgroundColor: string;
  adminMessageIcon: string;
  brandLogo: string;
  cartIcon: string;
  mainFontName: string;
  postEventCover: string;
  preEventCover: string;
  removeFromCartBackgroundColor: string;
  storeIcon: string;
  validateCartBackgroundColor: string;
};

export interface PreviewImages {
  adminMessageIcon: string | null;
  brandLogo: string | null;
  cartIcon: string | null;
  postEventCover: string | null;
  preEventCover: string | null;
  storeIcon: string | null;
}

function defaultValues(eventTheme: EventTheme): ThemeInputs {
  return {
    addToCartBackgroundColor: eventTheme.addToCartBackgroundColor,
    adminMessageBackgroundColor: eventTheme.adminMessageBackgroundColor,
    adminMessageIcon: '',
    brandLogo: '',
    cartIcon: '',
    mainFontName: eventTheme.mainFontName,
    postEventCover: '',
    preEventCover: '',
    removeFromCartBackgroundColor: eventTheme.removeFromCartBackgroundColor,
    storeIcon: '',
    validateCartBackgroundColor: eventTheme.validateCartBackgroundColor,
  };
}

const defaultFonts = [
  'Arial Narrow',
  'Arial',
  'Bookman Old Style',
  'Brush Script',
  'Calibri',
  'Cambria',
  'Candara',
  'Charcoal',
  'Copperplate',
  'Courier New',
  'Courier',
  'Didot',
  'Garamond',
  'Geneva',
  'Georgia',
  'Helvetica',
  'Impact',
  'Lucida Bright',
  'Lucida',
  'Monaco Monospace',
  'Monaco',
  'Optima',
  'Palatino Linotype',
  'Perpetua',
  'Roboto',
  'Sans-serif',
  'Times New Roman',
  'Times',
  'Trebuchet MS',
  'Ubuntu',
  'Verdana',
];

interface ThemeFormProps {
  currency: CBOCurrency;
  event: CBOEventReadModel;
  eventFilesUploadingStatus: CommandStatus;
  eventUploadFileUrlsFetchingStatus: QueryStatus;
  events: CBOEventListReadModel[];
  onCopyEventTheme: (copiedEventId: EventId) => void;
  onEventFileUrlsFetchingChanged: () => void;
  onFilesUploadingChanged: () => void;
  onSubmit: (values: SubmittedInputs) => void;
  readonly: boolean;
  submissionInProgress: boolean;
}

export default function ThemeForm({
  currency,
  event,
  eventFilesUploadingStatus,
  events,
  eventUploadFileUrlsFetchingStatus,
  onCopyEventTheme,
  onEventFileUrlsFetchingChanged,
  onFilesUploadingChanged,
  onSubmit,
  readonly,
  submissionInProgress,
}: ThemeFormProps) {
  const [copiedEventId, setCopiedEventId] = useState<EventId | null>(null);

  const [adminMessageIconPreview, setAdminMessageIconPreview] = useState<string | null>(null);
  const [brandLogoPreview, setBrandLogoPreview] = useState<string | null>(null);

  const eventTheme = event.theme;
  const { control, formState, getValues, handleSubmit, register, reset, watch } = useForm<ThemeInputs>({
    defaultValues: defaultValues(eventTheme),
  });

  const { errors, isDirty, isSubmitted } = formState;

  const [fontOptions, setFontOptions] = useState<string[]>(defaultFonts);

  register('mainFontName');
  const watchedMainFontName = watch('mainFontName');

  useEffect(() => {
    if (isSubmitted) {
      onEventFileUrlsFetchingChanged();
    }
  }, [isSubmitted, eventUploadFileUrlsFetchingStatus]);

  useEffect(() => {
    if (isSubmitted) onFilesUploadingChanged();
  }, [isSubmitted, eventFilesUploadingStatus]);

  useEffect(() => {
    if (watchedMainFontName) {
      setFontOptions((prevFontOptions) => [
        watchedMainFontName,
        ...prevFontOptions.filter((font) => font !== watchedMainFontName).sort(),
      ]);
    }
  }, [watchedMainFontName]);

  useEffect(() => {
    reset(defaultValues(eventTheme));
  }, [eventTheme, reset]);

  const onCancel = () => {
    reset(defaultValues(eventTheme));
  };

  const copyLastEventOptions = useMemo(
    () =>
      events
        .filter(({ id }) => id !== event.id)
        .map(({ id, title }) => ({ label: title, value: id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [events],
  );

  const isCopyLastEventSectionShown = useMemo(
    () => !readonly && copyLastEventOptions.length > 0,
    [copyLastEventOptions, readonly],
  );

  const renderLeftPanel = () => (
    <Box
      id="event-theme-form-left-panel"
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xl: '1fr 2.5fr',
          xs: '1fr',
        },
      }}
    >
      <Box sx={{ display: { xl: 'block', xs: 'none' } }}></Box>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'min-content',
        }}
      >
        {isCopyLastEventSectionShown && (
          <CopyLastEventSection
            onCopy={() => (copiedEventId ? onCopyEventTheme(copiedEventId) : null)}
            onSetCopiedEventId={setCopiedEventId}
            options={copyLastEventOptions}
            submissionInProgress={submissionInProgress}
            value={copiedEventId ?? ''}
          />
        )}

        <LogoSettingsSection
          defaultAdminMessageIconUrl={eventTheme.adminMessageIconUrl}
          defaultBrandLogoUrl={eventTheme.brandLogoUrl}
          errors={errors}
          onResetAdminMessageIcon={() => reset({ ...getValues(), adminMessageIcon: '' })}
          onResetBrandLogo={() => reset({ ...getValues(), brandLogo: '' })}
          onSetAdminMessageIconPreview={setAdminMessageIconPreview}
          onSetBrandLogoPreview={setBrandLogoPreview}
          readonly={readonly}
          register={register}
          roundTopBorder={!isCopyLastEventSectionShown}
          submissionInProgress={submissionInProgress}
          watch={watch}
        />

        <FontSettingsSection
          control={control}
          errors={errors}
          fontOptions={fontOptions}
          readonly={readonly}
          submissionInProgress={submissionInProgress}
        />

        <ColorsSettingsSection
          control={control}
          errors={errors}
          readonly={readonly}
          submissionInProgress={submissionInProgress}
          watch={watch}
        />

        <CoversSettingsSection
          defaultPostEventCoverUrl={eventTheme.postEventCoverUrl}
          defaultPreEventCoverUrl={eventTheme.preEventCoverUrl}
          errors={errors}
          onResetPostEventCover={() => reset({ ...getValues(), postEventCover: '' })}
          onResetPreEventCover={() => reset({ ...getValues(), preEventCover: '' })}
          readonly={readonly}
          register={register}
          submissionInProgress={submissionInProgress}
          watch={watch}
        />
      </Box>
    </Box>
  );

  const renderRightPanel = () => (
    <Box
      id="event-theme-form-right-panel"
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <ThemePreview
        adminMessageIcon={adminMessageIconPreview}
        brandLogo={brandLogoPreview}
        currency={currency}
        theme={getValues()}
        title={event.title}
      />
    </Box>
  );

  const renderActionBar = () =>
    !readonly && (
      <StickyActionBarWrapper inProgress={submissionInProgress}>
        <ThemeActionBar buttonsDisabled={submissionInProgress || readonly} isFormDirty={isDirty} onCancel={onCancel} />
      </StickyActionBarWrapper>
    );

  return (
    <>
      <form
        noValidate
        // FIXME
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '100%',
        }}
      >
        <Root id="event-theme-form-root">
          <Box
            id="event-them-form-main"
            sx={{
              display: 'grid',
              gap: 6,
              gridTemplateColumns: {
                md: '2fr 1fr',
                xs: '1fr',
              },
              mb: 1,
              mx: 6,
            }}
          >
            {renderLeftPanel()}
            {renderRightPanel()}
          </Box>
          {renderActionBar()}
        </Root>
      </form>
    </>
  );
}
