import { Link, styled } from '@mui/material';
import React from 'react';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[600],
  cursor: 'pointer',
  fontWeight: 'bold',
}));

interface ReplayLinkProps {
  children: string;
  onClick: (e: React.MouseEvent) => void;
}

export default function ReplayLink(props: ReplayLinkProps) {
  return <StyledLink {...props} />;
}
