import { EventId, ProductId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type ReorderProductsLineupParams = {
  eventId: EventId;
  newProductsOrder: ProductId[];
  previousProductsOrder: ProductId[];
};

export const reorderProductsLineup = createAppAsyncThunk(
  'events/reorder-products-lineup',
  async ({ eventId, newProductsOrder }: ReorderProductsLineupParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.reorderProductsLineup(eventId, { newProductsOrder });
  },
);
