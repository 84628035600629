import { V1 } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import MultistreamHeader from '../../../molecules/event/FormHeader';
import { createMultistreamViewModel } from './Multistream.viewmodel';
import MultistreamForm, { UpdateMultistreamInputs } from './MultistreamForm';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export type MultistreamProps = {
  event: CBOEventReadModel;
  readonly: boolean;
};

const Multistream: FC<MultistreamProps> = ({ event, readonly }) => {
  const viewModel = useSelector(createMultistreamViewModel({ dispatch: useDispatch() }));

  const {
    facebookInitStatus,
    facebookPages,
    facebookTokenExpiresAt,
    facebookUser,
    facebookUserAccessToken,
    fetchLoginUrlStatus,
    isConnectingToFacebook,
    isUpdatingEvent,
    onConnectFacebook,
    onDisconnectFacebook,
    onDisconnectYoutube,
    onFetchYoutubeChannels,
    onFetchYoutubeLoginUrl,
    onInitFacebook,
    onResetFetchYoutubeLoginUrl,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateEvent,
    youtubeConnectedUser,
    youtubeLoginUrl,
  } = viewModel;

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        ...eventToUserJourneyData(event),
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Event multistream',
    });
  }, []);

  const handleUpdateMultistream = (updatedEvent: UpdateMultistreamInputs) => {
    const payload: V1.api.UpdateEventDTO = {
      ...event,
      facebookRestreamingConfiguration: updatedEvent.facebookRestreamingConfiguration
        ? {
            ...updatedEvent.facebookRestreamingConfiguration,
            expiresAt: updatedEvent.facebookRestreamingConfiguration.expiresAt.toISOString(),
          }
        : null,
      restreamingConfigurations: updatedEvent.restreamingConfigurations.map((restreamingConfiguration) => {
        if (restreamingConfiguration.enabled) {
          return {
            ...restreamingConfiguration,
            expiresAt: restreamingConfiguration.expiresAt?.toISOString() ?? null,
          };
        }

        return restreamingConfiguration;
      }),
      showTime: event.showTime?.toISOString() ?? null,
      youtubeRestreamingConfiguration: updatedEvent.youtubeRestreamingConfiguration
        ? {
            ...updatedEvent.youtubeRestreamingConfiguration,
            expiresAt: updatedEvent.youtubeRestreamingConfiguration.expiresAt.toISOString(),
          }
        : null,
    };

    updateEvent(event.id, payload);
  };

  return (
    <Container>
      <MultistreamHeader event={event} />
      <MultistreamForm
        event={event}
        facebookIntegrationProps={{
          initStatus: facebookInitStatus,
          isConnecting: isConnectingToFacebook,
          onConnect: onConnectFacebook,
          onDisconnect: onDisconnectFacebook,
          onInit: onInitFacebook,
          pages: facebookPages,
          tokenExpiresAt: facebookTokenExpiresAt,
          user: facebookUser,
          userAccessToken: facebookUserAccessToken,
        }}
        onUpdateEvent={handleUpdateMultistream}
        readonly={readonly}
        submissionInProgress={isUpdatingEvent}
        youtubeIntegrationProps={{
          fetchLoginUrlStatus,
          loginUrl: youtubeLoginUrl,
          onDisconnect: onDisconnectYoutube,
          onFetchChannels: onFetchYoutubeChannels,
          onFetchLoginUrl: onFetchYoutubeLoginUrl,
          onResetFetchYoutubeLoginUrl,
          user: youtubeConnectedUser,
        }}
      />
    </Container>
  );
};

export default Multistream;
