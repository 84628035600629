import { MenuItem, Select } from '@mui/material';
import React from 'react';

import { CBOTenant } from '../../../../core/domain/CBOTenant';

interface YearSelector {
  onSelectedYearChanged: (year: number) => void;
  selectedYear: number;
  tenant: CBOTenant;
}

export default function YearSelector({ onSelectedYearChanged, selectedYear, tenant }: YearSelector) {
  const previousYears: number[] = [];
  const currentYear = new Date().getFullYear();
  const tenantCreationYear = tenant?.createdAt?.getFullYear() ?? currentYear;

  for (let i = tenantCreationYear; i <= currentYear; i++) previousYears.push(i);

  return (
    <Select
      onChange={(e) => onSelectedYearChanged(Number(e.target.value))}
      size="small"
      sx={{ backgroundColor: (theme) => theme.palette.common.white }}
      value={selectedYear}
    >
      {previousYears.map((y) => (
        <MenuItem key={y} value={y}>
          {y}
        </MenuItem>
      ))}
    </Select>
  );
}
