import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { connectFacebook } from '../../usecases/event/facebook/connect-facebook';
import { initFacebook } from '../../usecases/event/facebook/init-facebook';
import { FacebookState, INITIAL_FACEBOOK_STATE } from '../state/facebook';
import { errorStatus, notRequestedStatus, pendingStatus, successStatus } from '../utils';

export const disconnectFacebook = createAction<void>('facebook/disconnect');

const buildReducers = (builder: ActionReducerMapBuilder<FacebookState>) => {
  builder.addCase(connectFacebook.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('facebookConnecting'),
    connectedUser: action.payload,
  }));
  builder.addCase(connectFacebook.pending, (state) => ({
    ...state,
    ...pendingStatus('facebookConnecting'),
    connectedUser: null,
  }));
  builder.addCase(connectFacebook.rejected, (state, action) => ({
    ...state,
    ...errorStatus('facebookConnecting', [action.error]),
  }));
  builder.addCase(initFacebook.fulfilled, (state) => ({
    ...state,
    ...successStatus('facebookInitializing'),
  }));
  builder.addCase(initFacebook.pending, (state) => ({
    ...state,
    ...pendingStatus('facebookInitializing'),
  }));
  builder.addCase(initFacebook.rejected, (state, action) => ({
    ...state,
    ...errorStatus('facebookInitializing', [action.error]),
  }));
  builder.addCase(disconnectFacebook, (state) => ({
    ...state,
    ...notRequestedStatus('facebookConnecting'),
    connectedUser: null,
  }));
};

export const facebookSlice = createSlice({
  extraReducers: (builder) => {
    buildReducers(builder);
  },
  initialState: INITIAL_FACEBOOK_STATE,
  name: 'facebook',
  reducers: {},
});
