import React, { FC } from 'react';

import UploadFile, { FileTypes, UploadProps } from './UploadFile';

export type UploadImageCircleBoxProps = Pick<
  UploadProps,
  'buttonLabel' | 'disabled' | 'errorMessage' | 'helperText' | 'onSelectFile'
>;

const UploadImageCircleBox: FC<UploadImageCircleBoxProps> = (props) => (
  <UploadFile acceptedFiles={FileTypes.Image} loading={false} variant="circle" {...props} />
);

export default UploadImageCircleBox;
