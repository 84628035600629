import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { checkHasYoutubeLiveStreamEnabled } from '../../usecases/event/youtube/check-has-youtube-live-stream-enabled';
import { fetchYoutubeChannels } from '../../usecases/event/youtube/fetch-youtube-channels';
import { fetchYoutubeLoginUrl } from '../../usecases/event/youtube/fetch-youtube-login-url';
import { INITIAL_YOUTUBE_STATE, YoutubeState } from '../state/youtube';
import { errorStatus, loadedStatus, notLoadedStatus, pendingStatus } from '../utils';

export const disconnectYoutube = createAction<void>('youtube/disconnect');
export const resetFetchYoutubeLoginUrl = createAction<void>('youtube/reset-fetch-login-url');

const buildReducers = (builder: ActionReducerMapBuilder<YoutubeState>) => {
  builder.addCase(checkHasYoutubeLiveStreamEnabled.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('hasYoutubeLiveStreamEnabledChecking'),
    hasLiveStreamEnabled: action.payload,
  }));
  builder.addCase(checkHasYoutubeLiveStreamEnabled.pending, (state) => ({
    ...state,
    ...pendingStatus('hasYoutubeLiveStreamEnabledChecking'),
  }));
  builder.addCase(checkHasYoutubeLiveStreamEnabled.rejected, (state, action) => ({
    ...state,
    ...errorStatus('hasYoutubeLiveStreamEnabledChecking', [action.error]),
  }));
  builder.addCase(fetchYoutubeLoginUrl.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('loginUrlFetching'),
    loginUrl: action.payload,
  }));
  builder.addCase(fetchYoutubeLoginUrl.pending, (state) => ({
    ...state,
    ...pendingStatus('loginUrlFetching'),
    loginUrl: null,
  }));
  builder.addCase(fetchYoutubeLoginUrl.rejected, (state, action) => ({
    ...state,
    ...errorStatus('loginUrlFetching', [action.error]),
  }));
  builder.addCase(fetchYoutubeChannels.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('channelsFetching'),
    connectedUser: {
      channels: action.payload.channels,
      token: action.payload.token,
    },
  }));
  builder.addCase(fetchYoutubeChannels.pending, (state) => ({
    ...state,
    ...pendingStatus('channelsFetching'),
    connectedUser: null,
  }));
  builder.addCase(fetchYoutubeChannels.rejected, (state, action) => ({
    ...state,
    ...errorStatus('channelsFetching', [action.error]),
  }));
  builder.addCase(disconnectYoutube, (state) => ({
    ...state,
    ...notLoadedStatus('channelsFetching'),
    ...notLoadedStatus('loginUrlFetching'),
    ...notLoadedStatus('hasYoutubeLiveStreamEnabledChecking'),
    connectedUser: null,
    loginUrl: null,
  }));
  builder.addCase(resetFetchYoutubeLoginUrl, (state) => ({
    ...state,
    ...notLoadedStatus('loginUrlFetching'),
    loginUrl: null,
  }));
};

export const youtubeSlice = createSlice({
  extraReducers: (builder) => {
    buildReducers(builder);
  },
  initialState: INITIAL_YOUTUBE_STATE,
  name: 'youtube',
  reducers: {},
});
