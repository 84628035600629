import { Info } from '@mui/icons-material';
import { Box, Grid, Tooltip, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModel } from '../../../../core/domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import {
  CBOEventECommerceStatistics,
  CBOEventECommerceStatisticsWithSales,
  CBOEventStatistics,
  CBOEventStatisticsWithSales,
} from '../../../../core/domain/CBOTenantStatistics';
import { Section } from '../../../templates/analytics';
import SectionTitle from '../../atoms/analytics/SectionTitle';
import StatsItem from '../../molecules/analytics/StatsItem';
import ProductsStatisticsSection from './ProductsStatisticsSection';

const TooltipContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
}));

type EventECommerceSectionProps = {
  currency: CBOCurrency;
  event: CBOEventReadModel | null;
  products?: CBOEventReadModelProduct[];
} & (
  | {
      hasSales: false;
      productsStatistics?: CBOEventStatistics['productsStatistics'];
      statistics: CBOEventECommerceStatistics;
    }
  | {
      hasSales: true;
      productsStatistics?: CBOEventStatisticsWithSales['productsStatistics'];
      statistics: CBOEventECommerceStatisticsWithSales;
    }
);

const CART_CHECKOUT_RELEASE_DATE = new Date('2023-01-13T00:00:00.000Z');

const getAverageValue = (value1: number, value2: number) => {
  if (value1 === 0 && value2 === 0) return 0;
  if (value1 !== 0 && value2 === 0) return value1;
  if (value1 === 0 && value2 !== 0) return value2;
  if (value1 !== 0 && value2 !== 0) return (value1 + value2) / 2;
};

export default function EventECommerceSection({
  currency,
  event,
  hasSales,
  products,
  productsStatistics,
  statistics,
}: EventECommerceSectionProps) {
  const { t } = useTranslation('analytics', { keyPrefix: 'ECommerce' });
  const { t: tCommon } = useTranslation('common');

  const sinceCheckoutReleaseText: string | undefined = useMemo(() => {
    if (event?.showTime) {
      const isShown = event.showTime < CART_CHECKOUT_RELEASE_DATE;
      if (isShown) {
        return tCommon('Since', {
          date: CART_CHECKOUT_RELEASE_DATE.toLocaleDateString(),
        });
      }
    }
  }, [event?.showTime, tCommon]);

  const { addedProducts, cartsAmounts, filledCarts, validatedCarts, validatedCartsAmounts, validatedProducts } =
    statistics;

  const renderAddedProducts = useCallback(
    () => (
      <StatsItem
        format="number"
        livePercentage={addedProducts.live / (addedProducts.live + addedProducts.replay)}
        liveValue={addedProducts.live}
        replayPercentage={addedProducts.replay / (addedProducts.live + addedProducts.replay)}
        replayValue={addedProducts.replay}
        title={t('AddedProducts')}
        totalValue={addedProducts.live + addedProducts.replay}
      />
    ),
    [currency, addedProducts],
  );

  const renderAverageRevenuePerPurchasedCart = useCallback(
    (statistics: CBOEventECommerceStatisticsWithSales) => {
      const { purchasedAmounts, purchasedCarts } = statistics;
      const averageLivePurchasedCartValue = purchasedAmounts.live / (purchasedCarts.live || 1);
      const averageReplayPurchasedCartValue = purchasedAmounts.replay / (purchasedCarts.replay || 1);

      return (
        <StatsItem
          currency={currency}
          format="currency"
          liveValue={averageLivePurchasedCartValue}
          replayValue={averageReplayPurchasedCartValue}
          title={t('AveragePurchasedCartValue')}
          totalValue={
            (purchasedAmounts.live + purchasedAmounts.replay) / (purchasedCarts.live + purchasedCarts.replay || 1)
          }
          valueSize="large"
        />
      );
    },
    [currency],
  );

  const renderAverageValidatedProductsValue = useCallback(() => {
    if (validatedCartsAmounts === null)
      return <StatsItem currency={currency} format="currency" title={t('AverageValidatedProductValue')} />;

    const averageLiveValidatedProductsValue = validatedCartsAmounts.live / (validatedProducts.live || 1);
    const averageReplayValidatedProductsValue = validatedCartsAmounts.replay / (validatedProducts.replay || 1);

    return (
      <StatsItem
        currency={currency}
        format="currency"
        liveValue={averageLiveValidatedProductsValue}
        replayValue={averageReplayValidatedProductsValue}
        title={t('AverageValidatedProductValue')}
        totalValue={getAverageValue(averageLiveValidatedProductsValue, averageReplayValidatedProductsValue)}
      />
    );
  }, [currency]);

  const renderAverageValidatedCartValue = useCallback(() => {
    if (validatedCartsAmounts === null)
      return <StatsItem currency={currency} format="currency" title={t('AverageValidatedProductValue')} />;

    const averageLiveValidatedCartValue = validatedCartsAmounts.live / (validatedCarts.live || 1);
    const averageReplayValidatedCartValue = validatedCartsAmounts.replay / (validatedCarts.replay || 1);

    return (
      <StatsItem
        currency={currency}
        format="currency"
        liveValue={averageLiveValidatedCartValue}
        replayValue={averageReplayValidatedCartValue}
        title={t('AverageValidatedCartValue')}
        totalValue={getAverageValue(averageLiveValidatedCartValue, averageReplayValidatedCartValue)}
        valueSize="large"
      />
    );
  }, [currency]);

  const renderCartsAmounts = useCallback(() => {
    if (cartsAmounts === null) return <StatsItem currency={currency} format="currency" title={t('AmountInCarts')} />;

    return (
      <StatsItem
        currency={currency}
        format="currency"
        livePercentage={cartsAmounts.live / (cartsAmounts.live + cartsAmounts.replay)}
        liveValue={cartsAmounts.live}
        replayPercentage={cartsAmounts.replay / (cartsAmounts.live + cartsAmounts.replay)}
        replayValue={cartsAmounts.replay}
        subtext={sinceCheckoutReleaseText}
        title={t('AmountInCarts')}
        totalValue={cartsAmounts.live + cartsAmounts.replay}
        valueSize="large"
      />
    );
  }, [currency, cartsAmounts]);

  const renderFilledCarts = useCallback(() => {
    return (
      <StatsItem
        format="number"
        livePercentage={filledCarts.live / (filledCarts.live + filledCarts.replay)}
        liveValue={filledCarts.live}
        replayPercentage={filledCarts.replay / (filledCarts.live + filledCarts.replay)}
        replayValue={filledCarts.replay}
        title={t('FilledCarts')}
        totalValue={filledCarts.live + filledCarts.replay}
      />
    );
  }, [currency, filledCarts]);

  const renderNonValidatedCarts = useCallback(
    () => (
      <StatsItem
        format="number"
        liveValue={filledCarts.live - validatedCarts.live}
        replayValue={filledCarts.replay - validatedCarts.replay}
        title={t('NonValidatedCarts')}
        totalValue={filledCarts.live - validatedCarts.live + (filledCarts.replay - validatedCarts.replay)}
      />
    ),
    [currency, filledCarts],
  );

  const renderPurchasedCarts = useCallback(
    (purchasedCarts: CBOEventECommerceStatisticsWithSales['purchasedAmounts']) => (
      <StatsItem
        format="number"
        livePercentage={purchasedCarts.live / (purchasedCarts.live + purchasedCarts.replay || 1)}
        liveValue={purchasedCarts.live}
        replayPercentage={purchasedCarts.replay / (purchasedCarts.live + purchasedCarts.replay || 1)}
        replayValue={purchasedCarts.replay}
        title={t('PurchasedCarts')}
        totalValue={purchasedCarts.live + purchasedCarts.replay}
      />
    ),
    [currency, validatedCarts],
  );

  const renderPurchasedAmounts = useCallback(
    (purchasedAmounts: CBOEventECommerceStatisticsWithSales['purchasedAmounts']) => (
      <StatsItem
        currency={currency}
        format="currency"
        livePercentage={purchasedAmounts.live / (purchasedAmounts.live + purchasedAmounts.replay || 1)}
        liveValue={purchasedAmounts.live}
        replayPercentage={purchasedAmounts.replay / (purchasedAmounts.live + purchasedAmounts.replay || 1)}
        replayValue={purchasedAmounts.replay}
        subtext={sinceCheckoutReleaseText}
        title={t('PurchasedAmounts')}
        totalValue={purchasedAmounts.live + purchasedAmounts.replay}
        valueSize="large"
      />
    ),
    [currency],
  );

  const renderValidatedCarts = useCallback(
    () => (
      <StatsItem
        format="number"
        livePercentage={validatedCarts.live / (validatedCarts.live + validatedCarts.replay)}
        liveValue={validatedCarts.live}
        replayPercentage={validatedCarts.replay / (validatedCarts.live + validatedCarts.replay)}
        replayValue={validatedCarts.replay}
        title={t('CartsInCheckOut')}
        totalValue={validatedCarts.live + validatedCarts.replay}
      />
    ),
    [currency, validatedCarts],
  );

  const renderValidatedCartsAmounts = useCallback(() => {
    if (validatedCartsAmounts === null)
      return <StatsItem currency={currency} format="currency" title={t('AmountInCheckOut')} valueSize="large" />;

    return (
      <StatsItem
        currency={currency}
        format="currency"
        livePercentage={validatedCartsAmounts.live / (validatedCartsAmounts.live + validatedCartsAmounts.replay)}
        liveValue={validatedCartsAmounts.live}
        replayPercentage={validatedCartsAmounts.replay / (validatedCartsAmounts.live + validatedCartsAmounts.replay)}
        replayValue={validatedCartsAmounts.replay}
        subtext={sinceCheckoutReleaseText}
        title={t('AmountInCheckOut')}
        totalValue={validatedCartsAmounts.live + validatedCartsAmounts.replay}
        valueSize="large"
      />
    );
  }, [currency, validatedCartsAmounts]);

  return (
    <Section boxShadow={2} sx={{ position: 'relative' }}>
      {hasSales && (
        <TooltipContainer>
          <Tooltip title={tCommon('analytics:ConversionTrackerDisclaimer') || ''}>
            <Info sx={{ fontSize: '20px' }} />
          </Tooltip>
        </TooltipContainer>
      )}
      <SectionTitle content={t('Title')} />

      <Grid columnGap={9} container flexDirection="row" mt={2} rowGap={5}>
        {hasSales ? (
          <>
            <Grid item>{renderCartsAmounts()}</Grid>
            <Grid item>{renderPurchasedAmounts(statistics.purchasedAmounts)}</Grid>
            <Grid item>{renderAverageRevenuePerPurchasedCart(statistics)}</Grid>
            <Grid item>{renderAddedProducts()}</Grid>
            <Grid item>{renderFilledCarts()}</Grid>
            <Grid item>{renderPurchasedCarts(statistics.purchasedCarts)}</Grid>
          </>
        ) : (
          <>
            <Grid item>{renderCartsAmounts()}</Grid>
            <Grid item>{renderValidatedCartsAmounts()}</Grid>
            <Grid item>{renderAverageValidatedCartValue()}</Grid>
            <Grid item>{renderFilledCarts()}</Grid>
            <Grid item>{renderValidatedCarts()}</Grid>
            <Grid item>{renderNonValidatedCarts()}</Grid>
            <Grid item>{renderAddedProducts()}</Grid>
            <Grid item>{renderAverageValidatedProductsValue()}</Grid>
          </>
        )}
      </Grid>
      {productsStatistics &&
        products &&
        (hasSales ? (
          <ProductsStatisticsSection currency={currency} hasSales products={products} statistics={productsStatistics} />
        ) : (
          <ProductsStatisticsSection
            currency={currency}
            hasSales={false}
            products={products}
            statistics={productsStatistics}
          />
        ))}
    </Section>
  );
}
