export enum NavigationTabs {
  Administration = 'administration',
  Analytics = 'analytics',
  Catalog = 'catalog',
  Events = 'events',
  HelpAndSupport = 'help-and-support',
  Home = 'home',
  Integrations = 'integrations',
  LiveShoppingPage = 'live-shopping-page',
  Playlists = 'playlists',
  Replays = 'replays',
  Shoppables = 'shoppables',
}
