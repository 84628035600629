import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import { addCatalogProductsToEvent } from '../../usecases/catalog/add-catalog-products-to-event';
import { deleteProducts } from '../../usecases/catalog/delete-products';
import { fetchProduct } from '../../usecases/catalog/fetch-product';
import { fetchProducts } from '../../usecases/catalog/fetch-products';
import { fetchHomePage } from '../../usecases/home/fetch-home-page';
import { CatalogState, INITIAL_CATALOG_STATE } from '../state/catalog';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../utils';

export const clearSelectedProducts = createAction<void>('catalog/clear-selected-products');
export const resetAddProductsToEvent = createAction<void>('catalog/reset-add-products-to-event');
export const resetDeleteProducts = createAction<void>('catalog/reset-delete-products');
export const selectProduct = createAction<CBOCatalogProduct>('catalog/select-product');
export const unselectProduct = createAction<string>('catalog/unselect-product');

const buildCrudReducers = (builder: ActionReducerMapBuilder<CatalogState>) => {
  builder.addCase(addCatalogProductsToEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('addProductsToEvent', [action.error]),
  }));
  builder.addCase(addCatalogProductsToEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('addProductsToEvent'),
  }));
  builder.addCase(addCatalogProductsToEvent.fulfilled, (state) => ({
    ...state,
    ...successStatus('addProductsToEvent'),
  }));
  builder.addCase(deleteProducts.rejected, (state, action) => ({
    ...state,
    ...errorStatus('productsDelete', [action.error]),
  }));
  builder.addCase(deleteProducts.pending, (state) => ({
    ...state,
    ...pendingStatus('productsDelete'),
  }));
  builder.addCase(deleteProducts.fulfilled, (state, action) => {
    const filteredProducts = state.products.filter((p) => !action.payload.includes(p.id));
    const deletedProductsCount = state.products.length - filteredProducts.length;

    return {
      ...state,
      ...successStatus('productsDelete'),
      pagination: {
        ...state.pagination,
        totalCount: state.pagination.totalCount - deletedProductsCount,
      },
      products: filteredProducts,
      selectedProducts: [],
    };
  });
  builder.addCase(fetchProducts.rejected, (state, action) => ({
    ...state,
    ...errorStatus('productsFetching', [action.error]),
  }));
  builder.addCase(fetchProducts.pending, (state, action) => ({
    ...state,
    ...pendingStatus('productsFetching'),
    pagination: {
      ...state.pagination,
      offset: action.meta.arg.offset,
    },
    search: action.meta.arg.search,
    sort: action.meta.arg.sort,
  }));
  builder.addCase(fetchProduct.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('productFetching'),
    currentProduct: action.payload,
  }));
  builder.addCase(fetchProduct.rejected, (state, action) => ({
    ...state,
    ...errorStatus('productFetching', [action.error]),
  }));
  builder.addCase(fetchProduct.pending, (state) => ({
    ...state,
    ...pendingStatus('productFetching'),
    currentProduct: null,
  }));
  builder.addCase(fetchProducts.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('productsFetching'),
    pagination: {
      ...state.pagination,
      totalCount: action.payload.products.totalCount,
    },
    products: action.payload.products.items,
  }));
  builder.addCase(resetAddProductsToEvent, (state) => ({
    ...state,
    ...notRequestedStatus('addProductsToEvent'),
    selectedProducts: [],
  }));
  builder.addCase(resetDeleteProducts, (state) => ({
    ...state,
    ...notRequestedStatus('productsDelete'),
    selectedProducts: [],
  }));
  builder.addCase(fetchHomePage.fulfilled, (state, action) => ({
    ...state,
    pagination: {
      offset: state.pagination.offset,
      totalCount: action.payload.productsCount,
    },
  }));
};

const buildProductsReducers = (builder: ActionReducerMapBuilder<CatalogState>) => {
  builder.addCase(clearSelectedProducts, (state) => ({
    ...state,
    selectedProducts: [],
  }));
  builder.addCase(selectProduct, (state, action) => ({
    ...state,
    selectedProducts: [...state.selectedProducts, action.payload],
  }));
  builder.addCase(unselectProduct, (state, action) => ({
    ...state,
    selectedProducts: state.selectedProducts.filter(({ id }) => id !== action.payload),
  }));
};

export const catalogSlice = createSlice({
  extraReducers: (builder) => {
    buildCrudReducers(builder);
    buildProductsReducers(builder);
  },
  initialState: INITIAL_CATALOG_STATE,
  name: 'catalog',
  reducers: {},
});
