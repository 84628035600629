import { EventStatus } from '@bellepoque/api-contracts';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PublicIcon from '@mui/icons-material/Public';
import StopIcon from '@mui/icons-material/Stop';
import { Button, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import isBellepoqueTenant from '../../../../../utils/is-bellepoque-tenant';
import HeaderEventState from '../../../atoms/event/HeaderEventState';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}));

type HeaderLiveActionsProps = {
  areButtonsDisabled: boolean;
  event: CBOEventReadModel;
  onCloseEvent: () => void;
  onOpenEvent: () => void;
  onOpenReplay: () => void;
  onPublishEvent: () => void;
  onPublishReplay: () => void;
  onUnpublishEvent: () => void;
};

export default function HeaderLiveActions({
  areButtonsDisabled,
  event,
  onCloseEvent,
  onOpenEvent,
  onOpenReplay,
  onPublishEvent,
  onPublishReplay,
  onUnpublishEvent,
}: HeaderLiveActionsProps) {
  const { t } = useTranslation(['events']);

  const renderButtons = () => {
    switch (event.status) {
      case EventStatus.PLANNED:
        return (
          <>
            <Button
              className="onboarding-tour-highlighted"
              disabled={areButtonsDisabled}
              onClick={onPublishEvent}
              size="small"
              startIcon={<PlayCircleOutlineIcon />}
              variant="contained"
            >
              {t('Publish')}
            </Button>
            <Button disabled size="small" startIcon={<PublicIcon />} variant="contained">
              {t('Open')}
            </Button>
          </>
        );
      case EventStatus.PRIVATE_PREVIEW:
        return (
          <>
            <Box>
              <Button
                color="error"
                disabled={areButtonsDisabled}
                onClick={onUnpublishEvent}
                size="small"
                startIcon={<StopIcon />}
                variant="contained"
              >
                {t('UnPublish')}
              </Button>
            </Box>
            <Box>
              <Button
                className="onboarding-tour-highlighted"
                disabled={areButtonsDisabled}
                onClick={onOpenEvent}
                size="small"
                startIcon={<PublicIcon />}
                variant="contained"
              >
                {t('Open')}
              </Button>
            </Box>
          </>
        );
      case EventStatus.ON_AIR:
        return (
          <>
            {isBellepoqueTenant(event.tenantId) ? (
              <Button
                disabled={areButtonsDisabled}
                onClick={onPublishEvent}
                size="small"
                startIcon={<PlayCircleOutlineIcon />}
                variant="contained"
              >
                {t('BackToPrivateTest')}
              </Button>
            ) : null}
            <Button
              color="error"
              disabled={areButtonsDisabled}
              onClick={onCloseEvent}
              size="small"
              startIcon={<StopIcon />}
              variant="contained"
            >
              {t('Close')}
            </Button>
          </>
        );
      case EventStatus.REPLAY_READY:
        return (
          <>
            <Button
              disabled={areButtonsDisabled}
              onClick={onPublishReplay}
              size="small"
              startIcon={<PlayCircleOutlineIcon />}
              variant="contained"
            >
              {t('PublishReplay')}
            </Button>
            <Button disabled size="small" startIcon={<PublicIcon />} variant="contained">
              {t('OpenReplay')}
            </Button>
          </>
        );
      case EventStatus.PRIVATE_REPLAY:
        return (
          <Button
            disabled={areButtonsDisabled}
            onClick={onOpenReplay}
            size="small"
            startIcon={<PublicIcon />}
            variant="contained"
          >
            {t('OpenReplay')}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Container id="header-actions">
      <HeaderEventState eventStatus={event.status} />
      {renderButtons()}
    </Container>
  );
}
