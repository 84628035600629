import { Box, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMediaCollection } from '../../../core/domain/CBOMediaCollection';
import { CommandStatus } from '../../../core/store/state/utils';
import FixedContentHeader from '../../components/molecules/FixedContentHeader';
import MediaCollectionsHeader from '../../components/molecules/media-collections/MediaCollectionsHeader';
import PlayerMediaCollectionPreviewDialog, {
  PlayerMediaCollectionPreviewParams,
} from '../../components/organisms/PlayerMediaCollectionPreviewDialog';
import UpgradePlanDialog from '../../components/organisms/UpgradePlanDialog';
import MediaCollectionList from '../../components/organisms/media-collections/MediaCollectionList';
import AddPublishedPagesToMediaCollectionDialog from '../../components/organisms/media-collections/add-published-pages-to-media-collection-dialog/AddPublishedPagesToMediaCollectionDialog';
import ConfirmationDialog from '../dialog/ConfirmationDialog';

const PREFIX = 'MediaCollectionListGrid';

const classes = {
  gridRow: `${PREFIX}-grid-row`,
};

const MediaCollectionsTemplateRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  overflow: 'hidden',

  [`& .${classes.gridRow}`]: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}2B !important`,
      cursor: 'pointer',
    },
  },
}));

const MEDIA_COLLECTION_LIST_CONTAINER_OFFSET = 14;

const MediaCollectionListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  // be sure to maintain marginTop === paddingBottom for virtual scroll shoppables works properly
  marginTop: theme.spacing(MEDIA_COLLECTION_LIST_CONTAINER_OFFSET),
  paddingBottom: theme.spacing(MEDIA_COLLECTION_LIST_CONTAINER_OFFSET),
}));

export type MediaCollectionsTemplateProps = {
  currentMediaCollection: CBOMediaCollection | null;
  deleteMediaCollectionStatus: CommandStatus;
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  mediaCollectionPagesUpdateStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onDeleteMediaCollection: (mediaCollectionId: string) => void;
  onFetchMediaCollection: (collectionId: string) => void;
  onGoToMediaCollectionEditor: () => void;
  publishedShoppablesCount: number;
  tenantName: string;
};

const MediaCollectionsTemplate: FC<MediaCollectionsTemplateProps> = ({
  currentMediaCollection,
  onDeleteMediaCollection,
  deleteMediaCollectionStatus,
  loading,
  maxPublishedShoppablesCount,
  mediaCollectionPagesUpdateStatus,
  mediaCollections,
  onFetchMediaCollection,
  onGoToMediaCollectionEditor,
  publishedShoppablesCount,
  tenantName,
}) => {
  const { t } = useTranslation(['mediaCollections', 'common']);

  const [isAddPublishedPagesToMediaCollectionDialogOpen, setIsAddPublishedPagesToMediaCollectionDialogOpen] =
    useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const [currentlyPlayedMediaCollection, setCurrentlyPlayedMediaCollection] =
    useState<PlayerMediaCollectionPreviewParams | null>(null);
  const [isPlayerMediaCollectionModalOpen, setIsPlayerMediaCollectionModalOpen] = useState<boolean>(false);
  const [mediaCollectionIdToDelete, setMediaCollectionIdToDelete] = useState<string | null>(null);

  const handleCloseAddPublishedPagesToMediaCollectionDialog = () => {
    setIsAddPublishedPagesToMediaCollectionDialogOpen(false);
  };

  const handleOpenLocationsEditor = (mediaCollectionId: string) => {
    onFetchMediaCollection(mediaCollectionId);
    setIsAddPublishedPagesToMediaCollectionDialogOpen(true);
  };

  const handleDisplayUpdgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(true);
  };

  const handleCloseUpgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const handleMediaCollectionPreviewClick = ({ mediaCollectionId, startIndex }: PlayerMediaCollectionPreviewParams) => {
    setCurrentlyPlayedMediaCollection({ mediaCollectionId, startIndex });
    setIsPlayerMediaCollectionModalOpen(true);
  };

  useEffect(() => {
    if (mediaCollectionPagesUpdateStatus === 'success') handleCloseAddPublishedPagesToMediaCollectionDialog();
  }, [mediaCollectionPagesUpdateStatus]);

  useEffect(() => {
    if (deleteMediaCollectionStatus === 'success') {
      setMediaCollectionIdToDelete(null);
    }
  }, [deleteMediaCollectionStatus]);

  return (
    <MediaCollectionsTemplateRoot>
      <FixedContentHeader isDrawerOpen isShadowHidden>
        <MediaCollectionsHeader
          loading={loading}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onGoToMediaCollectionEditor={onGoToMediaCollectionEditor}
          publishedShoppablesCount={publishedShoppablesCount}
          tenantName={tenantName}
        />
      </FixedContentHeader>
      <MediaCollectionListContainer>
        <MediaCollectionList
          loading={loading}
          mediaCollections={mediaCollections}
          onDeleteMediaCollectionClick={setMediaCollectionIdToDelete}
          onGoToMediaCollectionEditor={onGoToMediaCollectionEditor}
          onMediaCollectionPreviewClick={handleMediaCollectionPreviewClick}
          onOpenPublishedPagesEditor={handleOpenLocationsEditor}
        />
      </MediaCollectionListContainer>

      {currentMediaCollection && (
        <AddPublishedPagesToMediaCollectionDialog
          isOpen={isAddPublishedPagesToMediaCollectionDialogOpen}
          onClose={handleCloseAddPublishedPagesToMediaCollectionDialog}
          onDisplayUpdgradePlanDialog={handleDisplayUpdgradePlanDialog}
        />
      )}

      {maxPublishedShoppablesCount && (
        <UpgradePlanDialog
          isOpen={isUpgradePlanDialogOpen}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onClose={handleCloseUpgradePlanDialog}
          tenantName={tenantName}
        />
      )}

      {!!currentlyPlayedMediaCollection?.mediaCollectionId && (
        <PlayerMediaCollectionPreviewDialog
          onClose={() => setIsPlayerMediaCollectionModalOpen(false)}
          open={isPlayerMediaCollectionModalOpen}
          {...currentlyPlayedMediaCollection}
        />
      )}

      <ConfirmationDialog
        confirm={t('Delete')}
        content={t('DeleteMediaCollectionDescription')}
        id="delete-media-collection"
        keepMounted
        loading={deleteMediaCollectionStatus === 'pending'}
        onClose={(yesOrNo) =>
          yesOrNo && !!mediaCollectionIdToDelete
            ? onDeleteMediaCollection(mediaCollectionIdToDelete)
            : setMediaCollectionIdToDelete(null)
        }
        open={!!mediaCollectionIdToDelete}
        refuse={t('common:Cancel')}
        title={t('DeleteMediaCollection')}
      />
    </MediaCollectionsTemplateRoot>
  );
};

export default MediaCollectionsTemplate;
