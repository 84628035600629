import { Box, Button, ButtonGroup, Typography, styled } from '@mui/material';
import React from 'react';

interface SwitchButtonProps {
  label: string;
  onChange: (value: boolean) => void;
  option1: string;
  option2: string;
  value: boolean;
}

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const SwitchButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.black,
    },
    backgroundColor: isSelected ? theme.palette.primary.light : theme.palette.grey[50],
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.common.black,

    fontWeight: 'bold',
  }),
);

export default function SwitchButtons({ label, value, onChange, option1, option2 }: SwitchButtonProps) {
  return (
    <Container>
      <Typography component="span" fontWeight="bold">
        {label}
      </Typography>

      <ButtonGroup
        aria-label="outlined primary button group"
        color="primary"
        sx={{
          marginLeft: (theme) => theme.spacing(2),
        }}
      >
        <SwitchButton
          isSelected={value}
          onClick={!value ? () => onChange(true) : undefined}
          size="large"
          variant="contained"
        >
          {option1}
        </SwitchButton>
        <SwitchButton
          isSelected={!value}
          onClick={value ? () => onChange(false) : undefined}
          size="large"
          variant="contained"
        >
          {option2}
        </SwitchButton>
      </ButtonGroup>
    </Container>
  );
}
