import { EventId, V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { MagicContentGateway } from '../../core/gateways/magic-content-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiMagicContentGateway extends ApiGateway implements MagicContentGateway {
  async fetchByEvent(eventId: EventId): Promise<V1.api.GetMagicContentsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery({
        url: `${this.apiEndpoint}/replays/${eventId}/magic-contents`,
      }),
    );
  }

  async generate(eventId: EventId): Promise<V1.api.GenerateMagicContentResponseDTO> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: {},
        url: `${this.apiEndpoint}/replays/${eventId}/magic-contents`,
        verb: 'post',
      }),
    );
  }
}
