import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import {
  UpdateReplayRequest,
  resetUpdateReplay,
  updateReplay,
} from '../../../../../core/usecases/event/crud/update-replay';
import { getSecuredReplayFileUploadUrls } from '../../../../../core/usecases/event/files/get-secured-replay-file-upload-urls';
import { uploadReplayFiles } from '../../../../../core/usecases/event/files/upload-replay-files';
import { fetchLandingPage } from '../../../../../core/usecases/landing-page/fetch-landing-page';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createReplayLinksViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { name: currentTenantName, id: currentTenantId } = state.tenants.currentTenant;
    const {
      currentEvent,
      currentEventRealtimeData: eventRealtimeData,
      replayFilesUploading,
      replayFileUploadUrlsFetching,
      replayFileUploadUrls,
      replayUpdate,
    } = state.events;
    const { hasCmsLandingPage, landingPageData } = state.landingPage;
    const eventId = currentEvent?.id;
    const landingPageUrl = landingPageData.landingPageUrl;
    const replayFileUrlsFetchingStatus = replayFileUploadUrlsFetching.status;
    const updateReplayStatus = replayUpdate.status;
    const uploadFileStatus = replayFilesUploading.status;

    return {
      currentTenantId,
      currentTenantName,
      eventRealtimeData,
      fetchFileUploadUrl: () => {
        if (eventId) {
          dispatch(getSecuredReplayFileUploadUrls({ replayId: eventId }));
        }
      },
      fetchLandingPageData: () => {
        dispatch(fetchLandingPage({ tenantId: currentTenantId }));
      },
      fetchUploadUrlStatus: replayFileUrlsFetchingStatus,
      hasCmsLandingPage,
      landingPageUrl,
      replayFileUploadUrls,
      resetUpdateReplay: () => {
        dispatch(resetUpdateReplay());
      },
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateReplay: (payload: UpdateReplayRequest['payload']) => {
        if (eventId) {
          dispatch(updateReplay({ id: eventId, payload }));
        }
      },
      updateReplayStatus,
      uploadFile: (file: File) => {
        if (replayFileUploadUrls && eventId) {
          dispatch(
            uploadReplayFiles({
              eventId,
              files: {
                cover: {
                  file,
                  url: replayFileUploadUrls.coverUrl,
                },
              },
            }),
          );
        }
      },
      uploadFileStatus,
    };
  };
