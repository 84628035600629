import { Typography, styled } from '@mui/material';
import React from 'react';

const Styled = styled(Typography)({
  color: 'black',
  fontSize: '1.4em',
  fontWeight: 'bold',
  margin: 0,
  textAlign: 'left',
});

interface SectionTitleProps {
  content: string;
}

export default function SectionTitle({ content }: SectionTitleProps) {
  return <Styled variant="h2">{content}</Styled>;
}
