import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { fetchReplaysChapters } from '../../../usecases/event/chapters/fetch-replays-chapters';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, pendingStatus } from '../../utils';

export const builderChaptersReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchReplaysChapters.fulfilled, (state, action) => {
    const chapters = action.payload;

    return {
      ...state,
      ...loadedStatus('replaysChaptersFetching'),
      events: state.events.map((event) => (chapters[event.id] ? { ...event, chapters: chapters[event.id] } : event)),
    };
  });
  builder.addCase(fetchReplaysChapters.pending, (state) => ({
    ...state,
    ...pendingStatus('replaysChaptersFetching'),
  }));
  builder.addCase(fetchReplaysChapters.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replaysChaptersFetching', [action.error]),
  }));
};
