import { NavigationTabs } from '../../../ui/navigation-tabs';
import { nextTutorialStep as nextTutorialStepAction } from '../../store/slices/tutorial.slice';

export type NextTutorialStepParams = {
  highlightedTab: NavigationTabs | null;
};

export const nextTutorialStep = (params: NextTutorialStepParams) => {
  return nextTutorialStepAction(params);
};
