import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { fetchProducts } from '../../../../../core/usecases/catalog/fetch-products';
import {
  UpdateMediaCollectionPagesRequest,
  resetUpdateMediaCollectionPages,
  updateMediaCollectionPages,
} from '../../../../../core/usecases/media-collections/update-media-collection-pages';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { DISPLAYED_PRODUCT_PAGES_ROWS } from './AddPublishedPagesToMediaCollectionDialog';

// TODO: Test view model
export const createAddPublishedPagesToMediaCollectionDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { activeSubscriptionId, id: tenantId } = state.tenants.currentTenant;
    const { products } = state.catalog;
    const { currentMediaCollection, mediaCollections } = state.mediaCollections;
    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    const canPublishOnHomePage = BILLING_PLANS[currentPlanId].canPublishOnHomePage ?? false;
    const maxPublishedShoppablesCount = BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null;

    return {
      canPublishOnHomePage,
      currentMediaCollection,
      fetchOneMediaCollectionStatus: state.mediaCollections.mediaCollectionFetching.status,
      fetchProducts: (search: string) => {
        dispatch(
          fetchProducts({
            limit: DISPLAYED_PRODUCT_PAGES_ROWS,
            offset: 0,
            search,
            sort: 'product.title',
            tenantId,
          }),
        );
      },
      maxPublishedShoppablesCount,
      mediaCollections,
      pages: state.shopify.pages,
      productFetchingSearchTerm: state.catalog.search,
      products,
      productsFetchingStatus: state.catalog.productsFetching.status,
      resetUpdateMediaCollectionPages: () => {
        dispatch(resetUpdateMediaCollectionPages());
      },
      tenantId: state.tenants.currentTenant.id,
      tenantName: state.tenants.currentTenant.name,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateMediaCollectionPages: (payload: UpdateMediaCollectionPagesRequest['payload']) => {
        if (currentMediaCollection)
          dispatch(updateMediaCollectionPages({ mediaCollectionId: currentMediaCollection.id, payload, tenantId }));
      },
      updateMediaCollectionPagesStatus: state.mediaCollections.mediaCollectionPagesUpdate.status,
    };
  };
