import { TenantId } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
} from '../../../domain/CBOShoppableVideoReadModel';
import { ShoppablesState } from '../../state/shoppables';
import { errorStatus, loadedStatus, pendingStatus } from '../../utils';

export const synchronizeOneShoppableFulfilled = createAction<CBOShoppableVideoListItemReadModel>(
  'shoppables/synchronize-one-shoppable-fulfilled',
);
export const synchronizeOneShoppablePending = createAction<{ shoppableId: string; tenantId: TenantId }>(
  'shoppables/synchronize-one-shoppable-pending',
);
export const synchronizeOneShoppableRejected = createAction<any[]>('shoppables/synchronize-one-shoppable-rejected');

export const synchronizeShoppablesFulfilled = createAction<CBOShoppableVideoListReadModel>(
  'shoppables/synchronize-shoppables-fulfilled',
);
export const synchronizeShoppablesPending = createAction<{ force: boolean; tenantId: TenantId }>(
  'shoppables/synchronize-shoppables-pending',
);
export const synchronizeShoppablesRejected = createAction<any[]>('shoppables/synchronize-shoppables-rejected');

export type ShoppableSynchronizeActions =
  | ReturnType<typeof synchronizeOneShoppableFulfilled>
  | ReturnType<typeof synchronizeOneShoppablePending>
  | ReturnType<typeof synchronizeOneShoppableRejected>
  | ReturnType<typeof synchronizeShoppablesFulfilled>
  | ReturnType<typeof synchronizeShoppablesPending>
  | ReturnType<typeof synchronizeShoppablesRejected>;

export const buildSynchronizeReducer = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(synchronizeOneShoppableFulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('shoppableFetching'),
    currentShoppable: action.payload,
  }));
  builder.addCase(synchronizeOneShoppablePending, (state, action) => {
    if (action.payload.shoppableId === state.currentShoppable?.id) {
      return state;
    }
    return {
      ...state,
      currentShoppable: null,
      fileUploadUrl: null,
      ...pendingStatus('shoppableFetching'),
    };
  });
  builder.addCase(synchronizeOneShoppableRejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableFetching', action.payload),
    currentShoppable: null,
  }));
  builder.addCase(synchronizeShoppablesFulfilled, (state, action) => {
    const currentShoppableInList = action.payload.find(({ id }) => id === state.currentShoppable?.id) ?? null;

    return {
      ...state,
      ...loadedStatus('shoppablesFetching'),
      currentShoppable: currentShoppableInList,
      shoppables: action.payload,
    };
  });
  builder.addCase(synchronizeShoppablesPending, (state, action) => {
    if (state.shoppablesFetching.status === 'loaded' && action.payload.tenantId === state.shoppablesTenantId) {
      return state;
    }

    return {
      ...state,
      ...pendingStatus('shoppablesFetching'),
      shoppablesTenantId: action.payload.tenantId,
    };
  });
  builder.addCase(synchronizeShoppablesRejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppablesFetching', action.payload),
    shoppables: [],
    shoppablesTenantId: '',
  }));
};
