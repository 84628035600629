import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import KlaviyoIcon from '../../../icons/KlaviyoIcon';
import NappsIcon from '../../../icons/NappsIcon';
import TapcartIcon from '../../../icons/TapcartIcon';
import { TabProps } from '../../components/molecules/Tabs';
import KlaviyoIntegration from '../../components/organisms/integrations/klaviyo/KlaviyoIntegration';
import NappsIntegration from '../../components/organisms/integrations/napps/NappsIntegration';
import TapcartIntegration from '../../components/organisms/integrations/tapcart/TapcartIntegration';
import { routes } from '../../routes';
import IntegrationsTemplate from '../../templates/IntegrationsTemplate';
import { createIntegrationsViewModel } from './Integrations.viewmodel';

enum TabsNumber {
  Klaviyo = 1,
  Napps = 2,
  Tapcart = 3,
}

export default function Integrations() {
  const isKlaviyoIntegrationPageActive = useMatch(routes.integrations.klaviyo);
  const isNappsIntegrationPageActive = useMatch(routes.integrations.napps);
  const isTapcartIntegrationPageActive = useMatch(routes.integrations.tapcart);

  const viewModel = useSelector(createIntegrationsViewModel({ dispatch: useDispatch() }));
  const { currentTenant, trackUserJourneyEvent } = viewModel;

  const { t } = useTranslation('integrations');
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(() => {
    if (isKlaviyoIntegrationPageActive) return TabsNumber.Klaviyo;
    if (isNappsIntegrationPageActive) return TabsNumber.Napps;
    if (isTapcartIntegrationPageActive) return TabsNumber.Tapcart;
    return TabsNumber.Klaviyo;
  });

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
      name: 'Integrations',
    });
  }, []);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) navigate(tab.to);
  }, [activeTab]);

  const tabs: TabProps[] = [
    {
      component: <KlaviyoIntegration />,
      enabled: true,
      icon: <KlaviyoIcon />,
      index: TabsNumber.Klaviyo,
      label: t('Klaviyo.Klaviyo'),
      to: routes.integrations.klaviyo,
    },
    {
      component: <NappsIntegration />,
      enabled: true,
      icon: <NappsIcon />,
      index: TabsNumber.Napps,
      label: t('Napps.Napps'),
      releaseStatus: 'new',
      to: routes.integrations.napps,
    },
    {
      component: <TapcartIntegration />,
      enabled: true,
      icon: <TapcartIcon />,
      index: TabsNumber.Tapcart,
      label: t('Tapcart.Tapcart'),
      to: routes.integrations.tapcart,
    },
  ];
  return <IntegrationsTemplate activeTab={activeTab} onChangeTab={setActiveTab} tabs={tabs} />;
}
