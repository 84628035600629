import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { checkPlayerPlaylistsBlockIsAdded } from '../../../usecases/shoppables/check-player-playlists-block-is-added';
import { ShoppablesState } from '../../state/shoppables';
import { errorStatus, loadedStatus, pendingStatus } from '../../utils';

export const buildPlayerPlaylistsBlockReducers = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(checkPlayerPlaylistsBlockIsAdded.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('playerPlaylistsBlockIsAddedFetching'),
    playerPlaylistsBlockIsAdded: action.payload,
  }));
  builder.addCase(checkPlayerPlaylistsBlockIsAdded.pending, (state) => ({
    ...state,
    ...pendingStatus('playerPlaylistsBlockIsAddedFetching'),
  }));
  builder.addCase(checkPlayerPlaylistsBlockIsAdded.rejected, (state, action) => ({
    ...state,
    ...errorStatus('playerPlaylistsBlockIsAddedFetching', [action.error]),
  }));
};
