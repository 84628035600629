import { V1 } from '@bellepoque/api-contracts';

import { CBOTenant } from '../../core/domain/CBOTenant';

export function tenantDtoToDomain(dto: V1.api.TenantDTO): CBOTenant {
  return {
    activeSubscriptionId: dto.activeSubscriptionId ?? 'live_access',
    alias: dto.alias,
    apiKey: dto.apiKey,
    cms: dto.cms,
    createdAt: new Date(dto.createdAt),
    currency: dto.currency,
    hasCatalogProducts: dto.hasCatalogProducts,
    id: dto.id,
    integrations: {
      ...dto.integrations,
    },
    name: dto.name,
  };
}

export function manyTenantDtosToDomain(dtos: V1.api.TenantDTO[]): CBOTenant[] {
  return dtos.map(tenantDtoToDomain);
}
