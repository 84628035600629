import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventTrafficStatistics, CBOLiveReplayCount } from '../../../../core/domain/CBOTenantStatistics';
import { Section } from '../../../templates/analytics';
import SectionTitle from '../../atoms/analytics/SectionTitle';
import StatsItem, { CustomStatsItem } from '../../molecules/analytics/StatsItem';

type EventTrafficSectionProps = {
  statistics: CBOEventTrafficStatistics;
  uniqueEvent?: boolean;
};

export default function EventTrafficSection({ statistics, uniqueEvent }: EventTrafficSectionProps) {
  const { t } = useTranslation('analytics', { keyPrefix: 'Traffic' });

  const deviceCountsTotal = useMemo(
    () => statistics.deviceCounts.desktop + statistics.deviceCounts.mobile + statistics.deviceCounts.tablet,
    [statistics.deviceCounts],
  );

  const getAverageForLiveAndReplay = (
    { live, replay }: CBOLiveReplayCount,
    uniquerViewers: CBOLiveReplayCount,
  ): number => {
    if (!replay) {
      return live;
    }
    return (
      (live * uniquerViewers.live + replay * uniquerViewers.replay) / (uniquerViewers.live + uniquerViewers.replay)
    );
  };

  return (
    <Section boxShadow={2}>
      <SectionTitle content={t('Title')} />

      <Grid columnGap={9} container flexDirection="row" mt={2} rowGap={5}>
        <Grid item>
          <StatsItem
            format="number"
            livePercentage={
              statistics.uniqueViewers.live / (statistics.uniqueViewers.live + statistics.uniqueViewers.replay)
            }
            liveValue={statistics.uniqueViewers.live}
            replayPercentage={
              statistics.uniqueViewers.replay / (statistics.uniqueViewers.live + statistics.uniqueViewers.replay)
            }
            replayValue={statistics.uniqueViewers.replay}
            title={t('UniqueViewers')}
            totalValue={statistics.uniqueViewers.live + statistics.uniqueViewers.replay}
          />
        </Grid>
        <Grid item>
          <StatsItem
            format="number"
            liveValue={statistics.views.live}
            replayValue={statistics.views.replay}
            title={t('Views')}
            totalValue={statistics.views.live + statistics.views.replay}
          />
        </Grid>
        <Grid item>
          <CustomStatsItem
            format="percentage"
            isNotApplicable={
              statistics.deviceCounts.desktop === 0 &&
              statistics.deviceCounts.mobile === 0 &&
              statistics.deviceCounts.tablet === 0
            }
            isTotalHidden
            title={t('Devices.Title')}
            values={[
              {
                text: t('Devices.Desktop'),
                value: statistics.deviceCounts.desktop / (deviceCountsTotal || 1),
              },
              {
                text: t('Devices.Mobile'),
                value: statistics.deviceCounts.mobile / (deviceCountsTotal || 1),
              },
              {
                text: t('Devices.Tablet'),
                value: statistics.deviceCounts.tablet / (deviceCountsTotal || 1),
              },
            ]}
          />
        </Grid>
        {!uniqueEvent && (
          <Grid item>
            <StatsItem format="time" title={t('TotalLiveDuration')} totalValue={statistics.totalLiveDuration} />
          </Grid>
        )}
        <Grid item>
          <StatsItem
            format="number"
            title={t('MaxSimultaneousViewers')}
            totalValue={statistics.maxSimultaneousViewers}
          />
        </Grid>
        <Grid item>
          <StatsItem
            format="time"
            liveValue={statistics.averageAttendanceTime.live}
            replayValue={statistics.averageAttendanceTime.replay}
            title={t('AverageAttendanceTime')}
            totalValue={getAverageForLiveAndReplay(statistics.averageAttendanceTime, statistics.uniqueViewers)}
          />
        </Grid>
        <Grid item>
          <StatsItem
            format="time"
            livePercentage={
              statistics.totalAttendanceTime.live /
              (statistics.totalAttendanceTime.live + statistics.totalAttendanceTime.replay)
            }
            liveValue={statistics.totalAttendanceTime.live}
            replayPercentage={
              statistics.totalAttendanceTime.replay /
              (statistics.totalAttendanceTime.live + statistics.totalAttendanceTime.replay)
            }
            replayValue={statistics.totalAttendanceTime.replay}
            title={t('TotalAttendanceTime')}
            totalValue={statistics.totalAttendanceTime.live + statistics.totalAttendanceTime.replay}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
