import { OnboardingStep } from '@bellepoque/api-contracts';
import { Box, Button, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ONBOARDING_SEQUENCE } from '../../../pages/onboarding/Onboarding';
import StepperIcon from '../../atoms/StepperIcon';

const ButtonsContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'center',
});

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderTop: `solid ${theme.palette.grey[200]} 1px`,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const StepperContainer = styled(Box)({
  flex: 2.5,
  height: '100%',
});

interface OnboardingTemplateProps {
  buttonsDisabled: boolean;
  currentSequenceIndex: number;
  isBackButtonShown: boolean;
  isNextButtonEnabled: boolean;
  isNextButtonShown: boolean;
  onGoToNextStep: () => void;
  onGoToPreviousStep: () => void;
}

export default function OnboardingFooter({
  buttonsDisabled,
  currentSequenceIndex,
  isBackButtonShown,
  isNextButtonEnabled,
  isNextButtonShown,
  onGoToNextStep,
  onGoToPreviousStep,
}: OnboardingTemplateProps) {
  const { t } = useTranslation(['common', 'onboarding']);

  const getLabelForSequenceIndex = useCallback(
    (sequenceIndex: number) => {
      const step = ONBOARDING_SEQUENCE.get(sequenceIndex);
      switch (step) {
        case OnboardingStep.CreateEvent:
          return t('onboarding:Steps.AddPhoneNumber.Label');
        case OnboardingStep.AddProducts:
          return t('onboarding:Steps.AddProducts.Label');
        case OnboardingStep.CameraApp:
          return t('onboarding:Steps.CameraApp.Label');
        case OnboardingStep.PrivateTest:
          return t('onboarding:Steps.PrivatePreview');
        case OnboardingStep.OpenToPublic:
          return t('onboarding:Steps.OpenToPublic');
      }
    },
    [t],
  );

  return (
    <Container>
      <Box flex={1} />
      <StepperContainer>
        <Stepper activeStep={currentSequenceIndex} alternativeLabel>
          {Array.from(ONBOARDING_SEQUENCE).map((sequence) => (
            <Step completed={currentSequenceIndex > sequence[0]} key={sequence[1]}>
              <StepLabel StepIconComponent={StepperIcon}>
                <Typography fontSize="1em" variant="titleFont">
                  {getLabelForSequenceIndex(sequence[0])}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperContainer>
      <ButtonsContainer>
        {isBackButtonShown && (
          <Button disabled={buttonsDisabled} onClick={onGoToPreviousStep} variant="text">
            {t('common:Back')}
          </Button>
        )}
        {isNextButtonShown && (
          <Button
            disabled={!isNextButtonEnabled}
            onClick={onGoToNextStep}
            size="large"
            sx={{ width: '125px' }}
            variant="contained"
          >
            {t('common:Next')}
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
}
