import { EventId } from '@bellepoque/api-contracts';

import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type DisplayProductsParams = {
  eventId: EventId;
  productToDisplay: CBOEventReadModelProduct;
};

export const displayProduct = createAppAsyncThunk(
  'events/display-product',
  async (
    { eventId, productToDisplay }: DisplayProductsParams,
    { extra: { eventGateway } },
  ): Promise<CBOEventReadModelProduct> => {
    await eventGateway.displayProduct(eventId, { productId: productToDisplay.id });
    return productToDisplay;
  },
);
