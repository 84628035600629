import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { EventDisplayedTime, EventDuration } from '../../../atoms/event/DisplayedValue';
import HeaderCurrentTime from '../../../atoms/event/HeaderCurrentTime';
import HeaderTitle from '../../../atoms/event/HeaderTitle';

interface ThemeHeaderProps {
  event: CBOEventReadModel;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '4fr 5fr',
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(2),
}));

const LeftSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: theme.spacing(2),
}));

export default function ThemeHeader({ event }: ThemeHeaderProps) {
  if (!event) return null;
  const isEventTimeDisplayed = event.startTime || event.showTime;

  return (
    <Container>
      <LeftSideContainer gridTemplateColumns={isEventTimeDisplayed ? '2fr 3fr' : '4fr 2fr'}>
        <Box alignItems="center" display="flex" textAlign="left">
          <HeaderTitle event={event} />
        </Box>

        <Box display="flex" gap={1} justifyContent="space-evenly">
          <EventDisplayedTime event={event} />
          <EventDuration event={event} />
        </Box>
      </LeftSideContainer>
      <Box display="flex">
        <HeaderCurrentTime />
      </Box>
    </Container>
  );
}
