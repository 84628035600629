import { Box, FormControl, Paper, Popover, Typography, styled } from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { Controller } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { FieldError } from 'react-hook-form/dist/types/errors';

const PREFIX = 'ReachHookFormImageSelector';

const classes = {
  outlinedLabel: `${PREFIX}-outlinedLabel`,
  root: `${PREFIX}-root`,
};

const StyledPaper = styled(Paper)({
  textAlign: 'initial',

  [`& .${classes.root}`]: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
});

const EllipsisContainer = styled(Box)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const StyledLabel = styled(Typography)({
  fontSize: '.8em',
  fontWeight: 'bold',
  marginBottom: '5px',
  textAlign: 'initial',
});

const ColorDisplay = styled('div', { shouldForwardProp: (prop) => prop !== 'backgroundColor' })<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  backgroundColor,
  borderRadius: '100%',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  height: '25px',
  padding: '5px',
  width: '25px',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 12px',
}));

const ColorString = styled('span')(({ theme }) => ({ color: theme.palette.grey[800] }));
interface ColorPickerProps extends Pick<UseFormReturn<any>, 'control' | 'watch'> {
  disabled: boolean;
  error?: FieldError;
  label: string;
  name: string;
}

export default function ReactHookFormColorPicker(props: ColorPickerProps) {
  const { control, disabled, error, label, name, watch } = props;
  const currentColor = watch(name);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget as Element);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorToRgbaString = (color: any): string => {
    if (typeof color === 'string') return color;
    const alpha = typeof color.rgb.a === 'number' && color.rgb.a < 1 ? color.rgb.a.toFixed(1) : 1;
    const rgbStr = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${alpha})`;
    return rgbStr;
  };

  const renderPopover = () => {
    return (
      <FormControl error={!!error} style={{ display: Boolean(anchorEl) ? 'block' : 'none' }} variant="outlined">
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Popover
              {...field}
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              aria-describedby="component-error-text"
              id={name}
              onChange={undefined}
              onClose={handleClose}
              open={Boolean(anchorEl)}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
            >
              <SketchPicker
                {...field}
                color={field.value}
                onChange={(color: ColorResult) => {
                  field.onChange(colorToRgbaString(color));
                }}
              />
            </Popover>
          )}
        />
      </FormControl>
    );
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <StyledLabel>{label}</StyledLabel>
      <StyledPaper elevation={0} style={{ flexGrow: 1 }} variant="elevation">
        <ContentContainer
          onClick={handleClick}
          sx={{
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          <EllipsisContainer>
            <ColorString>{colorToRgbaString(currentColor)}</ColorString>
          </EllipsisContainer>
          <ColorDisplay backgroundColor={currentColor} />
        </ContentContainer>
        {renderPopover()}
      </StyledPaper>
    </Box>
  );
}
