import { V1 } from '@bellepoque/api-contracts';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { lengthRules } from '../../../../../utils/validation';
import StickyActionBarWrapper from '../../../../templates/StickyActionBarWrapper';
import SettingsActionBar from '../../../molecules/event/FormActionBar';
import ChatSettingsSection from '../../../molecules/event/settings/ChatSettingsSection';
import EventSettingsSection from '../../../molecules/event/settings/EventSettingsSection';
import MessagesSettingsSection from '../../../molecules/event/settings/MessagesSettingsSection';

const constraints = V1.api.constraints.events;

const PREFIX = 'SettingsForm';

const classes = {
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',

  [`& .${classes.errorMessage}`]: {
    color: 'red',
  },
}));

export type UpdateEventInputs = Pick<
  CBOEventReadModel,
  | 'chatIntroductionText'
  | 'description'
  | 'moderatorMessageLabel'
  | 'postEventText'
  | 'preEventText'
  | 'publisherPhone'
  | 'showTime'
  | 'title'
>;

interface SettingsFormProps {
  event: CBOEventReadModel;
  onDeleteEvent: () => void;
  onUpdateEvent: (values: UpdateEventInputs) => void;
  readonly: boolean;
  submissionInProgress: boolean;
}

export type SettingsFormInputs = Pick<
  CBOEventReadModel,
  | 'chatIntroductionText'
  | 'description'
  | 'moderatorMessageLabel'
  | 'postEventText'
  | 'preEventText'
  | 'showTime'
  | 'title'
> & {
  publisherPhone: string;
};

export default function SettingsForm({
  event,
  onDeleteEvent,
  onUpdateEvent,
  readonly,
  submissionInProgress,
}: SettingsFormProps) {
  const defaultValues = (event: CBOEventReadModel): SettingsFormInputs => ({
    chatIntroductionText: event.chatIntroductionText || '',
    description: event.description || '',
    moderatorMessageLabel: event.moderatorMessageLabel || '',
    postEventText: event.postEventText || '',
    preEventText: event.preEventText || '',
    publisherPhone: event.publisherPhone || '',
    showTime: event.showTime || null,
    title: event.title,
  });

  const { t } = useTranslation(['events', 'common']);
  const { register, control, handleSubmit, formState, reset, watch } = useForm<SettingsFormInputs>({
    defaultValues: defaultValues(event),
  });
  const { errors, isDirty } = formState;

  useEffect(() => {
    resetForm();
  }, [event, reset]);

  const onCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    reset(defaultValues(event));
  };

  const onSubmit = (data: SettingsFormInputs) => {
    onUpdateEvent({
      chatIntroductionText: data.chatIntroductionText,
      description: data.description,
      moderatorMessageLabel: data.moderatorMessageLabel,
      postEventText: data.postEventText,
      preEventText: data.preEventText,
      publisherPhone: data.publisherPhone,
      showTime: data.showTime ? new Date(data.showTime.setSeconds(0)) : null,
      title: data.title,
    });
  };

  const renderEventSettingsSection = () => {
    const { ref: titleInputRef, ...titleInputProps } = register('title', {
      maxLength: {
        message: t('common:MaxCharsWithCount', { count: constraints.title.minLength }),
        value: constraints.title.maxLength,
      },
      minLength: {
        message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
        value: constraints.title.minLength,
      },
      required: { message: t('TitleIsRequired'), value: true },
    });
    const { ref: descriptionInputRef, ...descriptionInputProps } = register('description', {
      maxLength: {
        message: t('common:MaxCharsWithCount', { count: constraints.description.maxLength }),
        value: constraints.description.maxLength,
      },
      minLength: {
        message: t('common:MinCharsWithCount', { count: constraints.description.minLength }),
        value: constraints.description.minLength,
      },
      required: { message: t('DescriptionIsRequired'), value: true },
    });

    return (
      <EventSettingsSection
        control={control}
        descriptionInputProps={descriptionInputProps}
        descriptionInputRef={descriptionInputRef}
        descriptionWatch={watch('description')}
        errors={errors}
        readonly={readonly}
        submissionInProgress={submissionInProgress}
        titleInputProps={titleInputProps}
        titleInputRef={titleInputRef}
        titleWatch={watch('title')}
      />
    );
  };

  const renderMessagesSettingsSection = () => {
    const { ref: preEventTextInputRef, ...preEventTextInputProps } = register(
      'preEventText',
      lengthRules(constraints.postEventText.minLength, constraints.preEventText.maxLength, t),
    );
    const { ref: postEventTextInputRef, ...postEventTextInputProps } = register(
      'postEventText',
      lengthRules(constraints.postEventText.minLength, constraints.postEventText.maxLength, t),
    );

    return (
      <MessagesSettingsSection
        errors={errors}
        postEventTextInputProps={postEventTextInputProps}
        postEventTextInputRef={postEventTextInputRef}
        postEventTextInputWatch={watch('postEventText')}
        preEventTextInputProps={preEventTextInputProps}
        preEventTextInputRef={preEventTextInputRef}
        preEventTextInputWatch={watch('preEventText')}
        readonly={readonly}
        submissionInProgress={submissionInProgress}
      />
    );
  };

  const renderChatSettingsSection = () => {
    const { ref: chatIntroductionTextInputRef, ...chatIntroductionTextInputProps } = register(
      'chatIntroductionText',
      lengthRules(constraints.chatIntroductionText.minLength, constraints.chatIntroductionText.maxLength, t),
    );

    const { ref: moderatorMessageLabelInputRef, ...moderatorMessageLabelInputProps } = register(
      'moderatorMessageLabel',
      lengthRules(constraints.moderatorMessageLabel.minLength, constraints.moderatorMessageLabel.maxLength, t),
    );

    return (
      <ChatSettingsSection
        chatIntroductionTextInputProps={chatIntroductionTextInputProps}
        chatIntroductionTextInputRef={chatIntroductionTextInputRef}
        chatIntroductionTextInputWatch={watch('chatIntroductionText')}
        errors={errors}
        moderatorMessageLabelInputProps={moderatorMessageLabelInputProps}
        moderatorMessageLabelInputRef={moderatorMessageLabelInputRef}
        moderatorMessageLabelInputWatch={watch('moderatorMessageLabel')}
        readonly={readonly}
        submissionInProgress={submissionInProgress}
      />
    );
  };

  const renderActionBar = () => (
    <StickyActionBarWrapper inProgress={submissionInProgress}>
      <SettingsActionBar
        buttonsDisabled={submissionInProgress}
        isFormDirty={isDirty}
        onCancel={onCancel}
        onDelete={onDeleteEvent}
      />
    </StickyActionBarWrapper>
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
      }}
    >
      <Root id="event-form">
        <Grid container flex={1} justifyContent="center" p={3}>
          <Grid item lg={8} md={10} xs={12}>
            {renderEventSettingsSection()}
            {renderMessagesSettingsSection()}
            {renderChatSettingsSection()}
          </Grid>
        </Grid>
        {!readonly && renderActionBar()}
      </Root>
    </form>
  );
}
