import { V1 } from '@bellepoque/api-contracts';

export type CBOShoppableVideoListItemReadModel = Omit<V1.cbo.CBOShoppableVideoListItemReadModelDTO, 'createdAt'> & {
  createdAt: Date;
};
export type CBOShoppableVideoListReadModel = CBOShoppableVideoListItemReadModel[];

export function toCBOShoppableVideoListReadModel(
  dtos: V1.cbo.CBOShoppableVideoListItemReadModelDTO[],
): CBOShoppableVideoListReadModel {
  return dtos.map(toCBOShoppableVideoListItemReadModel);
}

export function toCBOShoppableVideoListItemReadModel(
  dto: V1.cbo.CBOShoppableVideoListItemReadModelDTO,
): CBOShoppableVideoListItemReadModel {
  return {
    ...dto,
    createdAt: new Date(dto.createdAt),
  };
}
