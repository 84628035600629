import { CBOLandingPageData } from '../../domain/CBOLandingPageData';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type UpdateLandingPageParams = {
  payload: Omit<CBOLandingPageData, 'landingPageUrl'>;
  tenantId: string;
};

export const updateLandingPage = createAppAsyncThunk(
  'landing-page/update',
  async (
    { tenantId, payload }: UpdateLandingPageParams,
    { extra: { landingPageGateway } },
  ): Promise<CBOLandingPageData> => {
    return landingPageGateway.updateOne(tenantId, payload);
  },
);
