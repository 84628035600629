import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Checkbox, Grid, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProductVariant } from '../../../../../core/domain/CBOEventReadModelProductOptions';
import { getIndexOfFirstNotRemoved } from '../../../../../utils/variants';
import VariantsHeader from '../../../atoms/event/VariantHeader';
import SettingsSection from '../../SettingsSection';
import VariantLine from '../VariantLine';

interface PreviewSectionProps {
  currency: CBOCurrency;
  defaultSamePricesChecked: boolean;
  readonly: boolean;
}

const VariantsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginLeft: `-${theme.spacing(3)}`,
  marginRight: `-${theme.spacing(3)}`,
  maxWidth: 'initial',
  width: `calc(100% + ${theme.spacing(3)} * 2)`,
}));

export default function PreviewSection(props: PreviewSectionProps) {
  const { t } = useTranslation(['products', 'events']);
  const { currency, readonly, defaultSamePricesChecked } = props;
  const [samePricesCheckedDisplay, setSamePricesCheckedDisplay] = useState(defaultSamePricesChecked);
  const { register, setValue, watch } = useFormContext();

  const samePricesChecked = watch('options.samePricesChecked');
  const variants: CBOEventReadModelProductVariant[] = watch('options.variants');
  const indexOfFirstNotRemoved = getIndexOfFirstNotRemoved(variants);

  useEffect(() => {
    setSamePricesCheckedDisplay(samePricesChecked);
  }, [samePricesChecked]);

  useEffect(() => {
    const uniqPrice = variants[indexOfFirstNotRemoved]?.price || 0;
    const uniqCrossedOutPrice = variants[indexOfFirstNotRemoved]?.crossedOutPrice;

    variants.forEach((_variant, index) => {
      if (samePricesChecked) {
        setValue(`options.variants.${index}.price`, uniqPrice, {
          shouldDirty: false,
        });
        setValue(`options.variants.${index}.crossedOutPrice`, uniqCrossedOutPrice, { shouldDirty: false });
      }
    });
  }, [samePricesChecked, variants[indexOfFirstNotRemoved]?.price]);

  return (
    <SettingsSection
      altBackground
      contentWidth={12}
      icon={<VisibilityOutlinedIcon sx={{ color: (theme) => theme.palette.common.white }} />}
      roundBottom
      title={t('events:productDetails.Preview')}
      width={12}
    >
      <Grid container justifyContent="center" marginLeft={1.5} spacing={2}>
        <Grid display="flex" item xs={11}>
          <label htmlFor="samePricesChecked" style={readonly ? {} : { cursor: 'pointer' }}>
            <Checkbox
              checked={samePricesCheckedDisplay}
              color="primary"
              disabled={readonly}
              id="samePricesChecked"
              sx={{ pl: 0 }}
              {...register('options.samePricesChecked')}
            />
            <span>{t('AllVariantsHaveSamePrices')}</span>
          </label>
        </Grid>
      </Grid>
      <VariantsContainer>
        {variants && (
          <Box marginBottom="8px" width="100%">
            <VariantsHeader />
          </Box>
        )}
        {variants.map((variant, index) => (
          <Box
            key={variant.option1ValueId + (variant.option2ValueId ?? '') + (variant.option3ValueId ?? '')}
            width="100%"
          >
            <VariantLine
              currency={currency}
              index={index}
              priceDisabled={samePricesChecked && index !== getIndexOfFirstNotRemoved(variants)}
              readonly={readonly}
              variant={variant}
            />
          </Box>
        ))}
      </VariantsContainer>
    </SettingsSection>
  );
}
