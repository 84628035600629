import { Grid, LinearProgress, SxProps, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { State } from '../../../../../core/store';
import { resetDeleteProduct } from '../../../../../core/store/slices/event/event-products.slice';
import { reorderProductsLineup } from '../../../../../core/usecases/event/live/reorder-products-lineup';
import { deleteEventProduct } from '../../../../../core/usecases/event/products/delete-event-product';
import ConfirmDeleteDialog from '../../../../templates/dialog/ConfirmDeleteDialog';
import AddProductButton from '../../../atoms/AddProductButton';
import ProductRow from '../../../molecules/event/ProductRow';
import ProductListHeader from '../../../molecules/event/product-settings/ProductListHeader';

type ProductListProps = {
  currency: CBOCurrency;
  eventId: string;
  hasCatalogProducts: boolean;
  isOnboarding?: boolean;
  onAddProduct: () => void;
  onRowClick?: (product: CBOEventReadModelProduct) => void;
  products: CBOEventReadModelProduct[];
  readonly?: boolean;
  sx?: SxProps;
};

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - 64px)`,
  width: '100%',
});

const NoProductsContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '80%',
  justifyContent: 'center',
});

const TableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
}));

const ProductsContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
  height: '100%',
  justifyContent: 'center',
  overflowY: 'auto',
  padding: theme.spacing(3),
}));

// TODO: view model and tests
export default function ProductList({
  currency,
  eventId,
  hasCatalogProducts,
  isOnboarding = false,
  onAddProduct,
  onRowClick,
  products,
  readonly = false,
  sx = {},
}: ProductListProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['events', 'common']);
  const event = useSelector((state: State) => state.events.currentEvent);
  const { status: productsLineupReorderingStatus } = useSelector(
    (state: State) => state.events.productsLineupReordering,
  );
  const { status: productDeleteStatus } = useSelector((state: State) => state.events.eventProductDelete);

  const [isProductDeleteModalOpen, setIsProductDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState<CBOEventReadModelProduct | null>(null);

  useEffect(() => {
    if (productDeleteStatus === 'success') {
      dispatch(resetDeleteProduct());
    }
  }, [productDeleteStatus]);

  const isPending = productsLineupReorderingStatus === 'pending';

  const deleteProduct = (product: CBOEventReadModelProduct) => {
    return dispatch(deleteEventProduct({ eventId, productId: product.id }));
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, draggableId, reason, source } = result;
    if (reason === 'CANCEL' || !destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const previousProductsOrder = products.map((product) => product.id);
    const newProductsOrder = products.map((product) => product.id);

    newProductsOrder.splice(source.index, 1);
    newProductsOrder.splice(destination.index, 0, draggableId);

    return dispatch(reorderProductsLineup({ eventId, newProductsOrder, previousProductsOrder }));
  };

  const handleShowProductDeleteModal = (product: CBOEventReadModelProduct) => {
    setIsProductDeleteModalOpen(true);
    setProductToDelete(product);
  };

  if (!event) return null;

  useEffect(() => {
    if (products.length === 0 && hasCatalogProducts && !isOnboarding) {
      onAddProduct();
    }
  }, []);

  return (
    <Root id="event-form" sx={sx}>
      <ProductListHeader event={event} onAddProduct={onAddProduct} readonly={readonly} />
      <ConfirmDeleteDialog
        deleteFunction={() => (productToDelete ? deleteProduct(productToDelete) : null)}
        deleteStatus={productDeleteStatus}
        onClose={() => setIsProductDeleteModalOpen(false)}
        open={isProductDeleteModalOpen}
      />

      {products.length > 0 ? (
        <ProductsContainer container>
          <Grid item md={10} xl={8} xs={12}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <TableContainer boxShadow={2}>
                <Table
                  id="product-list-table"
                  sx={{
                    width: '100%',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {!readonly && (
                        <StyledTableCell align="center" key="lineup" sx={{ pl: 3 }}>
                          {t('common:Position')}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center" key="image">
                        {t('Cover')}
                      </StyledTableCell>
                      <StyledTableCell align="left" key="title">
                        {t('Title')}
                      </StyledTableCell>
                      <StyledTableCell align="right" key="price">
                        {t('Price')}
                      </StyledTableCell>
                      {!readonly && <StyledTableCell key="actions"></StyledTableCell>}
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="products-list">
                    {(provided) => (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                        {products.map((product, index) => (
                          <ProductRow
                            currency={currency}
                            index={index}
                            isPending={isPending}
                            key={product.id}
                            onClick={() => onRowClick?.(product)}
                            onDelete={() => handleShowProductDeleteModal(product)}
                            product={product}
                            readonly={readonly}
                          />
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </TableContainer>
            </DragDropContext>
          </Grid>
        </ProductsContainer>
      ) : (
        <NoProductsContainer>
          <Box display="flex" flexDirection="column">
            {!readonly && <AddProductButton onAddProduct={onAddProduct} sx={{ mt: 1 }} />}
          </Box>
        </NoProductsContainer>
      )}

      {isPending && <LinearProgress sx={{ mt: 2 }} />}
    </Root>
  );
}
