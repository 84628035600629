import { TenantId } from '@bellepoque/api-contracts';

import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { CommandResult, QueryResult } from './utils';

export interface MediaCollectionsCommandsQueriesState {
  mediaCollectionCreation: CommandResult;
  mediaCollectionDeletion: CommandResult;
  mediaCollectionFetching: QueryResult;
  mediaCollectionPagesUpdate: CommandResult;
  mediaCollectionSettingsUpdate: CommandResult;
  mediaCollectionsFetching: QueryResult;
}

export interface MediaCollectionsState extends MediaCollectionsCommandsQueriesState {
  currentMediaCollection: CBOMediaCollection | null;
  mediaCollections: CBOMediaCollection[];
  mediaCollectionsTenantId: TenantId;
  publishedShoppablesCount: number;
}

export const INITIAL_MEDIA_COLLECTIONS_STATE: MediaCollectionsState = {
  currentMediaCollection: null,
  mediaCollectionCreation: {
    errors: [],
    status: 'notRequested',
  },
  mediaCollectionDeletion: {
    errors: [],
    status: 'notRequested',
  },
  mediaCollectionFetching: {
    errors: [],
    status: 'notLoaded',
  },
  mediaCollectionPagesUpdate: {
    errors: [],
    status: 'notRequested',
  },
  mediaCollectionSettingsUpdate: {
    errors: [],
    status: 'notRequested',
  },
  mediaCollections: [],
  mediaCollectionsFetching: {
    errors: [],
    status: 'notLoaded',
  },
  mediaCollectionsTenantId: '',
  publishedShoppablesCount: 0,
};
