import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProductVariant } from '../../../../core/domain/CBOEventReadModelProductOptions';
import ProductPriceInput from '../../atoms/ProductPriceInput';
import MiniImageSelectorByUrl from '../../atoms/inputs/MiniImageSelectorByUrl';

interface VariantLineProps {
  currency: CBOCurrency;
  index: number;
  priceDisabled: boolean;
  readonly: boolean;
  variant: CBOEventReadModelProductVariant;
}

export default function VariantLine(props: VariantLineProps) {
  const { t } = useTranslation(['events', 'products', 'common']);
  const { currency, index, priceDisabled, readonly, variant } = props;

  const { formState } = useFormContext();
  const { errors } = formState;
  const theme = useTheme();
  const { control, register, setValue, watch } = useFormContext();

  const priceComponentId = `${variant.label}-price`;
  const priceInError = !!errors.options?.variants[index]?.price;
  const crossedOutPriceComponentId = `${variant.label}-crossedOutPrice`;
  const crossedOutPriceInError = !!errors.options?.variants[index]?.crossedOutPrice;

  register(`options.variants.${index}.coverUrl`);
  register(`options.variants.${index}.removed`, { value: variant.removed });

  const watchVariantCoverUrl = watch(`options.variants.${index}.coverUrl`);

  const toggleVariantRemoval = (removed: boolean) => {
    setValue(`options.variants.${index}.removed`, removed, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const renderRemovedVariant = () => {
    return (
      <Grid alignItems="center" container item style={{ color: 'grey' }}>
        <Grid item textAlign="initial" xs={2.5}>
          <label>{variant.label}</label>
        </Grid>
        <Grid container item justifyContent={readonly ? 'center ' : 'flex-end'} xs={8.5}>
          {t(`products:${readonly ? 'ThisVariantWasNotCreated' : 'ThisVariantWillNotBeCreated'}`)}
        </Grid>
        {!readonly && (
          <Grid item xs={1}>
            <Button color="primary" onClick={() => toggleVariantRemoval(false)} size="small">
              {t('common:Undo')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const shouldDisplayImageSelector = () => {
    if (!readonly) return true;
    return !!watchVariantCoverUrl;
  };

  const renderActiveVariant = () => {
    return (
      <>
        <Grid item textAlign="initial" xs={2.5}>
          <Typography>{variant.label}</Typography>
        </Grid>
        {shouldDisplayImageSelector() && (
          <Grid alignItems="center" item justifyContent="center" xs={1.5}>
            <MiniImageSelectorByUrl
              disabled={readonly}
              id={`variants-${index}-coverUrl`}
              imgUrl={watchVariantCoverUrl}
              onChange={(newImageUrl: string) =>
                setValue(`options.variants.${index}.coverUrl`, newImageUrl, { shouldDirty: true })
              }
              required={false}
              title={`${variant.label} cover`}
            />
          </Grid>
        )}
        <Grid item py={1} xs={2}>
          <FormControl
            disabled={priceDisabled || readonly}
            error={priceInError}
            fullWidth
            size="small"
            style={{
              backgroundColor: priceDisabled ? 'rgba(0, 0, 0, 0.09)' : 'inherit',
            }}
            variant="outlined"
          >
            <Controller
              control={control}
              name={`options.variants.${index}.price`}
              render={({ field }) => (
                <ProductPriceInput
                  currency={currency}
                  defaultValue={variant.price}
                  disabled={priceDisabled || readonly}
                  field={field}
                  hideLabel
                  id={priceComponentId}
                  isRequired
                  isVariantPrice
                  label={t('common:Price')}
                  onChange={(value) => {
                    if (field.value !== value) field.onChange(value);
                  }}
                />
              )}
              rules={{
                min: { message: t('PriceCantBeNegative'), value: 0 },
              }}
            />
            {priceInError ? <FormHelperText>{errors.options?.variants[index]?.price?.message}</FormHelperText> : null}
          </FormControl>
        </Grid>
        <Grid item py={1} xs={2}>
          <FormControl
            disabled={priceDisabled || readonly}
            error={crossedOutPriceInError}
            fullWidth
            size="small"
            style={{
              backgroundColor: priceDisabled ? 'rgba(0, 0, 0, 0.09)' : 'inherit',
            }}
            variant="outlined"
          >
            <Controller
              control={control}
              name={`options.variants.${index}.crossedOutPrice`}
              render={({ field }) => (
                <ProductPriceInput
                  currency={currency}
                  defaultValue={variant.crossedOutPrice}
                  disabled={priceDisabled || readonly}
                  field={field}
                  hideLabel
                  id={crossedOutPriceComponentId}
                  isVariantPrice
                  label={t('common:CrossedPrice')}
                  onChange={(value) => {
                    if (field.value !== value) field.onChange(value);
                  }}
                />
              )}
              rules={{
                validate: {
                  nullablePositive: (v) => parseFloat(v) > 0 || v === null || t('PriceCantBeNegative').toString(),
                },
              }}
            />
            {crossedOutPriceInError ? (
              <FormHelperText>{errors.options?.variants[index]?.crossedOutPrice?.message}</FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item py={1} xs={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <FilledInput
              // inputProps={{ style: { padding: '4px' } }}
              disabled={readonly}
              hiddenLabel
              id={`options.variants.${index}`}
              placeholder={t('products:VariantId')}
              sx={{ backgroundColor: (theme) => theme.palette.common.white, borderRadius: '4px' }}
              value={variant.variantId}
              {...register(`options.variants.${index}.variantId`)}
            />
          </FormControl>
        </Grid>
        {!readonly && (
          <Grid item textAlign="right" xs={1}>
            <IconButton onClick={() => toggleVariantRemoval(true)}>
              <DeleteIcon sx={{ color: 'black' }} />
            </IconButton>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid
      alignItems="center"
      columnSpacing={1}
      container
      direction="row"
      justifyContent="center"
      marginLeft={0}
      marginTop={0}
      minHeight="66px"
      paddingX="8px"
      sx={{
        backgroundColor: index % 2 === 0 ? theme.palette.grey[200] : theme.palette.common.white,
      }}
      width="100%"
    >
      {variant.removed ? renderRemovedVariant() : renderActiveVariant()}
    </Grid>
  );
}
