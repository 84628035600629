import { NEVER } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import { fetchTenants } from '../../../usecases/tenants/fetch-tenants';
import { loginConfirmFulfilled } from '../../slices/authentication.slice';
import { ofType } from '../utils';

export const fetchTenantsEpic: AppEpic = (action$, $state, { store }) => {
  return action$.pipe(
    ofType<ReturnType<typeof loginConfirmFulfilled>>(loginConfirmFulfilled.type),
    switchMap(() => {
      store.dispatch(fetchTenants());
      return NEVER;
    }),
  );
};
