import { Box, Chip, Typography, chipClasses, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrandLogo = styled('img')({
  margin: '4px',
  maxHeight: '20px',
  maxWidth: '50px',
});

const Header = styled('div')`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin: 4px;
  position: relative;
  z-index: 52;
  flex-direction: row;
  justify-content: space-between;
`;

const LiveStatus = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.common.white}`,
  borderRadius: 7,
  height: 20,
  margin: '4px auto',
  marginRight: '4px',
  minWidth: 'fit-content',
  padding: '0 8px',
  zIndex: 100,
  [`& .${chipClasses.icon}`]: {
    margin: 0,
  },
}));

const LiveStatusText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 10,
  fontWeight: 'bold',
  lineHeight: '10px',
  overflow: 'visible',
  textTransform: 'uppercase',
}));

const LiveStatusDot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: '50%',
  height: '5px',
  width: '5px',
}));

const TitleContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: 1.4,
  margin: '4px',
  textAlign: 'initial',
  textShadow: 'rgb(0 0 0 / 37%) 0px 3px 6px',
  whiteSpace: 'nowrap',
}));

interface ThemePreviewHeaderProps {
  brandLogo: string | null;
  title: string;
}

export function ThemePreviewHeader({ brandLogo, title }: ThemePreviewHeaderProps) {
  const { t } = useTranslation('events', { keyPrefix: 'themePreview' });

  return (
    <Header>
      <TitleContainer>
        <BrandLogo src={brandLogo ?? ''} />
        <Title variant="h2">{title}</Title>
      </TitleContainer>
      <Box>
        <LiveStatus icon={<LiveStatusDot />} label={<LiveStatusText>{t('live')}</LiveStatusText>} size="small" />
      </Box>
    </Header>
  );
}
