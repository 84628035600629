import { UserJourneyEvent } from '../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../core/store';
import { trackUserJourneyEvent } from '../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createOnboardingCameraViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentEvent = state.events.currentEvent;
    const { id: tenantId, name: tenantName } = state.tenants.currentTenant;

    return {
      event: currentEvent,
      publisherPhone: currentEvent?.publisherPhone ?? null,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
