import InfoIcon from '@mui/icons-material/Info';
import { Grid, Tooltip, styled, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';

import SettingsSectionTitle from '../atoms/SettingsSectionTitle';

interface SettingsSectionProps {
  additionalTitleContent?: React.ReactNode;
  altBackground?: boolean;
  children: React.ReactNode;
  contentWidth?: number;
  direction?: 'column' | 'row';
  disableElevation?: boolean;
  fixedIcon?: boolean;
  icon: React.ReactElement;
  roundBottom?: boolean;
  roundTop?: boolean;
  sx?: BoxProps;
  title: string;
  tooltip?: string;
  width?: number;
}

const borderRadius = 20;

type ContainerProps = Pick<
  SettingsSectionProps,
  'altBackground' | 'direction' | 'disableElevation' | 'fixedIcon' | 'roundBottom' | 'roundTop'
>;

const Container = styled(Box)<ContainerProps>(
  ({ altBackground, direction, disableElevation, roundBottom, roundTop, theme }) => ({
    backgroundColor: altBackground ? theme.palette.grey[50] : theme.palette.common.white,
    borderRadius: `${roundTop ? borderRadius : 0}px ${roundTop ? borderRadius : 0}px ${
      roundBottom ? borderRadius : 0
    }px ${roundBottom ? borderRadius : 0}px`,
    flex: 1,
    padding: theme.spacing(3),
    ...(direction === 'row' && {
      alignItems: 'center',
      display: 'flex',
    }),
    ...(!roundBottom &&
      !disableElevation && {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      }),
  }),
);

const iconSizeInPx = 50;

const IconContainer = styled(Box)<{ fixedIcon: boolean }>(({ fixedIcon, theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '100%',
  display: 'flex',
  height: iconSizeInPx,
  justifyContent: 'center',
  marginLeft: -iconSizeInPx,
  marginRight: theme.spacing(1),
  position: fixedIcon ? 'fixed' : 'initial',
  width: iconSizeInPx,
}));

const SectionLabel = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginLeft: theme.spacing(2),
  position: 'relative',
  width: '100%',
}));

export default function SettingsSection({
  altBackground = false,
  additionalTitleContent,
  children,
  direction = 'column',
  disableElevation = false,
  fixedIcon = false,
  contentWidth = 10,
  icon,
  roundBottom = false,
  roundTop = false,
  sx,
  title,
  tooltip,
}: SettingsSectionProps) {
  const theme = useTheme();

  return (
    <Container
      altBackground={altBackground}
      boxShadow={disableElevation ? 0 : 2}
      direction={direction}
      disableElevation={disableElevation}
      fixedIcon={fixedIcon}
      roundBottom={roundBottom}
      roundTop={roundTop}
      sx={sx}
    >
      <SectionLabel>
        <IconContainer boxShadow={2} fixedIcon={fixedIcon}>
          {icon}
        </IconContainer>
        <SettingsSectionTitle
          sx={{
            ...(fixedIcon && {
              marginLeft: theme.spacing(2),
            }),
          }}
          title={title}
        />
        {!!tooltip && (
          <Tooltip placement="right" sx={{ bottom: 0, marginLeft: theme.spacing(1) }} title={tooltip}>
            <InfoIcon style={{ color: theme.palette.primary.main, fontSize: 20 }} />
          </Tooltip>
        )}
        {!!additionalTitleContent && (
          <Box alignItems="center" display="flex" flex={1} justifyContent="flex-end">
            {additionalTitleContent}
          </Box>
        )}
      </SectionLabel>
      <Grid container justifyContent="center" sx={{ ...(direction === 'column' && { marginTop: theme.spacing(2) }) }}>
        <Grid item xs={contentWidth}>
          <Grid container gap={3} justifyContent="center">
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
