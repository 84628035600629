import { manyTenantDtosToDomain } from '../../../gateways/tenants/tenant-mapper';
import { CBOTenant } from '../../domain/CBOTenant';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const fetchTenants = createAppAsyncThunk(
  'tenants/fetch-tenants',
  async (_, { extra: { tenantsGateway } }): Promise<CBOTenant[]> => {
    const dtos = await tenantsGateway.fetchMany();
    return manyTenantDtosToDomain(dtos);
  },
);
