import { Box, alpha, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AdminMessageIcon = styled('img')({
  height: 'auto',
  marginBottom: '-3px',
  marginRight: '0.2em',
  maxHeight: '15px',
  maxWidth: '15px',
});

const AuthorContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '2px',
});

const Author = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  marginRight: '6px',
  textShadow: `1px 1px 2px ${theme.palette.common.black}`,
}));

const Chat = styled(Box)({
  alignItems: 'flex-start',
  bottom: '8px',
  display: 'flex',
  flexDirection: 'column',
  left: '4px',
  margin: '4px',
  position: 'absolute',
  width: '60%',
  zIndex: 54,
});

const Content = styled('span')(({ theme }) => ({
  textShadow: `1px 1px 2px  ${theme.palette.common.black}`,
}));

const InnerContainer = styled('div')({
  boxSizing: 'initial',
  letterSpacing: 'initial',
  lineHeight: 'initial',
  width: '100%',
});

const Messages = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  overflow-wrap: break-word;
  max-height: 30vh;
  -webkit-mask-image: linear-gradient(
    to top,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 50%,
    rgba(0, 0, 0, 0.67) 75%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const Message = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'adminMessageBackgroundColor' && prop !== 'isModerator',
})<{
  adminMessageBackgroundColor: string;
  isModerator: boolean;
}>(({ adminMessageBackgroundColor, isModerator, theme }) => ({
  borderRadius: '0.4em',
  color: theme.palette.common.white,
  fontSize: '14px',
  marginTop: '4px',
  padding: '0.3em 0.4em',

  ...(isModerator
    ? {
        alignSelf: 'flex-end',
        backgroundColor: alpha(adminMessageBackgroundColor, 0.1),
        border: `1px solid ${adminMessageBackgroundColor}`,
      }
    : { alignSelf: 'flex-start', backgroundColor: 'rgba(155, 154, 154, 0.314)' }),
}));

const JoinChatButton = styled('button')(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  border: 'none',
  borderRadius: '0.6em',
  boxSizing: 'border-box',
  color: theme.palette.common.white,
  fontSize: '15px',
  marginTop: '0.4em',
  padding: '0.4em 1.4em 0.4em 0.8em',
  textAlign: 'left',
  width: '100%',
}));
interface Message {
  author: string;
  content: string;
  isModerator: boolean;
}

interface ThemePreviewChatProps {
  adminMessageBackgroundColor: string;
  adminMessageIcon: string | null;
}
export function ThemePreviewChat({ adminMessageBackgroundColor, adminMessageIcon }: ThemePreviewChatProps) {
  const { t } = useTranslation('events', { keyPrefix: 'themePreview' });

  const messages: Message[] = [
    {
      author: t('chatUser'),
      content: t('messageContent'),
      isModerator: false,
    },
    {
      author: t('chatUser'),
      content: t('messageContent'),
      isModerator: false,
    },
    {
      author: t('chatModerator'),
      content: t('messageContent'),
      isModerator: true,
    },
    {
      author: t('chatUser'),
      content: t('messageContent'),
      isModerator: false,
    },
  ];

  const renderMessage = ({ author, content, isModerator }: Message, index: number) => (
    <Message adminMessageBackgroundColor={adminMessageBackgroundColor} isModerator={isModerator} key={index}>
      <AuthorContainer>
        {isModerator && <AdminMessageIcon src={adminMessageIcon ?? ''} />}
        <Author>{author}</Author>
      </AuthorContainer>
      <Content>{content}</Content>
    </Message>
  );

  return (
    <Chat>
      <InnerContainer>
        <Messages>{messages.map(renderMessage)}</Messages>
        <JoinChatButton>{t('joinChat')}</JoinChatButton>
      </InnerContainer>
    </Chat>
  );
}
