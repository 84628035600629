import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../../../core/store';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { ENDPOINTS } from '../../../endpoints';
import { useRequiredParams } from '../../../utils/useRequiredParams';

export default function HelpAndSupport() {
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const dispatch = useDispatch();

  const { slug } = useRequiredParams<{ slug?: string }>();

  const urlToLoad = ENDPOINTS.support.home + decodeURIComponent(slug || '');

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
        name: 'Help & Support',
      }),
    );
  }, []);

  /* TODO: trads */
  return <iframe src={urlToLoad} style={{ border: 'none', width: '100%' }} title="Help & Support" />;
}
