import { EventStatus, FINISHED_OR_LATER_EVENT_STATUSES, REPLAY_STATUSES } from '@bellepoque/api-contracts';

export const isFinishedOrLater = (eventStatus: EventStatus): boolean => {
  const values: EventStatus[] = Object.values(FINISHED_OR_LATER_EVENT_STATUSES);
  return values.includes(eventStatus);
};

export const isReplay = (eventStatus: EventStatus): boolean => {
  const values: EventStatus[] = Object.values(REPLAY_STATUSES);
  return values.includes(eventStatus);
};
