import { BillingReports, TrafficReports } from '../../domain/Reports';
import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface ReportsCommandsQueriesState {
  billingReportsFetching: QueryResult;
  trafficReportsFetching: QueryResult;
}

export interface ReportsState extends ReportsCommandsQueriesState {
  billingReports: BillingReports;
  trafficReports: TrafficReports;
}

export const INITIAL_REPORTS_STATE: ReportsState = {
  billingReports: {},
  billingReportsFetching: INITIAL_QUERY_RESULT,
  trafficReports: {},
  trafficReportsFetching: INITIAL_QUERY_RESULT,
};
