import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'react-select-material-ui';

import FontIcon from '../../../../../icons/FontIcon';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import SingleSelect from '../../../atoms/inputs/SingleSelect';
import SettingsSection from '../../SettingsSection';

interface FontSettingsSectionProps {
  control: any;
  errors: any;
  fontOptions: string[];
  readonly: boolean;
  submissionInProgress: boolean;
}

export default function FontSettingsSection(props: FontSettingsSectionProps) {
  const { control, fontOptions, readonly, submissionInProgress } = props;
  const { t } = useTranslation(['events', 'common']);
  const disabled = readonly || submissionInProgress;
  const theme = useTheme();
  return (
    <SettingsSection
      contentWidth={11}
      icon={<FontIcon sx={{ color: theme.palette.common.white }} />}
      title={t('Fonts')}
    >
      <Grid container gap={3} justifyContent="center">
        <Grid item position="relative" xs={12}>
          <Box sx={{ flexGrow: 1, width: '370px' }}>
            <Controller
              control={control}
              defaultValue="Arial"
              name="mainFontName"
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  SelectProps={{
                    formatCreateLabel: (value: string) => `${value} (${t('fonts.NewLabel')})`,
                    isCreatable: true,
                    isValidNewOption: (inputValue: string) => inputValue.trim() !== '',
                    styles: {
                      container: () => ({
                        fontFamily: field.value,
                      }),
                      option: (base: any, { data }: { data: SelectOption }) => ({
                        fontFamily: data.value,
                      }),
                    },
                  }}
                  disabled={disabled}
                  label={t('fonts.MainFont')}
                  onChange={field.onChange}
                  options={fontOptions}
                />
              )}
              rules={{
                maxLength: { message: t('common:MaxCharsWithCount', { count: 100 }), value: 100 },
                minLength: { message: t('common:MinCharsWithCount', { count: 3 }), value: 3 },
                required: { message: t('MainFontNameIsRequired'), value: true },
              }}
            />
          </Box>
          <InputTooltip content={t('helpers.Font')} />
        </Grid>
      </Grid>
    </SettingsSection>
  );
}
