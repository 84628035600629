import { CBOShoppablesSettings } from '../../domain/CBOShoppablesSettings';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchShoppablesSettingsParams = {
  tenantId: string;
};

export const fetchShoppablesSettings = createAppAsyncThunk(
  'shoppables/settings/fetch',
  async (
    { tenantId }: FetchShoppablesSettingsParams,
    { extra: { shoppablesGateway } },
  ): Promise<CBOShoppablesSettings> => {
    return shoppablesGateway.fetchShoppablesSettings(tenantId);
  },
);
