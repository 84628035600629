import Image from '@jy95/material-ui-image';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Box, Button, styled } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UrlDialog from '../../../templates/dialog/UrlDialog';

interface MiniImageSelectorByUrlProps {
  disabled: boolean;
  id: string;
  imgUrl?: string;
  onChange: (newImageUrl: string) => void;
  required: boolean;
  title: string;
}

const size = 40;
const padding = 5;

const Container = styled(Box)({
  height: `${size + padding * 2}px`,
  padding: `${padding}px`,
  width: `${size + padding * 2}px`,
});

const StyledButton = styled(Button)({
  aspectRatio: '1 / 1',
  height: '100%',
});

export default function MiniImageSelectorByUrl(props: MiniImageSelectorByUrlProps) {
  const { disabled, id, onChange, required, title, imgUrl } = props;
  const { t } = useTranslation(['events']);
  const [changeImageUrlOpen, setChangeImageUrlOpen] = useState<boolean>(false);
  const [imageOpacity, setImageOpacity] = useState(1);

  return (
    <Container>
      <StyledButton
        disableFocusRipple
        disabled={disabled}
        onClick={() => setChangeImageUrlOpen(true)}
        sx={{ height: size, width: size }}
      >
        {imgUrl ? (
          <Image
            animationDuration={1000}
            imageStyle={{ height: size, opacity: imageOpacity, width: size }}
            onMouseEnter={() => setImageOpacity(0.7)}
            onMouseOut={() => setImageOpacity(1)}
            src={imgUrl}
            style={{ backgroundColor: 'transparent', height: size, objectFit: 'contain', padding: 0, width: size }}
            title={title}
          />
        ) : (
          <ImageSearchIcon />
        )}
      </StyledButton>
      {changeImageUrlOpen && (
        <UrlDialog
          defaultUrl={imgUrl}
          id={id}
          label={t('ImageURL')}
          onClose={(params) => {
            setChangeImageUrlOpen(false);
            onChange(params.url);
          }}
          open={changeImageUrlOpen}
          required={required}
        />
      )}
    </Container>
  );
}
