import { OnboardingStep } from '@bellepoque/api-contracts';
import { LiveTv } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { t } from 'i18next';
import React, { useMemo, useRef, useState } from 'react';

import ProductsIcon from '../../../icons/ProductsIcon';
import SettingsIcon from '../../../icons/SettingsIcon';
import Tabs, { TabProps, tabWidthInPx } from '../../components/molecules/Tabs';
import OnboardingFooter from '../../components/molecules/onboarding/OnboardingFooter';
import OnboardingAddProducts from '../../components/organisms/onboarding/OnboardingAddProducts';
import OnboardingCamera from '../../components/organisms/onboarding/OnboardingCamera';
import OnboardingCreateEvent, {
  OnboardingCreateEventRef,
} from '../../components/organisms/onboarding/OnboardingCreateEvent';
import OnboardingLiveManager from '../../components/organisms/onboarding/OnboardingLiveManager';
import { ONBOARDING_SEQUENCE } from '../../pages/onboarding/Onboarding';
import TabPanel from '../TabPanel';

enum TabsNumber {
  Settings = 0,
  Products = 1,
  CameraApp = 2,
  LiveWithChat = 3,
}

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxHeight: `calc(100vh - 64px)`,
  textAlign: 'initial',
});

const OnboardingStepContainer = styled(Box)({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  overflowY: 'auto',
});

const TabsContainer = styled(Box)({
  display: 'flex',
  height: '100%',
});

const FooterContainer = styled('div')({
  width: `100%`,
});

interface OnboardingTemplateProps {
  currentEventHasProducts: boolean;
  currentSequenceIndex: number;
  isCreatingOrUpdatingEvent: boolean;
  loading: boolean;
  onGoToNextStep: () => void;
  onGoToPreviousStep: () => void;
}

type CurrentSequenceSettings = {
  isBackButtonShown: boolean;
  isNextButtonEnabled: boolean;
  isNextButtonShown: boolean;
  tabNumber: TabsNumber;
};

export default function OnboardingTemplate({
  currentEventHasProducts,
  currentSequenceIndex,
  loading,
  isCreatingOrUpdatingEvent,
  onGoToNextStep,
  onGoToPreviousStep,
}: OnboardingTemplateProps) {
  const [isCreateEventFormValid, setIsCreateEventFormValid] = useState(false);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const createEventRef = useRef<OnboardingCreateEventRef>(null);

  const currentStep = ONBOARDING_SEQUENCE.get(currentSequenceIndex);

  const currentSequenceSettings: CurrentSequenceSettings = useMemo(() => {
    switch (currentStep) {
      case OnboardingStep.CreateEvent:
        return {
          isBackButtonShown: false,
          isNextButtonEnabled: isCreateEventFormValid && !isCreatingOrUpdatingEvent,
          isNextButtonShown: true,
          tabNumber: TabsNumber.Settings,
        };
      case OnboardingStep.AddProducts:
        return {
          isBackButtonShown: true,
          isNextButtonEnabled: currentEventHasProducts,
          isNextButtonShown: true,
          tabNumber: TabsNumber.Products,
        };
      case OnboardingStep.CameraApp:
        return {
          isBackButtonShown: true,
          isNextButtonEnabled: true,
          isNextButtonShown: true,
          tabNumber: TabsNumber.CameraApp,
        };
      case OnboardingStep.PrivateTest:
        return {
          isBackButtonShown: false,
          isNextButtonEnabled: false,
          isNextButtonShown: false,
          tabNumber: TabsNumber.LiveWithChat,
        };
      case OnboardingStep.OpenToPublic:
        return {
          isBackButtonShown: false,
          isNextButtonEnabled: false,
          isNextButtonShown: false,
          tabNumber: TabsNumber.LiveWithChat,
        };
      default:
        return {
          isBackButtonShown: false,
          isNextButtonEnabled: false,
          isNextButtonShown: false,
          tabNumber: TabsNumber.Settings,
        };
    }
  }, [currentEventHasProducts, currentStep, isCreateEventFormValid, isCreatingOrUpdatingEvent]);

  const handleCreateEventFormIsValid = (isValid: boolean) => {
    setIsCreateEventFormValid(isValid);
  };

  const handleGoToNextStep = () => {
    if (currentStep === OnboardingStep.CreateEvent) {
      createEventRef.current?.submitForm();
    } else {
      onGoToNextStep();
    }
  };

  const tabs: TabProps[] = useMemo(
    () => [
      {
        component: (
          <OnboardingCreateEvent
            onIsValid={(isValid: boolean) => handleCreateEventFormIsValid?.(isValid)}
            ref={createEventRef}
          />
        ),
        enabled: currentStep === OnboardingStep.CreateEvent,
        icon: <SettingsIcon />,
        index: TabsNumber.Settings,
        label: t('events:Tabs.Settings'),
        to: `settings`,
      },
      {
        component: <OnboardingAddProducts />,
        enabled: currentStep === OnboardingStep.AddProducts,
        icon: <ProductsIcon />,
        index: TabsNumber.Products,
        label: t('events:Tabs.Products'),
        to: `products`,
      },
      {
        component: <OnboardingCamera />,
        enabled: currentStep === OnboardingStep.CameraApp,
        icon: <></>,
        index: TabsNumber.CameraApp,
        isHidden: true,
        label: '',
        to: `camera`,
      },
      {
        component: <OnboardingLiveManager />,
        enabled: currentStep === OnboardingStep.PrivateTest || currentStep === OnboardingStep.OpenToPublic,
        icon: <LiveTv />,
        index: TabsNumber.LiveWithChat,
        label: t('events:Tabs.LiveManager'),
        to: `live-with-chat`,
      },
    ],
    [currentStep],
  );

  return (
    <Container>
      <Box display="flex" height="100%">
        <Box display="flex" minWidth={tabWidthInPx}>
          <TabsContainer>
            <Tabs activeTab={currentSequenceSettings.tabNumber} tabs={tabs} />
          </TabsContainer>
        </Box>

        <Box display="flex" flex={1} flexDirection="column">
          <OnboardingStepContainer height={`calc(100% - ${footerHeight}px)`}>
            {tabs.map(({ component, index, label }) => (
              <TabPanel index={index} key={label} value={currentSequenceSettings.tabNumber}>
                {component}
              </TabPanel>
            ))}
          </OnboardingStepContainer>
          <FooterContainer ref={(el) => setFooterHeight(el?.clientHeight || 0)}>
            <OnboardingFooter
              buttonsDisabled={loading}
              currentSequenceIndex={currentSequenceIndex}
              isBackButtonShown={currentSequenceSettings.isBackButtonShown}
              isNextButtonEnabled={currentSequenceSettings.isNextButtonEnabled}
              isNextButtonShown={currentSequenceSettings.isNextButtonShown}
              onGoToNextStep={handleGoToNextStep}
              onGoToPreviousStep={onGoToPreviousStep}
            />
          </FooterContainer>
        </Box>
      </Box>
    </Container>
  );
}
