import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetDeleteShoppable as resetDeleteShoppableAction } from '../../store/slices/shoppables/shoppables-crud.slice';

type DeleteShoppableParams = {
  shoppableId: string;
  tenantId: string;
};

export const deleteShoppable = createAppAsyncThunk(
  'shoppables/delete',
  async (params: DeleteShoppableParams, { extra: { shoppablesGateway } }): Promise<void> => {
    return shoppablesGateway.deleteOne(params);
  },
);

export const resetDeleteShoppable = () => {
  return resetDeleteShoppableAction();
};
