import { AppBar, Box, LinearProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'absolute',
  right: 'auto',
  width: '100%',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
  marginRight: theme.spacing(7),
  paddingBottom: theme.spacing(1),
}));

type ReplayHeaderProps = {
  loading: boolean;
};

export function ReplaysHeader({ loading }: ReplayHeaderProps) {
  const { t } = useTranslation('events');

  return (
    <Container elevation={0}>
      <ContentContainer>
        <Title variant="h4">{t('Replays')}</Title>
      </ContentContainer>
      {loading && <LinearProgress />}
    </Container>
  );
}
