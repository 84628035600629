import { Refresh, Search } from '@mui/icons-material';
import { Box, Checkbox, IconButton, InputAdornment, MenuItem, Select, TextField, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogSortType } from '../../../../../../core/store/state/catalog';

interface ProductListActionsHeaderProps {
  areAllProductsSelected: boolean;
  currentSort: CatalogSortType;
  onChangeSort: (sort: CatalogSortType) => void;
  onRefresh: () => void;
  onSearch: (search: string) => void;
  onSelectAll: () => void;
  searchValue: string;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  px: theme.spacing(1),
  width: '100%',
}));

export default function ProductListActionsHeader(props: ProductListActionsHeaderProps) {
  const { t } = useTranslation(['common, products']);
  const { areAllProductsSelected, searchValue, currentSort, onChangeSort, onRefresh, onSearch, onSelectAll } = props;

  let debounceTimer: number | null = null;

  const sortValues: { label: string; value: string }[] = [
    {
      label: t('common:Sort.Label', {
        field: t('products:title_capitalized'),
        order: t('common:Sort.Order.asc_alpha'),
      }),
      value: 'product.title',
    },
    {
      label: t('common:Sort.Label', {
        field: t('products:title_capitalized'),
        order: t('common:Sort.Order.desc_alpha'),
      }),
      value: '-product.title',
    },
    {
      label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.asc') }),
      value: 'minPrice',
    },
    {
      label: t('common:Sort.Label', { field: t('common:Price'), order: t('common:Sort.Order.desc') }),
      value: '-minPrice',
    },
  ];

  const handleSearch = (search: string) => {
    const callback = () => onSearch(search);
    if (debounceTimer) clearTimeout(debounceTimer);

    debounceTimer = window.setTimeout(callback, 500);
  };

  return (
    <Container>
      <Checkbox checked={areAllProductsSelected} onChange={onSelectAll} />
      <IconButton aria-label="delete" onClick={onRefresh} size="large">
        <Refresh />
      </IconButton>
      <Box>
        <Select
          defaultValue={currentSort}
          onChange={(e) => onChangeSort(e.target.value as CatalogSortType)}
          size="small"
        >
          {sortValues.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box flex={1} ml={1}>
        <TextField
          InputProps={{
            fullWidth: true,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          defaultValue={searchValue}
          label={t('common:Search')}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          size="small"
          sx={{ width: '100%' }}
        />
      </Box>
    </Container>
  );
}
