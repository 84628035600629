import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import ValueCard from '../../atoms/home/ValueCard';

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'initial',
}));

const ItemsContainer = styled(Box)(({ theme }) => ({
  [`& > div.MuiBox-root`]: {
    flexBasis: 'calc(100% / 3)',
    flexGrow: 0,
  },
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(2),

  marginTop: theme.spacing(2),
}));

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

type ActivityOverviewProps = {
  currency: CBOCurrency;
  loading: boolean;
  views: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
} & (
  | {
      purchasedAmounts: {
        live: number;
        replay: number;
        shoppableVideos: number;
      };
      purchasedCarts: {
        live: number;
        replay: number;
        shoppableVideos: number;
      };
      usesSales: true;
    }
  | {
      usesSales: false;
      validatedCarts: {
        live: number;
        replay: number;
      };
      validatedCartsAmounts: {
        live: number;
        replay: number;
      };
    }
);

export default function ActivityOverview(props: ActivityOverviewProps) {
  const { t } = useTranslation('home');

  const { currency, loading, usesSales } = props;

  return (
    <Container>
      <Title variant="h2">{t('ActivityOverview')}</Title>

      <ItemsContainer>
        <ValueCard
          liveValue={props.views.live}
          loading={loading}
          replayValue={props.views.replay}
          shoppableVideoValue={props.views.shoppableVideos}
          title={t('TotalViews')}
          totalValue={props.views.live + props.views.shoppableVideos + props.views.replay}
          valueType="number"
        />
        {usesSales ? (
          <>
            <ValueCard
              liveValue={props.purchasedCarts.live}
              loading={loading}
              replayValue={props.purchasedCarts.replay}
              shoppableVideoValue={props.purchasedCarts.shoppableVideos}
              title={t('TotalOrders')}
              tooltip={t('analytics:ConversionTrackerDisclaimer')}
              totalValue={
                props.purchasedCarts.live + props.purchasedCarts.shoppableVideos + props.purchasedCarts.replay
              }
              valueType="number"
            />
            <ValueCard
              currency={currency}
              liveValue={props.purchasedAmounts.live}
              loading={loading}
              replayValue={props.purchasedAmounts.replay}
              shoppableVideoValue={props.purchasedAmounts.shoppableVideos}
              title={t('TotalSales')}
              tooltip={t('analytics:ConversionTrackerDisclaimer')}
              totalValue={
                props.purchasedAmounts.live + props.purchasedAmounts.shoppableVideos + props.purchasedAmounts.replay
              }
              valueType="currency"
            />
          </>
        ) : (
          <>
            <ValueCard
              liveValue={props.validatedCarts.live}
              loading={loading}
              replayValue={props.validatedCarts.replay}
              title={t('ValidatedCarts')}
              totalValue={props.validatedCarts.live + props.validatedCarts.replay}
              valueType="number"
            />
            <ValueCard
              currency={currency}
              liveValue={props.validatedCartsAmounts.live}
              loading={loading}
              replayValue={props.validatedCartsAmounts.replay}
              title={t('TotalAmountInCheckout')}
              totalValue={props.validatedCartsAmounts.live + props.validatedCartsAmounts.replay}
              valueType="currency"
            />
          </>
        )}
      </ItemsContainer>
    </Container>
  );
}
