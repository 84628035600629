import { EventStatus } from '@bellepoque/api-contracts';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import EyeOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { CBOEventReadModel } from '../../core/domain/CBOEventReadModel';
import { CBOTenant } from '../../core/domain/CBOTenant';
import { CommandStatus } from '../../core/store/state/utils';
import MultistreamIcon from '../../icons/MultistreamIcon';
import ProductsIcon from '../../icons/ProductsIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { hasBeenLiveEvent } from '../../utils/has-been-live-event';
import { TabProps } from '../components/molecules/Tabs';
import AddProductsToEventDialog from '../components/organisms/event/event-details/AddProductsToEventDialog';
import LiveWithChat from '../components/organisms/event/event-details/LiveWithChat';
import Multistream from '../components/organisms/event/event-details/Multistream';
import Preview from '../components/organisms/event/event-details/Preview';
import ProductPanel from '../components/organisms/event/event-details/ProductPanel';
import Settings from '../components/organisms/event/event-details/Settings';
import Theme from '../components/organisms/event/event-details/Theme';
import { routes } from '../routes';
import EventDetailsTemplate from '../templates/event/EventDetailsTemplate';

enum TabsNumber {
  Settings = 0,
  Theme = 1,
  Products = 2,
  Multistream = 3,
  LiveWithChat = 4,
  Preview = 5,
}

type LiveEventDetailsTemplateProps = {
  currentTenant: CBOTenant;
  eventIdParam: string;
  liveEvent: CBOEventReadModel | null;
  liveEventClosingStatus: CommandStatus;
  onGoToLiveEventTab: (path: string) => void;
  onGoToNewProductCreationPage: () => void;
  productIdParam?: string;
};

const LiveEventDetailsTemplate: FC<LiveEventDetailsTemplateProps> = ({
  currentTenant,
  eventIdParam,
  liveEvent,
  liveEventClosingStatus,
  onGoToLiveEventTab,
  onGoToNewProductCreationPage,
  productIdParam,
}) => {
  const { t } = useTranslation(['events', 'common']);

  const isLiveWithChatActive = useMatch(routes.events.liveWithChat({ eventId: ':eventId' }));
  const isMultistreamActive = useMatch(routes.events.multistream({ eventId: ':eventId' }));
  const isPreviewActive = useMatch(routes.events.preview({ eventId: ':eventId' }));
  const isProductsActive = useMatch(routes.events.products({ eventId: ':eventId' }));
  const isProductPageActive = useMatch(routes.events.product({ eventId: ':eventId', productId: ':productId' }));
  const isSettingsActive = useMatch(routes.events.settings({ eventId: ':eventId' }));
  const isThemeActive = useMatch(routes.events.theme({ eventId: ':eventId' }));

  const [isAddCatalogProductsDialogOpen, setIsAddCatalogProductsDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabsNumber | undefined>();

  const getCurrentRouteTabNumber = () => {
    if (isLiveWithChatActive) return TabsNumber.LiveWithChat;
    if (isMultistreamActive) return TabsNumber.Multistream;
    if (isPreviewActive) return TabsNumber.Preview;
    if (isProductsActive || isProductPageActive) return TabsNumber.Products;
    if (isSettingsActive) return TabsNumber.Settings;
    if (isThemeActive) return TabsNumber.Theme;
    return TabsNumber.Settings;
  };

  const handleAddProduct = () => {
    if (!liveEvent?.id) return;

    if (currentTenant.hasCatalogProducts) {
      setIsAddCatalogProductsDialogOpen(true);
    } else {
      onGoToNewProductCreationPage();
    }
  };

  const loading = !liveEvent;

  const readonly = useMemo(() => (liveEvent ? hasBeenLiveEvent(liveEvent.status) : false), [liveEvent?.status]);

  const hasMultistreamWarning = () => {
    if (!liveEvent) return false;

    const { facebookRestreamingConfiguration, restreamingConfigurations, youtubeRestreamingConfiguration } = liveEvent;

    const now = new Date();

    const hasExpiredFacebookRestreamingConfiguration =
      facebookRestreamingConfiguration && facebookRestreamingConfiguration.expiresAt < now;

    const hasExpiredYoutubeRestreamingConfiguration =
      youtubeRestreamingConfiguration && youtubeRestreamingConfiguration.expiresAt < now;

    const hasExpiredRestreamingConfigurations = restreamingConfigurations.some(
      (restreamingConfiguration) =>
        restreamingConfiguration.enabled &&
        restreamingConfiguration.expiresAt &&
        restreamingConfiguration.expiresAt < now,
    );

    return (
      hasExpiredFacebookRestreamingConfiguration ||
      hasExpiredYoutubeRestreamingConfiguration ||
      hasExpiredRestreamingConfigurations
    );
  };

  const tabs: TabProps[] = liveEvent
    ? [
        {
          component: <Settings event={liveEvent} readonly={readonly} />,
          enabled: true,
          icon: <SettingsIcon />,
          index: TabsNumber.Settings,
          label: t('Tabs.Settings'),
          to: routes.events.settings({ eventId: liveEvent.id }),
        },
        {
          component: <Theme event={liveEvent} readonly={readonly} />,
          enabled: true,
          icon: <WallpaperIcon />,
          index: TabsNumber.Theme,
          label: t('Tabs.Theme'),
          to: routes.events.theme({ eventId: liveEvent.id }),
        },
        {
          component: (
            <ProductPanel
              event={liveEvent}
              hasCatalogProducts={currentTenant.hasCatalogProducts}
              onAddProduct={handleAddProduct}
              readonly={readonly}
            />
          ),
          enabled: true,
          icon: <ProductsIcon />,
          index: TabsNumber.Products,
          label: t('Tabs.Products'),
          to: productIdParam
            ? routes.events.product({ eventId: liveEvent.id, productId: productIdParam })
            : routes.events.products({ eventId: liveEvent.id }),
        },
        {
          component: <Multistream event={liveEvent} readonly={readonly} />,
          enabled: true,
          hasWarning: hasMultistreamWarning(),
          icon: <MultistreamIcon />,
          index: TabsNumber.Multistream,
          label: t('Tabs.Multistream'),
          to: routes.events.multistream({ eventId: liveEvent.id }),
        },
        {
          component: <LiveWithChat event={liveEvent} />,
          enabled: true,
          icon: <LiveTvIcon />,
          index: TabsNumber.LiveWithChat,
          label: t('Tabs.LiveManager'),
          to: routes.events.liveWithChat({ eventId: liveEvent.id }),
        },
        {
          component: <Preview event={liveEvent} />,
          enabled: liveEvent.status !== EventStatus.DRAFT,
          icon: <EyeOutlinedIcon />,
          index: TabsNumber.Preview,
          label: t('Tabs.Preview'),
          to: routes.events.preview({ eventId: liveEvent.id }),
        },
      ]
    : [];

  useEffect(() => {
    if (liveEvent && !activeTab) setActiveTab(getCurrentRouteTabNumber());
  }, [liveEvent?.status]);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) onGoToLiveEventTab(tab.to);
  }, [activeTab]);

  useEffect(() => {
    if (liveEvent?.id === eventIdParam && liveEventClosingStatus === 'success') {
      setActiveTab(TabsNumber.Settings);
    }
  }, [eventIdParam, liveEvent, liveEventClosingStatus]);

  if (activeTab === undefined) return null;

  return (
    <Fragment>
      {liveEvent && (
        <AddProductsToEventDialog
          event={liveEvent}
          isOpen={isAddCatalogProductsDialogOpen}
          onClose={() => setIsAddCatalogProductsDialogOpen(false)}
        />
      )}

      <EventDetailsTemplate
        activeTab={activeTab}
        loading={loading}
        onChangeTab={setActiveTab}
        tabs={tabs.filter(({ enabled }) => enabled)}
      />
    </Fragment>
  );
};

export default LiveEventDetailsTemplate;
