import { CBOTenantIntegrations } from '../../domain/CBOTenant';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateIntegrations as resetUpdateIntegrationsAction } from '../../store/slices/tenants.slice';

export type UpdateIntegrationsRequest = {
  integrations: CBOTenantIntegrations;
  tenantId: string;
};

export const updateIntegrations = createAppAsyncThunk(
  'tenants/update-integrations',
  async ({ integrations, tenantId }: UpdateIntegrationsRequest, { extra: { tenantsGateway } }): Promise<void> => {
    return tenantsGateway.updateIntegrations(tenantId, integrations);
  },
);

export const resetUpdateIntegrations = () => {
  return resetUpdateIntegrationsAction();
};
