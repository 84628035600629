import { AdministrationCommandsQueriesState } from './state/administration';
import { CatalogCommandsQueriesState } from './state/catalog';
import { EventsCommandsQueriesState } from './state/events';
import { FacebookCommandsQueriesState } from './state/facebook';
import { HomeCommandsQueriesState } from './state/home';
import { KlaviyoCommandsQueriesState } from './state/klaviyo';
import { LandingPageCommandsQueriesState } from './state/landingPage';
import { MediaCollectionsCommandsQueriesState } from './state/mediaCollections';
import { OnboardingCommandsQueriesState } from './state/onboarding';
import { ReportsState } from './state/reports';
import { SettingsCommandsQueriesState } from './state/settings';
import { ShopifyCommandsQueriesState } from './state/shopify';
import { ShoppablesCommandsQueriesState } from './state/shoppables';
import { TenantStatisticsCommandsQueriesState } from './state/tenantStatistics';
import { TenantsCommandsQueriesState } from './state/tenants';
import { YoutubeCommandsQueriesState } from './state/youtube';

type Status =
  | keyof AdministrationCommandsQueriesState
  | keyof CatalogCommandsQueriesState
  | keyof EventsCommandsQueriesState
  | keyof FacebookCommandsQueriesState
  | keyof HomeCommandsQueriesState
  | keyof KlaviyoCommandsQueriesState
  | keyof LandingPageCommandsQueriesState
  | keyof MediaCollectionsCommandsQueriesState
  | keyof OnboardingCommandsQueriesState
  | keyof ReportsState
  | keyof SettingsCommandsQueriesState
  | keyof ShopifyCommandsQueriesState
  | keyof ShoppablesCommandsQueriesState
  | keyof TenantsCommandsQueriesState
  | keyof TenantStatisticsCommandsQueriesState
  | keyof YoutubeCommandsQueriesState;

export const errorStatus = (status: Status, errors: any) => {
  return {
    [status]: {
      errors,
      status: 'error',
    },
  };
};

export const loadedStatus = (status: Status) => {
  return {
    [status]: {
      errors: [],
      status: 'loaded',
    },
  };
};

export const notLoadedStatus = (status: Status) => {
  return {
    [status]: {
      errors: [],
      status: 'notLoaded',
    },
  };
};

export const notRequestedStatus = (status: Status) => {
  return {
    [status]: {
      errors: [],
      status: 'notRequested',
    },
  };
};

export const pendingStatus = (status: Status) => {
  return {
    [status]: {
      errors: [],
      status: 'pending',
    },
  };
};

export const successStatus = (status: Status) => {
  return {
    [status]: {
      errors: [],
      status: 'success',
    },
  };
};
