import { Cms } from '@bellepoque/api-contracts';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import { State } from '../../../core/store';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import CatalogIcon from '../../../icons/CatalogIcon';
import { TabProps } from '../../components/molecules/Tabs';
import AdminTools from '../../components/organisms/administration/AdminTools';
import CreateTenant from '../../components/organisms/administration/CreateTenant';
import MasterInvitations from '../../components/organisms/administration/MasterInvitations';
import Catalog from '../../components/organisms/administration/catalog/Catalog';
import { routes } from '../../routes';
import AdministrationTemplate from '../../templates/AdministrationTemplate';

enum TabsNumber {
  Catalog = 1,
  Invitations = 2,
  CreateTenant = 3,
  Tools = 4,
}

// TODO: Use view model
export default function Administration() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['administration', 'common']);
  const isCatalogPageActive = useMatch(routes.administration.catalog);
  const isCreateTenantPageActive = useMatch(routes.administration.createTenant);
  const isInvitationsPageActive = useMatch(routes.administration.invitations);
  const isToolsPageActive = useMatch(routes.administration.tools);
  const { isAdmin } = useSelector((state: State) => state.authentication.currentUser);
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const navigate = useNavigate();

  const hasShopify = currentTenant.cms === Cms.Shopify;

  const [activeTab, setActiveTab] = useState<number>(() => {
    if (isCatalogPageActive) return TabsNumber.Catalog;
    if (isInvitationsPageActive) return TabsNumber.Invitations;
    if (isCreateTenantPageActive && isAdmin) return TabsNumber.CreateTenant;
    if (isToolsPageActive && isAdmin) return TabsNumber.Tools;
    return hasShopify ? TabsNumber.Catalog : TabsNumber.Invitations;
  });

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
        name: 'Administration',
      }),
    );
  }, []);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) navigate(tab.to);
  }, [activeTab]);

  const tabs: TabProps[] = [
    hasShopify && {
      component: <Catalog style="tab" />,
      enabled: true,
      icon: <CatalogIcon />,
      index: TabsNumber.Catalog,
      label: t('administration:Catalog.Catalog'),
      to: routes.administration.catalog,
    },
    {
      component: <MasterInvitations />,
      enabled: true,
      icon: <PersonAddIcon />,
      index: TabsNumber.Invitations,
      label: t('common:Invitations'),
      to: routes.administration.invitations,
    },
    isAdmin && {
      component: <CreateTenant />,
      enabled: true,
      icon: <DomainAddIcon />,
      index: TabsNumber.CreateTenant,
      label: t('common:CreateTenant'),
      to: routes.administration.createTenant,
    },
    isAdmin && {
      component: <AdminTools />,
      enabled: true,
      icon: <HomeRepairServiceIcon />,
      index: TabsNumber.Tools,
      label: t('common:AdminTools'),
      to: routes.administration.tools,
    },
  ].filter((x) => x);

  return <AdministrationTemplate activeTab={activeTab} onChangeTab={setActiveTab} tabs={tabs} />;
}
