import { EventId, V1 } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { EventFilesUpload } from '../../../../../core/gateways/event-gateway';
import { resetCopyEventTheme } from '../../../../../core/store/slices/event/event-theme.slice';
import { State } from '../../../../../core/store/state';
import { fetchEvents } from '../../../../../core/usecases/event/crud/fetch-events';
import { getSecuredFileUploadUrls } from '../../../../../core/usecases/event/files/get-secured-file-upload-urls';
import { uploadEventFiles } from '../../../../../core/usecases/event/files/upload-event-files';
import { copyEventTheme } from '../../../../../core/usecases/event/theme/copy-event-theme';
import { customiseEventTheme } from '../../../../../core/usecases/event/theme/customise-event-theme';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import ConfirmationDialog from '../../../../templates/dialog/ConfirmationDialog';
import ThemeHeader from '../../../molecules/event/theme/ThemeHeader';
import ThemeForm, { ThemeInputs } from './ThemeForm';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

interface SubmittedData {
  addToCartBackgroundColor: string;
  adminMessageBackgroundColor: string;
  adminMessageIcon?: File;
  brandLogo?: File;
  cartIcon?: File;
  mainFontName: string;
  postEventCover?: File;
  preEventCover?: File;
  removeFromCartBackgroundColor: string;
  storeIcon?: File;
  validateCartBackgroundColor: string;
}

export type SubmittedInputs = ThemeInputs & {
  adminMessageIcon: FileList;
  brandLogo: FileList;
  cartIcon: FileList;
  postEventCover: FileList;
  preEventCover: FileList;
  storeIcon: FileList;
};

interface ThemeProps {
  event: CBOEventReadModel;
  readonly: boolean;
}

// TODO: Add view model and tests
export default function Theme({ event, readonly }: ThemeProps) {
  const dispatch = useDispatch();
  const [showCopyEventConfirmModal, setShowCopyEventConfirmModal] = useState<boolean>(false);
  const [submittedData, setSubmittedData] = useState<SubmittedData>();
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);

  const { status: eventThemeCopyStatus } = useSelector((state: State) => state.events.eventThemeCopy);
  const { status: eventThemeCustomisationStatus } = useSelector((state: State) => state.events.eventThemeCustomisation);
  const { status: eventFilesUploadingStatus } = useSelector((state: State) => state.events.eventFilesUploading);
  const { status: eventUploadFileUrlsFetchingStatus } = useSelector(
    (state: State) => state.events.eventFileUrlsFetching,
  );
  const { status: eventsFetchingStatus } = useSelector((state: State) => state.events.eventsFetching);

  const { events } = useSelector((state: State) => state.events);
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const { t } = useTranslation(['events']);

  const [copiedEventId, setCopiedEventId] = useState<EventId | null>(null);

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'Theme',
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(fetchEvents({ eventsFetchingStatus, tenantId: currentTenant.id }));
  }, [currentTenant.id]);

  useEffect(() => {
    if (eventThemeCopyStatus === 'success') {
      setShowCopyEventConfirmModal(false);
      dispatch(resetCopyEventTheme());
    }
  }, [eventThemeCopyStatus]);

  useEffect(() => {
    if (
      eventUploadFileUrlsFetchingStatus === 'pending' ||
      eventThemeCustomisationStatus === 'pending' ||
      eventFilesUploadingStatus === 'pending'
    ) {
      setSubmissionInProgress(true);
    } else {
      setSubmissionInProgress(false);
    }
  }, [eventUploadFileUrlsFetchingStatus, eventThemeCustomisationStatus, eventFilesUploadingStatus]);

  const dispatchCustomiseEventTheme = useCallback(
    (payload: V1.api.CustomiseEventThemeDTO) => {
      return dispatch(
        customiseEventTheme({
          eventId: event.id,
          payload,
        }),
      );
    },
    [dispatch, event.theme],
  );

  const dispatchGetSecuredFileUploadUrls = useCallback(
    (eventId: EventId) => {
      return dispatch(getSecuredFileUploadUrls({ eventId }));
    },
    [dispatch],
  );

  const copyEvent = () => {
    if (copiedEventId) {
      dispatch(copyEventTheme({ copiedEventId, eventId: event.id }));
    }
  };

  const handleSubmit = (data: SubmittedInputs) => {
    const adminMessageIcon = data.adminMessageIcon[0];
    const brandLogo = data.brandLogo[0];
    const cartIcon = data.cartIcon[0];
    const postEventCover = data.postEventCover[0];
    const preEventCover = data.preEventCover[0];
    const storeIcon = data.storeIcon[0];

    if (adminMessageIcon || brandLogo || cartIcon || postEventCover || preEventCover || storeIcon) {
      const payload: SubmittedData = {
        addToCartBackgroundColor: data.addToCartBackgroundColor,
        adminMessageBackgroundColor: data.adminMessageBackgroundColor,
        adminMessageIcon,
        brandLogo,
        cartIcon,
        mainFontName: data.mainFontName,
        postEventCover,
        preEventCover,
        removeFromCartBackgroundColor: data.removeFromCartBackgroundColor,
        storeIcon,
        validateCartBackgroundColor: data.validateCartBackgroundColor,
      };
      setSubmittedData(payload);
      return dispatchGetSecuredFileUploadUrls(event.id);
    }
    return dispatch(
      dispatchCustomiseEventTheme({
        addToCartBackgroundColor: data.addToCartBackgroundColor,
        adminMessageBackgroundColor: data.adminMessageBackgroundColor,
        adminMessageIconUploaded: false,
        brandLogoUploaded: false,
        cartIconUploaded: false,
        mainFontName: data.mainFontName,
        postEventCoverUploaded: false,
        preEventCoverUploaded: false,
        removeFromCartBackgroundColor: data.removeFromCartBackgroundColor,
        storeIconUploaded: false,
        validateCartBackgroundColor: data.validateCartBackgroundColor,
      }),
    );
  };

  const handleEventFileUrlsFetchingChanged = () => {
    if (eventUploadFileUrlsFetchingStatus !== 'loaded' || !submittedData) {
      return;
    }

    const files: EventFilesUpload = {};
    if (submittedData.adminMessageIcon && event.fileUploadUrls?.adminMessageIconUrl) {
      files.adminMessageIcon = {
        file: submittedData.adminMessageIcon,
        url: event.fileUploadUrls.adminMessageIconUrl,
      };
    }
    if (submittedData.brandLogo && event.fileUploadUrls?.brandLogoUrl) {
      files.brandLogo = {
        file: submittedData.brandLogo,
        url: event.fileUploadUrls.brandLogoUrl,
      };
    }
    if (submittedData.cartIcon && event.fileUploadUrls?.cartIconUrl) {
      files.cartIcon = {
        file: submittedData.cartIcon,
        url: event.fileUploadUrls.cartIconUrl,
      };
    }
    if (submittedData.postEventCover && event.fileUploadUrls?.postEventCoverUrl) {
      files.postEventCover = {
        file: submittedData.postEventCover,
        url: event.fileUploadUrls.postEventCoverUrl,
      };
    }
    if (submittedData.preEventCover && event.fileUploadUrls?.preEventCoverUrl) {
      files.preEventCover = {
        file: submittedData.preEventCover,
        url: event.fileUploadUrls.preEventCoverUrl,
      };
    }
    if (submittedData.storeIcon && event.fileUploadUrls?.storeIconUrl) {
      files.storeIcon = {
        file: submittedData.storeIcon,
        url: event.fileUploadUrls.storeIconUrl,
      };
    }

    if (Object.keys(files).length) {
      dispatch(
        uploadEventFiles({
          eventId: event.id,
          files,
        }),
      );
    }
  };

  const handleFilesUploadingChanged = () => {
    if (eventFilesUploadingStatus !== 'success' || !submittedData) {
      return;
    }

    dispatchCustomiseEventTheme({
      addToCartBackgroundColor: submittedData.addToCartBackgroundColor,
      adminMessageBackgroundColor: submittedData.adminMessageBackgroundColor,
      adminMessageIconUploaded: event.adminMessageIconUploaded,
      brandLogoUploaded: event.brandLogoUploaded,
      cartIconUploaded: event.cartIconUploaded,
      mainFontName: submittedData.mainFontName,
      postEventCoverUploaded: event.postEventCoverUploaded,
      preEventCoverUploaded: event.preEventCoverUploaded,
      removeFromCartBackgroundColor: submittedData.removeFromCartBackgroundColor,
      storeIconUploaded: event.storeIconUploaded,
      validateCartBackgroundColor: submittedData.validateCartBackgroundColor,
    });
    return;
  };

  const handleCopyEventTheme = (_copiedEventId: EventId) => {
    setCopiedEventId(_copiedEventId);
    setShowCopyEventConfirmModal(true);
  };

  return (
    <Container>
      <ThemeHeader event={event} />
      <ThemeForm
        currency={currentTenant.currency}
        // We use a key here to force a full rerender on the child once a theme copy is done.
        // Otherwise, the form images are stuck with the previous values, since they can only be changed on mount or when receiving a new file manually
        event={event}
        eventFilesUploadingStatus={eventFilesUploadingStatus}
        eventUploadFileUrlsFetchingStatus={eventUploadFileUrlsFetchingStatus}
        events={events}
        key={eventThemeCopyStatus === 'success' ? '1' : '2'}
        onCopyEventTheme={handleCopyEventTheme}
        onEventFileUrlsFetchingChanged={handleEventFileUrlsFetchingChanged}
        onFilesUploadingChanged={handleFilesUploadingChanged}
        onSubmit={handleSubmit}
        readonly={readonly}
        submissionInProgress={submissionInProgress}
      />
      <ConfirmationDialog
        content={t('CopyEventConfirm')}
        id="confirm-copy-event"
        keepMounted={true}
        loading={eventThemeCopyStatus === 'pending'}
        onClose={(confirmed: boolean) => (confirmed ? copyEvent() : setShowCopyEventConfirmModal(false))}
        open={showCopyEventConfirmModal}
        title={t('CopyEvent')}
      />
    </Container>
  );
}
