import { Box, styled } from '@mui/material';
import React from 'react';

import { CBOEventDepiction } from '../../../../core/domain/CBOEventDepiction';
import { ProductPagesWithMediaCollectionsCount, ShoppablesCount } from '../../atoms/home/CounterWithUpgradeButton';
import UpcomingLive from '../../atoms/home/UpcomingLive';

const Container = styled(Box)(({ theme }) => ({
  [`& > div.MuiBox-root`]: {
    flexBasis: 'calc(100% / 3)',
    flexGrow: 0,
  },
  display: 'flex',
  gap: theme.spacing(2),
}));

type ActivityOverviewProps = {
  hasShopify: boolean;
  isProductPagesWithMediaCollectionsCountShown: boolean;
  isPublishedShoppablesCountShown: boolean;
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  onCopyEventLink: (eventId: string) => void;
  onCreateEvent: () => void;
  onCreateMediaCollection: () => void;
  onCreateShoppableVideo: () => void;
  onGoToEventSettings: (eventId: string) => void;
  onUpgrade: () => void;
  productPagesWithMediaCollectionsCount: number;
  productsCount: number;
  publishedShoppablesCount: number;
  upcomingEvent: CBOEventDepiction | null;
};

export default function ActivityOverview({
  hasShopify,
  isPublishedShoppablesCountShown,
  loading,
  maxPublishedShoppablesCount,
  onCreateEvent,
  onCreateMediaCollection,
  isProductPagesWithMediaCollectionsCountShown,
  onCopyEventLink,
  onGoToEventSettings,
  onUpgrade,
  onCreateShoppableVideo,
  productPagesWithMediaCollectionsCount,
  productsCount,
  publishedShoppablesCount,
  upcomingEvent,
}: ActivityOverviewProps) {
  return (
    <Container>
      <UpcomingLive
        event={upcomingEvent}
        hasShopify={hasShopify}
        loading={loading}
        onCopyEventLink={onCopyEventLink}
        onCreateEvent={onCreateEvent}
        onGoToEventSettings={onGoToEventSettings}
      />
      {isPublishedShoppablesCountShown && (
        <ShoppablesCount
          count={publishedShoppablesCount}
          loading={loading}
          maxCount={maxPublishedShoppablesCount}
          onCreateShoppableVideo={onCreateShoppableVideo}
          onUpgrade={onUpgrade}
        />
      )}
      {isProductPagesWithMediaCollectionsCountShown && (
        <ProductPagesWithMediaCollectionsCount
          count={productPagesWithMediaCollectionsCount}
          loading={loading}
          maxCount={productsCount}
          onCreateMediaCollection={onCreateMediaCollection}
        />
      )}
    </Container>
  );
}
