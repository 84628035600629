import { OnboardingProgress, OnboardingStep } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const updateOnboardingProgress = createAppAsyncThunk(
  'onboarding/update-progress',
  async (progress: OnboardingProgress, { extra: { onboardingGateway } }): Promise<OnboardingProgress> => {
    await onboardingGateway.updateProgress(progress);
    return progress;
  },
);

export const resetOnboardingProgress = () =>
  updateOnboardingProgress({ eventId: null, step: OnboardingStep.CreateEvent });
