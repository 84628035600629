import { ButtonProps } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModel } from '../../../../core/domain/CBOEventReadModel';
import FormDialog from '../../../templates/dialog/FormDialog';
import UpdateReplayInfosForm from './UpdateReplayInfosForm';
import { UpdateReplayInfosPayload } from './event-details/ReplayLinks';

export interface UpdateReplayInfosFormDialogProps {
  event: CBOEventReadModel;
  onClose: () => void;
  onSubmit: (data: UpdateReplayInfosPayload) => void;
  open: boolean;
  submissionInProgress: boolean;
}

export default function UpdateReplayInfosFormDialog({
  event,
  submissionInProgress,
  onClose,
  open,
  onSubmit,
}: UpdateReplayInfosFormDialogProps) {
  const { t } = useTranslation(['events', 'common']);

  const defaultValues = {
    description: event.description,
    title: event.title,
  };

  const form = useForm<UpdateReplayInfosPayload>({ defaultValues });

  const { handleSubmit, formState, reset, watch } = form;
  const { isDirty } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  const buttonsProps: ButtonProps[] = useMemo(
    () => [
      {
        children: <span>{t('common:Cancel')}</span>,
        color: 'primary',
        disabled: submissionInProgress,
        onClick: onClose,
        variant: 'text',
      },
      {
        children: <span>{t('common:Save')}</span>,
        color: 'primary',
        disabled: !isDirty || submissionInProgress || !watch('title') || !watch('description'),
        onClick: handleSubmit(onSubmit),
        type: 'submit',
        variant: 'contained',
      },
    ],
    [formState, submissionInProgress],
  );

  return (
    <FormDialog buttonsProps={buttonsProps} loading={submissionInProgress} onClose={onClose} open={open}>
      <UpdateReplayInfosForm
        disabled={submissionInProgress}
        form={form}
        hasDialogContainer
        onSubmit={handleSubmit(onSubmit)}
      />
    </FormDialog>
  );
}
