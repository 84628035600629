import { Button, ButtonProps, darken, lighten, styled, useTheme } from '@mui/material';
import React from 'react';

import ShopifyIcon from '../../../icons/ShopifyIcon';

type ShopifyButtonProps = Omit<ButtonProps, 'startIcon'>;

const StyledButton = styled(Button)(({ theme }) => ({
  '&:disabled': {
    backgroundColor: lighten(theme.palette.shopify.background, 0.4),
    color: `${darken(theme.palette.common.white, 0.25)} !important`,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.shopify.background, 0.1),
  },
  alignSelf: 'center',
  backgroundColor: theme.palette.shopify.background,
  borderRadius: '10px',
  color: theme.palette.common.white,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  textTransform: 'initial',

  whiteSpace: 'nowrap',
}));

export default function ShopifyButton(props: ShopifyButtonProps) {
  const theme = useTheme();
  return <StyledButton {...props} startIcon={<ShopifyIcon fill={theme.palette.shopify.main} />} />;
}
