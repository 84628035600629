import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const initChat = createAppAsyncThunk(
  'chat/init',
  async (_, { extra: { chatGateway, tracingGateway } }): Promise<void> => {
    try {
      await chatGateway.init();
    } catch (e: any) {
      console.error('Error while initializing chat', e);
      tracingGateway.captureException(e);
      throw e;
    }
  },
);
