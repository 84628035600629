import { Box, Button, Typography, alpha, styled } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOYoutubeChannel } from '../../../../../../core/domain/CBOYoutubeChannel';
import YoutubeChannelCard from '../../../../atoms/event/YoutubeChannelCard';

const ButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

const ChannelsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  justifyContent: 'center',
}));

interface SelectYoutubeChannelProps {
  areButtonsDisabled: boolean;
  channels: CBOYoutubeChannel[];
  onDisconnect: () => void;
  onSelect: (id: string) => void;
}

export default function SelectYoutubeChannel({
  areButtonsDisabled,
  onDisconnect,
  onSelect,
  channels,
}: SelectYoutubeChannelProps) {
  const { t } = useTranslation('events', { keyPrefix: 'YoutubeRestreaming' });
  const [selectedChannelId, setSelectedChannelId] = useState<string>(channels[0].id);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize=".9em">{t('SelectChannelForRestreaming')}</Typography>
      <ChannelsContainer>
        {channels.map((channel) => (
          <YoutubeChannelCard
            channel={channel}
            key={channel.id}
            onClick={() => setSelectedChannelId(channel.id)}
            selectable
            selected={selectedChannelId === channel.id}
          />
        ))}
      </ChannelsContainer>
      <ButtonsContainer>
        <Button
          disabled={areButtonsDisabled}
          onClick={onDisconnect}
          sx={{
            borderColor: (theme) => alpha(theme.palette.socials.youtube, 0.8),
            color: (theme) => theme.palette.socials.youtube,
            textTransform: 'initial',
          }}
          variant="outlined"
        >
          {t('common:Disconnect')}
        </Button>
        <Button
          disabled={areButtonsDisabled}
          onClick={() => onSelect(selectedChannelId)}
          sx={{ backgroundColor: (theme) => theme.palette.socials.youtube, textTransform: 'initial' }}
          variant="contained"
        >
          {t('SelectChannel')}
        </Button>
      </ButtonsContainer>
    </Box>
  );
}
