import { Box, Typography, styled } from '@mui/material';
import React from 'react';

interface ProductCountProps {
  count: number;
  countPosition?: 'left' | 'right';
  size?: 'medium' | 'small';
  text: string;
}

const Container = styled(Box, { shouldForwardProp: (prop) => prop !== 'size' })<{ size: ProductCountProps['size'] }>(
  ({ size, theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    justifyContent: 'center',
    padding: size === 'small' ? `${theme.spacing(0.5)} ${theme.spacing(2)}` : `${theme.spacing(1)} ${theme.spacing(3)}`,
  }),
);

const Title = styled(Typography, { shouldForwardProp: (prop) => prop !== 'size' })<{ size: ProductCountProps['size'] }>(
  ({ size }) => ({
    fontSize: size === 'small' ? '0.8em' : '0.9em',
  }),
);

const Value = styled(Typography, { shouldForwardProp: (prop) => prop !== 'size' })<{ size: ProductCountProps['size'] }>(
  ({ size }) => ({
    fontSize: size === 'small' ? '1.3em' : '1.6em',
    fontWeight: 'bold',
  }),
);

export default function ProductCount({ count, countPosition = 'left', size = 'medium', text }: ProductCountProps) {
  return (
    <Container size={size} sx={{ flexDirection: countPosition === 'left' ? 'row' : 'row-reverse' }}>
      <Value size={size} variant="h4">
        {count}
      </Value>
      <Title size={size}>{text}</Title>
    </Container>
  );
}
