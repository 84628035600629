import { CBOLandingPageData } from '../../domain/CBOLandingPageData';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchLandingPageParams = {
  tenantId: string;
};

export const fetchLandingPage = createAppAsyncThunk(
  'landing-page/fetch-one',
  async ({ tenantId }: FetchLandingPageParams, { extra: { landingPageGateway } }): Promise<CBOLandingPageData> => {
    return landingPageGateway.fetchOne(tenantId);
  },
);
