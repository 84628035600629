import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const ShoppablesIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 24.055 20">
      <g transform="translate(56 -192)">
        <g strokeWidth={1.5} transform="translate(-51 192)">
          <rect
            fill="none"
            height="18.5"
            rx="2.25"
            stroke={props.sx && 'color' in props.sx ? props.sx.color?.toString() : theme.palette.text.primary}
            width="12.5"
            x="0.75"
            y="0.75"
          />
        </g>
        <path
          d="M280.637,227.53a.677.677,0,0,0-.649.674v6.769a.677.677,0,0,0,1.037.572l5.413-3.382a.677.677,0,0,0,0-1.149l-5.413-3.385h0a.677.677,0,0,0-.387-.1Zm.7,1.9,3.46,2.163-3.46,2.163Z"
          strokeWidth={0.2}
          transform="translate(-326.987 -29.438)"
        />
      </g>
    </SvgIcon>
  );
};
export default ShoppablesIcon;
