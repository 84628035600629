import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

interface ProductActionBarProps extends Pick<UseFormReturn<any>, 'formState'> {
  isPending: boolean;
  mode: 'create' | 'edit';
  onCancel: () => void;
  onDelete: () => void;
}

export default function ProductActionBar(props: ProductActionBarProps) {
  const { formState, isPending, mode, onDelete, onCancel } = props;

  const { t } = useTranslation(['events', 'common']);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 3,
        py: 3,
        width: '100%',
      }}
    >
      <Box>
        {mode === 'edit' && (
          <Button color="error" disabled={isPending} onClick={onDelete} startIcon={<DeleteIcon />} variant="contained">
            <span>{t('DeleteThisProduct')}</span>
          </Button>
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: 3 }}>
        <Button disabled={isPending || !formState.isDirty} onClick={onCancel}>
          <span>{t('common:Cancel')}</span>
        </Button>
        <Button color="primary" disabled={isPending || !formState.isDirty} type="submit" variant="contained">
          <span>{mode === 'edit' ? t('common:Update') : t('AddProduct')}</span>
        </Button>
      </Box>
    </Box>
  );
}
