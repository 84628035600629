import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type DeleteEventParams = {
  eventId: EventId;
};

export const deleteEvent = createAppAsyncThunk(
  'events/delete-event',
  async ({ eventId }: DeleteEventParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.deleteOneEvent(eventId);
  },
);
