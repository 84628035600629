import { SecuredFileUploadUrls } from '../../../domain/Theme';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type GetSecuredFileUploadUrlsParams = {
  eventId: string;
};

export const getSecuredFileUploadUrls = createAppAsyncThunk(
  'events/get-secured-file-upload-urls',
  async ({ eventId }: GetSecuredFileUploadUrlsParams, { extra: { eventGateway } }): Promise<SecuredFileUploadUrls> => {
    return eventGateway.getSecuredFileUploadUrls(eventId);
  },
);
