import { EventStatus } from '@bellepoque/api-contracts';
import MagicContentsIcon from '@mui/icons-material/AutoFixHigh';
import ReplayIcon from '@mui/icons-material/Replay';
import EyeOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { CBOCurrency } from '../../core/domain/CBOCurrency';
import { CBOEventReadModel } from '../../core/domain/CBOEventReadModel';
import { CommandStatus } from '../../core/store/state/utils';
import AnalyticsIcon from '../../icons/AnalyticsIcon';
import { TabProps } from '../components/molecules/Tabs';
import Analytics from '../components/organisms/event/event-details/EventAnalytics';
import MagicContents from '../components/organisms/event/event-details/MagicContents/MagicContents';
import Preview from '../components/organisms/event/event-details/Preview';
import ReplayLinks from '../components/organisms/event/event-details/ReplayLinks';
import { routes } from '../routes';
import EventDetailsTemplate from '../templates/event/EventDetailsTemplate';

enum TabsNumber {
  ReplayLinks = 0,
  Analytics = 1,
  Preview = 2,
  MagicContents = 3,
}

export type ReplayDetailsTemplateProps = {
  currency: CBOCurrency;
  eventIdParam: string;
  onGoToReplayTab: (path: string) => void;
  replay: CBOEventReadModel | null;
  replayClosingStatus: CommandStatus;
};

const ReplayDetailsTemplate: FC<ReplayDetailsTemplateProps> = ({
  currency,
  eventIdParam,
  onGoToReplayTab,
  replay,
  replayClosingStatus,
}) => {
  const { t } = useTranslation(['events', 'common']);

  const isAnalyticsActive = useMatch(routes.replays.analytics({ eventId: ':eventId' }));
  const isPreviewActive = useMatch(routes.replays.preview({ eventId: ':eventId' }));
  const isReplayLinksActive = useMatch(routes.replays.replayLinks({ eventId: ':eventId' }));
  const isMagicContentsActive = useMatch(routes.replays.magicContents({ eventId: ':eventId' }));

  const isPublishedReplay = replay
    ? ![EventStatus.FINISHED, EventStatus.REPLAY_FAILED].includes(replay?.status)
    : false;

  const getCurrentRouteTabNumber = () => {
    if (isAnalyticsActive) return TabsNumber.Analytics;
    if (isPreviewActive) return TabsNumber.Preview;
    if (isReplayLinksActive && isPublishedReplay) return TabsNumber.ReplayLinks;
    if (isMagicContentsActive) return TabsNumber.MagicContents;
    return isPublishedReplay ? TabsNumber.ReplayLinks : TabsNumber.Analytics;
  };

  const [activeTab, setActiveTab] = useState<TabsNumber | undefined>();

  useEffect(() => {
    if (replay) setActiveTab(getCurrentRouteTabNumber());
  }, [replay?.status]);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) onGoToReplayTab(tab.to);
  }, [activeTab]);

  useEffect(() => {
    if (replay?.id === eventIdParam && replayClosingStatus === 'success') {
      setActiveTab(isPublishedReplay ? TabsNumber.ReplayLinks : TabsNumber.Analytics);
    }
  }, [eventIdParam, replay, replayClosingStatus]);

  const loading = !replay;

  const tabs: TabProps[] = replay
    ? [
        {
          component: <ReplayLinks event={replay} />,
          enabled: isPublishedReplay,
          icon: <ReplayIcon />,
          index: TabsNumber.ReplayLinks,
          label: t('Tabs.ReplayLinks'),
          to: routes.replays.replayLinks({ eventId: replay.id }),
        },
        {
          component: <Analytics currency={currency} event={replay} />,
          enabled: true,
          icon: <AnalyticsIcon />,
          index: TabsNumber.Analytics,
          label: t('Tabs.Analytics'),
          to: routes.replays.analytics({ eventId: replay.id }),
        },
        {
          component: <Preview event={replay} />,
          enabled: true,
          icon: <EyeOutlinedIcon />,
          index: TabsNumber.Preview,
          label: t('Tabs.Preview'),
          to: routes.replays.preview({ eventId: replay.id }),
        },
        {
          component: <MagicContents />,
          enabled: true,
          icon: <MagicContentsIcon />,
          index: TabsNumber.MagicContents,
          label: t('Tabs.MagicContents'),
          releaseStatus: 'beta',
          to: routes.replays.magicContents({ eventId: replay.id }),
        },
      ]
    : [];

  if (activeTab === undefined) return null;

  return (
    <EventDetailsTemplate
      activeTab={activeTab}
      loading={loading}
      onChangeTab={setActiveTab}
      tabs={tabs.filter(({ enabled }) => enabled)}
    />
  );
};

export default ReplayDetailsTemplate;
