import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type OpenReplayParams = {
  eventId: EventId;
};

export const openReplay = createAppAsyncThunk(
  'events/open-replay',
  async ({ eventId }: OpenReplayParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.openOneReplay(eventId);
  },
);
