import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import AddProductToShoppableDialog from '../add-product-to-shoppable-dialog/AddProductToShoppableDialog';
import CreateShoppableForm from './CreateShoppableForm';
import { createShoppableFormDialogViewModel } from './ShoppableFormDialogViewModel';
import UpdateShoppableForm from './UpdateShoppableForm';

const urlToFile = async (url: string, fileName: string): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], fileName, { type: data.type });
};

export type ShoppableFormInputs = {
  id: string;
  productId: string;
  title: string;
};

export interface ShoppableFormDialogProps {
  formType: 'create' | 'update';
  onClose: () => void;
  open: boolean;
}

export default function ShoppableFormDialog({ formType, onClose, open }: ShoppableFormDialogProps) {
  const [isAddProductToShoppableDialogOpen, setIsAddProductToShoppableDialogOpen] = useState(false);

  const viewModel = useSelector(createShoppableFormDialogViewModel({ dispatch: useDispatch() }));

  const {
    createShoppableVideo,
    creationStatus,
    currentCatalogProduct,
    currentShoppable,
    fetchThumbnailFileUploadUrl,
    fetchThumbnailFileUploadUrlStatus,
    resetCreate,
    resetFetchThumbnailUrl,
    resetUpdate,
    resetUploadThumbnail,
    tenantId,
    tenantName,
    thumbnailFileUploadingStatus,
    thumbnailFileUploadUrl,
    trackUserJourneyEvent,
    updateShoppableVideo,
    updateStatus,
    uploadThumbnail,
  } = viewModel;

  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [selectedProductImageUrl, setSelectedProductImageUrl] = useState<string | null>(null);
  const [submittedValues, setSubmittedValues] = useState<ShoppableFormInputs | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<CBOCatalogProduct | null>(null);

  const makeDefaultValues = (): ShoppableFormInputs => ({
    id: uuidv4(),
    productId: '',
    title: '',
  });

  const setCurrentShoppableValues = () => {
    if (currentShoppable) {
      reset({
        id: currentShoppable.id,
        productId: currentShoppable.productId,
        title: currentShoppable.title,
      });
      if (currentShoppable?.productId === currentCatalogProduct?.id) {
        setSelectedProduct(currentCatalogProduct);
      }
    }
  };

  const form = useForm<ShoppableFormInputs>({
    defaultValues: makeDefaultValues(),
    mode: 'onChange',
  });

  const { setValue, reset } = form;

  useEffect(() => {
    if (open) {
      trackUserJourneyEvent({
        data: {
          'Tenant id': tenantId,
          'Tenant name': tenantName,
          ...(formType === 'update' && currentShoppable
            ? { 'Shoppable id': currentShoppable.id, 'Shoppable name': currentShoppable.title }
            : {}),
        },
        name: formType === 'create' ? 'Create shoppable' : 'Edit shoppable',
      });
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      resetAll();
    }
  }, [open]);

  useEffect(() => {
    if (creationStatus === 'success' || updateStatus === 'success') {
      resetStatuses();
      onClose();
    }
  }, [creationStatus, updateStatus]);

  useEffect(() => {
    if (
      creationStatus === 'error' ||
      fetchThumbnailFileUploadUrlStatus === 'error' ||
      thumbnailFileUploadingStatus === 'error' ||
      updateStatus === 'error'
    ) {
      setSubmittedValues(null);
      setSubmissionInProgress(false);
      resetStatuses();
    }
  }, [creationStatus, fetchThumbnailFileUploadUrlStatus, thumbnailFileUploadingStatus, updateStatus]);

  useEffect(() => {
    if (formType === 'update' && open && currentCatalogProduct?.id === currentShoppable?.productId) {
      setCurrentShoppableValues();
    }
  }, [currentCatalogProduct, currentShoppable, open]);

  const resetStatuses = () => {
    if (creationStatus !== 'notRequested') {
      resetCreate();
    }

    if (updateStatus !== 'notRequested') {
      resetUpdate();
    }

    if (fetchThumbnailFileUploadUrlStatus !== 'notLoaded') {
      resetFetchThumbnailUrl();
    }

    if (thumbnailFileUploadingStatus !== 'notRequested') {
      resetUploadThumbnail();
    }
  };

  const resetAll = () => {
    if (selectedProduct) {
      setSelectedProduct(null);
    }

    if (selectedProductImageUrl) {
      setSelectedProductImageUrl(null);
    }

    if (submissionInProgress) {
      setSubmissionInProgress(false);
    }

    if (thumbnailFile) {
      setThumbnailFile(null);
    }

    resetStatuses();
    resetForm();
  };

  useEffect(() => {
    handleShoppableThumbnailFileUploadUrlFetchingChanged();
  }, [fetchThumbnailFileUploadUrlStatus]);

  useEffect(() => {
    if (thumbnailFileUploadingStatus === 'success' && submissionInProgress) {
      handleFileUploaded();
    }
  }, [thumbnailFileUploadingStatus]);

  useEffect(() => {
    setValue('productId', selectedProduct?.id ?? '');
  }, [selectedProduct]);

  const handleFileUploaded = () => {
    if (thumbnailFile && submittedValues) {
      saveShoppable(submittedValues);
    }
  };

  const handleShoppableThumbnailFileUploadUrlFetchingChanged = () => {
    if (fetchThumbnailFileUploadUrlStatus === 'loaded' && thumbnailFile && thumbnailFileUploadUrl) {
      uploadThumbnail(thumbnailFile);
    }
  };

  const handleSubmit = async (values: ShoppableFormInputs) => {
    if (formType === 'create' && !selectedProduct) return;

    setSubmissionInProgress(true);
    setSubmittedValues(values);

    let file: File | null = thumbnailFile;

    if (selectedProductImageUrl) {
      file = await urlToFile(selectedProductImageUrl, 'product image');
      setThumbnailFile(file);
    }

    if (file && fetchThumbnailFileUploadUrlStatus === 'notLoaded') {
      fetchThumbnailFileUploadUrl(values.id);
      return;
    }

    saveShoppable(values);
  };

  const saveShoppable = (values: ShoppableFormInputs) => {
    if (formType === 'create' && selectedProduct) {
      createShoppableVideo({
        dto: {
          id: values.id,
          productId: values.productId,
          title: values.title,
        },
        product: selectedProduct,
      });
      return;
    }

    if (formType === 'update') {
      updateShoppableVideo({
        thumbnailUploaded: !!thumbnailFile,
        title: values.title,
      });
    }
  };

  const resetForm = () => {
    reset(makeDefaultValues());
  };

  const handleSelectCustomFile = (file: File) => {
    setSelectedProductImageUrl(null);
    setThumbnailFile(file);
  };

  const handleSelectProductImage = (productImageUrl: string) => {
    setThumbnailFile(null);
    setSelectedProductImageUrl(productImageUrl);
  };

  const handleSelectProduct = (_product: CBOCatalogProduct) => {
    setSelectedProduct(_product);
  };

  const handleUnselectProduct = () => {
    setSelectedProduct(null);
  };

  if (formType === 'create') {
    return (
      <>
        <CreateShoppableForm
          form={form}
          hasDialogContainer
          onClose={onClose}
          onOpenAddProductToShoppableDialog={() => setIsAddProductToShoppableDialogOpen(true)}
          onSelectCustomFile={handleSelectCustomFile}
          onSelectProductImage={handleSelectProductImage}
          onSubmit={handleSubmit}
          onUnselectProduct={handleUnselectProduct}
          open={open}
          product={selectedProduct}
          submissionInProgress={submissionInProgress}
        />
        <AddProductToShoppableDialog
          isOpen={isAddProductToShoppableDialogOpen}
          onClose={() => setIsAddProductToShoppableDialogOpen(false)}
          onSelectProduct={handleSelectProduct}
        />
      </>
    );
  }

  return (
    <UpdateShoppableForm
      currentShoppable={currentShoppable}
      form={form}
      hasDialogContainer
      onClose={onClose}
      onSelectCustomFile={handleSelectCustomFile}
      onSelectProductImage={handleSelectProductImage}
      onSubmit={handleSubmit}
      open={open}
      product={currentCatalogProduct?.id === currentShoppable?.productId ? currentCatalogProduct : null}
      submissionInProgress={submissionInProgress}
    />
  );
}
