import { EventId, ProductId } from '@bellepoque/api-contracts';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../../../../core/domain/CBOCatalogProduct';
import { CBOEventListReadModel } from '../../../../../../core/domain/CBOEventListReadModel';
import { EVENT_PRODUCTS_COUNT_LIMIT } from '../../../../../../core/domain/CBOEventReadModel';
import ProductPrice from '../../../../atoms/ProductPrice';
import { createAddSelectedProductsToEventDialogViewModel } from './AddSelectedProductsToEventDialog.viewmodel';

const PREFIX = 'AddSelectedProductsToEventDialog';

const classes = {
  button: `${PREFIX}-button`,
  buttons: `${PREFIX}-buttons`,
  header: `${PREFIX}-header`,
  layout: `${PREFIX}-layout`,
  linearProgress: `${PREFIX}-linearProgress`,
  paper: `${PREFIX}-paper`,
  productImage: `${PREFIX}-productImage`,
  productTitleInfo: `${PREFIX}-productTitleInfo`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.layout}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    [theme.breakpoints.up(600 + +theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 600,
    },
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + +theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.buttons}`]: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
  },

  [`& .${classes.linearProgress}`]: {
    marginTop: '-4px',
  },

  [`& .${classes.productImage}`]: {
    maxHeight: '100%',
    maxWidth: '100%',
    padding: '10px',
  },

  [`& .${classes.productTitleInfo}`]: {
    marginLeft: '5px',
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '.7em',
  marginTop: '2px',
}));

export type AddSelectedProductsToEventDialogProps = {
  onClose: () => void;
  open: boolean;
  selectedProducts: CBOCatalogProduct[];
};

const findEventById = (eventId: EventId, events: CBOEventListReadModel[]) =>
  events.find(({ id }: CBOEventListReadModel) => id === eventId);

const isProductInEvent = (event: CBOEventListReadModel, productId: ProductId) =>
  !!event.productsIds.find((_productId: string) => _productId === productId);

export default function AddSelectedProductsToEventDialog({
  onClose,
  open,
  selectedProducts,
}: AddSelectedProductsToEventDialogProps) {
  const { t } = useTranslation('administration', { keyPrefix: 'Catalog' });

  const viewModel = useSelector(createAddSelectedProductsToEventDialogViewModel({ dispatch: useDispatch() }));

  const {
    addProductsToEventStatus,
    currency,
    notOpenedToPublicEvents,
    isFetchingEvents,
    addProductsToEvent,
    fetchEvents,
    resetAddProductsToEvent,
    tenantId,
  } = viewModel;

  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  useEffect(() => {
    fetchEvents();
  }, [tenantId]);

  useEffect(() => {
    if (notOpenedToPublicEvents.length === 1) {
      setSelectedEventId(notOpenedToPublicEvents[0].id);
    }
  }, [notOpenedToPublicEvents]);

  useEffect(() => {
    if (addProductsToEventStatus === 'success') {
      resetAddProductsToEvent();
      onClose();
    }
  }, [addProductsToEventStatus, onClose]);

  const areAllProductsAlreadyAdded = () => selectedProducts.every(isProductAlreadyAdded);

  const handleAddProductsToEvent = () => {
    if (selectedEventId) {
      const productsIds = selectedProducts.filter((product) => !isProductAlreadyAdded(product)).map(({ id }) => id);
      addProductsToEvent(productsIds, selectedEventId);
    }
  };

  const handleSelectEvent = (e: SelectChangeEvent) => setSelectedEventId(e.target.value);

  const hasSelectedTooManyProducts = (eventId: string) => {
    const event = notOpenedToPublicEvents.find(({ id }) => id === eventId);
    if (!event) return false;

    const productsThatCanBeAdded = selectedProducts.filter((product) => !isProductAlreadyAdded(product));
    return productsThatCanBeAdded.length + event.productsIds.length > EVENT_PRODUCTS_COUNT_LIMIT;
  };

  const isProductAlreadyAdded = (product: CBOCatalogProduct) => {
    if (!selectedEventId) return false;

    const selectedEvent = findEventById(selectedEventId, notOpenedToPublicEvents);

    if (!selectedEvent) return false;

    return isProductInEvent(selectedEvent, product.id);
  };

  const renderEventsSelection = useCallback(
    () =>
      notOpenedToPublicEvents.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel>{t('events:Event')}</InputLabel>
          <Select label={t('events:Event')} onChange={handleSelectEvent} value={selectedEventId ?? ''}>
            {notOpenedToPublicEvents.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        // eslint-disable-next-line react/no-unescaped-entities
        <p style={{ textAlign: 'center' }}>{t('AddToEventDialog.NoEvents')}</p>
      ),
    [handleSelectEvent, notOpenedToPublicEvents, selectedEventId],
  );

  const renderProducts = useCallback(() => {
    return selectedProducts.map((product) => {
      const productPriceVariant = product.hasVariants ? 'range' : 'simple';
      const isAlreadyAdded = isProductAlreadyAdded(product);

      return (
        <Grid display="flex" height="60px" item key={product.id} xs={12}>
          <Grid container width="100%">
            <Grid alignItems="center" height="100%" item>
              <img alt={product.title} className={classes.productImage} src={product.imageUrls[0]} />
            </Grid>
            <Grid alignItems="center" display="flex" flex={1} item>
              <span
                style={{
                  display: 'block',
                  fontWeight: 'bold',
                  textDecoration: isAlreadyAdded ? 'line-through' : 'none',
                  textTransform: 'uppercase',
                }}
              >
                {product.title}
              </span>
              {isAlreadyAdded && (
                <i className={classes.productTitleInfo}>
                  {'- '}
                  {t('AddToEventDialog.AlreadyAdded')}
                </i>
              )}
            </Grid>
            <Grid alignItems="center" display="flex" item>
              <ProductPrice currency={currency} price={product.price} variant={productPriceVariant} />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }, [selectedProducts]);

  const renderProgress = () => <LinearProgress className={classes.linearProgress} />;

  return (
    <StyledDialog className={classes.layout} onClose={onClose} open={open}>
      <Paper className={classes.paper}>
        <Typography className={classes.header} component="h1" variant="h4">
          {t('AddToEventDialog.Title', { count: selectedProducts.length })}
        </Typography>
        <Typography component="p" marginBottom={'15px'}>
          {t('AddToEventDialog.Text', { count: selectedProducts.length })}
        </Typography>
        <Grid borderBottom="solid 1px grey" container>
          {renderProducts()}
        </Grid>
        <Box flex={1} paddingTop={'20px'}>
          {isFetchingEvents ? renderProgress() : renderEventsSelection()}
        </Box>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            color="primary"
            disabled={addProductsToEventStatus === 'pending'}
            onClick={onClose}
            startIcon={<ClearIcon />}
            variant="contained"
          >
            <span>{t('common:Cancel')}</span>
          </Button>

          <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
            <Button
              className={classes.button}
              color="primary"
              disabled={
                addProductsToEventStatus === 'pending' ||
                areAllProductsAlreadyAdded() ||
                !selectedEventId ||
                hasSelectedTooManyProducts(selectedEventId)
              }
              onClick={handleAddProductsToEvent}
              type="submit"
              variant="contained"
            >
              <span>{t('common:Confirm')}</span>
            </Button>

            <ErrorText
              sx={{ visibility: selectedEventId && hasSelectedTooManyProducts(selectedEventId) ? 'visible' : 'hidden' }}
            >
              {t('events:ProductsLimitExceeded')}
            </ErrorText>
          </Box>
        </div>
      </Paper>
      {addProductsToEventStatus === 'pending' && renderProgress()}
    </StyledDialog>
  );
}
