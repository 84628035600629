import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';
import { Box, Button, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlanIcon from '../../../../icons/PlanIcon';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

type PlanIndicatorProps = {
  currentPlanId: BillingPlanId;
  hasShopify: boolean;
  onSeeAllPlans: () => void;
};

export function PlanIndicator({ currentPlanId, hasShopify, onSeeAllPlans }: PlanIndicatorProps) {
  const { t } = useTranslation('home', { keyPrefix: 'Plan' });
  return (
    <Container>
      {hasShopify && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
        >
          {t('YourPlan', { planName: BILLING_PLANS[currentPlanId].name })}
        </Typography>
      )}
      <Button color="primary" onClick={onSeeAllPlans} startIcon={<PlanIcon fontSize="small" />} variant="outlined">
        {t('SeeAllPlans')}
      </Button>
    </Container>
  );
}
