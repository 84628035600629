import { Box, styled } from '@mui/material';
import React from 'react';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { ThemePreviewActions } from '../../../molecules/event/ThemePreviewActions';
import { ThemePreviewChat } from '../../../molecules/event/ThemePreviewChat';
import { ThemePreviewHeader } from '../../../molecules/event/ThemePreviewHeader';
import { ThemePreviewProducts } from '../../../molecules/event/ThemePreviewProducts';
import { ThemeInputs } from './ThemeForm';

const ThemePreviewWrapper = styled(Box)({
  alignItems: 'stretch',
  backgroundColor: '#505050',
  borderRadius: '28px',
  display: 'flex',
  flexDirection: 'column',
  inset: '0px',
  overflow: 'hidden',
  pointerEvents: 'none',
  position: 'sticky',
  textAlign: 'left',
  top: '50px',

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  // iPhone SE dimensions
  height: '667px',
  width: '375px',
  /* eslint-enable sort-keys-fix/sort-keys-fix */
});

const ContentWrapper = styled('div')({
  flex: '1 1 0%',
  position: 'relative',
});

const PreviewBorder = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderWidth: 1,
  height: '37px',
  width: '100%',
}));

interface ThemePreviewProps {
  adminMessageIcon: string | null;
  brandLogo: string | null;
  currency: CBOCurrency;
  theme: ThemeInputs;
  title: string;
}

export default function ThemePreview({ adminMessageIcon, brandLogo, currency, title, theme }: ThemePreviewProps) {
  return (
    <Box display="flex" flex={1}>
      <ThemePreviewWrapper sx={{ boxShadow: 3, fontFamily: theme.mainFontName }}>
        <PreviewBorder />
        <ContentWrapper>
          <ThemePreviewHeader brandLogo={brandLogo} title={title} />
          <ThemePreviewActions addToCartBackgroundColor={theme.addToCartBackgroundColor} />
          <ThemePreviewChat
            adminMessageBackgroundColor={theme.adminMessageBackgroundColor}
            adminMessageIcon={adminMessageIcon}
          />
        </ContentWrapper>
        <ThemePreviewProducts addToCartBackgroundColor={theme.addToCartBackgroundColor} currency={currency} />
        <PreviewBorder />
      </ThemePreviewWrapper>
    </Box>
  );
}
