import { ProductId } from '@bellepoque/api-contracts';
import { Box, LinearProgress, Pagination, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CatalogSortType } from '../../../../../core/store/state/catalog';
import ConfirmDeleteDialog from '../../../../templates/dialog/ConfirmDeleteDialog';
import AdministrationHeader from '../../../atoms/administration/AdministrationHeader';
import Toolbar from '../../../molecules/administration/catalog/Toolbar';
import { createCatalogViewModel } from './Catalog.viewmodel';
import ProductList from './ProductList';
import AddSelectedProductsToEventDialog from './add-selected-products-to-event-dialog/AddSelectedProductsToEventDialog';

const PREFIX = 'Catalog';

const classes = {
  gridRow: `${PREFIX}-grid-row`,
};

const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  [`& .${classes.gridRow}`]: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}2B !important`,
      cursor: 'pointer',
    },
  },
}));

type CatalogProps = {
  style: 'page' | 'tab';
};

export default function Catalog({ style }: CatalogProps) {
  const { t } = useTranslation('administration', { keyPrefix: 'Catalog' });

  const viewModel = useSelector(createCatalogViewModel({ dispatch: useDispatch() }));

  const {
    apiKey,
    canRenewApiKey,
    changePage,
    clearSelectedProducts,
    currency,
    currentPage,
    deleteProducts,
    deleteStatus,
    fetchProductsWithoutFilters,
    hasShopify,
    pageCount,
    products,
    productsFetchingStatus,
    renewApiKey,
    renewApiKeyStatus,
    resetDeleteProducts,
    resetRenewApiKey,
    searchProducts,
    selectedProducts,
    selectProduct,
    sort,
    sortProducts,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
  } = viewModel;

  const [isAddProductsToEventModalOpen, setIsAddProductsToEventModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const [productsToAddToEvent, setProductsToAddToEvent] = useState<CBOCatalogProduct[]>([]);
  const [productsIdsToDelete, setProductsIdsToDelete] = useState<ProductId[]>([]);

  const areProductsLoading = productsFetchingStatus === 'pending';

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
      name: 'Catalog',
    });
  }, []);

  useEffect(() => {
    fetchProductsWithoutFilters();
    clearSelectedProducts();
  }, [tenantId]);

  useEffect(() => {
    if (renewApiKeyStatus === 'success') {
      resetRenewApiKey();
    }
  }, [renewApiKeyStatus]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      refreshProducts();
      resetDeleteProducts();
    }
  }, [deleteStatus]);

  const handleAddProductsToEvent = (products: CBOCatalogProduct[]) => {
    setProductsToAddToEvent(products);
    setIsAddProductsToEventModalOpen(true);
  };

  const handleChangePage = (page: number) => {
    changePage(page);
  };

  const handleChangeSort = (sort: CatalogSortType) => {
    sortProducts(sort);
  };

  const handleClearSelection = () => {
    clearSelectedProducts();
  };

  const handleCloseAddProductsToEventModal = () => {
    setIsAddProductsToEventModalOpen(false);
  };

  const handleCloseConfirmDeleteModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };

  const handleDeleteProducts = (productsIds: ProductId[]) => {
    setIsConfirmDeleteModalOpen(true);
    setProductsIdsToDelete(productsIds);
  };

  const handleRenewApiKey = () => {
    renewApiKey();
  };

  const handleSearchProducts = (search: string) => {
    searchProducts(search);
  };

  const handleToggleSelectProduct = (product: CBOCatalogProduct, isSelected: boolean) => {
    selectProduct(product, isSelected);
  };

  const refreshProducts = () => {
    fetchProductsWithoutFilters();
  };

  return (
    <Root
      id="product-list"
      sx={{
        flexGrow: 1,
      }}
    >
      {style === 'tab' && <AdministrationHeader title={t('Catalog')} />}

      <Box p={2} pl={style === 'page' ? 5 : 0} textAlign="initial">
        {style === 'page' && (
          <Typography fontSize="2.5em" variant="h4">
            {t('Catalog')}
          </Typography>
        )}

        <Toolbar
          canRenewApiKey={canRenewApiKey}
          hideApiKey={hasShopify}
          onRenewApiKey={handleRenewApiKey}
          onSearchProduct={handleSearchProducts}
          renewApiKeyStatus={renewApiKeyStatus}
          tenantApiKey={apiKey}
        />
      </Box>

      {areProductsLoading && <LinearProgress />}
      {!areProductsLoading && (
        <ProductList
          currency={currency}
          onAddToEvent={handleAddProductsToEvent}
          onChangeSort={handleChangeSort}
          onClearSelection={handleClearSelection}
          onDelete={handleDeleteProducts}
          onRefresh={() => handleChangePage(1)}
          onSelect={(product) => handleToggleSelectProduct(product, true)}
          onUnselect={(product) => handleToggleSelectProduct(product, false)}
          products={products}
          selectedProducts={selectedProducts}
          sort={sort}
        />
      )}

      <Box style={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: 20, paddingBottom: 20 }}>
        <Pagination
          count={pageCount}
          hidden={pageCount <= 1 || productsFetchingStatus === 'pending'}
          onChange={(_e, page) => handleChangePage(page)}
          page={currentPage}
        />
      </Box>

      {isConfirmDeleteModalOpen && (
        <ConfirmDeleteDialog
          deleteCount={productsIdsToDelete.length}
          deleteFunction={() => deleteProducts(productsIdsToDelete)}
          deleteStatus={deleteStatus}
          onClose={handleCloseConfirmDeleteModal}
          open={isConfirmDeleteModalOpen}
        />
      )}

      {isAddProductsToEventModalOpen && (
        <AddSelectedProductsToEventDialog
          onClose={handleCloseAddProductsToEventModal}
          open={isAddProductsToEventModalOpen}
          selectedProducts={productsToAddToEvent}
        />
      )}
    </Root>
  );
}
