import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function AnalyticsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 19.303 19.303" {...props}>
      <g transform="translate(0.3 0.3)">
        <g>
          <path
            d="M418.417,777h15.869a1.417,1.417,0,0,1,1.417,1.417v5.668a1.417,1.417,0,0,1-1.417,1.417H418.417A1.417,1.417,0,0,1,417,784.084v-5.668A1.417,1.417,0,0,1,418.417,777Zm0,.567a.85.85,0,0,0-.85.85v5.668a.85.85,0,0,0,.85.85h15.869a.85.85,0,0,0,.85-.85v-5.668a.85.85,0,0,0-.85-.85Zm0,9.635h5.668a1.417,1.417,0,0,1,1.417,1.417v5.668a1.417,1.417,0,0,1-1.417,1.417h-5.668A1.417,1.417,0,0,1,417,794.286v-5.668A1.417,1.417,0,0,1,418.417,787.2Zm0,.567a.85.85,0,0,0-.85.85v5.668a.85.85,0,0,0,.85.85h5.668a.85.85,0,0,0,.85-.85v-5.668a.85.85,0,0,0-.85-.85Zm10.2-.567h5.667a1.417,1.417,0,0,1,1.417,1.417v5.668a1.417,1.417,0,0,1-1.417,1.417h-5.667a1.417,1.417,0,0,1-1.417-1.417v-5.668A1.417,1.417,0,0,1,428.618,787.2Zm0,.567a.85.85,0,0,0-.85.85v5.668a.85.85,0,0,0,.85.85h5.667a.85.85,0,0,0,.85-.85v-5.668a.85.85,0,0,0-.85-.85Zm-5.668,3.684a.283.283,0,0,1,.567,0,2.267,2.267,0,1,1-2.267-2.267.283.283,0,1,1,0,.567,1.7,1.7,0,1,0,1.7,1.7Zm6.688-.057a.283.283,0,0,1-.34-.453l1.134-.85a.283.283,0,0,1,.3-.027l.968.484,1.558-1.3a.283.283,0,1,1,.363.435l-1.7,1.417a.283.283,0,0,1-.308.036l-.977-.488Zm.68,2.04a.283.283,0,1,1,0-.567h2.267a.283.283,0,1,1,0,.567Zm-8.758-10.759-2.194.823a.283.283,0,0,1-.2-.531l2.267-.85a.284.284,0,0,1,.15-.013l3.03.551,3.036-1.38a.284.284,0,0,1,.192-.015l2.98.813,2.439-1.9a.283.283,0,1,1,.348.447l-2.55,1.984a.284.284,0,0,1-.249.05l-3.018-.823-3.024,1.375a.283.283,0,0,1-.168.021Zm.824-2.56a.283.283,0,1,1,0-.567h2.834a.283.283,0,1,1,0,.567Zm-2.55.85a1.134,1.134,0,1,1,1.133-1.134A1.133,1.133,0,0,1,419.834,780.967Zm0-.567a.567.567,0,1,0-.567-.567A.567.567,0,0,0,419.834,780.4Z"
            stroke={props.color}
            strokeWidth="0.5"
            transform="translate(-417 -777)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
