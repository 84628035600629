import { CBOKlaviyoList } from '../../domain/CBOKlaviyoList';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchKlaviyoListsParams = {
  apiKey: string;
  tenantId: string;
};

export const fetchKlaviyoLists = createAppAsyncThunk(
  'klaviyo/fetch-lists',
  async (params: FetchKlaviyoListsParams, { extra: { klaviyoGateway } }): Promise<CBOKlaviyoList[]> => {
    return klaviyoGateway.fetchLists(params.tenantId, params.apiKey);
  },
);
