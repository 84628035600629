import { CBOTenantStatistics } from '../../domain/CBOTenantStatistics';
import { QueryResult } from './utils';

export interface TenantStatisticsCommandsQueriesState {
  tenantStatisticsFetching: QueryResult;
}

export interface TenantStatisticsState extends TenantStatisticsCommandsQueriesState {
  tenantStatistics: CBOTenantStatistics;
}

export const INITIAL_TENANT_STATISTICS: CBOTenantStatistics = {
  events: {
    eCommerce: {
      addedProducts: {
        live: 0,
        replay: 0,
      },
      cartsAmounts: {
        live: 0,
        replay: 0,
      },
      filledCarts: {
        live: 0,
        replay: 0,
      },
      validatedCarts: {
        live: 0,
        replay: 0,
      },
      validatedCartsAmounts: {
        live: 0,
        replay: 0,
      },
      validatedProducts: {
        live: 0,
        replay: 0,
      },
    },
    engagement: {
      chatMessages: 0,
      chatUsers: 0,
      likes: {
        live: 0,
        replay: 0,
      },
      reminders: {
        calendar: 0,
        sms: 0,
      },
      seenProductPages: {
        live: 0,
        replay: 0,
      },
    },
    eventStatisticsPreviews: [],
    liveCount: 0,
    traffic: {
      averageAttendancePercentage: {
        live: 0,
        replay: 0,
      },
      averageAttendanceTime: {
        live: 0,
        replay: 0,
      },
      deviceCounts: {
        desktop: 0,
        mobile: 0,
        tablet: 0,
      },
      maxSimultaneousViewers: 0,
      totalAttendanceTime: {
        live: 0,
        replay: 0,
      },
      totalLiveDuration: 0,
      uniqueViewers: {
        live: 0,
        replay: 0,
      },
      views: {
        live: 0,
        replay: 0,
      },
    },
  },
  shoppables: {
    shoppableVideoStatisticsPreviews: [],
    traffic: {
      views: 0,
    },
  },
};

export const INITIAL_TENANT_STATISTICS_STATE: TenantStatisticsState = {
  tenantStatistics: INITIAL_TENANT_STATISTICS,
  tenantStatisticsFetching: {
    errors: [],
    status: 'notLoaded',
  },
};
