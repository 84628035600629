export default {
  ConversionTrackerDisclaimer:
    'Order and Sales data are only logged if you have accepted to update our LiveMeUp app in your Shopify back office since May 1st 2024.',
  ECommerce: {
    AddedProducts: 'Added products',
    AmountInCarts: 'Amount in carts',
    AmountInCheckOut: 'Amount in check out',
    AveragePurchasedCartValue: 'Average order value',
    AverageValidatedCartValue: 'Amount per cart in checkout',
    AverageValidatedProductValue: 'Amount per validated product',
    CartsInCheckOut: 'Carts in check out',
    FilledCarts: 'Filled carts',
    NonValidatedCarts: 'Non validated carts',
    Orders: 'Orders',
    PurchasedAmounts: 'Total sales',
    PurchasedCarts: 'Purchased carts',
    Sales: 'Sales',
    Title: 'Ecommerce',
  },
  Engagement: {
    ChatUsers: 'Chat users',
    Likes: 'Likes',
    Reminders: {
      Calendar: 'Calendar',
      SetReminders: 'Set reminders',
      Sms: 'SMS',
    },
    SeenProductPages: 'Seen product pages',
    Title: 'Engagement',
    UserMessages: 'User messages',
  },
  Intro: {
    ChatUsers: 'Chat users',
    LiveEvents: 'Live events',
    MostPopularEvent: 'Most popular event',
    PurchasedAmounts: 'Sales',
    PurchasedCarts: 'Orders',
    ValidatedCarts: 'Validated carts',
    Views: 'Views',
  },
  Live: 'Live',
  LiveAndReplays: 'Live and replays',
  NoLiveReplayStats: 'Broadcast your first live event to access the statistics dashboard.',
  NoLiveReplayStatsDescription:
    'Track the success of your Live and Replay events with our Traffic, Engagement and Commerce data insights.',
  NoShoppableVideoStats: 'Display your first Playlist to access this dashboard.',
  NoShoppableVideoStatsDescription: 'Understand how shoppable videos perform on your store.',
  NotApplicable: 'N/A',
  Produit: 'Product',
  Replay: 'Replay',
  Reports: {
    LiveUsageInMin: {
      text: 'Live Usage in Min',
    },
    LiveViewers: {
      helperText: 'Unique Live Viewers',
      text: 'Live viewers',
    },
    ReplayUsageInMin: {
      text: 'Replay Usage in Min',
    },
    ReplayViewers: {
      helperText: 'Unique Replay Viewers',
      text: 'Replay viewers',
    },
    SMSSent: {
      helperText: 'Sent SMS live reminders',
      text: 'Sent SMS',
    },
    ShoppableVideoViewers: {
      helperText: 'Unique shoppable video viewers',
      text: 'Shoppable video viewers',
    },
  },
  ShoppableVideoStatisticsPreview: {
    Product: 'Product',
    PurchasedAmounts: 'Sales',
    PurchasedCarts: 'Orders',
    SectionTitle: 'Shoppable video ranking',
    Title: 'Title',
    Views: 'Views',
  },
  ShoppableVideos: 'Shoppable videos',
  Statistics: 'Statistics',
  StatsByEvent: {
    ProductCount: 'Nb of products',
    ShowReport: 'Show report',
    Title: 'Live reports',
  },
  StatsByProduct: {
    Cart: 'Cart',
    Checkout: 'Checkout',
    Popularity: 'Popularity',
    Purchased: 'Purchased',
    Title: 'Product stats',
    View: 'View',
    View_other: 'Views',
  },
  Total: 'Total',
  Traffic: {
    AverageAttendanceTime: 'Average attendance time',
    Devices: {
      Desktop: 'Desktop',
      Mobile: 'Mobile',
      Tablet: 'Tablet',
      Title: 'Devices',
    },
    MaxSimultaneousViewers: 'Max number of simultaneous viewers',
    Title: 'Traffic',
    TotalAttendanceTime: 'Total attendance time',
    TotalLiveDuration: 'Total live duration',
    UniqueViewers: 'Unique viewers',
    Viewers: 'Viewers',
    Views: 'Views',
  },
};
