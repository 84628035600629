import { EventId } from '@bellepoque/api-contracts';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventStatisticsPreview } from '../../../../core/domain/CBOTenantStatistics';
import SectionTitle from '../../atoms/analytics/SectionTitle';
import EventListRow from '../../molecules/analytics/EventListRow';

interface EventListSectionProps {
  eventStatisticsPreviews: CBOEventStatisticsPreview[];
  onShowEventStatistics: (eventId: EventId) => void;
}

const sortEventsByShowTimeDesc = (a: CBOEventStatisticsPreview, b: CBOEventStatisticsPreview) => {
  if (a.showTime === null && b.showTime === null) return 0;
  if (a.showTime === null) return 1;
  if (b.showTime === null) return -1;
  return b.showTime.getTime() - a.showTime.getTime();
};

export default function EventListSection({ eventStatisticsPreviews, onShowEventStatistics }: EventListSectionProps) {
  const { t } = useTranslation('analytics');

  const renderEventRow = (event: CBOEventStatisticsPreview) => (
    <EventListRow event={event} key={event.id} onShowReport={() => onShowEventStatistics(event.id)} />
  );

  const sortedEvents = [...eventStatisticsPreviews].sort(sortEventsByShowTimeDesc);

  return (
    <Box>
      <SectionTitle content={t('StatsByEvent.Title')} />

      <Grid container marginTop={4}>
        <Grid item xs={12}>
          <Grid alignItems="flex-start" columnGap={8} container>
            <Grid item width="40px" />
            <Grid item xs={1.5}>
              <Typography variant="subtitle2">{t('events:Date')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">{t('events:Title')}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2">{t('StatsByEvent.ProductCount')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {sortedEvents.map(renderEventRow)}
      </Grid>
    </Box>
  );
}
