/**
 * A valid url that launches the player at a certain time code can look like this:
 * www.tenant-website.com/page?autoplay-live-shopping-be={eventId}&t={timeCode}
 **/

const tags = {
  autoplay: 'autoplay-live-shopping-be',
  timeCode: 't',
};

type MakeAutoplayUrlOptions = {
  baseUrl: string | null;
  eventId: string;
  timeCode?: number;
};

export const makeAutoplayUrl = (options: MakeAutoplayUrlOptions) => {
  const params = new URLSearchParams();
  params.append(tags.autoplay, options.eventId);
  if (options.timeCode) params.append(tags.timeCode, options.timeCode.toString());

  return `${options.baseUrl ?? ''}?${params.toString()}`;
};
