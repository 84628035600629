export default {
  Administration: 'Administration',
  Analytics: 'Analytics',
  Catalog: 'Catalog',
  Events: 'Live events',
  HelpAndSupport: 'Help & support',
  Home: 'Home',
  Integrations: 'Integrations',
  LiveShoppingPage: 'Live shopping page',
  Multistream: 'Multistream',
  OnSiteVideos: 'Playlists',
  Replays: 'Replays',
  Shoppables: 'Shoppables',
};
