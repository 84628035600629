import { EventStatus, V1 } from '@bellepoque/api-contracts';

import { CBOEventListReadModel } from '../../core/domain/CBOEventListReadModel';
import { CBOReplayChapter, CBOReplaysChapters } from '../../core/domain/CBOReplaysChapters';

export function createEventListReadModelFromCreateDTO(dto: V1.api.CreateEventDTO): CBOEventListReadModel {
  return {
    ...dto,
    chapters: [],
    image: null,
    productsIds: [],
    remindersCount: 0,
    replayFileUrl: null,
    showTime: dto.showTime ? new Date(dto.showTime) : null,
    status: dto.showTime ? EventStatus.PLANNED : EventStatus.DRAFT,
    videoDuration: null,
  };
}

export const toCBOReplayChapter = (dto: V1.api.ReplayChapterDTO, replayId: string): CBOReplayChapter => ({
  ...dto,
  duration: dto.endAt - dto.startAt,
  id: `${replayId}-${dto.startAt}-${dto.endAt}`,
  replayId,
});

export function toCBOReplaysChapters(dto: V1.api.ReplaysChaptersDTO): CBOReplaysChapters {
  const chapters = Object.entries(dto).reduce((acc: CBOReplaysChapters, [replayId, replayChapters]) => {
    acc[replayId] = replayChapters.map((chapter) => toCBOReplayChapter(chapter, replayId));
    return acc;
  }, {});

  return chapters;
}
