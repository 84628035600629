import { Box, Link } from '@mui/material';
import React from 'react';

import DownloadReplayButton from '../../../atoms/event/DownloadReplayButton';

interface ReplayDownloadLinksProps {
  replayFileUrl: string | null;
}

export default function ReplayDownloadLinks({ replayFileUrl }: ReplayDownloadLinksProps) {
  return (
    <Box alignItems="center" display="flex" gap={1}>
      {!!replayFileUrl && (
        <Link href={replayFileUrl} target="_blank" underline="none">
          <DownloadReplayButton link={replayFileUrl} />
        </Link>
      )}
    </Box>
  );
}
