import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { eventToUserJourneyData } from '../../../../../../utils/event-to-user-journey-data';
import MagicContentsTemplate from '../../../../../templates/event/MagicContentsTemplate';
import { createMagicContentsViewModel } from './MagicContents.viewmodel';

const MagicContents = () => {
  const viewModel = useSelector(createMagicContentsViewModel({ dispatch: useDispatch() }));

  const {
    currentEvent,
    eventId,
    fetchMagicContents,
    generateMagicContent,
    isTranscriptAvailable,
    magicContents,
    magicContentsFetchingStatus,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    transcriptReleaseDate,
    wasEventAiredAfterTranscriptReleaseDate,
  } = viewModel;

  useEffect(() => {
    if (wasEventAiredAfterTranscriptReleaseDate) {
      fetchMagicContents();
    }
  }, [eventId]);

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        ...(currentEvent ? eventToUserJourneyData(currentEvent) : {}),
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Magic contents',
    });
  }, []);

  return (
    <MagicContentsTemplate
      isTranscriptAvailable={isTranscriptAvailable}
      magicContents={magicContents}
      magicContentsFetchingStatus={magicContentsFetchingStatus}
      onGenerateMagicContent={generateMagicContent}
      transcriptReleaseDate={transcriptReleaseDate}
      wasEventAiredAfterTranscriptReleaseDate={wasEventAiredAfterTranscriptReleaseDate}
    />
  );
};

export default MagicContents;
