import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_EVENTS_STATE } from '../../state/events';
import { builderChaptersReducers } from './event-chapters.slice';
import { buildChatReducers } from './event-chat.slice';
import { buildCrudReducers } from './event-crud.slice';
import { buildFilesReducers } from './event-files.slice';
import { buildLiveReducers } from './event-live.slice';
import { buildMagicContentReducers } from './event-magic-content.slice';
import { buildProductsReducers } from './event-products.slice';
import { EventRealtimeActions, buildRealtimeReducer } from './event-realtime.slice';
import { buildStatisticsReducer } from './event-statistics.slice';
import { EventSynchronizeActions, buildSynchronizeReducer } from './event-synchronize.slice';
import { buildThemeReducers } from './event-theme.slice';
import { buildUpcomingEventReducers } from './event-upcoming.slice';

export type EventsSliceActions = EventRealtimeActions | EventSynchronizeActions;

export const eventsSlice = createSlice({
  extraReducers: (builder) => {
    builderChaptersReducers(builder);
    buildChatReducers(builder);
    buildCrudReducers(builder);
    buildFilesReducers(builder);
    buildLiveReducers(builder);
    buildMagicContentReducers(builder);
    buildProductsReducers(builder);
    buildRealtimeReducer(builder);
    buildStatisticsReducer(builder);
    buildSynchronizeReducer(builder);
    buildThemeReducers(builder);
    buildUpcomingEventReducers(builder);
  },
  initialState: INITIAL_EVENTS_STATE,
  name: 'events',
  reducers: {},
});
