import { OnboardingStep } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { createEvent } from '../../usecases/event/crud/create-event';
import { fetchOnboardingProgress } from '../../usecases/onboarding/fetch-onboarding-progress';
import { skipOnboarding } from '../../usecases/onboarding/skip-onboarding';
import { updateOnboardingProgress } from '../../usecases/onboarding/update-onboarding-progress';
import { INITIAL_ONBOARDING_STATE, OnboardingState } from '../state/onboarding';
import { errorStatus, loadedStatus, pendingStatus, successStatus } from '../utils';

const buildOnboardingCrudReducers = (builder: ActionReducerMapBuilder<OnboardingState>) => {
  builder.addCase(fetchOnboardingProgress.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('progressFetching'),
    progress: action.payload,
  }));
  builder.addCase(fetchOnboardingProgress.pending, (state) => ({
    ...state,
    ...pendingStatus('progressFetching'),
  }));
  builder.addCase(fetchOnboardingProgress.rejected, (state, action) => ({
    ...state,
    ...errorStatus('progressFetching', [action.error]),
  }));
  builder.addCase(updateOnboardingProgress.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('progressUpdate'),
    progress: action.payload,
  }));
  builder.addCase(updateOnboardingProgress.pending, (state) => ({
    ...state,
    ...pendingStatus('progressUpdate'),
  }));
  builder.addCase(updateOnboardingProgress.rejected, (state, action) => ({
    ...state,
    ...errorStatus('progressUpdate', [action.error]),
  }));
};

const buildOnboardingSkipReducers = (builder: ActionReducerMapBuilder<OnboardingState>) => {
  builder.addCase(skipOnboarding.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('progressUpdate'),
    progress: action.payload,
  }));
  builder.addCase(skipOnboarding.pending, (state) => ({
    ...state,
    ...pendingStatus('progressUpdate'),
  }));
  builder.addCase(skipOnboarding.rejected, (state, action) => ({
    ...state,
    ...errorStatus('progressUpdate', [action.error]),
  }));
};

const buildEventCreationReducers = (builder: ActionReducerMapBuilder<OnboardingState>) => {
  builder.addCase(createEvent.fulfilled, (state, action) => {
    if (state.progress?.step === OnboardingStep.Completed) return state;
    return {
      ...state,
      progress: {
        eventId: action.payload.id,
        step: OnboardingStep.CreateEvent,
      },
    };
  });
};

export const onboardingSlice = createSlice({
  extraReducers: (builder) => {
    buildOnboardingCrudReducers(builder);
    buildOnboardingSkipReducers(builder);
    buildEventCreationReducers(builder);
  },
  initialState: INITIAL_ONBOARDING_STATE,
  name: 'onboarding',
  reducers: {},
});
