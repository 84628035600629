import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const OBSIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 22.793 22.791" {...props}>
      <g transform="translate(8402.396 -2058.668)">
        <g transform="translate(-8420.396 2040.668)">
          <path
            d="M61.971,53.276l-4.63-2.849A2.849,2.849,0,0,0,53,52.852v5.7a2.849,2.849,0,0,0,4.341,2.425l4.63-2.849a2.849,2.849,0,0,0,0-4.854m-6.122,5.278v-5.7l4.63,2.849Z"
            fill={theme.palette.common.white}
            transform="translate(-22.535 -20.607)"
          />
          <path
            d="M64.261,20H61.412a1.425,1.425,0,0,0-1.425,1.563,1.467,1.467,0,0,0,1.485,1.286h2.789a.712.712,0,0,1,.712.712V26.41a1.425,1.425,0,0,0,1.563,1.425,1.467,1.467,0,0,0,1.286-1.485V23.561A3.561,3.561,0,0,0,64.261,20"
            fill={theme.palette.common.white}
            transform="translate(-27.03 -1.288)"
          />
          <path
            d="M22.563,58.988A1.425,1.425,0,0,0,21,60.412v2.849a3.561,3.561,0,0,0,3.561,3.561H27.41a1.425,1.425,0,0,0,1.425-1.563,1.467,1.467,0,0,0-1.485-1.286H24.561a.712.712,0,0,1-.712-.712V60.473a1.467,1.467,0,0,0-1.286-1.485"
            fill={theme.palette.common.white}
            transform="translate(-1.932 -26.386)"
          />
          <path
            d="M19.425,24.41a4.986,4.986,0,0,0,4.986-4.986,1.425,1.425,0,0,0-2.849,0,2.137,2.137,0,0,1-2.137,2.137,1.425,1.425,0,0,0,0,2.849"
            fill={theme.palette.common.white}
          />
          <path
            d="M19.425,30.109A10.684,10.684,0,0,0,30.109,19.425a1.425,1.425,0,0,0-2.849,0,7.835,7.835,0,0,1-7.835,7.835,1.425,1.425,0,0,0,0,2.849"
            fill={theme.palette.common.white}
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default OBSIcon;
