import { User } from '@auth0/auth0-spa-js';

export type CurrentUserType = User | { [key: string]: unknown };

export interface AuthenticationState {
  currentUser: CurrentUserType;
  isAuthenticated: boolean | undefined;
}

export const INITIAL_AUTHENTICATION_STATE: AuthenticationState = {
  currentUser: {},
  isAuthenticated: undefined,
};
