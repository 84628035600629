import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { getShoppableThumbnailFileUploadUrl } from '../../../usecases/shoppables/get-shoppable-thumbnail-file-upload-url';
import { getShoppableVideoFileUploadUrl } from '../../../usecases/shoppables/get-shoppable-video-file-upload-url';
import { uploadShoppableVideoFile } from '../../../usecases/shoppables/upload-shoppable-video-file';
import { uploadVideoFromSocialMedia } from '../../../usecases/shoppables/upload-video-from-social-media';
import { ShoppablesState } from '../../state/shoppables';
import {
  errorStatus,
  loadedStatus,
  notLoadedStatus,
  notRequestedStatus,
  pendingStatus,
  successStatus,
} from '../../utils';

export const resetUploadShoppableVideoFile = createAction<void>('shoppables/reset-upload-file');
export const resetUploadShoppableThumbnailAction = createAction<void>('shoppables/reset-upload-thumbnail');
export const resetFetchThumbnailFileUploadUrlAction = createAction<void>(
  'shoppables/reset-fetch-thumbnail-file-upload-url',
);

export const buildFileUploadReducers = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(getShoppableVideoFileUploadUrl.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('fileUploadUrlFetching'),
    fileUploadUrl: action.payload,
  }));
  builder.addCase(getShoppableVideoFileUploadUrl.pending, (state) => ({
    ...state,
    ...pendingStatus('fileUploadUrlFetching'),
    fileUploadUrl: null,
  }));
  builder.addCase(getShoppableVideoFileUploadUrl.rejected, (state, action) => ({
    ...state,
    ...errorStatus('fileUploadUrlFetching', [action.error]),
  }));
  builder.addCase(getShoppableThumbnailFileUploadUrl.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('shoppableVideoThumbnailFileUploadUrlFetching'),
    shoppableVideoThumbnailFileUploadUrl: action.payload,
  }));
  builder.addCase(getShoppableThumbnailFileUploadUrl.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppableVideoThumbnailFileUploadUrlFetching'),
    shoppableVideoThumbnailFileUploadUrl: null,
  }));
  builder.addCase(getShoppableThumbnailFileUploadUrl.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppableVideoThumbnailFileUploadUrlFetching', [action.error]),
  }));
  builder.addCase(resetFetchThumbnailFileUploadUrlAction, (state) => ({
    ...state,
    ...notLoadedStatus('shoppableVideoThumbnailFileUploadUrlFetching'),
    shoppableVideoThumbnailFileUploadUrl: null,
  }));
  builder.addCase(resetUploadShoppableThumbnailAction, (state) => ({
    ...state,
    ...notRequestedStatus('shoppableThumbnailFileUploading'),
  }));
  builder.addCase(resetUploadShoppableVideoFile, (state) => ({
    ...state,
    ...notRequestedStatus('fileUploading'),
  }));
  builder.addCase(uploadShoppableVideoFile.fulfilled, (state, action) => {
    return {
      ...state,
      ...successStatus('fileUploading'),
      currentShoppable: state.currentShoppable
        ? {
            ...state.currentShoppable,
            videoUrl: action.payload,
          }
        : null,
      shoppables: state.shoppables.map((shoppable) => {
        if (shoppable.id === state.currentShoppable?.id) {
          return {
            ...shoppable,
            videoUrl: action.payload,
          };
        }

        return shoppable;
      }),
    };
  });
  builder.addCase(uploadShoppableVideoFile.pending, (state) => ({
    ...state,
    ...pendingStatus('fileUploading'),
  }));
  builder.addCase(uploadShoppableVideoFile.rejected, (state, action) => ({
    ...state,
    ...errorStatus('fileUploading', [action.error]),
  }));
  builder.addCase(uploadVideoFromSocialMedia.pending, (state) => ({
    ...state,
    ...pendingStatus('uploadingVideoFromSocialMedia'),
  }));
  builder.addCase(uploadVideoFromSocialMedia.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('uploadingVideoFromSocialMedia'),
    currentShoppable: state.currentShoppable
      ? {
          ...state.currentShoppable,
          videoUrl: action.payload.videoUrl,
        }
      : null,
    shoppables: state.shoppables.map((shoppable) => {
      if (shoppable.id === state.currentShoppable?.id) {
        return {
          ...shoppable,
          videoUrl: action.payload.videoUrl,
        };
      }

      return shoppable;
    }),
  }));
  builder.addCase(uploadVideoFromSocialMedia.rejected, (state, action) => ({
    ...state,
    ...errorStatus('uploadingVideoFromSocialMedia', [action.error]),
  }));
};
