import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type GenerateMagicContentParams = {
  eventId: EventId;
};

// TODO: Add use case tests
export const generateMagicContent = createAppAsyncThunk(
  'event/generate-magic-content',
  async ({ eventId }: GenerateMagicContentParams, { extra: { magicContentGateway } }): Promise<{ id: string }> => {
    return magicContentGateway.generate(eventId);
  },
);
