import { EventStatus } from '@bellepoque/api-contracts';
import { Box, Typography, styled } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import formatDate from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModel } from '../../../../core/domain/CBOEventReadModel';
import { secondsToFormatedTime } from '../../../../utils/date-format';

const Value = styled(Typography)({
  fontSize: '1em',
  fontWeight: '500',
  overflowWrap: 'anywhere',
  textAlign: 'initial',
});

const Title = styled(Typography)({
  fontSize: '0.8em',
  textAlign: 'initial',
  textTransform: 'uppercase',
});

interface DisplayedValueProps {
  title: string;
  value: string;
}

export default function DisplayedValue({ title, value }: DisplayedValueProps) {
  return (
    <Box alignItems="flex-start" display="flex" flexDirection="column" justifyContent="flex-start">
      <Title variant="titleFont">{title}</Title>
      <Value variant="titleFont">{value}</Value>
    </Box>
  );
}

interface EventDurationProps {
  event: CBOEventReadModel;
}

export function EventDuration({ event }: EventDurationProps) {
  const { t } = useTranslation('common');
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (event.status === EventStatus.ON_AIR) {
      timer = setInterval(() => {
        setDuration((previousValue) => previousValue + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [event.status]);

  const resetDuration = (startTime: Date) => {
    const now = new Date();
    setDuration(differenceInSeconds(now, startTime));
  };

  useEffect(() => {
    if (event.startTime) resetDuration(event.startTime);
  }, [event.startTime, resetDuration]);

  if (!event.startTime || event.status === EventStatus.PRIVATE_PREVIEW) return null;

  const props: DisplayedValueProps = event.finishTime
    ? {
        title: t('Duration'),
        value: secondsToFormatedTime(
          event.videoDuration ? event.videoDuration / 1000 : differenceInSeconds(event.finishTime, event.startTime),
        ),
      }
    : { title: t('Start'), value: secondsToFormatedTime(duration) };

  return <DisplayedValue {...props} />;
}

interface EventDisplayedTimeProps {
  event: CBOEventReadModel;
}

export function EventDisplayedTime({ event }: EventDisplayedTimeProps) {
  const displayedTime = event.showTime ?? event.startTime ?? null;
  if (!displayedTime) return null;

  return <DisplayedValue title={formatDate(displayedTime, 'y')} value={formatDate(displayedTime, 'E d MMM p')} />;
}
