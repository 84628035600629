import { Box, styled } from '@mui/material';
import React from 'react';

import FixedContentHeader from '../../components/molecules/FixedContentHeader';
import Tabs, { TabProps, tabWidthInPx } from '../../components/molecules/Tabs';
import AnalyticsHeader from '../../components/molecules/analytics/AnalyticsHeader';
import { toolbarHeight } from '../../components/organisms/Menu';
import { AnalyticsTabsNumber } from '../../pages/analytics/Analytics';
import TabPanel from '../TabPanel';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
});

const TabsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  position: 'fixed',
  top: theme.spacing(10) + toolbarHeight,
}));

type AnalyticsTemplateProps = {
  activeTab: AnalyticsTabsNumber;
  isDrawerOpen: boolean;
  isTutorialOpen: boolean;
  onChangeTab: (index: number) => void;
  onCreateEvent: () => void;
  onCreateShoppableVideo: () => void;
  tabs: TabProps[];
};

export default function AnalyticsTemplate({
  activeTab,
  isDrawerOpen,
  isTutorialOpen,
  onChangeTab,
  onCreateEvent,
  onCreateShoppableVideo,
  tabs,
}: AnalyticsTemplateProps) {
  return (
    <Container>
      <FixedContentHeader isDrawerOpen={isDrawerOpen} isTutorialOpen={isTutorialOpen}>
        <AnalyticsHeader
          isCreateEventButtonShown={activeTab === AnalyticsTabsNumber.LiveAndReplays}
          isCreateShoppableVideoButtonShown={activeTab === AnalyticsTabsNumber.ShoppableVideos}
          onCreateEvent={onCreateEvent}
          onCreateShoppableVideo={onCreateShoppableVideo}
        />
      </FixedContentHeader>

      <Box display="flex" flex={1} mt={10}>
        <Box height="100%" minWidth={tabWidthInPx}>
          <TabsContainer>
            <Tabs activeTab={activeTab} onChangeTab={onChangeTab} tabs={tabs} />
          </TabsContainer>
        </Box>
        <Box flex={1}>
          {tabs.map(({ component, index, label }) => (
            <TabPanel index={index} key={label} value={activeTab}>
              {component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Container>
  );
}
