import { V1 } from '@bellepoque/api-contracts';

import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateMediaCollectionSettings as resetUpdateMediaCollectionSettingsAction } from '../../store/slices/media-collections.slice';

export type UpdateMediaCollectionSettingsRequest = {
  mediaCollectionId: string;
  payload: Pick<CBOMediaCollection, 'medias' | 'title'>;
  tenantId: string;
};

export const updateMediaCollectionSettings = createAppAsyncThunk(
  'media-collections/update-media-collection-settings',
  async (
    { mediaCollectionId, payload, tenantId }: UpdateMediaCollectionSettingsRequest,
    { extra: { mediaCollectionsGateway } },
  ): Promise<void> => {
    const dto: V1.api.UpdateMediaCollectionSettingsDTO = {
      medias: payload.medias.map((media) => {
        return {
          ...media,
          id: media.type === 'replay-chapter' ? media.replayId : media.id,
        };
      }),
      title: payload.title,
    };

    await mediaCollectionsGateway.updateMediaCollectionSettings({ dto, mediaCollectionId, tenantId });
  },
);

export const resetUpdateMediaCollectionSettings = () => {
  return resetUpdateMediaCollectionSettingsAction();
};
