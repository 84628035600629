import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { fetchBillingReports } from '../../usecases/reports/fetch-billing-reports';
import { fetchTrafficReports } from '../../usecases/reports/fetch-traffic-reports';
import { INITIAL_REPORTS_STATE, ReportsState } from '../state/reports';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildReportsReducers = (builder: ActionReducerMapBuilder<ReportsState>) => {
  builder.addCase(fetchBillingReports.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('billingReportsFetching'),
    billingReports: action.payload,
  }));
  builder.addCase(fetchBillingReports.pending, (state) => ({
    ...state,
    ...pendingStatus('billingReportsFetching'),
  }));
  builder.addCase(fetchBillingReports.rejected, (state, action) => ({
    ...state,
    ...errorStatus('billingReportsFetching', [action.error]),
  }));
  builder.addCase(fetchTrafficReports.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('trafficReportsFetching'),
    trafficReports: action.payload,
  }));
  builder.addCase(fetchTrafficReports.pending, (state) => ({
    ...state,
    ...pendingStatus('trafficReportsFetching'),
  }));
  builder.addCase(fetchTrafficReports.rejected, (state, action) => ({
    ...state,
    ...errorStatus('trafficReportsFetching', [action.error]),
  }));
};

export const reportsSlice = createSlice({
  extraReducers: (builder) => {
    buildReportsReducers(builder);
  },
  initialState: INITIAL_REPORTS_STATE,
  name: 'reports',
  reducers: {},
});
