import { Box, SxProps, Theme, Typography, styled } from '@mui/material';
import React from 'react';

const CONTAINER_HEIGHT_IN_PX = 200;

const ActionsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifySelf: 'flex-end',
}));

const GradientContainer = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  position: 'relative',
}));

const Container = styled(Box)({
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: `${CONTAINER_HEIGHT_IN_PX}px`,
  padding: 0,
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '2.2em',
  fontWeight: 'bold',
  justifySelf: 'flex-start',
  textAlign: 'initial',
}));

const Image = styled('img')(({ theme }) => ({
  bottom: 0,
  height: `calc(${CONTAINER_HEIGHT_IN_PX}px + ${theme.spacing(1)})`,
  position: 'absolute',
  right: theme.spacing(3),
}));

export type ImageActionCardProps = {
  actions: React.ReactNode[];
  backgroundImage?: string;
  gradient: string;
  image?: string;
  sx?: SxProps<Theme>;
  title: string;
};

// TODO: rename
export function ImageActionCard({ actions, backgroundImage, image, title, gradient }: ImageActionCardProps) {
  return (
    <Container
      boxShadow={3}
      sx={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundPositionX: 'center',
              backgroundPositionY: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : {}
      }
    >
      <GradientContainer sx={{ background: gradient }}>
        {!!image && <Image src={image} />}
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box sx={{ flex: 1 / 3 }}>
            <Title>{title}</Title>
          </Box>
        </Box>
        <ActionsContainer>{actions}</ActionsContainer>
      </GradientContainer>
    </Container>
  );
}
