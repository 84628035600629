import { Error } from '@mui/icons-material';
import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
  justifySelf: 'flex-end',
}));

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.info.light,
  borderRadius: '15px',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}));

const IconAndTextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

const IconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifySelf: 'flex-start',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

export type ActionNotificationProps = {
  actions: React.ReactNode;
  text: string;
  variant: 'success' | 'warning';
};

export default function ActionNotification({ actions, text, variant }: ActionNotificationProps) {
  const theme = useTheme();
  return (
    <Container>
      <IconAndTextContainer>
        <IconContainer>
          {variant === 'success' && <CheckCircleRounded sx={{ color: theme.palette.secondary.main }} />}
          {variant === 'warning' && <Error sx={{ color: theme.palette.primary.main }} />}
        </IconContainer>
        <Box pr={2}>
          <Typography
            sx={{
              color: theme.palette.info.main,
              textAlign: 'initial',
            }}
          >
            {text}
          </Typography>
        </Box>
      </IconAndTextContainer>
      <ActionsContainer>{actions}</ActionsContainer>
    </Container>
  );
}
