import { EventId } from '@bellepoque/api-contracts';

import { CBOEventStatistics } from '../../../domain/CBOTenantStatistics';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type FetchEventStatisticsParams = {
  eventId: EventId;
};

export const fetchEventStatistics = createAppAsyncThunk(
  'events/fetch-event-statistics-event',
  async ({ eventId }: FetchEventStatisticsParams, { extra: { eventGateway } }): Promise<CBOEventStatistics> => {
    return eventGateway.fetchOneEventStatistics(eventId);
  },
);
