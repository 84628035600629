import { changePreviewModeAction } from '../../../store/slices/event/event-live.slice';

export type ChangePreviewModeRequest = {
  isPrivatePreview: boolean;
};

type ChangePreviewModeParams = {
  isPrivatePreview: boolean;
};

export const changePreviewMode = (params: ChangePreviewModeParams) => {
  return changePreviewModeAction(params);
};
