import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CopyEventIdButtonProps {
  onCopyIdButtonClick: () => void;
}

export default function CopyEventIdButton({ onCopyIdButtonClick }: CopyEventIdButtonProps) {
  const { t } = useTranslation('events');

  return (
    <Tooltip title={`${t('CopyEventId')}`}>
      <IconButton onClick={onCopyIdButtonClick} sx={{ color: grey[500] }}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
}
