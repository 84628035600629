import React, { FC } from 'react';

import UploadFile, { FileTypes, UploadProps } from './UploadFile';

export type UploadImageSquareBoxProps = Pick<
  UploadProps,
  'buttonDescription' | 'buttonLabel' | 'disabled' | 'errorMessage' | 'helperText' | 'onSelectFile'
>;

const UploadImageSquareBox: FC<UploadImageSquareBoxProps> = (props) => (
  <UploadFile acceptedFiles={FileTypes.Image} loading={false} variant="square" {...props} />
);

export default UploadImageSquareBox;
