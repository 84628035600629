import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function EventsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18.7 19.2" {...props}>
      <g transform="translate(-43.425)">
        <g transform="translate(43.425)">
          <path
            d="M47.32,37a.44.44,0,0,0-.44.44v1.047H45.625a2.2,2.2,0,0,0-2.2,2.2V54.069a2.2,2.2,0,0,0,2.2,2.2h14.3a2.2,2.2,0,0,0,2.2-2.2V40.686a2.2,2.2,0,0,0-2.2-2.2H58.672V37.44a.44.44,0,0,0-.88,0v1.047H47.76V37.44A.44.44,0,0,0,47.32,37ZM59.928,55.388h-14.3a1.321,1.321,0,0,1-1.32-1.32V43.827H61.248V54.069A1.321,1.321,0,0,1,59.928,55.388ZM57.793,39.367v1.487a.44.44,0,0,0,.88,0V39.367h1.256a1.321,1.321,0,0,1,1.32,1.32v2.261H44.3V40.686a1.321,1.321,0,0,1,1.32-1.32h1.256v1.487a.44.44,0,0,0,.88,0V39.367Z"
            strokeWidth={0.2}
            transform="translate(-43.425 -37)"
          />
          <path
            d="M180.16,241.844l-1.982-.288-.887-1.8a.44.44,0,0,0-.789,0l-.887,1.8-1.982.288a.44.44,0,0,0-.244.75l1.434,1.4-.339,1.974a.44.44,0,0,0,.638.464l1.773-.932,1.773.932a.44.44,0,0,0,.638-.464l-.339-1.974,1.434-1.4a.44.44,0,0,0-.244-.75Zm-1.97,1.68a.44.44,0,0,0-.127.389l.227,1.324-1.189-.625a.44.44,0,0,0-.409,0l-1.189.625.227-1.324a.44.44,0,0,0-.127-.389l-.962-.937,1.329-.193a.44.44,0,0,0,.331-.241l.594-1.2.594,1.2a.44.44,0,0,0,.331.241l1.329.193Z"
            strokeWidth={0.2}
            transform="translate(-167.546 -230.606)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
