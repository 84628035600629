import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import liveShoppingImg from '../../../assets/live-shopping.jpg';
import { HomeNotificationId } from '../../../pages/homepage/Home';
import ShopifyButton from '../../atoms/ShopifyButton';
import GetStartedActionCard from '../../atoms/home/GetStartedActionCard';

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'initial',
}));

const ItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

type GetStartedWithLiveShopping = {
  onCloseNotification: (notificationId: HomeNotificationId) => void;
  onShowConfigureLiveShoppingPageMenuModal: () => void;
  shownNotificationsIds: HomeNotificationId[];
};

export default function GetStartedWithLiveShopping({
  onCloseNotification,
  onShowConfigureLiveShoppingPageMenuModal,
  shownNotificationsIds,
}: GetStartedWithLiveShopping) {
  const { t } = useTranslation('home');

  return (
    <Container>
      <Title variant="h2">{t('GetStarted.LiveShopping.Title')}</Title>

      <ItemsContainer>
        {shownNotificationsIds.includes('landing-page-created') && (
          <GetStartedActionCard
            actionButton={
              <ShopifyButton onClick={onShowConfigureLiveShoppingPageMenuModal}>
                {t('GetStarted.LiveShopping.Cards.AddLiveShoppingPage.ActionButton')}
              </ShopifyButton>
            }
            description={t('GetStarted.LiveShopping.Cards.AddLiveShoppingPage.Description')}
            duration={t('GetStarted.LiveShopping.Cards.AddLiveShoppingPage.Duration')}
            imageSrc={liveShoppingImg}
            onClose={() => onCloseNotification('landing-page-created')}
            title={t('GetStarted.LiveShopping.Cards.AddLiveShoppingPage.Title')}
          />
        )}
      </ItemsContainer>
    </Container>
  );
}
