import { Box, Typography, styled } from '@mui/material';
import React from 'react';

import LinkFacebookAccountButton from '../../../../atoms/event/LinkFacebookAccountButton';

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '.8em',
  marginTop: theme.spacing(2),
}));

interface ConnectFacebookAccountProps {
  errorText?: string;
  isButtonDisabled: boolean;
  onConnect: () => void;
}

export default function ConnectFacebookAccount({
  errorText,
  isButtonDisabled,
  onConnect,
}: ConnectFacebookAccountProps) {
  return (
    <Box>
      <LinkFacebookAccountButton disabled={isButtonDisabled} onClick={onConnect} />
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </Box>
  );
}
