import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import {
  CreateShoppablePayload,
  createShoppable,
  resetCreateShoppable,
} from '../../../../../core/usecases/shoppables/create-shoppable';
import {
  getShoppableThumbnailFileUploadUrl,
  resetFetchThumbnailFileUploadUrl,
} from '../../../../../core/usecases/shoppables/get-shoppable-thumbnail-file-upload-url';
import {
  UpdateShoppableVideoPayload,
  resetUpdateShoppableVideo,
  updateShoppableVideo as updateShoppableVideoUseCase,
} from '../../../../../core/usecases/shoppables/update-shoppable-video';
import {
  resetUploadShoppableThumbnail,
  uploadShoppableThumbnailFile,
} from '../../../../../core/usecases/shoppables/upload-shoppable-thumbnail-file';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createShoppableFormDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentShoppable = state.shoppables.currentShoppable;
    const tenantId = state.tenants.currentTenantId;
    const formType: 'create' | 'update' = currentShoppable ? 'update' : 'create';

    return {
      createShoppableVideo: (payload: CreateShoppablePayload) => {
        dispatch(createShoppable({ payload, tenantId }));
      },
      creationStatus: state.shoppables.shoppableCreation.status,
      currentCatalogProduct: state.catalog.currentProduct,
      currentShoppable,
      fetchThumbnailFileUploadUrl: (shoppableId: string) => {
        dispatch(getShoppableThumbnailFileUploadUrl({ shoppableId, tenantId }));
      },
      fetchThumbnailFileUploadUrlStatus: state.shoppables.shoppableVideoThumbnailFileUploadUrlFetching.status,
      formType,
      resetCreate: () => {
        dispatch(resetCreateShoppable());
      },
      resetFetchThumbnailUrl: () => {
        dispatch(resetFetchThumbnailFileUploadUrl());
      },
      resetUpdate: () => {
        dispatch(resetUpdateShoppableVideo());
      },
      resetUploadThumbnail: () => {
        dispatch(resetUploadShoppableThumbnail());
      },
      tenantId: state.tenants.currentTenantId,
      tenantName: state.tenants.currentTenant.name,
      thumbnailFileUploadUrl: state.shoppables.shoppableVideoThumbnailFileUploadUrl?.url ?? null,
      thumbnailFileUploadingStatus: state.shoppables.shoppableThumbnailFileUploading.status,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateShoppableVideo: (payload: UpdateShoppableVideoPayload) => {
        if (currentShoppable) {
          dispatch(updateShoppableVideoUseCase({ payload, shoppableId: currentShoppable.id, tenantId }));
        }
      },
      updateStatus: state.shoppables.shoppableVideoUpdate.status,
      uploadThumbnail: (file: File) => {
        if (state.shoppables.shoppableVideoThumbnailFileUploadUrl?.url) {
          dispatch(
            uploadShoppableThumbnailFile({ file, url: state.shoppables.shoppableVideoThumbnailFileUploadUrl.url }),
          );
        }
      },
    };
  };
