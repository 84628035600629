import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_SHOPPABLES_STATE } from '../../state/shoppables';
import { buildCrudReducers } from './shoppables-crud.slice';
import { buildFileUploadReducers } from './shoppables-file-upload.slice';
import { buildPlayerPlaylistsBlockReducers } from './shoppables-player-playlists-block.slice';
import { buildSettingsReducer } from './shoppables-settings.slice';
import { ShoppableSynchronizeActions, buildSynchronizeReducer } from './shoppables-synchronize.slice';
import { buildVideoUpdateReducer } from './shoppables-update.slice';

export type ShoppablesSliceActions = ShoppableSynchronizeActions;

export const shoppablesSlice = createSlice({
  extraReducers: (builder) => {
    buildCrudReducers(builder);
    buildFileUploadReducers(builder);
    buildPlayerPlaylistsBlockReducers(builder);
    buildSettingsReducer(builder);
    buildSynchronizeReducer(builder);
    buildVideoUpdateReducer(builder);
  },
  initialState: INITIAL_SHOPPABLES_STATE,
  name: 'shoppables',
  reducers: {},
});
