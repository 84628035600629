import { Observable, from } from 'rxjs';
import { AjaxError, ajax } from 'rxjs/ajax';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthenticationGateway } from '../core/gateways/authentication-gateway';

interface Command {
  body?: any;
  url: string;
  verb: 'delete' | 'patch' | 'post' | 'put';
}

interface JsonQuery {
  params?: Record<string, string>;
  url: string;
}

const authHeader = (token: string): { Authorization: string } => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

function addApiErrorCode<T>() {
  return catchError<T, never>((error: AjaxError) => {
    const errorCode = error.response?.code;

    const newError = new Error(error.message);
    newError.name = error.name;

    //@ts-ignore
    newError.code = errorCode;
    //@ts-ignore
    newError.status = error.status;

    throw newError;
  });
}

export class ApiGateway {
  public readonly apiEndpoint: string;

  constructor(private authentication: AuthenticationGateway) {
    this.apiEndpoint = process.env.REACT_APP_API_ENDPOINT || '';
  }

  private logoutIf401 = <T>(source: Observable<T>) => {
    return source.pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.authentication.logout();
        }
        throw error;
      }),
    );
  };

  authenticatedCommand<T = void>({ verb, url, body }: Command): Observable<T> {
    return from(this.authentication.getAccessToken()).pipe(
      switchMap((token: string) => {
        const headers = {
          'Content-Type': 'application/json',
          ...authHeader(token),
        };

        const getCommand = () => {
          switch (verb) {
            case 'delete':
              return ajax.delete<T>(url, headers);
            case 'patch':
              return ajax.patch<T>(url, body, headers);
            case 'post':
              return ajax.post<T>(url, body, headers);
            case 'put':
              return ajax.put<T>(url, body, headers);
          }
        };

        return getCommand().pipe(
          addApiErrorCode(),
          map((ajaxResponse) => ajaxResponse.response),
          this.logoutIf401,
        );
      }),
    );
  }

  authenticatedJsonQuery<T>({ params, url }: JsonQuery): Observable<T> {
    if (params) {
      url = url.concat(`?${new URLSearchParams(params).toString()}`);
    }

    return from(this.authentication.getAccessToken()).pipe(
      switchMap((token: string) => {
        return ajax.getJSON<T>(url, authHeader(token)).pipe(addApiErrorCode(), this.logoutIf401);
      }),
    );
  }
}
