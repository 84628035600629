import { Box, Typography, styled } from '@mui/material';
import React from 'react';

type PhoneNumberProps = {
  children: React.ReactNode;
};

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '5px',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textAlign: 'center',
}));

export default function PhoneNumber(props: PhoneNumberProps) {
  return (
    <Container>
      <Typography fontWeight="bold" {...props}></Typography>
    </Container>
  );
}
