import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { CATALOG_PAGINATION_LIMIT, CatalogSortType } from '../../../../../core/store/state/catalog';
import { fetchProducts } from '../../../../../core/usecases/catalog/fetch-products';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createAddProductToShoppableDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currency, id: tenantId } = state.tenants.currentTenant;

    const { products, search, sort } = state.catalog;
    const { offset, totalCount } = state.catalog.pagination;

    const currentPage = Math.floor(offset / CATALOG_PAGINATION_LIMIT) + 1;
    const pageCount = Math.ceil(totalCount / CATALOG_PAGINATION_LIMIT);

    const defaultFetchProductsFilters = {
      limit: CATALOG_PAGINATION_LIMIT,
      offset,
      search,
      sort,
      tenantId,
    };

    return {
      changePage: (page: number) => {
        const newOffset = (page - 1) * CATALOG_PAGINATION_LIMIT;
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, offset: newOffset }));
      },
      currency,
      currentPage,
      fetchProductsWithoutFilters: () => {
        dispatch(fetchProducts(defaultFetchProductsFilters));
      },
      pageCount,
      products,
      productsFetchingStatus: state.catalog.productsFetching.status,
      searchProducts: (search: string) => {
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, offset: 0, search }));
      },
      sort,
      sortProducts: (sort: CatalogSortType) => {
        dispatch(fetchProducts({ ...defaultFetchProductsFilters, sort }));
      },
      tenantId,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
