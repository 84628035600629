import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type CopyEventThemeRequest = {
  copiedEventId: EventId;
  eventId: EventId;
};

export const copyEventTheme = createAppAsyncThunk(
  'events/copy-theme',
  async (params: CopyEventThemeRequest, { extra: { eventGateway } }): Promise<void> => {
    await eventGateway.copyTheme(params);
  },
);
