import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const LivePageHighlightIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 46 39" {...props}>
      <g transform="translate(-19989 -9763.535)">
        <g fill="none" stroke={theme.palette.common.white} strokeWidth="2" transform="translate(19989 9763.535)">
          <rect height="39" rx="5" stroke="none" width="46" />
          <rect fill="none" height="37" rx="4" width="44" x="1" y="1" />
        </g>
        <path
          d="M3,13h8V3H3Zm0,8h8V15H3Zm10,0h8V11H13ZM13,3V9h8V3Z"
          fill={theme.palette.common.white}
          transform="translate(20000 9771)"
        />
      </g>
    </SvgIcon>
  );
};

export default LivePageHighlightIcon;
