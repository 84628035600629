import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PreviewLogoPosition from '../../atoms/event/themePreview/PreviewLogoPosition';
import InputTooltip from '../../atoms/inputs/InputTooltip';
import ReactHookFormImageSelector from '../../atoms/inputs/ReactHookFormImageSelector';
import { ShoppableSettingsFormInputs } from '../../organisms/shoppables/ShoppableSettingsFormDialog';

const ImageSelectorContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  position: 'relative',
}));

const ImageSelectorLabel = styled(Typography)({
  display: 'block',
  fontSize: '.8em',
  fontWeight: 'bold',
  marginBottom: '5px',
  textAlign: 'initial',
  width: '100px',
});

export type BrandLogoSelectorProps = {
  defaultBrandLogoUrl: string;
  errors: any;
  onChange: (fileList: FileList) => void;
  onResetBrandLogo: () => void;
  onSetBrandLogoPreview: (url: string) => void;
  readonly: boolean;
  register: UseFormRegister<ShoppableSettingsFormInputs>;
  submissionInProgress: boolean;
  watch: UseFormWatch<ShoppableSettingsFormInputs>;
};

const BrandLogoSelector: FC<BrandLogoSelectorProps> = ({
  defaultBrandLogoUrl,
  errors,
  onChange,
  onResetBrandLogo,
  onSetBrandLogoPreview,
  readonly,
  register,
  submissionInProgress,
  watch,
}) => {
  const { t } = useTranslation(['shoppables']);
  const disabled = readonly || submissionInProgress;

  return (
    <Box position="relative">
      <ImageSelectorLabel>{t('BrandLogo')}</ImageSelectorLabel>
      <ImageSelectorContainer>
        <PreviewLogoPosition sx={{ height: '80px', margin: '8px 0', marginLeft: '12px', width: 'auto' }} />
        <ReactHookFormImageSelector
          altBackground
          defaultUrl={defaultBrandLogoUrl}
          disabled={disabled}
          errorText={errors?.brandLogo?.message}
          fieldName="brandLogo"
          key={defaultBrandLogoUrl}
          label={t('BrandLogo')}
          maxSizeInKb={50}
          onChange={onChange}
          onReset={onResetBrandLogo}
          onUpdateThumbnail={onSetBrandLogoPreview}
          readonly={readonly}
          register={register}
          watch={watch}
        />
        <InputTooltip content={t('Helpers.ShoppableHeaderLogo')} />
      </ImageSelectorContainer>
    </Box>
  );
};

export default BrandLogoSelector;
