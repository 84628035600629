import { Box, styled } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventDateValue {
  date: Date | null;
}

const Value = styled('span')`
  font-size: 1em;
  font-weight: 500;
`;

const TimeValue = styled(Value)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export default function EventDateValue({ date }: EventDateValue) {
  const { t } = useTranslation('events');
  const dateValue =
    date?.toLocaleDateString(i18next.language, {
      day: 'numeric',
      month: 'long',
      weekday: 'short',
      year: 'numeric',
    }) ?? t('Unplanned');
  const timeValue = date?.toLocaleTimeString(i18next.language, { hour: '2-digit', minute: '2-digit' }) ?? '-';

  return (
    <Box display="flex" flexDirection="column">
      <Value>{dateValue}</Value>
      <Box display="flex" flexDirection="row" gap={0.5}>
        {!!date && <TimeValue>{timeValue}</TimeValue>}
      </Box>
    </Box>
  );
}
