import { TenantId } from '@bellepoque/api-contracts';

import { synchronizeShoppablesPending } from '../../store/slices/shoppables/shoppables-synchronize.slice';
import { QueryStatus } from '../../store/state/utils';

export type FetchShoppablesRequest = {
  shoppablesFetchingStatus: QueryStatus;
  tenantId: TenantId;
};

export const fetchShoppables = ({ shoppablesFetchingStatus, tenantId }: FetchShoppablesRequest) => {
  return synchronizeShoppablesPending({ force: shoppablesFetchingStatus === 'error', tenantId });
};
