import { CBOMediaCollection } from '../core/domain/CBOMediaCollection';

export const countPublishedShoppablesFromMediaCollections = (mediaCollections: CBOMediaCollection[]) => {
  const publishedShoppablesIds = mediaCollections.reduce((acc: string[], mediaCollection) => {
    const isPublished = mediaCollection.publishedPages.length > 0;
    if (isPublished) {
      const shoppablesIds = mediaCollection.medias.filter(({ type }) => type === 'shoppable').map(({ id }) => id);
      shoppablesIds.forEach((id) => {
        if (!acc.includes(id)) {
          acc.push(id);
        }
      });
    }

    return acc;
  }, []);

  return publishedShoppablesIds.length;
};
