import { Box, Typography, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import {
  CBOEventStatisticsWithSales,
  CBOEventStatisticsWithoutSales,
  CBOProductStatistics,
  CBOProductStatisticsGuards,
} from '../../../../core/domain/CBOTenantStatistics';
import ProductStatisticsRow from '../../molecules/analytics/ProductStatisticsRow';

const Title = styled(Typography)({
  fontSize: '.8em',
  fontWeight: 'bold',
  textTransform: 'uppercase',
});

type ProductStatisticsSectionProps = {
  currency: CBOCurrency;
  products: CBOEventReadModelProduct[];
} & (
  | {
      hasSales: false;
      statistics: CBOEventStatisticsWithoutSales['productsStatistics'];
    }
  | {
      hasSales: true;
      statistics: CBOEventStatisticsWithSales['productsStatistics'];
    }
);

export const getProductStatisticsScore = (productStatistics: CBOProductStatistics): number => {
  if (CBOProductStatisticsGuards.isCBOProductStatisticsWithSales(productStatistics)) {
    return productStatistics.cart + productStatistics.views + productStatistics.purchased;
  } else {
    return productStatistics.cart + productStatistics.views + productStatistics.validated;
  }
};

const sortProductsByName = (a: CBOEventReadModelProduct, b: CBOEventReadModelProduct) => a.title.localeCompare(b.title);

export default function ProductStatisticsSection({
  currency,
  hasSales,
  products,
  statistics,
}: ProductStatisticsSectionProps) {
  const { t } = useTranslation('analytics');

  const allProductScores = useMemo(
    () =>
      Object.values(statistics)
        .map((statistics) => getProductStatisticsScore(statistics))
        .reduce((acc, val) => acc + val, 0),
    [statistics],
  );

  const sortedProducts = useMemo(() => [...products].sort(sortProductsByName), [products]);

  const renderProductStatisticsRow = useCallback(
    (product: CBOEventReadModelProduct) => {
      if (!product || !statistics[product.id]) return null;

      if (hasSales) {
        return (
          <ProductStatisticsRow
            allProductScores={allProductScores}
            currency={currency}
            hasSales
            key={product.id}
            product={product}
            productStatistics={statistics[product.id]}
          />
        );
      }

      return (
        <ProductStatisticsRow
          allProductScores={allProductScores}
          currency={currency}
          hasSales={false}
          key={product.id}
          product={product}
          productStatistics={statistics[product.id]}
        />
      );
    },
    [statistics],
  );

  const renderStatistics = useCallback(() => sortedProducts.map(renderProductStatisticsRow), [sortedProducts]);

  if (Object.keys(statistics).length === 0) return null;

  return (
    <Box py={5}>
      <Title variant="h5">{t('StatsByProduct.Title')}</Title>
      {renderStatistics()}
    </Box>
  );
}
