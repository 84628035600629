import { miniSerializeError } from '@reduxjs/toolkit';
import { NEVER, of, race } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, timeout } from 'rxjs/operators';

import { AppEpic } from '../../..';
import {
  synchronizeEventsFulfilled,
  synchronizeEventsPending,
  synchronizeEventsRejected,
} from '../../../slices/event/event-synchronize.slice';
import { ofType } from '../../utils';

export const synchronizeEventsEpic: AppEpic = (action$, state$, { container: { realtimeDataGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeEventsPending>>(synchronizeEventsPending.type),
    distinctUntilChanged((prev, cur) => (cur.payload.force ? false : prev.payload.tenantId === cur.payload.tenantId)),
    switchMap((action) =>
      race(
        realtimeDataGateway.getEventListReadModels({ tenantId: action.payload.tenantId }),
        NEVER.pipe(timeout(3000)),
      ),
    ),
    map((eventListReadModels) => synchronizeEventsFulfilled(eventListReadModels)),
    catchError((error) => of(synchronizeEventsRejected([miniSerializeError(error)]))),
  );
};
