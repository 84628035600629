import {
  ProductOption as EventProductOption,
  ProductOptionValue,
  ProductOptionValueId,
} from '@bellepoque/api-contracts';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TagsSelect } from 'react-select-material-ui';
import { v4 as uuidv4 } from 'uuid';

interface DeletableOptionProps {
  defaultProps: any;
  onDelete: (id: string) => void;
}

const DeletableOptionContainer = styled(Box)({
  '&:hover': {
    backgroundColor: '#D1ECFF',
  },
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  padding: '10px',
});

function DeletableOption({ defaultProps, onDelete }: DeletableOptionProps) {
  if (!defaultProps.innerProps || !defaultProps.data) return null;
  return (
    <DeletableOptionContainer onClick={defaultProps.innerProps.onClick}>
      <Box alignItems="center" display="flex" flex={1}>
        <Typography flexGrow={1} textAlign="center">
          {defaultProps.label}
        </Typography>
      </Box>
      {!defaultProps.data.__isNew__ && (
        <Box
          alignItems="center"
          display="flex"
          flexGrow={0}
          onClick={(e) => {
            onDelete(defaultProps.data.value);
            e.stopPropagation();
          }}
        >
          <DeleteIcon fillOpacity={0.75} fontSize="small" />
        </Box>
      )}
    </DeletableOptionContainer>
  );
}

const makeHandleChange = (allOptionValues: ProductOptionValue[], onChange: (values: ProductOptionValue[]) => void) => {
  return (selectedValues?: (ProductOptionValueId | string)[]) => {
    if (!selectedValues) {
      return onChange([]);
    }
    const selectedOptionValues: ProductOptionValue[] = selectedValues.map((selectedValue) => {
      const foundOptionValue = allOptionValues.find(({ id }) => id === selectedValue);
      if (foundOptionValue) {
        return foundOptionValue;
      }
      return {
        id: uuidv4(),
        name: selectedValue,
      };
    });

    onChange(selectedOptionValues);
  };
};

interface MultiGenericSelectorProps {
  onChange: (values: ProductOptionValue[]) => void;
  onDeleteValue: (value: ProductOptionValue) => void;
  option: EventProductOption;
  readonly: boolean;
}

export default function MultiGenericSelector({ onChange, onDeleteValue, option, readonly }: MultiGenericSelectorProps) {
  const { t } = useTranslation(['products', 'common']);
  const theme = useTheme();

  const handleDeleteValue = (id: string) => {
    const value = option.allValues.find(({ id: valueId }) => valueId === id);
    if (value) {
      onDeleteValue(value);
    }
  };

  return (
    <TagsSelect
      SelectProps={{
        components: {
          Option: (props: any) => <DeletableOption defaultProps={props} onDelete={handleDeleteValue} />,
        },
        formatCreateLabel: (value: string) => `${value} (${t('options.NewLabel')})`,
        isClearable: true,
        isCreatable: true,
        isValidNewOption: (inputValue: string) =>
          inputValue.trim() !== '' &&
          !option.allValues.find(({ name }) => name.toUpperCase() === inputValue.trim().toUpperCase()),
        msgNoOptionsAvailable: t('options.AllAreSelected'),
        msgNoValidValue: t('options.NewValueNotValid'),
        native: false,
        styles: {
          container: (base: any) => ({
            ...base,
            borderBottom: 'none',
            height: '100%',
          }),
          control: (base: any) => ({
            ...base,
            border: 'none !important',
            boxShadow: 'none !important',
          }),
          indicatorSeparator: (base: any) => ({
            ...base,
            display: 'none',
          }),

          multiValue: (base: any) => ({
            ...base,
            backgroundColor: theme.palette.grey[200],
            borderRadius: '6px',
            margin: '2px 3px',
          }),
          multiValueLabel: (base: any) => ({
            ...base,
            fontSize: '.9em',
            padding: '2px',
            paddingLeft: '8px',
          }),
          multiValueRemove: (base: any) => ({
            ...base,
            fontSize: '2em',
          }),
          valueContainer: (base: any) => ({
            ...base,
            backgroundColor: theme.palette.grey[50],
            height: '48px',
            outline: 'none',
            zIndex: 0,
          }),
        },
      }}
      disabled={readonly}
      helperText={
        !readonly &&
        t('options.CanAddNewVariant', {
          a_new: t('common:aNew_f'),
        })
      }
      label={!readonly && t('options.ChooseSomeVariant')}
      onChange={makeHandleChange(option.allValues, onChange)}
      options={option.allValues.map(({ id, name }) => ({ label: name, value: id }))}
      sx={{
        '& > .MuiFormHelperText-root': {
          position: 'static',
          textAlign: 'initial',
        },
        '& > .MuiInputLabel-root': {
          marginBottom: '-1em !important',
          marginLeft: '12px',
          pointerEvents: 'none',
          zIndex: 1,
        },
      }}
      values={option.selectedValues.map(({ id }) => id)}
    />
  );
}
