import { V1 } from '@bellepoque/api-contracts';

import { CBOShoppableVideo } from '../../domain/CBOShoppableVideo';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateShoppableVideo as resetUpdateShoppableVideoAction } from '../../store/slices/shoppables/shoppables-update.slice';

export type UpdateShoppableVideoPayload = V1.api.UpdateShoppableVideoDTO;

type UpdateShoppableVideoParams = {
  payload: V1.api.UpdateShoppableVideoDTO;
  shoppableId: string;
  tenantId: string;
};

export const updateShoppableVideo = createAppAsyncThunk(
  'shoppables/update-shoppable',
  async (
    { payload, shoppableId, tenantId }: UpdateShoppableVideoParams,
    { extra: { shoppablesGateway } },
  ): Promise<CBOShoppableVideo> => {
    return shoppablesGateway.updateShoppableVideo({ dto: payload, shoppableId, tenantId });
  },
);

export const resetUpdateShoppableVideo = () => {
  return resetUpdateShoppableVideoAction();
};
