import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { routes } from '../../../../routes';
import { EventDisplayedTime, EventDuration } from '../../../atoms/event/DisplayedValue';
import HeaderCurrentTime from '../../../atoms/event/HeaderCurrentTime';
import HeaderTitle from '../../../atoms/event/HeaderTitle';

interface ProductDetailHeaderProps {
  event: CBOEventReadModel;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '4fr 5fr',
  paddingBottom: 0,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(2),
}));

const LeftSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: theme.spacing(2),
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
  width: '100%',
}));

export default function ProductDetailHeader({ event }: ProductDetailHeaderProps) {
  const { t } = useTranslation(['events', 'common']);
  if (!event) return null;
  const isEventTimeDisplayed = event.startTime || event.showTime;

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Container>
        <LeftSideContainer gridTemplateColumns={isEventTimeDisplayed ? '2fr 3fr' : '4fr 2fr'}>
          <Box alignItems="center" display="flex" textAlign="left">
            <HeaderTitle event={event} />
          </Box>

          <Box display="flex" gap={1} justifyContent="space-evenly">
            <EventDisplayedTime event={event} />
            <EventDuration event={event} />
          </Box>
        </LeftSideContainer>
        <Box display="flex">
          <HeaderCurrentTime />
        </Box>
      </Container>
      <NavigationContainer>
        <Button
          color="primary"
          component={RouterLink}
          startIcon={<ArrowBackIcon color="primary" />}
          sx={{ pl: 0 }}
          to={routes.events.products({ eventId: event.id })}
        >
          <Typography color="primary" fontWeight="bold" textTransform="initial" variant="button">
            {t('Products')}
          </Typography>
        </Button>
      </NavigationContainer>
    </Box>
  );
}
