import { ShoppablesSettingsFilesUploads, UploadedAssetsNames } from '../../gateways/shoppables-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type UploadShoppablesSettingsFilesParams = {
  files: ShoppablesSettingsFilesUploads;
};

export const uploadShoppablesSettingsFiles = createAppAsyncThunk(
  'shoppables/settings/upload-files',
  async (
    { files }: UploadShoppablesSettingsFilesParams,
    { extra: { shoppablesGateway } },
  ): Promise<UploadedAssetsNames> => {
    return shoppablesGateway.uploadShoppablesSettingsFiles(files);
  },
);
