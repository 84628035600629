import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type CreateLandingPageParams = {
  tenantId: string;
};

export const createCmsLandingPage = createAppAsyncThunk(
  'landing-page/create-cms',
  async ({ tenantId }: CreateLandingPageParams, { extra: { landingPageGateway } }): Promise<void> => {
    return landingPageGateway.createCmsLandingPage(tenantId);
  },
);
