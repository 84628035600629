import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import { CommandStatus } from '../../../../core/store/state/utils';

const UploadRoot = styled('form')({
  display: 'flex',
  height: '100%',
  margin: 'auto',
  maxWidth: '100%',
  position: 'relative',
});

const UploadBoxLabel = styled('label')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  justifyContent: 'center',
  position: 'relative',
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '.8em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1.5),
  textAlign: 'center',
}));

const UploadButton = styled(Button)({
  whiteSpace: 'nowrap',
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  bottom: theme.spacing(-2),
  color: theme.palette.error.main,
  fontSize: '10px',
  fontWeight: 'bold',
  position: 'absolute',
}));

const HelperText = styled(Typography)({
  color: '#A8A8A8',
  fontSize: '10px',
  whiteSpace: 'nowrap',
});

const UploadContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'variant' })<{
  isError?: boolean;
  variant?: UploadProps['variant'];
}>(({ isError, theme, variant }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '100%',
  position: 'relative',
  textAlign: 'center',
  ...(variant === 'square' && {
    height: '100%',
    minHeight: '200px',
    padding: theme.spacing(4),
    ...(isError
      ? { border: `1px solid ${theme.palette.error.main}` }
      : {
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23bdbdbd' stroke-width='2' stroke-dasharray='7%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }),
  }),
  ...(variant === 'circle' && {
    borderRadius: '50%',
    cursor: 'pointer',
  }),
}));

const IconContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'variant' })<{
  isError?: boolean;
  variant: UploadProps['variant'];
}>(({ isError, theme, variant }) =>
  variant === 'square'
    ? {
        marginTop: theme.spacing(4),
      }
    : {
        alignItems: 'center',
        aspectRatio: '1/1',
        borderRadius: '50%',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        minHeight: '80px',
        position: 'relative',
        ...(isError
          ? { border: '1px solid red' }
          : {
              border: `1px dashed ${theme.palette.grey['400']}`,
            }),
      },
);

const DroppableBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'variant' })<{
  variant?: UploadProps['variant'];
}>(({ theme, variant }) => ({
  border: `solid ${theme.palette.primary.main} 1px`,
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  ...(variant === 'circle' && {
    borderRadius: '50%',
  }),
}));

export type UploadProps = {
  acceptedFiles?: string;
  buttonDescription?: string;
  buttonLabel: string;
  canSelectMultipleFiles?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  height?: string;
  helperText?: ReactNode;
  loading: boolean;
  onSelectFile: (file: File) => void;
  uploadStatus?: CommandStatus;
  variant?: 'circle' | 'default' | 'square';
};

export const FileTypes = {
  All: '*',
  Image: 'image/*',
  Jpg: 'image/jpg',
  Mp4: 'video/mp4',
  Png: 'image/png',
  Video: 'video/*',
};

type FileTypes = typeof FileTypes[keyof typeof FileTypes];

const UploadFile: FC<UploadProps> = ({
  acceptedFiles = FileTypes.All,
  buttonDescription,
  buttonLabel,
  canSelectMultipleFiles = false,
  disabled = false,
  errorMessage,
  helperText,
  loading,
  onSelectFile,
  uploadStatus,
  variant = 'default',
}) => {
  const inputRef = React.useRef<any>(null);

  const [dropActive, setDropActive] = React.useState(false);

  const handleDragFile = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (disabled) return;

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDropActive(true);
      } else if (e.type === 'dragleave') {
        setDropActive(false);
      }
    },
    [disabled],
  );

  const handleDropFile = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDropActive(false);

      const file = e.dataTransfer.files?.[0];

      if (file && file.type.match(acceptedFiles)) {
        onSelectFile(e.dataTransfer.files[0]);
      }
    },
    [onSelectFile],
  );

  const handleChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const file = e.target.files?.[0];

      if (file && file.type.match(acceptedFiles)) {
        onSelectFile(file);
      }
    },
    [onSelectFile],
  );

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const isError = uploadStatus === 'error';

  const uploadInput = useMemo(
    () => (
      <UploadRoot>
        <input
          accept={acceptedFiles}
          disabled={disabled}
          hidden
          multiple={canSelectMultipleFiles}
          onChange={handleChangeFile}
          ref={inputRef}
          type="file"
        />
        <UploadBoxLabel>
          {buttonDescription && <Description>{buttonDescription}</Description>}
          <UploadButton color="primary" disabled={disabled || loading} onClick={handleButtonClick} variant="contained">
            {buttonLabel}
          </UploadButton>
          {helperText && <HelperText>{helperText}</HelperText>}
          {isError && errorMessage && <ErrorMessage color="error">{errorMessage}</ErrorMessage>}
        </UploadBoxLabel>
      </UploadRoot>
    ),
    [
      acceptedFiles,
      buttonDescription,
      buttonLabel,
      canSelectMultipleFiles,
      errorMessage,
      handleChangeFile,
      helperText,
      isError,
      loading,
      handleButtonClick,
    ],
  );

  if (variant === 'default') return uploadInput;

  return (
    <UploadContainer
      isError={uploadStatus === 'error'}
      onDragEnter={handleDragFile}
      onSubmit={(e) => e.preventDefault()}
      variant={variant}
    >
      <IconContainer isError={uploadStatus === 'error'} onClick={handleButtonClick} variant={variant}>
        {loading ? <CircularProgress size="24px" /> : <UploadIcon fontSize="large" />}
        {variant === 'circle' && dropActive && !loading && (
          <DroppableBox
            onDragEnter={handleDragFile}
            onDragLeave={handleDragFile}
            onDragOver={handleDragFile}
            onDrop={handleDropFile}
            variant={variant}
          />
        )}
      </IconContainer>
      {uploadInput}
      {variant === 'square' && dropActive && !loading && (
        <DroppableBox
          onDragEnter={handleDragFile}
          onDragLeave={handleDragFile}
          onDragOver={handleDragFile}
          onDrop={handleDropFile}
        />
      )}
    </UploadContainer>
  );
};

export default UploadFile;
