import { V1 } from '@bellepoque/api-contracts';
import { FormControl, FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

import EventSettingsIcon from '../../../../../icons/EventSettingsIcon';
import DateTimePicker from '../../../atoms/inputs/DateTimePicker';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import { SettingsFormInputs } from '../../../organisms/event/event-details/SettingsForm';
import SettingsSection from '../../SettingsSection';

const constraints = V1.api.constraints.events;

interface EventSettingsSectionProps {
  control: Control<SettingsFormInputs, any>;
  descriptionInputProps: any;
  descriptionInputRef: React.Ref<any>;
  descriptionWatch: string;
  errors: any;
  readonly: boolean;
  submissionInProgress: boolean;
  titleInputProps: any;
  titleInputRef: React.Ref<any>;
  titleWatch: string;
}

export default function EventSettingsSection(props: EventSettingsSectionProps) {
  const {
    control,
    descriptionInputProps,
    descriptionInputRef,
    descriptionWatch,
    errors,
    readonly,
    submissionInProgress,
    titleInputProps,
    titleInputRef,
    titleWatch,
  } = props;
  const { t } = useTranslation(['events', 'common']);

  const disabled = readonly || submissionInProgress;
  const theme = useTheme();
  return (
    <SettingsSection
      icon={<EventSettingsIcon sx={{ color: theme.palette.common.white }} />}
      roundTop
      title={t('settings.Event')}
    >
      <Grid item position="relative" xs={12}>
        {/* TODO: Move to generic component */}
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.title}
          fullWidth
          helperText={!!errors.title ? errors?.title?.message : ''}
          id="title"
          inputRef={titleInputRef}
          label={t('Title')}
          required
          variant="filled"
          {...titleInputProps}
        />
        <InputTooltip content={t('helpers.Title')} />
        <FormHelperText>{`${titleWatch.length} / ${constraints.title.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item textAlign="left" xs={12}>
        <DateTimePicker control={control} disabled={submissionInProgress || readonly} error={!!errors.showTime} />
      </Grid>
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.description}
          fullWidth
          helperText={!!errors.description ? errors?.description?.message : ''}
          id="description"
          inputRef={descriptionInputRef}
          label={t('Description')}
          multiline
          required
          rows={4}
          variant="filled"
          {...descriptionInputProps}
        />
        <InputTooltip content={t('helpers.Description')} />
        <FormHelperText>{`${descriptionWatch.length} / ${constraints.description.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <FormControl
              error={!!errors.publisherPhone}
              fullWidth
              sx={{
                '& .PhoneInput': (theme) => ({
                  '&:hover': {
                    backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[200],
                  },
                  '&:hover input': {
                    backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[200],
                  },
                  backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[50],
                  borderBottom: `1px solid ${errors.publisherPhone ? theme.palette.error.main : 'transparent'}`,
                  borderRadius: '4px',
                  padding: '16.5px 14px',
                  transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;',
                }),
                '& input': {
                  backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[50],
                  color: disabled ? theme.palette.grey[200] : 'initial',
                  fontSize: '1rem',
                  fontWeight: '400',
                  letterSpacing: '0.00938em',
                  lineHeight: '1.4375em',
                  outline: 'none',
                  transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;',
                },
              }}
              variant="filled"
            >
              <Controller
                control={control}
                name="publisherPhone"
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    aria-describedby="component-error-text"
                    disabled={submissionInProgress || readonly}
                    onChange={(value) => field.onChange(value ?? '')}
                    placeholder={t('PhoneNumber') + ' *'}
                  />
                )}
                rules={{
                  validate: (value) => {
                    if (value === '') return t('PhoneNumberRequired') as string;
                    if (isPossiblePhoneNumber(value)) return true;
                    return t('PhoneNumberNotValid') as string;
                  },
                }}
              />
              <FormHelperText id="component-error-text">{errors?.publisherPhone?.message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </SettingsSection>
  );
}
