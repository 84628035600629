import AddIcon from '@mui/icons-material/Add';
import { Button, SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AddProductButtonProps {
  onAddProduct: () => void;
  sx?: SxProps;
}

export default function AddProductButton({ onAddProduct, sx = {} }: AddProductButtonProps) {
  const { t } = useTranslation('events');

  return (
    <Button
      className="onboarding-tour-highlighted"
      color="primary"
      onClick={onAddProduct}
      startIcon={<AddIcon fontSize="large" />}
      sx={sx}
      variant="contained"
    >
      {t('events:AddProduct')}
    </Button>
  );
}
