import Box from '@mui/material/Box';
import React from 'react';

import PageCountItem from '../../atoms/tutorial/PageCountItem';

interface PageCountProps {
  currentPage: number;
  pageCount: number;
  style?: React.CSSProperties;
}

export default function PageCount({ currentPage, pageCount, style = {} }: PageCountProps) {
  const renderItems = () => {
    const items = [];
    for (let i = 0; i < pageCount; i++) {
      items.push(<PageCountItem filled={i <= currentPage} key={i} />);
    }
    return items;
  };

  return <Box style={{ display: 'flex', gap: '10px', justifyContent: 'center', ...style }}>{renderItems()}</Box>;
}
