import { TenantId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetRenewApiKey as resetRenewApiKeyAction } from '../../store/slices/tenants.slice';

export type RenewApiKeyRequest = {
  tenantId: string;
};

type RenewApiKeyParams = {
  tenantId: TenantId;
};

export const renewApiKey = createAppAsyncThunk(
  'tenants/apiKey/renew',
  async (params: RenewApiKeyParams, { extra: { tenantsGateway } }): Promise<string> => {
    const { apiKey } = await tenantsGateway.renewApiKey(params.tenantId);
    return apiKey;
  },
);

export const resetRenewApiKey = () => {
  return resetRenewApiKeyAction();
};
