import { Cms, EventId } from '@bellepoque/api-contracts';
import { NavigateFunction } from 'react-router-dom';

import { UserJourneyEvent } from '../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../core/store';
import { fetchEvents } from '../../../core/usecases/event/crud/fetch-events';
import { fetchLandingPage } from '../../../core/usecases/landing-page/fetch-landing-page';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { isFinishedOrLater } from '../../../utils/is-replay';
import { routes } from '../../routes';

export const createReplaysViewModel =
  ({ dispatch, navigate }: { dispatch: AppDispatch; navigate: NavigateFunction }) =>
  (state: State) => {
    const { events, eventsFetching } = state.events;
    const { cms: tenantCms, name: tenantName, id: tenantId } = state.tenants.currentTenant;
    const replaysFetchingStatus = eventsFetching.status;

    return {
      canShareEvents: tenantCms === Cms.Shopify,
      fetchEvents: () => {
        dispatch(fetchEvents({ eventsFetchingStatus: replaysFetchingStatus, tenantId }));
      },
      fetchLandingPage: () => {
        dispatch(fetchLandingPage({ tenantId }));
      },
      goToReplayDetails: (id: EventId) => {
        navigate(routes.replays.replayLinks({ eventId: id }));
      },
      landingPageData: state.landingPage.landingPageData,
      landingPageTenantId: state.landingPage.landingPageTenantId,
      replays: events.filter(({ status }) => isFinishedOrLater(status)),
      replaysFetchingStatus,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
