import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface CatalogCommandsQueriesState {
  addProductsToEvent: CommandResult;
  productFetching: QueryResult;
  productsDelete: CommandResult;
  productsFetching: QueryResult;
}

export interface CatalogPaginationState {
  pagination: {
    offset: number;
    totalCount: number;
  };
}

export type CatalogSortType = '-minPrice' | '-product.title' | 'minPrice' | 'product.title';

export interface CatalogState extends CatalogCommandsQueriesState, CatalogPaginationState {
  currentProduct: CBOCatalogProduct | null;
  products: CBOCatalogProduct[];
  search: string;
  selectedProducts: CBOCatalogProduct[];
  sort: CatalogSortType;
}

export const CATALOG_PAGINATION_LIMIT = 20;
export const EVENT_PRODUCTS_PAGINATION_LIMIT = 5;

export const INITIAL_CATALOG_STATE: CatalogState = {
  addProductsToEvent: INITIAL_COMMAND_RESULT,
  currentProduct: null,
  pagination: {
    offset: 0,
    totalCount: 0,
  },
  productFetching: INITIAL_QUERY_RESULT,
  products: [],
  productsDelete: INITIAL_COMMAND_RESULT,
  productsFetching: INITIAL_QUERY_RESULT,
  search: '',
  selectedProducts: [],
  sort: 'product.title',
};
