import {
  EventId,
  ProductOption as EventProductOption,
  OverrideableSyncedProductFields,
  ProductOptionValue,
  ProductOptionValueId,
  ProductVariantState,
} from '@bellepoque/api-contracts';
import { Divider, Grid, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import {
  CBOEventReadModelProduct,
  CBOEventReadModelProductState,
  getEmptyCBOProduct,
} from '../../../../../core/domain/CBOEventReadModelProduct';
import {
  CBOEventReadModelProductVariant,
  getEmptyCBOProductOption,
  getEmptyCBOProductOptions,
} from '../../../../../core/domain/CBOEventReadModelProductOptions';
import { State } from '../../../../../core/store';
import { resetAddProductsToEvent } from '../../../../../core/store/slices/catalog.slice';
import { resetCreateProduct } from '../../../../../core/store/slices/event/event-products.slice';
import { createEventProduct } from '../../../../../core/usecases/event/products/create-event-product';
import { deleteEventProduct } from '../../../../../core/usecases/event/products/delete-event-product';
import { updateEventProduct } from '../../../../../core/usecases/event/products/update-event-product';
import { useRequiredParams } from '../../../../../utils/useRequiredParams';
import { routes } from '../../../../routes';
import StickyActionBarWrapper from '../../../../templates/StickyActionBarWrapper';
import ConfirmationDialog from '../../../../templates/dialog/ConfirmationDialog';
import ProductActionBar from '../../../molecules/event/ProductActionBar';
import DetailsSection from '../../../molecules/event/product-settings/DetailsSection';
import ImagesSection from '../../../molecules/event/product-settings/ImagesSection';
import MainDescriptionSection from '../../../molecules/event/product-settings/MainDescriptionSection';
import PreviewSection from '../../../molecules/event/product-settings/PreviewSection';
import ProductDetailHeader from '../../../molecules/event/product-settings/ProductDetailHeader';

const PREFIX = 'ProductDetail';

const classes = {
  footerDivider: `${PREFIX}-footerDivider`,
  layout: `${PREFIX}-layout`,
  linearProgress: `${PREFIX}-linearProgress`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.layout}`]: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + +theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  [`&.${classes.footerDivider}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  [`&.${classes.linearProgress}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
}));

interface SelectedOptionsValue {
  name: string;
  option1Id: ProductOptionValueId;
  option2Id: ProductOptionValueId | null;
  option3Id: ProductOptionValueId | null;
}

const unsyncedProductAllowedFieldsChanges: (keyof CBOEventReadModelProduct)[] = [
  'crossedOutPrice',
  'customerInternalId',
  'description',
  'hasOptionsChecked',
  'imageUrls',
  'options',
  'price',
  'title',
  'url',
];

const syncedProductAllowedFieldsChanges: (keyof OverrideableSyncedProductFields)[] = [
  'description',
  'imageUrls',
  'title',
];

export interface ComponentProps {
  currency: CBOCurrency;
  product?: CBOEventReadModelProduct;
  readonly: boolean;
}

// TODO: Use view model
export default function ProductDetail(props: ComponentProps) {
  const { eventId } = useRequiredParams<{ eventId: EventId }>();

  const { product = getEmptyCBOProduct(), readonly } = props;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation(['events', 'common']);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [isResetConfirmationDialogOpen, setIsResetConfirmationDialogOpen] = useState(false);
  const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false);

  const event = useSelector((state: State) => state.events.currentEvent);
  const { status: eventProductDeleteStatus } = useSelector((state: State) => state.events.eventProductDelete);
  const { status: eventProductAdditionStatus } = useSelector((state: State) => state.events.eventProductAddition);
  const { status: eventProductUpdateStatus } = useSelector((state: State) => state.events.eventProductUpdate);

  const methods = useForm<CBOEventReadModelProductState>({
    defaultValues: {
      ...product.toState(),
      ...product.getOverriddenFieldsValues(),
    },
  });
  const { handleSubmit, watch, reset, getValues, setValue, formState } = methods;

  const hasOptionsChecked = watch('hasOptionsChecked');
  const options = watch('options');
  const option1 = watch('options.option1');
  const option2 = watch('options.option2');
  const option3 = watch('options.option3');
  const variants = watch('options.variants');

  const mode: 'create' | 'edit' = props.product ? 'edit' : 'create';

  const allowedFieldsChanges: (keyof CBOEventReadModelProduct)[] = useMemo(() => {
    return product?.isSynced ? syncedProductAllowedFieldsChanges : unsyncedProductAllowedFieldsChanges;
  }, [product?.isSynced]);

  const isPending = useMemo(
    () =>
      eventProductAdditionStatus === 'pending' ||
      eventProductUpdateStatus === 'pending' ||
      eventProductDeleteStatus === 'pending',
    [eventProductAdditionStatus, eventProductUpdateStatus, eventProductDeleteStatus],
  );

  useEffect(() => {
    if (eventProductAdditionStatus === 'success' && mode === 'create') {
      resetProductAddition();
    }
  }, [eventProductAdditionStatus, mode]);

  useEffect(() => {
    if (eventProductUpdateStatus === 'success') {
      reset({}, { keepValues: true });
    }
  }, [eventProductUpdateStatus, reset]);

  useEffect(() => {
    if (eventProductDeleteStatus === 'success' && isBeingDeleted) {
      navigate(routes.events.products({ eventId }));
    }
  }, [eventProductDeleteStatus, eventId, history]);

  useEffect(() => {
    if (props.product) resetForm(props.product);
  }, [props.product]);

  useEffect(() => {
    if (hasOptionsChecked && !options) {
      setValue('options', getEmptyCBOProductOptions());
    } else if (!hasOptionsChecked && options) {
      setValue('options', null);
    }
  }, [hasOptionsChecked]);

  useEffect(() => {
    if (!options) return;
    setValue('options.variants', generateNewVariants());
  }, [option1, option2, option3]);

  const generateNewVariants = (): ProductVariantState[] => {
    let selectedOptionsValues: SelectedOptionsValue[];

    if (!!option2?.selectedValues?.length) {
      if (!!option3?.selectedValues?.length) {
        selectedOptionsValues = [];
        option1.selectedValues.forEach((option1Value: ProductOptionValue) => {
          option2.selectedValues.forEach((option2Value: ProductOptionValue) =>
            option3.selectedValues.forEach((option3Value: ProductOptionValue) =>
              selectedOptionsValues.push({
                name: `${option1Value.name} / ${option2Value.name} / ${option3Value.name}`,
                option1Id: option1Value.id,
                option2Id: option2Value.id,
                option3Id: option3Value.id,
              }),
            ),
          );
        });
      } else {
        selectedOptionsValues = [];
        option1.selectedValues.forEach((option1Value: ProductOptionValue) => {
          option2.selectedValues.forEach((option2Value: ProductOptionValue) =>
            selectedOptionsValues.push({
              name: `${option1Value.name} / ${option2Value.name}`,
              option1Id: option1Value.id,
              option2Id: option2Value.id,
              option3Id: null,
            }),
          );
        });
      }
    } else {
      selectedOptionsValues = option1.selectedValues.map(({ id, name }) => ({
        name,
        option1Id: id,
        option2Id: null,
        option3Id: null,
      }));
    }

    const actualVariants: CBOEventReadModelProductVariant[] = getValues('options.variants');
    const newVariants: CBOEventReadModelProductVariant[] = selectedOptionsValues.reduce(
      (acc: CBOEventReadModelProductVariant[], optionValue: SelectedOptionsValue) => {
        const variant = actualVariants.find(
          (actualVariant) =>
            actualVariant.option1ValueId === optionValue.option1Id &&
            (actualVariant.option2ValueId ?? null) === (optionValue.option2Id ?? null) &&
            (actualVariant.option3ValueId ?? null) === (optionValue.option3Id ?? null),
        );
        if (variant) {
          acc.push(variant);
        } else {
          acc.push({
            coverUrl: '',
            crossedOutPrice: null,
            label: optionValue.name,
            option1ValueId: optionValue.option1Id,
            option2ValueId: optionValue.option2Id,
            option3ValueId: optionValue.option3Id,
            price: 0,
            removed: false,
            variantId: '',
          });
        }
        return acc;
      },
      [],
    );

    return newVariants;
  };

  const onSubmit: SubmitHandler<CBOEventReadModelProductState> = (data: CBOEventReadModelProductState) => {
    const imageUrls = data.imageUrls.filter((imageUrl) => !!imageUrl);

    let updatedProduct: CBOEventReadModelProduct;
    const previousProductState = product.toState();

    if (previousProductState.isSynced) {
      updatedProduct = CBOEventReadModelProduct.create({
        ...previousProductState,
        isSynced: true,
        overriddenFields: {
          description: previousProductState.description === data.description ? null : data.description,
          imageUrls: JSON.stringify(previousProductState.imageUrls) === JSON.stringify(imageUrls) ? null : imageUrls,
          title: previousProductState.title === data.title ? null : data.title,
        },
      });
    } else {
      updatedProduct = CBOEventReadModelProduct.create({
        ...data,
        imageUrls,
        url: data.url === '' ? null : data.url,
      });
    }

    if (mode === 'create') {
      return dispatch(createEventProduct({ eventId, payload: updatedProduct.toState() }));
    }

    if (mode === 'edit' && product) {
      return dispatch(
        updateEventProduct({
          eventId,
          updatedProduct,
        }),
      );
    }
  };

  const handleCloseDeleteConfirmation = (yesOrNo: boolean) => {
    setIsDeleteConfirmationDialogOpen(false);
    if (yesOrNo) {
      setIsBeingDeleted(true);
      return dispatch(deleteEventProduct({ eventId, productId: product.id }));
    }
  };

  const handleCloseResetConfirmation = (yesOrNo: boolean) => {
    setIsResetConfirmationDialogOpen(false);
    if (yesOrNo) {
      return resetForm(product);
    }
  };

  const handleRemoveOption2 = () => {
    setValue('options.option2', null, { shouldDirty: true });
  };

  const handleRemoveOption3 = () => {
    setValue('options.option3', null, { shouldDirty: true });
  };

  const handleAddOption2 = () => {
    const option2 = getEmptyCBOProductOption();
    setValue('options.option2', option2, { shouldDirty: true });
  };

  const handleAddOption3 = () => {
    const option3 = getEmptyCBOProductOption();
    setValue('options.option3', option3, { shouldDirty: true });
  };

  const handleChangeOption1 = (newOption: EventProductOption) => {
    setValue('options.option1', newOption, { shouldDirty: true });
  };
  const handleChangeOption2 = (newOption: EventProductOption) => {
    setValue('options.option2', newOption, { shouldDirty: true });
  };
  const handleChangeOption3 = (newOption: EventProductOption) => {
    setValue('options.option3', newOption, { shouldDirty: true });
  };

  const handleDeleteOption1 = (value: ProductOptionValue) => {
    if (option1) handleChangeOption1({ ...option1, allValues: option1.allValues.filter(({ id }) => id !== value.id) });
  };
  const handleDeleteOption2 = (value: ProductOptionValue) => {
    if (option2) handleChangeOption2({ ...option2, allValues: option2.allValues.filter(({ id }) => id !== value.id) });
  };
  const handleDeleteOption3 = (value: ProductOptionValue) => {
    if (option3) handleChangeOption3({ ...option3, allValues: option3.allValues.filter(({ id }) => id !== value.id) });
  };

  const renderActionBar = () => (
    <StickyActionBarWrapper inProgress={isPending}>
      <Grid item xs={12}>
        <ProductActionBar
          formState={formState}
          isPending={isPending}
          mode={mode}
          onCancel={() => setIsResetConfirmationDialogOpen(true)}
          onDelete={() => setIsDeleteConfirmationDialogOpen(true)}
        />
      </Grid>
    </StickyActionBarWrapper>
  );

  const resetForm = (product: CBOEventReadModelProduct) => {
    reset({
      ...product.toState(),
      ...product.getOverriddenFieldsValues(),
    });
  };

  const resetProductAddition = () => {
    reset({}, { keepValues: true });
    dispatch(resetAddProductsToEvent());
    dispatch(resetCreateProduct());
    navigate(routes.events.products({ eventId }));
  };

  if (!event) return null;

  return (
    <StyledGrid container id="product-detail-root">
      <ProductDetailHeader event={event} />
      <Grid item sx={{ flex: 1 }} xs={12}>
        <FormProvider {...methods}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            {isPending ? <LinearProgress className={classes.linearProgress} /> : <Divider variant="fullWidth" />}

            {event && (
              <Grid container flex={1} justifyContent="center" p={3}>
                <Grid item lg={10} md={10} xl={8} xs={12}>
                  <MainDescriptionSection
                    allowedFieldsChanges={allowedFieldsChanges}
                    currency={props.currency}
                    hasOptionsChecked={hasOptionsChecked}
                    isPending={isPending}
                    product={product}
                    readonly={readonly}
                  />
                  <ImagesSection
                    isPending={isPending}
                    product={product}
                    readonly={readonly || !allowedFieldsChanges.includes('imageUrls')}
                    roundBottom={readonly && !product?.options}
                  />
                  <DetailsSection
                    hasOptionsChecked={hasOptionsChecked}
                    isPending={isPending}
                    onAddOption2={handleAddOption2}
                    onAddOption3={handleAddOption3}
                    onChangeOption1={handleChangeOption1}
                    onChangeOption2={handleChangeOption2}
                    onChangeOption3={handleChangeOption3}
                    onChangeOptionsChecked={(value) => setValue('hasOptionsChecked', value)}
                    onDeleteOption1={handleDeleteOption1}
                    onDeleteOption2={handleDeleteOption2}
                    onDeleteOption3={handleDeleteOption3}
                    onRemoveOption2={handleRemoveOption2}
                    onRemoveOption3={handleRemoveOption3}
                    option1={option1}
                    option2={option2}
                    option3={option3}
                    product={product}
                    readonly={readonly || !allowedFieldsChanges.includes('options')}
                    roundBottom={!hasOptionsChecked}
                  />
                  {hasOptionsChecked && variants && (
                    <PreviewSection
                      currency={props.currency}
                      defaultSamePricesChecked={product.options?.samePricesChecked ?? false}
                      readonly={readonly || !allowedFieldsChanges.includes('options')}
                    />
                  )}
                </Grid>
              </Grid>
            )}

            {!readonly && renderActionBar()}

            <ConfirmationDialog
              id="delete-product-confirmation"
              keepMounted={true}
              onClose={handleCloseDeleteConfirmation}
              open={isDeleteConfirmationDialogOpen}
              title={`${t('DeleteThisProduct')} ${product.title}`}
            />
            <ConfirmationDialog
              id="reset-product-confirmation"
              keepMounted={true}
              onClose={handleCloseResetConfirmation}
              open={isResetConfirmationDialogOpen}
              title={t('common:CancelModifications')}
            />
          </form>
        </FormProvider>
      </Grid>
    </StyledGrid>
  );
}
