import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { fetchMagicContent } from '../../../usecases/event/magic-content/fetch-magic-content';
import { generateMagicContent } from '../../../usecases/event/magic-content/generate-magic-content';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, pendingStatus, successStatus } from '../../utils';

export const buildMagicContentReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchMagicContent.fulfilled, (state, action) => {
    if (state.currentEvent?.id !== action.meta.arg.eventId) {
      return state;
    }

    return {
      ...state,
      ...loadedStatus('magicContentFetching'),
      currentEventMagicContents: action.payload.magicContents,
      isTranscriptAvailable: action.payload.transcriptAvailable,
    };
  });
  builder.addCase(fetchMagicContent.pending, (state) => ({
    ...state,
    ...pendingStatus('magicContentFetching'),
    currentEventMagicContents: [],
    isTranscriptAvailable: false,
  }));
  builder.addCase(fetchMagicContent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('magicContentFetching', [action.error]),
  }));

  builder.addCase(generateMagicContent.pending, (state) => ({
    ...state,
    ...pendingStatus('magicContentGenerating'),
  }));
  builder.addCase(generateMagicContent.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('magicContentGenerating'),
    currentEventMagicContents: [
      ...state.currentEventMagicContents,
      {
        createdAt: new Date(),
        id: action.payload.id,
        status: 'pending',
      },
    ],
  }));
  builder.addCase(generateMagicContent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('magicContentGenerating', [action.error]),
  }));
};
