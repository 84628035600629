import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { App } from '../App';
import AppReviewOTP from '../app-review/AppReviewOTP';
import { AuthRedirect } from './AuthRedirect';

function Auth() {
  return (
    <Routes>
      <Route element={<AppReviewOTP />} path="/app-review-otp" />
      <Route element={<AuthRedirect />} path="/auth/redirect" />
      <Route element={<App />} path="/*" />
    </Routes>
  );
}

export default Auth;
