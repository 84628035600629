import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 19.6 16.5" {...props}>
      <g id="icn-settings" transform="translate(-7 -10.995)">
        <path
          d="M26.221,28.566H13.629a1.961,1.961,0,0,0-3.844,0H7.392a.392.392,0,0,0,0,.785H9.785a1.961,1.961,0,0,0,3.844,0H26.221a.392.392,0,0,0,0-.785ZM11.707,30.135a1.177,1.177,0,1,1,1.177-1.177A1.177,1.177,0,0,1,11.707,30.135Z"
          transform="translate(0 -9.724)"
        />
        <path
          d="M26.221,44.566H23.829a1.961,1.961,0,0,0-3.844,0H7.392a.392.392,0,1,0,0,.785H19.984a1.961,1.961,0,0,0,3.844,0h2.393a.392.392,0,1,0,0-.785Zm-4.315,1.569a1.177,1.177,0,1,1,1.177-1.177A1.177,1.177,0,0,1,21.906,46.135Z"
          transform="translate(0 -19.447)"
        />
        <path d="M7.392,13.351H19.984a1.961,1.961,0,0,0,3.844,0h2.393a.392.392,0,0,0,0-.785H23.829a1.961,1.961,0,0,0-3.844,0H7.392a.392.392,0,0,0,0,.785Zm14.514-1.569a1.177,1.177,0,1,1-1.177,1.177A1.177,1.177,0,0,1,21.906,11.781Z" />
      </g>
    </SvgIcon>
  );
}
