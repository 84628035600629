import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type UnpublishEventParams = {
  eventId: EventId;
};

export const unpublishEvent = createAppAsyncThunk(
  'events/unpublish',
  async ({ eventId }: UnpublishEventParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.unPublishOne(eventId);
  },
);
