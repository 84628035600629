import { EventStatus } from '@bellepoque/api-contracts';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useMemo } from 'react';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CommandStatus } from '../../../../../core/store/state/utils';
import { EventDisplayedTime, EventDuration } from '../../../atoms/event/DisplayedValue';
import HeaderCurrentTime from '../../../atoms/event/HeaderCurrentTime';
import HeaderTitle from '../../../atoms/event/HeaderTitle';
import HeaderLiveActions from '../header-actions/HeaderLiveActions';

type LiveManagerHeaderProps = {
  event: CBOEventReadModel;
  eventClosingStatus: CommandStatus;
  eventOpeningStatus: CommandStatus;
  eventPublishStatus: CommandStatus;
  eventUnPublishStatus: CommandStatus;
  onCloseEvent: () => void;
  onOpenEvent: () => void;
  onOpenReplay: () => void;
  onPublishEvent: () => void;
  onPublishReplay: () => void;
  onUnpublishEvent: () => void;
  replayOpeningStatus: CommandStatus;
  replayPublishStatus: CommandStatus;
};

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '4fr 5fr',
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(2),
  width: '100%',
}));

const LeftSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: theme.spacing(2),
}));

const RightSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
}));

export default function LiveManagerHeader({
  event,
  eventClosingStatus,
  eventOpeningStatus,
  eventPublishStatus,
  eventUnPublishStatus,
  onCloseEvent,
  onOpenEvent,
  onOpenReplay,
  onPublishEvent,
  onPublishReplay,
  onUnpublishEvent,
  replayOpeningStatus,
  replayPublishStatus,
}: LiveManagerHeaderProps) {
  const isEventTimeDisplayed = event.startTime || event.showTime;

  const areHeaderLiveActionButtonsDisabled = useMemo(() => {
    switch (event.status) {
      case EventStatus.PLANNED:
        return eventPublishStatus === 'pending';
      case EventStatus.PRIVATE_PREVIEW:
        return eventUnPublishStatus === 'pending' || eventOpeningStatus === 'pending';
      case EventStatus.ON_AIR:
        return eventPublishStatus === 'pending' || eventClosingStatus === 'pending';
      case EventStatus.REPLAY_READY:
        return replayPublishStatus === 'pending';
      case EventStatus.PRIVATE_REPLAY:
        return replayOpeningStatus === 'pending';
      default:
        return false;
    }
  }, [
    event.status,
    eventClosingStatus,
    eventOpeningStatus,
    eventPublishStatus,
    eventUnPublishStatus,
    replayOpeningStatus,
    replayPublishStatus,
  ]);

  return (
    <Container>
      <LeftSideContainer gridTemplateColumns={isEventTimeDisplayed ? '2fr 3fr' : '4fr 2fr'} id="header-left-side">
        <Box alignItems="center" display="flex" id="header-title-wrapper" textAlign="left">
          <HeaderTitle event={event} />
        </Box>
        <Box display="flex" gap={1} justifyContent="space-evenly">
          <EventDisplayedTime event={event} />
          <EventDuration event={event} />
        </Box>
      </LeftSideContainer>
      <RightSideContainer id="header-right-side">
        <Box id="header-current-time-wrapper">
          <HeaderCurrentTime />
        </Box>
        <Box alignItems="center" flex={1} flexDirection="row" id="header-actions-wrapper" justifyContent="center">
          <HeaderLiveActions
            areButtonsDisabled={areHeaderLiveActionButtonsDisabled}
            event={event}
            onCloseEvent={onCloseEvent}
            onOpenEvent={onOpenEvent}
            onOpenReplay={onOpenReplay}
            onPublishEvent={onPublishEvent}
            onPublishReplay={onPublishReplay}
            onUnpublishEvent={onUnpublishEvent}
          />
        </Box>
      </RightSideContainer>
    </Container>
  );
}
