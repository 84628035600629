import { Box, SxProps, Tooltip, styled } from '@mui/material';
import React from 'react';

interface CounterProps {
  helperText: string;
  sx?: SxProps;
  title: string;
  titleSx?: SxProps;
  value: number | string;
}

const Container = styled(Box)({
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '7rem',
});

const TitleContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: 'x-small',
  marginBottom: theme.spacing(0.5),
  textTransform: 'uppercase',
}));

export default function Counter(props: CounterProps) {
  return (
    <Tooltip arrow title={props.helperText}>
      <Container sx={props.sx}>
        <Box fontSize="x-large">{props.value}</Box>
        <TitleContainer sx={props.titleSx}>{props.title}</TitleContainer>
      </Container>
    </Tooltip>
  );
}
