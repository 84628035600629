import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { formatCurrency } from '../../../../utils/currency-formatter';

const AddToCart = styled('button')(({ theme }) => ({
  border: 'none',
  borderRadius: '12px',
  color: theme.palette.common.white,
  fontSize: '12px',
  fontWeight: 'bold',
  padding: '8px',
  textTransform: 'uppercase',
}));

const AddToCartContainer = styled(Box)({
  margin: '8px',
});

const ImagePlaceholder = styled(Box)({
  backgroundColor: '#e9edf3',
  borderRadius: '12px',
  height: '70px !important',
  margin: '8px',
  objectFit: 'cover',
  width: '70px',
});

const Details = styled(Box)({
  alignSelf: 'flex-start',
  display: 'flex',
  flex: '2 1 0px',
  flexDirection: 'column',
  height: '100%',
  margin: '4px 0',
});

const Price = styled(Box)({
  fontSize: '13px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

const PriceContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
});

const Product = styled(Box)(({ theme }) => ({
  '-webkit-box-align': 'center',
  '-webkit-box-pack': 'justify',
  '-webkit-tap-highlight-color': 'transparent',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '12px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  flexShrink: '0',
  justifyContent: 'space-between',
  'scroll-snap-align': 'center',
  width: '100%',
}));

const ScrollContainer = styled(Box)({
  display: 'flex',
  margin: '8px',
  overflow: 'hidden',
  'scroll-behavior': 'smooth',
  'scroll-snap-type': 'x mandatory',
});

export const SlashedPrice = styled(Price)`
  color: #a8a8a8 !important;
  position: relative;
  width: min-content;
  margin-right: 8px;

  &:before {
    content: '';
    border-bottom: 1px solid #70645c;
    width: calc(100% + 8px);
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

const Title = styled(Box)({
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '4px 0',
});

interface ThemePreviewProductsProps {
  addToCartBackgroundColor: string;
  currency: CBOCurrency;
}

export function ThemePreviewProducts({ addToCartBackgroundColor, currency }: ThemePreviewProductsProps) {
  const { t } = useTranslation('events', { keyPrefix: 'themePreview' });
  const crossedOutPrice = 249;
  const price = 199;

  const renderProduct = () => (
    <Product>
      <ImagePlaceholder />
      <Details>
        <Title>{t('productTitle')}</Title>
        <PriceContainer>
          <SlashedPrice>{formatCurrency(crossedOutPrice, currency)}</SlashedPrice>
          <Price>{formatCurrency(price, currency)}</Price>
        </PriceContainer>
      </Details>
      <AddToCartContainer>
        <AddToCart style={{ backgroundColor: addToCartBackgroundColor }}>{t('addToCart')}</AddToCart>
      </AddToCartContainer>
    </Product>
  );
  return <ScrollContainer>{renderProduct()}</ScrollContainer>;
}
