import { TenantId } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, createAction, createSlice } from '@reduxjs/toolkit';

import { getLocalStorageItem } from '../../../utils/local-storage';
import { CBOTenant } from '../../domain/CBOTenant';
import { fetchTenants } from '../../usecases/tenants/fetch-tenants';
import { renewApiKey } from '../../usecases/tenants/renew-api-key';
import { updateAlias } from '../../usecases/tenants/update-alias';
import { updateCurrency } from '../../usecases/tenants/update-currency';
import { updateIntegrations } from '../../usecases/tenants/update-integrations';
import { INITIAL_TENANTS_STATE, TenantsState } from '../state/tenants';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../utils';

const cacheTenantId = () => getLocalStorageItem('tenantId');
const envTenantId = () => process.env.REACT_APP_BELLEPOQUE_TENANT_ID;

const getDefaultTenant = (tenants: CBOTenant[], lastSelectedTenantId: TenantId | null): CBOTenant => {
  const previousSessionTenantId = cacheTenantId();
  const previousSessionTenant = tenants.find((t) => t.id === previousSessionTenantId);
  const defaultTenantId = envTenantId();
  const defaultTenant = tenants.find((t) => t.id === defaultTenantId);
  const lastSelectedTenant = tenants.find((t) => t.id === lastSelectedTenantId);

  return lastSelectedTenant ?? previousSessionTenant ?? defaultTenant ?? tenants[0];
};

export const resetRenewApiKey = createAction<void>('tenants/apiKey/renew-reset');
export const resetUpdateCurrency = createAction<void>('tenants/reset-update-currency');
export const resetUpdateIntegrations = createAction<void>('tenants/reset-update-integrations');
export const selectTenant = createAction<TenantId>('tenants/select-tenant');

const buildAliasReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(updateAlias.fulfilled, (state, action) => {
    const updatedTenant: CBOTenant = { ...state.currentTenant, alias: action.meta.arg.alias };
    const tenants = state.tenants.map((tenant) => {
      if (tenant.id === state.currentTenantId) {
        return updatedTenant;
      }
      return tenant;
    });
    return {
      ...state,
      ...successStatus('updateAlias'),
      currentTenant: updatedTenant,
      tenants,
    };
  });
  builder.addCase(updateAlias.pending, (state) => {
    return {
      ...state,
      ...pendingStatus('updateAlias'),
    };
  });
  builder.addCase(updateAlias.rejected, (state, action) => ({
    ...state,
    ...errorStatus('updateAlias', [action.error]),
  }));
};

const buildApiKeyReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(renewApiKey.fulfilled, (state, action) => {
    const updatedTenant: CBOTenant = {
      ...state.currentTenant,
      apiKey: action.payload,
    };

    const tenants = state.tenants.map((tenant) => {
      if (tenant.id === state.currentTenantId) {
        return updatedTenant;
      }
      return tenant;
    });

    return {
      ...state,
      ...successStatus('renewApiKey'),
      currentTenant: updatedTenant,
      tenants,
    };
  });
  builder.addCase(renewApiKey.pending, (state) => ({
    ...state,
    ...pendingStatus('renewApiKey'),
  }));
  builder.addCase(renewApiKey.rejected, (state, action) => ({
    ...state,
    ...errorStatus('renewApiKey', [action.error]),
  }));
  builder.addCase(resetRenewApiKey, (state) => ({
    ...state,
    ...notRequestedStatus('renewApiKey'),
  }));
};

const buildCrudReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(fetchTenants.fulfilled, (state, action) => {
    const currentTenant = getDefaultTenant(action.payload, state.currentTenantId);
    return {
      ...state,
      ...loadedStatus('tenantsFetching'),
      currentTenant,
      currentTenantId: currentTenant.id,
      tenants: action.payload.map((tenant) => ({
        ...tenant,
        createdAt: new Date(tenant.createdAt),
      })),
    };
  });
  builder.addCase(fetchTenants.pending, (state) => {
    return {
      ...state,
      ...pendingStatus('tenantsFetching'),
    };
  });
  builder.addCase(fetchTenants.rejected, (state, action) => ({
    ...state,
    ...errorStatus('tenantsFetching', [action.error]),
  }));
};

const buildSelectTenantReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(selectTenant, (state, action) => {
    const currentTenant = state.tenants.find(({ id }) => id === action.payload) ?? state.currentTenant;

    return {
      ...state,
      currentTenant,
      currentTenantId: currentTenant.id,
    };
  });
};

const buildCurrencyReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(updateCurrency.fulfilled, (state) => ({
    ...state,
    ...successStatus('updateCurrency'),
  }));
  builder.addCase(updateCurrency.pending, (state, action) => {
    const updatedTenant: CBOTenant = {
      ...state.currentTenant,
      currency: action.meta.arg.currency,
    };

    const tenants = state.tenants.map((tenant) => {
      if (tenant.id === state.currentTenantId) {
        return updatedTenant;
      }
      return tenant;
    });
    return {
      ...state,
      ...pendingStatus('updateCurrency'),
      currentTenant: updatedTenant,
      tenants,
    };
  });
  builder.addCase(updateCurrency.rejected, (state, action) => ({
    ...state,
    ...errorStatus('updateCurrency', [action.error]),
  }));
  builder.addCase(resetUpdateCurrency, (state) => ({
    ...state,
    ...notRequestedStatus('updateCurrency'),
  }));
};

const buildIntegrationsReducers = (builder: ActionReducerMapBuilder<TenantsState>) => {
  builder.addCase(updateIntegrations.fulfilled, (state, action) => {
    const updatedTenant: CBOTenant = {
      ...state.currentTenant,
      integrations: action.meta.arg.integrations,
    };

    const tenants = state.tenants.map((tenant) => {
      if (tenant.id === state.currentTenantId) {
        return updatedTenant;
      }
      return tenant;
    });
    return {
      ...state,
      ...successStatus('updateIntegrations'),
      currentTenant: updatedTenant,
      tenants,
    };
  });
  builder.addCase(updateIntegrations.pending, (state) => ({
    ...state,
    ...pendingStatus('updateIntegrations'),
  }));
  builder.addCase(updateIntegrations.rejected, (state, action) => ({
    ...state,
    ...errorStatus('updateIntegrations', [action.error]),
  }));
  builder.addCase(resetUpdateIntegrations, (state) => ({
    ...state,
    ...notRequestedStatus('updateIntegrations'),
  }));
};

export const tenantsSlice = createSlice({
  extraReducers: (builder) => {
    buildAliasReducers(builder);
    buildApiKeyReducers(builder);
    buildCrudReducers(builder);
    buildCurrencyReducers(builder);
    buildIntegrationsReducers(builder);
    buildSelectTenantReducers(builder);
  },
  initialState: INITIAL_TENANTS_STATE,
  name: 'tenants',
  reducers: {},
});
