import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRequiredParams } from '../../../utils/useRequiredParams';
import { routes } from '../../routes';
import ReplayDetailsTemplate from '../../templates/ReplayDetailsTemplate';
import { createReplayDetailsViewModel } from './ReplayDetails.viewmodel';

const ReplayDetails = () => {
  const viewModel = useSelector(createReplayDetailsViewModel({ dispatch: useDispatch() }));

  const { currency, currentTenantId, fetchReplay, replay, replayClosingStatus, selectTenant } = viewModel;

  const navigate = useNavigate();
  const { eventId: eventIdParam } = useRequiredParams<{ eventId: string }>();

  const handleGoToReplayTab = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    // If we change the tenant, return to the replays page
    if (currentTenantId && replay?.id === eventIdParam && replay.tenantId !== currentTenantId)
      navigate(routes.replays.root);
  }, [currentTenantId]);

  useEffect(() => {
    if (currentTenantId !== '') fetchReplay(eventIdParam);
  }, [currentTenantId, eventIdParam]);

  useEffect(() => {
    // If we land on a new event which doesn't have the correct tenant, change the tenant
    if (replay?.id === eventIdParam && replay.tenantId !== currentTenantId) selectTenant();
  }, [replay?.id]);

  return (
    <ReplayDetailsTemplate
      currency={currency}
      eventIdParam={eventIdParam}
      onGoToReplayTab={handleGoToReplayTab}
      replay={replay}
      replayClosingStatus={replayClosingStatus}
    />
  );
};

export default ReplayDetails;
