import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Link, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMagicContent } from '../../../../../../core/domain/CBOMagicContent';
import { ENDPOINTS } from '../../../../../../endpoints';
import MagicContent from './MagicContent';

const MagicContentListRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MagicContentListAccordion = styled(Accordion)({
  borderRadius: '15px',
});

const MagicContentListAccordionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  flex: 1,
  fontSize: '1.2em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  marginTop: 0,
  textAlign: 'initial',
}));

const StyledAccordionSummary = styled(AccordionSummary)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-betwen',
});

type MagicContentListProps = {
  magicContents: CBOMagicContent[];
};

const MagicContentList = ({ magicContents }: MagicContentListProps) => {
  const { t } = useTranslation(['events', 'common']);
  return (
    <MagicContentListRoot>
      {magicContents.map((magicContent, index) => (
        <MagicContentListAccordion
          defaultExpanded={index + 1 === magicContents.length}
          disableGutters
          key={magicContent.id}
        >
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <MagicContentListAccordionLabel variant="h2">
              #{index + 1} - {format(magicContent.createdAt, 'dd/MM/yyyy hh:mm:ss')}
            </MagicContentListAccordionLabel>
            <Box mr={1}>
              <Link href={ENDPOINTS.magicContentFeedback} target="_blank" title={t('common:ShareFeedback')}>
                <Button color="secondary" size="small" variant="contained">
                  <Typography fontSize=".9em">{t('common:ShareFeedback')}</Typography>
                </Button>
              </Link>
            </Box>
          </StyledAccordionSummary>
          <AccordionDetails>
            <MagicContent {...magicContent} />
          </AccordionDetails>
        </MagicContentListAccordion>
      ))}
    </MagicContentListRoot>
  );
};

export default MagicContentList;
