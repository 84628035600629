import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function MultistreamIcon(props: SvgIconProps) {
  return (
    <SvgIcon height="34.139" viewBox="0 0 29.871 34.139" width="29.871" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M153.6,34.959,144.491,47.1h-3.883a4.267,4.267,0,1,0,0,2.134h3.883l9.11,12.14a4.251,4.251,0,1,0,.64-2.7l-7.084-9.442h6.55a4.267,4.267,0,1,0,0-2.134h-6.55l7.084-9.442a4.278,4.278,0,1,0-.64-2.7Zm6.358,26.01a2.134,2.134,0,1,1-2.134-2.134A2.14,2.14,0,0,1,159.961,60.969Zm-2.134-14.936a2.134,2.134,0,1,1-2.134,2.134A2.14,2.14,0,0,1,157.827,46.033Zm-19.2,2.134a2.134,2.134,0,1,1-2.134-2.134A2.14,2.14,0,0,1,138.624,48.166Zm21.337-12.8a2.134,2.134,0,1,1-2.134-2.134A2.14,2.14,0,0,1,159.961,35.364Z"
        transform="translate(-132.223 -31.096)"
      />
    </SvgIcon>
  );
}
