import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledDialog from '../../../templates/dialog/StyledDialog';
import CreateButton from '../../atoms/CreateButton';

interface ResumeOnboardingModalProps {
  onGoToOnboarding: () => void;
  onSkip: () => void;
}

export default function ResumeOnboardingModal({ onGoToOnboarding, onSkip }: ResumeOnboardingModalProps) {
  const { t } = useTranslation('onboarding');

  return (
    <StyledDialog
      ButtonsProps={[
        {
          children: t('Leave'),
          onClick: onSkip,
          sx: (theme) => ({
            borderColor: theme.palette.common.black,
            color: theme.palette.common.black,
          }),
          variant: 'outlined',
        },
      ]}
      content={t('ResumeModal.Description')}
      customButtons={[<CreateButton key="1" onClick={onGoToOnboarding} text={t('ResumeModal.Action')} />]}
      hiddenBackdrop
      justifyButtons="flex-end"
      open
      title={t('ResumeModal.Title')}
    />
  );
}
