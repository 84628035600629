import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventEngagementStatistics } from '../../../../core/domain/CBOTenantStatistics';
import { Section } from '../../../templates/analytics';
import SectionTitle from '../../atoms/analytics/SectionTitle';
import StatsItem, { CustomStatsItem } from '../../molecules/analytics/StatsItem';

type EventEngagementSectionProps = {
  statistics: CBOEventEngagementStatistics;
};

export default function EventEngagementSection({ statistics }: EventEngagementSectionProps) {
  const { t } = useTranslation('analytics', { keyPrefix: 'Engagement' });

  return (
    <Section boxShadow={2}>
      <SectionTitle content={t('Title')} />

      <Grid columnGap={9} container flexDirection="row" mt={2} rowGap={5}>
        <Grid item>
          <StatsItem format="number" title={t('ChatUsers')} totalValue={statistics.chatUsers} />
        </Grid>
        <Grid item>
          <StatsItem format="number" title={t('UserMessages')} totalValue={statistics.chatMessages} />
        </Grid>
        <Grid item>
          <StatsItem
            format="number"
            livePercentage={
              statistics.seenProductPages.live / (statistics.seenProductPages.live + statistics.seenProductPages.replay)
            }
            liveValue={statistics.seenProductPages.live}
            replayPercentage={
              statistics.seenProductPages.replay /
              (statistics.seenProductPages.live + statistics.seenProductPages.replay)
            }
            replayValue={statistics.seenProductPages.replay}
            title={t('SeenProductPages')}
            totalValue={statistics.seenProductPages.live + statistics.seenProductPages.replay}
          />
        </Grid>
        <Grid item>
          <StatsItem
            format="number"
            livePercentage={statistics.likes.live / (statistics.likes.live + statistics.likes.replay)}
            liveValue={statistics.likes.live}
            replayPercentage={statistics.likes.replay / (statistics.likes.live + statistics.likes.replay)}
            replayValue={statistics.likes.replay}
            title={t('Likes')}
            totalValue={statistics.likes.live + statistics.likes.replay}
          />
        </Grid>
        <Grid item>
          <CustomStatsItem
            format="number"
            title={t('Reminders.SetReminders')}
            values={[
              {
                text: t('Reminders.Calendar'),
                value: statistics.reminders.calendar,
              },
              {
                text: t('Reminders.Sms'),
                value: statistics.reminders.sms,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
