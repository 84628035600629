import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type InviteMasterRequest = {
  masterEmail: string;
  tenantId: string;
};

export const inviteMaster = createAppAsyncThunk(
  'administration/invite-master',
  async (request: InviteMasterRequest, { extra: { tenantsGateway } }): Promise<void> => {
    return tenantsGateway.inviteMaster(request);
  },
);
