import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import {
  selectProduct as selectProductAction,
  unselectProduct as unselectProductAction,
} from '../../store/slices/catalog.slice';

export type SelectProductRequest = {
  isSelected: boolean;
  product: CBOCatalogProduct;
};

export const selectProduct = ({ isSelected, product }: SelectProductRequest) => {
  return isSelected ? selectProductAction(product) : unselectProductAction(product.id);
};
