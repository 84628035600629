import { TOnboardingStep } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../core/store';
import { resetCreateEvent } from '../../../core/usecases/event/crud/create-event';
import { fetchOneEvent } from '../../../core/usecases/event/crud/fetch-event';
import { resetUpdateEvent } from '../../../core/usecases/event/crud/update-event';
import { updateOnboardingProgress } from '../../../core/usecases/onboarding/update-onboarding-progress';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createOnboardingViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currentEvent, eventCreation, eventUpdate } = state.events;
    const { progress, progressFetching, progressUpdate } = state.onboarding;
    const { id: tenantId, name: tenantName } = state.tenants.currentTenant;

    return {
      currentEventHasProducts: !!currentEvent && currentEvent.products.length > 0,
      currentEventStatus: currentEvent?.status || null,
      currentStep: progress?.step ?? null,
      fetchOnboardingEvent: () => {
        if (progress?.eventId) {
          dispatch(fetchOneEvent({ eventId: progress.eventId, tenantId }));
        }
      },
      hasCreatedOrUpdatedEventSuccessfully: eventCreation.status === 'success' || eventUpdate.status === 'success',
      isCreatingOrUpdatingEvent: eventCreation.status === 'pending' || eventUpdate.status === 'pending',
      isCurrentEventTheOnboardingEvent: !!progress?.eventId && progress.eventId === currentEvent?.id,
      isFetchingProgress: progressFetching.status === 'pending',
      isUpdatingProgress: progressUpdate.status === 'pending',
      resetEventCreationAndUpdate: () => {
        if (eventCreation.status !== 'notRequested') {
          dispatch(resetCreateEvent());
        }
        if (eventUpdate.status !== 'notRequested') {
          dispatch(resetUpdateEvent());
        }
      },
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateStep: (step: TOnboardingStep) => {
        dispatch(updateOnboardingProgress({ eventId: state.onboarding.progress?.eventId ?? null, step }));
      },
    };
  };
