import { miniSerializeError } from '@reduxjs/toolkit';
import { of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, retry, switchMap } from 'rxjs/operators';

import { AppEpic } from '../../..';
import { fetchRealtimeDataFulfilled, fetchRealtimeDataRejected } from '../../../slices/event/event-realtime.slice';
import { synchronizeOneEventFulfilled } from '../../../slices/event/event-synchronize.slice';
import { ofType } from '../../utils';

export const fetchRealtimeDataEpic: AppEpic = (action$, state$, { container: { realtimeDataGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeOneEventFulfilled>>(synchronizeOneEventFulfilled.type),
    distinctUntilChanged((prev, cur) => prev.payload.id === cur.payload.id),
    switchMap((action) =>
      realtimeDataGateway.getRealtimeData(action.payload.id).pipe(
        retry({ delay: () => timer(2000) }), // reconnect to firebase if it disconnects
      ),
    ),
    map((realtimeData) => fetchRealtimeDataFulfilled({ eventId: realtimeData.id, realtimeData })),
    catchError((error) => of(fetchRealtimeDataRejected([miniSerializeError(error)]))),
  );
};
