import EventIcon from '@mui/icons-material/Event';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import { Box, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import { usesAmPm } from '../../../../utils/uses-am-pm';

interface DateTimePickerProps {
  control: Control<any>;
  disablePast?: boolean;
  disabled: boolean;
  error: boolean;
}

export default function DateTimePicker(props: DateTimePickerProps) {
  const { t } = useTranslation(['events', 'common']);

  const amPm = usesAmPm();

  return (
    <Controller
      control={props.control}
      name="showTime"
      render={({ field }) => (
        <>
          <MobileDateTimePicker
            {...field}
            InputProps={{
              endAdornment: <EventIcon />,
            }}
            ampm={amPm}
            cancelText={t('common:Cancel')}
            clearText={t('common:Clear')}
            clearable
            disablePast={!!props.disablePast}
            disabled={props.disabled}
            inputFormat={amPm ? 'dd/MM/yyyy hh:mm a' : 'dd/MM/yyyy HH:mm'}
            label={t('Showtime')}
            renderInput={(props) => (
              <Box position="relative" width="300px">
                <TextField {...props} fullWidth variant="filled" />
              </Box>
            )}
            showTodayButton
            todayText={t('common:Today')}
          />
        </>
      )}
      rules={{ required: false }}
    />
  );
}
