export default {
  AddMediasFromSources: 'Add videos to your playlist from sources below',
  AddToMediaCollection: 'Add to playlist',
  AlreadyDisplayedCollection: 'Playlist “{{alreadyPublishedInCollectionTitle}}” is already displayed here',
  CannotPublishOnHomePage: 'Upgrade to paid plans to unlock display on Home page.',
  ChangeDestination: 'Change destination',
  ChangeDestinationDescription: 'You can modify or hide your playlist location on your Shopify.',
  ClickOnDisplay: 'Click on the “Display” button above.',
  CollectionPage_one: '1 Collection page',
  CollectionPage_other: '{{count}} Collection pages',
  CollectionPages: 'Collection pages',
  Content: 'Content',
  CreateOne: 'Create playlist',
  DeleteMediaCollection: 'Delete Playlist?',
  DeleteMediaCollectionDescription: 'This playlist will be deleted.',
  Destination: 'Destination',
  DestinationDescription: 'Set where you want this playlist to appear.',
  Destinations: 'Destinations',
  Display: 'Display',
  Displayed: 'Displayed',
  DisplayedCount: 'Displayed {{publishedPagesCount}}',
  Home: 'Home',
  HomePage: 'Home Page',
  InstantDisplay: 'Instant display',
  MaxMediasReached: 'Max videos reached {{count}}/{{maxCount}}',
  MediaCollection: 'Playlist',
  MediaCollectionPublishedCannotRemove: 'Please undisplay this playlist from your Shopify first.',
  MediaCollections: 'Playlists',
  MediaCollectionsManager: 'Playlist Manager',
  Name: 'Playlist name',
  NewMediaCollection: 'New playlist',
  NoMediaCollectionsFound: 'No playlists found',
  NoReplaysFound: 'No replays found',
  NoShoppableVideosFound: 'No shoppable videos found',
  NotDisplayed: 'Not displayed',
  OrganizeMedias: 'Organize the order of videos according to your needs.',
  ProductPage_one: '1 Product page',
  ProductPage_other: '{{count}} Product pages',
  ProductPages: 'Product pages',
  Replays: 'Replays',
  SaveToDisplay: 'Save to display',
  SaveToDisplayDescription: 'Save your playlist and then you can display it on your Shopify.',
  SearchProduct: 'Search product',
  SelectPagesToDisplay: 'Select pages to automatically display your playlist on your Shopify.',
  SelectedMedias: 'Selected videos',
  SetDestination: 'Set destination',
  Shoppables: 'Shoppables',
  Status: 'Status',
};
