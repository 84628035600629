import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LiveReplayLinks from '../../../molecules/event/replay-links/LiveReplayLinks';
import ReplayDownloadLinks from '../../../molecules/event/replay-links/ReplayDownloadLinks';

const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  justifyContent: 'center',
  padding: `${theme.spacing(3)} 0 ${theme.spacing(4)} 0`,
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.4em',
  fontWeight: 'bold',
});

interface ReplayLinksDownloadsProps {
  areLinksShown: boolean;
  arePlayButtonsShown: boolean;
  eventId: string;
  landingPageUrl: string | null;
  onCopyUrl: (url: string) => void;
  replayFileUrl: string | null;
}

export default function ReplayLinksDownloads({
  areLinksShown,
  arePlayButtonsShown,
  landingPageUrl,
  eventId,
  onCopyUrl,
  replayFileUrl,
}: ReplayLinksDownloadsProps) {
  const { t } = useTranslation('events');

  return (
    <Container container>
      <Grid item md={10} xs={12}>
        <Box display="flex" gap={2} justifyContent="space-between">
          <Box flex={1}>
            <SectionTitle mb={3} variant="h2">
              {t('Downloads')}
            </SectionTitle>
            <ReplayDownloadLinks replayFileUrl={replayFileUrl} />
          </Box>
          <Box flex={areLinksShown ? 1.5 : undefined}>
            <SectionTitle mb={3} variant="h2">
              {t('LiveReplay')}
            </SectionTitle>
            <LiveReplayLinks
              areLinksShown={areLinksShown}
              arePlayButtonsShown={arePlayButtonsShown}
              eventId={eventId}
              landingPageUrl={landingPageUrl}
              onCopyUrl={onCopyUrl}
            />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
