import { Chip } from '@mui/material';
import React from 'react';

type CollectionStatusChipProps = {
  isDisplayed: boolean;
  label: string;
};

export default function CollectionStatusChip({ isDisplayed, label }: CollectionStatusChipProps) {
  return <Chip color={isDisplayed ? 'success' : 'secondary'} label={label} />;
}
