import { EventId } from '@bellepoque/api-contracts';

import { EventFilesUpload, UploadedEventAssetNames } from '../../../gateways/event-gateway';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type UploadEventFilesParams = {
  eventId: EventId;
  files: EventFilesUpload;
};

export const uploadEventFiles = createAppAsyncThunk(
  'events/upload-event-files',
  async ({ eventId, files }: UploadEventFilesParams, { extra: { eventGateway } }): Promise<UploadedEventAssetNames> => {
    return eventGateway.uploadEventFiles(eventId, files);
  },
);
