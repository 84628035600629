import { AppDispatch, State } from '../../../../core/store';
import { CreateTenantRequest, createTenant } from '../../../../core/usecases/administration/create-tenant';
import { fetchCurrencies } from '../../../../core/usecases/settings/fetch-currencies';

export const createCreateTenantViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const createTenantStatus = state.administration.createTenant.status;

    return {
      createTenantStatus,
      currencies: state.settings.currencies,
      currentTenant: state.tenants.currentTenant,
      fetchCurrencies: () => {
        dispatch(fetchCurrencies());
      },
      onCreateTenant: (payload: CreateTenantRequest) => {
        dispatch(createTenant(payload));
      },
    };
  };
