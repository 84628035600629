export default {
  BeLive: {
    Content: 'Create an event, select the products to display and launch your live shopping event in minutes.',
    Title: 'Be live in a sec’',
  },
  LiveShoppingPage: {
    Content: 'Simply select which Live event and Replay clips are promoted on your website.',
    Title: 'Keep full control',
  },
  Shoppables: {
    Content: 'Create instant shoppable short videos and display them in a playlist on any page of your store.',
    Title: 'Shoppables and Playlists',
  },
  TrackPerformance: {
    Content: 'Access the essential information of your live and replay shows: traffic, engagement, turnover...',
    Title: 'Track your performance',
  },
  Welcome: {
    Content: 'Sell, retain and grow your customers with our immersive and interactive Live Shopping solution.',
    LiveShoppingPageContent: 'Let’s take a quick tour of our live shopping platform.',
    Title: 'Live Me Up : Welcome !',
  },
};
