import { V1 } from '@bellepoque/api-contracts';
import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import { routes } from '../../../../routes';
import ConfirmationDialog from '../../../../templates/dialog/ConfirmationDialog';
import SettingsHeader from '../../../molecules/event/FormHeader';
import { createSettingsViewModel } from './Settings.viewmodel';
import SettingsForm, { UpdateEventInputs } from './SettingsForm';

const Container = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

type SettingsProps = {
  event: CBOEventReadModel;
  readonly: boolean;
};

export default function Settings({ event, readonly }: SettingsProps) {
  const viewModel = useSelector(createSettingsViewModel({ dispatch: useDispatch() }));

  const { deleteEvent, isUpdatingEvent, tenantId, tenantName, trackUserJourneyEvent, updateEvent } = viewModel;

  const navigate = useNavigate();
  const { t } = useTranslation(['events', 'common']);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        ...eventToUserJourneyData(event),
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Event settings',
    });
  }, []);

  const handleDeleteEvent = () => {
    deleteEvent(event.id);
    navigate(routes.events.root);
  };

  const handleCloseDeleteConfirmation = (yesOrNo: boolean) => {
    setIsDeleteConfirmationDialogOpen(false);
    if (yesOrNo === true) {
      handleDeleteEvent();
    }
  };

  const handleUpdateEvent = (updatedEvent: UpdateEventInputs) => {
    const payload: V1.api.UpdateEventDTO = {
      ...event,
      chatIntroductionText: updatedEvent.chatIntroductionText,
      description: updatedEvent.description,
      facebookRestreamingConfiguration: event.facebookRestreamingConfiguration
        ? {
            ...event.facebookRestreamingConfiguration,
            expiresAt: event.facebookRestreamingConfiguration.expiresAt.toISOString(),
          }
        : null,
      moderatorMessageLabel: updatedEvent.moderatorMessageLabel,
      postEventText: updatedEvent.postEventText,
      preEventText: updatedEvent.preEventText,
      publisherPhone: updatedEvent.publisherPhone,
      restreamingConfigurations: event.restreamingConfigurations.map((restreamingConfiguration) => {
        if (restreamingConfiguration.enabled) {
          return {
            ...restreamingConfiguration,
            expiresAt: restreamingConfiguration.expiresAt?.toISOString() ?? null,
          };
        }
        return restreamingConfiguration;
      }),
      showTime: updatedEvent.showTime?.toISOString() ?? event.showTime?.toISOString() ?? null,
      title: updatedEvent.title,
      youtubeRestreamingConfiguration: event.youtubeRestreamingConfiguration
        ? {
            ...event.youtubeRestreamingConfiguration,
            expiresAt: event.youtubeRestreamingConfiguration.expiresAt.toISOString(),
          }
        : null,
    };

    updateEvent(event.id, payload);
  };

  return (
    <Container>
      <SettingsHeader event={event} />
      <SettingsForm
        event={event}
        onDeleteEvent={() => setIsDeleteConfirmationDialogOpen(true)}
        onUpdateEvent={handleUpdateEvent}
        readonly={readonly}
        submissionInProgress={isUpdatingEvent}
      />

      <ConfirmationDialog
        id="delete-event-confirmation"
        keepMounted={true}
        onClose={handleCloseDeleteConfirmation}
        open={isDeleteConfirmationDialogOpen}
        title={t('DeleteEvent', { title: event.title })}
      />
    </Container>
  );
}
