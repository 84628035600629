import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, DialogTitle, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ProductCount from '../../../../atoms/event/ProductCount';

interface TitleWithSelectedProductsCountProps {
  onClearSelectedProducts: () => void;
  selectedProductsCount: number;
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  display: 'flex',
  width: '100%',
}));

const SelectedProductsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const Title = styled(DialogTitle)({
  fontSize: '2.25em',
  fontWeight: 'bold',
});

export default function TitleWithSelectedProductsCount(props: TitleWithSelectedProductsCountProps) {
  const { t } = useTranslation(['common, products']);
  const { onClearSelectedProducts, selectedProductsCount } = props;

  return (
    <Container>
      <Title>{t('products:SelectYourProducts')}</Title>

      {selectedProductsCount > 0 && (
        <SelectedProductsContainer>
          <ProductCount
            count={selectedProductsCount}
            countPosition="left"
            size="small"
            text={t('products:SelectedProducts', { count: selectedProductsCount })}
          />
          <Box>
            <Button
              color="primary"
              onClick={onClearSelectedProducts}
              size="small"
              startIcon={<ClearIcon />}
              variant="outlined"
            >
              <span>{t('common:Clear')}</span>
            </Button>
          </Box>
        </SelectedProductsContainer>
      )}
    </Container>
  );
}
