import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export default function PreviewLogoPosition(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 49.2 84.8">
      <g transform="translate(-1194.9 -342)">
        <g transform="translate(1169 334.4)">
          <g>
            <path d="M67.5,10a5.093,5.093,0,0,1,5.2,4.8V85.2A5.093,5.093,0,0,1,67.5,90H33.3a4.911,4.911,0,0,1-5-4.8V14.8a4.977,4.977,0,0,1,5-4.8H67.5m0-2.4H33.3a7.342,7.342,0,0,0-7.4,7.2V85.2a7.342,7.342,0,0,0,7.4,7.2H67.5a7.52,7.52,0,0,0,7.6-7.2V14.8a7.455,7.455,0,0,0-7.6-7.2Z" />
          </g>
          <line
            fill="none"
            stroke={theme.palette.common.black}
            strokeWidth="0.8"
            transform="translate(25.9 81.2)"
            x2="49.2"
          />
          <line
            fill="none"
            stroke={theme.palette.common.black}
            strokeWidth="0.836"
            transform="translate(47.1 15.2)"
            x2="6.8"
          />
        </g>
        <rect fill="#5c5a5a" height="9" rx="4.5" transform="translate(1198 353)" width="21.5" />
      </g>
    </SvgIcon>
  );
}
