import { Box, Button, LinearProgress, Link, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LandingPageURL from '../../atoms/landing-page/LandingPageURL';

interface HeaderProps {
  landingPageUrl: string | null;
  loading: boolean;
}

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  textAlign: 'initial',
}));

const Container = styled(Box)({
  left: 0,
  position: 'absolute',
  right: 'auto',
  width: '100%',
});

const ActionsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
}));

export default function Header({ landingPageUrl, loading }: HeaderProps) {
  const { t } = useTranslation('liveShoppingPage');

  return (
    <Container>
      <ContentContainer>
        <Box>
          <Title variant="h2">{t('Title')}</Title>
        </Box>
        {!!landingPageUrl && (
          <ActionsContainer>
            <LandingPageURL url={landingPageUrl} />
            <Link href={landingPageUrl} target="_blank" underline="none">
              <Button color="primary" sx={{ fontWeight: 'bold', height: '48px' }} variant="contained">
                {t('Preview')}
              </Button>
            </Link>
          </ActionsContainer>
        )}
      </ContentContainer>
      {loading && (
        <Box width="100%">
          <LinearProgress />
        </Box>
      )}
    </Container>
  );
}
