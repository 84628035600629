import { CBOFacebookPage, CBOFacebookUser } from '../../../domain/CBOFacebookAccount';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type FacebookLoginResult = {
  accessToken: string;
  expiresAt: Date;
  pages: CBOFacebookPage[];
  user: CBOFacebookUser;
};

export const connectFacebook = createAppAsyncThunk(
  'facebook/connect',
  async (_, { extra: { facebookGateway, tracingGateway } }): Promise<FacebookLoginResult> => {
    let accessToken: string;
    let longLivedAccessToken: string;
    let expiresAt: Date;
    let user: CBOFacebookUser;
    let pages: CBOFacebookPage[];

    try {
      const connectionResult = await facebookGateway.login();
      if (connectionResult.status !== 'connected') throw new Error('Could not connect user');
      accessToken = connectionResult.authResponse.accessToken;
      expiresAt = new Date(connectionResult.authResponse.data_access_expiration_time * 1000);
    } catch (e: any) {
      tracingGateway.captureException(e);
      throw e;
    }

    try {
      longLivedAccessToken = await facebookGateway.fetchLongLivedAccessToken(accessToken);
    } catch (e: any) {
      tracingGateway.captureException(e);
      throw e;
    }

    try {
      user = await facebookGateway.fetchUser(accessToken);
      pages = await facebookGateway.fetchPages(longLivedAccessToken);
    } catch (e: any) {
      tracingGateway.captureException(e);
      throw e;
    }

    return { accessToken: longLivedAccessToken, expiresAt, pages, user };
  },
);
