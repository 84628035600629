import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';
import { resetCloseEvent as resetCloseEventAction } from '../../../store/slices/event/event-live.slice';

type CloseEventParams = {
  eventId: EventId;
};

export const closeEvent = createAppAsyncThunk(
  'events/close',
  async ({ eventId }: CloseEventParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.closeOne(eventId);
  },
);

export const resetCloseEvent = () => {
  return resetCloseEventAction();
};
