import { TenantId } from '@bellepoque/api-contracts';

import { toCBOReplaysChapters } from '../../../../gateways/event/event-mappers';
import { CBOReplaysChapters } from '../../../domain/CBOReplaysChapters';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type FetchReplaysChaptersParams = {
  replaysIds: string[];
  tenantId: TenantId;
};

export const fetchReplaysChapters = createAppAsyncThunk(
  'events/fetch-replays-chapters',
  async (params: FetchReplaysChaptersParams, { extra: { eventGateway } }): Promise<CBOReplaysChapters> => {
    const dto = await eventGateway.getReplaysChapters(params);
    return toCBOReplaysChapters(dto);
  },
);
