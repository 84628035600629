export default {
  ClipLengthExceeded: {
    live_access: 'Clips cannot exceed 20 min',
    live_engage: 'Clips cannot exceed 60 min',
  },
  CopyUrl: 'Copy page url',
  Display: 'Display',
  ErrorWhileFetching: 'An error occured while fetching your Live shopping page',
  Hide: 'Hide',
  Highlights: {
    Highlight: 'Highlight',
    Highlighted: 'Highlighted',
    NextLive: 'Your next live will appear here',
    SectionTooltip: 'Chose the one upcoming event that will be shown as highlight on your Live Shopping page.',
    Title: 'Upcoming Event section',
  },
  LiveShopUrl: 'Your Live Shop URL',
  NoCmsPage: {
    Action: 'Create page',
    Description:
      'Use the generic, yet elegant and beautiful, Live Shopping Page and manage which Live and Replay events you highlight on your website.',
    Title: 'Create a templated {{cms}} Live Shopping page',
  },
  Preview: 'Preview on your site',
  Replays: {
    CountExceeded: 'Up to {{maxCount}} displays with your plan',
    NextReplay: 'Your replays will appear here',
    ReplaysCount: '{{count}}/{{maxCount}} displayed replays',
    SectionTitle: 'Section title',
    SectionTitleRequired: 'Replay section title is required',
    SectionTitleTooltip: 'Enter the title of your replay section on your Live Shopping page',
    SectionTooltip: 'Chose which replay clips are to be shown or removed from your Live Shopping page',
    Title: 'Replay section',
  },
  Title: 'Live Shopping Page',
  UpgradeToDislay: 'Upgrade to display',
  notifications: {
    CmsLandingPageFailedCreation: 'An error occurred while creating the live shopping page. Please retry.',
    CmsLandingPageSuccessfullyCreated: 'Live shopping page successfully created!',
    HasCmsLandingPageFetchingFailed: 'An error occured while fetching the landing page information. Please retry.',
    HasCmsLandingPageFetchingSucceeded: 'Succesfully fetched the landing page information.',
    LandingPageFailedUpdating: 'An error occurred while updating your landing page. Please retry.',
    LandingPageSuccessfullyUpdated: 'Landing page successfully updated!',
  },
};
