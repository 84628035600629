import { V1 } from '@bellepoque/api-contracts';
import { FormHelperText, Grid, TextField } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import EventSettingsIcon from '../../../../../icons/EventSettingsIcon';
import { httpUrlRegex } from '../../../../../utils/http-url-regex';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import SettingsSection from '../../SettingsSection';
import ProductPrices from '../ProductPrices';

const constraints = V1.api.constraints.products;

interface MainDescriptionSectionProps {
  allowedFieldsChanges: (keyof CBOEventReadModelProduct)[];
  currency: CBOCurrency;
  hasOptionsChecked: boolean;
  isPending: boolean;
  product: CBOEventReadModelProduct;
  readonly: boolean;
}

export default function MainDescriptionSection(props: MainDescriptionSectionProps) {
  const { currency, hasOptionsChecked, isPending, allowedFieldsChanges, product, readonly } = props;

  const { t } = useTranslation(['events', 'common']);
  const { formState, register, watch } = useFormContext();
  const { errors } = formState;

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: constraints.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: constraints.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });
  const { ref: customerInternalIdInputRef, ...customerInternalIdInputProps } = register('customerInternalId', {
    required: { message: t('CustomerInternalIdIsRequired'), value: true },
  });
  const { ref: descriptionInputRef, ...descriptionInputProps } = register('description', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.description.maxLength }),
      value: constraints.description.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.description.minLength }),
      value: constraints.description.minLength,
    },
    required: { message: t('DescriptionIsRequired'), value: true },
  });
  const { ref: urlInputRef, ...urlInputProps } = register('url', {
    pattern: {
      message: t('PleaseEnterAValidURL'),
      value: httpUrlRegex,
    },
  });

  const getHasOptionsCheckedValue = () => {
    if (readonly) return product.hasOptionsChecked;
    return hasOptionsChecked;
  };

  const disabled = isPending || readonly;

  const isFieldReadonly = (field: keyof CBOEventReadModelProduct) => !allowedFieldsChanges.includes(field);

  return (
    <SettingsSection
      icon={<EventSettingsIcon sx={{ color: (theme) => theme.palette.common.white }} />}
      roundTop
      title={t('productDetails.MainDescription')}
      width={11}
    >
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={disabled || isFieldReadonly('title')}
          error={!!errors.title}
          fullWidth
          helperText={!!errors.title ? errors?.title?.message : ''}
          id={`${product?.id || 'newProduct'}-title`}
          inputRef={titleInputRef}
          label={t('Title')}
          required
          variant="filled"
          {...titleInputProps}
        />
        <InputTooltip content={t('helpers.ProductTitle')} />
        <FormHelperText>{`${watch('title').length} / ${constraints.title.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={disabled || isFieldReadonly('description')}
          error={!!errors.description}
          fullWidth
          helperText={!!errors.description ? errors?.description?.message : ''}
          id={`${product?.id || 'newProduct'}-description`}
          inputRef={descriptionInputRef}
          label={t('Description')}
          multiline
          required
          rows={4}
          variant="filled"
          {...descriptionInputProps}
        />
        <InputTooltip content={t('helpers.ProductDescription')} />
        <FormHelperText>{`${watch('description').length} / ${constraints.description.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={disabled || isFieldReadonly('customerInternalId')}
          error={!!errors.customerInternalId}
          fullWidth
          helperText={!!errors.customerInternalId ? errors?.customerInternalId?.message : ''}
          id={`${product?.id || 'newProduct'}-customerInternalId`}
          inputRef={customerInternalIdInputRef}
          label={t('CustomerInternalId')}
          required
          variant="filled"
          {...customerInternalIdInputProps}
        />
      </Grid>
      {!getHasOptionsCheckedValue() && (
        <ProductPrices currency={currency} disabled={disabled || isFieldReadonly('price')} product={product} />
      )}
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={disabled || isFieldReadonly('url')}
          error={!!errors.url}
          fullWidth
          helperText={!!errors.url ? errors?.url?.message : ''}
          id={`${product?.id || 'newProduct'}-url`}
          inputRef={urlInputRef}
          label={t('URL')}
          variant="filled"
          {...urlInputProps}
        />
      </Grid>
    </SettingsSection>
  );
}
