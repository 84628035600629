import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const OnSiteVideosIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 24.055 20">
      <g transform="translate(56 -192)">
        <g
          stroke={props.sx && 'color' in props.sx ? props.sx?.color?.toString() : theme.palette.common.white}
          strokeWidth={1.5}
          transform="translate(-51 192)"
        >
          <rect fill="none" height="18.5" rx="2.25" width="12.5" x="0.75" y="0.75" />
        </g>
        <g transform="translate(-56 194)">
          <path
            d="M 3.000004291534424 1.5 C 2.172904491424561 1.5 1.500004291534424 2.172900199890137 1.500004291534424 3 L 1.500004291534424 13 C 1.500004291534424 13.82709980010986 2.172904491424561 14.5 3.000004291534424 14.5 L 4.523959636688232 14.5 C 4.575929164886475 10.91154861450195 4.552901268005371 9.25584888458252 4.526714324951172 7.372159957885742 C 4.506374359130859 5.909780502319336 4.483719348907471 4.280603408813477 4.492655754089355 1.5 L 3.000004291534424 1.5 M 3.000004291534424 0 L 6.000004291534424 0 C 5.946244239807129 8.3614501953125 6.147624492645264 7.592470169067383 6.000004291534424 16 L 3.000004291534424 16 C 1.343144416809082 16 4.291534423828125e-06 14.65684986114502 4.291534423828125e-06 13 L 4.291534423828125e-06 3 C 4.291534423828125e-06 1.34315013885498 1.343144416809082 0 3.000004291534424 0 Z"
            strokeWidth={0.2}
          />
        </g>
        <g transform="translate(-31.945 210) rotate(180)">
          <path
            d="M 3.000004291534424 1.5 C 2.172904491424561 1.5 1.500004291534424 2.172900199890137 1.500004291534424 3 L 1.500004291534424 13 C 1.500004291534424 13.82709980010986 2.172904491424561 14.5 3.000004291534424 14.5 L 4.523959636688232 14.5 C 4.575929164886475 10.91154861450195 4.552901268005371 9.25584888458252 4.526714324951172 7.372159957885742 C 4.506374359130859 5.909780502319336 4.483719348907471 4.280603408813477 4.492655754089355 1.5 L 3.000004291534424 1.5 M 3.000004291534424 0 L 6.000004291534424 0 C 5.946244239807129 8.3614501953125 6.147624492645264 7.592470169067383 6.000004291534424 16 L 3.000004291534424 16 C 1.343144416809082 16 4.291534423828125e-06 14.65684986114502 4.291534423828125e-06 13 L 4.291534423828125e-06 3 C 4.291534423828125e-06 1.34315013885498 1.343144416809082 0 3.000004291534424 0 Z"
            strokeWidth={0.2}
          />
        </g>
        <path
          d="M280.637,227.53a.677.677,0,0,0-.649.674v6.769a.677.677,0,0,0,1.037.572l5.413-3.382a.677.677,0,0,0,0-1.149l-5.413-3.385h0a.677.677,0,0,0-.387-.1Zm.7,1.9,3.46,2.163-3.46,2.163Z"
          strokeWidth={0.2}
          transform="translate(-326.987 -29.438)"
        />
      </g>
    </SvgIcon>
  );
};

export default OnSiteVideosIcon;
