import { V1 } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../core/store';
import { CreateEventRequest, createEvent } from '../../../../core/usecases/event/crud/create-event';
import { updateEvent } from '../../../../core/usecases/event/crud/update-event';
import { trackUserJourneyEvent } from '../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createOnboardingCreateEventViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentEvent = state.events.currentEvent;
    const progress = state.onboarding.progress;
    const { id: tenantId, name: tenantName } = state.tenants.currentTenant;

    return {
      createEvent: (payload: CreateEventRequest) => {
        dispatch(createEvent({ payload, tenantId }));
      },
      event: currentEvent,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateEvent: (payload: V1.api.UpdateEventDTO) => {
        if (progress?.eventId) {
          dispatch(updateEvent({ id: progress.eventId, payload }));
        }
      },
    };
  };
