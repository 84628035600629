import { EventId, V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type AdminFindEventRequest = {
  eventId: EventId;
};

export const adminFindEvent = createAppAsyncThunk(
  'administration/find-event',
  async (request: AdminFindEventRequest, { extra: { eventGateway } }): Promise<V1.api.EventDTO | null> => {
    return eventGateway.fetchOne(request.eventId);
  },
);
