import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NewFeaturesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(-2 -2)">
        <path
          d="M15.877,2.52a1,1,0,0,0-1.755,0l-.566,1.036-1.036.567a1,1,0,0,0,0,1.755l1.036.567.566,1.036a1,1,0,0,0,1.755,0l.566-1.036,1.036-.567a1,1,0,0,0,0-1.755l-1.036-.567Z"
          fill="#fff"
        />
        <path
          d="M10.892,7.549a1,1,0,0,0-1.785,0L7.18,11.363l-4.32.613a1,1,0,0,0-.549,1.714l3.112,2.961-.734,4.176A1,1,0,0,0,6.13,21.89L10,19.9l3.87,1.986a1,1,0,0,0,1.441-1.063l-.734-4.176,3.112-2.961a1,1,0,0,0-.549-1.714l-4.32-.613Z"
          fill="#fff"
        />
        <path
          d="M20.877,8.52a1,1,0,0,0-1.755,0l-.213.389-.389.213a1,1,0,0,0,0,1.755l.389.213.213.389a1,1,0,0,0,1.755,0l.213-.389.389-.213a1,1,0,0,0,0-1.755L21.09,8.91Z"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default NewFeaturesIcon;
