import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';
import { resetFetchYoutubeLoginUrl as resetFetchYoutubeLoginUrlAction } from '../../../store/slices/youtube.slice';

export const fetchYoutubeLoginUrl = createAppAsyncThunk(
  'youtube/fetch-login-url',
  async (_, { extra: { youtubeGateway } }): Promise<string> => {
    return youtubeGateway.fetchLoginUrl();
  },
);

export const resetFetchYoutubeLoginUrl = () => {
  return resetFetchYoutubeLoginUrlAction();
};
