import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Typography, alpha, lighten, styled } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import NewFeaturesIcon from '../../../icons/NewFeaturesIcon';
import { routes } from '../../routes';

const StyledAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '15px',
});

const StyledAccordionDetails = styled(AccordionDetails)<{ expanded: boolean }>(({ expanded, theme }) => ({
  backgroundColor: lighten(theme.palette.primary.main, 0.9),
  border: `solid ${alpha(theme.palette.primary.main, 0.7)} 1px`,
  borderRadius: '15px',
  borderTop: 'none',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  transition: 'border-radius 0.3s ease',

  ...(expanded && {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
}));

const StyledAccordionSummary = styled(AccordionSummary)<{ expanded: boolean }>(({ expanded, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '15px',
  transition: 'border-radius 0.3s ease',

  ...(expanded && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
}));

const Title = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  fontSize: '1em',
  fontWeight: 'bold',
  gap: theme.spacing(2),
  textAlign: 'initial',
}));

const Content = styled(Typography)({
  fontSize: '.95em',
  textAlign: 'initial',
  whiteSpace: 'pre-line',
});

const Category = styled(Typography)({
  display: 'inline',
  fontWeight: 'bold',
});

type ReleaseNotesProps = {
  defaultExpanded?: boolean;
  hasShopify: boolean;
  version: string;
};

export default function ReleaseNotes({ defaultExpanded = true, hasShopify, version }: ReleaseNotesProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const { t } = useTranslation('home', { keyPrefix: 'ReleaseNotes' });

  return (
    <StyledAccordion
      disableGutters
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      variant="outlined"
    >
      <StyledAccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ color: (theme) => theme.palette.common.white }} />}
        expanded={isExpanded}
      >
        <Title>
          <NewFeaturesIcon fontSize="small" />
          {t('Title')}
        </Title>
      </StyledAccordionSummary>
      <StyledAccordionDetails expanded={isExpanded}>
        <Content variant="body2">
          <Trans
            components={{
              Category: <Category />,
              OpenShoppablesLink: <Link to={routes.shoppables} />,
            }}
            i18nKey={`ReleaseNotes.${version}${hasShopify ? '' : '-NonShopify'}`}
            ns="home"
          />
        </Content>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
