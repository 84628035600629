import { OnboardingStep } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchOnboardingProgress } from '../../../core/usecases/onboarding/fetch-onboarding-progress';
import { skipOnboarding } from '../../../core/usecases/onboarding/skip-onboarding';

export const createMenuViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const progress = state.onboarding.progress;
    const progressFetchingStatus = state.onboarding.progressFetching.status;
    const shopifyScopesFetchingStatus = state.shopify.checkShopifyScopesAreUpToDateFetching.status;

    return {
      areShopifyScopesUpToDate: state.shopify.areShopifyScopesUpToDate,
      fetchOnboardingProgress: () => {
        dispatch(fetchOnboardingProgress());
      },
      hasCheckedShopifyScopes: shopifyScopesFetchingStatus === 'loaded',
      hasCompletedOnboarding: progress?.step === OnboardingStep.Completed,
      hasFetchedOnboardingProgress: progressFetchingStatus === 'loaded',
      hasStartedOnboarding: progress !== null,
      skipOnboarding: () => {
        dispatch(skipOnboarding());
      },
    };
  };
