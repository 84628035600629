import { YoutubeAccessToken } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export const checkHasYoutubeLiveStreamEnabled = createAppAsyncThunk(
  'youtube/check-has-live-stream-enabled',
  async (token: YoutubeAccessToken, { extra: { youtubeGateway } }): Promise<boolean> => {
    return youtubeGateway.checkHasLiveStreamPermissions(token.accessToken);
  },
);
