import { BILLING_PLANS, BillingPlanId, CMS_WITH_SALES_TRACKING, Cms } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchHomePage } from '../../../core/usecases/home/fetch-home-page';
import { createCmsLandingPage } from '../../../core/usecases/landing-page/create-cms-landing-page';
import { fetchLandingPage } from '../../../core/usecases/landing-page/fetch-landing-page';
import { hasCmsLandingPage as checkHasCmsLandingPage } from '../../../core/usecases/landing-page/has-cms-landing-page';
import { checkPlayerPlaylistsBlockIsAdded } from '../../../core/usecases/shoppables/check-player-playlists-block-is-added';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { resetLocalStorageNotificationSeen } from '../../../utils/local-storage';

export const createHomeViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { activeSubscriptionId, cms, currency, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const { cmsLandingPageCreate, hasCmsLandingPage, landingPageData } = state.landingPage;
    const { shoppableCreation, playerPlaylistsBlockIsAdded } = state.shoppables;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    const hasShopify = cms === Cms.Shopify;

    return {
      canShareEvents: cms === Cms.Shopify,
      checkHasCmsLandingPage: () => {
        if (hasShopify) {
          dispatch(checkHasCmsLandingPage({ tenantId }));
        }
      },
      checkHasPlayerPlaylistsBlock: () => {
        if (hasShopify) {
          dispatch(checkPlayerPlaylistsBlockIsAdded(tenantId));
        }
      },
      cmsLandingPageCreationStatus: cmsLandingPageCreate.status,
      createCmsLandingPage: () => {
        dispatch(createCmsLandingPage({ tenantId }));
      },
      currency,
      currentPlanId,
      fetchHomePage: () => {
        dispatch(fetchHomePage({ tenantId }));
      },
      fetchHomePageStatus: state.home.homePageFetching.status,
      fetchLandingPage: () => {
        dispatch(fetchLandingPage({ tenantId }));
      },
      hasCmsLandingPageFetchingStatus: state.landingPage.hasCmsLandingPageFetching.status,
      hasShopify,
      isLandingPageCreatedNotificationShown: hasShopify && hasCmsLandingPage,
      isNoLandingPageFoundNotificationShown: hasShopify && !hasCmsLandingPage,
      isNoPlayerPlaylistsBlockNotificationShown: hasShopify && !playerPlaylistsBlockIsAdded,
      isProductPagesWithMediaCollectionsCountShown: hasShopify,
      isPublishedShoppablesCountShown: hasShopify,
      landingPageUrl: landingPageData.landingPageUrl,
      maxPublishedShoppablesCount: BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null,
      playerPlaylistsBlockIsAddedFetchingStatus: state.shoppables.playerPlaylistsBlockIsAddedFetching.status,
      productPagesWithMediaCollectionsCount: state.home.productPagesWithMediaCollectionsCount,
      productsCount: state.catalog.pagination.totalCount,
      publishedShoppablesCount: state.mediaCollections.publishedShoppablesCount,
      purchasedAmounts: state.home.purchasedAmounts,
      purchasedCarts: state.home.purchasedCarts,
      resetIsLandingPageCreatedNotification: () => {
        resetLocalStorageNotificationSeen(tenantId, 'landing-page-created');
      },
      shoppableCreationStatus: shoppableCreation.status,
      tenantId,
      tenantName,
      trackUserJourneyLiveEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
            name: 'Home',
          }),
        );
      },
      upcomingEvent: state.events.upcomingEvent,
      usesSales: CMS_WITH_SALES_TRACKING.includes(cms),
      validatedCarts: state.home.validatedCarts,
      validatedCartsAmounts: state.home.validatedCartsAmounts,
      views: state.home.views,
    };
  };
