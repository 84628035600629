import { Search, Visibility } from '@mui/icons-material';
import { Box, InputAdornment, TextField, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  borderRight: `solid 1px ${theme.palette.grey[200]}`,
  width: '100%',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginLeft: theme.spacing(3),
  padding: theme.spacing(2),
  paddingLeft: 0,
}));

const IconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.dark,
  borderRadius: '50%',
  display: 'grid',
  height: '30px',
  justifyContent: 'center',
  width: '30px',
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginLeft: theme.spacing(1),
}));

interface ShowcaseHeaderProps {
  onSearch: (search: string) => void;
}

export default function ShowcaseHeader({ onSearch }: ShowcaseHeaderProps) {
  const { t } = useTranslation('events');

  return (
    <Container>
      <ContentContainer>
        <IconContainer>
          <Visibility fontSize="small" htmlColor="white" />
        </IconContainer>
        <TitleContainer display="flex" gap={6}>
          <Typography fontSize="1.1em" fontWeight={600} variant="h6">
            {t('Showcase')}
          </Typography>

          <Box flex={1}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              label={t('common:Find')}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              size="small"
            />
          </Box>
        </TitleContainer>
      </ContentContainer>
    </Container>
  );
}
