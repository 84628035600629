import { TenantId } from '@bellepoque/api-contracts';

import { toDomain } from '../../../gateways/tenant-statistics/tenant-stats-mapper';
import { CBOTenantStatistics } from '../../domain/CBOTenantStatistics';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchTenantStatisticsParams = {
  tenantId: TenantId;
};

export const fetchTenantStatistics = createAppAsyncThunk(
  'tenants-statistics/fetch',
  async (params: FetchTenantStatisticsParams, { extra: { tenantStatisticsGateway } }): Promise<CBOTenantStatistics> => {
    const dto = await tenantStatisticsGateway.fetchTenantStatistics(params.tenantId);
    return toDomain(dto);
  },
);
