import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { checkShopifyScopesAreUpToDate } from '../../usecases/shopify/check-shopify-scopes-are-up-to-date';
import { getPagesForMediaCollections } from '../../usecases/shopify/get-pages-for-media-collections';
import { INITIAL_SHOPIFY_STATE, ShopifyState } from '../state/shopify';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildPagesReducers = (builder: ActionReducerMapBuilder<ShopifyState>) => {
  builder.addCase(getPagesForMediaCollections.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('pagesForMediaCollectionsFetching'),
    pages: action.payload,
  }));
  builder.addCase(getPagesForMediaCollections.pending, (state) => ({
    ...state,
    ...pendingStatus('pagesForMediaCollectionsFetching'),
  }));
  builder.addCase(getPagesForMediaCollections.rejected, (state, action) => ({
    ...state,
    ...errorStatus('pagesForMediaCollectionsFetching', [action.error]),
  }));
};

const buildScopesReducers = (builder: ActionReducerMapBuilder<ShopifyState>) => {
  builder.addCase(checkShopifyScopesAreUpToDate.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('checkShopifyScopesAreUpToDateFetching'),
    areShopifyScopesUpToDate: action.payload,
  }));
  builder.addCase(checkShopifyScopesAreUpToDate.pending, (state) => ({
    ...state,
    ...pendingStatus('checkShopifyScopesAreUpToDateFetching'),
  }));
  builder.addCase(checkShopifyScopesAreUpToDate.rejected, (state, action) => ({
    ...state,
    ...errorStatus('checkShopifyScopesAreUpToDateFetching', [action.error]),
  }));
};

export const shopifySlice = createSlice({
  extraReducers: (builder) => {
    buildPagesReducers(builder);
    buildScopesReducers(builder);
  },
  initialState: INITIAL_SHOPIFY_STATE,
  name: 'shopify',
  reducers: {},
});
