import { Box, Divider, Typography, styled } from '@mui/material';
import React from 'react';

type IntegrationsHeaderProps = {
  title: string;
};

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(3)}`,
  textAlign: 'left',
  width: '100%',
}));

export default function IntegrationsHeader({ title }: IntegrationsHeaderProps) {
  return (
    <Container id="details-template">
      <TitleContainer>
        <Typography
          style={{
            fontSize: '1.7vw',
            fontWeight: 'bold',
            lineHeight: '1.235',
          }}
          variant="h4"
        >
          {title}
        </Typography>
      </TitleContainer>
      <Box sx={{ width: '100%' }}>
        <Divider variant="fullWidth" />
      </Box>
    </Container>
  );
}
