import { PaginationWrapper } from '@bellepoque/api-contracts';

import { manyToDomain } from '../../../gateways/catalog/catalog-product-mappers';
import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import { FetchProductsFilters } from '../../gateways/catalog-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchProductsRequest = FetchProductsFilters;

type FetchProductsResponse = {
  products: PaginationWrapper<CBOCatalogProduct>;
  requestFilters: FetchProductsFilters;
};

export const fetchProducts = createAppAsyncThunk(
  'catalog/fetch-products',
  async (request: FetchProductsRequest, { extra: { catalogGateway } }): Promise<FetchProductsResponse> => {
    const { items, totalCount } = await catalogGateway.fetchProducts(request);

    return {
      products: {
        items: manyToDomain(items),
        totalCount,
      },
      requestFilters: request,
    };
  },
);
