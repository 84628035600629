import { SynchronizeEventListReadModelResponseDTO } from '@bellepoque/api-contracts';

import { CBOReplayChapter } from './CBOReplaysChapters';

export type CBOEventListReadModel = Omit<SynchronizeEventListReadModelResponseDTO, 'image' | 'showTime'> & {
  chapters: CBOReplayChapter[];
  image: string | null;
  showTime: Date | null;
};

export function toCBOEventListReadModels(dtos: SynchronizeEventListReadModelResponseDTO[]): CBOEventListReadModel[] {
  return dtos.map(toCBOEventListReadModel);
}

export function toCBOEventListReadModel(dto: SynchronizeEventListReadModelResponseDTO): CBOEventListReadModel {
  return {
    ...dto,
    chapters: [],
    showTime: dto.showTime ? new Date(dto.showTime) : null,
  };
}
