import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventStatisticsGuards } from '../../../core/domain/CBOTenantStatistics';
import { useRequiredParams } from '../../../utils/useRequiredParams';
import EventStatisticsTemplate from '../../templates/analytics/EventStatisticsTemplate';
import { createEventStatisticsViewModel } from './EventStatistics.viewmodel';

export default function EventStatistics() {
  const dispatch = useDispatch();
  const { eventId } = useRequiredParams<{ eventId: string }>();

  const viewModel = useSelector(createEventStatisticsViewModel({ dispatch: useDispatch() }));

  const {
    currency,
    event,
    eventFetchingStatus,
    eventStatisticsFetchingStatus,
    fetchEvent,
    fetchEventStatistics,
    selectEventTenant,
    statistics,
    tenantId,
    trackUserJourneyEvent,
  } = viewModel;

  useEffect(() => {
    if (event) {
      trackUserJourneyEvent();
    }
  }, [event]);

  useEffect(() => {
    fetchEvent(eventId);
    fetchEventStatistics(eventId);
  }, [dispatch, eventId, tenantId]);

  useEffect(() => {
    if (event && tenantId !== event.tenantId) {
      selectEventTenant();
    }
  }, [event]);

  const loading = eventStatisticsFetchingStatus === 'pending' || eventFetchingStatus === 'pending';

  if (!event) {
    return null;
  }

  const hasSales = CBOEventStatisticsGuards.isCBOEventStatisticsWithSales(statistics);

  if (hasSales) {
    return (
      <EventStatisticsTemplate currency={currency} event={event} hasSales loading={loading} statistics={statistics} />
    );
  }

  return (
    <EventStatisticsTemplate
      currency={currency}
      event={event}
      hasSales={false}
      loading={loading}
      statistics={statistics}
    />
  );
}
