import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React from 'react';

import { CBOCatalogProduct } from '../../../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import ProductPrice from '../../atoms/ProductPrice';

interface ProductRowProps {
  currency: CBOCurrency;
  index: number;
  onClick: () => void;
  product: CBOCatalogProduct;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:active': {
    backgroundColor: grey['100'],
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  minHeight: 0,
  padding: theme.spacing(1),
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  flex: 1,
  padding: theme.spacing(1),
}));

const Image = styled('img')(({ theme }) => ({
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '5px',
  flex: 1,
  objectFit: 'contain',
}));

export default function ProductRow(props: ProductRowProps) {
  const { currency, onClick, product } = props;

  const productPriceVariant = product.hasVariants ? 'range' : 'simple';

  return (
    <StyledTableRow
      hover
      onClick={onClick}
      sx={{
        '&:active': {
          backgroundColor: grey['100'],
          width: '100%',
        },
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <StyledCell align="center" width="100px">
        <Box sx={{ display: 'flex', height: '100px', margin: 'auto', width: '100px' }}>
          <Image alt={product.title} src={product.imageUrls[0]} />
        </Box>
      </StyledCell>
      <StyledCell>
        <Typography fontSize="1em" fontWeight="bold">
          {product.title}
        </Typography>
      </StyledCell>
      <StyledCell align="right">
        <ProductPrice
          currency={currency}
          price={product.price}
          sx={{ fontWeight: 'bold' }}
          variant={productPriceVariant}
        />
      </StyledCell>
    </StyledTableRow>
  );
}
