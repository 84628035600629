import { User } from '@auth0/auth0-spa-js';
import { EventId, ProductId, TenantId, V1 } from '@bellepoque/api-contracts';
import { Observable, firstValueFrom, forkJoin, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import {
  CopyEventThemeParams,
  CustomiseEventThemeParams,
  EventFileUpload,
  EventFilesUpload,
  EventGateway,
  GetReplaysChaptersParams,
  ReplayFilesUpload,
  UploadedEventAssetNames,
  UploadedReplayAssetNames,
} from '../../core/gateways/event-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiEventGateway extends ApiGateway implements EventGateway {
  // TO move to a dedicated gateway
  getFirebaseCustomToken(user: User): Observable<{ customToken: string; user: User }> {
    const body = { user };
    const url = `${this.apiEndpoint}/iam/firebase-token`;
    return this.authenticatedCommand<{ customToken: string }>({
      body,
      url,
      verb: 'post',
    }).pipe(
      map((result) => {
        return {
          customToken: result.customToken,
          user,
        };
      }),
    );
  }

  addCatalogProductsToEvent(eventId: string, dto: V1.api.AddCatalogProductsToEventDTO): Promise<void> {
    const body = dto;
    const url = `${this.apiEndpoint}/events/${eventId}/catalog-products`;
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body,
        url,
        verb: 'post',
      }),
    );
  }

  createEventProduct(eventId: EventId, dto: V1.api.CreateProductDTO): Promise<V1.api.ProductDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.ProductDTO>({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/products`,
        verb: 'post',
      }),
    );
  }

  create(dto: V1.api.CreateEventDTO, tenantId: TenantId): Promise<V1.api.EventDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.EventDTO>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/events`,
        verb: 'post',
      }),
    );
  }

  copyTheme({ copiedEventId, eventId }: CopyEventThemeParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: { copiedEventId },
        url: `${this.apiEndpoint}/events/${eventId}/copy-theme`,
        verb: 'post',
      }),
    );
  }

  customiseTheme({ dto, eventId }: CustomiseEventThemeParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/theme`,
        verb: 'put',
      }),
    );
  }

  deleteEventProduct(eventId: EventId, productId: ProductId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/products/${productId}`,
        verb: 'delete',
      }),
    );
  }

  deleteOneEvent(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}`,
        verb: 'delete',
      }),
    );
  }

  displayProduct(eventId: EventId, dto: V1.api.DisplayProductDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/products/display`,
        verb: 'post',
      }),
    );
  }

  fetchOne(id: EventId): Promise<V1.api.EventDTO | null> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.EventDTO>({
        url: `${this.apiEndpoint}/events/${id}`,
      }).pipe(
        catchError((error: any) => {
          if (error.status === 404) return of(null);
          throw error;
        }),
      ),
    );
  }

  fetchOneEventChatCredentials(id: EventId): Promise<V1.api.ChatCredentialsDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.ChatCredentialsDTO>({
        url: `${this.apiEndpoint}/events/${id}/join-chat-as-moderator`,
        verb: 'post',
      }),
    );
  }

  fetchOneEventStatistics(id: EventId): Promise<V1.api.EventStatisticsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.EventStatisticsDTO>({
        url: `${this.apiEndpoint}/events/${id}/statistics`,
      }),
    );
  }

  fetchOneLiveEventStatistics(id: EventId): Promise<V1.api.LiveEventStatisticsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.LiveEventStatisticsDTO>({
        url: `${this.apiEndpoint}/events/${id}/live-statistics`,
      }),
    );
  }

  fetchOneEventViewerToken(id: EventId): Promise<V1.api.ViewerTokenDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ViewerTokenDTO>({
        url: `${this.apiEndpoint}/events/${id}/viewer-token`,
      }),
    );
  }

  getReplaysChapters(params: GetReplaysChaptersParams): Promise<V1.api.ReplaysChaptersDTO> {
    const { replaysIds, tenantId } = params;
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ReplaysChaptersDTO>({
        params: { ids: replaysIds.join(',') },
        url: `${this.apiEndpoint}/tenants/${tenantId}/replays-chapters`,
      }),
    );
  }

  getSecuredFileUploadUrls(eventId: EventId): Promise<V1.api.ThemeFileUploadUrlsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ThemeFileUploadUrlsDTO>({
        url: `${this.apiEndpoint}/events/${eventId}/file-upload-urls`,
      }),
    );
  }

  getSecuredReplayFileUploadUrls(eventId: EventId): Promise<V1.api.ReplayFileUploadUrlsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ReplayFileUploadUrlsDTO>({
        url: `${this.apiEndpoint}/events/${eventId}/replay-file-upload-urls`,
      }),
    );
  }

  closeOne(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/close`,
        verb: 'post',
      }),
    );
  }

  openOne(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/open`,
        verb: 'post',
      }),
    );
  }

  openOneReplay(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/open-replay`,
        verb: 'post',
      }),
    );
  }

  pinMessage(eventId: EventId, dto: V1.api.PinMessageDTO): Promise<V1.api.PinnedMessageDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.PinnedMessageDTO>({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/pin-message`,
        verb: 'post',
      }),
    );
  }

  publishOne(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/publish`,
        verb: 'post',
      }),
    );
  }

  publishOneReplay(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/publish-replay`,
        verb: 'post',
      }),
    );
  }

  reorderProductsLineup(eventId: EventId, dto: V1.api.ReorderProductsLineupDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/products-lineup`,
        verb: 'post',
      }),
    );
  }

  toggleLiveViewersCountDisplay(eventId: EventId, dto: V1.api.ToggleLiveViewersCountDisplayDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/toggle-live-viewers-count-display`,
        verb: 'put',
      }),
    );
  }

  unpinMessage(eventId: EventId, messageId: string): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: { messageId },
        url: `${this.apiEndpoint}/events/${eventId}/unpin-message`,
        verb: 'post',
      }),
    );
  }

  unPublishOne(eventId: EventId): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/events/${eventId}/unpublish`,
        verb: 'post',
      }),
    );
  }

  updateOne(id: EventId, dto: V1.api.UpdateEventDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${id}`,
        verb: 'patch',
      }),
    );
  }

  updateReplay(id: EventId, dto: V1.api.UpdateReplayDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: dto,
        url: `${this.apiEndpoint}/events/${id}/replay`,
        verb: 'patch',
      }),
    );
  }

  updateOneSyncedProduct(eventId: EventId, productId: string, dto: V1.api.UpdateProductOverrideDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/products/${productId}`,
        verb: 'patch',
      }),
    );
  }

  updateOneUnsyncedProduct(eventId: EventId, productId: string, dto: V1.api.UpdateProductDTO): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand<void>({
        body: dto,
        url: `${this.apiEndpoint}/events/${eventId}/products/${productId}`,
        verb: 'patch',
      }),
    );
  }

  uploadEventFiles(eventId: EventId, files: EventFilesUpload): Promise<UploadedEventAssetNames> {
    const observables = Object.keys(files).reduce((acc: Record<string, Observable<boolean>>, assetName: string) => {
      const file: EventFileUpload = files[assetName as keyof EventFilesUpload]!;

      acc[assetName] = this.uploadFile(file);

      return acc;
    }, {});

    return firstValueFrom(forkJoin(observables));
  }

  uploadReplayFiles(eventId: EventId, files: ReplayFilesUpload): Promise<UploadedReplayAssetNames> {
    const observables = Object.keys(files).reduce((acc: Record<string, Observable<boolean>>, assetName: string) => {
      const file: EventFileUpload = files[assetName as keyof ReplayFilesUpload]!;

      acc[assetName] = this.uploadFile(file);

      return acc;
    }, {});

    return firstValueFrom(forkJoin(observables));
  }

  private uploadFile(file: EventFileUpload): Observable<boolean> {
    return ajax
      .put(file.url, file.file, {
        'Content-Length': file.file.size.toString(),
        'Content-Type': file.file.type,
        'x-ms-blob-type': 'BlockBlob',
      })
      .pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status >= 200 && ajaxResponse.status < 300) {
            return true;
          }
          console.error(`error while uploading file ${file.file.name}`);
          console.error(ajaxResponse);
          return false;
        }),
        catchError((error) => {
          console.error(`error while uploading file ${file.file.name}`);
          console.error(error);
          return of(false);
        }),
      );
  }
}
