import { AppBar, Box, LinearProgress, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../../atoms/CreateButton';
import ShoppablesCounterUpgradePlanButton from '../shoppables/ShoppablesCounterUpgradePlanButton';

const MediaCollectionsHeaderRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'absolute',
  right: 'auto',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
}));

const MediaCollectionsHeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
}));

const MediaCollectionsHeaderActionsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'end',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'start',
  textAlign: 'right',
}));

export type MediaCollectionsHeaderProps = {
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  onGoToMediaCollectionEditor: () => void;
  publishedShoppablesCount: number;
  tenantName: string;
};

const MediaCollectionsHeader: FC<MediaCollectionsHeaderProps> = ({
  maxPublishedShoppablesCount,
  onGoToMediaCollectionEditor,
  loading,
  publishedShoppablesCount,
  tenantName,
}) => {
  const { t } = useTranslation('mediaCollections');
  const handleClickCreateMediaCollection = () => onGoToMediaCollectionEditor();

  return (
    <>
      <MediaCollectionsHeaderRoot elevation={0}>
        <ContentContainer>
          <MediaCollectionsHeaderTitle variant="h4">{t('MediaCollectionsManager')}</MediaCollectionsHeaderTitle>
          <MediaCollectionsHeaderActionsContainer>
            {maxPublishedShoppablesCount && (
              <ShoppablesCounterUpgradePlanButton
                maxPublishedShoppablesCount={maxPublishedShoppablesCount}
                publishedShoppablesCount={publishedShoppablesCount}
                tenantName={tenantName}
              />
            )}
            <CreateButton onClick={handleClickCreateMediaCollection} text={t('NewMediaCollection')} />
          </MediaCollectionsHeaderActionsContainer>
        </ContentContainer>
        {loading && <LinearProgress sx={{ marginY: (theme) => theme.spacing(1) }} />}
      </MediaCollectionsHeaderRoot>
    </>
  );
};

export default MediaCollectionsHeader;
