import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { createCmsLandingPage } from '../../usecases/landing-page/create-cms-landing-page';
import { fetchLandingPage } from '../../usecases/landing-page/fetch-landing-page';
import { hasCmsLandingPage } from '../../usecases/landing-page/has-cms-landing-page';
import { updateLandingPage } from '../../usecases/landing-page/update-landing-page';
import { INITIAL_LANDING_PAGE_STATE, LandingPageState } from '../state/landingPage';
import { errorStatus, loadedStatus, pendingStatus, successStatus } from '../utils';

const buildCmsReducers = (builder: ActionReducerMapBuilder<LandingPageState>) => {
  builder.addCase(createCmsLandingPage.fulfilled, (state) => ({
    ...state,
    ...successStatus('cmsLandingPageCreate'),
    hasCmsLandingPage: true,
  }));
  builder.addCase(createCmsLandingPage.pending, (state) => ({
    ...state,
    ...pendingStatus('cmsLandingPageCreate'),
  }));
  builder.addCase(createCmsLandingPage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('cmsLandingPageCreate', [action.error]),
  }));
  builder.addCase(hasCmsLandingPage.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('hasCmsLandingPageFetching'),
    hasCmsLandingPage: action.payload,
  }));
  builder.addCase(hasCmsLandingPage.pending, (state) => ({
    ...state,
    ...pendingStatus('hasCmsLandingPageFetching'),
  }));
  builder.addCase(hasCmsLandingPage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('hasCmsLandingPageFetching', [action.error]),
  }));
};

const buildLandingPageDataReducers = (builder: ActionReducerMapBuilder<LandingPageState>) => {
  builder.addCase(fetchLandingPage.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('landingPageDataFetching'),
    landingPageData: action.payload,
    landingPageTenantId: action.meta.arg.tenantId,
  }));
  builder.addCase(fetchLandingPage.pending, (state) => ({
    ...state,
    ...pendingStatus('landingPageDataFetching'),
  }));
  builder.addCase(fetchLandingPage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('landingPageDataFetching', [action.error]),
  }));
  builder.addCase(updateLandingPage.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('landingPageDataUpdate'),
    landingPageData: action.payload,
  }));
  builder.addCase(updateLandingPage.pending, (state) => ({
    ...state,
    ...pendingStatus('landingPageDataUpdate'),
  }));
  builder.addCase(updateLandingPage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('landingPageDataUpdate', [action.error]),
  }));
};

export const landingPageSlice = createSlice({
  extraReducers: (builder) => {
    buildCmsReducers(builder);
    buildLandingPageDataReducers(builder);
  },
  initialState: INITIAL_LANDING_PAGE_STATE,
  name: 'landing-page',
  reducers: {},
});
