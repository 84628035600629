import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, FormHelperText, Grid, IconButton, Paper, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { isSizeLowerOrEquals } from '../../../../utils/is-size-lower-or-equals';

const ImageSizeLabel = styled('label')(({ theme }) => ({
  bottom: `-${theme.spacing(2)}`,
  color: 'grey',
  fontSize: '.7em',
  left: 0,
  position: 'absolute',
  textAlign: 'initial',
}));

const Thumbnail = styled('img')({
  maxWidth: '100%',
  objectFit: 'contain',
});

const ImageContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}));

type ImageSelectorReactHookFormProps = Pick<UseFormReturn<any>, 'register' | 'watch'>;

interface ImageSelectorProps extends ImageSelectorReactHookFormProps {
  alignActions?: 'center' | 'flex-end' | 'flex-start';
  altBackground?: boolean;
  defaultUrl: string;
  disabled: boolean;
  errorText?: string;
  fieldName: string;
  height?: number;
  label: string;
  maxSizeInKb: number;
  onChange?: (fileList: FileList) => void;
  onReset: () => void;
  onUpdateThumbnail?: (url: string) => void;
  readonly: boolean;
}

export interface ImageSelectorHandles {
  reset: () => void;
}

export default function ReactHookFormImageSelector(props: ImageSelectorProps) {
  const {
    altBackground = false,
    alignActions = 'center',
    onChange,
    defaultUrl,
    disabled,
    errorText,
    fieldName,
    label,
    height = 70,
    maxSizeInKb,
    onReset,
    onUpdateThumbnail,
    readonly,
    register,
    watch,
  } = props;

  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();
  const [thumbnailPicturesUrl, setThumbnailPictureUrl] = useState(defaultUrl);
  const [loading, setLoading] = useState(true);

  const newImageFiles = watch(fieldName, []);

  useEffect(() => {
    if (onUpdateThumbnail) onUpdateThumbnail(thumbnailPicturesUrl);
  }, [thumbnailPicturesUrl]);

  useEffect(() => {
    if (newImageFiles) {
      const newImageFile = newImageFiles[0];
      if (newImageFile) {
        setThumbnailPictureUrl(URL.createObjectURL(newImageFile));
      }
    }
    onChange?.(newImageFiles);
  }, [newImageFiles]);

  const reset = useCallback(() => {
    setThumbnailPictureUrl(defaultUrl);
    onReset();
  }, [defaultUrl]);

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: altBackground ? theme.palette.grey[50] : theme.palette.common.white,
        border: errorText ? 'solid 1px red' : 'none',
        flexGrow: 1,
        width: '100%',
      }}
      variant="elevation"
    >
      <ImageContainer container>
        <Grid item xs={3}>
          {loading && <CircularProgress size="50px" />}
          <div style={{ opacity: loading ? 0 : 1, transition: 'opacity 1s ease-in-out' }}>
            <Thumbnail
              alt={label}
              height={height}
              onLoad={() => setLoading(false)}
              src={thumbnailPicturesUrl}
              sx={{ display: loading ? 'none' : 'block' }}
            />
          </div>
        </Grid>
        <Grid alignSelf={alignActions} item>
          {!readonly && (
            <>
              <Grid container direction="column" position="relative">
                <Grid alignItems="center" gap={1} item>
                  <label htmlFor={`${fieldName}-file-input`}>
                    <Button
                      color="primary"
                      component="span"
                      disabled={disabled}
                      size="small"
                      sx={{
                        px: '20px',
                      }}
                      variant="contained"
                    >
                      {t('Select')}
                    </Button>
                  </label>
                  <IconButton
                    aria-label="delete"
                    disabled={disabled || !newImageFiles || newImageFiles.length === 0}
                    onClick={reset}
                    size="large"
                    sx={{ color: 'black', padding: '5px' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <ImageSizeLabel>{`${t('common:Max')} ${maxSizeInKb} ${t('common:Kb')}`}</ImageSizeLabel>
              </Grid>
              <input
                {...register(fieldName, {
                  validate: (files: FileList) => {
                    const sizeValid = isSizeLowerOrEquals(maxSizeInKb * 1024)(files[0]);
                    const errorMessage = `${t('FileSizeMustBeLowerThan')} ${maxSizeInKb} ${t('common:Kb')}`;
                    return sizeValid || errorMessage;
                  },
                })}
                accept="image/*"
                disabled={disabled}
                hidden
                id={`${fieldName}-file-input`}
                type="file"
              />
              <FormHelperText
                error
                id={`${fieldName}-component-error-text`}
                sx={{ marginTop: '20px' }}
                variant="outlined"
              >
                {errorText}
              </FormHelperText>
            </>
          )}
        </Grid>
      </ImageContainer>
    </Paper>
  );
}
