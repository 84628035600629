import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';

export interface CurrencySelectorProps {
  currencies: CBOCurrency[];
  currentCurrency: CBOCurrency;
  disabled?: boolean;
  hideLabel?: boolean;
  onChange: (id: string) => void;
  required?: boolean;
  size?: 'medium' | 'small';
  variant?: 'filled' | 'outlined';
}

export default function CurrencySelector({
  currencies,
  currentCurrency,
  disabled,
  hideLabel,
  size = 'medium',
  required = false,
  onChange,
  variant = 'filled',
}: CurrencySelectorProps) {
  const [value, setValue] = React.useState<CBOCurrency>({ code: '', decimalDigits: 0, id: '', symbol: '' });
  const { t } = useTranslation('common');
  useEffect(() => {
    setValue(currentCurrency);
  }, [currentCurrency]);

  const sortedCurrencies = [...currencies].sort((a, b) => a.code.localeCompare(b.code));

  return (
    <Autocomplete
      autoHighlight
      blurOnSelect
      disableClearable={true}
      disabled={disabled}
      fullWidth
      getOptionLabel={(option: CBOCurrency) => `${option.code} (${option.symbol})`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') onChange(newValue);
        if (newValue) onChange(newValue.id);
      }}
      options={sortedCurrencies}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
          label={hideLabel ? undefined : t('Currency')}
          required={required}
          variant={variant}
        />
      )}
      size={size}
      value={value}
    />
  );
}
