import { CBOConnectedYoutubeUser } from '../../domain/CBOYoutubeChannel';
import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export type YoutubeCommandsQueriesState = {
  channelsFetching: QueryResult;
  hasYoutubeLiveStreamEnabledChecking: QueryResult;
  loginUrlFetching: QueryResult;
};

export type YoutubeState = YoutubeCommandsQueriesState & {
  connectedUser: CBOConnectedYoutubeUser | null;
  hasLiveStreamEnabled: boolean;
  loginUrl: string | null;
};

export const INITIAL_YOUTUBE_STATE: YoutubeState = {
  channelsFetching: INITIAL_QUERY_RESULT,
  connectedUser: null,
  hasLiveStreamEnabled: false,
  hasYoutubeLiveStreamEnabledChecking: INITIAL_QUERY_RESULT,
  loginUrl: null,
  loginUrlFetching: INITIAL_QUERY_RESULT,
};
