import { ContentCopy, Settings, Star } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography, styled } from '@mui/material';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventDepiction } from '../../../../core/domain/CBOEventDepiction';

type UpcomingLiveProps = {
  event: CBOEventDepiction | null;
  hasShopify: boolean;
  loading: boolean;
  onCopyEventLink: (eventId: string) => void;
  onCreateEvent: () => void;
  onGoToEventSettings: (eventId: string) => void;
};

const ActionContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: 1 / 3,
  marginTop: theme.spacing(2),
  maxHeight: '70px',
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  textAlign: 'initial',
}));

const ContentContainer = styled(Box)({
  display: 'flex',
  flex: 2 / 3,
  flexDirection: 'column',
});

// TODO: Move typography style to theme and pass them to skeletons
const descriptionFontSize = '.9em';
const Description = styled(Typography)(({ theme }) => ({
  fontSize: descriptionFontSize,
  marginTop: theme.spacing(0.5),
}));

const subtitleFontSize = '.9em';
const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: subtitleFontSize,
  fontWeight: 'bold',
}));

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

type Content = {
  action: React.ReactNode;
  description: string | null;
  subtitle: string;
  title: string;
};

export default function UpcomingLive({
  event,
  hasShopify,
  loading,
  onCopyEventLink,
  onCreateEvent,
  onGoToEventSettings,
}: UpcomingLiveProps) {
  const { t } = useTranslation('home');
  const [hasCopiedEventLink, setHasCopiedEventLink] = useState<boolean>(false);

  useEffect(() => {
    if (hasCopiedEventLink) {
      setTimeout(() => {
        setHasCopiedEventLink(false);
      }, 1500);
    }
  }, [hasCopiedEventLink]);

  const handleCopyEventLink = (eventId: string) => {
    setHasCopiedEventLink(true);
    onCopyEventLink(eventId);
  };

  const renderSkeleton = () => (
    <>
      <ContentContainer>
        <Title>{t('UpcomingLive')}</Title>
        <Skeleton sx={{ fontSize: subtitleFontSize }} variant="text" />
        <Skeleton sx={{ fontSize: descriptionFontSize }} variant="text" />
      </ContentContainer>
      <ActionContainer>
        <Skeleton height="40px" sx={{ borderRadius: '10px' }} variant="rectangular" width="175px" />
      </ActionContainer>
    </>
  );

  const renderContent = () => {
    const { action, description, subtitle, title } = getContent();

    return (
      <>
        <ContentContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {!!description && <Description>{description}</Description>}
        </ContentContainer>
        <ActionContainer>{action}</ActionContainer>
      </>
    );
  };

  const getContent = (): Content => {
    if (!event || !event.showTime) {
      return {
        action: (
          <Button
            color="primary"
            fullWidth
            onClick={onCreateEvent}
            startIcon={<Star />}
            sx={{ height: '100%' }}
            variant="contained"
          >
            {t('CreateLiveAction')}
          </Button>
        ),
        description: t('NoPlannedEvent'),
        subtitle: t('NoPlannedEvent'),
        title: t('UpcomingLive'),
      };
    }

    const dateValue =
      event.showTime.toLocaleDateString(i18next.language, {
        day: 'numeric',
        month: 'long',
        weekday: 'short',
        year: 'numeric',
      }) ?? t('Unplanned');
    const timeValue =
      event.showTime?.toLocaleTimeString(i18next.language, { hour: '2-digit', minute: '2-digit' }) ?? '-';

    const description = `${dateValue} - ${timeValue}`;

    if (hasShopify) {
      return {
        action: (
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleCopyEventLink(event.id);
            }}
            startIcon={<ContentCopy />}
            sx={{ height: '100%' }}
            variant="contained"
          >
            {hasCopiedEventLink ? t('common:LinkCopied') : t('CopyLinkToEvent')}
          </Button>
        ),
        description,
        subtitle: event.title,
        title: t('UpcomingLive'),
      };
    }

    return {
      action: (
        <Button
          color="primary"
          fullWidth
          onClick={() => onGoToEventSettings(event.id)}
          startIcon={<Settings />}
          sx={{ height: '100%' }}
          variant="contained"
        >
          {t('EventSettings')}
        </Button>
      ),
      description,
      subtitle: event.title,
      title: t('UpcomingLive'),
    };
  };

  return <Container boxShadow={3}>{loading ? renderSkeleton() : renderContent()}</Container>;
}
