import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUploadShoppableVideoFile as resetUploadShoppableVideoFileAction } from '../../store/slices/shoppables/shoppables-file-upload.slice';

type UploadShoppableVideoFileParams = {
  file: File;
  fileUploadUrl: string;
  shoppableId: string;
  tenantId: string;
};

export const uploadShoppableVideoFile = createAppAsyncThunk(
  'shoppables/upload-file',
  async function uploadShoppableVideoFile(
    params: UploadShoppableVideoFileParams,
    { extra: { shoppablesGateway, tracingGateway } },
  ): Promise<string> {
    const { file, fileUploadUrl, shoppableId, tenantId } = params;
    const fileUploadUrlWithoutQueryParams = fileUploadUrl.split('?')[0];

    try {
      await shoppablesGateway.uploadFile(file, fileUploadUrl);
    } catch (e: any) {
      tracingGateway.captureException(e);
      console.error('Error while uploading file', e);
      throw e;
    }

    try {
      await shoppablesGateway.notifyFileUploaded({
        fileUrl: fileUploadUrlWithoutQueryParams,
        shoppableId,
        tenantId,
      });
    } catch (e: any) {
      tracingGateway.captureException(e);
      console.error('Error while notifying file uploaded', e);
      throw e;
    }

    return fileUploadUrlWithoutQueryParams;
  },
);

export const resetUploadShoppableVideoFile = () => {
  return resetUploadShoppableVideoFileAction();
};
