import { EventId } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../core/store';
import { fetchOneEvent } from '../../../core/usecases/event/crud/fetch-event';
import { selectTenant } from '../../../core/usecases/tenants/select-tenant';

export const createReplayDetailsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const {
      currentEvent,
      eventClosing: { status: replayClosingStatus },
    } = state.events;
    const { currentTenantId } = state.tenants;
    const { currency } = state.tenants.currentTenant;

    return {
      currency,
      currentTenantId,
      fetchReplay: (eventId: EventId) => {
        dispatch(fetchOneEvent({ eventId, tenantId: currentTenantId }));
      },
      replay: currentEvent,
      replayClosingStatus,
      selectTenant: () => {
        if (currentEvent) dispatch(selectTenant({ tenantId: currentEvent.tenantId }));
      },
    };
  };
