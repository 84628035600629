import { BILLING_PLANS, Cms } from '@bellepoque/api-contracts';

import { CBOTenantKlaviyoIntegration } from '../../../../../core/domain/CBOTenant';
import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { fetchKlaviyoLists } from '../../../../../core/usecases/klaviyo/fetch-klaviyo-lists';
import { updateIntegrations } from '../../../../../core/usecases/tenants/update-integrations';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createKlaviyoIntegrationViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentTenant = state.tenants.currentTenant;
    const authorizedTenantIds = ['a179bd4c-4138-410d-9357-4bebca68903f', 'a91e4dff-3baa-4547-9f70-12392bddc448'];

    const isEnabled =
      authorizedTenantIds.includes(currentTenant.id) ||
      currentTenant.cms !== Cms.Shopify ||
      BILLING_PLANS[currentTenant.activeSubscriptionId].integrationsEnabled.klaviyo;

    return {
      currentTenant,
      fetchLists: (apiKey: string) => {
        dispatch(fetchKlaviyoLists({ apiKey, tenantId: currentTenant.id }));
      },
      fetchListsStatus: state.klaviyo.klaviyoListsFetching.status,
      integrations: currentTenant.integrations,
      isEnabled,
      lists: state.klaviyo.lists,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateIntegration: (klaviyo: CBOTenantKlaviyoIntegration) => {
        dispatch(
          updateIntegrations({
            integrations: { ...currentTenant.integrations, klaviyo },
            tenantId: currentTenant.id,
          }),
        );
      },
      updateStatus: state.tenants.updateIntegrations.status,
    };
  };
