import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CoversIcon from '../../../../../icons/CoversIcon';
import PreviewCoverPosition from '../../../atoms/event/themePreview/PreviewCoverPosition';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import ReactHookFormImageSelector from '../../../atoms/inputs/ReactHookFormImageSelector';
import { ThemeInputs } from '../../../organisms/event/event-details/ThemeForm';
import SettingsSection from '../../SettingsSection';

interface CoversSettingsSectionProps {
  defaultPostEventCoverUrl: string;
  defaultPreEventCoverUrl: string;
  errors: any;
  onResetPostEventCover: () => void;
  onResetPreEventCover: () => void;
  readonly: boolean;
  register: UseFormRegister<ThemeInputs>;
  submissionInProgress: boolean;
  watch: UseFormWatch<ThemeInputs>;
}

const ImageSelectorContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
});

const ImageSelectorLabel = styled(Typography)({
  display: 'block',
  fontSize: '.8em',
  fontWeight: 'bold',
  textAlign: 'initial',
  width: '100px',
});

export default function CoversSettingsSection(props: CoversSettingsSectionProps) {
  const {
    defaultPostEventCoverUrl,
    defaultPreEventCoverUrl,
    errors,
    onResetPostEventCover,
    onResetPreEventCover,
    readonly,
    register,
    submissionInProgress,
    watch,
  } = props;
  const { t } = useTranslation(['events', 'common']);

  const disabled = readonly || submissionInProgress;
  const theme = useTheme();
  return (
    <SettingsSection
      contentWidth={11}
      icon={<CoversIcon sx={{ color: theme.palette.common.white }} />}
      roundBottom
      title={t('Covers')}
    >
      <Grid container direction={'row'} gap={3} justifyContent="center">
        <Grid item position="relative" xs={12}>
          <ImageSelectorContainer>
            <ImageSelectorLabel>{t('PreEventCover')}</ImageSelectorLabel>
            <PreviewCoverPosition sx={{ height: '80px', width: 'auto' }} />
            <ReactHookFormImageSelector
              alignActions="flex-end"
              altBackground
              defaultUrl={defaultPreEventCoverUrl}
              disabled={disabled}
              errorText={errors?.preEventCover?.message}
              fieldName="preEventCover"
              height={200}
              key={defaultPreEventCoverUrl}
              label={t('PreEventCover')}
              maxSizeInKb={300}
              onReset={onResetPreEventCover}
              readonly={readonly}
              register={register}
              watch={watch}
            />
          </ImageSelectorContainer>
          <InputTooltip content={t('helpers.PreEventCover')} />
        </Grid>
        <Grid item position="relative" xs={12}>
          <ImageSelectorContainer>
            <ImageSelectorLabel>{t('PostEventCover')}</ImageSelectorLabel>
            <PreviewCoverPosition sx={{ height: '80px', width: 'auto' }} />
            <ReactHookFormImageSelector
              alignActions="flex-end"
              altBackground
              defaultUrl={defaultPostEventCoverUrl}
              disabled={disabled}
              errorText={errors?.postEventCover?.message}
              fieldName="postEventCover"
              height={200}
              key={defaultPostEventCoverUrl}
              label={t('PostEventCover')}
              maxSizeInKb={300}
              onReset={onResetPostEventCover}
              readonly={readonly}
              register={register}
              watch={watch}
            />
          </ImageSelectorContainer>
          <InputTooltip content={t('helpers.PostEventCover')} />
        </Grid>
      </Grid>
    </SettingsSection>
  );
}
