import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isFinishedOrLater } from '../../../utils/is-replay';
import { useRequiredParams } from '../../../utils/useRequiredParams';
import { routes } from '../../routes';
import LiveEventDetailsTemplate from '../../templates/LiveEventDetailsTemplate';
import { createLiveEventDetailsViewModel } from './LiveEventDetails.viewmodel';

const LiveEventDetails = () => {
  const viewModel = useSelector(createLiveEventDetailsViewModel({ dispatch: useDispatch() }));

  const { currentTenant, currentTenantId, fetchLiveEvent, liveEvent, liveEventClosingStatus, selectTenant } = viewModel;

  const navigate = useNavigate();
  const { eventId: eventIdParam, productId: productIdParam } =
    useRequiredParams<{ eventId: string; productId?: string }>();

  const handleGoToLiveEventTab = (path: string) => {
    navigate(path);
  };

  const handleGoToNewProductCreationPage = () => {
    navigate(routes.events.newProduct({ eventId: eventIdParam }));
  };

  useEffect(() => {
    // If we change the tenant, return to the live events page
    if (currentTenantId && liveEvent?.id === eventIdParam && liveEvent.tenantId !== currentTenantId)
      navigate(routes.events.root);
  }, [currentTenantId]);

  useEffect(() => {
    if (currentTenantId !== '') fetchLiveEvent(eventIdParam);
  }, [currentTenantId, eventIdParam]);

  useEffect(() => {
    // If we land on a new event which doesn't have the correct tenant, change the tenant
    if (liveEvent?.id === eventIdParam && liveEvent.tenantId !== currentTenantId) selectTenant();
  }, [liveEvent?.id]);

  useEffect(() => {
    if (liveEvent?.id === eventIdParam && isFinishedOrLater(liveEvent.status)) {
      navigate(routes.replays.details({ eventId: liveEvent.id }));
    }
  }, [liveEvent?.id, liveEvent?.status]);

  return (
    <LiveEventDetailsTemplate
      currentTenant={currentTenant}
      eventIdParam={eventIdParam}
      liveEvent={liveEvent}
      liveEventClosingStatus={liveEventClosingStatus}
      onGoToLiveEventTab={handleGoToLiveEventTab}
      onGoToNewProductCreationPage={handleGoToNewProductCreationPage}
      productIdParam={productIdParam}
    />
  );
};

export default LiveEventDetails;
