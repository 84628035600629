import { CBOConnectedFacebookUser } from '../../domain/CBOFacebookAccount';
import { CommandResult } from './utils';

export type FacebookCommandsQueriesState = {
  facebookConnecting: CommandResult;
  facebookInitializing: CommandResult;
};

export type FacebookState = FacebookCommandsQueriesState & {
  connectedUser: CBOConnectedFacebookUser | null;
};

export const INITIAL_FACEBOOK_STATE: FacebookState = {
  connectedUser: null,
  facebookConnecting: {
    errors: [],
    status: 'notRequested',
  },
  facebookInitializing: {
    errors: [],
    status: 'notRequested',
  },
};
