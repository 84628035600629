import { Box, Paper, styled } from '@mui/material';
import { addHours } from 'date-fns';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CreateEventRequest } from '../../../../core/usecases/event/crud/create-event';
import OnboardingTitle from '../../../components/atoms/onboarding/OnboardingTitle';
import CreateEventForm from '../../../components/organisms/event/CreateEventForm';
import { createOnboardingCreateEventViewModel } from './OnboardingCreateEvent.viewmodel';

type OnboardingCreateEventProps = {
  onIsValid?: (isValid: boolean) => void;
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export type OnboardingCreateEventRef = {
  submitForm: () => void;
};

const OnboardingCreateEvent = forwardRef<OnboardingCreateEventRef, OnboardingCreateEventProps>(({ onIsValid }, ref) => {
  const { t } = useTranslation('events');

  const viewModel = useSelector(createOnboardingCreateEventViewModel({ dispatch: useDispatch() }));

  const { createEvent, event, tenantId, tenantName, trackUserJourneyEvent, updateEvent } = viewModel;

  const defaultValues = {
    description: t('onboarding:Steps.AddPhoneNumber.Form.Description'),
    publisherPhone: '',
    showTime: addHours(new Date(), 1).toISOString(),
    title: t('onboarding:Steps.AddPhoneNumber.Form.Title'),
  };

  const form = useForm<CreateEventRequest>({ defaultValues, mode: 'onChange' });

  const highlightPhoneNumberInput = !form.formState.isDirty;

  useEffect(() => {
    trackUserJourneyEvent({
      data: {
        'Event id': event?.id ?? 'No event id',
        'Tenant id': tenantId,
        'Tenant name': tenantName,
      },
      name: 'Onboarding - Create event',
    });
  }, []);

  useEffect(() => {
    if (event) {
      form.reset({
        description: event.description,
        publisherPhone: event.publisherPhone,
        showTime: event.showTime?.toISOString() || null,
        title: event.title,
      });
    }
  }, [event]);

  useEffect(() => {
    onIsValid?.(form.formState.isValid);
  }, [onIsValid, form.formState]);

  useImperativeHandle(ref, () => ({
    submitForm,
  }));

  const submitForm = () => {
    const payload: CreateEventRequest = form.getValues();

    if (event) {
      updateEvent({
        ...event,
        facebookRestreamingConfiguration: event.facebookRestreamingConfiguration
          ? {
              ...event.facebookRestreamingConfiguration,
              expiresAt: event.facebookRestreamingConfiguration.expiresAt.toISOString(),
            }
          : null,
        restreamingConfigurations: event.restreamingConfigurations.map((restreamingConfiguration) => {
          if (restreamingConfiguration.enabled) {
            return {
              ...restreamingConfiguration,
              expiresAt: restreamingConfiguration.expiresAt?.toISOString() ?? null,
            };
          }
          return restreamingConfiguration;
        }),
        youtubeRestreamingConfiguration: event.youtubeRestreamingConfiguration
          ? {
              ...event.youtubeRestreamingConfiguration,
              expiresAt: event.youtubeRestreamingConfiguration.expiresAt.toISOString(),
            }
          : null,
        ...payload,
      });
    } else {
      createEvent(payload);
    }
  };

  return (
    <Container>
      <Paper elevation={1} style={{ borderRadius: 21 }}>
        <OnboardingTitle>{t('NewEvent')}</OnboardingTitle>
        <CreateEventForm autoFocusPhoneNumberInput form={form} highlightPhoneNumberInput={highlightPhoneNumberInput} />
      </Paper>
    </Container>
  );
});

OnboardingCreateEvent.displayName = 'OnboardingCreateEvent';

export default OnboardingCreateEvent;
