import { TenantId } from '@bellepoque/api-contracts';

import { setLocalStorageItem } from '../../../utils/local-storage';
import { selectTenant as selectTenantAction } from '../../store/slices/tenants.slice';

export type SelectTenantRequest = {
  tenantId: TenantId;
};

export const selectTenant = (request: SelectTenantRequest) => {
  setLocalStorageItem('tenantId', request.tenantId);
  return selectTenantAction(request.tenantId);
};
