import { TaskAlt } from '@mui/icons-material';
import { Avatar, Box, Typography, styled } from '@mui/material';
import React from 'react';

import { CBOFacebookPage, CBOFacebookUser } from '../../../../core/domain/CBOFacebookAccount';

const Container = styled(Box, { shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'selectable' })<{
  selectable: boolean;
  selected: boolean;
}>(({ theme, selectable, selected }) => ({
  alignItems: 'center',
  border: `solid 1px ${theme.palette.grey[300]}`,
  borderRadius: '10px',
  cursor: selectable ? 'pointer' : 'initial',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  opacity: selected ? 1 : 0.5,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const IconContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifySelf: 'flex-end',
  width: theme.spacing(3),
}));

interface FacebookAccountCardProps {
  account: CBOFacebookPage | CBOFacebookUser;
  onClick: () => void;
  selectable?: boolean;
  selected: boolean;
}

export default function FacebookAccountCard({
  account,
  onClick,
  selectable = false,
  selected,
}: FacebookAccountCardProps) {
  return (
    <Container boxShadow={1} onClick={onClick} selectable={selectable} selected={selected}>
      <Box mr={1}>
        <Avatar alt={account.name} src={account.picture} />
      </Box>
      <Box>
        <Typography fontSize=".9em" fontWeight="bold">
          {account.name}
        </Typography>
      </Box>
      <IconContainer>{selected && <TaskAlt sx={{ color: (theme) => theme.palette.socials.facebook }} />}</IconContainer>
    </Container>
  );
}
