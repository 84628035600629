import { V1 } from '@bellepoque/api-contracts';
import { v4 as uuid } from 'uuid';

import { createEventListReadModelFromCreateDTO } from '../../../../gateways/event/event-mappers';
import { CBOEventListReadModel } from '../../../domain/CBOEventListReadModel';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';
import { resetCreateEvent as resetCreateEventAction } from '../../../store/slices/event/event-crud.slice';

export type CreateEventRequest = Omit<V1.api.CreateEventDTO, 'id'>;

type CreateEventParams = {
  payload: CreateEventRequest;
  tenantId: string;
};

export const createEvent = createAppAsyncThunk(
  'events/create-event',
  async ({ payload, tenantId }: CreateEventParams, { extra: { eventGateway } }): Promise<CBOEventListReadModel> => {
    const createDTO: V1.api.CreateEventDTO = {
      ...payload,
      id: uuid(),
    };

    await eventGateway.create(createDTO, tenantId);

    return createEventListReadModelFromCreateDTO(createDTO);
  },
);

export const resetCreateEvent = () => {
  return resetCreateEventAction();
};
