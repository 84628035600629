import { V1 } from '@bellepoque/api-contracts';
import { Search } from '@mui/icons-material';
import { Box, CircularProgress, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOTenant } from '../../../../../core/domain/CBOTenant';
import { QueryStatus } from '../../../../../core/store/state/utils';
import SettingsSection from '../../../molecules/SettingsSection';
import EventFinderForm from '../../../molecules/administration/EventFinderForm';
import EventFinderResult from '../../../molecules/administration/EventFinderResult';

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  width: '100%',
}));

type EventFinderSectionProps = {
  findEventStatus: QueryStatus;
  foundEvent: V1.api.EventDTO | null;
  onSubmit: (eventId: string) => void;
  tenants: CBOTenant[];
};

export default function EventFinderSection({
  findEventStatus,
  foundEvent,
  onSubmit,
  tenants,
}: EventFinderSectionProps) {
  const { t } = useTranslation('administration');
  const [foundEventTenantName, setFoundEventTenantName] = useState<string | null>(null);

  useEffect(() => {
    const tenant = tenants.find(({ id }) => foundEvent?.tenantId === id);
    setFoundEventTenantName(tenant?.alias ?? null);
  }, [foundEvent, tenants]);

  return (
    <SettingsSection
      icon={<Search sx={{ color: (theme) => theme.palette.common.white }} />}
      roundTop
      title={t('EventFinder')}
    >
      <SectionContainer>
        <FormContainer>
          <EventFinderForm loading={findEventStatus === 'pending'} onSubmit={onSubmit} />
        </FormContainer>
        <Box>
          {findEventStatus === 'pending' && <CircularProgress />}
          {findEventStatus === 'loaded' && (
            <EventFinderResult foundEvent={foundEvent} tenantName={foundEventTenantName} />
          )}
        </Box>
      </SectionContainer>
    </SettingsSection>
  );
}
