import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, LinearProgress, Paper, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommandStatus } from '../../../core/store/state/utils';

const PREFIX = 'ConfirmDeleteDialog';

const classes = {
  button: `${PREFIX}-button`,
  buttons: `${PREFIX}-buttons`,
  header: `${PREFIX}-header`,
  layout: `${PREFIX}-layout`,
  linearProgress: `${PREFIX}-linearProgress`,
  paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.layout}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
    [theme.breakpoints.up(600 + +theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 600,
    },
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + +theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
  },

  [`& .${classes.linearProgress}`]: {
    marginTop: '-4px',
  },
}));

export interface ConfirmDeleteDialogProps {
  deleteCount?: number;
  deleteFunction: () => void;
  deleteStatus: CommandStatus;
  onClose: () => void;
  open: boolean;
}

export default function ConfirmDeleteDialog(props: ConfirmDeleteDialogProps) {
  const { t } = useTranslation(['common']);

  const { onClose, deleteFunction, open, deleteStatus, deleteCount = 1 } = props;

  const renderProgress = () => {
    if (props.deleteStatus === 'pending') {
      return <LinearProgress className={classes.linearProgress} />;
    }
  };

  useEffect(() => {
    if (deleteStatus === 'success') {
      onClose();
    }
  }, [deleteStatus, onClose]);

  return (
    <StyledDialog className={classes.layout} onClose={onClose} open={open}>
      <Paper className={classes.paper}>
        <Typography align="center" className={classes.header} component="h1" variant="h4">
          {t('common:ConfirmDelete')}
        </Typography>
        <Typography component="p">{t('common:ConfirmDeleteText', { count: deleteCount })}</Typography>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            color="primary"
            disabled={deleteStatus === 'pending'}
            onClick={onClose}
            startIcon={<ClearIcon />}
            variant="contained"
          >
            <span>{t('common:Cancel')}</span>
          </Button>
          {
            <Button
              className={classes.button}
              color="warning"
              disabled={props.deleteStatus === 'pending'}
              onClick={deleteFunction}
              startIcon={<DeleteForeverIcon />}
              type="submit"
              variant="contained"
            >
              <span>{t('common:Delete')}</span>
            </Button>
          }
        </div>
      </Paper>
      {renderProgress()}
    </StyledDialog>
  );
}
