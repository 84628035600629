import { TenantId } from '@bellepoque/api-contracts';

import { CBOLandingPageData } from '../../domain/CBOLandingPageData';
import { CommandResult, INITIAL_COMMAND_RESULT, INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface LandingPageState extends LandingPageCommandsQueriesState {
  hasCmsLandingPage: boolean;
  landingPageData: CBOLandingPageData;
  landingPageTenantId: TenantId;
}

export interface LandingPageCommandsQueriesState {
  cmsLandingPageCreate: CommandResult;
  hasCmsLandingPageFetching: QueryResult;
  landingPageDataFetching: QueryResult;
  landingPageDataUpdate: CommandResult;
}

export const INITIAL_LANDING_PAGE_STATE: LandingPageState = {
  cmsLandingPageCreate: INITIAL_COMMAND_RESULT,
  hasCmsLandingPage: false,
  hasCmsLandingPageFetching: INITIAL_QUERY_RESULT,
  landingPageData: {
    highlightedEventsIds: [],
    landingPageUrl: '',
    publishedReplayEventsIds: [],
    replaySectionTitle: '',
  },
  landingPageDataFetching: INITIAL_QUERY_RESULT,
  landingPageDataUpdate: INITIAL_COMMAND_RESULT,
  landingPageTenantId: '',
};
