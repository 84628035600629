type CBOMediaCollectionPublishedPageCollection = { id: string; title: string; type: 'collection' };
export type CBOMediaCollectionPublishedPageProduct = {
  id: string;
  image: string | null;
  title: string;
  type: 'product';
};
type CBOMediaCollectionPublishedPageHome = { id: string; type: 'home' };

export type CBOMediaCollectionPublishedPage =
  | CBOMediaCollectionPublishedPageCollection
  | CBOMediaCollectionPublishedPageHome
  | CBOMediaCollectionPublishedPageProduct;
export type CBOMediaCollectionPublishedPageList = CBOMediaCollectionPublishedPage[];

export const isCBOMediaCollectionPublishedPageProduct = (
  page: CBOMediaCollectionPublishedPage,
): page is CBOMediaCollectionPublishedPageProduct => page.type === 'product';
