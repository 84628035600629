import { Box, Button, Typography, alpha, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOFacebookPage, CBOFacebookUser } from '../../../../../../core/domain/CBOFacebookAccount';
import FacebookAccountCard from '../../../../atoms/event/FacebookAccountCard';

interface SelectedFacebookAccountProps {
  account: CBOFacebookPage | CBOFacebookUser;
  areButtonsDisabled: boolean;
  expiresAt: Date;
  onRemove: () => void;
}

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const RemoveAccountButton = styled(Button)(({ theme }) => ({
  borderColor: alpha(theme.palette.socials.facebook, 0.8),
  color: theme.palette.socials.facebook,
  marginTop: theme.spacing(1),
  textTransform: 'initial',
}));

const TokenNotExpiredText = styled(Typography)(({ theme }) => ({
  fontSize: '.8em',
  marginTop: theme.spacing(1),
}));

const TokenExpiredText = styled(TokenNotExpiredText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function SelectedFacebookAccount({
  account,
  areButtonsDisabled,
  expiresAt,
  onRemove,
}: SelectedFacebookAccountProps) {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('events', { keyPrefix: 'FacebookRestreaming' });
  const now = new Date();
  return (
    <Container>
      <Typography fontSize=".8em" mb={2}>
        {t('RestreamingConfiguredForThisAccount')}
      </Typography>
      <FacebookAccountCard account={account} onClick={() => null} selected />
      {now < expiresAt ? (
        <TokenNotExpiredText>{t('TokenExpiresOn', { date: expiresAt.toLocaleDateString() })}</TokenNotExpiredText>
      ) : (
        <TokenExpiredText>{t('ExpiredTokenPleaseReconnect')}</TokenExpiredText>
      )}
      <RemoveAccountButton disabled={areButtonsDisabled} onClick={onRemove} variant="outlined">
        {tCommon('Remove')}
      </RemoveAccountButton>
    </Container>
  );
}
