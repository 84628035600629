import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { CBOEventListReadModel } from '../../../../../core/domain/CBOEventListReadModel';
import { CBOMediaCollection } from '../../../../../core/domain/CBOMediaCollection';
import {
  CBOMediaCollectionMedia,
  shoppableVideoListItemReadModelToMediaCollectionMedia,
} from '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOShoppableVideoListReadModel } from '../../../../../core/domain/CBOShoppableVideoReadModel';
import { CommandStatus } from '../../../../../core/store/state/utils';
import { CreateMediaCollectionRequest } from '../../../../../core/usecases/media-collections/create-media-collection';
import { UpdateMediaCollectionSettingsRequest } from '../../../../../core/usecases/media-collections/update-media-collection-settings';
import { countPublishedShoppablesFromMediaCollections } from '../../../../../utils/count-published-shoppables-from-media-collections';
import { useRequiredParams } from '../../../../../utils/useRequiredParams';
import { MediaCollectionsEditorFormType } from '../../../../pages/media-collections/media-collections-editor/MediaCollectionsEditor';
import MediaCollectionForm from './MediaCollectionForm';

export type MediaCollectionSettingsFormInputs = {
  id: string;
  medias: CBOMediaCollectionMedia[];
  tenantId: string;
  title: string;
};

export type MediaCollectionFormLayoutProps = {
  areReplayChaptersFetched: boolean;
  areReplaysFetched: boolean;
  areShoppablesFetched: boolean;
  creationStatus: CommandStatus;
  currentMediaCollection: CBOMediaCollection | null;
  formType: MediaCollectionsEditorFormType;
  maxPublishedShoppablesCount: number | null;
  mediaCollectionPagesUpdateStatus: CommandStatus;
  mediaCollectionSettingsUpdateStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onCreate: (payload: CreateMediaCollectionRequest['payload']) => void;
  onFetchReplayChapters: (replaysIds: string[]) => void;
  onGoToEventCreationPage: () => void;
  onGoToMediaCollection: (collectionId: string) => void;
  onGoToMediaCollectionList: () => void;
  onGoToShoppableCreationPage: () => void;
  onMediaPreviewClick: (media: CBOMediaCollectionMedia) => void;
  onOpenPublishedPagesEditor: () => void;
  onUpdateSettings: (payload: UpdateMediaCollectionSettingsRequest['payload']) => void;
  publishedShoppablesCount: number;
  replays: CBOEventListReadModel[];
  shoppables: CBOShoppableVideoListReadModel;
  tenantId: string;
  tenantName: string;
};

const MediaCollectionFormLayout: FC<MediaCollectionFormLayoutProps> = ({
  areReplayChaptersFetched,
  areReplaysFetched,
  areShoppablesFetched,
  creationStatus,
  currentMediaCollection,
  formType,
  maxPublishedShoppablesCount,
  mediaCollectionPagesUpdateStatus,
  mediaCollections,
  mediaCollectionSettingsUpdateStatus,
  onCreate,
  onFetchReplayChapters,
  onGoToEventCreationPage,
  onGoToMediaCollection,
  onGoToMediaCollectionList,
  onGoToShoppableCreationPage,
  onMediaPreviewClick,
  onOpenPublishedPagesEditor,
  onUpdateSettings,
  publishedShoppablesCount: defaultPublishedShoppablesCount,
  replays,
  shoppables,
  tenantId,
  tenantName,
}) => {
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [publishedShoppablesCount, setPublishedShoppablesCount] = useState<number>(defaultPublishedShoppablesCount);

  const { mediaCollectionMediaId } = useRequiredParams<{ mediaCollectionMediaId: string }>();

  const makeDefaultValues = (currentMediaCollection?: CBOMediaCollection): MediaCollectionSettingsFormInputs => {
    let mediaFromParam: CBOMediaCollectionMedia | null = null;

    if (mediaCollectionMediaId) {
      const shoppableOrNull = shoppables.find((shoppable) => shoppable.id === mediaCollectionMediaId) ?? null;

      if (shoppableOrNull) {
        mediaFromParam = shoppableVideoListItemReadModelToMediaCollectionMedia(shoppableOrNull);
      }
    }

    if (currentMediaCollection) {
      return {
        ...currentMediaCollection,
        medias: mediaFromParam ? [...currentMediaCollection.medias, mediaFromParam] : currentMediaCollection.medias,
      };
    }

    return {
      id: uuidv4(),
      medias: mediaFromParam ? [mediaFromParam] : [],
      tenantId,
      title: '',
    };
  };

  const form = useForm<MediaCollectionSettingsFormInputs>({
    defaultValues: makeDefaultValues(),
    mode: 'onChange',
  });

  const resetForm = () => {
    form.reset();
  };

  const { watch } = form;
  const medias = watch('medias');

  useEffect(() => {
    setPublishedShoppablesCount(defaultPublishedShoppablesCount);
  }, [defaultPublishedShoppablesCount]);

  useEffect(() => {
    if (formType === 'creation' && form.getValues('tenantId') !== tenantId) {
      form.reset({
        id: uuidv4(),
        medias: [],
        tenantId,
        title: form.getValues('title'),
      });
    }
  }, [formType, tenantId]);

  useEffect(() => {
    if (formType === 'edition' && currentMediaCollection) form.reset(makeDefaultValues(currentMediaCollection));
  }, [currentMediaCollection, formType]);

  useEffect(() => {
    if (creationStatus === 'success' && currentMediaCollection) {
      onGoToMediaCollection(currentMediaCollection.id);
    }
  }, [creationStatus, currentMediaCollection]);

  useEffect(() => {
    const isPending =
      creationStatus === 'pending' ||
      mediaCollectionPagesUpdateStatus === 'pending' ||
      mediaCollectionSettingsUpdateStatus === 'pending';

    setSubmissionInProgress(isPending);
  }, [creationStatus, mediaCollectionPagesUpdateStatus, mediaCollectionSettingsUpdateStatus]);

  useEffect(() => {
    updatePublishedShoppablesCount();
  }, [medias.length]);

  const handleSubmit = async (values: MediaCollectionSettingsFormInputs) => {
    const payload = values;

    formType === 'creation' ? onCreate(payload) : onUpdateSettings(payload);
  };

  const updatePublishedShoppablesCount = () => {
    const { id, medias, title } = form.getValues();

    let allMediasCollections: CBOMediaCollection[] = [];

    if (formType === 'creation') {
      allMediasCollections = [
        ...mediaCollections,
        {
          id,
          medias,
          publishedPages: [],
          tenantId,
          title,
        },
      ];
    } else if (currentMediaCollection) {
      allMediasCollections = mediaCollections.map((mediaCollection) =>
        mediaCollection.id === id
          ? {
              ...mediaCollection,
              medias,
            }
          : mediaCollection,
      );
    }

    setPublishedShoppablesCount(countPublishedShoppablesFromMediaCollections(allMediasCollections));
  };

  return (
    <MediaCollectionForm
      areReplayChaptersFetched={areReplayChaptersFetched}
      areReplaysFetched={areReplaysFetched}
      areShoppablesFetched={areShoppablesFetched}
      form={form}
      formType={formType}
      maxPublishedShoppablesCount={maxPublishedShoppablesCount}
      onFetchReplayChapters={onFetchReplayChapters}
      onGoToEventCreationPage={onGoToEventCreationPage}
      onGoToMediaCollectionList={onGoToMediaCollectionList}
      onGoToShoppableCreationPage={onGoToShoppableCreationPage}
      onMediaPreviewClick={onMediaPreviewClick}
      onOpenPublishedPagesEditor={onOpenPublishedPagesEditor}
      onSubmit={handleSubmit}
      publishedPagesCount={formType === 'creation' ? 0 : currentMediaCollection?.publishedPages.length || 0}
      publishedShoppablesCount={publishedShoppablesCount}
      replays={replays}
      resetForm={resetForm}
      shoppables={shoppables}
      submissionInProgress={submissionInProgress}
      tenantName={tenantName}
    />
  );
};

export default MediaCollectionFormLayout;
