import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, IconButton, LinearProgress, styled } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CBOCurrency } from '../../../core/domain/CBOCurrency';
import { CBOEventReadModel } from '../../../core/domain/CBOEventReadModel';
import { CBOEventStatisticsWithSales, CBOEventStatisticsWithoutSales } from '../../../core/domain/CBOTenantStatistics';
import EventStatisticsHeader from '../../components/molecules/EventStatisticsHeader';
import ECommerceSection from '../../components/organisms/analytics/EventECommerceSection';
import EngagementSection from '../../components/organisms/analytics/EventEngagementSection';
import TrafficSection from '../../components/organisms/analytics/EventTrafficSection';

const Root = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  flexGrow: 1,
  position: 'relative',
  textAlign: 'initial',
}));

const BackArrowContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${theme.spacing(6)}`,
  [theme.breakpoints.up('xs')]: {
    left: `${theme.spacing(0)}`,
  },
  [theme.breakpoints.up('md')]: {
    left: `${theme.spacing(2)}`,
  },
  [theme.breakpoints.up('lg')]: {
    left: `${theme.spacing(4)}`,
  },
  [theme.breakpoints.up('xl')]: {
    left: `${theme.spacing(8)}`,
  },
}));

type EventStatisticsTemplateProps = {
  currency: CBOCurrency;
  event: CBOEventReadModel;
  loading: boolean;
} & (
  | {
      hasSales: false;
      statistics: CBOEventStatisticsWithoutSales;
    }
  | {
      hasSales: true;
      statistics: CBOEventStatisticsWithSales;
    }
);

export default function EventStatisticsTemplate(props: EventStatisticsTemplateProps) {
  const { currency, event, hasSales, loading } = props;
  const renderProgress = () => {
    return <LinearProgress />;
  };

  const renderSections = () => (
    <Grid container justifyContent="center" pb={4} position="relative">
      <BackArrowContainer>
        <IconButton component={RouterLink} to="/analytics">
          <ArrowBackIcon sx={{ color: '#3E3B36' }} />
        </IconButton>
      </BackArrowContainer>
      <Grid gap={2} item md={10} mt={2} xs={12}>
        <Box display="flex" flex={1} flexDirection="column" gap={2}>
          <EventStatisticsHeader event={event} />
          <TrafficSection statistics={props.statistics.traffic} />
          <EngagementSection statistics={props.statistics.engagement} />
          {hasSales ? (
            <ECommerceSection
              currency={currency}
              event={event}
              hasSales
              products={event.products}
              productsStatistics={props.statistics.productsStatistics}
              statistics={props.statistics.eCommerce}
            />
          ) : (
            <ECommerceSection
              currency={currency}
              event={event}
              hasSales={false}
              products={event.products}
              productsStatistics={props.statistics.productsStatistics}
              statistics={props.statistics.eCommerce}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );

  return <Root id="event-statistics">{loading || !props.statistics ? renderProgress() : renderSections()}</Root>;
}
