import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { fetchKlaviyoLists } from '../../usecases/klaviyo/fetch-klaviyo-lists';
import { INITIAL_KLAVIYO_STATE, KlaviyoState } from '../state/klaviyo';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildListsReducers = (builder: ActionReducerMapBuilder<KlaviyoState>) => {
  builder.addCase(fetchKlaviyoLists.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('klaviyoListsFetching'),
    lists: action.payload,
  }));
  builder.addCase(fetchKlaviyoLists.pending, (state) => ({
    ...state,
    ...pendingStatus('klaviyoListsFetching'),
    lists: null,
  }));
  builder.addCase(fetchKlaviyoLists.rejected, (state, action) => ({
    ...state,
    ...errorStatus('klaviyoListsFetching', [action.error]),
  }));
};

export const klaviyoSlice = createSlice({
  extraReducers: (builder) => {
    buildListsReducers(builder);
  },
  initialState: INITIAL_KLAVIYO_STATE,
  name: 'klaviyo',
  reducers: {},
});
