import { Clear } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Inputs = {
  eventId: string;
};

interface EventFinderFormProps {
  loading: boolean;
  onSubmit: (eventId: string) => void;
}

export default function EventFinderForm({ loading, onSubmit }: EventFinderFormProps) {
  const { t } = useTranslation(['administration', 'common']);

  const defaultValues: Inputs = {
    eventId: '',
  };

  const { register, handleSubmit, formState, setValue, watch } = useForm<Inputs>({ defaultValues });
  const { errors } = formState;

  const { ref: eventIdInputRef, ...eventIdInputProps } = register('eventId', {
    maxLength: { message: t('common:MaxCharsWithCount', { count: 36 }), value: 36 },
    minLength: { message: t('common:MinCharsWithCount', { count: 36 }), value: 36 },
    required: { message: t('common:Required'), value: true },
  });

  const handleClearInput = () => setValue('eventId', '');

  return (
    <form
      noValidate
      onSubmit={handleSubmit(({ eventId }) => onSubmit(eventId))}
      style={{
        width: '100%',
      }}
    >
      <Box mb={1}>
        <Typography variant="h6">{t('EventFinder')}</Typography>
      </Box>
      <Box mb={2}>
        <TextField
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClearInput} sx={{ visibility: watch('eventId') ? 'visible' : 'hidden' }}>
                <Clear />
              </IconButton>
            ),
          }}
          disabled={loading}
          error={!!errors.eventId}
          fullWidth
          helperText={!!errors.eventId ? errors?.eventId?.message : ''}
          inputRef={eventIdInputRef}
          label={t('EventId')}
          required
          variant="outlined"
          {...eventIdInputProps}
        />
      </Box>
      <Button color="primary" disabled={!formState.isDirty || loading} type="submit" variant="contained">
        <span>{t('common:Find')}</span>
      </Button>
    </form>
  );
}
