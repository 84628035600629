import { BILLING_PLANS, Cms } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createNappsIntegrationViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const currentTenant = state.tenants.currentTenant;

    const isEnabled =
      currentTenant.cms !== Cms.Shopify || BILLING_PLANS[currentTenant.activeSubscriptionId].integrationsEnabled.napps;

    return {
      apiKey: state.tenants.currentTenant.apiKey,
      currentTenant,
      isEnabled,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
