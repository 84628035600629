import { Box, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMagicContent } from '../../../../../../core/domain/CBOMagicContent';
import ButtonWithTextChangeTimeout from '../../../../atoms/ButtonWithTextChangeTimeout';

const MagicContentRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
}));

const MagicContentSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: '100%',
}));

const MagicContentLabelContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
}));

const MagicContentLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.4em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  marginTop: 0,
  textalign: 'initial',
}));

const MagicContentValue = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'initial',
}));

const MagicContentCopyButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const MagicContent: FC<CBOMagicContent> = (magicContent) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getRawText = (text: string) => {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent || div.innerText || text;
  };

  const getCopyButtonAsText = useCallback(
    (text: string) => (
      <ButtonWithTextChangeTimeout
        ButtonProps={{
          color: 'inherit',
          onClick: () => {
            navigator.clipboard.writeText(getRawText(text));
          },
          sx: {
            borderColor: theme.palette.grey[400],
            color: theme.palette.common.black,
            px: 4,
            textTransform: 'initial',
            whiteSpace: 'nowrap',
            width: '140px',
          },
          variant: 'outlined',
        }}
        initialText={t('common:CopyAsText')}
        secondText={t('common:Copied')}
      />
    ),
    [],
  );

  const getCopyButtonAsHTML = useCallback(
    (text: string) => (
      <ButtonWithTextChangeTimeout
        ButtonProps={{
          color: 'inherit',
          onClick: () => {
            navigator.clipboard.writeText(text);
          },
          sx: {
            borderColor: theme.palette.grey[400],
            color: theme.palette.common.black,
            px: 4,
            textTransform: 'initial',
            whiteSpace: 'nowrap',
            width: '140px',
          },
          variant: 'outlined',
        }}
        initialText={t('common:CopyAsHtml')}
        secondText={t('common:Copied')}
      />
    ),
    [],
  );

  const getTextField = useCallback(
    (text: string) => (
      <TextField
        InputProps={{
          inputComponent: () => <MagicContentValue dangerouslySetInnerHTML={{ __html: text }} />,
          readOnly: true,
        }}
        fullWidth
      />
    ),
    [],
  );

  if (magicContent.status === 'failed') {
    return (
      <Typography color="error">
        {t('events:MagicContent.MagicContentFailed', { error: magicContent.error })}
      </Typography>
    );
  }

  if (magicContent.status === 'pending') {
    return <Typography>{t('events:MagicContent.MagicContentPending')}</Typography>;
  }

  const { metaDescriptionSnippet, htmlContent, id, htmlTitle } = magicContent;

  return (
    <MagicContentRoot key={id} py={2}>
      <MagicContentSection>
        <MagicContentLabelContainer>
          <MagicContentLabel variant="h3">{t('events:MagicContent.Title')}</MagicContentLabel>
          <MagicContentCopyButtonsContainer>
            {getCopyButtonAsText(htmlTitle)}
            {getCopyButtonAsHTML(htmlTitle)}
          </MagicContentCopyButtonsContainer>
        </MagicContentLabelContainer>
        {getTextField(htmlTitle)}
      </MagicContentSection>

      <MagicContentSection>
        <MagicContentLabelContainer>
          <MagicContentLabel variant="h3">{t('events:MagicContent.Content')}</MagicContentLabel>
          <MagicContentCopyButtonsContainer>
            {getCopyButtonAsText(htmlContent)}
            {getCopyButtonAsHTML(htmlContent)}
          </MagicContentCopyButtonsContainer>
        </MagicContentLabelContainer>
        {getTextField(htmlContent)}
      </MagicContentSection>

      <MagicContentSection>
        <MagicContentLabelContainer>
          <MagicContentLabel variant="h3">{t('events:MagicContent.SnippetPreview')}</MagicContentLabel>
        </MagicContentLabelContainer>

        <Box
          sx={{ backgroundColor: (theme) => theme.palette.grey[100], padding: theme.spacing(2), textAlign: 'justify' }}
        >
          <Typography>{getRawText(metaDescriptionSnippet)}</Typography>
        </Box>
      </MagicContentSection>
    </MagicContentRoot>
  );
};

export default MagicContent;
