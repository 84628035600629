import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { State } from '../../core/store';
import { loginConfirmPending } from '../../core/store/slices/authentication.slice';

export const AuthRedirect = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loginConfirmPending());
  }, [dispatch]);

  const isAuthenticated = useSelector((state: State) => state.authentication.isAuthenticated);

  const redirectTo = new URLSearchParams(location.search).get('redirect_url') || '/';

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate(redirectTo, { replace: true });
    }
  }, [isAuthenticated, history, redirectTo]);

  if (isAuthenticated === false) {
    return (
      <div>
        Authentication failed
        <br />
        <Link to={redirectTo}>Try again</Link>
      </div>
    );
  }

  return <div>Loading...</div>;
};
