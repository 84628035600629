import { LiveEventStatistics } from '@bellepoque/api-contracts';
import { Box, CircularProgress, List, styled, useTheme } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-phone-number-input/style.css';

import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { CommandStatus, QueryStatus } from '../../../../../core/store/state/utils';
import LiveDisplayedProductRow from '../../../molecules/event/LiveDisplayedProductRow';
import LiveNotDisplayedProductRow from '../../../molecules/event/LiveNotDisplayedProductRow';
import ShowcaseHeader from '../../../molecules/event/live-manager/ShowcaseHeader';

const ProductListContainer = styled(List)(({ theme }) => ({
  height: '100%',
  minHeight: 0,
  overflow: 'scroll',
  padding: `0 ${theme.spacing(2)}`,
}));

const Container = styled(Box)({
  height: '100%',
});

const ForegroundContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 0,
  position: 'relative',
  zIndex: 1,
});

const SideBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  height: '100%',
  left: 0,
  opacity: 0.17,
  position: 'absolute',
  top: 0,
  width: '90px',
  zIndex: 0,
}));

interface ProductsShowcaseProps {
  canProductsBeDisplayed?: boolean;
  currency: CBOCurrency;
  currentLiveEventStatistics: LiveEventStatistics;
  displayedProducts: CBOEventReadModelProduct[];
  onDisplayProduct: (product: CBOEventReadModelProduct) => void;
  products: CBOEventReadModelProduct[];
  productsDisplayStatus: CommandStatus;
  realtimeDataFetchingStatus: QueryStatus;
}

export default function ProductsShowcase(props: ProductsShowcaseProps) {
  const {
    canProductsBeDisplayed,
    currency,
    currentLiveEventStatistics,
    displayedProducts,
    onDisplayProduct,
    productsDisplayStatus,
    realtimeDataFetchingStatus,
  } = props;
  const theme = useTheme();

  const [products, setProducts] = useState<CBOEventReadModelProduct[]>(props.products);
  const [searchTerms, setSearchTerms] = useState<string | null>(null);

  useEffect(() => {
    let filteredProducts = props.products;
    if (searchTerms) {
      filteredProducts = props.products.filter(
        (product) => product.title.toUpperCase().indexOf(searchTerms.toUpperCase()) !== -1,
      );
    }
    setProducts(filteredProducts);
  }, [searchTerms, props.products]);

  const displayedProductsIds = useMemo(() => displayedProducts.map(({ id }) => id), [displayedProducts]);

  const isBackdropShown = useMemo(
    () => productsDisplayStatus === 'pending' || realtimeDataFetchingStatus === 'pending',
    [productsDisplayStatus, realtimeDataFetchingStatus],
  );

  const isProductDisplayed = useCallback(
    (product: CBOEventReadModelProduct) => displayedProducts.some(({ id }) => id === product.id),
    [displayedProducts],
  );

  const renderProductList = useCallback(
    () => (
      <List id="products-list" sx={{ width: '80%' }}>
        {products.map(renderProductRow)}
      </List>
    ),
    [products, displayedProductsIds, currentLiveEventStatistics],
  );

  const renderProductRow = useCallback(
    (product: CBOEventReadModelProduct) => {
      if (!isProductDisplayed(product))
        return (
          <LiveNotDisplayedProductRow
            currency={currency}
            disabled={!canProductsBeDisplayed}
            key={product.id}
            onClick={() => onDisplayProduct(product)}
            product={product}
          />
        );

      const isCurrentlyDisplayed =
        displayedProductsIds.length > 0 && displayedProductsIds[displayedProductsIds.length - 1] === product.id;

      return (
        <LiveDisplayedProductRow
          currency={currency}
          isCurrentlyDisplayed={isCurrentlyDisplayed}
          key={product.id}
          liveProductStats={currentLiveEventStatistics.byProductIds[product.id]}
          onClick={() => onDisplayProduct(product)}
          product={product}
        />
      );
    },
    [displayedProductsIds, currentLiveEventStatistics],
  );

  return (
    <Container id="showcase-root">
      <ForegroundContentContainer>
        <ShowcaseHeader onSearch={setSearchTerms} />
        <ProductListContainer>{renderProductList()}</ProductListContainer>
        <Backdrop
          open={isBackdropShown}
          sx={{
            position: 'absolute',
            zIndex: theme.zIndex.tooltip + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ForegroundContentContainer>
      <SideBackground />
    </Container>
  );
}
