import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Typography, styled, useTheme } from '@mui/material';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
});

const CloseButton = styled(Button)({
  padding: 0,
  position: 'absolute',
  right: 0,
  top: 0,
});

const FullScreenContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  width: '100%',
}));

type MobileFileUploadQRCodeProps = {
  disabled: boolean;
  url: string;
};

const MobileFileUploadQRCode = ({ disabled, url }: MobileFileUploadQRCodeProps) => {
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const { t } = useTranslation('shoppables', { keyPrefix: 'UploadVideo' });
  const theme = useTheme();
  const fullScreenSizeInVh = 75;
  const fullScreenPaddingInPx = 10;

  const handleCloseModal = () => {
    setIsFullScreen(false);
  };

  return (
    <Container flex={1} width="100%">
      <ButtonContainer boxShadow={2}>
        <Typography fontSize="1.1em" mb={2} textAlign="center" variant="h6">
          {t('UploadFromMobile')}
        </Typography>

        <Button
          disabled={disabled}
          onClick={() => setIsFullScreen(true)}
          sx={{ textTransform: 'initial' }}
          variant="contained"
        >
          {t('OpenQRCode')}
        </Button>
      </ButtonContainer>

      <Modal onBackdropClick={handleCloseModal} onClose={handleCloseModal} open={isFullScreen}>
        <FullScreenContainer
          height={`${fullScreenSizeInVh}vh`}
          padding={`${fullScreenPaddingInPx}px`}
          width={`${fullScreenSizeInVh}vh`}
        >
          <CloseButton onClick={handleCloseModal} sx={{ marginRight: '-5em' }}>
            <Close
              sx={{
                color: (sx) => sx.palette.common.white,
                fontSize: '3em',
              }}
            />
          </CloseButton>
          <QRCode
            fgColor={theme.palette.primary.dark}
            height={document.documentElement.clientHeight * (fullScreenSizeInVh / 100) - fullScreenPaddingInPx * 2}
            level="L"
            renderAs="svg"
            value={url}
            width={document.documentElement.clientHeight * (fullScreenSizeInVh / 100) - fullScreenPaddingInPx * 2}
          />
        </FullScreenContainer>
      </Modal>
    </Container>
  );
};

export default MobileFileUploadQRCode;
