import { YoutubeAccessToken } from '@bellepoque/api-contracts';
import { Box, CircularProgress, Link, Modal, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalStorageItem, setLocalStorageItem } from '../../utils/local-storage';
import { toolbarHeight } from '../components/organisms/Menu';
import { createYoutubeLoginViewModel } from './YoutubeLogin.viewmodel';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '5px',
  boxShadow: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  left: '50%',
  outline: 'none',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  position: 'absolute',
  top: `calc(50% - ${toolbarHeight}px)`,
  transform: 'translate(-50%, -50%)',
  width: '500px',
}));

const Message = styled(Typography)({
  fontSize: '1.1em',
  whiteSpace: 'break-spaces',
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2em',
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

export default function YoutubeLogin() {
  const viewModel = useSelector(createYoutubeLoginViewModel({ dispatch: useDispatch() }));

  const [hasLoginError, setHasLoginError] = useState(false);
  const [hasStreamingNotEnabledError, setHasStreamingNotEnabledError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<YoutubeAccessToken | null>(null);
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation('events', { keyPrefix: 'YoutubeRestreaming' });
  const hasError = hasLoginError || hasStreamingNotEnabledError;

  const {
    checkHasYoutubeLiveStreamEnabled,
    checkHasYoutubeLiveStreamEnabledStatus,
    hasYoutubeLiveStreamEnabled,
    exchangeYoutubeCode,
  } = viewModel;

  useEffect(() => {
    const code = params.get('code');
    if (code) {
      exchangeYoutubeCodeAndStoreToken(code);
    } else {
      setIsLoading(false);
      setHasLoginError(true);
    }
  }, []);

  useEffect(() => {
    if (token) {
      checkHasYoutubeLiveStreamEnabled(token);
    }
  }, [token]);

  useEffect(() => {
    if (checkHasYoutubeLiveStreamEnabledStatus === 'loaded') {
      setIsLoading(false);
      if (!hasYoutubeLiveStreamEnabled) {
        setHasStreamingNotEnabledError(true);
      }

      if (hasYoutubeLiveStreamEnabled && token) {
        storeTokenInLocalStorage(token);
      }
    }
  }, [checkHasYoutubeLiveStreamEnabledStatus, hasYoutubeLiveStreamEnabled, token]);

  const exchangeYoutubeCodeAndStoreToken = async (code: string) => {
    let token: YoutubeAccessToken;
    try {
      token = await exchangeYoutubeCode(code);
      setToken(token);
    } catch (e) {
      console.error('Error while exchanging youtube code', e);
      setHasLoginError(true);
    }
  };

  const storeTokenInLocalStorage = (token: YoutubeAccessToken) => {
    const stringifiedToken = JSON.stringify(token);
    const hasChanged = getLocalStorageItem('YoutubeAccessToken') !== stringifiedToken;
    if (hasChanged) {
      setLocalStorageItem('YoutubeAccessToken', stringifiedToken);
    }
  };

  const errorMessage = useMemo(() => {
    if (hasStreamingNotEnabledError) {
      return (
        <Trans
          components={{
            enableFeatureLink: (
              <Link href="https://www.youtube.com/features" target="_blank">
                {t('YoutubeLiveStreamingNotEnabled')}
              </Link>
            ),
          }}
          i18nKey="YoutubeLiveStreamingNotEnabled"
          ns="events"
          t={t}
        />
      );
    }
    return t('ErrorCloseTabAndRetry');
  }, [hasError, hasStreamingNotEnabledError]);

  return (
    <Modal open>
      <Container>
        {isLoading ? (
          <>
            <Title variant="titleFont">{t('YoutubeLogin')}</Title>
            <CircularProgress size="3em" />
          </>
        ) : (
          <>
            <Title variant="titleFont">{t(hasError ? 'YoutubeLoginFailed' : 'YoutubeLoginSuccessful')}</Title>
            <Message>{hasError ? errorMessage : t('SuccessCloseTab')}</Message>
          </>
        )}
      </Container>
    </Modal>
  );
}
