import { V1 } from '@bellepoque/api-contracts';

import { CBOShoppablesSettings } from '../../domain/CBOShoppablesSettings';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUpdateShoppablesSettings as resetUpdateShoppablesSettingsAction } from '../../store/slices/shoppables/shoppables-settings.slice';

export type UpdateShoppablesSettingsPayload = V1.api.UpdateShoppablesSettingsDTO;

type UpdateShoppablesSettingsParams = {
  payload: V1.api.UpdateShoppablesSettingsDTO;
  tenantId: string;
};

export const updateShoppablesSettings = createAppAsyncThunk(
  'shoppables/settings/update',
  async (
    { payload, tenantId }: UpdateShoppablesSettingsParams,
    { extra: { shoppablesGateway } },
  ): Promise<CBOShoppablesSettings> => {
    return shoppablesGateway.updateShoppablesSettings(tenantId, payload);
  },
);

export const resetUpdateShoppablesSettings = () => {
  return resetUpdateShoppablesSettingsAction();
};
