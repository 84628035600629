import { Box, ButtonProps, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommandStatus } from '../../../../core/store/state/utils';
import FormDialog from '../../../templates/dialog/FormDialog';
import UploadVideoSquareBox from '../../atoms/inputs/UploadVideoSquareBox';
import MobileFileUploadQRCode from '../../molecules/shoppables/MobileFileUploadQRCode';

// import UploadFromSocialMedia from '../../molecules/shoppables/UploadFromSocialMedia';

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}));

const OrContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

const VideoPreview = styled('video')({
  height: 0,
  position: 'absolute',
  width: 0,
  zIndex: -1,
});

const HelperText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isError' })<{ isError?: boolean }>(
  ({ isError, theme }) => ({
    fontSize: '10px',
    ...(isError && {
      color: theme.palette.error.main,
      fontSize: '12px',
      fontWeight: 'bold',
    }),
  }),
);
const MOBILE_WEBSITE_URL = process.env.REACT_APP_SHOPPABLE_MOBILE_UPLOAD_WEBSITE;

export type AddVideoToShoppableDialogProps = {
  fileUploadStatus: CommandStatus;
  fileUploadUrl: string;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onUploadVideo: (file: File) => void;
  onUploadVideoFromSocialMedia: (url: string) => void;
  shoppableId: string;
  shoppableVideoUrl: string | null;
  tenantId: string;
  uploadVideoFromSocialMediaStatusLoading: boolean;
};

export default function AddVideoToShoppableDialog({
  fileUploadStatus,
  fileUploadUrl,
  isOpen,
  loading,
  onClose,
  onUploadVideo,
  // onUploadVideoFromSocialMedia,
  uploadVideoFromSocialMediaStatusLoading,
  shoppableVideoUrl,
  shoppableId,
  tenantId,
}: AddVideoToShoppableDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(['shoppables', 'common']);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hasUploadErrors, setUploadErrors] = useState(false);

  const [isVertical, setIsVertical] = useState<boolean | undefined>(undefined);

  const mobileUploadUrl = new URL(`${MOBILE_WEBSITE_URL}`);
  mobileUploadUrl.searchParams.append('u', encodeURIComponent(fileUploadUrl));
  mobileUploadUrl.searchParams.append('s', shoppableId);
  mobileUploadUrl.searchParams.append('t', tenantId);

  const getVideoIsVertical = useCallback((video) => {
    const { videoHeight, videoWidth } = video;
    return videoHeight >= videoWidth;
  }, []);

  const handleVideoPreviewSet = useCallback(({ target }: React.SyntheticEvent<HTMLVideoElement>) => {
    setIsVertical(getVideoIsVertical(target));
  }, []);

  useEffect(() => {
    const areUploadingVideoChecksPassed = isVertical;

    if (!selectedFile || areUploadingVideoChecksPassed === undefined) return;

    if (areUploadingVideoChecksPassed) onUploadVideo(selectedFile);

    setUploadErrors(!areUploadingVideoChecksPassed);
    setSelectedFile(null);
  }, [isVertical, onUploadVideo]);

  useEffect(() => {
    if (shoppableVideoUrl) {
      enqueueSnackbar(t('notifications.ShoppableVideoSuccessfullyImported'), { variant: 'success' });
      onClose();
    }
  }, [shoppableVideoUrl]);

  const handleClose = () => {
    onClose();
  };

  const handleSelectedFile = (file: File) => {
    setSelectedFile(file);
  };

  const buttonsProps: ButtonProps[] = [
    {
      children: <span>{t('common:Close')}</span>,
      color: 'primary',
      disabled: uploadVideoFromSocialMediaStatusLoading,
      onClick: handleClose,
      variant: 'contained',
    },
  ];

  return (
    <FormDialog
      DialogContentProps={{ sx: { backgroundColor: (theme) => theme.palette.grey[100] } }}
      buttonsProps={buttonsProps}
      loading={uploadVideoFromSocialMediaStatusLoading}
      onClose={handleClose}
      open={isOpen}
      sx={{
        minWidth: '700px',
        overflowY: 'visible',
        width: '700px',
        // With import from social media component
        // minWidth: '900px',
        // width: '900px',
      }}
      title={t('UploadVideo.ImportVideo')}
    >
      <ContentContainer p={2}>
        <Box sx={{ height: '100%' }}>
          <UploadVideoSquareBox
            buttonDescription={t('UploadVideo.Description')}
            buttonLabel={t('UploadVideo.Action')}
            disabled={uploadVideoFromSocialMediaStatusLoading}
            errorMessage={t('common:ErrorPleaseRetry')}
            helperText={<HelperText isError={hasUploadErrors}>{t('UploadVideo.HelperText')}</HelperText>}
            loading={loading || fileUploadStatus === 'pending'}
            onSelectFile={handleSelectedFile}
            uploadStatus={fileUploadStatus}
          />
          {selectedFile && (
            <VideoPreview autoPlay muted onTimeUpdate={handleVideoPreviewSet} src={URL.createObjectURL(selectedFile)} />
          )}
        </Box>
        <OrContainer>
          <Typography>{t('common:Or')}</Typography>
        </OrContainer>
        <Box display="flex" flex={1} flexDirection="column" gap={2}>
          <MobileFileUploadQRCode disabled={uploadVideoFromSocialMediaStatusLoading} url={mobileUploadUrl.toString()} />
          {/* <UploadFromSocialMedia
            onUpload={onUploadVideoFromSocialMedia}
            submissionInProgress={uploadVideoFromSocialMediaStatusLoading}
          /> */}
        </Box>
      </ContentContainer>
    </FormDialog>
  );
}
