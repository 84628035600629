import { V1 } from '@bellepoque/api-contracts';
import { FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CoversOverlayIcon from '../../../../../icons/CoversOverlayIcon';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import SettingsSection from '../../SettingsSection';

const constraints = V1.api.constraints.events;

interface MessagesSettingsSectionProps {
  errors: any;
  postEventTextInputProps: any;
  postEventTextInputRef: React.Ref<any>;
  postEventTextInputWatch: string;
  preEventTextInputProps: any;
  preEventTextInputRef: React.Ref<any>;
  preEventTextInputWatch: string;
  readonly: boolean;
  submissionInProgress: boolean;
}

export default function MessagesSettingsSection(props: MessagesSettingsSectionProps) {
  const {
    errors,
    postEventTextInputProps,
    postEventTextInputRef,
    postEventTextInputWatch,
    preEventTextInputProps,
    preEventTextInputRef,
    preEventTextInputWatch,
    readonly,
    submissionInProgress,
  } = props;
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();

  return (
    <SettingsSection
      icon={<CoversOverlayIcon sx={{ color: theme.palette.common.white }} />}
      title={t('settings.PreAndPostEventOverlays')}
    >
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.preEventText}
          fullWidth
          helperText={!!errors.preEventText ? errors?.preEventText?.message : ''}
          id="preEventText"
          inputRef={preEventTextInputRef}
          label={t('PreEventText')}
          multiline
          rows={4}
          sx={{ backgroundColor: theme.palette.common.white }}
          variant="filled"
          {...preEventTextInputProps}
        />
        <InputTooltip content={t('helpers.PreEventText')} />
        <FormHelperText>{`${preEventTextInputWatch.length} / ${constraints.preEventText.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.postEventText}
          fullWidth
          helperText={!!errors.postEventText ? errors?.postEventText?.message : ''}
          id="postEventText"
          inputRef={postEventTextInputRef}
          label={t('PostEventText')}
          multiline
          rows={4}
          variant="filled"
          {...postEventTextInputProps}
        />
        <InputTooltip content={t('helpers.PostEventText')} />
        <FormHelperText>{`${postEventTextInputWatch.length} / ${constraints.postEventText.maxLength}`}</FormHelperText>
      </Grid>
    </SettingsSection>
  );
}
