import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ColorIcon from '../../../../../icons/ColorIcon';
import ReactHookFormColorPicker from '../../../atoms/inputs/ReactHookFormColorPicker';
import { ThemeInputs } from '../../../organisms/event/event-details/ThemeForm';
import SettingsSection from '../../SettingsSection';

interface ColorsSettingsSectionProps {
  control: Control<ThemeInputs, any>;
  errors: any;
  readonly: boolean;
  submissionInProgress: boolean;
  watch: UseFormWatch<ThemeInputs>;
}

export default function ColorsSettingsSection(props: ColorsSettingsSectionProps) {
  const { control, errors, readonly, submissionInProgress, watch } = props;
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();
  const disabled = readonly || submissionInProgress;

  return (
    <SettingsSection
      altBackground
      contentWidth={11}
      icon={<ColorIcon sx={{ color: theme.palette.common.white }} />}
      title={t('Colors')}
    >
      <Grid container flexWrap="wrap" spacing={3}>
        <Grid alignItems="flex-end" display="flex" item position="relative" xs={6}>
          <ReactHookFormColorPicker
            control={control}
            disabled={disabled}
            error={errors.addToCartBackgroundColor}
            label={t('PrimaryColor')}
            name="addToCartBackgroundColor"
            watch={watch}
          />
        </Grid>
        <Grid alignItems="flex-end" display="flex" item position="relative" xs={6}>
          <ReactHookFormColorPicker
            control={control}
            disabled={disabled}
            error={errors.removeFromCartBackgroundColor}
            label={t('SecondaryColor')}
            name="removeFromCartBackgroundColor"
            watch={watch}
          />
        </Grid>
        <Grid alignItems="flex-end" display="flex" item position="relative" xs={6}>
          <ReactHookFormColorPicker
            control={control}
            disabled={disabled}
            error={errors.validateCartBackgroundColor}
            label={t('ValidateCartBackgroundColor')}
            name="validateCartBackgroundColor"
            watch={watch}
          />
        </Grid>
        <Grid display="flex" item justifyContent="flex-end" position="relative" xs={6}>
          <ReactHookFormColorPicker
            control={control}
            disabled={disabled}
            error={errors.adminMessageBackgroundColor}
            label={t('AdminMessageBackgroundColor')}
            name="adminMessageBackgroundColor"
            watch={watch}
          />
        </Grid>
      </Grid>
    </SettingsSection>
  );
}
