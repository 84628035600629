import { FormControl, FormHelperText, Grid } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import ProductPriceInput from '../../atoms/ProductPriceInput';

interface ProductPricesProps {
  currency: CBOCurrency;
  disabled?: boolean;
  product: CBOEventReadModelProduct;
}

export default function ProductPrices({ currency, disabled = false, product }: ProductPricesProps) {
  const { t } = useTranslation('events');
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <Grid item xs={12}>
        <FormControl disabled={disabled} error={!!errors.price} fullWidth required variant="outlined">
          <Controller
            control={control}
            name={'price'}
            render={({ field }) => (
              <ProductPriceInput
                currency={currency}
                defaultValue={product.price}
                disabled={disabled}
                field={field}
                id={`${product?.id || 'newProduct'}-price`}
                label={t('Price')}
                onChange={(value) => {
                  if (field.value !== value) field.onChange(value);
                }}
              />
            )}
            rules={{
              min: { message: t('PriceCantBeNegative'), value: 0 },
              required: { message: t('PriceIsRequired'), value: true },
            }}
          />
          {!!errors.price ? <FormHelperText>{errors.price?.message}</FormHelperText> : null}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl disabled={disabled} error={!!errors.crossedOutPrice} fullWidth variant="outlined">
          <Controller
            control={control}
            name={'crossedOutPrice'}
            render={({ field }) => (
              <ProductPriceInput
                currency={currency}
                defaultValue={product.crossedOutPrice}
                disabled={disabled}
                field={field}
                id={`${product?.id || 'newProduct'}-crossedOutPrice`}
                label={t('CrossedPrice')}
                onChange={(value) => {
                  if (field.value !== value) field.onChange(value);
                }}
              />
            )}
            rules={{
              validate: {
                nullablePositive: (v) => parseFloat(v) >= 0 || v === null || t('PriceCantBeNegative').toString(),
              },
            }}
          />
          {!!errors.crossedOutPrice ? <FormHelperText>{errors.crossedOutPrice?.message}</FormHelperText> : null}
        </FormControl>
      </Grid>
    </>
  );
}
