import { EventStatus } from '@bellepoque/api-contracts';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eventStatusToTranslation } from '../../../../utils/event-status-to-translation';

interface OnOffAirStyle {
  text: string;
  textBgColor: string;
  textColor: string;
}
interface HeaderEventStateProps {
  eventStatus: EventStatus;
}

export default function HeaderEventState({ eventStatus }: HeaderEventStateProps) {
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { t } = useTranslation('events', { keyPrefix: 'status' });

  const defaultOnOffAirStyle: OnOffAirStyle = {
    text: t('OffAir'),
    textBgColor: '#A8A8A8',
    textColor: theme.palette.common.white,
  };
  const onOffAirByEventStatus = {
    [EventStatus.PRIVATE_PREVIEW]: {
      text: eventStatusToTranslation(EventStatus.PRIVATE_PREVIEW, t),
      textBgColor: '#2196f3',
      textColor: theme.palette.common.white,
    },
    [EventStatus.ON_AIR]: {
      text: eventStatusToTranslation(EventStatus.ON_AIR, t),
      textBgColor: '#DE2C01',
      textColor: theme.palette.common.white,
    },
    [EventStatus.REPLAY_READY]: {
      text: eventStatusToTranslation(EventStatus.REPLAY_READY, t),
      textBgColor: '#2196f3',
      textColor: theme.palette.common.white,
    },
    [EventStatus.REPLAY_FAILED]: {
      text: eventStatusToTranslation(EventStatus.REPLAY_FAILED, t),
      textBgColor: '#9e0e2b',
      textColor: theme.palette.common.white,
    },
    [EventStatus.PRIVATE_REPLAY]: {
      text: eventStatusToTranslation(EventStatus.PRIVATE_REPLAY, t),
      textBgColor: '#2196f3',
      textColor: theme.palette.common.white,
    },
    [EventStatus.REPLAY]: {
      text: eventStatusToTranslation(EventStatus.REPLAY, t),
      textBgColor: '#62ff00',
      textColor: theme.palette.common.white,
    },
    [EventStatus.FINISHED]: {
      text: eventStatusToTranslation(EventStatus.FINISHED, t),
      textBgColor: '#A8A8A8',
      textColor: theme.palette.common.white,
    },
  };

  const [onOffAirState, setOnOffAirState] = useState<OnOffAirStyle>(defaultOnOffAirStyle);

  useEffect(() => {
    if (
      eventStatus === EventStatus.FINISHED ||
      eventStatus === EventStatus.ON_AIR ||
      eventStatus === EventStatus.PRIVATE_PREVIEW ||
      eventStatus === EventStatus.PRIVATE_REPLAY ||
      eventStatus === EventStatus.REPLAY ||
      eventStatus === EventStatus.REPLAY_READY ||
      eventStatus === EventStatus.REPLAY_FAILED
    ) {
      return setOnOffAirState(onOffAirByEventStatus[eventStatus]);
    }
    return setOnOffAirState(defaultOnOffAirStyle);
  }, [eventStatus]);

  return (
    <Button
      disableRipple
      disabled
      size="small"
      sx={{
        '&.Mui-disabled': {
          color: onOffAirState.textColor,
        },
        backgroundColor: onOffAirState.textBgColor,
        color: onOffAirState.textColor,
        minWidth: '95px',
        px: isLgScreen ? theme.spacing(2) : theme.spacing(1),

        textTransform: 'uppercase',
      }}
    >
      {onOffAirState.text}
    </Button>
  );
}
