import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOTenantKlaviyoIntegration } from '../../../../../core/domain/CBOTenant';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import IntegrationsHeader from '../../../atoms/integrations/IntegrationsHeader';
import IntegrationDisabled from '../../../molecules/integrations/IntegrationDisabled';
import { createKlaviyoIntegrationViewModel } from './KlaviyoIntegration.viewmodel';

const Container = styled(Box)({
  width: '100%',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  marginTop: theme.spacing(2),
  width: '100%',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  position: 'relative',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '15px',
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    '-webkit-appearance': 'initial',
    width: '7px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    borderRadius: '20px',
  },
  overflow: 'scroll',
  paddingRight: theme.spacing(1),
}));

const StyledTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  cursor: 'pointer',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const API_KEY_LENGTH = 37;

type Inputs = CBOTenantKlaviyoIntegration;

export default function KlaviyoIntegration() {
  const dispatch = useDispatch();
  const viewModel = useSelector(createKlaviyoIntegrationViewModel({ dispatch }));
  const { t } = useTranslation(['integrations', 'common']);

  const {
    fetchLists,
    fetchListsStatus,
    integrations,
    trackUserJourneyEvent,
    isEnabled,
    updateIntegration,
    lists,
    currentTenant,
    updateStatus,
  } = viewModel;

  const [isKeyVisible, setIsKeyVisible] = useState(false);

  const form = useForm<Inputs>({ criteriaMode: 'all', defaultValues: integrations.klaviyo, mode: 'onChange' });
  const { handleSubmit: handleFormSubmit, formState, register, watch } = form;
  const { errors } = formState;

  const { ref: apiKeyInputRef, ...apiKeyInputProps } = register('apiKey', {
    disabled: !isEnabled,
    validate: (value) =>
      value === null || value === '' || value?.length === API_KEY_LENGTH || (t('Klaviyo.ApiKeyInvalidInput') as string),
  });

  const { ref: listIdInputRef, ...listIdInputProps } = register('listId', {
    disabled: !isEnabled,
    required: false,
  });

  const watchApiKey = watch('apiKey');
  const watchListId = watch('listId');

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
      name: 'Klaviyo Integration',
    });
  }, []);

  useEffect(() => {
    if (watchApiKey && watchApiKey.length === API_KEY_LENGTH) {
      fetchLists(watchApiKey);
    }
  }, [watchApiKey]);

  useEffect(() => {
    if (updateStatus === 'success') {
      resetForm();
    }
  }, [updateStatus]);

  useEffect(() => {
    resetForm();
  }, [integrations.klaviyo]);

  const handleRemoveIntegration = () => {
    form.setValue('apiKey', null, { shouldDirty: true });
    form.setValue('listId', null, { shouldDirty: true });
  };

  const handleSelectedListChange = (selectedListId: string) => {
    if (!submissionInProgress) {
      form.setValue('listId', selectedListId, { shouldDirty: true });
    }
  };

  const resetForm = () => {
    form.reset(integrations.klaviyo);
  };

  const handleSubmit = (values: Inputs) => {
    const { apiKey, listId } = values;

    updateIntegration({
      apiKey: apiKey && listId ? apiKey : null,
      listId: apiKey && listId ? listId : null,
    });
  };

  const doesSelectedListExists = useMemo(() => {
    return lists?.findIndex((list) => list.id === watchListId) !== -1;
  }, [lists, watchListId]);

  const sortedLists = useMemo(() => {
    if (!lists) return null;
    return [...lists].sort((a, b) => a.name.localeCompare(b.name));
  }, [lists]);

  const submissionInProgress = updateStatus === 'pending';

  const canSubmit = useMemo(() => {
    if (!!watchApiKey && !watchListId) {
      return false;
    }

    if (watchListId && !doesSelectedListExists) {
      return false;
    }

    if (submissionInProgress || !formState.isValid || !formState.isDirty) {
      return false;
    }

    return true;
  }, [watchApiKey, watchListId, submissionInProgress, formState.isValid, formState.isDirty, doesSelectedListExists]);

  const isRemoveButtonShown = useMemo(
    () => integrations.klaviyo.apiKey && integrations.klaviyo.listId && !!watchApiKey && !!watchListId,
    [integrations.klaviyo, watchApiKey, watchListId],
  );

  return (
    <Container>
      <IntegrationsHeader title={t('Klaviyo.Klaviyo')} />
      <ContentContainer>
        <StyledPaper>
          {!isEnabled ? (
            <IntegrationDisabled tenantName={currentTenant.name} text={t('Klaviyo.IntegrationNotEnabled')} />
          ) : (
            <Box p={6} pb={4}>
              <form noValidate onSubmit={handleFormSubmit(handleSubmit)} style={{ width: '100%' }}>
                <InputContainer>
                  <TextField
                    {...apiKeyInputProps}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setIsKeyVisible(!isKeyVisible)}>
                          {isKeyVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                      type: isKeyVisible ? 'text' : 'password',
                    }}
                    error={!!errors.apiKey}
                    fullWidth
                    helperText={!!errors.apiKey ? errors?.apiKey?.message : ''}
                    inputRef={apiKeyInputRef}
                    label={t('Klaviyo.ApiKey')}
                    required
                    variant="outlined"
                  />
                  <InputTooltip content={t('Klaviyo.ApiKeyHelper')} />
                  <FormHelperText>{`${watchApiKey?.length ?? 0} / ${API_KEY_LENGTH}`}</FormHelperText>
                </InputContainer>
                {fetchListsStatus === 'pending' ? (
                  <Box py={4}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {fetchListsStatus === 'error' && (
                      <Box mb={2}>
                        <Typography color="error">{t('Klaviyo.ApiKeyInvalid')}</Typography>
                      </Box>
                    )}

                    {watchListId && !doesSelectedListExists && (
                      <Box mb={2}>
                        <Typography color="error">{t('Klaviyo.ListInvalid')}</Typography>
                      </Box>
                    )}

                    {watchApiKey?.length === API_KEY_LENGTH && sortedLists && (
                      <>
                        <Box m={2}>
                          <Typography fontSize="1em">{t('Klaviyo.Info')}</Typography>
                        </Box>
                        <StyledTableContainer sx={{ maxHeight: '300px', mb: 2 }}>
                          <Table id="product-list-table">
                            <TableBody>
                              {sortedLists.map((list, index) => (
                                <StyledTableRow key={index} onClick={() => handleSelectedListChange(list.id)}>
                                  <StyledTableCell>
                                    <Radio
                                      {...listIdInputProps}
                                      checked={watchListId === list.id}
                                      inputRef={listIdInputRef}
                                      size="small"
                                      value={list.id}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ flex: 1 }}>{list.name}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </StyledTableContainer>
                      </>
                    )}
                  </>
                )}

                <ButtonsContainer mt={3}>
                  {isRemoveButtonShown && (
                    <Button color="primary" onClick={handleRemoveIntegration} variant="outlined">
                      <span>{t('common:Remove')}</span>
                    </Button>
                  )}

                  <Button color="primary" disabled={!canSubmit} type="submit" variant="contained">
                    <span>{t('common:Save')}</span>
                  </Button>
                </ButtonsContainer>
              </form>
            </Box>
          )}
        </StyledPaper>
      </ContentContainer>
    </Container>
  );
}
