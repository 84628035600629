import { Grid, LinearProgress, Table, TableBody, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CatalogSortType } from '../../../../core/store/state/catalog';
import { QueryStatus } from '../../../../core/store/state/utils';
import ProductListHeader from '../../molecules/shoppables/ProductListHeader';
import ProductRow from '../../molecules/shoppables/ProductRow';

type ProductListProps = {
  currency: CBOCurrency;
  onChangeSort: (sort: CatalogSortType) => void;
  onSearchProducts: (search: string) => void;
  onSelect: (product: CBOCatalogProduct) => void;
  products: CBOCatalogProduct[];
  productsFetchingStatus: QueryStatus;
  sort: CatalogSortType;
};

const TableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  marginTop: theme.spacing(2),
  width: '100%',
}));

export default function ProductList({
  currency,
  onChangeSort,
  onSearchProducts,
  onSelect,
  products,
  productsFetchingStatus,
  sort,
}: ProductListProps) {
  const { t } = useTranslation(['products', 'common']);

  const isPending = () => productsFetchingStatus === 'pending';

  const isProductsListEmpty = products.length === 0;

  const renderProductsList = () =>
    isProductsListEmpty ? (
      <Box style={{ marginTop: 20 }}>
        <Typography>{t('administration:Catalog.NoProducts')}</Typography>
      </Box>
    ) : (
      <TableContainer>
        <Table>
          <TableBody>
            {products.map((product, index) => (
              <ProductRow
                currency={currency}
                index={index}
                key={product.id}
                onClick={() => onSelect(product)}
                product={product}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  const renderProgress = () => {
    return <LinearProgress />;
  };

  return (
    <Box id="product-list-root">
      <Grid container justifyContent="center">
        <Grid gap={2} item lg={10} mt={1.5} xs={12}>
          <Grid container flex={1}>
            <ProductListHeader
              isProductsListEmpty={isProductsListEmpty}
              onChangeSort={onChangeSort}
              onSearchProduct={onSearchProducts}
              sort={sort}
            />
            {isPending() ? renderProgress() : renderProductsList()}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
