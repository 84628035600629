import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function CatalogIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21.536 21.957">
      <path
        d="M130.544,38.921a2.352,2.352,0,0,0-1.772-.784H111.951a2.352,2.352,0,0,0-2.353,2.674l1.921,14.115a2.352,2.352,0,0,0,2.353,2.031h12.939a2.352,2.352,0,0,0,2.352-2.031l1.921-14.115a2.352,2.352,0,0,0-.541-1.89Zm-2.917,15.762a.784.784,0,0,1-.784.682H113.88a.784.784,0,0,1-.784-.682L111.175,40.6a.785.785,0,0,1,.776-.894h16.8a.784.784,0,0,1,.784.886Zm-15.119-18.9a.784.784,0,0,1,.784-.784h14.115a.784.784,0,1,1,0,1.568H113.292a.784.784,0,0,1-.784-.784Zm7.842,7.842a3.921,3.921,0,1,0,2.772,1.148A3.921,3.921,0,0,0,120.349,43.626Zm0,6.273a2.352,2.352,0,1,1,1.664-.689A2.353,2.353,0,0,1,120.349,49.9Z"
        strokeWidth={0}
        transform="translate(-109.576 -35)"
      />
    </SvgIcon>
  );
}
