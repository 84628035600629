import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Theme,
  styled,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { FetchProductsFilters } from '../../../../../core/gateways/catalog-gateway';
import { State } from '../../../../../core/store';
import { CatalogSortType, EVENT_PRODUCTS_PAGINATION_LIMIT } from '../../../../../core/store/state/catalog';
import { fetchProducts } from '../../../../../core/usecases/catalog/fetch-products';
import ProductList from '../ProductList';
import { createAddProductToShoppableDialogViewModel } from './AddProductToShoppableDialog.viewmodel';

const Root = styled(Box)({
  flexGrow: 1,
});

const Title = styled(DialogTitle)({
  fontSize: '2.25em',
  fontWeight: 'bold',
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '20px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  margin: `0 ${theme.spacing(2)}`,
  marginTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

export type AddProductToShoppableDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelectProduct: (product: CBOCatalogProduct) => void;
};

export default function AddProductToShoppableDialog({
  isOpen,
  onClose,
  onSelectProduct,
}: AddProductToShoppableDialogProps) {
  const dispatch = useDispatch();
  const mdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { t } = useTranslation('shoppables', { keyPrefix: 'AddProduct' });

  // TODO: Custom state
  const viewModel = useSelector(createAddProductToShoppableDialogViewModel({ dispatch: useDispatch() }));

  const {
    pagination: { offset },
    search,
  } = useSelector((state: State) => state.catalog);

  const {
    changePage,
    currency,
    currentPage,
    fetchProductsWithoutFilters,
    pageCount,
    products,
    productsFetchingStatus,
    searchProducts,
    sort,
    tenantId,
  } = viewModel;

  useEffect(() => {
    fetchProductsWithoutFilters();
  }, [tenantId]);

  const changeFilters = (newFilters: Partial<FetchProductsFilters>) => {
    const filters = { limit: EVENT_PRODUCTS_PAGINATION_LIMIT, offset, search, sort, tenantId };
    dispatch(fetchProducts({ ...filters, ...newFilters }));
  };

  const handleChangePage = (page: number) => {
    changePage(page);
  };

  const handleChangeSort = (sort: CatalogSortType) => {
    changeFilters({ sort });
  };

  const handleSearchProducts = (search: string) => {
    searchProducts(search);
  };

  const handleClose = () => {
    onClose();
    searchProducts('');
  };

  const handleSelectProduct = (product: CBOCatalogProduct) => {
    onSelectProduct(product);
    handleClose();
  };

  return (
    <StyledDialog
      PaperProps={{ sx: { borderRadius: '20px', maxWidth: mdScreen ? '80%' : '60%' } }}
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <Title>{t('SelectProduct')}</Title>
      <DialogContent>
        <Root flexGrow={1} id="product-list">
          <ProductList
            currency={currency}
            onChangeSort={handleChangeSort}
            onSearchProducts={handleSearchProducts}
            onSelect={(product: CBOCatalogProduct) => handleSelectProduct(product)}
            products={products}
            productsFetchingStatus={productsFetchingStatus}
            sort={sort}
          />
        </Root>
      </DialogContent>
      <DialogActions>
        <PaginationContainer>
          <Pagination
            count={pageCount}
            hidden={pageCount <= 1 || productsFetchingStatus === 'pending'}
            onChange={(_e, page) => handleChangePage(page)}
            page={currentPage}
          />
        </PaginationContainer>
      </DialogActions>
    </StyledDialog>
  );
}
