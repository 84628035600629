import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, ListItem, Theme, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import ProductHighlightCard from './ProductHighlightCard';

interface LiveNotDisplayedProductRowProps {
  currency: CBOCurrency;
  disabled?: boolean;
  onClick: () => void;
  product: CBOEventReadModelProduct;
}

const Container = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'disabled' })(
  ({ disabled, theme }: { disabled?: boolean; theme: Theme }) => ({
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: theme.spacing(4),
    width: '100%',
    ...(disabled && {
      pointerEvents: 'none',
    }),
  }),
);

export default function LiveNotDisplayedProductRow({
  currency,
  disabled,
  onClick,
  product,
}: LiveNotDisplayedProductRowProps) {
  const { t } = useTranslation(['events', 'common']);

  return (
    <Container disabled={disabled} key={product.id} onClick={disabled ? undefined : onClick}>
      <Box alignItems="center" display="flex" width="100%">
        <Box flexGrow={1}>
          <ProductHighlightCard currency={currency} product={product} />
        </Box>
        <Box px={2} textAlign="center">
          <Button
            disabled={disabled}
            startIcon={
              <VisibilityIcon
                sx={{
                  color: disabled ? 'primary.grey' : 'primary.dark',
                }}
              />
            }
            sx={{
              '&:hover': {
                backgroundColor: 'grey.300',
              },
              backgroundColor: 'grey.100',
              color: 'primary.dark',
            }}
            variant="contained"
          >
            {t('common:Display')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
