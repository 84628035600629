import { Box, styled } from '@mui/material';
import React from 'react';

export interface ActionDialogProps {
  actionButton: React.ReactNode;
  content: string;
  open: boolean;
  title: string;
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  bottom: 0,
  flexGrow: 0,
  height: 'fit-content',
  left: 0,
  maxWidth: '350px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  position: 'sticky',
  textAlign: 'initial',
  top: 'calc(50% - 100px)',
}));

export default function ActionDialog(props: ActionDialogProps) {
  const { actionButton, content, open, title } = props;

  if (!open) return null;

  return (
    <Container boxShadow={2}>
      <h3>{title}</h3>
      <p>{content}</p>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>{actionButton}</Box>
    </Container>
  );
}
