import { Adjust } from '@mui/icons-material';
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PreviewChatLogoPosition from '../../../atoms/event/themePreview/PreviewChatLogoPosition';
import PreviewLogoPosition from '../../../atoms/event/themePreview/PreviewLogoPosition';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import ReactHookFormImageSelector from '../../../atoms/inputs/ReactHookFormImageSelector';
import { ThemeInputs } from '../../../organisms/event/event-details/ThemeForm';
import SettingsSection from '../../SettingsSection';

interface LogoSettingsSectionProps {
  defaultAdminMessageIconUrl: string;
  defaultBrandLogoUrl: string;
  errors: any;
  onResetAdminMessageIcon: () => void;
  onResetBrandLogo: () => void;
  onSetAdminMessageIconPreview: (url: string) => void;
  onSetBrandLogoPreview: (url: string) => void;
  readonly: boolean;
  register: UseFormRegister<ThemeInputs>;
  roundTopBorder: boolean;
  submissionInProgress: boolean;
  watch: UseFormWatch<ThemeInputs>;
}

const ImageSelectorContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
});

const ImageSelectorLabel = styled(Typography)({
  display: 'block',
  fontSize: '.8em',
  fontWeight: 'bold',
  textAlign: 'initial',
  width: '100px',
});

export default function LogoSettingsSection(props: LogoSettingsSectionProps) {
  const {
    defaultAdminMessageIconUrl,
    defaultBrandLogoUrl,
    errors,
    readonly,
    register,
    roundTopBorder,
    onResetBrandLogo,
    onResetAdminMessageIcon,
    onSetBrandLogoPreview,
    onSetAdminMessageIconPreview,
    submissionInProgress,
    watch,
  } = props;
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();
  const disabled = readonly || submissionInProgress;

  return (
    <SettingsSection
      altBackground
      contentWidth={11}
      icon={<Adjust fontSize="large" sx={{ color: theme.palette.common.white }} />}
      roundTop={roundTopBorder}
      title={t('Logos')}
    >
      <Grid container direction={'row'} gap={3} justifyContent="center">
        <Grid item position="relative" xs={12}>
          <ImageSelectorContainer>
            <ImageSelectorLabel>{t('BrandLogo')}</ImageSelectorLabel>
            <PreviewLogoPosition sx={{ height: '80px', width: 'auto' }} />
            <ReactHookFormImageSelector
              defaultUrl={defaultBrandLogoUrl}
              disabled={disabled}
              errorText={errors?.brandLogo?.message}
              fieldName="brandLogo"
              key={defaultBrandLogoUrl}
              label={t('BrandLogo')}
              maxSizeInKb={50}
              onReset={onResetBrandLogo}
              onUpdateThumbnail={onSetBrandLogoPreview}
              readonly={readonly}
              register={register}
              watch={watch}
            />
          </ImageSelectorContainer>
          <InputTooltip content={t('helpers.LiveHeaderLogo')} />
        </Grid>
        <Grid item position="relative" xs={12}>
          <ImageSelectorContainer>
            <ImageSelectorLabel>{t('AdminMessageIcon')}</ImageSelectorLabel>
            <PreviewChatLogoPosition sx={{ height: '80px', width: 'auto' }} />
            <ReactHookFormImageSelector
              defaultUrl={defaultAdminMessageIconUrl}
              disabled={submissionInProgress || readonly}
              errorText={errors?.adminMessageIcon?.message}
              fieldName="adminMessageIcon"
              key={defaultAdminMessageIconUrl}
              label={t('AdminMessageIcon')}
              maxSizeInKb={50}
              onReset={onResetAdminMessageIcon}
              onUpdateThumbnail={onSetAdminMessageIconPreview}
              readonly={readonly}
              register={register}
              watch={watch}
            />
          </ImageSelectorContainer>
          <InputTooltip content={t('helpers.ChatIcon')} />
        </Grid>
      </Grid>
    </SettingsSection>
  );
}
