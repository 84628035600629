import { useEffect } from 'react';

import { CommandQueryResult } from '../../core/store/state/utils';
import { SnackbarMessageMap } from '../../utils/get-display-snackbar';

export type DisplaySnackbarType = (map: SnackbarMessageMap, data: CommandQueryResult) => void;

const useDisplaySnackbar = (
  displaySnackbar: DisplaySnackbarType,
  result: CommandQueryResult,
  snackbarMessage: SnackbarMessageMap,
): void => {
  useEffect(() => {
    displaySnackbar(snackbarMessage, result);
  }, [displaySnackbar, result]);
};

export default useDisplaySnackbar;
