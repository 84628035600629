import { User } from '@auth0/auth0-spa-js';
import { EventId, FirestoreCollectionNames, TenantId, V1 } from '@bellepoque/api-contracts';
import { FirebaseApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { CollectionReference, Firestore, collection, doc, getFirestore } from 'firebase/firestore';
import { collectionData, docData } from 'rxfire/firestore';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CBOEventListReadModel, toCBOEventListReadModels } from '../../core/domain/CBOEventListReadModel';
import { CBOEventReadModel, toCBOEventReadModel } from '../../core/domain/CBOEventReadModel';
import { CBORealtimeData } from '../../core/domain/CBORealtimeData';
import {
  CBOShoppableVideoListItemReadModel,
  CBOShoppableVideoListReadModel,
  toCBOShoppableVideoListItemReadModel,
  toCBOShoppableVideoListReadModel,
} from '../../core/domain/CBOShoppableVideoReadModel';
import { RealtimeDataGateway } from '../../core/gateways/realtime-data-gateway';

export class RealtimeDataFirebaseGateway implements RealtimeDataGateway {
  private realTimeDataCollection: CollectionReference;
  private readonly firestore: Firestore;

  constructor(private app: FirebaseApp) {
    this.firestore = getFirestore(this.app);
    this.realTimeDataCollection = collection(this.firestore, FirestoreCollectionNames.liveShows);
  }

  signInWithCustomToken(user: User, customToken?: string): Observable<User> {
    if (!customToken) {
      return of(user);
    }

    const auth = getAuth(this.app);

    // custom token expires in 1h (fixed by Firebase) but refresh is handled transparently by the lib so it is ok
    return from(
      signInWithCustomToken(auth, customToken)
        .then(() => user)
        .catch((error) => {
          console.log('error while signing with firebase', error);
          throw error;
        }),
    );
  }

  signOut(): Promise<void> {
    const auth = getAuth(this.app);
    return auth.signOut();
  }

  getRealtimeData(eventId: EventId): Observable<CBORealtimeData> {
    return docData(doc(this.realTimeDataCollection, eventId)).pipe(
      map((realtimeData: any) => ({
        ...realtimeData,
        productHighlights: Array.isArray(realtimeData?.productHighlights) ? realtimeData.productHighlights : [],
      })),
    );
  }

  getEventReadModel(props: { eventId: EventId; tenantId: TenantId }): Observable<CBOEventReadModel | null> {
    return docData(
      doc(
        collection(
          this.firestore,
          FirestoreCollectionNames.tenants,
          props.tenantId,
          FirestoreCollectionNames.eventReadModels,
        ),
        props.eventId,
      ),
    ).pipe(map((dto: any) => (dto ? toCBOEventReadModel(dto) : null)));
  }

  getEventListReadModels(props: { tenantId: TenantId }): Observable<CBOEventListReadModel[]> {
    return docData(doc(collection(this.firestore, FirestoreCollectionNames.tenants), props.tenantId)).pipe(
      map((tenant: any) => toCBOEventListReadModels(tenant.eventList)),
    );
  }

  getShoppableVideoListItemReadModel(props: {
    shoppableId: string;
    tenantId: TenantId;
  }): Observable<CBOShoppableVideoListItemReadModel | null> {
    return docData(
      doc(
        collection(
          this.firestore,
          FirestoreCollectionNames.tenants,
          props.tenantId,
          FirestoreCollectionNames.shoppableList,
        ),
        props.shoppableId,
      ),
    ).pipe(
      map((dto) => {
        return toCBOShoppableVideoListItemReadModel(dto as V1.cbo.CBOShoppableVideoListItemReadModelDTO);
      }),
    );
  }

  getShoppableVideoListReadModel(props: { tenantId: TenantId }): Observable<CBOShoppableVideoListReadModel> {
    return collectionData(
      collection(
        this.firestore,
        FirestoreCollectionNames.tenants,
        props.tenantId,
        FirestoreCollectionNames.shoppableList,
      ),
    ).pipe(map((dtos) => toCBOShoppableVideoListReadModel(dtos as V1.cbo.CBOShoppableVideoListItemReadModelDTO[])));
  }
}
