import { BillingPlanId, EventId, TCms } from '@bellepoque/api-contracts';
import { Replay } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOEventListReadModel } from '../../core/domain/CBOEventListReadModel';
import { CBOLandingPageData } from '../../core/domain/CBOLandingPageData';
import FixedContentHeader from '../components/molecules/FixedContentHeader';
import Header from '../components/molecules/live-shopping-page/Header';
import LiveShoppingPageConfiguration from '../components/organisms/live-shopping-page/LiveShoppingPageConfiguration';
import LiveShoppingPageCreation from '../components/organisms/live-shopping-page/LiveShoppingPageCreation';

const Root = styled(Box)({
  display: 'flex',
  flexGrow: 1,
});

interface LiveShoppingPageTemplateProps {
  cmsLandingPageCreationInProgress: boolean;
  currentPlanId: BillingPlanId;
  formErrors: any;
  hasCmsLandingPage: boolean;
  hasFetchError: boolean;
  highlightableEvents: CBOEventListReadModel[];
  isDirty: boolean;
  isDrawerOpen: boolean;
  landingPageData: CBOLandingPageData;
  landingPageUrl: string | null;
  loading: boolean;
  maxPublishedReplayDurationInSeconds?: number;
  maxPublishedReplays?: number;
  onCancel: () => void;
  onChangeHighlightedEvents: (eventIds: EventId[]) => void;
  onChangePublishedReplays: (eventIds: EventId[]) => void;
  onCreateCmsLandingPage: () => void;
  onOpenEventCreationModal: () => void;
  onRetryFailedFetch: () => void;
  onSubmit: () => void;
  replayEvents: CBOEventListReadModel[];
  replaySectionTitleProps: any;
  replaySectionTitleRef: RefCallBack;
  tenantCms: TCms;
  tenantName: string;
  updateInProgress: boolean;
}

const ErrorMessageContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'center',
}));

export default function LiveShoppingPageTemplate(props: LiveShoppingPageTemplateProps) {
  const { t } = useTranslation(['common', 'liveShoppingPage']);
  const {
    cmsLandingPageCreationInProgress,
    currentPlanId,
    formErrors,
    hasCmsLandingPage,
    hasFetchError,
    highlightableEvents,
    isDirty,
    isDrawerOpen,
    landingPageData,
    landingPageUrl,
    loading,
    maxPublishedReplayDurationInSeconds,
    maxPublishedReplays,
    onCancel,
    onChangeHighlightedEvents,
    onChangePublishedReplays,
    onCreateCmsLandingPage,
    onOpenEventCreationModal,
    onRetryFailedFetch,
    onSubmit,
    replayEvents,
    replaySectionTitleProps,
    replaySectionTitleRef,
    tenantCms,
    tenantName,
    updateInProgress,
  } = props;

  return (
    <Root id="live-shopping-page">
      <FixedContentHeader isDrawerOpen={isDrawerOpen}>
        <Header landingPageUrl={hasCmsLandingPage ? landingPageUrl : null} loading={loading} />
      </FixedContentHeader>

      {hasFetchError ? (
        <ErrorMessageContainer>
          <Typography>{t('liveShoppingPage:ErrorWhileFetching')}</Typography>
          <Button
            color="secondary"
            disabled={loading}
            onClick={onRetryFailedFetch}
            startIcon={<Replay />}
            variant="contained"
          >
            {t('common:Retry')}
          </Button>
        </ErrorMessageContainer>
      ) : (
        <Box display="block" visibility={loading ? 'hidden' : 'visible'} width={'100%'}>
          <LiveShoppingPageCreation
            isShown={!hasCmsLandingPage}
            loading={loading}
            onCreate={onCreateCmsLandingPage}
            submissionInProgress={cmsLandingPageCreationInProgress}
            tenantCms={tenantCms}
          />

          <LiveShoppingPageConfiguration
            currentPlanId={currentPlanId}
            formErrors={formErrors}
            highlightableEvents={highlightableEvents}
            isDirty={isDirty}
            isFooterShown={hasCmsLandingPage}
            landingPageData={landingPageData}
            loading={loading}
            maxPublishedReplayDurationInSeconds={maxPublishedReplayDurationInSeconds}
            maxPublishedReplays={maxPublishedReplays}
            onCancel={onCancel}
            onChangeHighlightedEvents={onChangeHighlightedEvents}
            onChangePublishedReplays={onChangePublishedReplays}
            onOpenEventCreationModal={onOpenEventCreationModal}
            onSubmit={onSubmit}
            replayEvents={replayEvents}
            replaySectionTitleProps={replaySectionTitleProps}
            replaySectionTitleRef={replaySectionTitleRef}
            submissionInProgress={updateInProgress}
            tenantName={tenantName}
          />
        </Box>
      )}
    </Root>
  );
}
