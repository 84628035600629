import { EventId, V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';
import { resetUpdateEvent as resetUpdateEventAction } from '../../../store/slices/event/event-crud.slice';

type UpdateEventParams = {
  id: EventId;
  payload: V1.api.UpdateEventDTO;
};

export const updateEvent = createAppAsyncThunk(
  'events/update-event',
  async ({ id, payload }: UpdateEventParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.updateOne(id, payload);
  },
);

export const resetUpdateEvent = () => {
  return resetUpdateEventAction();
};
