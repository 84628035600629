import { V1 } from '@bellepoque/api-contracts';

import { CBOMagicContent } from '../../core/domain/CBOMagicContent';

export function toDomain(dto: V1.api.MagicContentDTO): CBOMagicContent {
  if (dto.status === 'failed') {
    return {
      createdAt: new Date(dto.createdAt),
      error: dto.error,
      id: dto.id,
      status: 'failed',
      updatedAt: new Date(dto.updatedAt),
    };
  }

  if (dto.status === 'pending') {
    return {
      createdAt: new Date(dto.createdAt),
      id: dto.id,
      status: 'pending',
    };
  }

  return {
    createdAt: new Date(dto.createdAt),
    htmlContent: dto.content_html,
    htmlTitle: dto.title,
    id: dto.id,
    metaDescriptionSnippet: dto.meta_description_snippet,
    status: 'succeed',
    updatedAt: new Date(dto.updatedAt),
  };
}

export function manyToDomain(dtos: V1.api.MagicContentDTO[]): CBOMagicContent[] {
  return dtos.map(toDomain);
}
