import { Box, Button, Grid, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventStatisticsPreview } from '../../../../core/domain/CBOTenantStatistics';
import EventDateValue from '../../atoms/EventDateValue';

const Value = styled('span')`
  font-size: 0.9em;
  font-weight: 500;
`;
const Title = styled('span')`
  font-weight: 800;
`;
const Container = styled(Box)`
  padding: 20px 0;
  border-bottom: solid #a8a8a8 1px;
`;
const Image = styled('img')`
  height: 40px;
  width: 40px;
  border-radius: 10px;
  object-fit: cover;
`;

interface EventListRowProps {
  event: CBOEventStatisticsPreview;
  onShowReport: () => void;
}

export default function EventListRow({ event, onShowReport }: EventListRowProps) {
  const { t } = useTranslation('analytics', { keyPrefix: 'StatsByEvent' });

  return (
    <Container>
      <Grid alignItems="center" columnGap={8} container>
        <Grid alignItems="center" display="flex" item width="40px">
          <Image src={event.imageUrl} />
        </Grid>
        <Grid display="flex" item xs={1.5}>
          <EventDateValue date={event.showTime} />
        </Grid>
        <Grid item xs={3}>
          <Title>{event.title}</Title>
        </Grid>
        <Grid item xs={1}>
          <Value>{event.productsCount}</Value>
        </Grid>
        <Grid display="flex" flex={1} item justifyContent="flex-end">
          <Button color="primary" onClick={onShowReport} variant="contained">
            {t('ShowReport')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
