import { ShoppableThumbnailFileUpload } from '../../gateways/shoppables-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetUploadShoppableThumbnailAction } from '../../store/slices/shoppables/shoppables-file-upload.slice';

export const uploadShoppableThumbnailFile = createAppAsyncThunk(
  'shoppables/thumbnail/upload-file',
  async (file: ShoppableThumbnailFileUpload, { extra: { shoppablesGateway } }): Promise<void> => {
    const success = await shoppablesGateway.uploadShoppableThumbnailFile(file);
    if (!success) {
      throw new Error('Thumbnail upload failed');
    }
  },
);

export const resetUploadShoppableThumbnail = () => {
  return resetUploadShoppableThumbnailAction();
};
