import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import { CBOEventReadModel, EVENT_PRODUCTS_COUNT_LIMIT } from '../../../../../core/domain/CBOEventReadModel';
import { EventDisplayedTime, EventDuration } from '../../../atoms/event/DisplayedValue';
import HeaderCurrentTime from '../../../atoms/event/HeaderCurrentTime';
import HeaderTitle from '../../../atoms/event/HeaderTitle';
import HeaderProductsActions from '../header-actions/HeaderProductsActions';

interface ProductListHeaderProps {
  event: CBOEventReadModel;
  onAddProduct: () => void;
  readonly: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '4fr 5fr',
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(2),
}));

const LeftSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gap: theme.spacing(2),
}));

const RightSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
}));

export default function ProductListHeader({ event, onAddProduct, readonly }: ProductListHeaderProps) {
  const isEventTimeDisplayed = event.startTime || event.showTime;
  const hasSelectedTooManyProducts = event.products.length >= EVENT_PRODUCTS_COUNT_LIMIT;

  return (
    <Container>
      <LeftSideContainer gridTemplateColumns={isEventTimeDisplayed ? '2fr 3fr' : '4fr 2fr'} id="header-left-side">
        <Box alignItems="center" display="flex" id="header-title-wrapper" textAlign="left">
          <HeaderTitle event={event} />
        </Box>

        <Box display="flex" gap={1} justifyContent="space-evenly">
          <EventDisplayedTime event={event} />
          <EventDuration event={event} />
        </Box>
      </LeftSideContainer>
      <RightSideContainer id="header-right-side">
        <Box id="header-current-time-wrapper">
          <HeaderCurrentTime />
        </Box>
        <Box alignItems="center" flex={1} flexDirection="row" id="header-actions-wrapper" justifyContent="center">
          <HeaderProductsActions
            isAddProductsButtonHidden={hasSelectedTooManyProducts}
            onAddProduct={onAddProduct}
            productsCount={event.products.length}
            readonly={readonly}
          />
        </Box>
      </RightSideContainer>
    </Container>
  );
}
