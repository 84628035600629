import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Paper, TextField, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { httpUrlRegex } from '../../../utils/http-url-regex';

const PREFIX = 'UrlDialog';

const classes = {
  layout: `${PREFIX}-layout`,
  paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.layout}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up(600 + +theme.spacing(2) * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + +theme.spacing(3) * 2)]: {
      padding: theme.spacing(3),
    },
  },
}));

interface SimpleDialogProps {
  defaultUrl?: string;
  id: string;
  label: string;
  onClose: (params: { url: string }) => void;
  open: boolean;
  required: boolean;
}

export default function UrlDialog(props: SimpleDialogProps) {
  const { defaultUrl = '', id, onClose, open, label, required } = props;
  const { t } = useTranslation(['events', 'common']);

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('md'));
  const propDisplayed = `displayedImages-${id}`;
  const methods = useForm<{ [key: string]: string }>({
    defaultValues: {
      [propDisplayed]: defaultUrl,
    },
  });
  const { register, getValues, setValue, trigger, formState } = methods;

  useEffect(() => {
    setValue(propDisplayed, defaultUrl, { shouldDirty: false, shouldValidate: false });
  }, [defaultUrl]);

  const onCancelClose = () => onClose({ url: defaultUrl });
  const onDeleteClose = () => onClose({ url: '' });
  const onOkClose = () => onClose({ url: getValues(propDisplayed) });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const result = await trigger(propDisplayed);
    if (result) {
      onOkClose();
    }
  };

  const { ref: propDisplayedInputRef, ...propDisplayedInputProps } = register(propDisplayed, {
    pattern: {
      message: t('PleaseEnterAValidURL'),
      value: httpUrlRegex,
    },
    required: { message: t('UrlIsRequired'), value: required },
    shouldUnregister: true,
  });

  return (
    <StyledDialog
      className={classes.layout}
      fullWidth
      maxWidth="lg"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancelClose();
        }
      }}
      open={open}
    >
      <form noValidate onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                // disabled={eventProductAdditionStatus === 'pending' || productUpdateStatus === 'pending'}
                autoFocus
                error={!!formState.errors[propDisplayed]}
                fullWidth
                helperText={!!formState.errors[propDisplayed] ? formState.errors[propDisplayed].message : ''}
                id={propDisplayed}
                inputRef={propDisplayedInputRef}
                label={label}
                required={required}
                variant="outlined"
                {...propDisplayedInputProps}
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent={xsScreen ? 'center' : 'flex-end'} spacing={2}>
                <Grid item sm={required ? 3 : 2} xs={8}>
                  <Button
                    color="primary"
                    fullWidth
                    onClick={() => onCancelClose()}
                    startIcon={<ClearIcon />}
                    variant="contained"
                  >
                    <span>{t('common:Cancel')}</span>
                  </Button>
                </Grid>

                {!required && (
                  <Grid item sm={2} xs={8}>
                    <Button
                      color="error"
                      fullWidth
                      onClick={() => onDeleteClose()}
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                    >
                      <span>{t('common:Delete')}</span>
                    </Button>
                  </Grid>
                )}
                <Grid item sm={required ? 3 : 2} xs={8}>
                  <Button color="primary" fullWidth startIcon={<SaveIcon />} type="submit" variant="contained">
                    <span>{t('common:Ok')}</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </StyledDialog>
  );
}
