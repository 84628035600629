import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type PublishReplayParams = {
  eventId: EventId;
};

export const publishReplay = createAppAsyncThunk(
  'events/publish-replay',
  async ({ eventId }: PublishReplayParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.publishOneReplay(eventId);
  },
);
