import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export default function SettingsIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 16.492 26.666" {...props}>
      <g transform="translate(-680.552 -188.997)">
        <g transform="translate(680.552 188.997)">
          <path
            d="M35.434,4H24.159A2.609,2.609,0,0,0,21.55,6.609V28.058a2.609,2.609,0,0,0,2.609,2.609H35.434a2.609,2.609,0,0,0,2.609-2.609V6.609A2.609,2.609,0,0,0,35.434,4ZM24.159,5.739H35.434a.87.87,0,0,1,.87.87V7.4H23.289V6.609A.87.87,0,0,1,24.159,5.739ZM36.3,9.139V24.168H23.289V9.139Zm-.87,19.788H24.159a.87.87,0,0,1-.87-.87V25.907H36.3v2.151A.87.87,0,0,1,35.434,28.927Z"
            fill={theme.palette.common.white}
            transform="translate(-21.55 -4)"
          />
        </g>
        <path
          d="M5.94,4.5A5.311,5.311,0,0,0,1,7.868a5.307,5.307,0,0,0,9.88,0A5.311,5.311,0,0,0,5.94,4.5Zm0,5.614A2.246,2.246,0,1,1,8.186,7.868,2.246,2.246,0,0,1,5.94,10.114Zm0-3.593A1.347,1.347,0,1,0,7.287,7.868,1.345,1.345,0,0,0,5.94,6.521Z"
          fill={theme.palette.common.white}
          transform="translate(682.857 194.16)"
        />
      </g>
    </SvgIcon>
  );
}
