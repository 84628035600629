import { COMPLETED_ONBOARDING_PROGRESS, OnboardingProgress } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const skipOnboarding = createAppAsyncThunk(
  'onboarding/skip-onboarding',
  async (_, { extra: { onboardingGateway } }): Promise<OnboardingProgress> => {
    const progress = COMPLETED_ONBOARDING_PROGRESS;
    await onboardingGateway.updateProgress(progress);
    return progress;
  },
);
