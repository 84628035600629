import { miniSerializeError } from '@reduxjs/toolkit';
import { from, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, retry, switchMap } from 'rxjs/operators';

import { AppEpic } from '../../..';
import {
  synchronizeOneEventFulfilled,
  synchronizeOneEventPending,
  synchronizeOneEventRejected,
} from '../../../slices/event/event-synchronize.slice';
import { ofType } from '../../utils';

export const synchronizeEventEpic: AppEpic = (
  action$,
  state$,
  { container: { eventGateway, realtimeDataGateway } },
) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeOneEventPending>>(synchronizeOneEventPending.type),
    distinctUntilChanged((prev, cur) => prev.payload.eventId === cur.payload.eventId),
    switchMap((action) => {
      return realtimeDataGateway
        .getEventReadModel({ eventId: action.payload.eventId, tenantId: action.payload.tenantId })
        .pipe(
          retry({ delay: () => timer(2000) }), // reconnect to firebase if it disconnects
          switchMap((readModel) => {
            if (readModel) return of(readModel);

            return from(eventGateway.fetchOne(action.payload.eventId)).pipe(
              switchMap((eventDto) => {
                if (!eventDto) throw new Error('Event DTO not found');
                return realtimeDataGateway
                  .getEventReadModel({
                    eventId: action.payload.eventId,
                    tenantId: eventDto.tenantId,
                  })
                  .pipe(
                    map((readModelWithCorrectTenantId) => {
                      if (!readModelWithCorrectTenantId) throw new Error('Event read model not found');
                      return readModelWithCorrectTenantId;
                    }),
                    retry({ delay: () => timer(500) }),
                  );
              }),
            );
          }),
        );
    }),
    map((eventReadModel) => synchronizeOneEventFulfilled(eventReadModel)),
    catchError((error) => of(synchronizeOneEventRejected([miniSerializeError(error)]))),
    distinctUntilChanged((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur)),
  );
};
