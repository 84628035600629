import { V1 } from '@bellepoque/api-contracts';
import { Observable, firstValueFrom, forkJoin, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import {
  DeleteShoppableParams,
  FetchShoppableFileUploadUrlParams,
  FetchShoppableVideoThumbnailFileUploadUrlParams,
  GetVideoFromSocialMediaResponse,
  NotifyFileUploadedParams,
  ShoppableSettingsFileUpload,
  ShoppableThumbnailFileUpload,
  ShoppablesGateway,
  ShoppablesSettingsFilesUploads,
  UpdateShoppableVideoParams,
  UploadedAssetsNames,
} from '../../core/gateways/shoppables-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiShoppablesGateway extends ApiGateway implements ShoppablesGateway {
  checkPlayerPlaylistsBlockIsAdded(tenantId: string): Promise<boolean> {
    return firstValueFrom(
      this.authenticatedJsonQuery<boolean>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/player-playlists-block`,
      }),
    );
  }

  async create(dto: V1.api.CreateShoppableVideoDTO, tenantId: string): Promise<V1.api.ShoppableVideoDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.ShoppableVideoDTO>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos`,
        verb: 'post',
      }),
    );
  }

  async deleteOne({ shoppableId, tenantId }: DeleteShoppableParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos/${shoppableId}`,
        verb: 'delete',
      }),
    );
  }

  getShoppablesVideoFileUploadUrl({ shoppableId, tenantId }: FetchShoppableFileUploadUrlParams): Promise<string> {
    const url = `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos/${shoppableId}/file-upload-url`;

    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ShoppableVideoFileUploadUrlDTO>({ url }).pipe(map((dto) => dto.url)),
    );
  }

  getShoppablesSettingsFileUploadUrls(tenantId: string): Promise<V1.api.ShoppableThemeFileUploadUrlsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ShoppableThemeFileUploadUrlsDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos-settings-file-upload-urls`,
      }),
    );
  }

  getShoppableThumbnailFileUploadUrl({
    shoppableId,
    tenantId,
  }: FetchShoppableVideoThumbnailFileUploadUrlParams): Promise<V1.api.ShoppableVideoThumbnailFileUploadUrlDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ShoppableVideoThumbnailFileUploadUrlDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos/${shoppableId}/thumbnail-file-upload-url`,
      }),
    );
  }

  getVideoFromSocialMedia(url: string): Promise<GetVideoFromSocialMediaResponse> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.GetVideoFromSocialMediaUrlResponseDTO>({
        params: { url },
        url: `${this.apiEndpoint}/video-from-social-media-url`,
      }),
    );
  }

  notifyFileUploaded({ fileUrl, shoppableId, tenantId }: NotifyFileUploadedParams): Promise<void> {
    return firstValueFrom(
      this.authenticatedCommand({
        body: { fileUrl },
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos/${shoppableId}/file-upload-completed`,
        verb: 'post',
      }),
    );
  }

  fetchShoppablesSettings(tenantId: string): Promise<V1.api.ShoppablesSettingsDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.ShoppablesSettingsDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos-settings`,
      }),
    );
  }

  uploadFile(file: File, fileUploadUrl: string): Promise<void> {
    const method = 'PUT';

    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open(method, fileUploadUrl, true);

      xhr.setRequestHeader('Content-Type', file.type);
      // xhr.setRequestHeader('Content-Length', file.size.toString());

      xhr.onload = function () {
        if (this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send(file);
    });
  }

  updateShoppablesSettings(
    tenantId: string,
    dto: V1.api.UpdateShoppablesSettingsDTO,
  ): Promise<V1.api.ShoppablesSettingsDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.ShoppablesSettingsDTO>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos-settings`,
        verb: 'put',
      }),
    );
  }

  updateShoppableVideo({ shoppableId, tenantId, dto }: UpdateShoppableVideoParams): Promise<V1.api.ShoppableVideoDTO> {
    return firstValueFrom(
      this.authenticatedCommand<V1.api.ShoppableVideoDTO>({
        body: dto,
        url: `${this.apiEndpoint}/tenants/${tenantId}/shoppable-videos/${shoppableId}`,
        verb: 'put',
      }),
    );
  }

  uploadShoppableThumbnailFile(file: ShoppableThumbnailFileUpload): Promise<boolean> {
    return firstValueFrom(
      ajax
        .put(file.url, file.file, {
          'Content-Length': file.file.size.toString(),
          'Content-Type': file.file.type,
          'x-ms-blob-type': 'BlockBlob',
        })
        .pipe(
          map((ajaxResponse) => {
            if (ajaxResponse.status >= 200 && ajaxResponse.status < 300) {
              return true;
            }
            console.error(`error while uploading file ${file.file.name}`);
            console.error(ajaxResponse);
            return false;
          }),
          catchError((error) => {
            console.error(`error while uploading file ${file.file.name}`);
            console.error(error);
            return of(false);
          }),
        ),
    );
  }

  uploadShoppablesSettingsFiles(files: ShoppablesSettingsFilesUploads): Promise<UploadedAssetsNames> {
    const observables = Object.keys(files).reduce((acc: Record<string, Observable<boolean>>, assetName: string) => {
      const file: ShoppableSettingsFileUpload = files[assetName as keyof ShoppablesSettingsFilesUploads]!;

      acc[assetName] = ajax
        .put(file.url, file.file, {
          'Content-Length': file.file.size.toString(),
          'Content-Type': file.file.type,
          'x-ms-blob-type': 'BlockBlob',
        })
        .pipe(
          map((ajaxResponse) => {
            if (ajaxResponse.status >= 200 && ajaxResponse.status < 300) {
              return true;
            }
            console.error(`error while uploading file ${file.file.name}`);
            console.error(ajaxResponse);
            return false;
          }),
          catchError((error) => {
            console.error(`error while uploading file ${file.file.name}`);
            console.error(error);
            return of(false);
          }),
        );

      return acc;
    }, {});

    return firstValueFrom(forkJoin(observables));
  }
}
