import { Grid, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModel } from '../../../core/domain/CBOEventReadModel';
import DisplayedValue, { EventDisplayedTime, EventDuration } from '../atoms/event/DisplayedValue';

const Image = styled('img')({
  borderRadius: '20px',
  height: '170px',
  objectFit: 'cover',
  width: '170px',
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  padding: `${theme.spacing(4)}`,
}));

const Title = styled(Typography)({
  color: 'black',
  fontSize: '1.4em',
  fontWeight: 'bold',
  marginBottom: '5px',
  marginTop: '0',
  textalign: 'left',
});

interface EventStatisticsHeaderProps {
  event: CBOEventReadModel;
}

export default function EventStatisticsHeader({ event }: EventStatisticsHeaderProps) {
  const { t } = useTranslation('events');

  return (
    <Container boxShadow={2} id="event-header" py={2}>
      <Grid container>
        <Grid alignItems="center" display="flex" gap={4} item md={10} xs={12}>
          <Image src={event.theme.preEventCoverUrl} />
          <Box display="flex" flex={1} flexDirection="column" height="100%" justifyContent="space-evenly">
            <Box>
              <Title variant="h2">{event.title}</Title>
            </Box>
            <Box display="flex" gap={4}>
              <EventDisplayedTime event={event} />
              <EventDuration event={event} />
              <DisplayedValue title={t('PublisherPhone')} value={event.publisherPhone} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
