import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOEventReadModelPinnedMessage } from '../../../../../core/domain/CBOEventReadModel';
import ChatLoadingHeader from '../../../molecules/event/live-manager/ChatLoadingHeader';
import { createChatViewModel } from './Chat.viewmodel';
import { CometChatMessages } from './comet-src';

interface ChatProps {
  onPinMessage: (content: string, messageId: string) => void;
  onUnpinMessage: () => void;
  pinnedChatMessage: CBOEventReadModelPinnedMessage | null;
}

export default function Chat({ onPinMessage, onUnpinMessage, pinnedChatMessage }: ChatProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { i18n } = useTranslation();

  const viewModel = useSelector(createChatViewModel({ dispatch: useDispatch() }));
  const { chatCredentials, eventId, fetchEventChatCredentials, pinMessageStatus, unpinMessageStatus } = viewModel;

  useEffect(() => {
    fetchEventChatCredentials();
    initChat();
  }, []);

  useEffect(() => {
    if (chatCredentials && !isLoggedIn) {
      loginChat(chatCredentials.authToken);
    }
  }, [chatCredentials, isLoggedIn]);

  const initChat = async () => {
    try {
      await viewModel.initChat();
    } catch (error) {
      console.error('Chat initialization failed with error:', error);
    }
  };
  const loginChat = async (authToken: string) => {
    try {
      await viewModel.loginChat(authToken);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Chat login failed with error:', error);
    }
  };

  const handlePinMessage = (message: any) => {
    if (pinMessageStatus !== 'pending') onPinMessage(message.text, message.id);
  };

  const handleUnpinMessage = () => {
    if (unpinMessageStatus !== 'pending') onUnpinMessage();
  };

  const renderChat = () => {
    if (!chatCredentials || !isLoggedIn) {
      return <ChatLoadingHeader />;
    }

    return (
      <CometChatMessages
        chatWithGroup={eventId}
        lang={i18n.resolvedLanguage}
        onPinMessage={handlePinMessage}
        onUnpinMessage={handleUnpinMessage}
        pinnedMessageId={pinnedChatMessage?.messageId}
      />
    );
  };

  return renderChat();
}
