import { EventId } from '@bellepoque/api-contracts';

import { ReplayFilesUpload, UploadedReplayAssetNames } from '../../../gateways/event-gateway';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type UploadReplayFilesParams = {
  eventId: EventId;
  files: ReplayFilesUpload;
};

export const uploadReplayFiles = createAppAsyncThunk(
  'events/upload-replay-files',
  async (
    { eventId, files }: UploadReplayFilesParams,
    { extra: { eventGateway } },
  ): Promise<UploadedReplayAssetNames> => {
    return eventGateway.uploadReplayFiles(eventId, files);
  },
);
