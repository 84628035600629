import { Check, Circle } from '@mui/icons-material';
import { Box, StepIconProps, Typography, styled } from '@mui/material';
import React from 'react';

const Container = styled(Box)<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: theme.palette.common.white,
  display: 'flex',
  height: 50,
  justifyContent: 'center',
  width: 50,
  zIndex: 1,
  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(!ownerState.active &&
    !ownerState.completed && {
      backgroundColor: theme.palette.common.white,
      border: `solid ${theme.palette.grey[300]} 5px`,
      color: theme.palette.common.black,
    }),
}));

export default function StepperIcon(props: StepIconProps) {
  const { active, completed, icon: iconNumber } = props;

  const renderIcon = () => {
    if (active) return <Circle sx={{ fontSize: '13px' }} />;
    if (completed) return <Check sx={{ fontSize: '2em' }} />;
    return (
      <Typography fontSize="1.3em" variant="titleFont">
        {iconNumber}
      </Typography>
    );
  };

  return <Container ownerState={{ active, completed }}>{renderIcon()}</Container>;
}
