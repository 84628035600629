import React from 'react';

export default function DraggableIcon() {
  return (
    <img
      alt="position-icon"
      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiBmaWxsPSIjMDAwMDAwIj4KCiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHJlY3QgaWQ9InN2Z18zIiBoZWlnaHQ9IjIiIHdpZHRoPSIyNSIgeT0iMCIgeD0iMCIvPgogIDxyZWN0IHN0cm9rZT0ibnVsbCIgaWQ9InN2Z182IiBoZWlnaHQ9IjIiIHdpZHRoPSIyNSIgeT0iOCIgeD0iMCIvPgogIDxyZWN0IGlkPSJzdmdfNyIgaGVpZ2h0PSIyIiB3aWR0aD0iMjUiIHk9IjQiIHg9IjAiLz4KIDwvZz4KPC9zdmc+"
    />
  );
}
