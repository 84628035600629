import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  styled,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getUrlToTenantShopifyApp } from '../../../utils/get-url-to-tenant-shopify-app';

const ButtonText = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
interface ShopifyScopesModalProps {
  isOpen: boolean;
  onClose: () => void;
  tenantName: string;
}

export default function ShopifyScopesModal({ isOpen, onClose, tenantName }: ShopifyScopesModalProps) {
  const { t } = useTranslation(['shopifyScopes', 'common']);
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle> {t('Title')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{t('Text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link href={getUrlToTenantShopifyApp(tenantName)} target="_blank">
          <Button color="primary" onClick={onClose} variant="contained">
            <OpenInNew />
            <ButtonText> {t('common:Update')}</ButtonText>
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}
