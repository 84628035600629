import { YoutubeAccessToken, YoutubeLoginUrlDTO } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

import { CBOYoutubeChannel } from '../../core/domain/CBOYoutubeChannel';
import { YoutubeGateway } from '../../core/gateways/youtube-gateway';
import { ApiGateway } from '../ApiGateway';

type YoutubeFetchChannelsResponse = {
  items: {
    id: string;
    snippet: {
      thumbnails: {
        medium: {
          url: string;
        };
      };
      title: string;
    };
  }[];
  pageInfo: {
    resultsPerPage: number;
    totalResults: number;
  };
};

export class RealYoutubeGateway extends ApiGateway implements YoutubeGateway {
  async fetchLoginUrl(): Promise<string> {
    return firstValueFrom(
      this.authenticatedJsonQuery<YoutubeLoginUrlDTO>({
        url: `${this.apiEndpoint}/youtube-login-url`,
      }).pipe(map(({ url }) => url)),
    );
  }

  async fetchLongLivedAccessToken(code: string): Promise<YoutubeAccessToken> {
    return firstValueFrom(
      this.authenticatedJsonQuery<YoutubeAccessToken>({
        url: `${this.apiEndpoint}/youtube-long-lived-access-token?code=${code}`,
      }),
    );
  }

  async fetchChannels(token: string): Promise<CBOYoutubeChannel[]> {
    const urlParams = new URLSearchParams({
      mine: '1',
      part: 'snippet',
    });

    return firstValueFrom(
      ajax
        .get<YoutubeFetchChannelsResponse>(`https://www.googleapis.com/youtube/v3/channels?${urlParams.toString()}`, {
          Authorization: `Bearer ${token}`,
        })
        .pipe(
          map(({ response }) => response),
          map((result: YoutubeFetchChannelsResponse) => {
            if (!result.items) return [];
            return result.items.map((item) => {
              return {
                id: item.id,
                picture: item.snippet.thumbnails.medium.url,
                title: item.snippet.title,
              };
            });
          }),
        ),
    );
  }

  async checkHasLiveStreamPermissions(token: string): Promise<boolean> {
    const urlParams = new URLSearchParams({
      mine: 'true',
      part: 'id',
    });

    try {
      await firstValueFrom(
        ajax.get(`https://www.googleapis.com/youtube/v3/liveStreams?${urlParams.toString()}`, {
          Authorization: `Bearer ${token}`,
        }),
      );
      return true;
    } catch (e: any) {
      const reason = e.response.error?.errors?.[0]?.reason;
      if (reason === 'liveStreamingNotEnabled') {
        return false;
      }
      throw e;
    }
  }
}
