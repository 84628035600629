import { Box, Typography, styled } from '@mui/material';
import i18next from 'i18next';
import React from 'react';

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1;
  width: 100%;
`;

const Title = styled(Typography, { shouldForwardProp: (prop) => prop !== 'colored' })<{ colored: boolean }>(
  ({ colored, theme }) => ({
    color: colored ? theme.palette.secondary.main : 'inherit',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '0.8em',
    textTransform: 'uppercase',
  }),
);

const Value = styled(Typography, { shouldForwardProp: (prop) => prop !== 'colored' })<{ colored: boolean }>(
  ({ colored, theme }) => ({
    color: colored ? theme.palette.secondary.main : 'inherit',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '1.8em',
    fontWeight: 'bold',
  }),
);

interface ProductValueProps {
  colored?: boolean;
  isPercentage?: boolean;
  title: string;
  value: number;
}

export default function ProductStatisticsValue({
  colored = false,
  isPercentage = false,
  title,
  value,
}: ProductValueProps) {
  const formattedValue = value.toLocaleString(i18next.language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: isPercentage ? 'percent' : 'decimal',
  });

  return (
    <Container>
      <Value colored={colored}>{formattedValue}</Value>
      <Title colored={colored}>{title}</Title>
    </Container>
  );
}
