import { V1 } from '@bellepoque/api-contracts';

import { CBOMediaCollection } from '../../core/domain/CBOMediaCollection';
import { CBOMediaCollectionPublishedPage } from '../../core/domain/CBOMediaCollectionPublishedPage';

const mediaCollectionPageToDomain = (dto: V1.api.MediaCollectionPublishedPageDTO): CBOMediaCollectionPublishedPage => {
  if (dto.type === 'home') {
    return {
      ...dto,
      id: 'home',
    };
  }

  if (dto.type === 'product') {
    return dto;
  }

  return {
    ...dto,
    // TODO
    title: '',
  };
};

export const mediaCollectionToDomain = (dto: V1.api.MediaCollectionDTO): CBOMediaCollection => {
  return {
    id: dto.id,
    medias: dto.medias.map((media) => {
      if (media.type === 'replay-chapter') {
        return {
          ...media,
          duration: media.endTimeStamp - media.startTimeStamp,
          id: `${media.id}-${media.startTimeStamp}-${media.endTimeStamp}`,
          replayId: media.id,
        };
      }

      return media;
    }),
    publishedPages: dto.publishedPages.map(mediaCollectionPageToDomain),
    tenantId: dto.tenantId,
    title: dto.title,
  };
};
