import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Autocomplete, Box, Button, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../../../../core/store';
import { inviteMaster } from '../../../../core/usecases/administration/invite-master';
import { emailRegEx } from '../../../../utils/email-regex';
import DetailsContainer from '../../molecules/administration/DetailsContainer';

type Inputs = {
  masterEmail: string;
  tenantId: string;
};

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}));

export default function MasterInvitations() {
  const { t } = useTranslation(['administration', 'common']);
  const dispatch = useDispatch();

  const { status: masterInvitationStatus } = useSelector((state: State) => state.administration.masterInvitation);
  const { tenants } = useSelector((state: State) => state.tenants);
  const hasSingleTenant = tenants.length === 1;
  const tenantId = hasSingleTenant ? tenants[0].id : '';

  const defaultValues: Inputs = {
    masterEmail: '',
    tenantId,
  };

  const { register, handleSubmit, formState, setValue, reset } = useForm<Inputs>({ defaultValues });
  const { errors } = formState;

  const [loadingInProgress, setLoadingInProgress] = useState(masterInvitationStatus === 'pending');

  useEffect(() => {
    setLoadingInProgress(masterInvitationStatus === 'pending');
  }, [masterInvitationStatus]);

  const onSubmit = (data: Inputs) => {
    const payload = {
      masterEmail: data.masterEmail,
      tenantId: data.tenantId,
    };

    return dispatch(inviteMaster(payload));
  };

  const onCancel = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    if (masterInvitationStatus === 'success') {
      reset(defaultValues);
    }
  }, [reset, masterInvitationStatus]);

  const renderTenantIdField = () => {
    if (hasSingleTenant) return null;

    const { ref: tenantIdInputRef } = register('tenantId', {
      required: { message: t('common:Required'), value: true },
    });

    const textFieldProps = {
      error: !!errors.tenantId,
      helperText: !!errors.tenantId ? errors?.tenantId?.message : '',
      inputRef: tenantIdInputRef,
      required: true,
    };

    return (
      <Autocomplete
        clearOnBlur
        disabled={tenants.length === 1}
        fullWidth
        getOptionLabel={(tenant) => tenant.name}
        handleHomeEndKeys
        onChange={(e, newTenant) => setValue('tenantId', newTenant?.id ?? '')}
        options={tenants}
        renderInput={(params) => (
          <TextField {...params} {...textFieldProps} label={t('TenantName')} variant="outlined" />
        )}
        renderOption={(props, tenant) => <li {...props}>{tenant.name}</li>}
        selectOnFocus
      />
    );
  };

  const { ref: masterEmailInputRef, ...masterEmailInputProps } = register('masterEmail', {
    pattern: {
      message: 'Please enter a valid email address',
      value: emailRegEx,
    },
    required: { message: 'Master Email is required', value: true },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: '100%',
      }}
    >
      <DetailsContainer actionsLoading={loadingInProgress} headerLoading={false} title={t('MasterInvitation')}>
        <Container boxShadow={2}>
          <Box
            id="master-invitation-page-content"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '80%',
            }}
          >
            <TextField
              disabled={loadingInProgress}
              error={!!errors.masterEmail}
              fullWidth
              helperText={!!errors.masterEmail ? errors?.masterEmail?.message : ''}
              id="masterEmail"
              inputRef={masterEmailInputRef}
              label={t('MasterEmail')}
              required
              variant="outlined"
              {...masterEmailInputProps}
            />

            {renderTenantIdField()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              color="primary"
              disabled={!formState.isDirty || loadingInProgress}
              onClick={onCancel}
              startIcon={<ClearIcon />}
              variant="contained"
            >
              <span>{t('common:Cancel')}</span>
            </Button>
            <Button
              color="primary"
              disabled={!formState.isDirty || loadingInProgress}
              startIcon={<SendIcon />}
              type="submit"
              variant="contained"
            >
              <span>{t('InviteMaster')}</span>
            </Button>
          </Box>
        </Container>
      </DetailsContainer>
    </form>
  );
}
