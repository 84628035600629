import { useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSelect as ReactSelectMaterialUi, SelectOption } from 'react-select-material-ui';

interface SingleSelectProps {
  SelectProps?: any;
  disabled: boolean;
  label: string;
  noOptionsMessage?: string;
  onChange: (value: string) => void;
  options: { label: string; value: any }[] | string[];
  value: string;
}

export default function SingleSelect(props: SingleSelectProps) {
  const { disabled, label, onChange, options, SelectProps = {}, value } = props;
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();

  return (
    <ReactSelectMaterialUi
      SelectProps={{
        msgNoOptionsMatchFilter: t('NoEventsFound'),
        ...SelectProps,
        styles: {
          ...(SelectProps.styles ?? {}),
          container: (base: any) => ({
            ...base,
            '&:hover': {
              borderColor: theme.palette.primary.dark,
            },
            borderBottom: '1px solid black',
            padding: `${theme.spacing(0.5)} 0`,
            textAlign: 'initial',
            ...(SelectProps.styles?.container(base) ?? {}),
          }),
          control: (base: any) => ({
            ...base,
            border: 0,
            boxShadow: 0,
            ...(SelectProps.styles?.control ?? {}),
          }),
          indicatorSeparator: (base: any) => ({
            ...base,
            display: 'none',
            ...(SelectProps.styles?.indicatorSeparator ?? {}),
          }),
          menu: (base: any) => ({
            ...base,
            zIndex: 9999,
            ...(SelectProps.styles?.menu ?? {}),
          }),
          option: (base: any, { data, isSelected }: { data: SelectOption; isSelected: boolean }) => ({
            ...base,
            '&:hover': {
              backgroundColor: 'grey',
              color: theme.palette.primary.contrastText,
            },
            backgroundColor: isSelected ? theme.palette.primary.dark : theme.palette.primary.contrastText,
            color: isSelected ? 'white' : theme.palette.primary.dark,
            ...(SelectProps.styles?.option(base, { data, isSelected }) ?? {}),
          }),
          placeholder: (base: any) => ({
            ...base,
            fontWeight: 'bold',
            textAlign: 'initial',
            ...(SelectProps.styles?.placeholder ?? {}),
          }),
        },
      }}
      disabled={disabled}
      label={label}
      onChange={onChange}
      options={options}
      size="medium"
      sx={{
        '& > .MuiInputLabel-root': {
          color: 'black',
          textAlign: 'initial',
        },
        '& > .MuiInputLabel-shrink': {
          textAlign: 'initial',
          width: 'fit-content',
          zIndex: 1,
        },
      }}
      value={value}
    />
  );
}
