import { EventId } from '@bellepoque/api-contracts';

import { manyToDomain } from '../../../../gateways/magic-content/magic-content-mappers';
import { CBOMagicContent } from '../../../domain/CBOMagicContent';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type FetchMagicContentParams = {
  eventId: EventId;
};

export type FetchMagicContentResult = {
  magicContents: CBOMagicContent[];
  transcriptAvailable: boolean;
};

// TODO: Add use case tests
export const fetchMagicContent = createAppAsyncThunk(
  'event/fetch-magic-content',
  async (
    { eventId }: FetchMagicContentParams,
    { extra: { magicContentGateway } },
  ): Promise<FetchMagicContentResult> => {
    const dto = await magicContentGateway.fetchByEvent(eventId);

    return {
      magicContents: manyToDomain(dto.magicContents),
      transcriptAvailable: dto.transcriptAvailable,
    };
  },
);
