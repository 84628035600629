import { EventId, ProductId } from '@bellepoque/api-contracts';
import { ActionReducerMapBuilder, SerializedError, createAction } from '@reduxjs/toolkit';

import { CBOEventReadModelProduct } from '../../../domain/CBOEventReadModelProduct';
import { CBORealtimeData } from '../../../domain/CBORealtimeData';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus } from '../../utils';

export const fetchRealtimeDataFulfilled = createAction<{ eventId: EventId; realtimeData: CBORealtimeData }>(
  'events/fetch-realtime-data-fulfilled',
);
export const fetchRealtimeDataRejected = createAction<SerializedError[]>('events/fetch-realtime-data-rejected');

export type EventRealtimeActions =
  | ReturnType<typeof fetchRealtimeDataFulfilled>
  | ReturnType<typeof fetchRealtimeDataRejected>;

export const buildRealtimeReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchRealtimeDataFulfilled, (state, action) => {
    const displayedProducts: CBOEventReadModelProduct[] = [];
    action.payload.realtimeData.productHighlights.forEach((productId: ProductId) => {
      const found = state.currentEvent?.products.find(({ id }) => productId === id);
      if (found) {
        displayedProducts.push(CBOEventReadModelProduct.fromState(found.toState()));
      }
    });
    return {
      ...state,
      ...loadedStatus('realtimeDataFetching'),
      currentEventRealtimeData: action.payload.realtimeData,
      displayedProducts,
    };
  });
  builder.addCase(fetchRealtimeDataRejected, (state, action) => ({
    ...state,
    ...errorStatus('realtimeDataFetching', action.payload),
    currentEventRealtimeData: null,
  }));
};
