import { LiveProductStatistics } from '@bellepoque/api-contracts';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, ListItem, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import CheckIcon from '../../../../icons/ic_check_24px.svg';
import Counter from '../../atoms/event/Counter';
import ProductHighlightCard from './ProductHighlightCard';

export interface ShowCasedProductStyleProps {
  backgroundColor: string;
  figureColor: string;
  leftIcon: JSX.Element;
  titleColor: string;
}

interface LiveDisplayedProductRowProps {
  currency: CBOCurrency;
  isCurrentlyDisplayed?: boolean;
  liveProductStats?: LiveProductStatistics;
  onClick: () => void;
  product: CBOEventReadModelProduct;
  styleProps?: ShowCasedProductStyleProps;
}

const Container = styled(ListItem)({
  borderRadius: '0.8rem',
  cursor: 'pointer',
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%',
});

const defaultLiveProductStats: LiveProductStatistics = {
  countLiveCartQuantity: 0,
  nbProductPageViews: 0,
  totalLiveCartAmount: 0,
};

export default function LiveDisplayedProductRow({
  currency,
  isCurrentlyDisplayed = false,
  onClick,
  product,
  liveProductStats = defaultLiveProductStats,
  styleProps,
}: LiveDisplayedProductRowProps) {
  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();

  return (
    <Container
      key={product.id}
      onClick={onClick}
      sx={{ backgroundColor: isCurrentlyDisplayed ? theme.palette.primary.dark : 'transparent' }}
    >
      <Box alignItems="center" display="grid" gridTemplateColumns="1fr 7fr 5fr" width="100%">
        <Box textAlign="center" width="74px">
          {isCurrentlyDisplayed ? (
            <VisibilityIcon htmlColor={theme.palette.common.white} />
          ) : (
            <img
              alt=""
              src={CheckIcon}
              style={{
                // computed with https://codepen.io/sosuke/pen/Pjoqqp
                // keep brightness(0) saturate(100%) at the beginning to make it black as first step
                filter:
                  'brightness(0) saturate(100%) invert(64%) sepia(14%) saturate(639%) hue-rotate(11deg) brightness(92%) contrast(88%)',
              }}
            />
          )}
        </Box>
        <Box flexGrow={1} ml={1}>
          <ProductHighlightCard currency={currency} product={product} />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr" textAlign="center">
          <Box>
            <Counter
              helperText={t('counters.ProductViewsHelper')}
              sx={{ color: isCurrentlyDisplayed ? theme.palette.common.white : undefined, minWidth: 0 }}
              title={t('counters.ProductViews')}
              titleSx={{ ...(styleProps?.titleColor ? { color: styleProps?.titleColor } : {}) }}
              value={liveProductStats.nbProductPageViews}
            />
          </Box>
          <Box>
            <Counter
              helperText={t('counters.ProductInCartHelper')}
              sx={{
                color: isCurrentlyDisplayed ? theme.palette.common.white : undefined,
                marginRight: 0,
                minWidth: 0,
              }}
              title={t('counters.ProductInCart')}
              titleSx={{ color: isCurrentlyDisplayed ? theme.palette.common.white : undefined }}
              value={liveProductStats.countLiveCartQuantity}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
