import { TenantId } from '@bellepoque/api-contracts';

import { mediaCollectionToDomain } from '../../../gateways/media-collections/media-collection-mapper';
import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type FetchOneMediaCollectionRequest = {
  mediaCollectionId: string;
  tenantId: TenantId;
};

export const fetchOneMediaCollection = createAppAsyncThunk(
  'media-collections/fetch-one-media-collection',
  async (
    { mediaCollectionId, tenantId }: FetchOneMediaCollectionRequest,
    { extra: { mediaCollectionsGateway } },
  ): Promise<CBOMediaCollection> => {
    const dto = await mediaCollectionsGateway.fetchOne({ mediaCollectionId, tenantId });
    return mediaCollectionToDomain(dto);
  },
);
