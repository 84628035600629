import { V1 } from '@bellepoque/api-contracts';

import { CBOCatalogProduct } from '../../core/domain/CBOCatalogProduct';
import { CBOShoppableVideoListItemReadModel } from '../../core/domain/CBOShoppableVideoReadModel';

export function dtoToShoppableListItemReadModel(
  dto: V1.api.ShoppableVideoDTO,
  product: CBOCatalogProduct,
): CBOShoppableVideoListItemReadModel {
  return {
    createdAt: new Date(dto.createdAt),
    id: dto.id,
    productId: dto.productId,
    productImage: product.imageUrls[0] ?? null,
    productOriginalId: product.originalId,
    productTitle: product.title,
    status: dto.status,
    thumbnailUrl: dto.thumbnailUrl,
    title: dto.title,
    videoDuration: dto.videoDuration,
    videoUrl: dto.videoUrl,
  };
}
