import { combineEpics } from 'redux-observable';

import { AuthenticationEpics } from './authentication';
import { EventEpics } from './event';
import { ShoppableEpics } from './shoppables';
import { TenantsEpics } from './tenants';
import { TutorialEpics } from './tutorial';

export const rootEpic = combineEpics(
  ...AuthenticationEpics,
  ...EventEpics,
  ...ShoppableEpics,
  ...TenantsEpics,
  ...TutorialEpics,
);
