import { V1 } from '@bellepoque/api-contracts';
import { Box, Typography, styled } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import formatDate from 'date-fns/format';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { secondsToFormatedTime } from '../../../../utils/date-format';
import { eventStatusToTranslation } from '../../../../utils/event-status-to-translation';
import DisplayedValue from '../../atoms/event/DisplayedValue';

const NotFoundMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Image = styled('img')({
  borderRadius: '20px',
  height: '170px',
  objectFit: 'cover',
  width: '170px',
});

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '15px',
  display: 'flex',
  padding: `${theme.spacing(4)}`,
  textAlign: 'initial',
}));

const Title = styled(Typography)({
  color: 'black',
  fontSize: '1.4em',
  fontWeight: 'bold',
  marginBottom: '5px',
  marginTop: '0',
  textalign: 'left',
});

interface EventFinderResultProps {
  foundEvent: V1.api.EventDTO | null;
  tenantName: string | null;
}

export default function EventFinderResult({ foundEvent, tenantName }: EventFinderResultProps) {
  const { t } = useTranslation('events');
  const { t: eventStatusTranslator } = useTranslation('events', { keyPrefix: 'status' });

  if (!foundEvent) return <NotFoundMessage>{t('EventNotFound')}</NotFoundMessage>;

  const displayedTime = foundEvent.showTime
    ? new Date(foundEvent.showTime)
    : foundEvent.startTime
    ? new Date(foundEvent.startTime)
    : null;

  const startTime = foundEvent.startTime ? new Date(foundEvent.startTime) : null;
  const finishTime = foundEvent.finishTime ? new Date(foundEvent.finishTime) : null;

  return (
    <Container boxShadow={2} py={2}>
      <Box display="flex" gap={2}>
        <Image src={foundEvent.theme.preEventCoverUrl} />
        <Box display="flex" flex={1} flexDirection="column" height="100%" justifyContent="space-evenly">
          <Box>
            <Title variant="h2">{foundEvent.title}</Title>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={4}>
            {!!tenantName && (
              <Box maxWidth="400px">
                <DisplayedValue title={t('Tenant')} value={tenantName} />
              </Box>
            )}
            <DisplayedValue
              title={t('Status')}
              value={eventStatusToTranslation(foundEvent.status, eventStatusTranslator)}
            />
            {displayedTime && (
              <DisplayedValue title={formatDate(displayedTime, 'y')} value={formatDate(displayedTime, 'E d MMM p')} />
            )}
            {startTime && (
              <DisplayedValue
                title={finishTime ? t('Duration') : t('Start')}
                value={secondsToFormatedTime(differenceInSeconds(finishTime ?? new Date(), startTime))}
              />
            )}
            <DisplayedValue title={t('PublisherPhone')} value={foundEvent.publisherPhone} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
