import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { State } from '../../../../../core/store';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import { useRequiredParams } from '../../../../../utils/useRequiredParams';
import { routes } from '../../../../routes';
import ProductDetail from './ProductDetail';
import ProductList from './ProductList';

type ProductsProps = {
  event: CBOEventReadModel;
  hasCatalogProducts: boolean;
  onAddProduct: () => void;
  readonly: boolean;
};

export default function ProductPanel({ event, hasCatalogProducts, onAddProduct, readonly }: ProductsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId, productId } = useRequiredParams<{ eventId: string; productId?: string }>();
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const products = event.products ?? [];

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'Product panel',
      }),
    );
  }, []);

  const handleProductRowClick = (product: CBOEventReadModelProduct) => {
    navigate(routes.events.product({ eventId, productId: product.id }));
  };

  if (!productId)
    return (
      <ProductList
        currency={currentTenant.currency}
        eventId={event.id}
        hasCatalogProducts={hasCatalogProducts}
        onAddProduct={onAddProduct}
        onRowClick={handleProductRowClick}
        products={products}
        readonly={readonly}
      />
    );

  const product = products.find(({ id }) => id === productId);
  return <ProductDetail currency={currentTenant.currency} product={product} readonly={readonly} />;
}
