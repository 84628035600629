import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { fetchOneEventChatCredentials } from '../../../usecases/event/chat/fetch-event-chat-credentials';
import { pinMessage } from '../../../usecases/event/chat/pin-message';
import { unpinMessage } from '../../../usecases/event/chat/unpin-message';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, pendingStatus, successStatus } from '../../utils';

const buildChatCredentialsReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchOneEventChatCredentials.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('eventChatCredentialsFetching'),
    chatCredentials: action.payload,
  }));
  builder.addCase(fetchOneEventChatCredentials.pending, (state) => ({
    ...state,
    ...pendingStatus('eventChatCredentialsFetching'),
    chatCredentials: null,
  }));
  builder.addCase(fetchOneEventChatCredentials.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventChatCredentialsFetching', [action.error]),
  }));
};

const buildPinMessagesReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(pinMessage.fulfilled, (state) => ({
    ...state,
    ...successStatus('pinMessage'),
  }));
  builder.addCase(pinMessage.pending, (state) => ({
    ...state,
    ...pendingStatus('pinMessage'),
  }));
  builder.addCase(pinMessage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('pinMessage', [action.error]),
  }));
  builder.addCase(unpinMessage.fulfilled, (state) => ({
    ...state,
    ...successStatus('unpinMessage'),
  }));
  builder.addCase(unpinMessage.pending, (state) => ({
    ...state,
    ...pendingStatus('unpinMessage'),
  }));
  builder.addCase(unpinMessage.rejected, (state, action) => ({
    ...state,
    ...errorStatus('unpinMessage', [action.error]),
  }));
};

export const buildChatReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  buildChatCredentialsReducer(builder);
  buildPinMessagesReducer(builder);
};
