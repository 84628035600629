import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { adminFindEvent } from '../../usecases/administration/admin-find-event';
import { createTenant } from '../../usecases/administration/create-tenant';
import { inviteMaster } from '../../usecases/administration/invite-master';
import { AdministrationState, INITIAL_ADMINISTRATION_STATE } from '../state/administration';
import { errorStatus, loadedStatus, pendingStatus, successStatus } from '../utils';

const buildToolsReducers = (builder: ActionReducerMapBuilder<AdministrationState>) => {
  builder.addCase(adminFindEvent.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('findEvent'),
    foundEvent: action.payload,
  }));
  builder.addCase(adminFindEvent.pending, (state) => ({
    ...state,
    ...pendingStatus('findEvent'),
    foundEvent: null,
  }));
  builder.addCase(adminFindEvent.rejected, (state, action) => ({
    ...state,
    ...errorStatus('findEvent', [action.error]),
  }));
};

const buildTenantManagementReducers = (builder: ActionReducerMapBuilder<AdministrationState>) => {
  builder.addCase(createTenant.fulfilled, (state) => ({
    ...state,
    ...successStatus('createTenant'),
  }));
  builder.addCase(createTenant.pending, (state) => ({
    ...state,
    ...pendingStatus('createTenant'),
  }));
  builder.addCase(createTenant.rejected, (state, action) => ({
    ...state,
    ...errorStatus('createTenant', [action.error]),
  }));
  builder.addCase(inviteMaster.fulfilled, (state) => ({
    ...state,
    ...successStatus('masterInvitation'),
  }));
  builder.addCase(inviteMaster.pending, (state) => ({
    ...state,
    ...pendingStatus('masterInvitation'),
  }));
  builder.addCase(inviteMaster.rejected, (state, action) => ({
    ...state,
    ...errorStatus('masterInvitation', [action.error]),
  }));
};

export const administrationSlice = createSlice({
  extraReducers: (builder) => {
    buildToolsReducers(builder);
    buildTenantManagementReducers(builder);
  },
  initialState: INITIAL_ADMINISTRATION_STATE,
  name: 'administration',
  reducers: {},
});
