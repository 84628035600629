import { Box, Grid, LinearProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMagicContent } from '../../../core/domain/CBOMagicContent';
import { QueryStatus } from '../../../core/store/state/utils';
import MagicContentHeader from '../../components/molecules/event/magic-content/MagicContentsHeader';
import MagicContentList from '../../components/organisms/event/event-details/MagicContents/MagicContentList';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
});

type MagicContentsTemplateProps = {
  isTranscriptAvailable: boolean;
  magicContents: CBOMagicContent[];
  magicContentsFetchingStatus: QueryStatus;
  onGenerateMagicContent: () => void;
  transcriptReleaseDate: Date;
  wasEventAiredAfterTranscriptReleaseDate: boolean;
};

const MagicContentsTemplate = ({
  isTranscriptAvailable,
  magicContents,
  magicContentsFetchingStatus,
  onGenerateMagicContent,
  transcriptReleaseDate,
  wasEventAiredAfterTranscriptReleaseDate,
}: MagicContentsTemplateProps) => {
  const { i18n, t } = useTranslation('events');
  const hasMagicContentPending = magicContents.some((magicContent) => magicContent.status === 'pending');

  const renderAvailableFromReleaseDate = () => (
    // TODO: Styled component
    <Box
      boxShadow={2}
      sx={{
        backgroundColor: 'common.white',
        borderRadius: '15px',
        maxWidth: '400px',
        paddingX: 2,
        paddingY: 4,
      }}
    >
      <Typography>
        {t('MagicContent.AvailableFromDate', {
          date: transcriptReleaseDate.toLocaleDateString(i18n.language, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }),
        })}
      </Typography>
    </Box>
  );

  if (magicContentsFetchingStatus === 'pending') {
    return <LinearProgress />;
  }

  return (
    <Container alignItems={magicContents.length > 0 ? 'flex-start' : 'center'}>
      {wasEventAiredAfterTranscriptReleaseDate ? (
        <Grid container justifyContent="center" mt={2} pb={4}>
          <Grid alignItems="center" display="flex" flexDirection="column" item md={10} xs={12}>
            <MagicContentHeader
              hasMagicContentPending={hasMagicContentPending}
              hasMagicContents={magicContents.length > 0}
              isTranscriptAvailable={isTranscriptAvailable}
              onGenerateMagicContent={onGenerateMagicContent}
            />
            <MagicContentList magicContents={magicContents} />
          </Grid>
        </Grid>
      ) : (
        renderAvailableFromReleaseDate()
      )}
    </Container>
  );
};

export default MagicContentsTemplate;
