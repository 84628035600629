import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function CoversIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26.659 26.659">
      <g transform="translate(-2.5 -2.5)">
        <path
          d="M15.83,2.5a13.33,13.33,0,1,0,13.33,13.33A13.342,13.342,0,0,0,15.83,2.5Zm3.2,6.37a1.768,1.768,0,1,1-1.768,1.768A1.761,1.761,0,0,1,19.029,8.87ZM22.593,20.6H9.067a.994.994,0,0,1-.814-1.571l5-6.959a1,1,0,0,1,1.628,0l2.89,4.041a.341.341,0,0,0,.561,0L19,15.184a1,1,0,0,1,1.628,0l2.778,3.845A.994.994,0,0,1,22.593,20.6Z"
          transform="translate(0 0)"
        />
      </g>
    </SvgIcon>
  );
}
