import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormActionBarProps {
  buttonsDisabled: boolean;
  isFormDirty: boolean;
  onCancel: () => void;
  onDelete?: () => void;
}

export default function FormActionBar({ buttonsDisabled, isFormDirty, onCancel, onDelete }: FormActionBarProps) {
  const { t } = useTranslation(['common', 'events']);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 3,
        py: 3,
        width: '100%',
      }}
    >
      <Box>
        {onDelete && (
          <Button
            color="error"
            disableElevation
            disabled={buttonsDisabled}
            onClick={onDelete}
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            <span>{t('events:DeleteThisEvent')}</span>
          </Button>
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: 3 }}>
        <Button disabled={!isFormDirty || buttonsDisabled} onClick={onCancel}>
          <span>{t('Cancel')}</span>
        </Button>
        <Button
          color="primary"
          disableElevation
          disabled={!isFormDirty || buttonsDisabled}
          type="submit"
          variant="contained"
        >
          <span>{t('Update')}</span>
        </Button>
      </Box>
    </Box>
  );
}
