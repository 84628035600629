import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import {
  CreateEventRequest,
  createEvent,
  resetCreateEvent,
} from '../../../../../core/usecases/event/crud/create-event';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createCreateEventFormDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const tenantId = state.tenants.currentTenantId;
    const tenantName = state.tenants.currentTenant.name;

    return {
      createEvent: (request: CreateEventRequest) => {
        dispatch(createEvent({ payload: request, tenantId }));
      },
      eventCreationStatus: state.events.eventCreation.status,
      resetCreateEvent: () => {
        dispatch(resetCreateEvent());
      },
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
    };
  };
