import { V1 } from '@bellepoque/api-contracts';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  ButtonProps,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import PictureIcon from '../../../../../icons/PictureIcon';
import ProductsIcon from '../../../../../icons/ProductsIcon';
import ShoppablesIcon from '../../../../../icons/ShoppablesIcon';
import FormDialog from '../../../../templates/dialog/FormDialog';
import SettingsSection from '../../../molecules/SettingsSection';
import ThumbnailSelector from '../../../molecules/shoppables/ThumbnailSelector';
import { ShoppableFormInputs } from './ShoppableFormDialog';

const constraints = V1.api.constraints.shoppableVideos;

const ProductContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const ProductImage = styled('img')({
  width: '80px',
});

export type CreateShoppableFormProps = {
  disabled?: boolean;
  form: UseFormReturn<ShoppableFormInputs>;
  hasDialogContainer?: boolean;
  onClose: () => void;
  onOpenAddProductToShoppableDialog: () => void;
  onSelectCustomFile: (file: File) => void;
  onSelectProductImage: (productImage: string) => void;
  onSubmit: (values: ShoppableFormInputs) => void;
  onUnselectProduct: () => void;
  open: boolean;
  product: CBOCatalogProduct | null;
  submissionInProgress: boolean;
};

const CreateShoppableForm = ({
  disabled,
  form,
  hasDialogContainer = false,
  onClose,
  onOpenAddProductToShoppableDialog,
  onSelectCustomFile,
  onSelectProductImage,
  onSubmit,
  onUnselectProduct,
  open,
  product,
  submissionInProgress,
}: CreateShoppableFormProps) => {
  const { t } = useTranslation(['shoppables', 'common']);

  const { watch, formState, register, setFocus, handleSubmit: handleFormSubmit } = form;
  const { errors, isValid } = formState;
  const theme = useTheme();

  const title = watch('title');

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: constraints.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: constraints.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });

  useEffect(() => {
    if (product) {
      setFocus('title');

      if (product.imageUrls.length > 0) {
        onSelectProductImage(product.imageUrls[0]);
      }
    }
  }, [product]);

  const renderNoProduct = () => (
    <Button
      color="primary"
      onClick={onOpenAddProductToShoppableDialog}
      startIcon={<AddIcon fontSize="large" />}
      variant="contained"
    >
      {t('AddProduct.SelectProduct')}
    </Button>
  );

  const renderProduct = () => {
    if (!product) return null;

    return (
      <ProductContainer>
        <ProductImage src={product.imageUrls[0]} />
        <Box alignItems="center" display="flex" flexDirection="column" gap={1} justifyContent="center">
          <Typography>{product.title}</Typography>
        </Box>
        <IconButton disableRipple onClick={onUnselectProduct}>
          <Delete />
        </IconButton>
      </ProductContainer>
    );
  };

  const buttonsProps: ButtonProps[] = [
    {
      children: <Typography>{t('common:Cancel')}</Typography>,
      onClick: onClose,
      sx: {
        fontSize: '16px',
        textTransform: 'capitalize',
      },
      variant: 'text',
    },
    {
      children: <Typography>{t('common:Create')}</Typography>,
      disabled: !isValid || !product || submissionInProgress,
      onClick: handleFormSubmit(onSubmit),
      sx: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px',
        fontSize: '16px',
        fontWeight: 'normal',
        textTransform: 'capitalize',
      },
      variant: 'contained',
    },
  ];

  const handleSelectCustomFile = (file: File) => {
    onSelectCustomFile(file);
  };

  const handleSelectProductImage = async (productImageUrl: string) => {
    onSelectProductImage(productImageUrl);
  };

  return (
    <FormDialog buttonsProps={buttonsProps} loading={false} onClose={onClose} open={open} title={t('NewShoppable')}>
      <form noValidate onSubmit={handleFormSubmit(onSubmit)}>
        <SettingsSection
          disableElevation
          fixedIcon={hasDialogContainer}
          icon={<ProductsIcon sx={{ color: theme.palette.common.white, height: 30 }} />}
          roundBottom
          sx={
            hasDialogContainer
              ? { marginLeft: (theme) => `-${theme.spacing(4)}`, padding: theme.spacing(3) }
              : undefined
          }
          title={t('Product')}
        >
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Grid container gap={3} justifyContent="center">
                <Grid item xs={12}>
                  {product ? renderProduct() : renderNoProduct()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SettingsSection>
        <SettingsSection
          disableElevation
          fixedIcon={hasDialogContainer}
          icon={<ShoppablesIcon sx={{ color: theme.palette.common.white, height: 30 }} />}
          roundBottom
          sx={
            hasDialogContainer
              ? { marginLeft: (theme) => `-${theme.spacing(4)}`, padding: theme.spacing(3) }
              : undefined
          }
          title={t('Title')}
        >
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Grid container gap={3} justifyContent="center">
                <Grid item position="relative" xs={12}>
                  <TextField
                    disabled={disabled || !product}
                    error={!!errors.title}
                    fullWidth
                    helperText={!!errors.title ? errors?.title?.message : ''}
                    id="title"
                    inputRef={titleInputRef}
                    label={t('TitleLabel')}
                    required
                    variant="filled"
                    {...titleInputProps}
                  />
                  <FormHelperText>{`${title.length} / ${constraints.title.maxLength}`}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SettingsSection>
      </form>

      <SettingsSection
        disableElevation
        fixedIcon={hasDialogContainer}
        icon={<PictureIcon sx={{ color: theme.palette.common.white, height: 30 }} />}
        roundBottom
        sx={hasDialogContainer ? { marginLeft: (theme) => `-${theme.spacing(4)}`, padding: theme.spacing(3) } : {}}
        title={t('Thumbnail')}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container gap={3} justifyContent="center">
              <Grid item position="relative" xs={12}>
                <ThumbnailSelector
                  buttonLabel={t('UploadImage.Action')}
                  defaultSelectedThumbnail={product?.imageUrls?.[0] ?? ''}
                  disabled={disabled || !product}
                  imageUrls={product?.imageUrls || []}
                  onSelectFile={handleSelectCustomFile}
                  onSelectThumbnail={handleSelectProductImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SettingsSection>
    </FormDialog>
  );
};

export default CreateShoppableForm;
