import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { fetchEventStatistics } from '../../../usecases/event/statistics/fetch-event-statistics';
import { fetchLiveEventStatistics } from '../../../usecases/event/statistics/fetch-live-event-statistics';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, pendingStatus } from '../../utils';

const buildEventStatisticsReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchEventStatistics.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('eventStatisticsFetching'),
    currentEventStatistics: action.payload,
  }));
  builder.addCase(fetchEventStatistics.pending, (state) => ({
    ...state,
    ...pendingStatus('eventStatisticsFetching'),
  }));
  builder.addCase(fetchEventStatistics.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventStatisticsFetching', [action.error]),
  }));
};

const buildLiveStatisticsReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(fetchLiveEventStatistics.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('liveEventStatisticsFetching'),
    currentLiveEventStatistics: {
      ...state.currentLiveEventStatistics,
      ...action.payload,
    },
  }));
  builder.addCase(fetchLiveEventStatistics.pending, (state) => ({
    ...state,
    ...pendingStatus('liveEventStatisticsFetching'),
  }));
  builder.addCase(fetchLiveEventStatistics.rejected, (state, action) => ({
    ...state,
    ...errorStatus('liveEventStatisticsFetching', [action.error]),
  }));
};

export const buildStatisticsReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  buildEventStatisticsReducer(builder);
  buildLiveStatisticsReducer(builder);
};
