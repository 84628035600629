import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ThemeActionBarProps {
  buttonsDisabled: boolean;
  isFormDirty: boolean;
  onCancel: () => void;
}

export default function ThemeActionBar({ buttonsDisabled, isFormDirty, onCancel }: ThemeActionBarProps) {
  const { t } = useTranslation('common');
  return (
    <Box
      id="event-theme-form-action-bar"
      sx={{
        display: 'flex',
        gap: 3,
        justifyContent: 'flex-end',
        px: 3,
        py: 3,
        width: '100%',
      }}
    >
      <Button disabled={!isFormDirty || buttonsDisabled} onClick={onCancel}>
        <span>{t('Cancel')}</span>
      </Button>
      <Button color="primary" disabled={!isFormDirty || buttonsDisabled} type="submit" variant="contained">
        <span>{t('Update')}</span>
      </Button>
    </Box>
  );
}
