import { TenantId } from '@bellepoque/api-contracts';

import { synchronizeEventsPending } from '../../../store/slices/event/event-synchronize.slice';
import { QueryStatus } from '../../../store/state/utils';

export type FetchEventsRequest = {
  eventsFetchingStatus: QueryStatus;
  tenantId: TenantId;
};

export const fetchEvents = ({ tenantId, eventsFetchingStatus }: FetchEventsRequest) => {
  return synchronizeEventsPending({ force: eventsFetchingStatus === 'error', tenantId });
};
