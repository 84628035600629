import { Typography, styled } from '@mui/material';
import React from 'react';

const Styled = styled(Typography, { shouldForwardProp: (prop) => prop !== 'useMainColor' })<{ useMainColor?: boolean }>(
  ({ theme, useMainColor }) => ({
    color: useMainColor ? theme.palette.secondary.dark : 'black',
    fontSize: '0.8em',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: 'normal',
    minHeight: '2.2em',
    textAlign: 'left',
    textTransform: 'uppercase',
  }),
);

interface StatsTitleProps {
  color?: string;
  content: string;
}

export default function StatsTitle({ color = 'black', content }: StatsTitleProps) {
  return (
    <Styled sx={{ color }} variant="h3">
      {content}
    </Styled>
  );
}
