import { Box, Grid, LinearProgress, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOTenantStatistics, CBOTenantStatisticsGuards } from '../../../../core/domain/CBOTenantStatistics';
import BlurOverlay from '../../../templates/BlurOverlay';
import ActionDialog from '../../../templates/dialog/ActionDialog';
import CreateButton from '../../atoms/CreateButton';
import ShoppableVideoIntroSection from './ShoppableVideoIntroSection';
import ShoppableVideoStatisticsPreviewSection from './ShoppableVideoStatisticsPreviewSection';

const Root = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  flexGrow: 1,
  position: 'relative',
  textAlign: 'initial',
}));

type TenantShoppableVideoAnalyticsProps = {
  currency: CBOCurrency;
  loading: boolean;
  onCreateShoppableVideo: () => void;
  tenantStatistics: CBOTenantStatistics;
};

export default function TenantShoppableVideoAnalytics({
  currency,
  loading,
  onCreateShoppableVideo,
  tenantStatistics,
}: TenantShoppableVideoAnalyticsProps) {
  const { t } = useTranslation();
  const hasSales = CBOTenantStatisticsGuards.isCBOTenantStatisticsWithSales(tenantStatistics);
  const hasShoppableVideos = tenantStatistics.shoppables.shoppableVideoStatisticsPreviews.length > 0;

  const renderPreviews = () => {
    return hasSales ? (
      <ShoppableVideoStatisticsPreviewSection
        currency={currency}
        hasSales
        previews={tenantStatistics.shoppables.shoppableVideoStatisticsPreviews}
      />
    ) : (
      <ShoppableVideoStatisticsPreviewSection
        hasSales={false}
        previews={tenantStatistics.shoppables.shoppableVideoStatisticsPreviews}
      />
    );
  };

  return (
    <Root pb={4}>
      {loading ? (
        <LinearProgress style={{ width: '100%' }} />
      ) : (
        <>
          <BlurOverlay shown={!hasShoppableVideos}>
            <ActionDialog
              actionButton={<CreateButton onClick={onCreateShoppableVideo} text={t('shoppables:CreateShoppable')} />}
              content={t('analytics:NoShoppableVideoStatsDescription')}
              open={!hasShoppableVideos}
              title={t('analytics:NoShoppableVideoStats')}
            />
          </BlurOverlay>
          <Grid container justifyContent="center">
            <Grid gap={2} item lg={11} md={11} xl={10} xs={12}>
              <Box display="flex" flex={1} flexDirection="column" gap={2}>
                {hasSales ? (
                  <ShoppableVideoIntroSection
                    currency={currency}
                    hasSales
                    purchasedAmounts={tenantStatistics.shoppables.eCommerce.purchasedAmounts}
                    purchasedCarts={tenantStatistics.shoppables.eCommerce.purchasedCarts}
                    views={tenantStatistics.shoppables.traffic.views}
                  />
                ) : (
                  <ShoppableVideoIntroSection hasSales={false} views={tenantStatistics.shoppables.traffic.views} />
                )}

                {/* Not used for now. We will bring back the sections once we have more shoppable statistics */}
                {/* <ShoppableVideoTrafficSection statistics={tenantStatistics.shoppables.traffic} />
              {hasSales && (
                <ShoppableVideoECommerceSection
                  currency={currency}
                  statistics={tenantStatistics.shoppables.eCommerce}
                />
              )} */}
                {renderPreviews()}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Root>
  );
}
