import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export const initFacebook = createAppAsyncThunk(
  'facebook/init',
  async (_, { extra: { facebookGateway, tracingGateway } }): Promise<void> => {
    try {
      facebookGateway.init();
    } catch (e: any) {
      tracingGateway.captureException(e);
      throw e;
    }
  },
);
