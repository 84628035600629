import { miniSerializeError } from '@reduxjs/toolkit';
import { NEVER, of, race } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, timeout } from 'rxjs/operators';

import { AppEpic } from '../..';
import {
  synchronizeShoppablesFulfilled,
  synchronizeShoppablesPending,
  synchronizeShoppablesRejected,
} from '../../slices/shoppables/shoppables-synchronize.slice';
import { ofType } from '../utils';

export const synchronizeShoppablesEpic: AppEpic = (action$, state$, { container: { realtimeDataGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof synchronizeShoppablesPending>>(synchronizeShoppablesPending.type),
    distinctUntilChanged((prev, cur) => (cur.payload.force ? false : prev.payload.tenantId === cur.payload.tenantId)),
    switchMap((action) =>
      race(
        realtimeDataGateway.getShoppableVideoListReadModel({ tenantId: action.payload.tenantId }),
        NEVER.pipe(timeout(3000)),
      ),
    ),
    map((shoppableListReadModels) => synchronizeShoppablesFulfilled(shoppableListReadModels)),
    catchError((error) => of(synchronizeShoppablesRejected([miniSerializeError(error)]))),
  );
};
