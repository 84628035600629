import { YoutubeAccessToken } from '@bellepoque/api-contracts';

import { CBOYoutubeChannel } from '../../../domain/CBOYoutubeChannel';
import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

type FetchYoutubeChannelsResult = {
  channels: CBOYoutubeChannel[];
  token: YoutubeAccessToken;
};

export const fetchYoutubeChannels = createAppAsyncThunk(
  'youtube/fetch-channels',
  async (token: YoutubeAccessToken, { extra: { youtubeGateway } }): Promise<FetchYoutubeChannelsResult> => {
    const channels = await youtubeGateway.fetchChannels(token.accessToken);
    return {
      channels,
      token,
    };
  },
);
