import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import UpgradePlanButton from '../atoms/UpgradePlanButton';

const Root = styled(Box)({
  flexGrow: 1,
});

const CollectionLocationHeaderContainer = styled(Box)(({ theme }) => ({
  '&:after': {
    backgroundColor: theme.palette.grey[200],
    bottom: 0,
    content: `''`,
    height: '1px',
    left: theme.spacing(-2),
    position: 'absolute',
    width: `calc(100% + ${theme.spacing(4)})`,
  },
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,

  position: 'relative',
}));

const CollectionLocationHeaderTitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '20px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

export type UpgradePlanDialogProps = {
  isOpen: boolean;
  maxPublishedShoppablesCount: number;
  onClose: () => void;
  tenantName: string;
};

export default function UpgradePlanDialog({
  isOpen,
  maxPublishedShoppablesCount,
  onClose,
  tenantName,
}: UpgradePlanDialogProps) {
  const { t } = useTranslation(['plan', 'common']);

  const mdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleClose = () => {
    onClose();
  };

  return (
    <StyledDialog
      PaperProps={{ sx: { borderRadius: '20px', maxWidth: mdScreen ? '80%' : '60%' } }}
      onClose={handleClose}
      open={isOpen}
    >
      <CollectionLocationHeaderContainer>
        <Box>
          <CollectionLocationHeaderTitleContainer>
            <Typography color="error" variant="h6">
              {t('PlanExceeded')}
            </Typography>
          </CollectionLocationHeaderTitleContainer>
        </Box>
      </CollectionLocationHeaderContainer>
      <DialogContent style={{ padding: '0 px' }}>
        <Root>
          <Typography>{t('UpgradeForDisplay', { count: maxPublishedShoppablesCount })}</Typography>
        </Root>
      </DialogContent>
      <DialogActions>
        <UpgradePlanButton tenantName={tenantName} />
        <Button onClick={handleClose} variant="contained">
          {t('common:Cancel')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
