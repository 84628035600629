import Mixpanel from 'mixpanel-browser';

import {
  IdentifyUserParams,
  UserJourneyEvent,
  UserJourneyTracingGateway,
} from '../../core/gateways/user-journey-tracing-gateway';

export default class MixPanelUserJourneyTracingGateway implements UserJourneyTracingGateway {
  constructor(token: string) {
    Mixpanel.init(token, {
      persistence: 'localStorage',
      track_pageview: true, // // will track some non-SPA alternative routes
    });
  }

  identifyUser(params: IdentifyUserParams): void {
    Mixpanel.identify(params.email);

    Mixpanel.people.set({
      $email: params.email,
      $name: params.email,
    });

    Mixpanel.people.unset('Tenant id');
    Mixpanel.people.unset('Tenant name');

    Mixpanel.people.union('Tenant ids', params.tenantId);
    Mixpanel.people.union('Tenant names', params.tenantName);
  }

  trackEvent(event: UserJourneyEvent): void {
    Mixpanel.track(event.name, event.data);
  }
}
