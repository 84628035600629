import { EventId, V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type CustomiseEventThemeRequest = {
  eventId: EventId;
  payload: V1.api.CustomiseEventThemeDTO;
};

export const customiseEventTheme = createAppAsyncThunk(
  'events/customise-theme',
  async ({ eventId, payload }: CustomiseEventThemeRequest, { extra: { eventGateway } }): Promise<void> => {
    await eventGateway.customiseTheme({ dto: payload, eventId });
  },
);
