import { V1 } from '@bellepoque/api-contracts';

import { dtoToShoppableListItemReadModel } from '../../../gateways/shoppables/shoppables-mappers';
import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import { CBOShoppableVideoListItemReadModel } from '../../domain/CBOShoppableVideoReadModel';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetCreateShoppable as resetCreateShoppableAction } from '../../store/slices/shoppables/shoppables-crud.slice';

export type CreateShoppablePayload = {
  dto: V1.api.CreateShoppableVideoDTO;
  product: CBOCatalogProduct;
};

type CreateShoppableParams = {
  payload: CreateShoppablePayload;
  tenantId: string;
};

export const createShoppable = createAppAsyncThunk(
  'shoppables/create-shoppable',
  async (
    { payload, tenantId }: CreateShoppableParams,
    { extra: { shoppablesGateway } },
  ): Promise<CBOShoppableVideoListItemReadModel> => {
    const { dto, product } = payload;

    const createdDTO = await shoppablesGateway.create(dto, tenantId);

    const listReadModel = dtoToShoppableListItemReadModel(createdDTO, product);
    return listReadModel;
  },
);

export const resetCreateShoppable = () => {
  return resetCreateShoppableAction();
};
