import { EventStatus } from '@bellepoque/api-contracts';
import { TFunction } from 'i18next';

export const eventStatusToTranslation = (eventStatus: EventStatus, t: TFunction): string => {
  switch (eventStatus) {
    case EventStatus.DRAFT:
      return t('Draft');
    case EventStatus.FINISHED:
      return t('ProcessingReplay');
    case EventStatus.ON_AIR:
      return t('OnAir');
    case EventStatus.PLANNED:
      return t('Planned');
    case EventStatus.PRIVATE_PREVIEW:
      return t('Test');
    case EventStatus.PRIVATE_REPLAY:
      return t('TestReplay');
    case EventStatus.REPLAY:
      return t('ReplayOnAir');
    case EventStatus.REPLAY_READY:
      return t('ReplayReady');
    case EventStatus.REPLAY_FAILED:
      return t('ReplayFailed');
  }
};
