import { Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledDialog from '../../../templates/dialog/StyledDialog';
import ShopifyButton from '../../atoms/ShopifyButton';

type ConfigureLandingPageMenuModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onGoToShopifyMenu: () => void;
};

const Line = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const DialogContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),
}));

export default function ConfigureLandingPageMenuModal({
  isOpen,
  onClose,
  onGoToShopifyMenu,
}: ConfigureLandingPageMenuModalProps) {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'common']);

  const getDialogContent = () => {
    return (
      <DialogContentContainer>
        <Line>
          {t('ConfigureLiveShoppingPageModal.Step1')}
          <ShopifyButton onClick={onGoToShopifyMenu} sx={{ flex: 'none' }}>
            {t('ConfigureMenu')}
          </ShopifyButton>
        </Line>
        <Typography>{t('ConfigureLiveShoppingPageModal.Step2')}</Typography>
        <Typography>{t('ConfigureLiveShoppingPageModal.Step3')}</Typography>
        <Typography>{t('ConfigureLiveShoppingPageModal.Step4')}</Typography>
      </DialogContentContainer>
    );
  };

  return (
    <StyledDialog
      ButtonsProps={[
        {
          children: t('common:OK'),
          onClick: onClose,
          sx: { marginRight: theme.spacing(2) },
          variant: 'outlined',
        },
      ]}
      content={getDialogContent()}
      onClose={onClose}
      open={isOpen}
      title={t('ConfigureLiveShoppingPageModal.Title')}
    />
  );
}
