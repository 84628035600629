import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface HomeCommandsQueriesState {
  homePageFetching: QueryResult;
}

export type HomeState = HomeCommandsQueriesState & {
  productPagesWithMediaCollectionsCount: number;
  purchasedAmounts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
  purchasedCarts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
  validatedCarts: {
    live: number;
    replay: number;
  };
  validatedCartsAmounts: {
    live: number;
    replay: number;
  };
  views: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
};

export const INITIAL_HOME_STATE: HomeState = {
  homePageFetching: INITIAL_QUERY_RESULT,
  productPagesWithMediaCollectionsCount: 0,
  purchasedAmounts: {
    live: 0,
    replay: 0,
    shoppableVideos: 0,
  },
  purchasedCarts: {
    live: 0,
    replay: 0,
    shoppableVideos: 0,
  },
  validatedCarts: {
    live: 0,
    replay: 0,
  },
  validatedCartsAmounts: {
    live: 0,
    replay: 0,
  },
  views: {
    live: 0,
    replay: 0,
    shoppableVideos: 0,
  },
};
