import { V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { CurrencyGateway } from '../../core/gateways/currency-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiCurrencyGateway extends ApiGateway implements CurrencyGateway {
  fetchAll(): Promise<V1.api.CurrencyDTO[]> {
    const url = `${this.apiEndpoint}/currencies/`;
    return firstValueFrom(this.authenticatedJsonQuery<V1.api.CurrencyDTO[]>({ url }));
  }
}
