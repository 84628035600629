import { V1 } from '@bellepoque/api-contracts';
import { firstValueFrom } from 'rxjs';

import { HomeGateway } from '../../core/gateways/homepage-gateway';
import { ApiGateway } from '../ApiGateway';

export class ApiHomeGateway extends ApiGateway implements HomeGateway {
  async fetchHomepage(tenantId: string): Promise<V1.api.HomepageDTO> {
    return firstValueFrom(
      this.authenticatedJsonQuery<V1.api.HomepageDTO>({
        url: `${this.apiEndpoint}/tenants/${tenantId}/home-page`,
      }),
    );
  }
}
