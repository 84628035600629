import { ButtonProps } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CreateEventRequest } from '../../../../../core/usecases/event/crud/create-event';
import { routes } from '../../../../routes';
import FormDialog from '../../../../templates/dialog/FormDialog';
import CreateEventForm from '../CreateEventForm';
import { createCreateEventFormDialogViewModel } from './CreateEventFormDialog.viewmodel';

type CreateEventFormDialogProps = {
  onClose: () => void;
  open: boolean;
};

export default function CreateEventFormDialog({ onClose, open }: CreateEventFormDialogProps) {
  const navigate = useNavigate();
  const { t } = useTranslation(['events', 'common']);

  const viewModel = useSelector(createCreateEventFormDialogViewModel({ dispatch: useDispatch() }));

  const { createEvent, eventCreationStatus, resetCreateEvent, tenantId, tenantName, trackUserJourneyEvent } = viewModel;

  const form = useForm<CreateEventRequest>({
    defaultValues: {
      description: '',
      publisherPhone: '',
      showTime: null,
      title: '',
    },
    mode: 'onChange',
  });

  const { handleSubmit, formState, watch } = form;

  const { isDirty, isValid } = formState;

  const submissionInProgress = useMemo(() => eventCreationStatus === 'pending', [eventCreationStatus]);

  const buttonsProps: ButtonProps[] = useMemo(
    () => [
      {
        children: <span>{t('common:Cancel')}</span>,
        color: 'primary',
        disabled: submissionInProgress,
        onClick: onClose,
        variant: 'text',
      },
      {
        children: <span>{t('common:Next')}</span>,
        color: 'primary',
        disabled:
          !isDirty ||
          !isValid ||
          submissionInProgress ||
          !watch('title') ||
          !watch('description') ||
          !watch('publisherPhone'),
        onClick: handleSubmit(createEvent),
        type: 'submit',
        variant: 'contained',
      },
    ],
    [formState, submissionInProgress],
  );

  useEffect(() => {
    if (eventCreationStatus === 'success') {
      resetCreateEvent();
      onClose();
      goToEventsList();
    }
  }, [eventCreationStatus]);

  useEffect(() => {
    if (open) {
      trackUserJourneyEvent({
        data: {
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: 'Create event',
      });
    }
  }, [open]);

  const goToEventsList = () => {
    navigate(routes.events.root);
  };

  return (
    <FormDialog
      buttonsProps={buttonsProps}
      loading={submissionInProgress}
      onClose={onClose}
      open={open}
      title={t('NewEvent')}
    >
      <CreateEventForm disabled={submissionInProgress} form={form} hasDialogContainer />
    </FormDialog>
  );
}
