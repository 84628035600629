import { AppDispatch, State } from '../../../core/store';
import { fetchTenantStatistics } from '../../../core/usecases/tenant-statistics/fetch-tenant-statistics';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createAnalyticsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currency: tenantCurrency, id: tenantId, name: tenantName } = state.tenants.currentTenant;

    const isAdmin = Boolean(state.authentication.currentUser.isAdmin);
    const isTutorialOpen = state.tutorial.isShown;
    const tenantStatistics = state.tenantStatistics.tenantStatistics;
    const tenantStatisticsFetchingStatus = state.tenantStatistics.tenantStatisticsFetching.status;

    return {
      fetchTenantStatistics: () => {
        dispatch(fetchTenantStatistics({ tenantId }));
      },
      isAdmin,
      isTutorialOpen,
      tenantCurrency,
      tenantId,
      tenantStatistics,
      tenantStatisticsFetchingStatus,
      trackUserJourneyEvent: () => {
        dispatch(
          trackUserJourneyEvent({
            data: { 'Tenant id': tenantId, 'Tenant name': tenantName },
            name: 'Analytics',
          }),
        );
      },
    };
  };
