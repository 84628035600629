import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type LoginChatParams = {
  authToken: string;
};

export const loginChat = createAppAsyncThunk(
  'chat/login',
  async (params: LoginChatParams, { extra: { chatGateway, tracingGateway } }): Promise<void> => {
    try {
      await chatGateway.login(params.authToken);
    } catch (e: any) {
      console.error('Error while logging in chat', e);
      tracingGateway.captureException(e);
      throw e;
    }
  },
);
