import { V1 } from '@bellepoque/api-contracts';

import { CBOShopifyPage } from '../../core/domain/CBOShopifyPage';

export const toCBOShopifyPage = (dto: V1.api.ShopifyPageDTO): CBOShopifyPage => {
  if (dto.type === 'home') {
    return {
      id: 'home',
      type: 'home',
    };
  }

  // Unused since we make the product pages from the catalog
  if (dto.type === 'product') {
    return {
      id: dto.id,
      image: null,
      title: '',
      type: 'product',
    };
  }

  return {
    id: dto.id,
    title: dto.name,
    type: 'collection',
  };
};

export const manyToCBOShopifyPage = (dtos: V1.api.ShopifyPageDTO[]): CBOShopifyPage[] => {
  return dtos.map((dto) => toCBOShopifyPage(dto));
};
