import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Epic, createEpicMiddleware } from 'redux-observable';

import { Container } from '../di/container';
import { rootEpic } from './epics';
import { Action, rootReducer } from './slices';
import { State } from './state';

type AppEpicDependencies = {
  container: Container;
  store: AppStore;
};

export type AppEpic = Epic<Action, Action, State, AppEpicDependencies>;
export type { State } from './state';

export const createStore = (container: Container, preloadedState: State) => {
  const epicMiddleware = createEpicMiddleware<Action, Action, State, Container>({
    dependencies: container,
  });

  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = configureStore({
    enhancers: [sentryReduxEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: container,
        },
      }).concat(epicMiddleware),
    preloadedState,
    reducer: rootReducer,
  });

  epicMiddleware.run((action$, state$) => {
    return rootEpic(action$, state$, { container, store });
  });

  return store;
};

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = ThunkDispatch<State, Container, AnyAction>;
