import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';

import { EventBillingReport, MonthBillingReports } from '../../../../core/domain/Reports';
import { addReportValue } from '../../../../utils/add-report-value';
import { BillingMonthReporting, BillingMonthReportingReport } from '../../atoms/reports/MonthReporting';

type Totals = {
  liveUsageInMin: number;
  nbSentSMS: number;
  replayUsageInMin: number;
};

interface MonthDetailsProps {
  defaultOpened: boolean;
  monthName: string;
  reports: MonthBillingReports;
}

const compareByShowTime = (a: BillingMonthReportingReport, b: BillingMonthReportingReport) =>
  a.report.showTime > b.report.showTime ? -1 : 1;

export default function BillingMonthDetails({ defaultOpened, monthName, reports }: MonthDetailsProps) {
  const totals = Object.values<EventBillingReport>(reports).reduce<Totals>(
    (acc, value) => ({
      liveUsageInMin: addReportValue(acc.liveUsageInMin, value.liveUsageInMin),
      nbSentSMS: addReportValue(acc.nbSentSMS, value.nbSentSMS),
      replayUsageInMin: addReportValue(acc.replayUsageInMin, value.replayUsageInMin),
    }),
    {
      liveUsageInMin: 0,
      nbSentSMS: 0,
      replayUsageInMin: 0,
    },
  );

  const builtReports: BillingMonthReportingReport[] = Object.entries<EventBillingReport>(reports)
    .map<BillingMonthReportingReport>(([eventId, report]) => ({
      eventId,
      report,
    }))
    .sort(compareByShowTime);

  return (
    <Accordion defaultExpanded={defaultOpened}>
      <AccordionSummary aria-controls="month-details" expandIcon={<ExpandMoreIcon />} id={`month-details-${monthName}`}>
        <Typography sx={{ flexShrink: 0, textAlign: 'left', width: '40%' }}>{monthName}</Typography>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{Math.ceil(totals.liveUsageInMin)}</Typography>
        </Box>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{Math.ceil(totals.replayUsageInMin)}</Typography>
        </Box>
        <Box sx={{ width: '20%' }}>
          <Typography sx={{ color: 'text.secondary' }}>{totals.nbSentSMS}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pr: 5 }}>
        <BillingMonthReporting reports={builtReports} />
      </AccordionDetails>
    </Accordion>
  );
}
