export const chatListStyle = (context) => {
  return {
    backgroundColor: `${context.theme.backgroundColor.white}`,
    zIndex: '1',
    width: '100%',
    flex: '1 1 0',
    order: '2',
    position: 'relative',
    '.list__scrolltobottombutton': {
      position: "absolute",
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%)',
      zIndex: 1000,
      fontSize: "10px"
      },
  };
};

export const listWrapperStyle = () => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    top: '0',
    transition: 'background .3s ease-out .1s',
    width: '100%',
    zIndex: '100',
    paddingTop: '16px',
    
  };
};

export const pinMessageStyle = () => {
  return {
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    borderBottom: '1px solid #eaeaea',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '5px 10px',
    textAlign: 'center',
    width: '100%',
    '.pin-message__content': {
      flex: 1,
      paddingLeft: '24px',
      p: {
        margin: 0,
        textAlign: 'center',
      },
      small: {
        fontSize: '.7em',
      },
    },
    '.pin-message__icon': {
      height: '20px',
      width: '20px',
    },
  };
};

export const pinMessageButtonStyle = (img) => {
  return {
    alignItems: 'center',
    backgroundColor: '#FFAA00',
    border: '0',
    cursor: 'pointer',
    display: 'inline-flex',
    fill: '#FFAA00',
    height: '24px',
    justifyContent: 'center',
    mask: `url(${img}) center center no-repeat`,
    outline: '0',
    position: 'relative',
    width: '24px',
  };
};

export const messageDateContainerStyle = () => {
  return {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
  };
};

export const messageDateStyle = (context) => {
  return {
    padding: '8px 12px',
    backgroundColor: `${context.theme.backgroundColor.secondary}`,
    color: `${context.theme.color.primary}`,
    borderRadius: '10px',
  };
};

export const decoratorMessageStyle = () => {
  return {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
  };
};

export const decoratorMessageTxtStyle = (context) => {
  return {
    margin: '0',
    height: '36px',
    color: `${context.theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
    lineHeight: '30px',
  };
};
