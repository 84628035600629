import { PlayArrow } from '@mui/icons-material';
import { Box, Button, Link, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeAutoplayUrl } from '../../../../../utils/replay-links';
import ButtonWithTextChangeTimeout from '../../../atoms/ButtonWithTextChangeTimeout';
import ReplayLink from '../../../atoms/event/ReplayLink';

interface LiveReplayLinksProps {
  areLinksShown: boolean;
  arePlayButtonsShown: boolean;
  eventId: string;
  landingPageUrl: string | null;
  onCopyUrl: (url: string) => void;
}

export default function LiveReplayLinks({
  areLinksShown,
  arePlayButtonsShown,
  eventId,
  landingPageUrl,
  onCopyUrl,
}: LiveReplayLinksProps) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const replayUrl = makeAutoplayUrl({ baseUrl: landingPageUrl, eventId });

  return (
    <Box alignItems="center" display="flex" gap={2} justifyContent={areLinksShown ? 'space-between' : 'flex-end'}>
      {areLinksShown && <ReplayLink onClick={() => onCopyUrl(replayUrl)}>{replayUrl}</ReplayLink>}
      <ButtonWithTextChangeTimeout
        ButtonProps={{
          color: 'inherit',
          onClick: () => onCopyUrl(replayUrl),
          sx: {
            borderColor: theme.palette.grey[400],
            color: theme.palette.common.black,
            px: 4,
            textTransform: 'initial',
            width: '140px',
          },
          variant: 'outlined',
        }}
        initialText={t(areLinksShown ? 'Copy' : 'CopyLink')}
        secondText={t(areLinksShown ? 'Copied' : 'LinkCopied')}
      />

      {arePlayButtonsShown && (
        <Link href={replayUrl} target="_blank" underline="none">
          <Button
            color="secondary"
            startIcon={<PlayArrow />}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              px: 4,
              textTransform: 'initial',
            }}
            variant="contained"
          >
            {t('Play')}
          </Button>
        </Link>
      )}
    </Box>
  );
}
