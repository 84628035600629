import { TenantId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type UpdateAliasParams = {
  alias: string;
  tenantId: TenantId;
};

export const updateAlias = createAppAsyncThunk(
  'tenants/update-alias',
  async (params: UpdateAliasParams, { extra: { tenantsGateway } }): Promise<void> => {
    return tenantsGateway.updateAlias(params.tenantId, params.alias);
  },
);
