import { SxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import { closedDrawerWidth, drawerWidth } from './MiniDrawer';

interface ContentHeaderProps {
  children?: React.ReactNode;
  isDrawerOpen: boolean;
  isShadowHidden?: boolean;
  isTutorialOpen?: boolean;
  sx?: SxProps;
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen' && prop !== 'isShadowHidden' && prop !== 'isTutorialOpen',
})<{ isDrawerOpen: boolean; isShadowHidden: boolean }>(({ isShadowHidden, isDrawerOpen, theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: isShadowHidden ? 'none' : '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14)',
  display: 'flex',
  left: closedDrawerWidth,
  position: 'fixed',
  top: '64px',
  transition: theme.transitions.create(['width', 'left'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: `calc(100% - ${closedDrawerWidth}px)`,
  zIndex: theme.zIndex.drawer - 2,
  ...(isDrawerOpen && {
    left: drawerWidth,
    transition: theme.transitions.create(['width', 'left'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

export default function FixedContentHeader({
  children,
  isShadowHidden = false,
  isDrawerOpen,
  sx = {},
}: ContentHeaderProps) {
  return (
    <Container isDrawerOpen={isDrawerOpen} isShadowHidden={isShadowHidden} sx={sx}>
      {children}
    </Container>
  );
}
