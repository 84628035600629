import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type DeleteMediaCollectionRequest = {
  id: string;
  tenantId: string;
};

export const deleteMediaCollection = createAppAsyncThunk(
  'media-collections/delete-media-collection',
  async ({ id, tenantId }: DeleteMediaCollectionRequest, { extra: { mediaCollectionsGateway } }): Promise<void> => {
    return mediaCollectionsGateway.deleteOne({ id, tenantId });
  },
);
