import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ShoppablesSettingsFilesUploads } from '../../../core/gateways/shoppables-gateway';
import { UpdateMediaCollectionSettingsRequest } from '../../../core/usecases/media-collections/update-media-collection-settings';
import { UpdateShoppablesSettingsPayload } from '../../../core/usecases/shoppables/update-shoppables-settings';
import { routes } from '../../routes';
import ShoppablesTemplate from '../../templates/ShoppablesTemplate';
import { createShoppablesViewModel } from './Shoppables.viewmodel';

const Shoppables = () => {
  const viewModel = useSelector(createShoppablesViewModel({ dispatch: useDispatch() }));

  const navigate = useNavigate();

  const {
    canPublishShoppables,
    canUploadFile,
    copyShoppableId,
    creationStatus,
    currentShoppable,
    deleteShoppable,
    deletionStatus,
    fetchCurrentShoppableCatalogProduct,
    fetchMediaCollections,
    fetchPlayerPlaylistsBlock,
    fetchShoppable,
    fetchShoppables,
    fetchShoppablesSettings,
    fetchShoppablesSettingsFileUploadUrls,
    fetchShoppableVideoFileUploadUrl,
    fileUploadStatus,
    fileUploadUrl,
    maxPublishedShoppablesCount,
    mediaCollections,
    mediaCollectionSettingsUpdateStatus,
    onUploadVideoFromSocialMedia,
    publishedShoppablesCount,
    resetUpdateMediaCollectionSettings,
    resetUpdateShoppablesSettings,
    resetUploadFile,
    shoppables,
    shoppablesFetchingStatus,
    shoppablesSettings,
    shoppablesSettingsFileUploadingStatus,
    shoppablesSettingsFileUploadUrls,
    shoppablesSettingsFileUploadUrlsFetchingStatus,
    tenantCurrency,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateMediaCollectionSettings,
    updateShoppablesSettings,
    updateShoppablesSettingsStatus,
    uploadFile,
    uploadShoppablesSettingsFiles,
    uploadVideoFromSocialMediaStatus,
  } = viewModel;

  useEffect(() => {
    fetchMediaCollections();
    fetchShoppables();
    fetchShoppablesSettings();
  }, [tenantId]);

  useEffect(() => {
    trackUserJourneyEvent();
  }, []);

  useEffect(() => {
    if (creationStatus === 'success') {
      if (!shoppablesSettings) {
        fetchShoppablesSettings();
      }
    }
  }, [creationStatus]);

  const shouldCheckForPlayerPlaylistsBlock = useMemo(
    () => !shoppables.some((shoppable) => shoppable.status === 'PUBLISHED'),
    [shoppables],
  );

  useEffect(() => {
    if (updateShoppablesSettingsStatus === 'success') {
      resetUpdateShoppablesSettings();
    }
  }, [updateShoppablesSettingsStatus]);

  useEffect(() => {
    if (!currentShoppable?.videoUrl) {
      fetchShoppableVideoFileUploadUrl();
    }
  }, [currentShoppable?.videoUrl]);

  useEffect(() => {
    if (currentShoppable) {
      fetchCurrentShoppableCatalogProduct();
    }
  }, [currentShoppable?.productId]);

  useEffect(() => {
    if (tenantId !== '' && shouldCheckForPlayerPlaylistsBlock) {
      fetchPlayerPlaylistsBlock();
    }
  }, [tenantId]);

  const handleCopyIdShoppable = (shoppableId: string) => {
    copyShoppableId(shoppableId);
  };

  const handleDeleteShoppable = (shoppableId: string) => {
    deleteShoppable(shoppableId);
  };

  const handleFetchShoppable = (shoppableId: string) => {
    if (currentShoppable?.id === shoppableId) return;
    fetchShoppable(shoppableId);
  };

  const handleFetchShoppablesSettingsFileUploadUrls = () => {
    fetchShoppablesSettingsFileUploadUrls();
  };

  const handleResetUploadFile = () => {
    resetUploadFile();
  };

  const handleUploadVideo = (file: File) => {
    uploadFile(file);
  };

  const handleUpdateShoppablesSettings = (payload: UpdateShoppablesSettingsPayload) => {
    updateShoppablesSettings(payload);
  };

  const handleUploadShoppablesSettingsFiles = (files: ShoppablesSettingsFilesUploads) => {
    uploadShoppablesSettingsFiles(files);
  };

  const handleGoToNewMediaCollection = (shoppableId: string) => {
    navigate(routes.playlists.new({ mediaCollectionMediaId: shoppableId }));
  };

  const handleGoToMediaCollection = (mediaCollectionId: string) => {
    navigate(routes.playlists.mediaCollection({ mediaCollectionId }));
  };

  const handleUpdateMediaCollectionSettings = (
    mediaCollectionId: string,
    payload: UpdateMediaCollectionSettingsRequest['payload'],
  ) => {
    updateMediaCollectionSettings(mediaCollectionId, payload);
  };

  useEffect(() => {
    if (mediaCollectionSettingsUpdateStatus === 'success') resetUpdateMediaCollectionSettings();
  }, [mediaCollectionSettingsUpdateStatus]);

  return (
    <ShoppablesTemplate
      canPublishShoppables={canPublishShoppables}
      canUploadFile={canUploadFile}
      creationStatus={creationStatus}
      currentShoppable={currentShoppable}
      deletionStatus={deletionStatus}
      fileUploadStatus={fileUploadStatus}
      fileUploadUrl={fileUploadUrl}
      maxPublishedShoppablesCount={maxPublishedShoppablesCount}
      mediaCollectionSettingsUpdateStatus={mediaCollectionSettingsUpdateStatus}
      mediaCollections={mediaCollections}
      onCopyIdShoppable={handleCopyIdShoppable}
      onDeleteShoppable={handleDeleteShoppable}
      onFetchShoppable={handleFetchShoppable}
      onGetShoppablesSettingsSecuredFileUploadUrls={handleFetchShoppablesSettingsFileUploadUrls}
      onGoToMediaCollection={handleGoToMediaCollection}
      onGoToNewMediaCollection={handleGoToNewMediaCollection}
      onUpdateMediaCollectionSettings={handleUpdateMediaCollectionSettings}
      onUpdateShoppablesSettings={handleUpdateShoppablesSettings}
      onUploadShoppablesSettingsFiles={handleUploadShoppablesSettingsFiles}
      onUploadVideo={handleUploadVideo}
      onUploadVideoFromSocialMedia={onUploadVideoFromSocialMedia}
      publishedShoppablesCount={publishedShoppablesCount}
      resetUploadFile={handleResetUploadFile}
      shoppables={shoppables}
      shoppablesFetchingStatus={shoppablesFetchingStatus}
      shoppablesSettings={shoppablesSettings}
      shoppablesSettingsFileUploadUrls={shoppablesSettingsFileUploadUrls}
      shoppablesSettingsFileUploadUrlsFetchingStatus={shoppablesSettingsFileUploadUrlsFetchingStatus}
      shoppablesSettingsFileUploadingStatus={shoppablesSettingsFileUploadingStatus}
      tenantCurrency={tenantCurrency}
      tenantId={tenantId}
      tenantName={tenantName}
      updateShoppablesSettingsStatus={updateShoppablesSettingsStatus}
      uploadVideoFromSocialMediaStatus={uploadVideoFromSocialMediaStatus}
    />
  );
};

export default Shoppables;
