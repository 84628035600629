import { UserJourneyEvent } from '../../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../../core/store';
import { fetchMagicContent } from '../../../../../../core/usecases/event/magic-content/fetch-magic-content';
import { generateMagicContent } from '../../../../../../core/usecases/event/magic-content/generate-magic-content';
import { trackUserJourneyEvent } from '../../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

const transcriptReleaseDate = new Date(process.env.REACT_APP_TRANSCRIPT_RELEASE_DATE || '');

// TODO: Test view model
export const createMagicContentsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currentEvent, currentEventMagicContents, isTranscriptAvailable } = state.events;
    const tenantId = state.tenants.currentTenantId;
    const tenantName = state.tenants.currentTenant.name;

    const wasEventAiredAfterTranscriptReleaseDate =
      !!currentEvent?.showTime && currentEvent.showTime > transcriptReleaseDate;

    return {
      currentEvent,
      eventId: currentEvent?.id,
      fetchMagicContents: () => {
        if (currentEvent) {
          dispatch(fetchMagicContent({ eventId: currentEvent.id }));
        }
      },
      generateMagicContent: () => {
        if (currentEvent) {
          dispatch(generateMagicContent({ eventId: currentEvent.id }));
        }
      },
      isTranscriptAvailable,
      magicContents: currentEventMagicContents,
      magicContentsFetchingStatus: state.events.magicContentFetching.status,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      transcriptReleaseDate,
      wasEventAiredAfterTranscriptReleaseDate,
    };
  };
