import { ofType as _ofType } from 'redux-observable';
import { Observable } from 'rxjs';

import { Action as Actions } from '../slices';

export function ofType<Action extends Actions, Type extends Action['type'] = Action['type']>(
  ...key: [Type, ...Type[]]
): (source: Observable<Actions>) => Observable<Action> {
  return _ofType<Actions, Type, Action>(...key);
}
