import { YoutubeAccessToken } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export const exchangeYoutubeCode = createAppAsyncThunk(
  'youtube/exchange-code',
  async (code: string, { extra: { youtubeGateway } }): Promise<YoutubeAccessToken> => {
    return youtubeGateway.fetchLongLivedAccessToken(code);
  },
);
