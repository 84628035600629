import { Typography, styled } from '@mui/material';
import React from 'react';

type OnboardingTitleProps = {
  children: React.ReactNode;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  display: 'block',
  fontSize: '2em',
  fontWeight: 'bold',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

export default function OnboardingTitle(props: OnboardingTitleProps) {
  return <StyledTypography variant="titleFont" {...props} />;
}
