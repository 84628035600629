import { BillingReports } from '../../domain/Reports';
import { FetchBillingReportsFilters } from '../../gateways/reports-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const fetchBillingReports = createAppAsyncThunk(
  'reports/fetch-billing-reports',
  async (request: FetchBillingReportsFilters, { extra: { reportsGateway } }): Promise<BillingReports> => {
    return reportsGateway.fetchBillingReports(request);
  },
);
