import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value !== index) {
    return null;
  }

  return (
    <Box
      aria-labelledby={`vertical-tab-${index}`}
      display="flex"
      flex={1}
      height="100%"
      id={`vertical-tabpanel-${index}`}
      maxHeight="100%"
      role="tabpanel"
      width="100%"
      {...other}
    >
      {children}
    </Box>
  );
}
