import { BILLING_PLANS, Cms, EventStatus } from '@bellepoque/api-contracts';
import { Star } from '@mui/icons-material';
import { Box, Button, SxProps, Typography, styled, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SetIntervalAsyncTimer, clearIntervalAsync, setIntervalAsync } from 'set-interval-async/dynamic';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import { MobileAppsPlatforms } from '../../../../../core/domain/MobileAppPlatforms';
import { State } from '../../../../../core/store';
import { fetchOneEventChatCredentials } from '../../../../../core/usecases/event/chat/fetch-event-chat-credentials';
import { pinMessage } from '../../../../../core/usecases/event/chat/pin-message';
import { unpinMessage } from '../../../../../core/usecases/event/chat/unpin-message';
import { closeEvent, resetCloseEvent } from '../../../../../core/usecases/event/live/close-event';
import { displayProduct } from '../../../../../core/usecases/event/live/display-product';
import { openEvent } from '../../../../../core/usecases/event/live/open-event';
import { openReplay } from '../../../../../core/usecases/event/live/open-replay';
import { publishEvent } from '../../../../../core/usecases/event/live/publish-event';
import { publishReplay } from '../../../../../core/usecases/event/live/publish-replay';
import { toggleLiveViewersCountDisplay } from '../../../../../core/usecases/event/live/toggle-live-viewers-count-display';
import { unpublishEvent } from '../../../../../core/usecases/event/live/unpublish-event';
import { fetchLiveEventStatistics } from '../../../../../core/usecases/event/statistics/fetch-live-event-statistics';
import { updateLandingPage } from '../../../../../core/usecases/landing-page/update-landing-page';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import CameraAppIcon from '../../../../../icons/CameraAppIcon';
import LivePageHighlightIcon from '../../../../../icons/LivePageHighlightIcon';
import { eventToUserJourneyData } from '../../../../../utils/event-to-user-journey-data';
import ConfirmationDialog from '../../../../templates/dialog/ConfirmationDialog';
import PhoneNumber from '../../../atoms/onboarding/PhoneNumber';
import SettingsSection from '../../../molecules/SettingsSection';
import LiveStats from '../../../molecules/event/LiveStats';
import LiveManagerHeader from '../../../molecules/event/live-manager/LiveManagerHeader';
import OBSSection from '../../../molecules/event/live-manager/OBSSection';
import StoreLink from '../../../molecules/tutorial/StoreLinks';
import Chat from '../chat/Chat';
import ProductsShowcase from './ProductsShowcase';

const Root = styled(Box)(({ theme }) => ({
  '.MuiBackdrop-root': {
    opacity: '0.4 !important',
  },
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: `calc(100vh - 64px)`,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  borderTop: `0.5px solid ${theme.palette.grey[400]}`,
  display: 'flex',
  flex: 1,
  height: '100%',
  minHeight: 0,
  position: 'relative',
  width: '100%',
}));

const StoreLinksContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(5),
  justifyContent: 'center',
}));

const PhoneNumberWithTextContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'center',
}));

const Column = styled(Box)({
  flex: 1,
  width: '50%',
});

const FloatedColumn = styled(Column)(({ theme }) => ({
  background: theme.palette.grey[100],
  overflow: 'scroll',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(9),
}));

interface LiveDetailsProps {
  disableOpenToPublicConfirmationModal?: boolean;
  event: CBOEventReadModel;
  sx?: SxProps;
}

// TODO: Use a view model
export default function LiveWithChat({
  disableOpenToPublicConfirmationModal = false,
  event,
  sx = {},
}: LiveDetailsProps) {
  const theme = useTheme();
  const { t } = useTranslation(['events']);
  const dispatch = useDispatch();
  const currentTenant = useSelector((state: State) => state.tenants.currentTenant);
  const { hasCmsLandingPage, landingPageData } = useSelector((state: State) => state.landingPage);
  const { enqueueSnackbar } = useSnackbar();

  const { currentEvent, currentEventRealtimeData, currentLiveEventStatistics, displayedProducts } = useSelector(
    (state: State) => state.events,
  );

  const { status: productsDisplayStatus } = useSelector((state: State) => state.events.productsDisplay);
  const { status: realtimeDataFetchingStatus } = useSelector((state: State) => state.events.realtimeDataFetching);
  const { status: eventClosingStatus } = useSelector((state: State) => state.events.eventClosing);
  const { status: eventOpeningStatus } = useSelector((state: State) => state.events.eventOpening);
  const { status: eventPublishStatus } = useSelector((state: State) => state.events.eventPublish);
  const { status: eventUnPublishStatus } = useSelector((state: State) => state.events.eventUnPublish);
  const { status: replayOpeningStatus } = useSelector((state: State) => state.events.replayOpening);
  const { status: replayPublishStatus } = useSelector((state: State) => state.events.replayPublish);

  const [isCloseEventConfirmationDialogOpen, setIsCloseEventConfirmationDialogOpen] = useState(false);
  const [isOpenEventConfirmationDialogOpen, setIsOpenEventConfirmationDialogOpen] = useState(false);

  const hasObsEnabled =
    BILLING_PLANS[currentTenant.activeSubscriptionId].obsEnabled || currentTenant.cms !== Cms.Shopify;

  const eventStatus = currentEventRealtimeData?.status;
  const pinnedChatMessages = currentEventRealtimeData?.pinnedChatMessages;

  const isEventOnAir = eventStatus === EventStatus.PRIVATE_PREVIEW || eventStatus === EventStatus.ON_AIR;
  const isHighlightEventSectionVisible = useMemo(
    () => hasCmsLandingPage && !landingPageData.highlightedEventsIds.includes(event.id),
    [],
  );

  const pinnedChatMessage = useMemo(() => {
    if (!pinnedChatMessages || pinnedChatMessages.length === 0) return null;
    const message = pinnedChatMessages[pinnedChatMessages.length - 1];
    if (message.unpinnedAt) return null;
    return message;
  }, [pinnedChatMessages]);

  const isLiveViewersCountDisplaySwitchShown = useMemo(
    () => eventStatus === EventStatus.PRIVATE_PREVIEW || eventStatus === EventStatus.ON_AIR,
    [eventStatus],
  );

  const hasInstagramRestreamingEnabled = currentEvent?.restreamingConfigurations.some(
    ({ enabled, type }) => enabled && type === 'Instagram',
  );

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': currentTenant.id,
          'Tenant name': currentTenant.name,
        },
        name: 'LiveManager',
      }),
    );
  }, []);

  useEffect(() => {
    if (
      eventStatus !== EventStatus.FINISHED &&
      eventStatus !== EventStatus.ON_AIR &&
      eventStatus !== EventStatus.PRIVATE_PREVIEW &&
      eventStatus !== EventStatus.REPLAY
    ) {
      return;
    }

    getLiveStatistics();

    let intervalId: SetIntervalAsyncTimer;
    if (eventStatus === EventStatus.PRIVATE_PREVIEW || eventStatus === EventStatus.ON_AIR) {
      intervalId = setIntervalAsync(getLiveStatistics, 7500);
    }

    return () => {
      if (intervalId) {
        clearIntervalAsync(intervalId);
      }
    };
  }, [dispatch, event.id, eventStatus]);

  useEffect(() => {
    if (isEventOnAir) {
      dispatch(fetchOneEventChatCredentials({ eventId: event.id }));
    }
  }, [event.id, isEventOnAir]);

  useEffect(() => {
    if (eventClosingStatus === 'success') {
      dispatch(resetCloseEvent());
    }
  }, [eventClosingStatus]);

  const getLiveStatistics = () => {
    dispatch(fetchLiveEventStatistics({ eventId: event.id }));
  };

  const handleCloseCloseEventConfirmationModal = (yesOrNo: boolean) => {
    setIsCloseEventConfirmationDialogOpen(false);
    if (yesOrNo === true) {
      handleCloseEvent();
    }
  };

  const handleDisplayProduct = (product: CBOEventReadModelProduct) => {
    dispatch(
      displayProduct({
        eventId: event.id,
        productToDisplay: product,
      }),
    );
  };

  const handleCloseOpenEventConfirmationModal = (yesOrNo: boolean) => {
    setIsOpenEventConfirmationDialogOpen(false);
    if (yesOrNo === true) {
      handleOpenEvent();
    }
  };

  const handleToggleLiveViewersCountDisplay = (isDisplayed: boolean) => {
    dispatch(toggleLiveViewersCountDisplay({ eventId: event.id, isDisplayed }));
  };

  const handlePinMessage = (content: string, messageId: string) => {
    dispatch(pinMessage({ content, eventId: event.id, messageId }));
  };

  const handleUnpinMessage = () => {
    if (!pinnedChatMessage) return;
    dispatch(unpinMessage({ eventId: event.id, messageId: pinnedChatMessage.messageId }));
  };

  const handleCloseEvent = () => {
    dispatch(
      closeEvent({
        eventId: event.id,
      }),
    );
  };

  const handleOpenEvent = () => {
    dispatch(
      openEvent({
        eventId: event.id,
      }),
    );
  };
  const handleOpenReplay = () => {
    dispatch(
      openReplay({
        eventId: event.id,
      }),
    );
  };
  const handlePublishEvent = () => {
    dispatch(
      publishEvent({
        eventId: event.id,
      }),
    );
  };
  const handlePublishReplay = () => {
    dispatch(
      publishReplay({
        eventId: event.id,
      }),
    );
  };
  const handleUnpublishEvent = () => {
    dispatch(
      unpublishEvent({
        eventId: event.id,
      }),
    );
  };

  const handleClickCopyStreamKey = useCallback(() => {
    if (event.videoStreamChannel?.streamKey) {
      navigator.clipboard.writeText(event.videoStreamChannel.streamKey.key);
      enqueueSnackbar(t('notifications.StreamKeySuccessfullyCopied'), { variant: 'success' });
    }
  }, [event.videoStreamChannel?.streamKey, t]);

  const handleClickCopyStreamUrl = useCallback(() => {
    if (event.videoStreamChannel?.streamKey) {
      navigator.clipboard.writeText(event.videoStreamChannel.streamKey.url);
      enqueueSnackbar(t('notifications.StreamUrlSuccessfullyCopied'), { variant: 'success' });
    }
  }, [event.videoStreamChannel?.streamKey, t]);

  const handleHighlightLivePage = useCallback(() => {
    if (!landingPageData.highlightedEventsIds.includes(event.id)) {
      dispatch(
        updateLandingPage({
          payload: {
            ...landingPageData,
            highlightedEventsIds: [event.id, ...landingPageData.highlightedEventsIds],
          },
          tenantId: event.tenantId,
        }),
      );
    }
  }, [dispatch, event, landingPageData]);

  return (
    <Root id="live-manager-with-chat-root" sx={sx}>
      <LiveManagerHeader
        event={event}
        eventClosingStatus={eventClosingStatus}
        eventOpeningStatus={eventOpeningStatus}
        eventPublishStatus={eventPublishStatus}
        eventUnPublishStatus={eventUnPublishStatus}
        onCloseEvent={() => setIsCloseEventConfirmationDialogOpen(true)}
        onOpenEvent={() =>
          disableOpenToPublicConfirmationModal ? handleOpenEvent() : setIsOpenEventConfirmationDialogOpen(true)
        }
        onOpenReplay={handleOpenReplay}
        onPublishEvent={handlePublishEvent}
        onPublishReplay={handlePublishReplay}
        onUnpublishEvent={handleUnpublishEvent}
        replayOpeningStatus={replayOpeningStatus}
        replayPublishStatus={replayPublishStatus}
      />
      <Box width="100%">
        <LiveStats
          currency={currentTenant.currency}
          currentLiveEventStatistics={currentLiveEventStatistics}
          isLiveViewersCountDisplaySwitchShown={isLiveViewersCountDisplaySwitchShown}
          isLiveViewersCountDisplayed={currentEvent?.isLiveViewersCountDisplayed ?? true}
          onToggleLiveViewersCountDisplay={handleToggleLiveViewersCountDisplay}
        />
      </Box>

      <ContentContainer id="central-panel">
        <Column>
          <ProductsShowcase
            canProductsBeDisplayed={isEventOnAir}
            currency={currentTenant.currency}
            currentLiveEventStatistics={currentLiveEventStatistics}
            displayedProducts={displayedProducts}
            onDisplayProduct={handleDisplayProduct}
            products={event.products}
            productsDisplayStatus={productsDisplayStatus}
            realtimeDataFetchingStatus={realtimeDataFetchingStatus}
          />
        </Column>
        {isEventOnAir ? (
          <Column>
            <Chat
              onPinMessage={handlePinMessage}
              onUnpinMessage={handleUnpinMessage}
              pinnedChatMessage={pinnedChatMessage}
            />
          </Column>
        ) : (
          <FloatedColumn flex={1} width="50%">
            <SettingsSection
              icon={<CameraAppIcon sx={{ color: theme.palette.common.white }} />}
              roundTop
              title={t('OpenCameraApp')}
            >
              <StoreLinksContainer>
                <StoreLink variant={MobileAppsPlatforms.ios} />
                <StoreLink variant={MobileAppsPlatforms.android} />
              </StoreLinksContainer>
              <PhoneNumberWithTextContainer>
                <Typography textAlign="initial">{t('OpenCameraAppGivePhoneNumber')}</Typography>
                <PhoneNumber>{currentEvent?.publisherPhone}</PhoneNumber>
              </PhoneNumberWithTextContainer>
            </SettingsSection>
            {event.videoStreamChannel?.streamKey && (
              <OBSSection
                enabled={hasObsEnabled}
                onCopyStreamKey={handleClickCopyStreamKey}
                onCopyStreamUrl={handleClickCopyStreamUrl}
                roundBottom={!isHighlightEventSectionVisible}
                tenantName={currentTenant.name}
              />
            )}
            {isHighlightEventSectionVisible && (
              <SettingsSection
                direction="row"
                icon={<LivePageHighlightIcon sx={{ color: theme.palette.common.white }} />}
                roundBottom
                sx={{ whiteSpace: 'nowrap' }}
                title={t('LivePageDisplay')}
              >
                <Button
                  color="primary"
                  disabled={landingPageData.highlightedEventsIds.includes(event.id)}
                  onClick={handleHighlightLivePage}
                  startIcon={<Star />}
                  sx={{ textTransform: 'capitalize' }}
                  variant="contained"
                >
                  <span>{t('DisplayEvent')}</span>
                </Button>
              </SettingsSection>
            )}
          </FloatedColumn>
        )}
      </ContentContainer>

      <ConfirmationDialog
        id="close-event-confirmation"
        keepMounted={true}
        onClose={handleCloseCloseEventConfirmationModal}
        open={isCloseEventConfirmationDialogOpen}
        title={t('Close')}
      />
      <ConfirmationDialog
        confirm={t('Open')}
        content={
          <Trans
            components={{ Bold: <Typography sx={{ display: 'inline', fontWeight: 'bold' }} /> }}
            i18nKey={hasInstagramRestreamingEnabled ? 'OpenInstagramWarning' : 'OpenWarning'}
            ns="events"
          />
        }
        id="open-event-confirmation"
        keepMounted={true}
        onClose={handleCloseOpenEventConfirmationModal}
        open={isOpenEventConfirmationDialogOpen}
        refuse={t('Cancel')}
        title={t('Open')}
      />
    </Root>
  );
}
