import React from 'react';
import { FormEvent, useState } from 'react';

interface State {
  content: string;
  date?: string;
  password: string;
  status: 'done-with-error' | 'done' | 'in-progress' | 'not-requested';
}

const defaultState: State = {
  content: '',
  date: undefined,
  password: '',
  status: 'not-requested',
};

const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/publishing-app-review-otp`;

const requestCodeFromApi = async (password: string) => {
  const requestOptions = {
    body: JSON.stringify({ password }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  };
  return fetch(apiEndpoint, requestOptions);
};

export default function AppReviewOTP() {
  const [state, setState] = useState<State>(defaultState);

  const requestCode = async () => {
    const response = await requestCodeFromApi(state.password);
    const isJson = response.headers.get('content-type')?.includes('application/json');
    const data = isJson && (await response.json());

    // check for error response
    if (response.status >= 400) {
      if (response.status === 404) {
        return setState((state) => ({
          ...state,
          content: 'No message found',
          date: undefined,
          status: 'done',
        }));
      }
      return setState(defaultState);
    }

    setState((state) => ({
      ...state,
      content: data.content,
      date: data.date,
      status: 'done',
    }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setState((state) => ({ ...state, status: 'in-progress' }));
    requestCode();
  };

  const renderResult = () => {
    if (state.status === 'in-progress') {
      return <p>Loading...</p>;
    }

    return (
      <div>
        <code>{state.content}</code>
        {state.date && <p>Sent at {state.date}</p>}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <p>Input the password provided in the review instructions</p>
          <input
            name="password"
            onChange={(event) => setState((state) => ({ ...state, password: event.target.value }))}
            placeholder="Password"
            type="password"
            value={state.password}
          />
          <button type="submit">Get code</button>
        </form>
      </div>
    );
  };

  return state.status === 'not-requested' ? renderForm() : renderResult();
}
