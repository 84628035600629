import { CBOKlaviyoList } from '../../domain/CBOKlaviyoList';
import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface KlaviyoCommandsQueriesState {
  klaviyoListsFetching: QueryResult;
}

export interface KlaviyoState extends KlaviyoCommandsQueriesState {
  lists: CBOKlaviyoList[] | null;
}

export const INITIAL_KLAVIYO_STATE: KlaviyoState = {
  klaviyoListsFetching: INITIAL_QUERY_RESULT,
  lists: null,
};
