import { V1 } from '@bellepoque/api-contracts';
import { FormControl, FormHelperText, Grid, TextField, alpha, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input/mobile';

import { CreateEventRequest } from '../../../../core/usecases/event/crud/create-event';
import EventSettingsIcon from '../../../../icons/EventSettingsIcon';
import StreamingIcon from '../../../../icons/StreamingIcon';
import DateTimePicker from '../../atoms/inputs/DateTimePicker';
import InputTooltip from '../../atoms/inputs/InputTooltip';
import SettingsSection from '../../molecules/SettingsSection';

const constraints = V1.api.constraints.events;

export type CreateEventFormProps = {
  autoFocusPhoneNumberInput?: boolean;
  disabled?: boolean;
  form: UseFormReturn<CreateEventRequest, object>;
  hasDialogContainer?: boolean;
  highlightPhoneNumberInput?: boolean;
};

const CreateEventForm = ({
  autoFocusPhoneNumberInput,
  disabled = false,
  form,
  hasDialogContainer = false,
  highlightPhoneNumberInput = false,
}: CreateEventFormProps) => {
  const { control, register, formState, watch } = form;
  const { errors } = formState;
  const theme = useTheme();
  const { t } = useTranslation(['events', 'common']);

  const phoneNumberInputRef = useRef<any>(null);

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: constraints.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: constraints.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });
  const { ref: descriptionInputRef, ...descriptionInputProps } = register('description', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.description.maxLength }),
      value: constraints.description.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.description.minLength }),
      value: constraints.description.minLength,
    },
    required: { message: t('DescriptionIsRequired'), value: true },
  });

  useEffect(() => {
    if (autoFocusPhoneNumberInput) phoneNumberInputRef.current?.focus();
  }, [autoFocusPhoneNumberInput]);

  return (
    <form noValidate>
      <SettingsSection
        disableElevation
        fixedIcon={hasDialogContainer}
        icon={<EventSettingsIcon sx={{ color: theme.palette.common.white }} />}
        sx={{ ...(hasDialogContainer && { marginLeft: theme.spacing(-4), padding: theme.spacing(3) }) }}
        title={t('settings.Event')}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container gap={3} justifyContent="center">
              <Grid item position="relative" xs={12}>
                <TextField
                  disabled={disabled}
                  error={!!errors.title}
                  fullWidth
                  helperText={!!errors.title ? errors?.title?.message : ''}
                  id="title"
                  inputRef={titleInputRef}
                  label={t('Title')}
                  required
                  variant="filled"
                  {...titleInputProps}
                />
                <InputTooltip content={t('helpers.Title')} />
                <FormHelperText>{`${watch('title').length} / ${constraints.title.maxLength}`}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker control={control} disabled={disabled} error={!!errors.showTime} />
              </Grid>
              <Grid item position="relative" xs={12}>
                <TextField
                  disabled={disabled}
                  error={!!errors.description}
                  fullWidth
                  helperText={!!errors.description ? errors?.description?.message : ''}
                  id="description"
                  inputRef={descriptionInputRef}
                  label={t('Description')}
                  multiline
                  required
                  rows={4}
                  variant="filled"
                  {...descriptionInputProps}
                />
                <InputTooltip content={t('helpers.Description')} />
                <FormHelperText>{`${watch('description').length} / ${
                  constraints.description.maxLength
                }`}</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SettingsSection>

      <SettingsSection
        disableElevation
        fixedIcon={hasDialogContainer}
        icon={<StreamingIcon sx={{ color: theme.palette.common.white, height: 30, marginLeft: '3px' }} />}
        roundBottom
        sx={hasDialogContainer ? { marginLeft: theme.spacing(-4), padding: theme.spacing(3) } : {}}
        title={t('settings.Stream')}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container gap={3} justifyContent="center">
              <Grid item xs={12}>
                <FormControl
                  error={!!errors.publisherPhone}
                  required
                  sx={{
                    '& .PhoneInput': (theme) => ({
                      '&:hover': {
                        backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[200],
                      },
                      '&:hover input': {
                        backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.grey[200],
                      },
                      backgroundColor: disabled
                        ? theme.palette.grey[100]
                        : highlightPhoneNumberInput
                        ? alpha(theme.palette.primary.main, 0.2)
                        : theme.palette.grey[50],
                      borderBottom: `1px solid ${errors.publisherPhone ? theme.palette.error.main : 'transparent'}`,
                      borderRadius: '4px',
                      fontFamily: theme.typography,
                      padding: '16.5px 14px',
                      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;',
                    }),
                    '& input': (theme) => ({
                      backgroundColor: 'transparent',
                      color: disabled ? theme.palette.grey[200] : 'initial',
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      fontWeight: '400',
                      letterSpacing: '0.00938em',
                      lineHeight: '1.4375em',
                      outline: 'none',
                      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;',
                    }),
                  }}
                  variant="filled"
                >
                  <Controller
                    control={control}
                    name="publisherPhone"
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        aria-describedby="component-error-text"
                        disabled={disabled}
                        onChange={(value) => field.onChange(value ?? '')}
                        placeholder={t('PhoneNumber') + ' *'}
                        ref={phoneNumberInputRef}
                        style={{ width: '300px' }}
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        if (value === '') return t('PhoneNumberRequired') as string;

                        const country = parsePhoneNumber(value)?.country;
                        if (country && isValidPhoneNumber(value)) return true;

                        return t('PhoneNumberNotValid') as string;
                      },
                    }}
                  />
                  <FormHelperText id="component-error-text">{errors?.publisherPhone?.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SettingsSection>
    </form>
  );
};

export default CreateEventForm;
