import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CBOCurrency } from '../../../../../core/domain/CBOCurrency';
import ProductPrice from '../../../atoms/ProductPrice';

interface ProductRowProps {
  currency: CBOCurrency;
  index: number;
  isSelected: boolean;
  onAddToEvent: () => void;
  onSelect: () => void;
  onUnselect: () => void;
  product: CBOCatalogProduct;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:active': {
    backgroundColor: grey['100'],
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  borderTop: `1px solid ${theme.palette.divider}`,
  minHeight: 0,
  padding: theme.spacing(1),
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  flex: 1,
  padding: theme.spacing(1),
}));

const Image = styled('img')(({ theme }) => ({
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.grey[100],
  borderRadius: '5px',
  flex: 1,
  objectFit: 'contain',
}));

export default function ProductRow(props: ProductRowProps) {
  const { currency, isSelected, onAddToEvent, onSelect, onUnselect, product } = props;

  const { t } = useTranslation('administration', { keyPrefix: 'Catalog' });
  const productPriceVariant = product.hasVariants ? 'range' : 'simple';

  const handleAddToEvent = (e: any) => {
    onAddToEvent();
    e.stopPropagation();
  };

  const handleClick = () => {
    isSelected ? onUnselect() : onSelect();
  };

  return (
    <StyledTableRow
      hover
      onClick={handleClick}
      sx={{
        '&:active': {
          backgroundColor: grey['100'],
          width: '100%',
        },
        '&:hover': {
          cursor: 'pointer',
        },
        backgroundColor: isSelected ? grey['100'] : 'default',
      }}
    >
      <TableCell align="center" padding="normal" style={{ height: '60px', width: '40px' }}>
        <Checkbox checked={isSelected} />
      </TableCell>
      <StyledCell align="center" width="100px">
        <Box sx={{ display: 'flex', height: '100px', margin: 'auto', width: '100px' }}>
          <Image alt={product.title} src={product.imageUrls[0]} />
        </Box>
      </StyledCell>
      <StyledCell>
        <Typography fontSize="1em" fontWeight="bold">
          {product.title}
        </Typography>
      </StyledCell>
      <StyledCell align="right">
        <ProductPrice
          currency={currency}
          price={product.price}
          sx={{ fontWeight: 'bold' }}
          variant={productPriceVariant}
        />
      </StyledCell>
      <StyledCell align="center" sx={{ width: '160px' }}>
        <Box style={{ width: '160px' }}>
          <Button color="primary" onClick={handleAddToEvent} startIcon={<AddIcon />} variant="contained">
            {t('AddToEvent')}
          </Button>
        </Box>
      </StyledCell>
    </StyledTableRow>
  );
}
