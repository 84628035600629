import { intervalToDuration } from 'date-fns';

export function secondsToFormatedTime(seconds: number): string {
  const duration = intervalToDuration({ end: seconds * 1000, start: 0 });

  const padUnit = (unit = 0): string => unit.toString().padStart(2, '0');

  const paddedMinutes = padUnit(duration.minutes);
  const paddedSeconds = padUnit(duration.seconds);

  const hasHours = !!duration.hours;
  const hasMinutes = !!duration.minutes;
  const hasSeconds = !!duration.seconds;

  const hoursText = hasHours ? `${duration.hours}h` : '';
  const minutesText = hasMinutes ? `${hasHours ? paddedMinutes : duration.minutes}m` : '';
  const secondsText = hasSeconds ? `${hasHours || hasMinutes ? paddedSeconds : duration.seconds}s` : '';

  return `${hoursText}${minutesText}${secondsText}`;
}
