import { V1 } from '@bellepoque/api-contracts';
import { Clear } from '@mui/icons-material';
import { Box, Button, IconButton, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const constraints = V1.api.constraints.tenants;

type Inputs = {
  tenantAlias: string;
};

interface EventFinderFormProps {
  defaultAlias: string;
  loading: boolean;
  onSubmit: (tenantAlias: string) => void;
}

export default function TenantAliasForm({ defaultAlias, loading, onSubmit }: EventFinderFormProps) {
  const { t } = useTranslation(['administration', 'common']);

  const defaultValues: Inputs = {
    tenantAlias: defaultAlias,
  };

  const { register, handleSubmit, formState, setValue, watch } = useForm<Inputs>({ defaultValues });
  const { errors } = formState;

  const { ref: eventIdInputRef, ...eventIdInputProps } = register('tenantAlias', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.alias.maxLength }),
      value: constraints.alias.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.alias.minLength }),
      value: constraints.alias.minLength,
    },
    required: { message: t('common:Required'), value: true },
  });

  const handleClearInput = () => setValue('tenantAlias', '');

  return (
    <form
      noValidate
      onSubmit={handleSubmit(({ tenantAlias }) => onSubmit(tenantAlias))}
      style={{
        width: '100%',
      }}
    >
      <Box mb={2}>
        <TextField
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClearInput} sx={{ visibility: watch('tenantAlias') ? 'visible' : 'hidden' }}>
                <Clear />
              </IconButton>
            ),
          }}
          disabled={loading}
          error={!!errors.tenantAlias}
          fullWidth
          helperText={!!errors.tenantAlias ? errors?.tenantAlias?.message : ''}
          inputRef={eventIdInputRef}
          label={t('Name')}
          required
          variant="outlined"
          {...eventIdInputProps}
        />
      </Box>
      <Button color="primary" disabled={!formState.isDirty || loading} type="submit" variant="contained">
        <span>{t('common:Update')}</span>
      </Button>
    </form>
  );
}
