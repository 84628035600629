import { Button, ButtonProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

import publisherImg from '../../assets/tutorial/publisher.jpg';
import PageCount from './tutorial/PageCount';

export type ImageModalProps = {
  ButtonsProps: ButtonProps[];
  content: string;
  imageUrl: string;
  isContentFaded?: boolean;
  isModalFaded?: boolean;
  isOpen: boolean;
  largeContentWidth?: boolean;
  pageCountComponent?: React.ReactNode;
  title: string;
};

const heightPercentage = 58;
const widthPercentage = 47;
const modalBorderRadius = 20;

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  margin: theme.spacing(3),
}));

const ContentContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 4,
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'opacity 0.15s',
  whiteSpace: 'break-spaces',
});

const Content = styled('p')({
  fontSize: '0.9em',
  marginBottom: 0,
  textAlign: 'center',
});

const ImageContainer = styled(Box)({
  display: 'block',
  flex: 7,
  position: 'relative',
});

const Image = styled('img')({
  borderRadius: `${modalBorderRadius}px ${modalBorderRadius}px 0 0`,
  height: 0,
  minHeight: '100%',
  objectFit: 'cover',
  width: '100%',
});

const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: `${modalBorderRadius}px`,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: `${heightPercentage}%`,
  left: `calc(50% - ${widthPercentage / 2}%)`,
  maxWidth: `${widthPercentage}%`,
  outline: 'none',
  position: 'sticky',
  top: `calc(50% - ${heightPercentage / 2}%)`,
  transition: 'opacity 0.5s',
}));

const Title = styled('h3')({
  fontSize: '1.5em',
  fontWeight: 'extra-bold',
  margin: 0,
  marginTop: '0.6em',
  textAlign: 'center',
});

export function ImageModal({
  ButtonsProps,
  content,
  largeContentWidth = false,
  imageUrl,
  isContentFaded = false,
  isModalFaded = false,
  pageCountComponent,
  title,
}: ImageModalProps) {
  return (
    <ModalContainer sx={{ boxShadow: 3, opacity: isModalFaded ? 0 : 1 }}>
      <ImageContainer>
        {pageCountComponent}
        <Image src={imageUrl} />
      </ImageContainer>
      <ContentContainer sx={{ opacity: isContentFaded ? 0 : 1 }}>
        <Title>{title}</Title>
        <Content sx={{ width: largeContentWidth ? '75%' : '55%' }}>{content}</Content>
        <ButtonsContainer>
          {ButtonsProps.map((buttonProps, index) => (
            <Button key={`button-${index}`} variant="contained" {...buttonProps} />
          ))}
        </ButtonsContainer>
      </ContentContainer>
    </ModalContainer>
  );
}

type OnboardingModalProps = {
  onGoToShoppables: () => void;
  onSkip: () => void;
  onStart: () => void;
};

export function OnboardingModal({ onGoToShoppables, onStart, onSkip }: OnboardingModalProps) {
  const { t } = useTranslation('onboarding');

  return (
    <ImageModal
      ButtonsProps={[
        {
          children: t('StartModal.Action'),
          color: 'secondary',
          onClick: onStart,
          variant: 'contained',
        },
        {
          children: t('StartModal.ActionSecondary'),
          color: 'secondary',
          onClick: onGoToShoppables,
          variant: 'outlined',
        },
        {
          children: t('Skip'),
          color: 'primary',
          onClick: onSkip,
          variant: 'outlined',
        },
      ]}
      content={t('StartModal.Description')}
      imageUrl={publisherImg}
      isOpen
      largeContentWidth
      title={t('StartModal.Title')}
    />
  );
}

type TutorialStepProps = {
  ImageModalProps: ImageModalProps;
  currentStepIndex: number;
  stepsCount: number;
};

export function TutorialStep({ currentStepIndex, ImageModalProps, stepsCount }: TutorialStepProps) {
  return (
    <ImageModal
      {...ImageModalProps}
      pageCountComponent={
        <PageCount
          currentPage={currentStepIndex}
          pageCount={stepsCount}
          style={{ left: '50%', position: 'absolute', top: 15, transform: 'translate(-50%)' }}
        />
      }
    />
  );
}
