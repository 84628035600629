import { CBOEventReadModelProductVariant } from '../core/domain/CBOEventReadModelProductOptions';

export const getIndexOfFirstNotRemoved = (variants: { removed: boolean }[]) =>
  variants.findIndex(({ removed }) => !removed);

export const getSmallestVariantPrice = (variants: CBOEventReadModelProductVariant[]): number | null => {
  const prices = variants.filter(({ removed }) => !removed).map(({ price }) => price);
  if (prices.length === 0) return null;
  return Math.min(...prices);
};
