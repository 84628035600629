import { EventId, V1 } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';
import { resetUpdateReplay as resetUpdateReplayAction } from '../../../store/slices/event/event-crud.slice';

export type UpdateReplayRequest = {
  id: EventId;
  payload: V1.api.UpdateReplayDTO;
};

export const updateReplay = createAppAsyncThunk(
  'events/update-replay',
  async ({ id, payload }: UpdateReplayRequest, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.updateReplay(id, payload);
  },
);

export const resetUpdateReplay = () => resetUpdateReplayAction();
