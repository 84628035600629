import { CBOShopifyPage } from '../../domain/CBOShopifyPage';
import { INITIAL_QUERY_RESULT, QueryResult } from './utils';

export interface ShopifyState extends ShopifyCommandsQueriesState {
  areShopifyScopesUpToDate: boolean;
  pages: CBOShopifyPage[];
}
export interface ShopifyCommandsQueriesState {
  checkShopifyScopesAreUpToDateFetching: QueryResult;
  pagesForMediaCollectionsFetching: QueryResult;
}

export const INITIAL_SHOPIFY_STATE: ShopifyState = {
  areShopifyScopesUpToDate: false,
  checkShopifyScopesAreUpToDateFetching: INITIAL_QUERY_RESULT,
  pages: [],
  pagesForMediaCollectionsFetching: INITIAL_QUERY_RESULT,
};
