import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { fetchCurrencies } from '../../usecases/settings/fetch-currencies';
import { INITIAL_SETTINGS_STATE, SettingsState } from '../state/settings';
import { errorStatus, loadedStatus, pendingStatus } from '../utils';

const buildCurrencyReducers = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(fetchCurrencies.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('currenciesFetching'),
    currencies: action.payload,
  }));
  builder.addCase(fetchCurrencies.pending, (state) => ({
    ...state,
    ...pendingStatus('currenciesFetching'),
  }));
  builder.addCase(fetchCurrencies.rejected, (state, action) => ({
    ...state,
    ...errorStatus('currenciesFetching', [action.error]),
  }));
};

export const settingsSlice = createSlice({
  extraReducers: (builder) => {
    buildCurrencyReducers(builder);
  },
  initialState: INITIAL_SETTINGS_STATE,
  name: 'settings',
  reducers: {},
});
