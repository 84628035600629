import { toDomain } from '../../../gateways/catalog/catalog-product-mappers';
import { CBOCatalogProduct } from '../../domain/CBOCatalogProduct';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type FetchProductRequest = {
  productId: string;
  tenantId: string;
};

export const fetchProduct = createAppAsyncThunk(
  'catalog/fetch-product',
  async (request: FetchProductRequest, { extra: { catalogGateway } }): Promise<CBOCatalogProduct> => {
    const catalogProductDto = await catalogGateway.fetchProduct(request);

    return toDomain(catalogProductDto);
  },
);
