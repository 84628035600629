import React, { FC } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ReactHookFormColorPicker from '../../atoms/inputs/ReactHookFormColorPicker';
import { ShoppableSettingsFormInputs } from '../../organisms/shoppables/ShoppableSettingsFormDialog';

export type ButtonsColorPickerProps = {
  control: Control<ShoppableSettingsFormInputs, any>;
  errors: any;
  readonly: boolean;
  submissionInProgress: boolean;
  watch: UseFormWatch<ShoppableSettingsFormInputs>;
};

const ButtonsColorPicker: FC<ButtonsColorPickerProps> = ({
  control,
  errors,
  readonly,
  submissionInProgress,
  watch,
}) => {
  const { t } = useTranslation(['events', 'common']);
  const disabled = readonly || submissionInProgress;

  return (
    <ReactHookFormColorPicker
      control={control}
      disabled={disabled}
      error={errors.addToCartBackgroundColor}
      label={t('PrimaryColor')}
      name="addToCartBackgroundColor"
      watch={watch}
    />
  );
};

export default ButtonsColorPicker;
