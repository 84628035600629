import { V1 } from '@bellepoque/api-contracts';
import { Box, ButtonProps, FormHelperText, Grid, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CBOShoppableVideoListItemReadModel } from '../../../../../core/domain/CBOShoppableVideoReadModel';
import PictureIcon from '../../../../../icons/PictureIcon';
import ShoppablesIcon from '../../../../../icons/ShoppablesIcon';
import FormDialog from '../../../../templates/dialog/FormDialog';
import SettingsSection from '../../../molecules/SettingsSection';
import ThumbnailSelector, { Thumbnail } from '../../../molecules/shoppables/ThumbnailSelector';
import { ShoppableFormInputs } from './ShoppableFormDialog';

const ThumbnailPreviewContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

const ThumbnailPreviewTitle = styled(Typography)({
  /* stylelint-disable-next-line property-no-vendor-prefix */
  '-webkit-box-orient': 'vertical',
  /* stylelint-disable-next-line property-no-vendor-prefix */
  '-webkit-line-clamp': '2',
  display: '-webkit-box',
  inlineSize: '150px',
  left: '50%',
  lineClamp: 2,
  overflow: 'hidden',
  overflowWrap: 'break-word',
  position: 'absolute',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  top: '100%',
  transform: 'translate(-50%, 0)',
});

type ThumbnailPreviewProps = {
  src: string;
  title: string;
};

const ThumbnailPreview: FC<ThumbnailPreviewProps> = ({ title, src }) => (
  <Box alignItems="center" display="flex" flexDirection="column" position="relative">
    <Thumbnail src={src} />
    <ThumbnailPreviewTitle>{title}</ThumbnailPreviewTitle>
  </Box>
);

export type UpdateShoppableFormProps = {
  currentShoppable: CBOShoppableVideoListItemReadModel | null;
  disabled?: boolean;
  form: UseFormReturn<ShoppableFormInputs>;
  hasDialogContainer?: boolean;
  onClose: () => void;
  onSelectCustomFile: (file: File) => void;
  onSelectProductImage: (productImage: string) => void;
  onSubmit: (values: ShoppableFormInputs) => void;
  open: boolean;
  product: CBOCatalogProduct | null;
  submissionInProgress: boolean;
};

const constraints = V1.api.constraints.shoppableVideos;

const UpdateShoppableForm = ({
  currentShoppable,
  disabled,
  form,
  hasDialogContainer = false,
  onClose,
  onSelectCustomFile,
  onSelectProductImage,
  onSubmit,
  open,
  product,
  submissionInProgress,
}: UpdateShoppableFormProps) => {
  const { t } = useTranslation(['shoppables', 'common']);

  const imageUrls = product?.imageUrls || [];

  const thumbnailPreviewUrl = useRef(currentShoppable?.thumbnailUrl || imageUrls[0]);

  const { watch, formState, register, handleSubmit: handleFormSubmit } = form;
  const { errors, isValid } = formState;
  const theme = useTheme();

  const title = watch('title');

  const isLoading = !product || currentShoppable?.productId !== product.id;

  const { ref: titleInputRef, ...titleInputProps } = register('title', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.title.maxLength }),
      value: constraints.title.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.title.minLength }),
      value: constraints.title.minLength,
    },
    required: { message: t('TitleIsRequired'), value: true },
  });

  const buttonsProps: ButtonProps[] = [
    {
      children: <Typography>{t('common:Cancel')}</Typography>,
      onClick: onClose,
      variant: 'text',
    },
    {
      children: <Typography>{t('common:Save')}</Typography>,
      color: 'primary',
      disabled: !isValid || submissionInProgress,
      onClick: handleFormSubmit(onSubmit),
      variant: 'contained',
    },
  ];

  const handleSelectCustomFile = (file: File) => {
    onSelectCustomFile(file);
    thumbnailPreviewUrl.current = URL.createObjectURL(file);
  };

  const handleSelectProductImage = async (productImageUrl: string) => {
    onSelectProductImage(productImageUrl);
    thumbnailPreviewUrl.current = productImageUrl;
  };

  useEffect(() => {
    thumbnailPreviewUrl.current = currentShoppable?.thumbnailUrl || imageUrls[0];
  }, [imageUrls]);

  return (
    <FormDialog
      buttonsProps={buttonsProps}
      loading={isLoading}
      onClose={onClose}
      open={open}
      title={t('EditShoppable')}
    >
      {product && (
        <Fragment>
          <form noValidate onSubmit={handleFormSubmit(onSubmit)}>
            <SettingsSection
              disableElevation
              fixedIcon={hasDialogContainer}
              icon={<ShoppablesIcon sx={{ color: theme.palette.common.white, height: 30 }} />}
              roundBottom
              sx={
                hasDialogContainer
                  ? { marginLeft: (theme) => `-${theme.spacing(4)}`, padding: theme.spacing(3) }
                  : undefined
              }
              title={t('Title')}
            >
              <Grid container gap={3} justifyContent="center">
                <Grid
                  alignItems="center"
                  display="flex"
                  gap={theme.spacing(8)}
                  item
                  justifyContent="space-between"
                  position="relative"
                  xs={10}
                >
                  <Box maxWidth="350px" position="relative" width="100%">
                    <TextField
                      disabled={disabled}
                      error={!!errors.title}
                      fullWidth
                      helperText={!!errors.title ? errors?.title?.message : ''}
                      id="title"
                      inputRef={titleInputRef}
                      label={t('TitleLabel')}
                      required
                      variant="filled"
                      {...titleInputProps}
                    />
                    <FormHelperText>{`${title.length} / ${constraints.title.maxLength}`}</FormHelperText>
                  </Box>
                  <ThumbnailPreviewContainer>
                    <Typography fontWeight="bold" position="absolute" top={theme.spacing(-3)} variant="subtitle2">
                      {t('Preview')}
                    </Typography>
                    <ThumbnailPreview src={thumbnailPreviewUrl.current} title={title} />
                  </ThumbnailPreviewContainer>
                </Grid>
              </Grid>
            </SettingsSection>
          </form>
          <SettingsSection
            disableElevation
            fixedIcon={hasDialogContainer}
            icon={<PictureIcon sx={{ color: theme.palette.common.white, height: 24 }} />}
            roundBottom
            sx={
              hasDialogContainer
                ? { marginLeft: (theme) => `-${theme.spacing(4)}`, padding: theme.spacing(3) }
                : undefined
            }
            title={t('Thumbnail')}
          >
            <Grid container gap={3} justifyContent="center">
              <Grid item position="relative" xs={10}>
                <ThumbnailSelector
                  buttonLabel={t('UploadImage.Action')}
                  imageUrls={imageUrls}
                  onSelectFile={handleSelectCustomFile}
                  onSelectThumbnail={handleSelectProductImage}
                />
              </Grid>
            </Grid>
          </SettingsSection>
        </Fragment>
      )}
    </FormDialog>
  );
};

export default UpdateShoppableForm;
