import { CometChat } from '@cometchat-pro/chat';

import { ChatGateway } from '../../core/gateways/chat-gateway';

export class CometChatGateway implements ChatGateway {
  constructor(private appId: string, private region: string) {}

  async init(): Promise<void> {
    const appSetting = new CometChat.AppSettingsBuilder().enableAutoJoinForGroups(true).setRegion(this.region).build();
    await CometChat.init(this.appId, appSetting);
    CometChat.setSource('ui-kit', 'web', 'reactjs');
  }

  async login(authToken: string): Promise<void> {
    await CometChat.login(authToken);
  }
}
