import { TenantId } from '@bellepoque/api-contracts';
import { Autocomplete, Popper, TextField, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOTenant } from '../../../../core/domain/CBOTenant';
import isBellepoqueTenant from '../../../../utils/is-bellepoque-tenant';

export interface TenantSelectorProps {
  currentTenant: CBOTenant;
  onChange: (tenantId: TenantId) => void;
  tenants: CBOTenant[];
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& .MuiAutocomplete-endAdornment svg': {
      color: theme.palette.common.white,
    },
    '& fieldset': {
      borderColor: theme.palette.common.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.light,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.common.white,
    },
    color: theme.palette.common.white,
  },
}));

interface AutocompleteOption {
  id: string;
  label: string;
}

export default function TenantSelector(props: TenantSelectorProps) {
  const { t } = useTranslation(['common']);
  const [selectedValue, setSelectedValue] = useState<AutocompleteOption | null>(null);
  const [inputValue, setInputValue] = useState(props.currentTenant.alias);

  useEffect(() => {
    setSelectedValue({ id: props.currentTenant.id, label: props.currentTenant.alias });
    setInputValue(props.currentTenant.alias);
  }, [props.currentTenant]);

  const sortedTenantsOptions: AutocompleteOption[] = useMemo(
    () =>
      props.tenants
        .map(({ id, alias, name }) => ({ id, label: `${alias} (${name})` }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .sort((a) => (isBellepoqueTenant(a.id) ? -1 : 0)),
    [props.tenants],
  );

  const handleChange = (newValue: AutocompleteOption) => {
    setSelectedValue(newValue);
    props.onChange(newValue.id);
  };

  return (
    <Autocomplete
      PopperComponent={(props) => <Popper {...props} placement="bottom" style={{ maxHeight: '75vh' }} />}
      blurOnSelect
      id="tenant-selector"
      inputValue={inputValue}
      onChange={(_event: any, newValue: AutocompleteOption | null) => (newValue ? handleChange(newValue) : null)}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      options={sortedTenantsOptions}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          InputLabelProps={{ ...params.InputLabelProps, sx: { color: (theme) => theme.palette.common.white } }}
          label={t('Tenant')}
          variant="outlined"
        />
      )}
      value={selectedValue}
    />
  );
}
