import { Box, Skeleton, styled } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileAppsPlatforms, TMobileAppsPlatforms } from '../../../core/domain/MobileAppPlatforms';
import { ENDPOINTS } from '../../../endpoints';

const AppStoreLink = styled('a')({
  display: 'flex',
  flex: 1,
  placeItems: 'center',
});

const AppStoreIcon = styled('img', { shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'isLoaded' })<{
  isLoaded: boolean;
  variant: StoreImageProps['variant'];
}>(({ isLoaded, variant }) => ({
  display: isLoaded ? 'block' : 'none',
  ...(variant === MobileAppsPlatforms.android && { height: 65, marginTop: -10 }),
  ...(variant === MobileAppsPlatforms.ios && { height: 45 }),
}));

const AppStoreSkeleton = styled(Box)({
  backgroundColor: '#e3e3e3',
  borderRadius: 6,
  display: 'flex',
  gap: 6,
  padding: 8,
});

const AppStoreSkeletonItem = styled(Skeleton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

interface StoreImageProps {
  variant: TMobileAppsPlatforms;
}

export default function StoreImage({ variant }: StoreImageProps) {
  const publisherStoreUrls = ENDPOINTS.publisherStoreUrls;

  const [appStoreIconLoaded, setAppStoreIconLoadedLoaded] = useState(false);

  const {
    i18n: { language },
  } = useTranslation();

  const imageSource = useMemo(() => {
    const languageCode = language.substr(0, 2);
    switch (variant) {
      case MobileAppsPlatforms.android:
        return `https://play.google.com/intl/en_us/badges/static/images/badges/${languageCode}_badge_web_generic.png`;
      case MobileAppsPlatforms.ios:
        return `https://prodclientfilesbellepoq.blob.core.windows.net/store-badges/${languageCode}.svg`;
    }
  }, [variant, language]);

  const storeUrl = useMemo(() => {
    switch (variant) {
      case MobileAppsPlatforms.android:
        return publisherStoreUrls.playStore;
      case MobileAppsPlatforms.ios:
        return publisherStoreUrls.appStore;
    }
  }, [variant]);

  const iconSkeleton = useMemo(
    () =>
      !appStoreIconLoaded && (
        <AppStoreSkeleton>
          <Box alignItems="center" display="flex">
            <AppStoreSkeletonItem animation="wave" height={24} variant="rectangular" width={24} />
          </Box>
          <Box display="flex" flexDirection="column" height={30} justifyContent="space-between">
            <AppStoreSkeletonItem animation="wave" height={12} width={100} />
            <AppStoreSkeletonItem animation="wave" height={32} width={100} />
          </Box>
        </AppStoreSkeleton>
      ),
    [appStoreIconLoaded],
  );

  const handleLoadAppStoreIcon = useCallback(() => {
    setAppStoreIconLoadedLoaded(true);
  }, []);

  return (
    <AppStoreLink href={storeUrl} rel="noreferrer" target="_blank">
      <AppStoreIcon isLoaded={appStoreIconLoaded} onLoad={handleLoadAppStoreIcon} src={imageSource} variant={variant} />
      {iconSkeleton}
    </AppStoreLink>
  );
}
