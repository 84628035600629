import { TCms } from '@bellepoque/api-contracts';

import { CountryCode } from '../../../utils/countryCodes';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type CreateTenantRequest = {
  cms: TCms;
  countryCode: CountryCode;
  currencyId: string;
  masterEmail: string;
  tenantName: string;
};

export const createTenant = createAppAsyncThunk(
  'administration/create-tenant',
  async (request: CreateTenantRequest, { extra: { tenantsGateway } }): Promise<void> => {
    return tenantsGateway.create(request);
  },
);
