import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function AdministrationIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 21" {...props}>
      <g>
        <path
          d="M24.153,21.812a1.384,1.384,0,1,0-.7,2.581,1.4,1.4,0,0,0,.355-.046,1.384,1.384,0,0,0,.344-2.535Zm-.207,1.482a.568.568,0,1,1-.2-.776h0a.567.567,0,0,1,.2.776Zm3.581-1.4a.409.409,0,0,0-.045-.287l-.772-1.351a.409.409,0,0,0-.225-.185,2.09,2.09,0,0,0-1.1-.081l-.252-.147a2.107,2.107,0,0,0-.611-.916.408.408,0,0,0-.271-.1L22.7,18.812h0a.408.408,0,0,0-.27.1,2.11,2.11,0,0,0-.619.91l-.253.145a2.109,2.109,0,0,0-1.1.072.408.408,0,0,0-.226.182l-.784,1.345a.408.408,0,0,0-.048.287,2.106,2.106,0,0,0,.479.992v.292a2.108,2.108,0,0,0-.488.987.408.408,0,0,0,.045.287l.772,1.351a.409.409,0,0,0,.225.185,2.1,2.1,0,0,0,1.1.081l.252.147a2.112,2.112,0,0,0,.611.916.409.409,0,0,0,.272.1l1.556.006h0a.408.408,0,0,0,.27-.1,2.109,2.109,0,0,0,.619-.911l.254-.145a2.092,2.092,0,0,0,1.1-.071.408.408,0,0,0,.227-.183l.784-1.345a.408.408,0,0,0,.047-.287,2.111,2.111,0,0,0-.479-.991v-.292a2.109,2.109,0,0,0,.488-.987Zm-1.025.372a.409.409,0,0,0-.278.386l0,.744a.409.409,0,0,0,.275.388,1.425,1.425,0,0,1,.185.4l-.615,1.055a1.334,1.334,0,0,1-.44.036.409.409,0,0,0-.473-.048l-.646.37a.408.408,0,0,0-.2.432,1.418,1.418,0,0,1-.255.36l-1.222,0a1.39,1.39,0,0,1-.252-.363.409.409,0,0,0-.195-.434l-.643-.375a.408.408,0,0,0-.473.044,1.41,1.41,0,0,1-.44-.041l-.606-1.061a1.418,1.418,0,0,1,.188-.4.409.409,0,0,0,.278-.386l0-.744a.408.408,0,0,0-.275-.388,1.425,1.425,0,0,1-.185-.4l.615-1.055a1.457,1.457,0,0,1,.44-.037.407.407,0,0,0,.473.048l.646-.369a.408.408,0,0,0,.2-.432,1.422,1.422,0,0,1,.255-.36l1.222,0a1.425,1.425,0,0,1,.252.363.409.409,0,0,0,.195.434l.643.375a.409.409,0,0,0,.473-.044,1.419,1.419,0,0,1,.44.041l.606,1.06a1.43,1.43,0,0,1-.188.4ZM15.746,15.449a4.818,4.818,0,1,0-4.817-4.818,4.818,4.818,0,0,0,4.817,4.818Zm0-8.819a4,4,0,1,1-4,4,4,4,0,0,1,4-4Zm4.2,11.974a7.747,7.747,0,0,0-4.192-.964,6.983,6.983,0,0,0-5.3,1.808,7.8,7.8,0,0,0-2.014,5.064.408.408,0,0,1-.4.413H8.035a.408.408,0,0,1-.408-.4,8.5,8.5,0,0,1,2.249-5.649,7.745,7.745,0,0,1,5.877-2.05A8.584,8.584,0,0,1,20.365,17.9a.408.408,0,0,1-.419.7Z"
          strokeWidth="0.4"
          transform="translate(-7.627 -5.814)"
        />
      </g>
    </SvgIcon>
  );
}
