import { styled } from '@mui/material';
import MUITabs, { tabsClasses } from '@mui/material/Tabs';
import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ReleaseBadgeProps } from '../atoms/ReleaseBadge';
import StyledTab from '../atoms/StyledTab';

export const tabWidthInPx = 118;

const StyledTabs = styled(MUITabs)({
  height: '100%',
  [`& .${tabsClasses.flexContainer}`]: {
    alignItems: 'center',
  },
  width: `${tabWidthInPx}px`,
});

export interface TabProps {
  component: JSX.Element;
  enabled: boolean;
  hasWarning?: boolean;
  icon: JSX.Element;
  index: number;
  isHidden?: boolean;
  label: string;
  releaseStatus?: ReleaseBadgeProps['status'];
  to: string;
}

interface TabsProps {
  activeTab: number;
  onChangeTab?: (index: number) => void;
  tabs: TabProps[];
}

function getA11yProps(index: number) {
  return {
    'aria-controls': `vertical-tabpanel-${index}`,
    id: `vertical-tab-${index}`,
  };
}

export default function Tabs(props: TabsProps) {
  const { activeTab, onChangeTab, tabs } = props;

  const shownTabs = tabs.filter(({ isHidden }) => !isHidden);

  const handleChangeTab = useCallback(
    (_e: SyntheticEvent, tabIndex: number) => {
      onChangeTab?.(tabs[tabIndex].index);
    },
    [onChangeTab, tabs],
  );

  const value = useMemo(() => {
    return shownTabs.findIndex((tab) => tab.index === activeTab);
  }, [activeTab, tabs]);

  return (
    <StyledTabs aria-label="Vertical tabs" onChange={handleChangeTab} orientation="vertical" value={value}>
      {shownTabs
        .sort((a, b) => a.index - b.index)
        .map(({ label, icon, index, releaseStatus, hasWarning }) => (
          <StyledTab
            LinkComponent={Link}
            hasWarning={hasWarning}
            icon={icon}
            key={index}
            label={label}
            releaseStatus={releaseStatus}
            {...getA11yProps(index)}
          />
        ))}
    </StyledTabs>
  );
}
