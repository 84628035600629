import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

export default function CloseButtonIcon(props: IconButtonProps) {
  return (
    <IconButton {...props}>
      <Close sx={{ color: (theme) => theme.palette.primary.main }} />
    </IconButton>
  );
}
