import { EventId } from '@bellepoque/api-contracts';

import { createAppAsyncThunk } from '../../../store/create-app-async-thunk';

export type PublishEventParams = {
  eventId: EventId;
};

export const publishEvent = createAppAsyncThunk(
  'events/publish',
  async ({ eventId }: PublishEventParams, { extra: { eventGateway } }): Promise<void> => {
    return eventGateway.publishOne(eventId);
  },
);
