import { Box, Grid, SxProps, styled, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import {
  CBOProductStatisticsWithSales,
  CBOProductStatisticsWithoutSales,
} from '../../../../core/domain/CBOTenantStatistics';
import { getSmallestVariantPrice } from '../../../../utils/variants';
import ProductPrice from '../../atoms/ProductPrice';
import ProductStatisticsValue from '../../atoms/analytics/ProductStatisticsValue';
import { getProductStatisticsScore } from '../../organisms/analytics/ProductsStatisticsSection';
import { PriceWithCrossedOutPrice } from '../event/PriceWithCrossedOutPrice';

const IMAGE_SIZE = '80px';

const Title = styled('span')({
  fontWeight: 800,
});

const Container = styled(Box)(({ theme }) => ({
  borderBottom: `solid ${theme.palette.grey[500]} 1px`,
  padding: '10px 0',
}));

const Image = styled('img')({
  borderRadius: '10px',
  height: IMAGE_SIZE,
  objectFit: 'cover',
  width: IMAGE_SIZE,
});

const EmptyImage = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `solid ${theme.palette.grey[500]} 1px`,
  borderRadius: '10px',
  display: 'block',
  height: IMAGE_SIZE,
  width: IMAGE_SIZE,
}));

type ProductListRowProps = {
  allProductScores: number;
  currency: CBOCurrency;
  product: CBOEventReadModelProduct;
} & (
  | {
      hasSales: false;
      productStatistics: CBOProductStatisticsWithoutSales;
    }
  | {
      hasSales: true;
      productStatistics: CBOProductStatisticsWithSales;
    }
);

export default function ProductStatisticsRow({
  allProductScores,
  currency,
  hasSales,
  product,
  productStatistics,
}: ProductListRowProps) {
  const { t } = useTranslation('analytics', { keyPrefix: 'StatsByProduct' });
  const theme = useTheme();
  const priceStyle: SxProps = {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '1.2em',
    fontWeight: 'bold',
  };

  const hasVariants = product.options !== null ? product.options.variants?.length > 0 : false;
  const productPriceVariant = hasVariants ? 'range' : 'simple';
  const price = hasVariants && product.options ? getSmallestVariantPrice(product.options.variants) : product.price;

  const score = useMemo(() => getProductStatisticsScore(productStatistics), [productStatistics]);

  const popularity = useMemo(() => {
    if (score === 0) return 0;
    return score / allProductScores || 1;
  }, [score, allProductScores]);

  return (
    <Container>
      <Grid alignItems="center" columnSpacing={8} container gap={2}>
        <Grid item width={IMAGE_SIZE}>
          {product.imageUrls[0] ? <Image src={product.imageUrls[0]} /> : <EmptyImage />}
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Title>{product.title}</Title>
            <Box display="flex" flex={1} gap={1}>
              <Box display="flex" flexDirection="row" gap={2}>
                {!!product.crossedOutPrice ? (
                  <PriceWithCrossedOutPrice
                    crossedOutPrice={product.crossedOutPrice}
                    crossedOutPriceSx={priceStyle}
                    currency={currency}
                    price={price}
                    priceSx={priceStyle}
                  />
                ) : (
                  <ProductPrice currency={currency} price={price} sx={priceStyle} variant={productPriceVariant} />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid alignItems="center" display="flex" item justifyContent="center" xs={4}>
          <Box flex={1}>
            <ProductStatisticsValue
              title={t('View', { count: productStatistics.views })}
              value={productStatistics.views}
            />
          </Box>
          <Box flex={1}>
            <ProductStatisticsValue title={t('Cart')} value={productStatistics.cart} />
          </Box>
          <Box flex={1}>
            {hasSales ? (
              <ProductStatisticsValue title={t('Purchased')} value={productStatistics.purchased} />
            ) : (
              <ProductStatisticsValue title={t('Checkout')} value={productStatistics.validated} />
            )}
          </Box>
        </Grid>
        <Grid alignItems="center" display="flex" item justifyContent="center" xs={2}>
          <ProductStatisticsValue colored isPercentage title={t('Popularity')} value={popularity} />
        </Grid>
      </Grid>
    </Container>
  );
}
