import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function EventSettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 27.886 31.828" {...props}>
      <g transform="translate(-13 -7.77)">
        <path
          d="M36.741,7.77h-19.6A4.145,4.145,0,0,0,13,11.915V35.453A4.145,4.145,0,0,0,17.145,39.6h19.6a4.145,4.145,0,0,0,4.145-4.145V11.915A4.145,4.145,0,0,0,36.741,7.77Zm1.884,27.683a1.884,1.884,0,0,1-1.884,1.884h-19.6a1.884,1.884,0,0,1-1.884-1.884V11.915a1.884,1.884,0,0,1,1.884-1.884h19.6a1.884,1.884,0,0,1,1.884,1.884Z"
          transform="translate(0 0)"
        />
        <path
          d="M27.4,47.5H25.531a1.131,1.131,0,1,0,0,2.261H27.4a1.131,1.131,0,0,0,0-2.261Z"
          transform="translate(-7.104 -24.758)"
        />
        <path
          d="M52.466,47.5H41.421a1.131,1.131,0,0,0,0,2.261H52.466a1.131,1.131,0,1,0,0-2.261Z"
          transform="translate(-17.006 -24.758)"
        />
        <path
          d="M27.4,29.5H25.531a1.131,1.131,0,1,0,0,2.261H27.4a1.131,1.131,0,0,0,0-2.261Z"
          transform="translate(-7.104 -13.541)"
        />
        <path
          d="M52.466,29.5H41.421a1.131,1.131,0,0,0,0,2.261H52.466a1.131,1.131,0,1,0,0-2.261Z"
          transform="translate(-17.006 -13.541)"
        />
        <path
          d="M27.4,65.5H25.531a1.131,1.131,0,1,0,0,2.261H27.4a1.131,1.131,0,1,0,0-2.261Z"
          transform="translate(-7.104 -35.975)"
        />
        <path
          d="M52.466,65.5H41.421a1.131,1.131,0,1,0,0,2.261H52.466a1.131,1.131,0,1,0,0-2.261Z"
          transform="translate(-17.006 -35.975)"
        />
      </g>
    </SvgIcon>
  );
}
