import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import { AuthenticatedUser } from '../../../domain/User';
import { loginConfirmFulfilled, loginConfirmPending, loginFailed } from '../../slices/authentication.slice';
import { ofType } from '../utils';

const namespace = process.env.REACT_APP_AUTH0_NAMESPACE || '';

export const confirmLoginEpic: AppEpic = (action$, state$, { container: { authenticationGateway, eventGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof loginConfirmPending>>(loginConfirmPending.type),
    switchMap(() => {
      return authenticationGateway.handleRedirectCallback().pipe(
        switchMap(() => authenticationGateway.getUser()),
        switchMap((user) => {
          if (!user) return of(undefined);
          return eventGateway.getFirebaseCustomToken(user);
        }),
        switchMap((result) => {
          if (!result) return of(undefined);
          return authenticationGateway.signFirebaseInWithCustomToken(result.user, result?.customToken);
        }),
        map((user) => {
          if (!user) return loginFailed({ error: 'login succeeded but no user data received' });

          const authenticatedUser: AuthenticatedUser = {
            ...user,
            isAdmin: user && user[namespace] && user[namespace].isAdmin,
            previousLogin: (user && user[namespace] && user[namespace].previousLogin) || null,
            tenantId: user && user[namespace] && user[namespace].tenantId,
            userId: (user && user.sub && user.sub.replace(/^.*\|/, '')) || 'missing-cbo-user-id',
          };
          return loginConfirmFulfilled({ user: authenticatedUser });
        }),
        catchError((error) => of(loginFailed(error))),
      );
    }),
  );
};
