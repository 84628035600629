import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { SxProps, Theme, styled, useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';

const StyledDialog = styled(Dialog)({
  borderRadius: '15px',
  padding: 0,
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export interface FormDialogProps {
  DialogContentProps?: DialogContentProps;
  buttonsProps: ButtonProps[];
  children?: React.ReactNode;
  loading: boolean;
  onClose: () => void;
  open: boolean;
  sx?: SxProps<Theme>;
  title?: string;
}

export default function FormDialog({
  DialogContentProps = {},
  buttonsProps,
  children,
  loading,
  onClose,
  open,
  title,
  sx,
}: FormDialogProps) {
  const theme = useTheme();

  const renderProgress = useCallback(() => <LinearProgress />, []);

  if (!open) return null;

  return (
    <StyledDialog PaperProps={{ sx: { maxWidth: '50%', width: '50%', ...sx } }} onClose={onClose} open={open}>
      {!!title && (
        <DialogTitle sx={{ borderBottom: 'solid 1px #C6C6C6', fontSize: '2.25em', fontWeight: 'bold' }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={{ height: '100%', overflowY: 'visible', padding: `0 ${theme.spacing(2)}` }}
        {...DialogContentProps}
      >
        {children}
      </DialogContent>
      {loading && renderProgress()}
      <StyledDialogActions>
        {buttonsProps.map((buttonProps, index) => (
          <Button key={index} {...buttonProps} sx={{ minWidth: '100px', ...buttonProps.sx }} />
        ))}
      </StyledDialogActions>
    </StyledDialog>
  );
}
