import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { fetchShoppablesSettings } from '../../../usecases/shoppables/fetch-shoppables-settings';
import { getShoppablesSettingsFileUploadUrls } from '../../../usecases/shoppables/get-shoppables-settings-file-upload-urls';
import { updateShoppablesSettings } from '../../../usecases/shoppables/update-shoppables-settings';
import { uploadShoppablesSettingsFiles } from '../../../usecases/shoppables/upload-shoppables-settings-files';
import { ShoppablesState } from '../../state/shoppables';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetUpdateShoppablesSettings = createAction<void>('shoppables/settings/reset-update');

export const buildSettingsReducer = (builder: ActionReducerMapBuilder<ShoppablesState>) => {
  builder.addCase(fetchShoppablesSettings.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('shoppablesSettingsFetching'),
    shoppablesSettings: action.payload,
  }));
  builder.addCase(fetchShoppablesSettings.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppablesSettingsFetching'),
    shoppablesSettings: null,
  }));
  builder.addCase(fetchShoppablesSettings.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppablesSettingsFetching', [action.error]),
  }));
  builder.addCase(getShoppablesSettingsFileUploadUrls.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('shoppablesSettingsFileUploadUrlsFetching'),
    shoppablesSettingsFileUploadUrls: action.payload,
  }));
  builder.addCase(getShoppablesSettingsFileUploadUrls.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppablesSettingsFileUploadUrlsFetching'),
    shoppablesSettingsFileUploadUrls: null,
  }));
  builder.addCase(getShoppablesSettingsFileUploadUrls.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppablesSettingsFileUploadUrlsFetching', [action.error]),
  }));

  builder.addCase(updateShoppablesSettings.fulfilled, (state, action) => ({
    ...state,
    ...successStatus('shoppablesSettingsUpdate'),
    shoppablesSettings: action.payload,
  }));
  builder.addCase(updateShoppablesSettings.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppablesSettingsUpdate'),
  }));
  builder.addCase(updateShoppablesSettings.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppablesSettingsUpdate', [action.error]),
  }));

  builder.addCase(uploadShoppablesSettingsFiles.fulfilled, (state) => ({
    ...state,
    ...successStatus('shoppablesSettingsFileUploading'),
  }));
  builder.addCase(uploadShoppablesSettingsFiles.pending, (state) => ({
    ...state,
    ...pendingStatus('shoppablesSettingsFileUploading'),
  }));
  builder.addCase(uploadShoppablesSettingsFiles.rejected, (state, action) => ({
    ...state,
    ...errorStatus('shoppablesSettingsFileUploading', [action.error]),
  }));

  builder.addCase(resetUpdateShoppablesSettings, (state) => ({
    ...state,
    ...notRequestedStatus('shoppablesSettingsUpdate'),
  }));
};
