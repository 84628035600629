import { Edit } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import React from 'react';

type EditableImageProps = {
  ImageProps: React.ImgHTMLAttributes<HTMLImageElement>;
  onEdit: () => void;
};

const containerBorderRadiusInPx = 20;

const Container = styled(Box)({
  borderRadius: `${containerBorderRadiusInPx}px`,
  overflow: 'hidden',
  position: 'relative',
});

const EditButtonContainer = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 0,
});

const Image = styled('img')({
  height: '170px',
  objectFit: 'cover',
  width: '170px',
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  borderLeft: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: `0 ${containerBorderRadiusInPx}px 0 10px`,

  padding: theme.spacing(1),
}));

const StyledIcon = styled(Edit)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '20px',
}));

export default function EditableImage({ onEdit, ImageProps }: EditableImageProps) {
  return (
    <Container>
      <Image {...ImageProps} />
      <EditButtonContainer>
        <StyledIconButton onClick={onEdit}>
          <StyledIcon />
        </StyledIconButton>
      </EditButtonContainer>
    </Container>
  );
}
