import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OBSIcon from '../../../../../icons/OBSIcon';
import UpgradePlanButton from '../../../atoms/UpgradePlanButton';
import SettingsSection from '../../../molecules/SettingsSection';

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

interface OBSSectionProps {
  enabled: boolean;
  onCopyStreamKey: () => void;
  onCopyStreamUrl: () => void;
  roundBottom: boolean;
  tenantName: string;
}

export default function OBSSection({
  enabled,
  onCopyStreamKey,
  onCopyStreamUrl,
  roundBottom,
  tenantName,
}: OBSSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation(['events']);

  const renderDisabledSection = () => (
    <Box my={1}>
      <Typography>{t('ObsPlanRestriction')}</Typography>
      <UpgradePlanButton sx={{ marginTop: (theme) => theme.spacing(2) }} tenantName={tenantName} />
    </Box>
  );

  return (
    <SettingsSection
      direction="row"
      icon={<OBSIcon sx={{ color: theme.palette.common.white, height: '24px', width: '24px' }} />}
      roundBottom={roundBottom}
      sx={{ flexDirection: 'column' }}
      title={t('ContributeOBS')}
    >
      {enabled ? (
        <ButtonsContainer mt={1}>
          <Button
            color="primary"
            onClick={onCopyStreamUrl}
            startIcon={<ContentCopy />}
            sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
            variant="contained"
          >
            <span>{t('StreamUrl')}</span>
          </Button>
          <Button
            color="primary"
            onClick={onCopyStreamKey}
            startIcon={<ContentCopy />}
            sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
            variant="contained"
          >
            <span>{t('StreamKey')}</span>
          </Button>
        </ButtonsContainer>
      ) : (
        renderDisabledSection()
      )}
    </SettingsSection>
  );
}
