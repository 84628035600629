import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

type HasLandingPageParams = {
  tenantId: string;
};

export const hasCmsLandingPage = createAppAsyncThunk(
  'landing-page/has-cms-one',
  async ({ tenantId }: HasLandingPageParams, { extra: { landingPageGateway } }): Promise<boolean> => {
    const { hasCmsLandingPage } = await landingPageGateway.hasCmsLandingPage(tenantId);
    return hasCmsLandingPage;
  },
);
