import { V1 } from '@bellepoque/api-contracts';
import { FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EventMessagesSettingsIcon from '../../../../../icons/EventMessagesSettingsIcon';
import InputTooltip from '../../../atoms/inputs/InputTooltip';
import SettingsSection from '../../SettingsSection';

const constraints = V1.api.constraints.events;

interface ChatSettingsSectionProps {
  chatIntroductionTextInputProps: any;
  chatIntroductionTextInputRef: React.Ref<any>;
  chatIntroductionTextInputWatch: string;
  errors: any;
  moderatorMessageLabelInputProps: any;
  moderatorMessageLabelInputRef: React.Ref<any>;
  moderatorMessageLabelInputWatch: string;
  readonly: boolean;
  submissionInProgress: boolean;
}

export default function ChatSettingsSection(props: ChatSettingsSectionProps) {
  const {
    moderatorMessageLabelInputProps,
    moderatorMessageLabelInputRef,
    moderatorMessageLabelInputWatch,
    chatIntroductionTextInputProps,
    chatIntroductionTextInputRef,
    chatIntroductionTextInputWatch,
    errors,
    readonly,
    submissionInProgress,
  } = props;

  const { t } = useTranslation(['events', 'common']);
  const theme = useTheme();
  return (
    <SettingsSection
      icon={<EventMessagesSettingsIcon sx={{ color: theme.palette.common.white }} />}
      roundBottom
      title={t('settings.Chat')}
    >
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.moderatorMessageLabel}
          fullWidth
          helperText={!!errors.moderatorMessageLabel ? errors?.moderatorMessageLabel?.message : ''}
          id="moderatorMessageLabel"
          inputRef={moderatorMessageLabelInputRef}
          label={t('ModeratorMessageLabel')}
          variant="filled"
          {...moderatorMessageLabelInputProps}
        />
        <InputTooltip content={t('helpers.ModeratorMessageLabel')} />
        <FormHelperText>{`${moderatorMessageLabelInputWatch.length} / ${constraints.moderatorMessageLabel.maxLength}`}</FormHelperText>
      </Grid>
      <Grid item position="relative" xs={12}>
        <TextField
          disabled={submissionInProgress || readonly}
          error={!!errors.chatIntroductionText}
          fullWidth
          helperText={!!errors.chatIntroductionText ? errors?.chatIntroductionText?.message : ''}
          id="chatIntroductionText"
          inputRef={chatIntroductionTextInputRef}
          label={t('ChatIntroductionText')}
          multiline
          rows={8}
          variant="filled"
          {...chatIntroductionTextInputProps}
        />
        <InputTooltip content={t('helpers.ChatIntroductionText')} />
        <FormHelperText>{`${chatIntroductionTextInputWatch.length} / ${constraints.chatIntroductionText.maxLength}`}</FormHelperText>
      </Grid>
    </SettingsSection>
  );
}
