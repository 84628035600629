import { YoutubeAccessToken } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../core/store';
import { checkHasYoutubeLiveStreamEnabled } from '../../core/usecases/event/youtube/check-has-youtube-live-stream-enabled';
import { exchangeYoutubeCode } from '../../core/usecases/event/youtube/exchange-youtube-code';

export const createYoutubeLoginViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    return {
      checkHasYoutubeLiveStreamEnabled: (token: YoutubeAccessToken) =>
        dispatch(checkHasYoutubeLiveStreamEnabled(token)),
      checkHasYoutubeLiveStreamEnabledStatus: state.youtube.hasYoutubeLiveStreamEnabledChecking.status,
      exchangeYoutubeCode: (code: string): Promise<YoutubeAccessToken> => {
        return dispatch(exchangeYoutubeCode(code)).unwrap();
      },
      hasYoutubeLiveStreamEnabled: state.youtube.hasLiveStreamEnabled,
    };
  };
