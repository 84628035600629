import { UserJourneyEvent } from '../../gateways/user-journey-tracing-gateway';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export const trackUserJourneyEvent = createAppAsyncThunk(
  'user-journey-tracing/track-event',
  async (
    userJourneyEvent: UserJourneyEvent,
    { extra: { tracingGateway, userJourneyTracingGateway } },
  ): Promise<void> => {
    try {
      userJourneyTracingGateway.trackEvent(userJourneyEvent);
    } catch (e: any) {
      tracingGateway.captureException(e);
      console.error('Error while tracking user journey event', e);
      throw e;
    }
  },
);
