import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function KlaviyoIcon(props: SvgIconProps) {
  return (
    <SvgIcon height="1032" viewBox="0 0 1541 1032" width="1541" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m1541 1031.8h-1540.8v-1031.8h1540.8l-323.2 516.3z" fill={props.fill} fillRule="evenodd" />
    </SvgIcon>
  );
}
