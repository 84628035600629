import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Textfit } from 'react-textfit';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOEventReadModelProduct } from '../../../../core/domain/CBOEventReadModelProduct';
import { getIndexOfFirstNotRemoved, getSmallestVariantPrice } from '../../../../utils/variants';
import ProductPrice from '../../atoms/ProductPrice';
import { PriceWithCrossedOutPrice } from './PriceWithCrossedOutPrice';

interface ProductHighlightProps {
  currency: CBOCurrency;
  product: CBOEventReadModelProduct;
}

const PREFIX = 'ProductHighlightCard';

const classes = {
  productImage: `${PREFIX}-productImage`,
  productName: `${PREFIX}-productName`,
  productPrice: `${PREFIX}-productPrice`,
};

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'stretch',
  backgroundColor: theme.palette.common.white,
  borderRadius: '0.8rem',
  display: 'flex',
  justifyContent: 'flex-start',
  margin: 0,
  padding: theme.spacing(1),
  width: '100%',

  [`& .${classes.productImage}`]: {
    flexShrink: '0',
    height: '70px',
    objectFit: 'cover',
    width: '70px',
  },
  [`& .${classes.productName}`]: {
    display: 'block',
    maxHeight: '100px',
  },
  [`& .${classes.productPrice}`]: {
    fontSize: '17px',
    fontWeight: 'bold',
  },
}));

function Price({
  currency,
  crossedOutPrice,
  price,
}: {
  crossedOutPrice: number | null;
  currency: CBOCurrency;
  price: number | null;
}) {
  if (crossedOutPrice) {
    return <PriceWithCrossedOutPrice crossedOutPrice={crossedOutPrice} currency={currency} price={price} />;
  }
  return <ProductPrice currency={currency} price={price} />;
}

export default function ProductHighlightCard({ currency, product }: ProductHighlightProps) {
  const renderWithOptionsPrice = () => {
    if (product.options && product.options.samePricesChecked) {
      const indexOfFirstNotRemoved = getIndexOfFirstNotRemoved(product.options.variants);

      if (indexOfFirstNotRemoved === -1) {
      }
      const { crossedOutPrice, price } =
        indexOfFirstNotRemoved === -1
          ? { crossedOutPrice: null, price: 0 }
          : product.options.variants[indexOfFirstNotRemoved];

      return <Price crossedOutPrice={crossedOutPrice} currency={currency} price={price} />;
    }

    const smallestPrice = product.options ? getSmallestVariantPrice(product.options.variants) : null;
    return <ProductPrice currency={currency} price={smallestPrice} variant="range" />;
  };

  return (
    <Root boxShadow={2}>
      <img alt={product.title} className={classes.productImage} src={product.imageUrls[0]} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxHeight: '100px',
          mx: 1,
        }}
      >
        <div className={classes.productName} style={{ height: '80%' }}>
          <Textfit max={15} mode="multi" style={{ height: '100%', width: '100%' }}>
            {product.title}
          </Textfit>
        </div>
        <div className={classes.productPrice}>
          {product.hasOptionsChecked ? (
            renderWithOptionsPrice()
          ) : (
            <Price crossedOutPrice={product.crossedOutPrice} currency={currency} price={product.price} />
          )}
        </div>
      </Box>
    </Root>
  );
}
