import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { State } from '../../core/store';
import { CommandStatus, QueryStatus } from '../../core/store/state/utils';
import getDisplaySnackbar from '../../utils/get-display-snackbar';
import useDisplaySnackbar from '../hooks/useDisplaySnackbar';

const anyInError = (
  ...results: { errors: unknown[]; status: CommandStatus | QueryStatus }[]
): { errors: unknown[]; status: CommandStatus | QueryStatus } | null => {
  let anyInError = null;
  results.forEach((result) => {
    if (result.status === 'error') {
      anyInError = result;
    }
  });
  return anyInError;
};

export default function RequestsNotifications() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['events', 'shoppables']);
  const { addProductsToEvent, productsDelete: catalogProductsDelete } = useSelector((state: State) => state.catalog);
  const createTenant = useSelector((state: State) => state.administration.createTenant);
  const {
    eventClosing,
    eventCreation,
    eventDeletion,
    eventFileUrlsFetching,
    eventFilesUploading,
    eventOpening,
    eventProductAddition,
    eventProductDelete,
    eventProductUpdate,
    eventPublish,
    eventThemeCopy,
    eventThemeCustomisation,
    eventUnPublish,
    eventUpdate,
    eventsFetching,
    pinMessage,
    productsLineupReordering,
    replayUpdate,
    unpinMessage,
  } = useSelector((state: State) => state.events);
  const { facebookConnecting } = useSelector((state: State) => state.facebook);
  const { masterInvitation } = useSelector((state: State) => state.administration);
  const { cmsLandingPageCreate, hasCmsLandingPageFetching, landingPageDataUpdate } = useSelector(
    (state: State) => state.landingPage,
  );
  const {
    mediaCollectionCreation,
    mediaCollectionDeletion,
    mediaCollectionPagesUpdate,
    mediaCollectionSettingsUpdate,
  } = useSelector((state: State) => state.mediaCollections);
  const {
    shoppableCreation,
    shoppableVideoUpdate,
    shoppableDeletion,
    shoppablePublishing,
    shoppableThumbnailFileUploading,
    shoppableVideoThumbnailFileUploadUrlFetching,
  } = useSelector((state: State) => state.shoppables);
  const { updateAlias, updateIntegrations } = useSelector((state: State) => state.tenants);

  const displaySnackbar = useMemo(() => getDisplaySnackbar(enqueueSnackbar, t), [enqueueSnackbar]);

  useDisplaySnackbar(displaySnackbar, addProductsToEvent, {
    error: 'catalog:notifications.AddProductsToEventFailed',
    success: 'catalog:notifications.AddProductsToEventSuccess',
  });

  useDisplaySnackbar(displaySnackbar, eventsFetching, {
    error: 'notifications.EventsFailedFetching',
    success: 'notifications.EventSuccessfullyFetched',
  });

  useDisplaySnackbar(displaySnackbar, catalogProductsDelete, {
    error: 'catalog:notifications.CatalogProductsFailedDeleting',
    success: 'catalog:notifications.CatalogProductsSuccessfullyDeleted',
  });

  useDisplaySnackbar(displaySnackbar, catalogProductsDelete, {
    error: t('catalog:notifications.CatalogProductsFailedDeleting'),
    success: t('catalog:notifications.CatalogProductsSuccessfullyDeleted'),
  });

  useDisplaySnackbar(displaySnackbar, cmsLandingPageCreate, {
    error: t('liveShoppingPage:notifications.CmsLandingPageFailedCreation'),
    success: t('liveShoppingPage:notifications.CmsLandingPageSuccessfullyCreated'),
  });

  // TODO
  useDisplaySnackbar(displaySnackbar, mediaCollectionCreation, {
    error: 'An error occurred while creating the playlist. Please retry.',
    success: 'Collection successfully created!',
  });

  useDisplaySnackbar(displaySnackbar, mediaCollectionDeletion, {
    error: 'An error occurred while deleting the playlist. Please retry.',
    success: 'Collection successfully deleted!',
  });

  // TODO
  useDisplaySnackbar(displaySnackbar, mediaCollectionSettingsUpdate, {
    error: 'An error occurred while updating the playlist. Please retry.',
    success: 'Collection successfully updated!',
  });

  useDisplaySnackbar(displaySnackbar, mediaCollectionPagesUpdate, {
    error: 'An error occurred while updating the playlist. Please retry.',
    success: 'Collection pages successfully updated!',
  });

  useDisplaySnackbar(displaySnackbar, createTenant, {
    error: 'An error occurred while inviting the master. Are you sure the tenant is unique ?',
    success: 'Tenant successfully created!',
  });

  useDisplaySnackbar(displaySnackbar, eventClosing, {
    error: t('notifications.EventFailedClosing'),
    success: t('notifications.EventSuccessfullyClosed'),
  });

  useDisplaySnackbar(displaySnackbar, eventCreation, {
    error: t('notifications.EventFailedCreating'),
    success: t('notifications.EventSuccessfullyCreated'),
  });

  useDisplaySnackbar(displaySnackbar, eventDeletion, {
    error: t('notifications.EventFailedDeleting'),
    success: t('notifications.EventSuccessfullyDeleted'),
  });

  useDisplaySnackbar(displaySnackbar, eventOpening, {
    error: t('notifications.EventFailedOpening'),
    success: t('notifications.EventSuccessfullyOpened'),
  });

  useDisplaySnackbar(displaySnackbar, eventPublish, {
    error: t('notifications.EventFailedPublishing'),
    success: t('notifications.EventSuccessfullyPublished'),
  });

  useDisplaySnackbar(displaySnackbar, eventThemeCopy, {
    error: t('notifications.EventThemeFailedCopying'),
    success: t('notifications.EventThemeSuccessfullyCopied'),
  });

  useDisplaySnackbar(displaySnackbar, eventUnPublish, {
    error: t('notifications.EventFailedUnPublishing'),
    success: t('notifications.EventSuccessfullyUnPublished'),
  });

  useDisplaySnackbar(displaySnackbar, eventUpdate, {
    error: t('notifications.EventFailedUpdating'),
    success: t('notifications.EventSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, landingPageDataUpdate, {
    error: t('liveShoppingPage:notifications.LandingPageFailedUpdating'),
    success: t('liveShoppingPage:notifications.LandingPageSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, masterInvitation, {
    error: 'An error occurred while inviting the master.',
    success: 'Master successfully invited!',
  });

  useDisplaySnackbar(displaySnackbar, pinMessage, {
    error: t('notifications.MessageFailedPinning'),
    success: t('notifications.MessageSuccessfullyPinned'),
  });

  useDisplaySnackbar(displaySnackbar, eventProductAddition, {
    error: t('notifications.ProductFailedAddition'),
    success: t('notifications.ProductSuccessfullyAdded'),
  });

  useDisplaySnackbar(displaySnackbar, eventProductDelete, {
    error: t('notifications.ProductFailedDeleting'),
    success: t('notifications.ProductSuccessfullyDeleted'),
  });

  useDisplaySnackbar(displaySnackbar, eventProductUpdate, {
    error: t('notifications.ProductFailedUpdating'),
    success: t('notifications.ProductSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, productsLineupReordering, {
    error: t('notifications.ProductsLineupFailedReordering'),
    success: t('notifications.ProductsLineupSuccessfullyReordered'),
  });

  useDisplaySnackbar(displaySnackbar, replayUpdate, {
    error: t('notifications.ReplayFailedUpdating'),
    success: t('notifications.ReplaySuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, unpinMessage, {
    error: t('notifications.MessageFailedUnpinning'),
    success: t('notifications.MessageSuccessfullyUnpinned'),
  });

  useDisplaySnackbar(displaySnackbar, updateAlias, {
    error: t('administration:notifications.AliasFailedUpdating'),
    success: t('administration:notifications.AliasSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, updateIntegrations, {
    error: t('administration:notifications.IntegrationsFailedUpdating'),
    success: t('administration:notifications.IntegrationsSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, shoppablePublishing, {
    error: t('shoppables:notifications.ShoppableFailedPublishing'),
    success: t('shoppables:notifications.ShoppableSuccessfullyPublished'),
  });

  useDisplaySnackbar(displaySnackbar, shoppableCreation, {
    error: t('shoppables:notifications.ShoppableFailedCreation'),
    success: t('shoppables:notifications.ShoppableSuccessfullyCreated'),
  });

  useDisplaySnackbar(displaySnackbar, shoppableDeletion, {
    error: t('shoppables:notifications.ShoppableFailedDeleting'),
    success: t('shoppables:notifications.ShoppableSuccessfullyDeleted'),
  });

  useDisplaySnackbar(displaySnackbar, shoppableVideoUpdate, {
    error: t('shoppables:notifications.ShoppableFailedUpdate'),
    success: t('shoppables:notifications.ShoppableSuccessfullyUpdated'),
  });

  useDisplaySnackbar(displaySnackbar, shoppableThumbnailFileUploading, {
    error: t('shoppables:notifications.ThumbnailConfigurationFailed'),
    success: t(''),
  });

  useDisplaySnackbar(displaySnackbar, shoppableVideoThumbnailFileUploadUrlFetching, {
    error: t('shoppables:notifications.ThumbnailConfigurationFailed'),
    success: '',
  });

  useEffect(() => {
    const message = {
      error: t('notifications.EventThemeFailedUpdating'),
      success: t('notifications.EventThemeSuccessfullyUpdating'),
    };
    if (eventThemeCustomisation.status === 'success') {
      // display notif only after having downloaded file and not for all steps
      return displaySnackbar(message, eventThemeCustomisation);
    }

    const inError = anyInError(eventFileUrlsFetching, eventThemeCustomisation, eventFilesUploading);
    if (inError !== null) {
      displaySnackbar(message, inError);
    }
  }, [displaySnackbar, eventFileUrlsFetching, eventThemeCustomisation, eventFilesUploading]);

  useEffect(() => {
    if (hasCmsLandingPageFetching.status === 'error') {
      displaySnackbar(
        {
          error: t('liveShoppingPage:notifications.HasCmsLandingPageFetchingFailed'),
          success: t('liveShoppingPage:notifications.HasCmsLandingPageFetchingSucceeded'),
        },
        hasCmsLandingPageFetching,
      );
    }
  }, [displaySnackbar, hasCmsLandingPageFetching]);

  useEffect(() => {
    if (facebookConnecting.status === 'error') {
      displaySnackbar(
        {
          error: t('notifications.FacebookFailedConnecting'),
          success: '',
        },
        facebookConnecting,
      );
    }
  }, [displaySnackbar, facebookConnecting]);

  return null;
}
