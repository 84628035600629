import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';

type StickyActionBarWrapperProps = PropsWithChildren<{
  inProgress?: boolean;
}>;

export default function StickyActionBarWrapper({ children, inProgress }: StickyActionBarWrapperProps) {
  return (
    <Box
      id="action-bar-wrapper"
      sx={{
        backgroundColor: 'rgba(255,255,255,.6)',
        borderTop: 'solid #E9EDF3 1px',
        bottom: 0,
        position: 'sticky',
        width: '100%',
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Box sx={{ width: '100%' }}>{inProgress && <LinearProgress />}</Box>
      {children}
    </Box>
  );
}
