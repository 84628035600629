import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

const CameraAppIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21.437 37.361" {...props}>
      <g transform="translate(-523.656 -267.301)">
        <g id="noun-cloud-wifi-3022543" transform="translate(526.647 267.301)">
          <path
            d="M242.167,90.575a.615.615,0,0,1-.532-.923,4.305,4.305,0,0,1,7.315-.228c.05.074.1.15.141.228a.615.615,0,1,1-1.064.616c-.032-.055-.066-.109-.1-.163a3.076,3.076,0,0,0-5.226.163.614.614,0,0,1-.533.307Z"
            fill={theme.palette.common.white}
            transform="translate(-236.14 -85.041)"
          />
          <path
            d="M193.1,21.189a.615.615,0,0,1-.491-.984,6.765,6.765,0,0,1,10.822,0,.615.615,0,1,1-.983.738,5.535,5.535,0,0,0-8.856,0,.614.614,0,0,1-.492.245Z"
            fill={theme.palette.common.white}
            transform="translate(-188.8 -17.5)"
          />
          <path
            d="M102.11,214.068a5.6,5.6,0,0,0-10.773,0,3.809,3.809,0,1,0,.16,7.614H101.95a3.809,3.809,0,1,0,.16-7.614Z"
            fill={theme.palette.common.white}
            transform="translate(-87.5 -203.236)"
          />
        </g>
        <rect
          fill={theme.palette.primary.main}
          height="5.185"
          rx="2.593"
          transform="translate(524.25 277.651)"
          width="15.555"
        />
        <g id="noun-mobile-1598029" transform="translate(523.656 277.351)">
          <path
            d="M220.892,68.533a.65.65,0,0,1-.65.65h-4.226a.65.65,0,1,1,0-1.3h4.226a.65.65,0,0,1,.65.65ZM210,47.4a2.6,2.6,0,0,1,2.6-2.6h11.054a2.6,2.6,0,0,1,2.6,2.6V69.508a2.6,2.6,0,0,1-2.6,2.6H212.6a2.6,2.6,0,0,1-2.6-2.6Zm14.956,22.108V50H211.3V69.508a1.3,1.3,0,0,0,1.3,1.3h11.054a1.3,1.3,0,0,0,1.3-1.3ZM211.3,47.4v1.3h13.655V47.4a1.3,1.3,0,0,0-1.3-1.3H212.6a1.3,1.3,0,0,0-1.3,1.3Z"
            fill={theme.palette.common.white}
            transform="translate(-210 -44.799)"
          />
        </g>
        <rect fill={theme.palette.common.white} height="17.283" transform="translate(524.25 281.108)" width="15.555" />
        <path
          d="M0,4.5,2.987,0,5.974,4.5Z"
          fill={theme.palette.primary.main}
          transform="translate(534.309 287.425) rotate(90)"
        />
      </g>
    </SvgIcon>
  );
};

export default CameraAppIcon;
