import { AppDispatch, State } from '../../../../../core/store';
import { initChat } from '../../../../../core/usecases/chat/init-chat';
import { loginChat } from '../../../../../core/usecases/chat/login-chat';
import { fetchOneEventChatCredentials } from '../../../../../core/usecases/event/chat/fetch-event-chat-credentials';

export const createChatViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { chatCredentials } = state.events;
    const eventId = state.events.currentEvent?.id ?? null;
    const { status: pinMessageStatus } = state.events.pinMessage;
    const { status: unpinMessageStatus } = state.events.unpinMessage;

    return {
      chatCredentials,
      eventId,
      fetchEventChatCredentials: () => {
        if (eventId) {
          dispatch(fetchOneEventChatCredentials({ eventId }));
        }
      },
      initChat: () => dispatch(initChat()).unwrap(),
      loginChat: (authToken: string) => dispatch(loginChat({ authToken })).unwrap(),
      pinMessageStatus,
      unpinMessageStatus,
    };
  };
