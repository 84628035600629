import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps } from '@mui/material';
import React, { FC } from 'react';

type CreateButtonProps = ButtonProps & {
  onClick: () => void;
  text: string;
};

const CreateButton: FC<CreateButtonProps> = (props) => (
  <Button
    {...props}
    color="primary"
    onClick={props.onClick}
    startIcon={<AddIcon fontSize="large" />}
    sx={{
      height: '100%',
    }}
    variant="contained"
  >
    {props.text}
  </Button>
);

export default CreateButton;
