import { TenantId } from '@bellepoque/api-contracts';

import { mediaCollectionToDomain } from '../../../gateways/media-collections/media-collection-mapper';
import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';

export type FetchMediaCollectionsRequest = {
  tenantId: TenantId;
};

export const fetchMediaCollections = createAppAsyncThunk(
  'media-collections/fetch-media-collections',
  async (
    { tenantId }: FetchMediaCollectionsRequest,
    { extra: { mediaCollectionsGateway } },
  ): Promise<CBOMediaCollection[]> => {
    const dtos = await mediaCollectionsGateway.fetchMany(tenantId);
    return dtos.map((dto) => mediaCollectionToDomain(dto));
  },
);
