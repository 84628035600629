import { V1, YoutubeAccessToken } from '@bellepoque/api-contracts';
import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { addHours } from 'date-fns';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-phone-number-input/style.css';

import { CBOEventReadModel } from '../../../../../core/domain/CBOEventReadModel';
import { CBOFacebookPage, CBOFacebookUser } from '../../../../../core/domain/CBOFacebookAccount';
import { CBOConnectedYoutubeUser } from '../../../../../core/domain/CBOYoutubeChannel';
import {
  CBOFacebookRestreamingConfiguration,
  CBOYoutubeRestreamingConfiguration,
} from '../../../../../core/domain/Restreaming';
import { CommandStatus, QueryStatus } from '../../../../../core/store/state/utils';
import { lengthRules } from '../../../../../utils/validation';
import StickyActionBarWrapper from '../../../../templates/StickyActionBarWrapper';
import MultistreamActionBar from '../../../molecules/event/FormActionBar';
import FacebookRestreamingSection from '../../../molecules/event/multistream/facebook-restreaming/FacebookRestreamingSection';
import InstagramRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/InstagramRestreamingSection';
import OtherRTMPSourceRestreamingSection from '../../../molecules/event/multistream/rtmp-restreaming/OtherRTMPSourceRestreamingSection';
import YoutubeRestreamingSection from '../../../molecules/event/multistream/youtube-restreaming/YoutubeRestreamingSection';

const constraints = V1.api.constraints.events;

const PREFIX = 'MultistreamForm';

const classes = {
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',

  [`& .${classes.errorMessage}`]: {
    color: theme.palette.error,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export type UpdateMultistreamInputs = Pick<
  CBOEventReadModel,
  'facebookRestreamingConfiguration' | 'restreamingConfigurations' | 'youtubeRestreamingConfiguration'
>;

interface MultistreamFormProps {
  event: CBOEventReadModel;
  facebookIntegrationProps: {
    initStatus: CommandStatus;
    isConnecting: boolean;
    onConnect: () => void;
    onDisconnect: () => void;
    onInit: () => void;
    pages: CBOFacebookPage[];
    tokenExpiresAt: Date | null;
    user: CBOFacebookUser | null;
    userAccessToken: string | null;
  };
  onUpdateEvent: (values: UpdateMultistreamInputs) => void;
  readonly: boolean;
  submissionInProgress: boolean;
  youtubeIntegrationProps: {
    fetchLoginUrlStatus: QueryStatus;
    loginUrl: string | null;
    onDisconnect: () => void;
    onFetchChannels: (token: YoutubeAccessToken) => void;
    onFetchLoginUrl: () => void;
    onResetFetchYoutubeLoginUrl: () => void;
    user: CBOConnectedYoutubeUser | null;
  };
}

export type MultistreamFormInputs = {
  facebookRestreamingConfiguration: CBOFacebookRestreamingConfiguration | null;
  instagramRestreamingEnabled: boolean;
  instagramRestreamingKey: string;
  instagramRestreamingUrl: string;
  otherRTMPSourceRestreamingEnabled: boolean;
  otherRTMPSourceRestreamingKey: string;
  otherRTMPSourceRestreamingUrl: string;
  youtubeRestreamingConfiguration: CBOYoutubeRestreamingConfiguration | null;
};

export default function MultistreamForm({
  event,
  facebookIntegrationProps,
  onUpdateEvent,
  readonly,
  submissionInProgress,
  youtubeIntegrationProps,
}: MultistreamFormProps) {
  const instagramRestreamingConfiguration = event.restreamingConfigurations.find(
    ({ type }) => type === V1.api.RestreamingPlatformSchema.enum.Instagram,
  );

  const otherRTMPRestreamingConfiguration = event.restreamingConfigurations.find(
    ({ type }) => type === V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source,
  );

  const defaultValues = (event: CBOEventReadModel): MultistreamFormInputs => {
    return {
      facebookRestreamingConfiguration: event.facebookRestreamingConfiguration || null,
      instagramRestreamingEnabled: instagramRestreamingConfiguration?.enabled || false,
      instagramRestreamingKey: instagramRestreamingConfiguration?.key || '',
      instagramRestreamingUrl: instagramRestreamingConfiguration?.url || '',
      otherRTMPSourceRestreamingEnabled: otherRTMPRestreamingConfiguration?.enabled || false,
      otherRTMPSourceRestreamingKey: otherRTMPRestreamingConfiguration?.key || '',
      otherRTMPSourceRestreamingUrl: otherRTMPRestreamingConfiguration?.url || '',
      youtubeRestreamingConfiguration: event.youtubeRestreamingConfiguration || null,
    };
  };

  const { t } = useTranslation(['events', 'common']);
  const { register, handleSubmit, setValue, formState, reset, watch } = useForm<MultistreamFormInputs>({
    defaultValues: defaultValues(event),
  });

  const { errors, isDirty } = formState;

  const {
    facebookRestreamingConfiguration,
    instagramRestreamingEnabled,
    otherRTMPSourceRestreamingEnabled,
    youtubeRestreamingConfiguration,
  } = watch();

  useEffect(() => {
    if (isDirty) handleSubmit(onSubmit)();
  }, [facebookRestreamingConfiguration, youtubeRestreamingConfiguration]);

  useEffect(() => {
    if (isDirty && !instagramRestreamingEnabled) handleSubmit(onSubmit)();
  }, [instagramRestreamingEnabled]);

  useEffect(() => {
    resetForm();
  }, [event, reset]);

  const resetForm = () => {
    reset(defaultValues(event));
  };

  const onCancel = () => {
    resetForm();
  };

  const renderActionBar = () => (
    <StickyActionBarWrapper inProgress={submissionInProgress}>
      <MultistreamActionBar buttonsDisabled={submissionInProgress} isFormDirty={isDirty} onCancel={onCancel} />
    </StickyActionBarWrapper>
  );

  const onSubmit = (data: MultistreamFormInputs) => {
    const instagramRestreamingConfiguration = data.instagramRestreamingEnabled
      ? {
          enabled: data.instagramRestreamingEnabled,
          expiresAt: addHours(new Date(), 5),
          key: data.instagramRestreamingKey,
          type: V1.api.RestreamingPlatformSchema.enum.Instagram,
          url: data.instagramRestreamingUrl,
        }
      : {
          enabled: data.instagramRestreamingEnabled,
          expiresAt: null,
          key: data.instagramRestreamingKey,
          type: V1.api.RestreamingPlatformSchema.enum.Instagram,
          url: data.instagramRestreamingUrl,
        };

    const otherRTMPRestreamingConfiguration = {
      enabled: data.otherRTMPSourceRestreamingEnabled,
      expiresAt: null,
      key: data.otherRTMPSourceRestreamingKey,
      type: V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source,
      url: data.otherRTMPSourceRestreamingUrl,
    };

    onUpdateEvent({
      facebookRestreamingConfiguration: data.facebookRestreamingConfiguration,
      restreamingConfigurations: [instagramRestreamingConfiguration, otherRTMPRestreamingConfiguration],
      youtubeRestreamingConfiguration: data.youtubeRestreamingConfiguration,
    });
  };

  const fieldRequiredIfInstagramRestreamingEnabledValidator = (value: string) => {
    if (value === '' && instagramRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: instagramRestreamingKeyInputRef, ...instagramRestreamingKeyInputProps } = register(
    'instagramRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramRestreamingEnabledValidator,
    },
  );

  const { ref: instagramRestreamingUrlInputRef, ...instagramRestreamingUrlInputProps } = register(
    'instagramRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfInstagramRestreamingEnabledValidator,
    },
  );

  const fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator = (value: string) => {
    if (value === '' && otherRTMPSourceRestreamingEnabled) return t('FieldRequiredIfStreamEnabled');
    return true;
  };

  const { ref: otherRTMPSourceRestreamingKeyInputRef, ...otherRTMPSourceRestreamingKeyInputProps } = register(
    'otherRTMPSourceRestreamingKey',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator,
    },
  );

  const { ref: otherRTMPSourceRestreamingUrlInputRef, ...otherRTMPSourceRestreamingUrlInputProps } = register(
    'otherRTMPSourceRestreamingUrl',
    {
      ...lengthRules(constraints.rtmpRestreamingKey.minLength, constraints.rtmpRestreamingKey.maxLength, t),
      validate: fieldRequiredIfOtherRTMPSourceRestreamingEnabledValidator,
    },
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Root id="event-form">
        <Grid container flex={1} justifyContent="center" p={3}>
          <Grid item lg={8} md={10} xs={12}>
            <Container boxShadow={3} py={2}>
              <InstagramRestreamingSection
                expiresAt={instagramRestreamingConfiguration?.expiresAt ?? null}
                keyError={errors?.instagramRestreamingKey?.message}
                onChangeRestreamingEnabled={(value: boolean) =>
                  setValue('instagramRestreamingEnabled', value, { shouldDirty: true })
                }
                readonly={readonly}
                restreamingEnabledWatch={instagramRestreamingEnabled}
                restreamingKeyInputProps={instagramRestreamingKeyInputProps}
                restreamingKeyInputRef={instagramRestreamingKeyInputRef}
                restreamingUrlInputProps={instagramRestreamingUrlInputProps}
                restreamingUrlInputRef={instagramRestreamingUrlInputRef}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.Instagram}
                urlError={errors?.instagramRestreamingUrl?.message}
              />
              <Divider />
              <OtherRTMPSourceRestreamingSection
                expiresAt={otherRTMPRestreamingConfiguration?.expiresAt ?? null}
                keyError={errors?.otherRTMPSourceRestreamingKey?.message}
                onChangeRestreamingEnabled={(value: boolean) =>
                  setValue('otherRTMPSourceRestreamingEnabled', value, { shouldDirty: true })
                }
                readonly={readonly}
                restreamingEnabledWatch={otherRTMPSourceRestreamingEnabled}
                restreamingKeyInputProps={otherRTMPSourceRestreamingKeyInputProps}
                restreamingKeyInputRef={otherRTMPSourceRestreamingKeyInputRef}
                restreamingUrlInputProps={otherRTMPSourceRestreamingUrlInputProps}
                restreamingUrlInputRef={otherRTMPSourceRestreamingUrlInputRef}
                submissionInProgress={submissionInProgress}
                type={V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source}
                urlError={errors?.otherRTMPSourceRestreamingUrl?.message}
              />
              <Divider />
              <FacebookRestreamingSection
                {...facebookIntegrationProps}
                areButtonsDisabled={readonly || submissionInProgress}
                configuredAccount={facebookRestreamingConfiguration}
                onChange={(value: CBOFacebookRestreamingConfiguration | null) =>
                  setValue('facebookRestreamingConfiguration', value, { shouldDirty: true })
                }
              />
              <Divider />
              <YoutubeRestreamingSection
                {...youtubeIntegrationProps}
                configuredAccount={youtubeRestreamingConfiguration}
                onChange={(value: CBOYoutubeRestreamingConfiguration | null) =>
                  setValue('youtubeRestreamingConfiguration', value, { shouldDirty: true })
                }
                readonly={readonly}
                submissionInProgress={submissionInProgress}
              />
            </Container>
          </Grid>
        </Grid>
        {!readonly && renderActionBar()}
      </Root>
    </form>
  );
}
