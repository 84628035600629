import { NavigationTabs } from '../../../ui/navigation-tabs';

export interface TutorialState {
  currentStep: number;
  highlightedTab: NavigationTabs | null;
  isShown: boolean;
}

export const INITIAL_TUTORIAL_STATE: TutorialState = {
  currentStep: 0,
  highlightedTab: null,
  isShown: false,
};
