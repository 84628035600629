import { V1 } from '@bellepoque/api-contracts';

export type CBOEventDepiction = {
  id: string;
  showTime: Date | null;
  title: string;
};

export const eventDepictionToDomain = (dto: V1.api.EventDepictionDTO): CBOEventDepiction => ({
  ...dto,
  showTime: dto.showTime ? new Date(dto.showTime) : null,
});
