import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { usesAmPm } from '../../../../utils/uses-am-pm';

const CurrentTime = styled(Typography)({
  fontSize: '2.5vw',
  fontWeight: 500,
});

export default function HeaderCurrentTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const amPm = usesAmPm();

  return (
    <Box component="span">
      <CurrentTime variant="titleFont">{format(currentTime, amPm ? 'hh:mm a' : 'HH:mm')}</CurrentTime>
    </Box>
  );
}
