import { AdministrationState, INITIAL_ADMINISTRATION_STATE } from './administration';
import { AuthenticationState, INITIAL_AUTHENTICATION_STATE } from './authentication';
import { CatalogState, INITIAL_CATALOG_STATE } from './catalog';
import { EventsState, INITIAL_EVENTS_STATE } from './events';
import { FacebookState, INITIAL_FACEBOOK_STATE } from './facebook';
import { HomeState, INITIAL_HOME_STATE } from './home';
import { INITIAL_KLAVIYO_STATE, KlaviyoState } from './klaviyo';
import { INITIAL_LANDING_PAGE_STATE, LandingPageState } from './landingPage';
import { INITIAL_MEDIA_COLLECTIONS_STATE, MediaCollectionsState } from './mediaCollections';
import { INITIAL_ONBOARDING_STATE, OnboardingState } from './onboarding';
import { INITIAL_REPORTS_STATE, ReportsState } from './reports';
import { INITIAL_SETTINGS_STATE, SettingsState } from './settings';
import { INITIAL_SHOPIFY_STATE, ShopifyState } from './shopify';
import { INITIAL_SHOPPABLES_STATE, ShoppablesState } from './shoppables';
import { INITIAL_TENANT_STATISTICS_STATE, TenantStatisticsState } from './tenantStatistics';
import { INITIAL_TENANTS_STATE, TenantsState } from './tenants';
import { INITIAL_TUTORIAL_STATE, TutorialState } from './tutorial';
import { INITIAL_YOUTUBE_STATE, YoutubeState } from './youtube';

export interface State {
  administration: AdministrationState;
  authentication: AuthenticationState;
  catalog: CatalogState;
  events: EventsState;
  facebook: FacebookState;
  home: HomeState;
  klaviyo: KlaviyoState;
  landingPage: LandingPageState;
  mediaCollections: MediaCollectionsState;
  onboarding: OnboardingState;
  reports: ReportsState;
  settings: SettingsState;
  shopify: ShopifyState;
  shoppables: ShoppablesState;
  tenantStatistics: TenantStatisticsState;
  tenants: TenantsState;
  tutorial: TutorialState;
  youtube: YoutubeState;
}

export const INITIAL_STATE: State = {
  administration: INITIAL_ADMINISTRATION_STATE,
  authentication: INITIAL_AUTHENTICATION_STATE,
  catalog: INITIAL_CATALOG_STATE,
  events: INITIAL_EVENTS_STATE,
  facebook: INITIAL_FACEBOOK_STATE,
  home: INITIAL_HOME_STATE,
  klaviyo: INITIAL_KLAVIYO_STATE,
  landingPage: INITIAL_LANDING_PAGE_STATE,
  mediaCollections: INITIAL_MEDIA_COLLECTIONS_STATE,
  onboarding: INITIAL_ONBOARDING_STATE,
  reports: INITIAL_REPORTS_STATE,
  settings: INITIAL_SETTINGS_STATE,
  shopify: INITIAL_SHOPIFY_STATE,
  shoppables: INITIAL_SHOPPABLES_STATE,
  tenantStatistics: INITIAL_TENANT_STATISTICS_STATE,
  tenants: INITIAL_TENANTS_STATE,
  tutorial: INITIAL_TUTORIAL_STATE,
  youtube: INITIAL_YOUTUBE_STATE,
};
