import i18next from 'i18next';

import { CBOCurrency } from '../core/domain/CBOCurrency';

type FormatCurrencyOptions = {
  locale?: string;
  round?: boolean;
  withoutSymbol?: boolean;
};

export function formatCurrency(amount: number, currency: CBOCurrency, options?: FormatCurrencyOptions) {
  const locale = options?.locale ?? i18next.language;

  const fractionDigits = options?.round ? 0 : currency.decimalDigits;

  if (options?.withoutSymbol) {
    return amount.toLocaleString(locale, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
      useGrouping: false,
    });
  }

  return amount.toLocaleString(locale, {
    currency: currency.code,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: 'currency',
  });
}
