import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';
import React from 'react';

type ReplayInfosProps = {
  eventDescription: string;
  eventTitle: string;
  onEdit: () => void;
};

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(4),
}));

const TitleAndDescriptionContainer = styled(Box)({
  flex: 1,
  maxWidth: '250px',
});

const Description = styled(Typography)({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  display: '-webkit-box',
  flex: 1,
  fontSize: '.9em',
  lineHeight: 1.4,
  maxHeight: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  width: '100%',
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.4em',
  fontWeight: 'bold',
  marginBottom: '5px',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
  backgroundColor: theme.palette.grey[100],

  padding: theme.spacing(1),
}));

const StyledIcon = styled(Edit)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '20px',
}));

export default function ReplayInfos({ eventTitle, eventDescription, onEdit }: ReplayInfosProps) {
  return (
    <Container>
      <TitleAndDescriptionContainer>
        <Title variant="h2">{eventTitle}</Title>
        <Description>{eventDescription}</Description>
      </TitleAndDescriptionContainer>
      <Box>
        <StyledIconButton onClick={onEdit}>
          <StyledIcon />
        </StyledIconButton>
      </Box>
    </Container>
  );
}
