import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import UpgradePlanButton from '../../atoms/UpgradePlanButton';

type IntegrationDisabledProps = {
  tenantName: string;
  text?: string;
};

export default function IntegrationDisabled({ tenantName, text }: IntegrationDisabledProps): JSX.Element {
  const { t } = useTranslation('integrations');

  return (
    <Box mb={2} p={6} pb={4}>
      <Typography>{text ?? t('IntegrationNotEnabled')}</Typography>
      <UpgradePlanButton sx={{ marginTop: (theme) => theme.spacing(2) }} tenantName={tenantName} />
    </Box>
  );
}
