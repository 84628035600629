import { V1 } from '@bellepoque/api-contracts';

import { CBOEventStatisticsPreview, CBOTenantStatistics } from '../../core/domain/CBOTenantStatistics';

function eventStatisticsPreviewToDomain(dto: V1.api.EventStatisticsPreviewDTO): CBOEventStatisticsPreview {
  return {
    ...dto,
    showTime: dto.showTime ? new Date(dto.showTime) : null,
  };
}

export function toDomain(tenantStatisticsDTO: V1.api.TenantStatisticsDTO): CBOTenantStatistics {
  return {
    ...tenantStatisticsDTO,
    events: {
      ...tenantStatisticsDTO.events,
      eventStatisticsPreviews: tenantStatisticsDTO.events.eventStatisticsPreviews.map((preview) =>
        eventStatisticsPreviewToDomain(preview),
      ),
    },
    shoppables: {
      ...tenantStatisticsDTO.shoppables,
    },
  };
}
