import { Box, Typography, lighten, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import CloseButtonIcon from '../CloseButtonIcon';

const GetStartedActionCardContainer = styled(Box)({
  borderRadius: '20px',
  boxShadow: '0px 3px 16px #00000029',
  display: 'flex',
  height: 140,
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
});

const CloseButtonIconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const Image = styled('img')({
  height: '100%',
  width: '185px',
});

const RightSideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  width: '100%',
}));

const TextsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  justifyContent: 'center',
}));

const Duration = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: lighten(theme.palette.primary.main, 0.9),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '10px',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '0.8em',
  fontWeight: 'bold',
  height: '30px',
  justifyContent: 'center',
  textAlign: 'center',
  width: '200px',
}));

const Title = styled(Typography)({
  fontSize: '1.2em',
  fontWeight: 'bold',
});

const ActionButtonContainer = styled(Box)({
  '& > button': {
    width: '100%',
  },

  width: '200px',
});

type GetStartedActionCardProps = {
  actionButton: ReactNode;
  description: string;
  duration: string;
  imageSrc: string;
  onClose: () => void;
  title: string;
};

const GetStartedActionCard: FC<GetStartedActionCardProps> = ({
  actionButton,
  description,
  duration,
  imageSrc,
  onClose,
  title,
}) => (
  <GetStartedActionCardContainer>
    <CloseButtonIconContainer>
      <CloseButtonIcon onClick={onClose} size="small" />
    </CloseButtonIconContainer>

    <Image src={imageSrc} />

    <RightSideContainer>
      <TextsContainer>
        <Duration>{duration}</Duration>
        <Title variant="h3">{title}</Title>
        <Typography>{description}</Typography>
      </TextsContainer>

      <ActionButtonContainer>{actionButton}</ActionButtonContainer>
    </RightSideContainer>
  </GetStartedActionCardContainer>
);

export default GetStartedActionCard;
