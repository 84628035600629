import { AppBar, Box, LinearProgress, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from '../../atoms/CreateButton';
import SettingsButton from '../../atoms/SettingsButton';
import ShoppablesCounterUpgradePlanButton from './ShoppablesCounterUpgradePlanButton';

const Container = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  left: 0,
  position: 'absolute',
  right: 'auto',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(5)}`,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '2.5em',
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'end',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'start',
  textAlign: 'right',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

type ShoppablesHeaderProps = {
  isSettingsButtonShown: boolean;
  loading: boolean;
  maxPublishedShoppablesCount: number | null;
  onOpenSettings: () => void;
  onOpenShoppableCreator: () => void;
  publishedShoppablesCount: number;
  tenantName: string;
};

const ShoppablesHeader = ({
  loading,
  isSettingsButtonShown,
  maxPublishedShoppablesCount,
  onOpenSettings,
  onOpenShoppableCreator,
  publishedShoppablesCount,
  tenantName,
}: ShoppablesHeaderProps) => {
  const { t } = useTranslation(['shoppables', 'common']);

  return (
    <Container elevation={0}>
      <ContentContainer>
        <Title variant="h4">{t('ShoppableVideoManager')}</Title>
        <ActionsContainer>
          {maxPublishedShoppablesCount && (
            <ShoppablesCounterUpgradePlanButton
              maxPublishedShoppablesCount={maxPublishedShoppablesCount}
              publishedShoppablesCount={publishedShoppablesCount}
              tenantName={tenantName}
            />
          )}
          <ButtonsContainer>
            <CreateButton onClick={onOpenShoppableCreator} text={t('CreateShoppable')} />
            {isSettingsButtonShown && <SettingsButton onClick={onOpenSettings}>{t('common:Settings')}</SettingsButton>}
          </ButtonsContainer>
        </ActionsContainer>
      </ContentContainer>
      {loading && <LinearProgress sx={{ mt: 1 }} />}
    </Container>
  );
};

export default ShoppablesHeader;
