import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppEpic } from '../..';
import { logoutFailed, logoutPending, logoutSucceeded } from '../../slices/authentication.slice';
import { ofType } from '../utils';

export const logoutEpic: AppEpic = (action$, state$, { container: { authenticationGateway } }) => {
  return action$.pipe(
    ofType<ReturnType<typeof logoutPending>>(logoutPending.type),
    switchMap(() => {
      return of(authenticationGateway.logout()).pipe(
        map(() => logoutSucceeded()),
        catchError((error) => of(logoutFailed(error))),
      );
    }),
  );
};
