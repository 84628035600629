import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

import { getSecuredFileUploadUrls } from '../../../usecases/event/files/get-secured-file-upload-urls';
import { getSecuredReplayFileUploadUrls } from '../../../usecases/event/files/get-secured-replay-file-upload-urls';
import { uploadEventFiles } from '../../../usecases/event/files/upload-event-files';
import { uploadReplayFiles } from '../../../usecases/event/files/upload-replay-files';
import { EventsState } from '../../state/events';
import { errorStatus, loadedStatus, notRequestedStatus, pendingStatus, successStatus } from '../../utils';

export const resetCopyEventTheme = createAction<void>('events/reset-copy-theme');

const buildEventFilesReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(getSecuredFileUploadUrls.fulfilled, (state, action) => {
    let currentEvent = state.currentEvent;
    if (currentEvent?.id === action.meta.arg.eventId) {
      currentEvent = {
        ...currentEvent,
        fileUploadUrls: action.payload,
      };
    }

    return {
      ...state,
      ...loadedStatus('eventFileUrlsFetching'),
      currentEvent,
    };
  });
  builder.addCase(getSecuredFileUploadUrls.pending, (state) => ({
    ...state,
    ...notRequestedStatus('eventFilesUploading'),
    ...notRequestedStatus('eventThemeCustomisation'),
    ...pendingStatus('eventFileUrlsFetching'),
  }));
  builder.addCase(getSecuredFileUploadUrls.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventFileUrlsFetching', [action.error]),
  }));

  builder.addCase(uploadEventFiles.fulfilled, (state, action) => {
    let currentEvent = state.currentEvent;
    if (currentEvent?.id === action.meta.arg.eventId) {
      currentEvent = {
        ...currentEvent,
        adminMessageIconUploaded: !!action.payload.adminMessageIcon,
        brandLogoUploaded: !!action.payload.brandLogo,
        cartIconUploaded: !!action.payload.cartIcon,
        postEventCoverUploaded: !!action.payload.postEventCover,
        preEventCoverUploaded: !!action.payload.preEventCover,
        storeIconUploaded: !!action.payload.storeIcon,
      };
    }

    return {
      ...state,
      ...successStatus('eventFilesUploading'),
      currentEvent,
    };
  });
  builder.addCase(uploadEventFiles.pending, (state) => ({
    ...state,
    ...pendingStatus('eventFilesUploading'),
  }));
  builder.addCase(uploadEventFiles.rejected, (state, action) => ({
    ...state,
    ...errorStatus('eventFilesUploading', [action.error]),
  }));
};

const buildReplayFilesReducer = (builder: ActionReducerMapBuilder<EventsState>) => {
  builder.addCase(getSecuredReplayFileUploadUrls.fulfilled, (state, action) => ({
    ...state,
    ...loadedStatus('replayFileUploadUrlsFetching'),
    replayFileUploadUrls: action.payload,
  }));
  builder.addCase(getSecuredReplayFileUploadUrls.pending, (state) => ({
    ...state,
    ...pendingStatus('replayFileUploadUrlsFetching'),
    replayFileUploadUrls: null,
  }));
  builder.addCase(getSecuredReplayFileUploadUrls.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replayFileUploadUrlsFetching', [action.error]),
  }));

  builder.addCase(uploadReplayFiles.fulfilled, (state) => {
    return {
      ...state,
      ...successStatus('replayFilesUploading'),
    };
  });
  builder.addCase(uploadReplayFiles.pending, (state) => ({
    ...state,
    ...pendingStatus('replayFilesUploading'),
  }));
  builder.addCase(uploadReplayFiles.rejected, (state, action) => ({
    ...state,
    ...errorStatus('replayFilesUploading', [action.error]),
  }));
};

export const buildFilesReducers = (builder: ActionReducerMapBuilder<EventsState>) => {
  buildEventFilesReducer(builder);
  buildReplayFilesReducer(builder);
};
