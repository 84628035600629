import { Badge, Chip, ChipProps, badgeClasses, chipClasses, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

type Status = 'beta' | 'new' | 'soon';

export type ReleaseBadgeProps = {
  newFeaturesNumber?: number;
  size?: ChipProps['size'];
  status: Status;
};

const colorsByStatus = (theme: Theme): Record<Status, { backgroundColor: string; color: string }> => ({
  beta: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  new: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  soon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
});

const ReleaseBadgeRoot = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    fontSize: '10px',
    height: '14px',
    minWidth: '14px',
    right: '2px',
    top: '4px',
    width: '14px',
  },
});

const ReleaseChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'status' })<{
  status: Status;
}>(({ status, theme }) => {
  const { backgroundColor, color } = colorsByStatus(theme)[status];
  return {
    backgroundColor: 'transparent',
    [`& .${chipClasses.label}`]: {
      backgroundColor,
      borderRadius: '4px',
      color,
      fontSize: '10px',
      fontWeight: 'bold',
      width: 'inherit',
    },
  };
});

const ReleaseBadge: FC<ReleaseBadgeProps> = ({ size, status, newFeaturesNumber }) => (
  <ReleaseBadgeRoot badgeContent={newFeaturesNumber} color="secondary">
    <ReleaseChip label={status} size={size} status={status} />
  </ReleaseBadgeRoot>
);

export default ReleaseBadge;
