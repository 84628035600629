import React from 'react';

interface HighlightedMenuArrowProps {
  size?: number;
  style?: React.CSSProperties;
}

export default function HighlightedMenuArrow({ size = 50, style = {} }: HighlightedMenuArrowProps) {
  return (
    <svg height={size} style={style} viewBox="0 0 51.596 51.596" width={size}>
      <path
        d="M4,23.8l3.489,3.489L21.323,13.478V43.6h4.949V13.478L40.082,27.312,43.6,23.8,23.8,4Z"
        id="ic_arrow_upward_24px"
        stroke="#fce937"
        strokeLinejoin="round"
        strokeWidth="12"
        transform="translate(2 49.596) rotate(-90)"
      />
    </svg>
  );
}
