import { initializeApp } from 'firebase/app';

import Auth0Gateway from '../../gateways/authentication/auth0-gateway';
import { ApiCatalogGateway } from '../../gateways/catalog/api-catalog-gateway';
import { CometChatGateway } from '../../gateways/chat/comet-chat-gateway';
import { ApiCurrencyGateway } from '../../gateways/currency/api-currency-gateway';
import { ApiEventGateway } from '../../gateways/event/api-event-gateway';
import { RealFacebookGateway } from '../../gateways/facebook/real-facebook-gateway';
import { ApiHomeGateway } from '../../gateways/home/api-home-gateway';
import { ApiKlaviyoGateway } from '../../gateways/klaviyo/api-klaviyo-gateway';
import { ApiLandingPageGateway } from '../../gateways/landing-page/api-landing-page-gateway';
import { ApiMagicContentGateway } from '../../gateways/magic-content/api-magic-content-gateway';
import { ApiMediaCollectionsGateway } from '../../gateways/media-collections/api-media-collections-gateway';
import { ApiOnboardingGateway } from '../../gateways/onboarding/api-onboarding-gateway';
import { RealtimeDataFirebaseGateway } from '../../gateways/realtime/realtime-data-firebase-gateway';
import { ApiReportsGateway } from '../../gateways/reports/api-reports-gateway';
import { ApiShopifyGateway } from '../../gateways/shopify/api-shopify-gateway';
import { ApiShoppablesGateway } from '../../gateways/shoppables/api-shoppables-gateway';
import { ApiTenantStatisticsGateway } from '../../gateways/tenant-statistics/api-tenant-statistics-gateway';
import { ApiTenantsGateway } from '../../gateways/tenants/api-tenants-gateway';
import SentryTracingGateway from '../../gateways/tracing/sentry-tracing-gateway';
import MixPanelUserJourneyTracingGateway from '../../gateways/user-journey-tracing/mixpanel-user-journey-tracing-gateway';
import { RealYoutubeGateway } from '../../gateways/youtube/real-youtube-gateway';
import { Container } from './container';

export default function buildProdContainer() {
  const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const sentryDsn = process.env.REACT_APP_CBO_SENTRY_DSN;
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  const cometAppID = process.env.REACT_APP_COMETCHAT_APP_ID;
  const cometRegion = process.env.REACT_APP_COMETCHAT_REGION;
  const heymantleAppToken = process.env.REACT_APP_HEYMANTLE_APP_TOKEN;

  if (!firebaseApiKey) {
    throw new Error('env variable REACT_APP_FIREBASE_API_KEY is missing');
  }
  if (!firebaseProjectId) {
    throw new Error('env variable REACT_APP_FIREBASE_PROJECT_ID is missing');
  }
  if (!sentryDsn) {
    throw new Error('env variable REACT_APP_SENTRY_DSN is missing');
  }
  if (!facebookAppId) {
    throw new Error('env variable REACT_APP_FACEBOOK_APP_ID is missing');
  }
  if (!mixPanelToken) {
    throw new Error('env variable REACT_APP_MIXPANEL_TOKEN is missing');
  }

  if (!cometAppID) {
    throw new Error('env variable REACT_APP_COMETCHAT_APP_ID is missing');
  }

  if (!cometRegion) {
    throw new Error('env variable REACT_APP_COMETCHAT_REGION is missing');
  }
  if (!heymantleAppToken) {
    throw new Error('env variable HEYMANTLE_APP_TOKEN is missing');
  }

  const app = initializeApp({
    apiKey: firebaseApiKey,
    projectId: firebaseProjectId,
  });
  const realtimeDataGateway = new RealtimeDataFirebaseGateway(app);
  const authenticationGateway = new Auth0Gateway(realtimeDataGateway);

  const prodContainer: Container = {
    authenticationGateway,
    catalogGateway: new ApiCatalogGateway(authenticationGateway),
    chatGateway: new CometChatGateway(cometAppID, cometRegion),
    currencyGateway: new ApiCurrencyGateway(authenticationGateway),
    eventGateway: new ApiEventGateway(authenticationGateway),
    facebookGateway: new RealFacebookGateway(facebookAppId, authenticationGateway),
    homeGateway: new ApiHomeGateway(authenticationGateway),
    klaviyoGateway: new ApiKlaviyoGateway(authenticationGateway),
    landingPageGateway: new ApiLandingPageGateway(authenticationGateway),
    magicContentGateway: new ApiMagicContentGateway(authenticationGateway),
    mediaCollectionsGateway: new ApiMediaCollectionsGateway(authenticationGateway),
    onboardingGateway: new ApiOnboardingGateway(authenticationGateway),
    realtimeDataGateway,
    reportsGateway: new ApiReportsGateway(authenticationGateway),
    shopifyGateway: new ApiShopifyGateway(authenticationGateway),
    shoppablesGateway: new ApiShoppablesGateway(authenticationGateway),
    tenantStatisticsGateway: new ApiTenantStatisticsGateway(authenticationGateway),
    tenantsGateway: new ApiTenantsGateway(authenticationGateway),
    tracingGateway: new SentryTracingGateway(sentryDsn),
    userJourneyTracingGateway: new MixPanelUserJourneyTracingGateway(mixPanelToken),
    youtubeGateway: new RealYoutubeGateway(authenticationGateway),
  };

  return prodContainer;
}
