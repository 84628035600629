import { Box, styled } from '@mui/material';
import React from 'react';

import ActionNotification, { ActionNotificationProps } from '../ActionNotification';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

type NotificationsProps = {
  notificationsProps: ActionNotificationProps[];
};

export default function Notifications({ notificationsProps }: NotificationsProps) {
  return (
    <Container>
      {notificationsProps.map((notificationProps) => (
        <ActionNotification key={notificationProps.text} {...notificationProps} />
      ))}
    </Container>
  );
}
