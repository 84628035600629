import { V1 } from '@bellepoque/api-contracts';

import { CBOEventDepiction, eventDepictionToDomain } from './CBOEventDepiction';

type BaseCBOHomePage = {
  productPagesWithMediaCollectionsCount: number;
  productsCount: number;
  publishedShoppablesCount: number;
  upcomingEvent: CBOEventDepiction | null;
  views: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
};

type CBOHomePageWithSales = BaseCBOHomePage & {
  purchasedAmounts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
  purchasedCarts: {
    live: number;
    replay: number;
    shoppableVideos: number;
  };
};

type CBOHomePageWithoutSales = BaseCBOHomePage & {
  validatedCarts: {
    live: number;
    replay: number;
  };
  validatedCartsAmounts: {
    live: number;
    replay: number;
  };
};

export type CBOHomePage = CBOHomePageWithoutSales | CBOHomePageWithSales;

export class CBOHomePageGuards {
  static isCBOHomePageWithSales(homePage: CBOHomePage): homePage is CBOHomePageWithSales {
    return 'purchasedAmounts' in homePage && 'purchasedCarts' in homePage;
  }

  static isCBOHomePageWithoutSales(homePage: CBOHomePage): homePage is CBOHomePageWithoutSales {
    return 'validatedCarts' in homePage && 'validatedCartsAmounts' in homePage;
  }
}

export const homePageToDomain = (dto: V1.api.HomepageDTO): CBOHomePage => ({
  ...dto,
  upcomingEvent: dto.upcomingEvent ? eventDepictionToDomain(dto.upcomingEvent) : null,
});
