import { Box, Button, ButtonProps, Dialog, Typography, styled } from '@mui/material';
import type * as CSS from 'csstype';
import React from 'react';

export interface StyledDialogProps {
  ButtonsProps?: ButtonProps[];
  allowClicksThroughBackdrop?: boolean;
  className?: string;
  content: JSX.Element | string;
  customButtons?: React.ReactNode[];
  disableBackdropClose?: boolean;
  hiddenBackdrop?: boolean;
  justifyButtons?: CSS.Property.JustifyContent;
  onClose?: () => void;
  open: boolean;
  title: string;
}

const ButtonsContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'justifyContent' })<{
  justifyContent: CSS.Property.JustifyContent;
}>(({ justifyContent, theme }) => ({
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent,
  marginTop: theme.spacing(2),
}));

const Content = styled(Typography)({
  fontSize: '1em',
  textAlign: 'initial',
  whiteSpace: 'pre-line',
});

const Title = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: '1.5em',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const TitleAndContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function StyledDialog(props: StyledDialogProps) {
  const {
    ButtonsProps = [],
    allowClicksThroughBackdrop = false,
    className,
    content,
    customButtons,
    disableBackdropClose = false,
    hiddenBackdrop = false,
    justifyButtons = 'flex-end',
    onClose,
    open,
    title,
  } = props;

  const handleClose = (reason: string) => {
    if (disableBackdropClose && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose?.();
  };

  return (
    <Dialog
      BackdropProps={{
        sx: { backgroundColor: 'rgba(0,0,0,.5)', pointerEvents: allowClicksThroughBackdrop ? 'none' : 'initial' },
      }}
      PaperProps={{
        className,
        sx: (theme) => ({
          borderRadius: '15px',
          padding: theme.spacing(2),
          pointerEvents: 'all',
        }),
      }}
      disableEnforceFocus={allowClicksThroughBackdrop}
      fullWidth
      hideBackdrop={hiddenBackdrop}
      maxWidth="xs"
      onClose={(_e, reason) => handleClose(reason)}
      open={open}
      sx={{ pointerEvents: allowClicksThroughBackdrop ? 'none' : 'initial' }}
    >
      <TitleAndContentContainer>
        <Title variant="titleFont">{title}</Title>
        <Content>{content}</Content>
      </TitleAndContentContainer>
      {(customButtons || ButtonsProps.length > 0) && (
        <ButtonsContainer justifyContent={justifyButtons}>
          {ButtonsProps.map((buttonProps, index) => (
            <Button {...buttonProps} key={index} />
          ))}
          {customButtons}
        </ButtonsContainer>
      )}
    </Dialog>
  );
}
