import { V1 } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { deleteEvent } from '../../../../../core/usecases/event/crud/delete-event';
import { updateEvent } from '../../../../../core/usecases/event/crud/update-event';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createSettingsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const { status: eventUpdateStatus } = state.events.eventUpdate;

    return {
      deleteEvent: (eventId: string) => dispatch(deleteEvent({ eventId })),
      isUpdatingEvent: eventUpdateStatus === 'pending',
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateEvent: (eventId: string, payload: V1.api.UpdateEventDTO) =>
        dispatch(
          updateEvent({
            id: eventId,
            payload,
          }),
        ),
    };
  };
