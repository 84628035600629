import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CBOEventReadModelProduct } from '../../../../../core/domain/CBOEventReadModelProduct';
import PictureIcon from '../../../../../icons/PictureIcon';
import ImageSelectorByUrl from '../../../atoms/inputs/ImageSelectorByUrl';
import SettingsSection from '../../SettingsSection';

interface ImagesSectionProps {
  isPending: boolean;
  product?: CBOEventReadModelProduct;
  readonly: boolean;
  roundBottom?: boolean;
}

export default function ImagesSection(props: ImagesSectionProps) {
  const { isPending, product, readonly, roundBottom = false } = props;
  const { register, setValue, watch } = useFormContext();
  const { t } = useTranslation('events');
  const theme = useTheme();

  register('imageUrls.0');
  register('imageUrls.1');
  register('imageUrls.2');
  register('imageUrls.3');
  const watchImage0: string | undefined = watch('imageUrls.0');
  const watchImage1: string | undefined = watch('imageUrls.1');
  const watchImage2: string | undefined = watch('imageUrls.2');
  const watchImage3: string | undefined = watch('imageUrls.3');

  const makeSetValue = (watchedValue: string | undefined, propName: string) => (newImageUrl: string) =>
    setValue(propName, newImageUrl, {
      shouldDirty: watchedValue !== newImageUrl,
      shouldValidate: watchedValue !== newImageUrl,
    });

  const shouldShowInReadonly = (index: number): boolean => (readonly ? !!product?.imageUrls[index] : true);

  return (
    <SettingsSection
      altBackground
      icon={<PictureIcon sx={{ color: theme.palette.common.white }} />}
      roundBottom={roundBottom}
      title={t('productDetails.Pictures')}
      width={11}
    >
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" width="100%">
        <ImageSelectorByUrl
          disabled={isPending || readonly}
          id="image0"
          imgUrl={watchImage0}
          onChange={makeSetValue(watchImage0, 'imageUrls.0')}
          required={true}
          title="image0"
        />
        {watchImage0 && shouldShowInReadonly(1) && (
          <ImageSelectorByUrl
            disabled={isPending || readonly}
            id="image1"
            imgUrl={watchImage1}
            onChange={makeSetValue(watchImage1, 'imageUrls.1')}
            required={false}
            title="image1"
          />
        )}
        {(watchImage1 || watchImage2 || watchImage3) && shouldShowInReadonly(2) && (
          <ImageSelectorByUrl
            disabled={isPending || readonly}
            id="image2"
            imgUrl={watchImage2}
            onChange={makeSetValue(watchImage2, 'imageUrls.2')}
            required={false}
            title="image2"
          />
        )}
        {(watchImage2 || watchImage3) && shouldShowInReadonly(3) && (
          <ImageSelectorByUrl
            disabled={isPending || readonly}
            id="image3"
            imgUrl={watchImage3}
            onChange={makeSetValue(watchImage3, 'imageUrls.3')}
            required={false}
            title="image3"
          />
        )}
      </Box>
    </SettingsSection>
  );
}
